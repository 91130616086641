import { Project } from "../../entities";

export type SequenceReducerState = number;

export type SequenceReducerAction =
  | { type: "projects/add" }
  | { type: "app/open-project"; project: Project };

export function sequenceReducer(
  sequenceState = 0,
  action: SequenceReducerAction
): number {
  switch (action.type) {
    case "projects/add":
      return 1;
    case "app/open-project":
      return action.project.sequenceId || 0;
    default:
      return sequenceState + 1;
  }
}
