// @ts-nocheck
/**
 * External dependencies.
 */
import { useContext } from "react";
import {
  billingAddress,
  customerType,
  getCustomerName,
  getEmail,
  getPhoneNumber,
  shippingAddress,
} from "../entities/utils";
import { EstimatorContext, InventoryContext } from "../App";
import { getTotal, roundToHundreth } from "../partsList";
import { projectEstimateNumber } from "../utils";
import { getItemLists } from "../utils/partsList";
import {
  getBillAddressFromCustomer,
  getShipAddressFromCustomer,
} from "./CustomerSelect/utils";
import { useFullListLoader } from "../hooks/useFullListLoader";
import { Customer, ProjectSettings } from "../entities";
import { projectJsonToImmutable } from "../data/fetch-projects";

function getFirstWeekStartDate() {
  return new Date("2023-03-01T00:00:00.000Z");
}

function getWeekNumber(date: Date, firstWeekStartDate: Date) {
  const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
  const timeDiff = date - firstWeekStartDate;
  return Math.floor(timeDiff / millisecondsPerWeek);
}

function itemListsContain(itemLists, project) {
  for (let i = 0; i < itemLists.length; i++) {
    if (
      itemLists[i]["691027711484"] ||
      itemLists[i]["691027711491"] ||
      itemLists[i]["691027711507"] ||
      itemLists[i]["691027711514"]
    ) {
      return true;
    }
  }

  let hasItem = false;

  project.canvases.forEach((canvas) => {
    if (canvas.overrides) {
      canvas.overrides.forEach((item) => {
        if (item.type === "addItemToCanvas") {
          if (
            item?.product?.BarCodeValue?.toString() === "691027711484" ||
            item?.product?.BarCodeValue?.toString() === "691027711491" ||
            item?.product?.BarCodeValue?.toString() === "691027711507" ||
            item?.product?.BarCodeValue?.toString() === "691027711514"
          ) {
            hasItem = true;
          }
        }
      });
    }
  });

  return hasItem;
}

export default function Reports() {
  return null;

  const { estimators } = useContext(EstimatorContext);
  const inventoryList = useContext(InventoryContext);

  const { items: projectsWithCustomers } = useFullListLoader({
    countEndpoint: "getProjectsPageCount",
    dataEndpoint: "getProjectsWithCustomers",
    itemParser: (json) => {
      return {
        project: projectJsonToImmutable(json.project),
        customer: Customer(json.customer),
      };
    },
  });

  return (
    <button
      onClick={() => {
        console.log("Generate reports");

        const targetDate = new Date("2023-03-01T00:00:00.000Z");

        const report = projectsWithCustomers
          .map(({ project, customer }) => {
            if (!project) {
              return null;
            }
            // console.log("project", project.toJS());
            try {
              const projectEstimators = project.estimators.size
                ? project.estimators.reduce((map, id) => {
                    if (estimators.has(id)) {
                      map = estimators.get(id)!.name;
                    }

                    return map;
                  }, "")
                : "";

              const mainEstimator = project.mainEstimators.size
                ? project.mainEstimators.reduce((map, id) => {
                    if (estimators.has(id)) {
                      map = estimators.get(id)!.name;
                    }

                    return map;
                  }, "")
                : "";

              const estimateNumber = projectEstimateNumber(project).replace(
                "#",
                ""
              );

              const canvases = project.canvases;

              const itemLists = getItemLists(canvases, inventoryList, project);
              const getTotals = itemLists.map((itemList) => getTotal(itemList));
              const total = getTotals.reduce(
                (acc, total) => roundToHundreth(acc + total),
                0
              );

              const tax = project.tax;

              const data = {
                estimateNumber,
                customer: customer?.name || "N/A",
                date: project.createdOn,
                salesRep: projectEstimators,
                estimator: mainEstimator,
                phone: getPhoneNumber(customer),
                email: getEmail(customer),
                customerType: customerType(customer),
                projectType: project.settings.projectType,
                total: total.toString(),
                tax: tax.value,
                grandTotal: total + tax.value,
                exportedDate: project.frozenProject.date || "",
                projectTitle: project?.settings?.projectTitle || "",
                shippingAddress: shippingAddress(customer, project.settings),
                billingAddress: billingAddress(customer),
              };

              return data;
            } catch (error) {
              console.log("error", error);
              return null;
            }
          })
          .filter((report) => report)
          .filter((item) => {
            const createdOnDate = new Date(item.date);
            return createdOnDate >= targetDate;
          });

        console.log("RAW REPORT");
        console.log(JSON.stringify(report));

        const itemReport = projects
          .map((project) => {
            if (!project) {
              return null;
            }
            // console.log("project", project.toJS());
            try {
              const customer = project.customer
                ? customers.has(project.customer)
                  ? customers.get(project.customer)?.toJS()
                  : ""
                : "";

              const projectEstimators = project.estimators.size
                ? project.estimators.reduce((map, id) => {
                    if (estimators.has(id)) {
                      map = estimators.get(id)!.name;
                    }

                    return map;
                  }, "")
                : "";

              const estimateNumber = projectEstimateNumber(project).replace(
                "#",
                ""
              );

              const canvases = project.canvases;

              const itemLists = getItemLists(canvases, inventoryList, project);

              if (itemListsContain(itemLists, project)) {
                return estimateNumber;
              } else {
                return null;
              }
            } catch (error) {
              console.log("error", error);
              return null;
            }
          })
          .filter((report) => report);

        console.log("ITEM REPORT");
        console.log(JSON.stringify(itemReport));

        const weeklyReport = report.reduce((buckets, item) => {
          const itemDate = new Date(item.date);
          const firstWeekStartDate = getFirstWeekStartDate();
          const weekNumber = getWeekNumber(itemDate, firstWeekStartDate);

          if (!buckets[weekNumber]) {
            buckets[weekNumber] = 0;
          }

          buckets[weekNumber]++;

          return buckets;
        }, {});

        console.log("WEEKLY COUNT REPORT");
        console.log(JSON.stringify(weeklyReport));

        const salesPersonReport = report.reduce((buckets, item) => {
          const { salesRep } = item;

          if (!buckets[salesRep]) {
            buckets[salesRep] = 0;
          }

          buckets[salesRep]++;

          return buckets;
        }, {});

        console.log("SALES PERSON REPORT");
        console.log(JSON.stringify(salesPersonReport));
      }}
    >
      Generate Reports
    </button>
  );
}
