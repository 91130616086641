// @ts-nocheck
/**
 * External dependencies.
 */
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";

/**
 * Internal dependencies.
 */
import { distanceInFeetObject, pixelsPerFoot, pixelsPerInch } from "../utils";

function EditStairsLength(props) {
  const { stairs, point } = props;
  const pointType = props["point-type"];

  const dispatch = useDispatch();
  const allStairs = useSelector((state) => state.state.present.stairs);

  const theStairs = allStairs.get(stairs.id);

  const element = useRef();

  useOnClickOutside(element, () => {
    dispatch({ type: "window/close-stairs-length" });
  });

  let metrics;

  if (pointType === 1) {
    metrics = distanceInFeetObject({
      x1: theStairs.x1,
      x2: theStairs.x2,
      y1: theStairs.y1,
      y2: theStairs.y1,
    });
  } else if (pointType === 2) {
    metrics = distanceInFeetObject({
      x1: theStairs.x1,
      x2: theStairs.x1,
      y1: theStairs.y1,
      y2: theStairs.y2,
    });
  } else {
    metrics = distanceInFeetObject(theStairs);
  }

  const [metricState, setMetricState] = useState(metrics);

  const boxWidth = 224;
  const boxHeight = 64;

  const adjustedPoint = {
    x: point.x - boxWidth / 2,
    y: point.y - boxHeight / 2,
  };

  return (
    <div
      style={{ left: adjustedPoint.x, top: adjustedPoint.y }}
      ref={element}
      className="error-message edit-stairs-length"
    >
      <div className="error-message__message edit-popup__position">
        <form
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              let newFeet = metricState.feet;
              if (isNaN(newFeet)) {
                newFeet = 0;
              }

              let newInches = metricState.inches;
              if (isNaN(newInches)) {
                newInches = 0;
              }

              if (pointType === 1) {
                let baseValue;

                if (stairs.get("x2") > stairs.get("x1")) {
                  baseValue = theStairs.x1;
                } else {
                  baseValue = theStairs.x2;
                }

                const newX2 =
                  newFeet * pixelsPerFoot() +
                  newInches * pixelsPerInch() +
                  baseValue;

                let newStairs;
                if (stairs.get("x2") > stairs.get("x1")) {
                  newStairs = stairs.set("x2", newX2);
                } else {
                  newStairs = stairs.set("x1", newX2);
                }

                dispatch({ type: "stairs/edit", stairs: newStairs });
              } else if (pointType === 2) {
                let baseValue;

                if (stairs.get("y2") > stairs.get("y1")) {
                  baseValue = theStairs.y1;
                } else {
                  baseValue = theStairs.y2;
                }

                const newY2 =
                  newFeet * pixelsPerFoot() +
                  newInches * pixelsPerInch() +
                  baseValue;

                let newStairs;

                if (stairs.get("y2") > stairs.get("y1")) {
                  newStairs = stairs.set("y2", newY2);
                } else {
                  newStairs = stairs.set("y1", newY2);
                }

                dispatch({ type: "stairs/edit", stairs: newStairs });
              }

              dispatch({ type: "window/close-stairs-length" });
            }
          }}
        >
          <span className="labeled-input">
            <label>FT</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.feet) ? "" : metricState.feet}
              autoFocus
              onChange={(event) => {
                const feet = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, feet: feet });
              }}
            />
          </span>
          <span className="labeled-input">
            <label>IN</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.inches) ? "" : metricState.inches}
              min="0"
              max="11"
              onChange={(event) => {
                const inches = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, inches: inches });
              }}
            />
          </span>
        </form>
      </div>
    </div>
  );
}

export default EditStairsLength;
