import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";
import InputMask from "react-input-mask";
import Icon from "../../Icon";
import { Customer } from "../../../entities";
import { EstimatorContext } from "../../../App";
import { errorClassName } from "../utils";

import CAProvinceSelect from "./CAProvinceSelect";
import CountrySelect from "./CountrySelect";
import USStateSelect from "./USStateSelect";

interface PropType {
  setAddNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  updateCustomer: (
    customer: Customer | null,
    sendEvent?: "customers/add" | "customers/edit"
  ) => void;
  customer: Customer | null;
}

const AddCustomerWindow = ({
  setAddNewWindow,
  setOpen,
  updateCustomer,
  customer,
}: PropType) => {
  const [firstName, setFirstName] = useState(
    customer?.data?.firstName || customer?.quickbooksData?.FirstName || ""
  );
  const [lastName, setLastName] = useState(
    customer?.data?.lastName || customer?.quickbooksData?.LastName || ""
  );

  let initialPhone =
    customer?.data?.phone || customer?.quickbooksData?.Phone || "";

  if (typeof initialPhone === "number") {
    initialPhone = initialPhone.toString();
  }

  const [phone, setPhone] = useState(initialPhone);

  let initialAltPhone =
    customer?.data?.altPhone || customer?.quickbooksData?.AltPhone || "";

  if (typeof initialAltPhone === "number") {
    initialAltPhone = initialAltPhone.toString();
  }

  const [altPhone, setAltPhone] = useState(initialAltPhone);
  const [email, setEmail] = useState(customer?.data?.email || "");
  const [altEmail, setAltEmail] = useState(customer?.data?.altEmail || "");

  const [companyName, setCompanyName] = useState(
    customer?.data?.companyName || customer?.quickbooksData?.CompanyName || ""
  );
  const [customerType, setCustomerType] = useState(
    customer?.data.customerType ||
      customer?.quickbooksData.CustomerTypeRef?.FullName ||
      ""
  );

  const [customerRep, setCustomerRep] = useState(
    customer?.data.customerRep ||
      customer?.quickbooksData.SalesRepRef?.FullName ||
      ""
  );

  const setNewSalesRep = useSelector(
    (state: any) => state.appState.setNewSalesRep
  );

  useEffect(() => {
    if (setNewSalesRep) {
      setCustomerRep(setNewSalesRep);
    }
  }, [customer, setNewSalesRep]);

  const [showBillTo, setShowBillTo] = useState(customer ? true : false);
  const [showShipTo, setShowShipTo] = useState(customer ? true : false);
  const [billToSameAsShipTo, setBillToSameAsShipTo] = useState(false);

  let billToDefaultValue = {
    name: "",
    note: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  };
  if (customer?.data.billing) {
    billToDefaultValue = {
      name: "",
      note: "",
      ...customer.data.billing,
    };
  } else if (customer?.quickbooksData.BillAddress) {
    billToDefaultValue = {
      name: customer?.quickbooksData.BillAddress.Addr1?.toString() || "",
      note: "",
      street: customer?.quickbooksData.BillAddress.Addr2?.toString() || "",
      city: customer?.quickbooksData.BillAddress.City?.toString() || "",
      state: customer?.quickbooksData.BillAddress.State?.toString() || "",
      zip: customer?.quickbooksData.BillAddress.PostalCode?.toString() || "",
      country: customer?.quickbooksData.BillAddress.Country || "US",
    };
  }
  let shipToDefaultValue = {
    name: "",
    note: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "US",
  };
  if (customer?.data?.shipping) {
    shipToDefaultValue = {
      name: "",
      note: "",
      ...customer.data.shipping,
    };
  } else if (customer?.quickbooksData.ShipAddress) {
    shipToDefaultValue = {
      name: customer?.quickbooksData.ShipAddress.Addr1?.toString() || "",
      note: "",
      street: customer?.quickbooksData.ShipAddress.Addr2?.toString() || "",
      city: customer?.quickbooksData.ShipAddress.City?.toString() || "",
      state: customer?.quickbooksData.ShipAddress.State?.toString() || "",
      zip: customer?.quickbooksData.ShipAddress.PostalCode?.toString() || "",
      country: customer?.quickbooksData.ShipAddress.Country || "US",
    };
  }

  const [billTo, setBillTo] = useState(billToDefaultValue);
  const [shipTo, setShipTo] = useState(shipToDefaultValue);

  let altContactsDefaultValue: {
    id: string;
    name: string;
    phone: string;
    email: string;
    note: string;
  }[] = [];
  if (customer?.data?.altContacts?.length) {
    altContactsDefaultValue = customer?.data?.altContacts.map((val: any) => {
      return { id: uuid(), ...val };
    });
  }
  // else if (customer?.quickbooksData.AdditionalContactRef) {
  //   const contactDefaultValue = {
  //     id: uuid(),
  //     name: "",
  //     phone: "",
  //     email: "",
  //     note: "",
  //   };
  //   let contactRefInfo: {
  //     ContactName: string;
  //     ContactValue: string | number;
  //   }[] = [];
  //   if ("length" in customer.quickbooksData.AdditionalContactRef) {
  //     contactRefInfo = customer?.quickbooksData.AdditionalContactRef;
  //   } else {
  //     contactRefInfo = [customer.quickbooksData.AdditionalContactRef];
  //   }
  //   contactRefInfo.forEach((contact) => {
  //     if (contact.ContactName.toLowerCase().includes("name")) {
  //       contactDefaultValue.name =
  //         contactDefaultValue.name || contact.ContactValue.toString();
  //     } else if (contact.ContactName.toLowerCase().includes("phone")) {
  //       contactDefaultValue.phone =
  //         contactDefaultValue.phone || contact.ContactValue.toString();
  //     } else if (contact.ContactName.toLowerCase().includes("email")) {
  //       contactDefaultValue.email =
  //         contactDefaultValue.email || contact.ContactValue.toString();
  //     }
  //   });
  //   if (
  //     contactDefaultValue.email ||
  //     contactDefaultValue.name ||
  //     contactDefaultValue.phone ||
  //     contactDefaultValue.note
  //   ) {
  //     altContactsDefaultValue.push(contactDefaultValue);
  //   }
  // }

  const [altContacts, setAltContacts] = useState(altContactsDefaultValue);

  const dispatch = useDispatch();
  let errorsDefaultValue: Record<string, boolean> = {
    isEmailValid: true,
    isAltEmailValid: true,
    isCustomerTypeValid: true,
  };
  altContactsDefaultValue.forEach((contact) => {
    errorsDefaultValue[`altContactEmailValid${contact.id}`] = true;
  });
  const [errors, setErrors] =
    useState<Record<string, boolean>>(errorsDefaultValue);

  const addCustomerText = customer
    ? "Save Customer Details"
    : "Add New Customer";

  const { estimators } = useContext(EstimatorContext);

  const setEmailErrors = (email: string, error: string): boolean => {
    const pattern =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;

    const match = email.match(pattern);

    if (
      !error.includes("altContactEmailValid") &&
      (match === null || email === "")
    ) {
      setErrors((errors) => {
        return {
          ...errors,
          [error]: false,
        };
      });
      return false;
    } else {
      setErrors((errors) => {
        return {
          ...errors,
          [error]: true,
        };
      });
      return true;
    }
  };

  const setCustomerTypeErrors = (customerType: string): boolean => {
    setErrors((errors) => {
      return {
        ...errors,
        isCustomerTypeValid: !!customerType,
      };
    });
    return !!customerType;
  };

  return (
    <div className="estimators__add-estimator">
      <div className="edit-popup__header add-estimator__header">
        <h2 className="edit-popup__heading">Customer Details</h2>
        <button
          className="app__tooltip-close"
          onClick={() => {
            setAddNewWindow(false);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
      <div className="customer-details">
        <div className="add-estimator__fields">
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-name"
              className="add-estimator__field-label"
            >
              First Name
            </label>
            <input
              className="add-estimator__field-input"
              type="text"
              id="add-customer-name"
              onChange={(event) => setFirstName(event.target.value)}
              value={firstName}
            />
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-name"
              className="add-estimator__field-label"
            >
              Last Name
            </label>
            <input
              className="add-estimator__field-input"
              type="text"
              id="add-customer-name"
              onChange={(event) => setLastName(event.target.value)}
              value={lastName}
            />
          </div>

          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-phone"
              className="add-estimator__field-label"
            >
              Phone
            </label>

            <InputMask
              mask="(999) 999-9999 ext: 999999"
              onChange={(event) => setPhone(event.target.value)}
              value={phone}
            >
              <input
                className="add-estimator__field-input"
                type="text"
                id="add-customer-phone"
                placeholder="(---) --- ----"
              />
            </InputMask>
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-alt-phone"
              className="add-estimator__field-label"
            >
              Alt Phone
            </label>
            <InputMask
              mask="(999) 999-9999 ext: 999999"
              onChange={(event) => setAltPhone(event.target.value)}
              value={altPhone}
            >
              <input
                className="add-estimator__field-input"
                type="text"
                id="add-customer-alt-phone"
                placeholder="(---) --- ----"
              />
            </InputMask>
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-email"
              className="add-estimator__field-label"
            >
              Email Address
            </label>
            <input
              className={errorClassName(
                "add-estimator__field-input",
                errors,
                "isEmailValid"
              )}
              type="text"
              id="add-customer-email"
              onChange={(event) => {
                const email = event.target.value;
                setEmailErrors(email, "isEmailValid");
                setEmail(email);
              }}
              value={email}
            />
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-email"
              className="add-estimator__field-label"
            >
              Alt. Email Address
            </label>
            <input
              className={errorClassName(
                "add-estimator__field-input",
                errors,
                "isAltEmailValid"
              )}
              type="text"
              id="add-customer-email"
              onChange={(event) => {
                const email = event.target.value;
                setEmailErrors(email, "isAltEmailValid");
                setAltEmail(email);
              }}
              value={altEmail}
            />
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-company-name"
              className="add-estimator__field-label"
            >
              Company
            </label>
            <input
              className="add-estimator__field-input"
              type="text"
              id="add-company-name"
              onChange={(event) => setCompanyName(event.target.value)}
              value={companyName}
            />
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-email"
              className="add-estimator__field-label"
            >
              Type
            </label>
            <select
              className={errorClassName(
                "add-estimator__field-input",
                errors,
                "isCustomerTypeValid"
              )}
              id="add-customer-email"
              onChange={(event) => {
                setCustomerTypeErrors(event.target.value);
                setCustomerType(event.target.value);
              }}
              value={customerType}
            >
              <option value="">Select Type</option>
              <option value="Homeowner">Homeowner</option>
              <option value="Contractor">Contractor</option>
              {/* <option value="COMMERCIAL">Commercial</option> */}
            </select>
          </div>
          <div className="add-estimator__field">
            <label
              htmlFor="add-customer-email"
              className="add-estimator__field-label"
            >
              Representative
            </label>
            <select
              className="add-estimator__field-input"
              id="add-customer-email"
              onChange={(event) => {
                const id = event.target.value;
                const rep = estimators.get(id);

                setCustomerRep(id);
                dispatch({
                  type: "estimators/set",
                  estimator: rep,
                });
              }}
              value={customerRep}
            >
              <option key="none" value="">
                Select Rep
              </option>
              {estimators.entrySeq().map(([index, estimator]) => {
                return (
                  <option key={estimator.id} value={estimator.id}>
                    {estimator.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        {showBillTo && (
          <div className="add-estimator__fields add-customer__addresses">
            <div className="add-estimator__field add-address__field">
              <label
                htmlFor="add-customer-phone"
                className="add-estimator__field-label add-address__label"
              >
                Bill-to Address
              </label>
              <div className="add-address__inputs">
                <input
                  className="address-input"
                  placeholder="Name"
                  value={billTo.name}
                  onChange={(event) => {
                    setBillTo((addr: any) => ({
                      ...addr,
                      name: event.target.value,
                    }));

                    if (billToSameAsShipTo) {
                      setShipTo((addr: any) => ({
                        ...addr,
                        name: event.target.value,
                      }));
                    }
                  }}
                />
                <input
                  className="address-input"
                  placeholder="Note"
                  value={billTo.note}
                  onChange={(event) => {
                    setBillTo((addr: any) => ({
                      ...addr,
                      note: event.target.value,
                    }));

                    if (billToSameAsShipTo) {
                      setShipTo((addr: any) => ({
                        ...addr,
                        note: event.target.value,
                      }));
                    }
                  }}
                />
                <input
                  className="address-input"
                  placeholder="Street Address"
                  value={billTo.street}
                  onChange={(event) => {
                    setBillTo((addr: any) => ({
                      ...addr,
                      street: event.target.value,
                    }));

                    if (billToSameAsShipTo) {
                      setShipTo((addr: any) => ({
                        ...addr,
                        street: event.target.value,
                      }));
                    }
                  }}
                />
                <input
                  className="address-input"
                  placeholder="City"
                  value={billTo.city}
                  onChange={(event) => {
                    setBillTo((addr: any) => ({
                      ...addr,
                      city: event.target.value,
                    }));

                    if (billToSameAsShipTo) {
                      setShipTo((addr: any) => ({
                        ...addr,
                        city: event.target.value,
                      }));
                    }
                  }}
                />
                <div className="add-address__state-zip">
                  {(billTo.country === "US" || !billTo.country) && (
                    <USStateSelect
                      className="address-input"
                      placeholder="State"
                      value={billTo.state}
                      onChange={(event) => {
                        setBillTo((addr: any) => ({
                          ...addr,
                          state: event.target.value,
                        }));

                        if (billToSameAsShipTo) {
                          setShipTo((addr: any) => ({
                            ...addr,
                            state: event.target.value,
                          }));
                        }
                      }}
                    />
                  )}
                  {billTo.country === "CA" && (
                    <CAProvinceSelect
                      className="address-input"
                      placeholder="Province"
                      value={billTo.state}
                      onChange={(event) => {
                        setBillTo((addr: any) => ({
                          ...addr,
                          state: event.target.value,
                        }));

                        if (billToSameAsShipTo) {
                          setShipTo((addr: any) => ({
                            ...addr,
                            state: event.target.value,
                          }));
                        }
                      }}
                    />
                  )}
                  <input
                    className="address-input"
                    placeholder="Zip"
                    value={billTo.zip}
                    onChange={(event) => {
                      setBillTo((addr: any) => ({
                        ...addr,
                        zip: event.target.value,
                      }));

                      if (billToSameAsShipTo) {
                        setShipTo((addr: any) => ({
                          ...addr,
                          zip: event.target.value,
                        }));
                      }
                    }}
                  />
                </div>
                <CountrySelect
                  className="address-input"
                  value={billTo.country}
                  onChange={(event) => {
                    setBillTo((addr: any) => ({
                      ...addr,
                      country: event.target.value,
                    }));

                    if (billToSameAsShipTo) {
                      setShipTo((addr: any) => ({
                        ...addr,
                        country: event.target.value,
                      }));
                    }
                  }}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setShowBillTo(false);
                setBillTo({
                  name: "",
                  note: "",
                  street: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "US",
                });

                if (billToSameAsShipTo) {
                  setShipTo({
                    name: "",
                    note: "",
                    street: "",
                    city: "",
                    state: "",
                    zip: "",
                    country: "US",
                  });
                }
              }}
              className="address__remove"
            >
              <Icon className="estimators__add-icon" icon="minus" />
              <span className="estimators__add-text">
                Remove Bill-to Address
              </span>
            </button>
          </div>
        )}
        {showShipTo && (
          <div className="add-estimator__fields add-customer__addresses">
            <div className="add-estimator__field add-address__field">
              <label
                htmlFor="add-customer-phone"
                className="add-estimator__field-label add-address__label"
              >
                Ship-to Address
              </label>
              <div className="add-address__inputs">
                <input
                  className="address-input"
                  placeholder="Name"
                  value={shipTo.name}
                  onChange={(event) => {
                    if (billToSameAsShipTo) {
                      return;
                    }
                    setShipTo((addr: any) => ({
                      ...addr,
                      name: event.target.value,
                    }));
                  }}
                />
                <input
                  className="address-input"
                  placeholder="Note"
                  value={shipTo.note}
                  onChange={(event) => {
                    if (billToSameAsShipTo) {
                      return;
                    }
                    setShipTo((addr: any) => ({
                      ...addr,
                      note: event.target.value,
                    }));
                  }}
                />
                <input
                  className="address-input"
                  placeholder="Street Address"
                  value={shipTo.street}
                  onChange={(event) => {
                    if (billToSameAsShipTo) {
                      return;
                    }
                    setShipTo((addr: any) => ({
                      ...addr,
                      street: event.target.value,
                    }));
                  }}
                />
                <input
                  className="address-input"
                  placeholder="City"
                  value={shipTo.city}
                  onChange={(event) => {
                    if (billToSameAsShipTo) {
                      return;
                    }
                    setShipTo((addr: any) => ({
                      ...addr,
                      city: event.target.value,
                    }));
                  }}
                />
                <div className="add-address__state-zip">
                  {(shipTo.country === "US" || !shipTo.country) && (
                    <USStateSelect
                      className="address-input"
                      placeholder="State"
                      value={shipTo.state}
                      onChange={(event) => {
                        if (billToSameAsShipTo) {
                          return;
                        }
                        setShipTo((addr: any) => ({
                          ...addr,
                          state: event.target.value,
                        }));
                      }}
                    />
                  )}
                  {shipTo.country === "CA" && (
                    <CAProvinceSelect
                      className="address-input"
                      placeholder="Province"
                      value={shipTo.state}
                      onChange={(event) => {
                        if (billToSameAsShipTo) {
                          return;
                        }
                        setShipTo((addr: any) => ({
                          ...addr,
                          state: event.target.value,
                        }));
                      }}
                    />
                  )}
                  <input
                    className="address-input"
                    placeholder="Zip"
                    value={shipTo.zip}
                    onChange={(event) => {
                      if (billToSameAsShipTo) {
                        return;
                      }
                      setShipTo((addr: any) => ({
                        ...addr,
                        zip: event.target.value,
                      }));
                    }}
                  />
                </div>
                <CountrySelect
                  className="address-input"
                  value={shipTo.country}
                  onChange={(event) => {
                    if (billToSameAsShipTo) {
                      return;
                    }

                    setShipTo((addr: any) => ({
                      ...addr,
                      country: event.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <button
              onClick={() => {
                setShowShipTo(false);
                if (billToSameAsShipTo) {
                  return;
                }
                setShipTo({
                  name: "",
                  note: "",
                  street: "",
                  city: "",
                  state: "",
                  zip: "",
                  country: "US",
                });
              }}
              className="address__remove"
            >
              <Icon className="estimators__add-icon" icon="minus" />
              <span className="estimators__add-text">
                Remove Ship-to Address
              </span>
            </button>
          </div>
        )}
        <div className="add-estimator__fields add-customer__addresses">
          {!showBillTo && (
            <button
              onClick={() => {
                setShowBillTo(true);
              }}
              className="address__add-new"
            >
              <Icon className="estimators__add-icon" icon="plus" />
              <span className="estimators__add-text">Add Bill-to Address</span>
            </button>
          )}
          <div className="add-customer__ship-to-bill-to-same">
            <label>
              <input
                type="checkbox"
                onChange={(event) => {
                  setBillToSameAsShipTo(event.target.checked);

                  if (event.target.checked) {
                    setShipTo({ ...billTo });
                  }
                }}
              />
              <span>Ship To Same as Bill To</span>
            </label>
          </div>
          {!showShipTo && (
            <button
              onClick={() => {
                setShowShipTo(true);
              }}
              className="address__add-new"
            >
              <Icon className="estimators__add-icon" icon="plus" />
              <span className="estimators__add-text">Add Ship-to Address</span>
            </button>
          )}
        </div>

        {altContacts?.map((contact, index) => {
          return (
            <div className="add-estimator__fields add-alternate__contact">
              <div className="add-estimator__field">
                <label
                  htmlFor="add-alt-contact-name"
                  className="add-estimator__field-label"
                >
                  Full Name
                </label>
                <input
                  className="add-estimator__field-input"
                  type="text"
                  id="add-alt-contact-name"
                  onChange={(event) => {
                    const name = event.target.value;
                    setAltContacts((contacts) => {
                      const newContacts = [...contacts];
                      newContacts[index].name = name;
                      return newContacts;
                    });
                  }}
                  value={contact.name}
                />
              </div>
              <div className="add-estimator__field">
                <label
                  htmlFor="add-alt-contact-phone"
                  className="add-estimator__field-label"
                >
                  Phone
                </label>

                <InputMask
                  mask="(999) 999-9999"
                  onChange={(event) => {
                    const phone = event.target.value;
                    setAltContacts((contacts) => {
                      const newContacts = [...contacts];
                      newContacts[index].phone = phone;
                      return newContacts;
                    });
                  }}
                  value={contact.phone}
                >
                  <input
                    className="add-estimator__field-input"
                    type="text"
                    id="add-alt-contact-phone"
                    placeholder="(---) --- ----"
                  />
                </InputMask>
              </div>

              <div className="add-estimator__field">
                <label
                  htmlFor="add-alt-contact-email"
                  className="add-estimator__field-label"
                >
                  Email Address
                </label>
                <input
                  className={errorClassName(
                    "add-estimator__field-input",
                    errors,
                    `altContactEmailValid${contact.id}`
                  )}
                  type="text"
                  id="add-alt-contact-email"
                  onChange={(event) => {
                    const email = event.target.value;
                    setEmailErrors(email, `altContactEmailValid${contact.id}`);
                    setAltContacts((contacts) => {
                      const newContacts = [...contacts];
                      newContacts[index].email = email;
                      return newContacts;
                    });
                  }}
                  value={contact.email}
                />
              </div>

              <div className="add-estimator__field">
                <label
                  htmlFor="add-alt-contact-note"
                  className="add-estimator__field-label"
                >
                  Note
                </label>
                <input
                  className={"add-estimator__field-input"}
                  type="text"
                  id="add-alt-contact-note"
                  onChange={(event) => {
                    const note = event.target.value;
                    setAltContacts((contacts) => {
                      const newContacts = [...contacts];
                      newContacts[index].note = note;
                      return newContacts;
                    });
                  }}
                  value={contact.note}
                />
              </div>

              <button
                onClick={() => {
                  setAltContacts((contacts) => {
                    const newContacts = [...contacts];
                    newContacts.splice(index, 1);
                    return newContacts;
                  });
                }}
                className="address__remove"
              >
                <Icon className="estimators__add-icon" icon="minus" />
                <span className="estimators__add-text">Remove Alt Contact</span>
              </button>
            </div>
          );
        })}

        <div className="add-estimator__fields add-customer__addresses">
          <button
            onClick={() => {
              const newContact = {
                id: uuid(),
                email: "",
                name: "",
                phone: "",
                note: "",
              };
              setAltContacts((contacts) => {
                return [...contacts, newContact];
              });
              setErrors((errors) => {
                return {
                  ...errors,
                  [`altContactEmailValid${newContact.id}`]: true,
                };
              });
            }}
            className="address__remove"
          >
            <Icon className="estimators__add-icon" icon="plus" />
            <span className="estimators__add-text">Add Alternate Contact</span>
          </button>
        </div>
      </div>
      <div className="add-estimator__footer">
        <button
          onClick={() => {
            const customerTypeValid = setCustomerTypeErrors(customerType);
            const emailValid = setEmailErrors(email, "isEmailValid");
            const altContactsEmailValid = altContacts
              .map((contact) =>
                setEmailErrors(
                  contact.email,
                  `altContactEmailValid${contact.id}`
                )
              )
              .reduce((acc, current) => acc && current, true);

            let valid =
              customerTypeValid && emailValid && altContactsEmailValid;

            if (!valid) {
              return;
            }

            const data = {
              firstName: firstName,
              lastName: lastName,
              phone: phone,
              altPhone: altPhone,
              email: email,
              altEmail: altEmail,
              billing: billTo,
              shipping: shipTo,
              customerRep: customerRep,
              customerType: customerType,
              companyName: companyName,
              ...(altContacts.length ? { altContacts: altContacts } : {}),
            };

            let newCustomer: Customer;

            if (customer) {
              newCustomer = customer
                .set("data", data)
                .set("exportedToQB", customer.exportedToQB);
            } else {
              newCustomer = Customer({
                id: uuid(),
                data: data,
                exportedToQB: false,
                // Bumping to version 2. Version 1 uses original naming scheme which used customer.name or customer.data.name.
                version: "2",
              });
            }

            updateCustomer(newCustomer, "customers/add");

            dispatch({
              type: "customer/select",
              checked: false,
              customer: newCustomer,
            });
            dispatch({ type: "tax/reset" });
            setAddNewWindow(false);
            setOpen(false);
          }}
          className="add-estimator__footer-button button button--primary"
        >
          {addCustomerText}
        </button>
      </div>
    </div>
  );
};

export default AddCustomerWindow;
