import { Map } from "immutable";

const requiredFieldsReducerState = Map<string, boolean>();

export type RequiredFieldsReducerState = typeof requiredFieldsReducerState;
export type RequiredFieldsReducerAction =
  | { type: "fields/require-saltwater" }
  | { type: "fields/require-poolwater" }
  | { type: "fields/require-field"; field: string }
  | { type: "fields/unrequire-field"; field: string }
  | { type: "customer/select"; checked: boolean }
  | { type: "estimators/set" }
  | { type: "estimators/select"; checked: boolean };

export function requiredFieldsReducer(
  state: RequiredFieldsReducerState = requiredFieldsReducerState,
  action: RequiredFieldsReducerAction
): RequiredFieldsReducerState {
  switch (action.type) {
    case "fields/require-saltwater":
      return state.set("nearSaltwater", true);
    case "fields/require-poolwater":
      return state.set("nearPoolwater", true);
    case "fields/require-field":
      return state.set(action.field, true);
    case "fields/unrequire-field":
      return state.delete(action.field);
    case "customer/select":
      if (action.checked) {
        return state;
      } else {
        return state.delete("customer");
      }
    case "estimators/set":
      return state.delete("estimator");
    case "estimators/select":
      if (action.checked) {
        return state;
      } else {
        return state.delete("estimator");
      }
    default:
      return state;
  }
}
