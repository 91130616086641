import { Customer } from "../../entities";

const customerReducerState = Customer();

export type CustomerReducerState = typeof customerReducerState;
export type CustomerReducerAction = {
  type: "customer/select";
  customer: Customer;
  checked: boolean;
};

export function customerReducer(
  state: CustomerReducerState = Customer(),
  action: CustomerReducerAction
): CustomerReducerState {
  switch (action.type) {
    case "customer/select":
      if (action.checked) {
        // Unset customer.
        return Customer();
      } else {
        return action.customer;
      }
    default:
      return state;
  }
}
