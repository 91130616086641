// @ts-nocheck
import { Component, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ActionCreators } from "redux-undo";
import Sketch from "react-p5";
import { v4 as uuid } from "uuid";
import { List, Map, OrderedMap, Set } from "immutable";

import {
  drawStairs,
  drawCompletedStairs,
  drawLanding,
  drawShape,
  drawPerfectShape,
  drawGate,
  drawGateRun,
  drawHandrail,
  drawPost,
  drawStairsPost,
  drawRun,
  drawStraightRun,
  drawStairsRun,
  drawImage,
  drawNote,
  drawRunConnectedToStairs,
  drawHandrailAttached,
} from "../draw";

import { calculateCorners } from "../utils/corners";
import {
  Run,
  Post,
  Shape,
  Gate,
  Note,
  Stairs,
  Handrail,
  Landing,
  Image,
  Point,
  Corner,
} from "../entities";

import {
  isClickedInElement,
  isNearPoint,
  isClickedInElements,
  shapeIntersection,
  runIntersection,
  gateIntersection,
  stairsIntersection,
  stairsCornerIntersection,
  imageIntersection,
  nearestStairCornerForRun,
  findNearestStairCorner,
  handrailIntersection,
  roundToHundreth,
  getNormalDistance,
  getClosestPointOnLine,
  snapDistanceThreshold,
  textIntersection,
  findVerticesOfStairs,
  getStairCornersForSnapLines,
  cornerHash,
  getCornersWithFlips,
  getHandrailsWithRunsAndCorners,
  getHandrailsWithFlipsCalculated,
  getRunsWithFlipsCalculated,
  generateHandrailAnchorPoints,
  getDistance,
  getUnrotatedStairsCorners,
  getRotatedStairsCorners,
  distanceBetweenPoints,
  isCloseToValue,
  isNumber,
  isPointBetweenPoints,
} from "../utils";

import {
  shapeHasCorners,
  calculateShapeCorners,
  getShapeChain,
} from "../utils/shapeCorners";
import {
  Edge,
  getAllGraphs,
  getConnectedElements,
  getConnectedRuns,
  getGatePoints,
  getNodesOfGraph,
  getPostsFactoringInCorners,
  handleMoveTransformsOfGraph,
  nodesToObject,
  sortGraphOfRuns,
  walkAllGraphsWithStepFunction,
} from "../utils/graph";
import { getCanvasBox } from "./ExportPDF/RenderCanvas";
import { isFileImage } from "./SingleProjectView";
import { serviceUrl } from "../environment";
import { getPosts } from "../utils/getPosts";
import { isProjectFrozen } from "../utils/freezeProject";
import { getRunIndex } from "../utils/canvas";

export function CanvasContainer(props) {
  const { tool, setTool, shape, stairsTool } = props;
  const state = useSelector((state) => state.state);
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;
  const canvas = state.present.canvases.get(state.present.currentCanvas);
  const dispatch = useDispatch();

  return (
    <Canvas
      dispatch={dispatch}
      runs={state.present.runs}
      tool={tool}
      setTool={setTool}
      stairsTool={stairsTool}
      pan={canvas.pan}
      scale={canvas.scale}
      shape={shape}
      shapes={state.present.shapes}
      gates={state.present.gates}
      images={state.present.images}
      notes={state.present.notes}
      stairs={state.present.stairs}
      posts={state.present.posts}
      edit={state.present.edit}
      state={state.present}
      canvas={canvas}
      htmlId="3d-canvas"
      className="main-canvas"
      renderResolve={null}
      settings={state.present.settings}
      currentProject={currentProject}
      handrails={state.present.handrails}
    />
  );
}

export class Canvas extends Component {
  runs = [];
  shapes = [];
  currentPoint = { x: 0, y: 0 };
  mousePoint = { x: 0, y: 0 };
  pan = { x: this.props.pan.x, y: this.props.pan.y };
  currentPan = { x: 0, y: 0 };
  isDrawing = false;
  isShiftDown = false;
  isControlDown = false;
  p5 = null;
  images = {};
  font = null;
  panStartPoint = { x: 0, y: 0 };
  moveStartPoint = { x: 0, y: 0 };
  movingObject = null;
  movingObjectType = null;
  isMovingObject = false;
  movingObjects = null;
  movingObjectsType = null;
  originalMovingRun = null;
  originalMovingShape = null;
  isMovingObjects = false;
  rotatingObject = null;
  rotatingObjectType = null;
  isRotatingObject = false;
  hoveringObject = null;
  hoveringImage = null;
  hoveringGrabrailPoint = null;
  grabrailAnchorPoint = null;
  scalingObject = null;
  isScalingObject = false;
  scale = this.props.scale || 1;
  rawMousePoint = { x: 0, y: 0 };
  snapLine = null;
  imagesLoaded = [];

  // Stairs Snapping to Other Stair Like Elements
  hoveringStairsSnap = null;
  currentStairsSnap = null;
  stairsAlignmentLine = null;

  stairsRun = null;
  hoveringStairsCorner = null;
  stairCornerIntersections = null;
  landingSnaps = null;
  isDrawingStairsRun = false;
  isSnappedToStairs = false;
  wheelTimeoutId = null;

  // Handrail Calculation Variables
  corners = [];
  cornersWithFlips = [];
  allGraphs = Map();
  calculatedFlips = Map();
  calculatedRunFlips = Map();

  // Resize Observer
  windowObserver = null;

  constructor(props) {
    super(props);
    this.state = {};
    this.handleWheel = this.handleWheel.bind(this);
    this.handleImagePaste = this.handleImagePaste.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.isProjectFrozen = this.isProjectFrozen.bind(this);
    this.mount = createRef();

    const corners = calculateCorners(this.props.runs);

    this.corners = corners;

    const cornersWithFlips = getCornersWithFlips(corners);

    const allGraphs = getAllGraphs({
      runs: this.props.runs,
      corners: corners,
      gates: this.props.gates,
      handrails: this.props.handrails,
      posts: this.props.posts,
      stairs: this.props.stairs,
    });

    const handrailsWithFlips = getHandrailsWithRunsAndCorners(
      this.props.handrails,
      this.props.runs,
      corners
    );

    const calculatedFlips = getHandrailsWithFlipsCalculated(
      allGraphs.filter((node) => node.type === "handrail"),
      handrailsWithFlips
    );

    const calculatedRunFlips = getRunsWithFlipsCalculated(
      this.props.runs,
      corners,
      allGraphs.filter((node) => node.type === "run")
    );

    this.allGraphs = allGraphs;
    this.calculatedFlips = calculatedFlips;
    this.cornersWithFlips = cornersWithFlips;
    this.calculatedRunFlips = calculatedRunFlips;
  }

  handleImageUpload(file, event) {
    if (!file || !event) {
      return;
    }

    let formData = new FormData();

    if (isFileImage(file)) {
      const hashProcess = new Promise((resolve, reject) => {
        var fileItem = file.getAsFile();
        var reader = new FileReader();

        reader.onload = function () {
          var data = reader.result;
          var digestAlgorithm = { name: "SHA-256" };

          crypto.subtle
            .digest(digestAlgorithm, data)
            .then(function (hash) {
              var hashArray = Array.from(new Uint8Array(hash));
              var hashHex = hashArray
                .map(function (b) {
                  return ("00" + b.toString(16)).slice(-2);
                })
                .join("");

              resolve({ hash: hashHex, file: fileItem });
            })
            .catch((err) => {
              reject(err);
            });
        };
        reader.readAsArrayBuffer(fileItem);
      });

      hashProcess
        .then((data) => {
          formData.append("imageUpload", data.file);
          formData.append("hash", data.hash);

          fetch(serviceUrl("upload"), {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response && response.ok) {
                return response.json();
              } else {
                throw new Error("Network error while uploading image.");
              }
            })
            .catch((error) => {
              this.props.dispatch({
                type: "window/error-message-open",
                message: "Network error while uploading image.",
              });
            })
            .then((data) => {
              if (!data || !data.width || !data.height) {
                return;
              }

              const windowWidth = window.innerWidth;
              const windowHeight = window.innerHeight;

              let width = data.width;
              let height = data.height;

              const ratio = width / height;

              if (height > windowHeight) {
                height = windowHeight;
                width = height * ratio;
              }

              if (width > windowWidth) {
                width = windowWidth;
                height = width / ratio;
              }

              let imageScale = height / data.height;

              this.props.dispatch({
                type: "images/add",
                image: Image({
                  id: uuid(),
                  url: data.upload,
                  point: Point({ ...this.mousePoint }),
                  originalWidth: data.width,
                  originalHeight: data.height,
                  width: width,
                  height: height,
                  scale: imageScale,
                }),
              });
            });
        })
        .catch((err) => {
          this.props.dispatch({
            type: "window/error-message-open",
            message: "Image upload failed to generate.",
          });
        });
    } else {
      this.props.dispatch({
        type: "window/error-message-open",
        message: "Only supports image files. Try a JPG, PNG, GIF, WebP etc.",
      });
    }
  }

  handleImagePaste(event) {
    if (document.activeElement && document.activeElement.tagName !== "BODY") {
      return;
    }
    event.preventDefault();

    const items = event.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      if (isFileImage(items[i])) {
        const file = items[i];

        this.handleImageUpload(file, event);
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    let update = false;

    if (
      !this.props.runs.equals(nextProps.runs) ||
      !this.props.gates.equals(nextProps.gates) ||
      !this.props.handrails.equals(nextProps.handrails) ||
      !this.props.images.equals(nextProps.images) ||
      !this.props.notes.equals(nextProps.notes) ||
      !this.props.stairs.equals(nextProps.stairs) ||
      !this.props.posts.equals(nextProps.posts) ||
      !this.props.shapes.equals(nextProps.shapes) ||
      !this.props.settings.equals(nextProps.settings) ||
      this.props.tool !== nextProps.tool
    ) {
      update = true;
      return update;
    }

    if (!this.props.canvas.equals(nextProps.canvas)) {
      if (
        !this.props.canvas.runs.equals(nextProps.canvas.runs) ||
        !this.props.canvas.gates.equals(nextProps.canvas.gates) ||
        !this.props.canvas.handrails.equals(nextProps.canvas.handrails) ||
        !this.props.canvas.images.equals(nextProps.canvas.images) ||
        !this.props.canvas.notes.equals(nextProps.canvas.notes) ||
        !this.props.canvas.stairs.equals(nextProps.canvas.stairs) ||
        !this.props.canvas.posts.equals(nextProps.canvas.posts) ||
        !this.props.canvas.shapes.equals(nextProps.canvas.shapes) ||
        !this.props.canvas.labels.runs === nextProps.canvas.labels.runs
      ) {
        update = true;
        return update;
      }
    }

    return update;
  }

  componentDidUpdate(prevProps) {
    const corners = calculateCorners(this.props.runs);

    this.corners = corners;

    const cornersWithFlips = getCornersWithFlips(corners);

    const allGraphs = getAllGraphs({
      runs: this.props.runs,
      corners: corners,
      gates: this.props.gates,
      handrails: this.props.handrails,
      posts: this.props.posts,
      stairs: this.props.stairs,
    });

    const handrailsWithFlips = getHandrailsWithRunsAndCorners(
      this.props.handrails,
      this.props.runs,
      corners
    );

    const calculatedFlips = getHandrailsWithFlipsCalculated(
      allGraphs.filter((node) => node.type === "handrail"),
      handrailsWithFlips
    );

    const calculatedRunFlips = getRunsWithFlipsCalculated(
      this.props.runs,
      corners,
      allGraphs.filter((node) => node.type === "run")
    );

    this.allGraphs = allGraphs;
    this.calculatedFlips = calculatedFlips;
    this.cornersWithFlips = cornersWithFlips;
    this.calculatedRunFlips = calculatedRunFlips;

    this.currentStairsSnap = null;

    if (prevProps.tool !== this.props.tool) {
      this.snapLine = null;
    }

    if (
      this.p5 &&
      this.props.images.size &&
      prevProps.images.size !== this.props.images.size
    ) {
      this.props.images.forEach((item, i) => {
        const image = new window.Image();

        image.onload = function () {
          this.images[item.url] = image;
        }.bind(this);

        if (image.complete) {
          this.images[item.url] = image;
        }

        image.src = item.url;
      });
    }
  }

  // Screen To world.
  calculatePointWithPanScale = (event) => {
    return {
      x: (event.mouseX - this.pan.x) / this.scale,
      y: (event.mouseY - this.pan.y) / this.scale,
    };
  };

  clickedInUI = (mousePoint) => {
    if (mousePoint.x < 60) {
      return true;
    }

    if (isClickedInElement(".app__note", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".app__tooltip", mousePoint)) {
      // If clicking on tooltip ignore click event.
      return true;
    }

    // If clicking on shapes selection ignore click event.
    if (isClickedInElement(".shapes__selection", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".stairs__selection", mousePoint)) {
      return true;
    }

    // If clicking on edit popup ignore click event.
    if (isClickedInElement(".edit-popup", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".project-settings", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".app__details", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".revisions-description__container", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".app__options", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".revisions-menu", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".app__canvas-manager", mousePoint)) {
      return true;
    }

    if (isClickedInElements(".canvas-menu", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".canvas-menu__button", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".rename-canvas-menu", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".add-hardware", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".add-hardware__list", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".shipping-estimate", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".error-message", mousePoint)) {
      return true;
    }

    if (isClickedInElement(".estimators__add-estimator", mousePoint)) {
      return true;
    }

    return false;
  };

  isProjectFrozen = () => {
    if (this.props.tool && isProjectFrozen(this.props.state)) {
      // Project Frozen. Do not modify the canvas.
      this.props.dispatch({
        type: "window/open-project-frozen-dialog",
      });

      return true;
    }

    return false;
  };

  mouseReleased = (event) => {
    this.isMouseDown = false;

    if (this.clickedInUI({ x: event.mouseX, y: event.mouseY })) {
      return;
    }

    if (this.isProjectFrozen()) {
      return;
    }

    if (this.props.tool === "pan") {
      this.currentPan.x = event.mouseX - this.panStartPoint.x;
      this.currentPan.y = event.mouseY - this.panStartPoint.y;

      this.pan.x += this.currentPan.x;
      this.pan.y += this.currentPan.y;

      this.currentPan = { x: 0, y: 0 };

      clearTimeout(this.wheelTimeoutId);
      this.wheelTimeoutId = setTimeout(() => {
        this.props.dispatch({
          type: "canvas/set-pan-scale",
          pan: this.pan,
          scale: this.scale,
        });
      }, 500);
    }

    if (this.isMovingObject) {
      switch (this.movingObjectType) {
        case "stairs":
          this.props.dispatch({
            type: "stairs/edit",
            stairs: this.movingObject,
          });
          break;
        case "post":
          this.props.dispatch({
            type: "posts/edit",
            post: this.movingObject,
          });
          break;
        case "shape":
          this.props.dispatch({
            type: "shapes/edit",
            shape: this.movingObject,
          });
          break;
        case "image":
          this.props.dispatch({
            type: "images/edit",
            image: this.movingObject,
          });
          break;
        case "runs":
          this.props.dispatch({
            type: "runs/edit-move",
            run: this.movingObject,
          });
          break;
        case "run-drag":
          this.props.dispatch({ type: "runs/edit", run: this.movingObject });
          break;
        case "note-drag":
          this.props.dispatch({
            type: "notes/edit",
            note: this.movingObject,
            index: this.movingObject.id,
          });
          break;
        case "shape-drag":
          this.props.dispatch({
            type: "shapes/edit",
            shape: this.movingObject,
          });
          break;
        default:
          break;
      }

      this.movingObject = null;
      this.movingObjectType = null;
      this.isMovingObject = false;
    }

    if (this.isMovingObjects) {
      switch (this.movingObjectsType) {
        case "drag-all":
          const newEntities = nodesToObject(
            this.movingObjects,
            this.props.runs,
            this.props.gates,
            this.props.stairs,
            this.props.posts,
            this.props.handrails
          );
          this.props.dispatch({
            type: "canvas/edit-entities",
            posts: newEntities.posts,
            runs: newEntities.runs,
            gates: newEntities.gates,
            stairs: newEntities.stairs,
            handrails: newEntities.handrails,
          });
          break;
        case "runs-drag":
          this.movingObjects.forEach((runObject) => {
            this.props.dispatch({ type: "runs/edit", run: runObject });
          });
          break;
        case "shapes-drag":
          this.movingObjects.forEach((shapeObject) => {
            this.props.dispatch({ type: "shapes/edit", shape: shapeObject });
          });
          break;
        default:
          break;
      }

      this.movingObjects = null;
      this.movingObjectsType = null;
      this.isMovingObjects = false;
    }

    if (this.isScalingObject) {
      this.props.dispatch({
        type: "images/edit",
        image: this.scalingObject,
      });

      this.isScalingObject = false;
      this.scalingObject = null;
    }

    if (this.isRotatingObject) {
      switch (this.rotatingObjectType) {
        case "stairs":
          this.props.dispatch({
            type: "stairs/edit",
            stairs: this.rotatingObject,
          });
          break;
        default:
          break;
      }

      this.rotatingObject = null;
      this.rotatingObjectType = null;
      this.isRotatingObject = false;
    }
  };

  mouseClicked = (event) => {
    // If clicking sidebar ignore.
    if (event.mouseX < 60) {
      return;
    }

    if (this.clickedInUI({ x: event.mouseX, y: event.mouseY })) {
      return;
    }

    if (this.isProjectFrozen()) {
      return;
    }

    if (this.props.tool === "run") {
      if (!this.isDrawing) {
        this.currentPoint = this.calculatePointWithPanScale(event);

        if (this.snapLine && !this.isControlDown) {
          this.currentPoint = getClosestPointOnLine(
            { x: this.snapLine.x1, y: this.snapLine.y1 },
            { x: this.snapLine.x2, y: this.snapLine.y2 },
            this.mousePoint
          );

          if (this.snapLine.type && this.snapLine.type === "stairs") {
            this.isSnappedToStairs = { ...this.snapLine };
          }
        }
        this.isDrawing = true;

        const selectedStairs = this.props.stairs
          .filter((stairs) => stairs.type === "stairs")
          .map((stairs) =>
            stairsCornerIntersection(stairs, this.mousePoint, 15)
          );

        const stairsIndex = selectedStairs.findKey((selected) => selected);

        if (stairsIndex) {
          const stairs = this.props.stairs.get(stairsIndex);
          const cornerPoint = nearestStairCornerForRun(stairs, this.mousePoint);

          if (!this.isControlDown) {
            this.currentPoint.x = cornerPoint.x;
            this.currentPoint.y = cornerPoint.y;
          }
        }

        if (this.hoveringObject) {
          this.currentPoint.x = this.hoveringObject.point.x;
          this.currentPoint.y = this.hoveringObject.point.y;
        }

        if (this.isSnappedToStairs) {
          const stairs = this.props.stairs.get(this.isSnappedToStairs.id);

          const cornerPoints = findNearestStairCorner(
            stairs,
            this.mousePoint,
            this.snapLine
          );
          const snapLineCorners = getStairCornersForSnapLines(stairs);

          this.stairsRun = {
            startPost: cornerPoints.corners[cornerPoints.keys.start],
            stairsIndex: this.isSnappedToStairs.id,
            endPost: cornerPoints.corners[cornerPoints.keys.end],
            snapped: this.isSnappedToStairs,
            snapLineCorners: snapLineCorners,
            keys: cornerPoints.keys,
          };

          this.stairsRun.continuousStairs = getContinuousSpanOfStairs(
            this.props.stairs,
            this.stairsRun.stairsIndex,
            this.stairsRun
          );
        }
      } else {
        // If mouse position is the same as it was.
        if (
          this.currentPoint.x === event.mouseX &&
          this.currentPoint.y === event.mouseY
        ) {
          return;
        }

        const dx = Math.abs(this.mousePoint.x - this.currentPoint.x);
        const dy = Math.abs(this.mousePoint.y - this.currentPoint.y);

        let theRun = Run();

        if (!this.isShiftDown) {
          // If shift is not down draw normally.
          theRun = Run({
            x1: Math.round(this.currentPoint.x),
            y1: Math.round(this.currentPoint.y),
            x2: Math.round(this.mousePoint.x),
            y2: Math.round(this.mousePoint.y),
          });
        } else {
          if (dx > dy) {
            // Push horizontal line.
            theRun = Run({
              x1: Math.round(this.currentPoint.x),
              y1: Math.round(this.currentPoint.y),
              x2: Math.round(this.mousePoint.x),
              y2: Math.round(this.currentPoint.y),
            });
          } else {
            // Push vertical line.
            theRun = Run({
              x1: Math.round(this.currentPoint.x),
              y1: Math.round(this.currentPoint.y),
              x2: Math.round(this.currentPoint.x),
              y2: Math.round(this.mousePoint.y),
            });
          }
        }

        const id = uuid();

        theRun = theRun.set("id", id);

        if (this.isSnappedToStairs) {
          const closestPoint = getClosestPointOnLine(
            { x: this.isSnappedToStairs.x1, y: this.isSnappedToStairs.y1 },
            { x: this.isSnappedToStairs.x2, y: this.isSnappedToStairs.y2 },
            { x: theRun.x2, y: theRun.y2 }
          );

          theRun = theRun.set("x2", Math.round(closestPoint.x));
          theRun = theRun.set("y2", Math.round(closestPoint.y));
        }

        if (
          (this.isDrawingStairsRun || this.isSnappedToStairs) &&
          this.stairsRun
        ) {
          theRun = theRun.set("stairs", { ...this.stairsRun });

          theRun.stairs.continousStairs = Map(
            theRun.stairs.continousStairs
          ).map((stair) => Stairs(stair));
        }

        // Handle snap line.
        if (this.snapLine && !this.isControlDown) {
          const closestPoint = getClosestPointOnLine(
            { x: this.snapLine.x1, y: this.snapLine.y1 },
            { x: this.snapLine.x2, y: this.snapLine.y2 },
            { x: theRun.x2, y: theRun.y2 }
          );

          theRun = theRun.set("x2", Math.round(closestPoint.x));
          theRun = theRun.set("y2", Math.round(closestPoint.y));
        }

        // Handle connecting a run onto the end of an existing run.
        if (
          this.hoveringObject &&
          this.hoveringObject.run &&
          !this.isControlDown
        ) {
          const point = this.hoveringObject.point;
          theRun = theRun.set("x2", point.x);
          theRun = theRun.set("y2", point.y);

          if (this.isShiftDown) {
            const dx = Math.abs(theRun.x2 - theRun.x1);
            const dy = Math.abs(theRun.y2 - theRun.y1);

            if (dx > dy) {
              // If horizontal match y value of endpoint.
              theRun = theRun.set("y1", point.y);
            } else {
              // If vertical match x value of endpoint.
              theRun = theRun.set("x1", point.x);
            }
          }

          this.isDrawing = false;
        }

        if (
          Math.abs(theRun.x2 - theRun.x1) < 1 &&
          Math.abs(theRun.y2 - theRun.y1) < 1
        ) {
          // Exit early if the run does not have a significant distance.
          return;
        }

        if (
          isNumber(theRun.x1) &&
          isNumber(theRun.x2) &&
          isNumber(theRun.y1) &&
          isNumber(theRun.y2)
        ) {
          this.props.dispatch({
            type: "runs/add",
            run: theRun,
            id: id,
            currentProject: this.props.currentProject,
          });
        }

        if (
          this.snapLine &&
          !this.isControlDown &&
          this.snapLine.x1 !== this.isSnappedToStairs.x1 &&
          this.snapLine.y1 !== this.isSnappedToStairs.y1 &&
          this.snapLine.x2 !== this.isSnappedToStairs.x2 &&
          this.snapLine.y2 !== this.isSnappedToStairs.y2
        ) {
          if (this.snapLine.type && this.snapLine.type === "stairs") {
            this.currentPoint.x = theRun.x2;
            this.currentPoint.y = theRun.y2;
            this.isSnappedToStairs = this.snapLine;

            const stairs = this.props.stairs.get(this.isSnappedToStairs.id);

            const cornerPoints = findNearestStairCorner(
              stairs,
              this.mousePoint,
              this.snapLine
            );
            const snapLineCorners = getStairCornersForSnapLines(stairs);

            this.stairsRun = {
              startPost: cornerPoints.corners[cornerPoints.keys.start],
              stairsIndex: this.isSnappedToStairs.id,
              endPost: cornerPoints.corners[cornerPoints.keys.end],
              snapped: this.isSnappedToStairs,
              snapLineCorners: snapLineCorners,
              keys: cornerPoints.keys,
            };

            this.stairsRun.continuousStairs = getContinuousSpanOfStairs(
              this.props.stairs,
              this.stairsRun.stairsIndex,
              this.stairsRun
            );

            this.isDrawingStairsRun = false;

            return;
          }
        }

        // If shift is not down update both x and y coordinates.
        if (!this.isShiftDown) {
          this.currentPoint.x = this.mousePoint.x;
          this.currentPoint.y = this.mousePoint.y;
        } else {
          if (dx > dy) {
            // Only update horizontal position.
            this.currentPoint.x = this.mousePoint.x;
          } else {
            // Only update vertical position.
            this.currentPoint.y = this.mousePoint.y;
          }
        }

        this.currentPoint.x = theRun.x2;
        this.currentPoint.y = theRun.y2;

        this.isDrawingStairsRun = false;
        this.stairsRun = null;
        this.isSnappedToStairs = false;
      }
    }

    if (this.props.tool === "shapes") {
      if (!this.isDrawing) {
        this.currentPoint = this.calculatePointWithPanScale(event);

        if (this.snapLine && !this.isControlDown) {
          this.currentPoint = getClosestPointOnLine(
            { x: this.snapLine.x1, y: this.snapLine.y1 },
            { x: this.snapLine.x2, y: this.snapLine.y2 },
            this.mousePoint
          );
        }

        // Toggle drawing On.
        this.isDrawing = true;
      } else {
        // If mouse position is the same as it was do not draw anything return early.
        if (
          this.currentPoint.x === event.mouseX &&
          this.currentPoint.y === event.mouseY
        ) {
          return;
        }

        const dx = Math.abs(this.mousePoint.x - this.currentPoint.x);
        const dy = Math.abs(this.mousePoint.y - this.currentPoint.y);

        const id = uuid();

        let theShape = Shape();

        if (!this.isShiftDown) {
          theShape = Shape({
            id: id,
            type: this.props.shape,
            x1: this.currentPoint.x,
            y1: this.currentPoint.y,
            x2: this.mousePoint.x,
            y2: this.mousePoint.y,
          });
        } else {
          if (dx > dy) {
            theShape = Shape({
              id: id,
              type: this.props.shape,
              x1: this.currentPoint.x,
              y1: this.currentPoint.y,
              x2: this.mousePoint.x,
              y2: this.currentPoint.y,
            });
          } else {
            theShape = Shape({
              id: id,
              type: this.props.shape,
              x1: this.currentPoint.x,
              y1: this.currentPoint.y,
              x2: this.currentPoint.x,
              y2: this.mousePoint.y,
            });
          }
        }

        if (this.snapLine && !this.isControlDown) {
          const closestPoint = getClosestPointOnLine(
            { x: this.snapLine.x1, y: this.snapLine.y1 },
            { x: this.snapLine.x2, y: this.snapLine.y2 },
            { x: theShape.x2, y: theShape.y2 }
          );

          theShape = theShape.set("x2", closestPoint.x);
          theShape = theShape.set("y2", closestPoint.y);
        }

        this.props.dispatch({
          type: "shapes/add",
          shape: theShape,
          id: this.props.currentProject,
        });

        // Toggle drawing off after shape is drawn.
        if (this.props.shape === "line") {
          // If shift is not down update both x and y coordinates.
          if (!this.isShiftDown) {
            this.currentPoint.x = this.mousePoint.x;
            this.currentPoint.y = this.mousePoint.y;
          } else {
            if (dx > dy) {
              // Only update horizontal position.
              this.currentPoint.x = this.mousePoint.x;
            } else {
              // Only update vertical position.
              this.currentPoint.y = this.mousePoint.y;
            }
          }

          this.currentPoint.x = theShape.x2;
          this.currentPoint.y = theShape.y2;
        } else {
          this.isDrawing = false;
        }
      }
    }

    if (this.props.tool === "stairs") {
      if (!this.isDrawing) {
        this.currentPoint = this.calculatePointWithPanScale(event);

        // Handle stair snapping.
        if (this.props.stairs.size) {
          if (!this.isControlDown) {
            if (this.hoveringStairCorners) {
              this.hoveringStairCorners.forEach((corner) => {
                this.currentPoint.x = corner.nearPoint.point[0];
                this.currentPoint.y = corner.nearPoint.point[1];
              });

              this.currentStairsSnap = this.hoveringStairCorners;
            } else {
              if (this.stairsAlignmentLine) {
                const closestPoint = getClosestPointOnLine(
                  {
                    x: this.stairsAlignmentLine.x1,
                    y: this.stairsAlignmentLine.y1,
                  },
                  {
                    x: this.stairsAlignmentLine.x2,
                    y: this.stairsAlignmentLine.y2,
                  },
                  { x: this.mousePoint.x, y: this.mousePoint.y }
                );

                this.currentPoint.x = closestPoint.x;
                this.currentPoint.y = closestPoint.y;

                this.stairsAlignmentLine = null;
              }
            }
          }
        }

        // Toggle drawing On.
        this.isDrawing = true;
      } else {
        // If mouse position is the same as it was do not draw anything return early.
        if (
          this.currentPoint.x === event.mouseX &&
          this.currentPoint.y === event.mouseY
        ) {
          return;
        }

        let theStairs = null;

        if (this.props.stairsTool === "stairs") {
          theStairs = Stairs({
            id: uuid(),
            x1: this.currentPoint.x,
            y1: this.currentPoint.y,
            x2: this.mousePoint.x,
            y2: this.mousePoint.y,
          });
        }

        if (this.props.stairsTool === "landing") {
          theStairs = Landing({
            id: uuid(),
            x1: this.currentPoint.x,
            y1: this.currentPoint.y,
            x2: this.mousePoint.x,
            y2: this.mousePoint.y,
          });
        }

        if (this.props.stairsTool === "stairs") {
          const dx = Math.abs(theStairs.x2 - theStairs.x1);
          const dy = Math.abs(theStairs.y2 - theStairs.y1);

          if (dx > dy) {
            theStairs = theStairs.set("orientation", "horizontal");
          }
        }

        let matchingEdges = Map();

        if (
          this.currentStairsSnap &&
          this.hoveringStairCorners &&
          // @TODO FIX.
          (true || !this.stairCornerIntersections) &&
          !this.isControlDown
        ) {
          // Handle snapping to other points.
          const stairCorners = this.hoveringStairCorners.map(
            (corner) => corner.nearPoint.point
          );

          let newX = this.mousePoint.x;
          let newY = this.mousePoint.y;

          stairCorners.forEach((point) => {
            const [x, y] = point;

            if (isCloseToValue(newX, x, 10)) {
              newX = x;
            }

            if (isCloseToValue(newY, y, 10)) {
              newY = y;
            }
          });

          theStairs = theStairs.set("x2", newX);
          theStairs = theStairs.set("y2", newY);

          const matchingIds = this.hoveringStairCorners.map(
            (corner) => corner.nearPoint.stairsId
          );

          let edges = Map();

          matchingIds.forEach((id) => {
            const currentPoint = this.currentStairsSnap.get(id);
            const hoveringPoint = this.hoveringStairCorners.get(id);

            if (currentPoint && hoveringPoint) {
              const currentNearPoint = currentPoint.nearPoint;
              const hoveringNearPoint = hoveringPoint.nearPoint;
              const currentType = currentNearPoint.cornerType;
              const hoveringType = hoveringNearPoint.cornerType;

              if (currentType && hoveringType) {
                let existingEdgeKey = "stairs-to-stairs";

                const currentVertical = currentType[0];
                const currentHorizontal = currentType[1];

                const hoveringVertical = hoveringType[0];
                const hoveringHorizontal = hoveringType[1];

                if (currentVertical === hoveringVertical) {
                  if (currentVertical === "T") {
                    existingEdgeKey = "stairs-top-to-stairs";
                  } else if (currentVertical === "B") {
                    existingEdgeKey = "stairs-bottom-to-stairs";
                  }
                } else if (currentHorizontal === hoveringHorizontal) {
                  if (currentHorizontal === "L") {
                    existingEdgeKey = "stairs-left-to-stairs";
                  } else if (currentHorizontal === "R") {
                    existingEdgeKey = "stairs-right-to-stairs";
                  }
                }

                matchingEdges = matchingEdges.set(
                  currentNearPoint.stairsId,
                  Map({
                    [existingEdgeKey]: Edge({
                      from: currentNearPoint.stairsId,
                      to: theStairs.id,
                    }),
                  })
                );
              }

              const newPoints = getUnrotatedStairsCorners(theStairs);

              const currentPointMatch = { x: theStairs.x1, y: theStairs.y1 };

              const matchCurrentType = Object.entries(newPoints).reduce(
                (type, point) => {
                  const [key, value] = point;
                  const [x, y] = value;

                  if (
                    isCloseToValue(x, currentPointMatch.x, 1) &&
                    isCloseToValue(y, currentPointMatch.y, 1)
                  ) {
                    return key;
                  }

                  return type;
                },
                ""
              );

              const matchHoveringType = Object.entries(newPoints).reduce(
                (type, point) => {
                  const [key, value] = point;
                  const [x, y] = value;

                  if (
                    isCloseToValue(x, hoveringNearPoint.point[0], 1) ||
                    isCloseToValue(y, hoveringNearPoint.point[1], 1)
                  ) {
                    if (!type.length) {
                      return key;
                    } else {
                      const newDistance = getDistance(
                        { x, y },
                        {
                          x: hoveringNearPoint.point[0],
                          y: hoveringNearPoint.point[1],
                        }
                      );
                      const oldDistance = getDistance(
                        { x, y },
                        { x: newPoints[type][0], y: newPoints[type][1] }
                      );

                      if (newDistance < oldDistance) {
                        return key;
                      }
                    }
                  }

                  return type;
                },
                ""
              );

              let edgeKey = "stairs-to-stairs";

              const matchCurrentVertical = matchCurrentType[0];
              const matchCurrentHorizontal = matchCurrentType[1];

              const matchHoveringVertical = matchHoveringType[0];
              const matchHoveringHorizontal = matchHoveringType[1];

              if (matchCurrentVertical === matchHoveringVertical) {
                if (matchCurrentVertical === "T") {
                  edgeKey = "stairs-top-to-stairs";
                } else if (matchCurrentVertical === "B") {
                  edgeKey = "stairs-bottom-to-stairs";
                }
              } else if (matchCurrentHorizontal === matchHoveringHorizontal) {
                if (matchCurrentHorizontal === "L") {
                  edgeKey = "stairs-left-to-stairs";
                } else if (matchCurrentHorizontal === "R") {
                  edgeKey = "stairs-right-to-stairs";
                }
              }
              edges = edges.set(edgeKey, Edge({ from: theStairs.id, to: id }));
            } else if (hoveringPoint) {
              const currentNearPoint = this.currentStairsSnap.first().nearPoint;
              const hoveringNearPoint = hoveringPoint.nearPoint;

              const newPoints = getUnrotatedStairsCorners(theStairs);
              const hoveringPoints = getUnrotatedStairsCorners(
                this.props.stairs.get(hoveringNearPoint.stairsId)
              );

              const hoveringType = hoveringNearPoint.cornerType;

              const currentType = getCurrentPointType(
                currentNearPoint.point,
                hoveringPoints
              );

              if (currentType && hoveringType) {
                let existingEdgeKey = "stairs-to-stairs";

                const currentVertical = currentType[0];
                const currentHorizontal = currentType[1];

                const hoveringVertical = hoveringType[0];
                const hoveringHorizontal = hoveringType[1];

                if (currentVertical === hoveringVertical) {
                  if (currentVertical === "T") {
                    existingEdgeKey = "stairs-top-to-stairs";
                  } else if (currentVertical === "B") {
                    existingEdgeKey = "stairs-bottom-to-stairs";
                  }
                } else if (currentHorizontal === hoveringHorizontal) {
                  if (currentHorizontal === "L") {
                    existingEdgeKey = "stairs-left-to-stairs";
                  } else if (currentHorizontal === "R") {
                    existingEdgeKey = "stairs-right-to-stairs";
                  }
                }

                matchingEdges = matchingEdges.set(
                  hoveringNearPoint.stairsId,
                  Map({
                    [existingEdgeKey]: Edge({
                      from: hoveringNearPoint.stairsId,
                      to: theStairs.id,
                    }),
                  })
                );
              }

              const matchHoveringType = Object.entries(newPoints).reduce(
                (type, point) => {
                  const [key, value] = point;
                  const [x, y] = value;

                  if (
                    isCloseToValue(x, hoveringNearPoint.point[0], 1) &&
                    isCloseToValue(y, hoveringNearPoint.point[1], 1)
                  ) {
                    if (!type.length) {
                      return key;
                    } else {
                      const newDistance = getDistance(
                        { x, y },
                        {
                          x: hoveringNearPoint.point[0],
                          y: hoveringNearPoint.point[1],
                        }
                      );
                      const oldDistance = getDistance(
                        { x, y },
                        { x: newPoints[type][0], y: newPoints[type][1] }
                      );

                      if (newDistance < oldDistance) {
                        return key;
                      }
                    }
                  }

                  return type;
                },
                ""
              );

              const matchCurrentType = getCurrentPointType(
                hoveringPoints[currentType],
                newPoints
              );

              let edgeKey = "stairs-to-stairs";

              const matchCurrentVertical = matchCurrentType[0];
              const matchCurrentHorizontal = matchCurrentType[1];

              const matchHoveringVertical = matchHoveringType[0];
              const matchHoveringHorizontal = matchHoveringType[1];

              if (matchCurrentVertical === matchHoveringVertical) {
                if (matchCurrentVertical === "T") {
                  edgeKey = "stairs-top-to-stairs";
                } else if (matchCurrentVertical === "B") {
                  edgeKey = "stairs-bottom-to-stairs";
                }
              } else if (matchCurrentHorizontal === matchHoveringHorizontal) {
                if (matchCurrentHorizontal === "L") {
                  edgeKey = "stairs-left-to-stairs";
                } else if (matchCurrentHorizontal === "R") {
                  edgeKey = "stairs-right-to-stairs";
                }
              }
              edges = edges.set(edgeKey, Edge({ from: theStairs.id, to: id }));
            }
          });

          theStairs = theStairs.set("stairsEdges", edges);
          // @TODO FIX.
        } else if (false && this.currentStairsSnap && !this.isControlDown) {
          const landingDivisions = this.stairCornerIntersections.reduce(
            (lines, intersection) => {
              const { x, y } = intersection.point;

              return lines.setIn(["x", x], x).setIn(["y", y], y);
            },
            Map()
          );
          console.log("YAM", landingDivisions.toJS());

          const xCoords = landingDivisions.get("x").toSetSeq().toArray().flat();
          const yCoords = landingDivisions.get("y").toSetSeq().toArray().flat();

          const x1 = this.currentPoint.x;
          const y1 = this.currentPoint.y;
          const x2 = this.mousePoint.x;
          const y2 = this.mousePoint.y;

          const subdivided = subdivideRectangle(
            { x1, y1, x2, y2 },
            xCoords,
            yCoords
          );

          console.log("EM", subdivided);
          const metaId = uuid();

          const subdividedStairs = subdivided.map((subdivision) => {
            const { x1, y1, x2, y2 } = subdivision;

            if (this.props.stairsTool === "stairs") {
              return Stairs({
                id: uuid(),
                x1,
                y1,
                x2,
                y2,
                metaId,
              });
            }

            if (this.props.stairsTool === "landing") {
              return Landing({
                id: uuid(),
                x1,
                y1,
                x2,
                y2,
                metaId,
              });
            }

            return Stairs({
              id: uuid(),
              x1,
              y1,
              x2,
              y2,
              metaId,
            });
          });

          this.props.dispatch({
            type: "stairs/add-many",
            stairs: subdividedStairs,
          });
          this.isDrawing = false;
          return;
        }

        if (this.stairsAlignmentLine) {
          const closestPoint = getClosestPointOnLine(
            { x: this.stairsAlignmentLine.x1, y: this.stairsAlignmentLine.y1 },
            { x: this.stairsAlignmentLine.x2, y: this.stairsAlignmentLine.y2 },
            { x: this.mousePoint.x, y: this.mousePoint.y }
          );

          theStairs = theStairs
            .set("x2", closestPoint.x)
            .set("y2", closestPoint.y);

          this.stairsAlignmentLine = null;
        }

        // if (theStairs.x1 > theStairs.x2) {
        //   const currentX1 = theStairs.x1;

        //   theStairs = theStairs.set("x1", theStairs.x2).set("x2", currentX1);
        // }

        // if (theStairs.y1 > theStairs.y2) {
        //   const currentY1 = theStairs.y1;

        //   theStairs = theStairs.set("y1", theStairs.y2).set("y2", currentY1);
        // }

        this.props.dispatch({
          type: "stairs/add",
          stair: theStairs,
          matchingEdges: matchingEdges.size ? matchingEdges : null,
          id: this.props.currentProject,
        });

        // Toggle drawing off after shape is drawn.
        this.isDrawing = false;
      }
    }

    if (this.props.tool === "gate") {
      if (!this.isDrawing) {
        this.currentPoint = this.calculatePointWithPanScale(event);

        const startPost = this.props.runs
          .flatMap((theRun, runIndex) => {
            const posts = getPosts(
              theRun,
              this.props.settings,
              this.props.state
            );

            const startingPost = posts.map((post, postIndex) => {
              if (isNearPoint(this.currentPoint, post)) {
                return {
                  runIndex: runIndex,
                  postIndex: postIndex,
                };
              }

              return false;
            });
            return startingPost;
          })
          .filter((post) => post !== false);

        if (startPost.size === 1) {
          this.isDrawing = true;
          return;
        }

        const clickingOnIndividualPost = this.props.posts
          .map((post) => {
            if (isNearPoint(this.currentPoint, post)) {
              return {
                runIndex: null,
                postIndex: post.id,
              };
            }

            return false;
          })
          .filter((post) => post !== false)
          .reduce((_acc, post) => post);

        if (clickingOnIndividualPost) {
          this.isDrawing = true;
          return;
        }
      } else {
        // If mouse position is the same as it was do not draw anything return early.
        if (
          this.currentPoint.x === event.mouseX &&
          this.currentPoint.y === event.mouseY
        ) {
          return;
        }

        const startPost = this.props.runs
          .map((theRun) => {
            const posts = getPostsFactoringInCorners(
              theRun,
              this.props.settings,
              calculateCorners(this.props.runs),
              this.props.state
            );

            const startingPost = posts.map((post, postIndex) => {
              if (isNearPoint(this.currentPoint, post)) {
                return {
                  runIndex: theRun.id,
                  postIndex: postIndex,
                };
              }

              return false;
            });

            return startingPost.filter((post) => post !== false);
          })
          .filter((posts) => posts.length === 1)
          .reduce((acc, posts) => posts);

        const clickingOnIndividualStartPost = this.props.posts
          .map((post) => {
            if (isNearPoint(this.currentPoint, post)) {
              return {
                runIndex: null,
                postIndex: post.id,
              };
            }

            return false;
          })
          .filter((post) => post !== false)
          .reduce((_acc, post) => post);

        let gate = Gate({ id: uuid() });

        if (startPost && startPost.length === 1) {
          gate = gate.set("p1", startPost[0]);
        }

        if (clickingOnIndividualStartPost) {
          gate = gate.set("p1", clickingOnIndividualStartPost);
        }

        let endPost = this.props.runs
          .filter((theRun) => theRun.id === gate.p1.runIndex)
          .map((theRun, runIndex) => {
            const posts = getPostsFactoringInCorners(
              theRun,
              this.props.settings,
              calculateCorners(this.props.runs),
              this.props.state
            );

            const endingPost = posts.map((post, postIndex) => {
              if (isNearPoint(this.mousePoint, post)) {
                return {
                  runIndex: theRun.id,
                  postIndex: postIndex,
                };
              }

              return false;
            });

            return endingPost.filter((post) => post !== false);
          })
          .filter((posts) => posts.length === 1)
          .reduce((_acc, posts) => posts);

        if (endPost && endPost.length === 1) {
          gate = gate.set("p2", endPost[0]);
        }

        const clickingOnIndividualEndPost = this.props.posts
          .map((post) => {
            if (isNearPoint(this.mousePoint, post)) {
              return {
                runIndex: null,
                postIndex: post.id,
              };
            }

            return false;
          })
          .filter((post) => post !== false)
          .reduce((_acc, post) => post);

        if (clickingOnIndividualEndPost) {
          gate = gate.set("p2", clickingOnIndividualEndPost);
        }

        gate = gate.set("opening", "normal");
        gate = gate.set("side", "normal");

        if (
          gate.p1.runIndex &&
          gate.p2.runIndex &&
          gate.p1.runIndex === gate.p2.runIndex
        ) {
          const corners = calculateCorners(this.props.runs);
          const runIndex = gate.p1.runIndex;
          const run = this.props.runs.get(runIndex);

          // Handle splitting of run.
          const posts = getPosts(run, this.props.settings, this.props.state);

          let startCorner = null;

          // If gate start/end post is at start of run.
          if (gate.p1.postIndex === 0 || gate.p2.postIndex === 0) {
            startCorner = corners.find((corner) => {
              let start = false;

              Object.values(corner.points).forEach((point) => {
                if (point.id === runIndex && point.type === "1") {
                  start = true;
                }
              });

              return start;
            });
          }

          let endingCorner = null;

          // If gate start/end post is at start of run.
          if (
            gate.p1.postIndex === posts.length - 1 ||
            gate.p2.postIndex === posts.length - 1
          ) {
            endingCorner = corners.find((corner) => {
              let end = false;

              Object.values(corner.points).forEach((point) => {
                if (point.id === runIndex && point.type === "2") {
                  end = true;
                }
              });

              return end;
            });
          }

          // No connected runs to attach to.
          if (!endingCorner && !startCorner) {
            let startEndOfRun = null;

            if (
              gate.p1.postIndex === 0 ||
              gate.p1.postIndex === posts.length - 1
            ) {
              startEndOfRun = true;
            }

            let endEndOfRun = null;

            if (
              gate.p2.postIndex === 0 ||
              gate.p2.postIndex === posts.length - 1
            ) {
              endEndOfRun = true;
            }

            if (startEndOfRun && endEndOfRun) {
              // If both posts of gate are at the ends. Replace run and add two posts at ends.
              let updatedExistingRun = run;
              let replacedPostIndex = [0, posts.length - 1];

              let newPost1 = Post({
                id: uuid(),
              });

              let newPost2 = Post({
                id: uuid(),
              });

              if (gate.p1.postIndex === 0) {
                gate = gate
                  .set("x1", posts[0].x)
                  .set("y1", posts[0].y)
                  .setIn(["p1", "postIndex"], newPost1.id)
                  .setIn(["p1", "runIndex"], null);

                newPost1 = newPost1.set("x", posts[0].x).set("y", posts[0].y);

                replacedPostIndex = [0, posts.length - 1];
              }

              if (gate.p1.postIndex === posts.length - 1) {
                gate = gate
                  .set("x1", posts[posts.length - 1].x)
                  .set("y1", posts[posts.length - 1].y)
                  .setIn(["p1", "postIndex"], newPost1.id)
                  .setIn(["p1", "runIndex"], null);

                newPost1 = newPost1
                  .set("x", posts[posts.length - 1].x)
                  .set("y", posts[posts.length - 1].y);

                replacedPostIndex = [posts.length - 1, 0];
              }

              if (gate.p2.postIndex === 0) {
                gate = gate
                  .set("x2", posts[0].x)
                  .set("y2", posts[0].y)
                  .setIn(["p2", "postIndex"], newPost2.id)
                  .setIn(["p2", "runIndex"], null);

                newPost2 = newPost2.set("x", posts[0].x).set("y", posts[0].y);

                replacedPostIndex = [posts.length - 1, 0];
              }

              if (gate.p2.postIndex === posts.length - 1) {
                gate = gate
                  .set("x2", posts[posts.length - 1].x)
                  .set("y2", posts[posts.length - 1].y)
                  .setIn(["p2", "postIndex"], newPost2.id)
                  .setIn(["p2", "runIndex"], null);

                newPost2 = newPost2
                  .set("x", posts[posts.length - 1].x)
                  .set("y", posts[posts.length - 1].y);

                replacedPostIndex = [0, posts.length - 1];
              }

              const gates = this.props.gates.set(gate.id, gate);
              const runs = this.props.runs.remove(run.id);

              const newPosts = this.props.posts
                .set(newPost1.id, newPost1)
                .set(newPost2.id, newPost2);

              const cornerIds = calculateCorners(runs).map(cornerHash);

              const insertedPosts = [newPost1, newPost2];

              this.props.dispatch({
                type: "gates/replace-run",
                gates: gates,
                runs: runs,
                cornerIds: cornerIds,
                updatedExistingRun: updatedExistingRun,
                posts: newPosts,
                insertedPosts: insertedPosts,
                insertedGate: gate,
                replacedPostIndex: replacedPostIndex,
              });
            } else if (startEndOfRun) {
              // If only the start of gate is at the end.
              if (gate.p1.postIndex === 0) {
                let updatedExistingRun = run;
                updatedExistingRun = updatedExistingRun
                  .set("x1", posts[gate.p2.postIndex].x)
                  .set("y1", posts[gate.p2.postIndex].y);

                const newPost = Post({
                  id: uuid(),
                  x: posts[gate.p1.postIndex].x,
                  y: posts[gate.p1.postIndex].y,
                });

                const replacedPostIndex = [gate.p1.postIndex];

                gate = gate
                  .set("x1", posts[gate.p2.postIndex].x)
                  .set("y1", posts[gate.p2.postIndex].y)
                  .set("x2", posts[gate.p1.postIndex].x)
                  .set("y2", posts[gate.p1.postIndex].y)
                  .set("p1", {
                    postIndex: 0,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: newPost.id,
                    runIndex: null,
                    noRun: true,
                  });

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);
                const newPosts = this.props.posts.set(newPost.id, newPost);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: newPosts,
                  insertedPost: newPost,
                  gatePostIndex: "p2",
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              }

              if (gate.p1.postIndex === posts.length - 1) {
                let updatedExistingRun = run;
                updatedExistingRun = updatedExistingRun
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y);

                const newPost = Post({
                  id: uuid(),
                  x: posts[gate.p1.postIndex].x,
                  y: posts[gate.p1.postIndex].y,
                });

                const replacedPostIndex = [gate.p1.postIndex];

                gate = gate
                  .set("x1", posts[gate.p2.postIndex].x)
                  .set("y1", posts[gate.p2.postIndex].y)
                  .set("x2", posts[gate.p1.postIndex].x)
                  .set("y2", posts[gate.p1.postIndex].y)
                  .set("p1", {
                    postIndex: gate.p2.postIndex,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: newPost.id,
                    runIndex: null,
                    noRun: true,
                  });

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);
                const newPosts = this.props.posts.set(newPost.id, newPost);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: newPosts,
                  insertedPost: newPost,
                  gatePostIndex: "p2",
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              }
            } else if (endEndOfRun) {
              // If only the end of gate is at the end of run.
              if (gate.p2.postIndex === 0) {
                let updatedExistingRun = run;
                updatedExistingRun = updatedExistingRun
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y);

                const newPost = Post({
                  id: uuid(),
                  x: posts[gate.p2.postIndex].x,
                  y: posts[gate.p2.postIndex].y,
                });

                const replacedPostIndex = [gate.p2.postIndex];

                gate = gate
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y)
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y)
                  .set("p1", {
                    postIndex: 0,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: newPost.id,
                    runIndex: null,
                    noRun: true,
                  });

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);
                const newPosts = this.props.posts.set(newPost.id, newPost);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: newPosts,
                  insertedPost: newPost,
                  gatePostIndex: "p2",
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              }

              if (gate.p2.postIndex === posts.length - 1) {
                let updatedExistingRun = run;
                updatedExistingRun = updatedExistingRun
                  .set("x2", posts[gate.p1.postIndex].x)
                  .set("y2", posts[gate.p1.postIndex].y);

                const newPost = Post({
                  id: uuid(),
                  x: posts[gate.p2.postIndex].x,
                  y: posts[gate.p2.postIndex].y,
                });

                const replacedPostIndex = [gate.p2.postIndex];

                gate = gate
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y)
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y)
                  .set("p1", {
                    postIndex: gate.p1.postIndex,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: newPost.id,
                    runIndex: null,
                    noRun: true,
                  });

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);
                const newPosts = this.props.posts.set(newPost.id, newPost);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: newPosts,
                  insertedPost: newPost,
                  gatePostIndex: "p2",
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              }
            } else if (!startEndOfRun && !endEndOfRun) {
              // If gate is in the middle of run.
              const id = uuid();

              let newRun = Run({ id: id });
              let updatedExistingRun = run;

              let gates = this.props.gates;

              if (gate.p1.postIndex < gate.p2.postIndex) {
                updatedExistingRun = updatedExistingRun
                  .set("x1", run.x1)
                  .set("y1", run.y1)
                  .set("x2", posts[gate.p1.postIndex].x)
                  .set("y2", posts[gate.p1.postIndex].y);

                newRun = newRun
                  .set("x1", posts[gate.p2.postIndex].x)
                  .set("y1", posts[gate.p2.postIndex].y)
                  .set("x2", run.x2)
                  .set("y2", run.y2);

                gates = gates.map((theGate) => {
                  if (theGate.p1.runIndex === runIndex) {
                    if (theGate.p1.postIndex > gate.p1.postIndex) {
                      const newPosts = getPosts(newRun, this.props.settings);

                      const oldPost = posts[theGate.p1.postIndex];

                      const match = newPosts.findIndex((post) => {
                        if (oldPost) {
                          return post.x === oldPost.x && post.y === oldPost.y;
                        }

                        return false;
                      });

                      if (match !== -1) {
                        theGate = theGate.set("p1", {
                          runIndex: newRun.id,
                          postIndex: match,
                        });
                      }
                    }

                    return theGate;
                  }

                  if (theGate.p2.runIndex === runIndex) {
                    if (theGate.p2.postIndex > gate.p1.postIndex) {
                      const newPosts = getPosts(newRun, this.props.settings);

                      const oldPost = posts[theGate.p2.postIndex];

                      const match = newPosts.findIndex((post) => {
                        if (oldPost) {
                          return post.x === oldPost.x && post.y === oldPost.y;
                        }

                        return false;
                      });

                      if (match !== -1) {
                        theGate = theGate.set("p2", {
                          runIndex: newRun.id,
                          postIndex: match,
                        });
                      }
                    }

                    return theGate;
                  }

                  return theGate;
                });

                gate = gate
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y)
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y)
                  .set("p1", {
                    postIndex: gate.p1.postIndex,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: 0,
                    runIndex: newRun.id,
                  });
              } else {
                updatedExistingRun = updatedExistingRun
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y)
                  .set("x2", run.x2)
                  .set("y2", run.y2);

                newRun = newRun
                  .set("x1", run.x1)
                  .set("y1", run.y1)
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y);

                gates = gates.map((theGate) => {
                  if (theGate.p1.runIndex === runIndex) {
                    if (theGate.p1.postIndex > gate.p2.postIndex) {
                      const newPosts = getPosts(
                        updatedExistingRun,
                        this.props.settings
                      );

                      const oldPost = posts[theGate.p1.postIndex];

                      const match = newPosts.findIndex((post) => {
                        return post.x === oldPost.x && post.y === oldPost.y;
                      });

                      if (match !== -1) {
                        theGate = theGate.set("p1", {
                          runIndex: updatedExistingRun.id,
                          postIndex: match,
                        });
                      }
                    }

                    return theGate;
                  }

                  if (theGate.p2.runIndex === runIndex) {
                    if (theGate.p2.postIndex > gate.p2.postIndex) {
                      const newPosts = getPosts(
                        updatedExistingRun,
                        this.props.settings
                      );

                      const oldPost = posts[theGate.p2.postIndex];

                      const match = newPosts.findIndex((post) => {
                        return post.x === oldPost.x && post.y === oldPost.y;
                      });

                      if (match !== -1) {
                        theGate = theGate.set("p2", {
                          runIndex: updatedExistingRun.id,
                          postIndex: match,
                        });
                      }
                    }

                    return theGate;
                  }

                  return theGate;
                });

                gate = gate
                  .set("x1", posts[gate.p1.postIndex].x)
                  .set("y1", posts[gate.p1.postIndex].y)
                  .set("x2", posts[gate.p2.postIndex].x)
                  .set("y2", posts[gate.p2.postIndex].y)
                  .set("p1", {
                    postIndex: 0,
                    runIndex: updatedExistingRun.id,
                  })
                  .set("p2", {
                    postIndex: gate.p2.postIndex,
                    runIndex: newRun.id,
                  });
              }

              gates = gates.set(gate.id, gate);

              const runs = this.props.runs
                .set(newRun.id, newRun)
                .set(updatedExistingRun.id, updatedExistingRun);

              const cornerIds = calculateCorners(runs).map(cornerHash);

              this.props.dispatch({
                type: "gates/insert-into-run",
                gates: gates,
                runs: runs,
                cornerIds,
                updatedExistingRun: updatedExistingRun,
              });
            }
          } else {
            // Handle bridging gate between two runs attached at a corner and gate is on corner.
            if (endingCorner && startCorner) {
              // Gate spans entire run.
              let matchingEndRunPoint = null;

              Object.values(endingCorner.points).forEach((point) => {
                if (point.id !== gate.p1.runIndex) {
                  matchingEndRunPoint = point;
                }
              });

              let matchingStartRunPoint = null;

              Object.values(startCorner.points).forEach((point) => {
                if (point.id !== gate.p1.runIndex) {
                  matchingStartRunPoint = point;
                }
              });

              const originalGate = gate;

              const runStart = this.props.runs.get(
                matchingStartRunPoint ? matchingStartRunPoint.id : null
              );

              if (runStart) {
                const postsStart = getPosts(
                  runStart,
                  this.props.settings,
                  this.props.state
                );

                // Matches at start of run.
                if (matchingStartRunPoint.type === "1") {
                  let newIndex = "1";
                  if (originalGate.p1.postIndex === 0) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  gate = gate
                    .setIn(
                      [gatePostIndex, "runIndex"],
                      matchingStartRunPoint.id
                    )
                    .setIn([gatePostIndex, "postIndex"], 0)
                    .set("x" + newIndex, postsStart[0].x)
                    .set("y" + newIndex, postsStart[0].y);
                }

                // Matches at end of run.
                if (matchingStartRunPoint.type === "2") {
                  let newIndex = "1";
                  if (originalGate.p1.postIndex === 0) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  gate = gate
                    .setIn(
                      [gatePostIndex, "runIndex"],
                      matchingStartRunPoint.id
                    )
                    .setIn([gatePostIndex, "postIndex"], postsStart.length - 1)
                    .set("x" + newIndex, postsStart[postsStart.length - 1].x)
                    .set("y" + newIndex, postsStart[postsStart.length - 1].y);
                }
              }

              const runEnd = this.props.runs.get(
                matchingEndRunPoint ? matchingEndRunPoint.id : null
              );

              if (runEnd) {
                const postsEnd = getPosts(
                  runEnd,
                  this.props.settings,
                  this.props.state
                );

                // Matches at start of run.
                if (matchingEndRunPoint.type === "1") {
                  let newIndex = "2";
                  if (originalGate.p1.postIndex === posts.length - 1) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  gate = gate
                    .setIn([gatePostIndex, "runIndex"], matchingEndRunPoint.id)
                    .setIn([gatePostIndex, "postIndex"], 0)
                    .set("x" + newIndex, postsEnd[0].x)
                    .set("y" + newIndex, postsEnd[0].y);
                }

                // Matches at end of run.
                if (matchingEndRunPoint.type === "2") {
                  let newIndex = "2";
                  if (originalGate.p1.postIndex === posts.length - 1) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  gate = gate
                    .setIn([gatePostIndex, "runIndex"], matchingEndRunPoint.id)
                    .setIn([gatePostIndex, "postIndex"], postsEnd.length - 1)
                    .set("x" + newIndex, postsEnd[postsEnd.length - 1].x)
                    .set("y" + newIndex, postsEnd[postsEnd.length - 1].y);
                }
              }

              const updatedExistingRun = this.props.runs.get(
                originalGate.p1.runIndex
              );
              const runs = this.props.runs.remove(originalGate.p1.runIndex);
              const gates = this.props.gates.set(gate.id, gate);
              const cornerIds = calculateCorners(runs).map(cornerHash);

              this.props.dispatch({
                type: "gates/insert-into-run",
                gates: gates,
                runs: runs,
                cornerIds: cornerIds,
                updatedExistingRun: updatedExistingRun,
              });
            } else if (endingCorner) {
              // Only ending corner.
              let matchingEndRunPoint = null;

              Object.values(endingCorner.points).forEach((point) => {
                if (point.id !== gate.p1.runIndex) {
                  matchingEndRunPoint = point;
                }
              });

              const runEnd = this.props.runs.get(
                matchingEndRunPoint ? matchingEndRunPoint.id : null
              );

              const originalGate = gate;

              if (runEnd) {
                const postsEnd = getPosts(
                  runEnd,
                  this.props.settings,
                  this.props.state
                );

                // Matches at start of run.
                if (matchingEndRunPoint.type === "1") {
                  let newIndex = "2";
                  if (originalGate.p1.postIndex === posts.length - 1) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;
                  // Change start of gate to matching run.
                  gate = gate
                    .setIn([gatePostIndex, "runIndex"], matchingEndRunPoint.id)
                    .setIn([gatePostIndex, "postIndex"], 0)
                    .set("x" + newIndex, postsEnd[0].x)
                    .set("y" + newIndex, postsEnd[0].y);
                }

                // Matches at end of run.
                if (matchingEndRunPoint.type === "2") {
                  let newIndex = "2";
                  if (originalGate.p1.postIndex === posts.length - 1) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;
                  // Change start of gate to matching run.
                  gate = gate
                    .setIn([gatePostIndex, "runIndex"], matchingEndRunPoint.id)
                    .setIn([gatePostIndex, "postIndex"], postsEnd.length - 1)
                    .set("x" + newIndex, postsEnd[postsEnd.length - 1].x)
                    .set("y" + newIndex, postsEnd[postsEnd.length - 1].y);
                }
              }

              let updatedExistingRun = this.props.runs.get(
                originalGate.p1.runIndex
              );

              let startEndOfRun = null;

              if (
                originalGate.p1.postIndex === 0 ||
                originalGate.p1.postIndex === posts.length - 1
              ) {
                startEndOfRun = true;
              }

              let endEndOfRun = null;

              if (
                originalGate.p2.postIndex === 0 ||
                originalGate.p2.postIndex === posts.length - 1
              ) {
                endEndOfRun = true;
              }

              if (startEndOfRun && endEndOfRun) {
                // If both posts of gate are at the ends.
                // If there is only one ending corner Then we replace the run with a gate and add a post to attach the gate to.

                let newPost = Post({
                  id: uuid(),
                });

                let gatePostIndex = "p1";

                let replacedPostIndex = [];
                if (originalGate.p1.postIndex === 0) {
                  gate = gate.set("x1", posts[0].x).set("y1", posts[0].y);
                  gate = gate
                    .set("x2", posts[posts.length - 1].x)
                    .set("y2", posts[posts.length - 1].y);

                  gatePostIndex = "p1";

                  gate = gate
                    .setIn([gatePostIndex, "postIndex"], newPost.id)
                    .setIn([gatePostIndex, "runIndex"], null);

                  replacedPostIndex = [0];
                  newPost = newPost.set("x", posts[0].x).set("y", posts[0].y);
                }

                if (originalGate.p1.postIndex === posts.length - 1) {
                  gate = gate
                    .set("x1", posts[posts.length - 1].x)
                    .set("y1", posts[posts.length - 1].y);
                  gate = gate.set("x2", posts[0].x).set("y2", posts[0].y);

                  gatePostIndex = "p2";

                  gate = gate
                    .setIn([gatePostIndex, "postIndex"], newPost.id)
                    .setIn([gatePostIndex, "runIndex"], null);

                  replacedPostIndex = [0];
                  newPost = newPost.set("x", posts[0].x).set("y", posts[0].y);
                }

                const runs = this.props.runs.remove(originalGate.p1.runIndex);
                const gates = this.props.gates.set(gate.id, gate);
                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: this.props.posts.set(newPost.id, newPost),
                  insertedPost: newPost,
                  gatePostIndex: gatePostIndex,
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              } else if (startEndOfRun) {
                if (originalGate.p1.postIndex === 0) {
                  // x1,y1 of the run is at the start corner, we need to change it to the end of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P2 is somewhere in the middle of the run.
                    .set("x1", posts[originalGate.p2.postIndex].x)
                    .set("y1", posts[originalGate.p2.postIndex].y);

                  gate = gate
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);
                }

                if (originalGate.p1.postIndex === posts.length - 1) {
                  // x2,y2 of the run is at the end corner, we need to change it to the end of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P2 is somewhere in the middle of the run.
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);

                  gate = gate
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);
                }

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                });
              } else if (endEndOfRun) {
                if (originalGate.p2.postIndex === 0) {
                  // x1,y1 of the run is at the start corner, we need to change it to the start of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P1 is in middle of the run somewhere.
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);

                  gate = gate
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);
                }

                if (originalGate.p2.postIndex === posts.length - 1) {
                  // x2,y2 of the run is at the end corner, we need to change it to the start of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P1 is in middle of the run somewhere.
                    .set("x2", posts[originalGate.p1.postIndex].x)
                    .set("y2", posts[originalGate.p1.postIndex].y);

                  gate = gate
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);
                }

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                });
              }
            } else if (startCorner) {
              // Only starting corner.
              let matchingStartRunPoint = null;

              Object.values(startCorner.points).forEach((point) => {
                if (point.id !== gate.p1.runIndex) {
                  matchingStartRunPoint = point;
                }
              });

              const runStart = this.props.runs.get(
                matchingStartRunPoint ? matchingStartRunPoint.id : null
              );

              const originalGate = gate;

              if (runStart) {
                const postsStart = getPosts(
                  runStart,
                  this.props.settings,
                  this.props.state
                );

                // Matches at start of run.
                if (matchingStartRunPoint.type === "1") {
                  let newIndex = "1";
                  if (originalGate.p1.postIndex === 0) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  // Change start of gate to matching run.
                  gate = gate
                    .setIn(
                      [gatePostIndex, "runIndex"],
                      matchingStartRunPoint.id
                    )
                    .setIn([gatePostIndex, "postIndex"], 0)
                    .set("x" + newIndex, postsStart[0].x)
                    .set("y" + newIndex, postsStart[0].y);
                }

                // Matches at end of run.
                if (matchingStartRunPoint.type === "2") {
                  let newIndex = "1";
                  if (originalGate.p1.postIndex === 0) {
                    newIndex = "1";
                  } else {
                    newIndex = "2";
                  }

                  const gatePostIndex = "p" + newIndex;

                  // Change start of gate to matching run.
                  gate = gate
                    .setIn(
                      [gatePostIndex, "runIndex"],
                      matchingStartRunPoint.id
                    )
                    .setIn([gatePostIndex, "postIndex"], postsStart.length - 1)
                    .set("x" + newIndex, postsStart[postsStart.length - 1].x)
                    .set("y" + newIndex, postsStart[postsStart.length - 1].y);
                }
              }

              let updatedExistingRun = this.props.runs.get(
                originalGate.p1.runIndex
              );

              let startEndOfRun = null;

              if (
                originalGate.p1.postIndex === 0 ||
                originalGate.p1.postIndex === posts.length - 1
              ) {
                startEndOfRun = true;
              }

              let endEndOfRun = null;

              if (
                originalGate.p2.postIndex === 0 ||
                originalGate.p2.postIndex === posts.length - 1
              ) {
                endEndOfRun = true;
              }

              if (startEndOfRun && endEndOfRun) {
                // If both posts of gate are at the ends.
                // If there is only one starting corner Then we replace the run with a gate and add a post to attach the gate to.
                let newPost = Post({
                  id: uuid(),
                });

                let gatePostIndex = "p1";
                let replacedPostIndex = [];
                if (originalGate.p1.postIndex === 0) {
                  gate = gate.set("x1", posts[0].x).set("y1", posts[0].y);
                  gate = gate
                    .set("x2", posts[posts.length - 1].x)
                    .set("y2", posts[posts.length - 1].y);

                  gatePostIndex = "p2";

                  gate = gate
                    .setIn([gatePostIndex, "postIndex"], newPost.id)
                    .setIn([gatePostIndex, "runIndex"], null);

                  newPost = newPost
                    .set("x", posts[posts.length - 1].x)
                    .set("y", posts[posts.length - 1].y);

                  replacedPostIndex = [posts.length - 1];
                }

                if (originalGate.p1.postIndex === posts.length - 1) {
                  gate = gate
                    .set("x1", posts[posts.length - 1].x)
                    .set("y1", posts[posts.length - 1].y);
                  gate = gate.set("x2", posts[0].x).set("y2", posts[0].y);

                  gatePostIndex = "p1";

                  gate = gate
                    .setIn([gatePostIndex, "postIndex"], newPost.id)
                    .setIn([gatePostIndex, "runIndex"], null);

                  newPost = newPost
                    .set("x", posts[posts.length - 1].x)
                    .set("y", posts[posts.length - 1].y);

                  replacedPostIndex = [posts.length - 1];
                }

                const runs = this.props.runs.remove(originalGate.p1.runIndex);
                const gates = this.props.gates.set(gate.id, gate);
                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                  posts: this.props.posts.set(newPost.id, newPost),
                  insertedPost: newPost,
                  gatePostIndex: gatePostIndex,
                  insertedGate: gate,
                  replacedPostIndex: replacedPostIndex,
                });
              } else if (startEndOfRun) {
                // Gate post is at start.
                if (originalGate.p1.postIndex === 0) {
                  // x1,y1 of the run is at the start of run, we need to change it to the end of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P2 is in the middle of the run somewhere.
                    .set("x1", posts[originalGate.p2.postIndex].x)
                    .set("y1", posts[originalGate.p2.postIndex].y);

                  gate = gate
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);
                }

                if (originalGate.p1.postIndex === posts.length - 1) {
                  // x2,y2 of the run is at the end of run, we need to change it to the end of the gate.
                  updatedExistingRun = updatedExistingRun
                    // P2 is in the middle of the run somewhere.
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);

                  gate = gate
                    .set("x2", posts[originalGate.p2.postIndex].x)
                    .set("y2", posts[originalGate.p2.postIndex].y);
                }

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                });
              } else if (endEndOfRun) {
                if (originalGate.p2.postIndex === 0) {
                  // x1,y1 of the run is at the start corner, we need to change it to the start of the gate.
                  updatedExistingRun = updatedExistingRun
                    // p1 is in the middle of the run somewhere.
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);

                  gate = gate
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);
                }

                if (originalGate.p2.postIndex === posts.length - 1) {
                  // x2,y2 of the run is at the end corner, we need to change it to the start of the gate.
                  updatedExistingRun = updatedExistingRun
                    // p1 is in the middle of the run somewhere.
                    .set("x2", posts[originalGate.p1.postIndex].x)
                    .set("y2", posts[originalGate.p1.postIndex].y);

                  gate = gate
                    .set("x1", posts[originalGate.p1.postIndex].x)
                    .set("y1", posts[originalGate.p1.postIndex].y);
                }

                const gates = this.props.gates.set(gate.id, gate);

                const runs = this.props.runs.set(
                  updatedExistingRun.id,
                  updatedExistingRun
                );

                const cornerIds = calculateCorners(runs).map(cornerHash);

                this.props.dispatch({
                  type: "gates/insert-into-run",
                  gates: gates,
                  runs: runs,
                  cornerIds: cornerIds,
                  updatedExistingRun: updatedExistingRun,
                });
              }
            }
          }
        } else {
          // Recalculate endPost if undefined from being on separate run.
          const checkEndPost = this.props.runs
            .map((theRun, runIndex) => {
              const posts = getPostsFactoringInCorners(
                theRun,
                this.props.settings,
                calculateCorners(this.props.runs),
                this.props.state
              );

              const endingPost = posts.map((post, postIndex) => {
                if (isNearPoint(this.mousePoint, post)) {
                  return {
                    runIndex: theRun.id,
                    postIndex: postIndex,
                  };
                }

                return false;
              });

              return endingPost.filter((post) => post !== false);
            })
            .filter((posts) => posts.length === 1)
            .reduce((_acc, posts) => posts);

          if (checkEndPost && checkEndPost.length === 1) {
            gate = gate.set("p2", checkEndPost[0]);

            if (gate.p1.runIndex) {
              // Allow gates to be added onto independent runs. 3.14.2023
              // For now don't allow gates to added onto independent runs.
              // Return before isDrawing is set to false and gate is added.
              // return;
            }
          }

          // Handle bridging gate between two runs.
          const gatePoints = getGatePoints(
            gate,
            this.props.runs,
            this.props.posts,
            this.props.settings
          );

          // Runs are guaranteed to be different.
          if (gate.p1.runIndex && gate.p2.runIndex) {
            // Check if connected to corner.
            const corners = calculateCorners(this.props.runs);

            let corner = null;

            // If gate start/end post is at start of run.
            if (gate.p1.postIndex === 0 || gate.p2.postIndex === 0) {
              corner = corners.find((corner) => {
                return Object.values(corner.points).find((point) => {
                  if (
                    (point.id === gate.p1.runIndex && point.type === "1") ||
                    (point.id === gate.p1.runIndex && point.type === "2")
                  ) {
                    return true;
                  }

                  return false;
                });
              });
            }

            /**
             * Check if ending corner or start corner match new run.
             * If they do match do not create a gate. This prevents gates
             * from being drawn on the corner. It also prevents gates from
             * being flat and running through posts.
             **/
            if (corner) {
              const matches = Object.values(corner.points).find((point) => {
                return point.id === gate.p2.runIndex;
              });

              if (matches) {
                // Return before isDrawing is set to false and gate is added.
                return;
              }
            }
          }

          const startPost = gatePoints.startPost;
          const endPost = gatePoints.endPost;

          if (!startPost || !endPost) {
            // Exit before drawing is set to false and gate is added.
            return;
          }

          gate = gate.set("x1", startPost.x);
          gate = gate.set("y1", startPost.y);
          gate = gate.set("x2", endPost.x);
          gate = gate.set("y2", endPost.y);

          this.props.dispatch({
            type: "gates/add",
            gate: gate,
            id: this.props.currentProject,
          });
        }

        this.isDrawing = false;
      }
    }

    if (this.props.tool === "handrail") {
      if (!this.isDrawing) {
        this.currentPoint = this.calculatePointWithPanScale(event);

        if (this.hoveringGrabrailPoint) {
          this.currentPoint = this.hoveringGrabrailPoint.point;
          this.grabrailAnchorPoint = this.hoveringGrabrailPoint;
        }

        this.isDrawing = true;
      } else {
        if (
          this.currentPoint.x === event.mouseX &&
          this.currentPoint.y === event.mouseY
        ) {
          return;
        }

        let handrail = Handrail({
          id: uuid(),
        });

        if (this.grabrailAnchorPoint) {
          handrail = handrail.set("p1", { ...this.grabrailAnchorPoint.point });
          handrail = handrail.set("x1", this.grabrailAnchorPoint.point.x);
          handrail = handrail.set("y1", this.grabrailAnchorPoint.point.y);
        } else {
          handrail = handrail.set("x1", this.currentPoint.x);
          handrail = handrail.set("y1", this.currentPoint.y);
        }

        if (this.hoveringGrabrailPoint) {
          handrail = handrail.set("p2", {
            ...this.hoveringGrabrailPoint.point,
          });
          handrail = handrail.set("x2", this.hoveringGrabrailPoint.point.x);
          handrail = handrail.set("y2", this.hoveringGrabrailPoint.point.y);
          // handrail = handrail.set("run", this.hoveringGrabrailPoint.point.run);

          this.currentPoint.x = this.hoveringGrabrailPoint.point.x;
          this.currentPoint.y = this.hoveringGrabrailPoint.point.y;

          const corners = calculateCorners(this.props.runs);

          const points = getHandrailAnchorPoints.bind(this)(
            corners,
            this.props.settings,
            this.props.runs,
            this.props.state
          );

          if (this.grabrailAnchorPoint) {
            this.grabrailAnchorPoint = getMatchingCornerAnchorPoints.bind(this)(
              points,
              this.grabrailAnchorPoint
            );
          } else {
            this.grabrailAnchorPoint = this.hoveringGrabrailPoint;
          }
        } else {
          this.grabrailAnchorPoint = null;
          if (this.isShiftDown) {
            const dx = Math.abs(this.mousePoint.x - this.currentPoint.x);
            const dy = Math.abs(this.mousePoint.y - this.currentPoint.y);

            if (dx > dy) {
              handrail = handrail.set("x2", this.mousePoint.x);
              handrail = handrail.set("y2", this.currentPoint.y);

              this.currentPoint.x = this.mousePoint.x;
            } else {
              handrail = handrail.set("x2", this.currentPoint.x);
              handrail = handrail.set("y2", this.mousePoint.y);

              this.currentPoint.y = this.mousePoint.y;
            }
          } else {
            handrail = handrail.set("x2", this.mousePoint.x);
            handrail = handrail.set("y2", this.mousePoint.y);

            this.currentPoint = this.mousePoint;
          }
        }

        this.props.dispatch({
          type: "handrails/add",
          handrail: handrail,
          id: this.props.currentProject,
        });
      }
      return;
    }

    if (this.props.tool === "note") {
      this.currentPoint.x = event.mouseX - this.pan.x;
      this.currentPoint.y = event.mouseY - this.pan.y;
      this.currentPoint = this.calculatePointWithPanScale(event);

      // If clicking on a note do not add a new note.
      if (isClickedInElements(".app__note", this.currentPoint)) {
        return;
      }

      const note = Note({
        id: uuid(),
        x: this.currentPoint.x,
        y: this.currentPoint.y,
        text: "Enter a note",
      });

      this.props.dispatch({
        type: "notes/add",
        note,
        id: this.props.currentProject,
      });
    }

    if (this.props.tool === "post") {
      this.currentPoint = this.calculatePointWithPanScale(event);

      if (this.snapLine && !this.isControlDown) {
        this.currentPoint = getClosestPointOnLine(
          { x: this.snapLine.x1, y: this.snapLine.y1 },
          { x: this.snapLine.x2, y: this.snapLine.y2 },
          this.mousePoint
        );
      }

      const post = Post({
        x: this.currentPoint.x,
        y: this.currentPoint.y,
        id: uuid(),
      });

      this.props.dispatch({
        type: "posts/add",
        post,
        id: this.props.currentProject,
      });
    }

    if (this.props.tool === "edit-selection") {
      // Edit handrails.
      if (this.props.handrails.size) {
        const selectedHandrails = this.props.handrails.map((handrail) =>
          handrailIntersection(handrail, this.mousePoint, this.props.runs)
        );

        const handrailIndex = selectedHandrails.findKey(
          (selected) => selected === true
        );

        if (handrailIndex) {
          this.props.dispatch({
            type: "handrails/edit-open",
            handrailIndex,
          });

          // Return early to prevent other click triggers.
          return;
        }
      }

      // Check for editing handrail length label.
      if (this.props.handrails.size) {
        const selectedHandrailLabels = this.props.handrails
          .map((handrail) => {
            const distanceLabelsSide = handrail.getIn([
              "labels",
              "distanceLabel",
            ]);

            let distanceLabelSide = 1;

            if (distanceLabelsSide) {
              distanceLabelSide = 1;
            } else {
              distanceLabelSide = -1;
            }

            if (handrail.run) {
              // Do not allow attached handrails to have editing of length.
              return false;
            }

            if (handrail.p1.run && handrail.p2.run) {
              // Do not allow attached handrails to have editing of length.
              return false;
            }

            if (handrail.p1.stairsIndex && handrail.p2.stairsIndex) {
              // Do not allow editing length of handrail attached to stairs.
              return false;
            }

            const angle =
              Math.atan(
                (handrail.y2 - handrail.y1) / (handrail.x2 - handrail.x1)
              ) -
              Math.PI / 2;
            const midpoint = {
              x:
                handrail.x1 +
                Math.floor((handrail.x2 - handrail.x1) / 2) +
                30 * distanceLabelSide * Math.cos(angle),
              y:
                handrail.y1 +
                Math.floor((handrail.y2 - handrail.y1) / 2) -
                30 * distanceLabelSide * Math.sin(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              const adjustedPoint = { ...midpoint };

              adjustedPoint.x = event.mouseX;
              adjustedPoint.y = event.mouseY;

              this.props.dispatch({
                type: "window/open-handrail-length",
                handrail: handrail,
                point: adjustedPoint,
              });
              return handrail;
            } else {
              return false;
            }
          })
          .filter((selected) => selected);

        if (selectedHandrailLabels.size) {
          return;
        }
      }

      // Handle editing the label.
      if (this.props.runs.size) {
        const selectedLabels = this.props.runs
          .map((run) => {
            const distanceLabelsSide = run.getIn(["labels", "distanceLabel"]);

            let distanceLabelSide = 1;

            if (distanceLabelsSide) {
              distanceLabelSide = 1;
            } else {
              distanceLabelSide = -1;
            }

            const angle = Math.atan((run.y2 - run.y1) / (run.x2 - run.x1));
            const midpoint = {
              x:
                run.x1 +
                Math.floor((run.x2 - run.x1) / 2) +
                50 * distanceLabelSide * Math.sin(angle),
              y:
                run.y1 +
                Math.floor((run.y2 - run.y1) / 2) -
                50 * distanceLabelSide * Math.cos(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              const adjustedPoint = { ...midpoint };

              adjustedPoint.x = event.mouseX;
              adjustedPoint.y = event.mouseY;

              this.props.dispatch({
                type: "window/open-run-length",
                run: run,
                point: adjustedPoint,
              });
              return run;
            } else {
              return false;
            }
          })
          .filter((selected) => selected);

        // Return early if selected label was clicked so nothing else triggers.
        if (selectedLabels.size) {
          return;
        }
      }

      // Check for notes.
      if (this.props.notes.size) {
        const selectedNotes = this.props.notes
          .map((note) => {
            return textIntersection(note, this.mousePoint);
          })
          .filter((selected) => selected);

        if (selectedNotes.size) {
          const note = selectedNotes.reduce(
            (_, object, index) => this.props.notes.get(index),
            null
          );

          this.props.dispatch({
            type: "notes/edit-open",
            note: note.set("isEditing", true),
          });

          return;
        }
      }

      // Detect edit length for stairs labels.
      if (this.props.stairs.size) {
        const selectedLabels = this.props.stairs
          .map((stairs) => {
            const { x1, x2, y1, y2 } = stairs;

            const midpoint1 = {
              x: Math.floor((x2 - x1) / 2),
              y: y2,
            };

            const midpoint2 = {
              x: x2,
              y: Math.floor((y2 - y1) / 2),
            };

            if (x2 > x1 && y2 > y1) {
              const point1 = { x: midpoint1.x + x1, y: y1 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                const adjustedPoint = { ...point1 };
                adjustedPoint.x = event.mouseX;
                adjustedPoint.y = event.mouseY;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 1,
                  point: adjustedPoint,
                });
                return stairs;
              }

              const point2 = { x: x2 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                const adjustedPoint = { ...point2 };
                adjustedPoint.x = event.mouseX;
                adjustedPoint.y = event.mouseY;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 2,
                  point: adjustedPoint,
                });
                return stairs;
              }
            }

            if (x2 < x1 && y2 > y1) {
              const point1 = { x: midpoint1.x + x1, y: y1 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                const adjustedPoint = { ...point1 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 1,
                  point: adjustedPoint,
                });
                return stairs;
              }

              const point2 = { x: x1 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                const adjustedPoint = { ...point2 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 2,
                  point: adjustedPoint,
                });
                return stairs;
              }
            }

            if (x2 > x1 && y2 < y1) {
              const point1 = { x: midpoint1.x + x1, y: y2 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                const adjustedPoint = { ...point1 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 1,
                  point: adjustedPoint,
                });
                return stairs;
              }

              const point2 = { x: x2 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                const adjustedPoint = { ...point2 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 2,
                  point: adjustedPoint,
                });
                return stairs;
              }
            }

            if (x2 < x1 && y2 < y1) {
              const point1 = { x: midpoint1.x + x1, y: y2 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                const adjustedPoint = { ...point1 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 1,
                  point: adjustedPoint,
                });
                return stairs;
              }

              const point2 = { x: x1 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                const adjustedPoint = { ...point2 };
                adjustedPoint.x = adjustedPoint.x + this.pan.x;
                adjustedPoint.y = adjustedPoint.y + this.pan.y;
                this.props.dispatch({
                  type: "window/open-stairs-length",
                  stairs: stairs,
                  "point-type": 2,
                  point: adjustedPoint,
                });
                return stairs;
              }
            }

            return false;
          })
          .filter((selected) => selected);

        if (selectedLabels.size) {
          document.body.style.cursor = "text";
          return;
        }
      }

      const corners = calculateCorners(this.props.runs);

      const cornerPoints = getCornerPoints.bind(this)(corners, this.props.runs);

      const selectedCorners = cornerPoints.map((corner) => {
        return isNearPoint(corner.point, this.mousePoint);
      });

      const selectedCornerIndex = selectedCorners.findIndex(
        (selected) => selected === true
      );

      if (selectedCornerIndex !== -1) {
        this.props.dispatch({
          type: "corners/edit-open",
          selectedCorner: cornerPoints[selectedCornerIndex],
        });
        return;
      }

      const selectedRuns = this.props.runs.map((run) =>
        runIntersection(run, this.mousePoint)
      );

      const runIndex = selectedRuns.findKey((selected) => selected === true);

      if (runIndex) {
        this.props.dispatch({
          type: "runs/edit-open",
          runIndex,
          id: this.props.currentProject,
        });
        return;
      }

      // Posts intersection.
      const selectedPosts = this.props.posts.map((post) =>
        isNearPoint({ x: post.x, y: post.y }, this.mousePoint)
      );

      const postIndex = selectedPosts.findKey((selected) => selected === true);

      if (postIndex) {
        this.props.dispatch({
          type: "posts/edit-open",
          postIndex,
          id: this.props.currentProject,
        });
        return;
      }

      const selectedStairs = this.props.stairs.map((stairs) =>
        stairsIntersection(stairs, this.mousePoint)
      );

      const stairsIndex = selectedStairs.findKey(
        (selected) => selected === true
      );

      if (stairsIndex) {
        this.props.dispatch({
          type: "stairs/edit-open",
          stairsIndex,
          id: this.props.currentProject,
        });
        return;
      }

      const selectedGates = this.props.gates.map((gate) =>
        gateIntersection(
          gate,
          this.mousePoint,
          this.props.runs,
          this.props.posts,
          this.props.settings
        )
      );

      const gateIndex = selectedGates.findKey((selected) => selected === true);

      if (gateIndex) {
        this.props.dispatch({
          type: "gates/edit-open",
          gateIndex,
          id: this.props.currentProject,
        });
        return;
      }

      // Shape intersection for opening edit menu.
      if (this.props.shapes.size) {
        const selectedShapes = this.props.shapes.map((polygon) =>
          shapeIntersection(polygon, this.mousePoint)
        );

        const shapeIndex = selectedShapes.findKey(
          (selected) => selected === true
        );

        if (shapeIndex) {
          this.props.dispatch({
            type: "shapes/edit-open",
            shapeIndex,
            id: this.props.currentProject,
          });

          // Return early to prevent other click triggers.
          return;
        }
      }

      // Check for shapes line labels.
      if (this.props.shapes.size) {
        this.props.shapes
          .filter((shape) => shape.type === "line")
          .forEach((shape) => {
            const angle = Math.atan(
              (shape.y2 - shape.y1) / (shape.x2 - shape.x1)
            );
            const midpoint = {
              x:
                shape.x1 +
                Math.floor((shape.x2 - shape.x1) / 2) +
                30 * Math.sin(angle),
              y:
                shape.y1 +
                Math.floor((shape.y2 - shape.y1) / 2) -
                30 * Math.cos(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              const adjustedPoint = { ...midpoint };

              adjustedPoint.x = adjustedPoint.x + this.pan.x;
              adjustedPoint.y = adjustedPoint.y + this.pan.y;

              this.props.dispatch({
                type: "window/open-line-length",
                shape: shape,
                point: adjustedPoint,
              });
              return;
            }
          });
      }

      // Image intersection.
      if (this.props.images.size) {
        const clickedImages = this.props.images
          .map((image) => {
            return imageIntersection(image, this.mousePoint, this.props.images);
          })
          .filter((clicked) => clicked)
          .findKey((clicked) => clicked);

        if (clickedImages) {
          this.props.dispatch({
            type: "images/edit-open",
            imageId: clickedImages,
          });
        }
      }
    }

    if (this.props.tool === "delete-selection") {
      // Handrails intersection.
      const selectedHandrails = this.props.handrails.map((handrail) =>
        handrailIntersection(handrail, this.mousePoint, this.props.runs)
      );

      const handrailIndex = selectedHandrails.findKey(
        (selected) => selected === true
      );

      if (handrailIndex) {
        this.props.dispatch({
          type: "handrails/remove",
          handrailId: handrailIndex,
        });

        // Prevent further deletion.
        return;
      }

      // Posts intersection.
      const selectedPosts = this.props.posts.map((post) =>
        isNearPoint({ x: post.x, y: post.y }, this.mousePoint)
      );

      const postIndex = selectedPosts.findKey((selected) => selected === true);

      if (postIndex) {
        this.props.dispatch({
          type: "posts/remove",
          postIndex,
          id: this.props.currentProject,
        });
        // Prevent further deletion.
        return;
      }

      // Notes intersection.
      const selectedNotes = this.props.notes.map((note) => {
        return textIntersection(note, this.mousePoint);
      });

      const noteIndex = selectedNotes.findKey((selected) => selected === true);

      if (noteIndex) {
        this.props.dispatch({
          type: "notes/remove",
          id: noteIndex,
        });

        return;
      }

      const deletedRuns = this.props.runs.map((run) =>
        runIntersection(run, this.mousePoint)
      );

      const runIndex = deletedRuns.findKey((deleted) => deleted === true);

      // Only delete a run if there is a run to delete.
      if (runIndex) {
        this.props.dispatch({
          type: "runs/remove",
          runIndex,
          id: this.props.currentProject,
        });
        // Prevent further deletion.
        return;
      }

      const deletedShapes = this.props.shapes.map((polygon) =>
        shapeIntersection(polygon, this.mousePoint)
      );

      const shapeIndex = deletedShapes.findKey((deleted) => deleted === true);

      // Only delete a shape if there is a shape to delete.
      if (shapeIndex) {
        this.props.dispatch({
          type: "shapes/remove",
          shapeId: shapeIndex,
          id: this.props.currentProject,
        });
        // Prevent further deletion.
        return;
      }

      const deletedGates = this.props.gates.map((gate) =>
        gateIntersection(
          gate,
          this.mousePoint,
          this.props.runs,
          this.props.posts,
          this.props.settings
        )
      );

      const gateIndex = deletedGates.findKey((selected) => selected === true);

      if (gateIndex) {
        this.props.dispatch({
          type: "gates/remove",
          gateId: gateIndex,
          id: this.props.currentProject,
        });
        // Prevent further deletion.
        return;
      }

      const deletedStairs = this.props.stairs.map((stairs) =>
        stairsIntersection(stairs, this.mousePoint)
      );

      const stairsIndex = deletedStairs.findKey((deleted) => deleted === true);

      // Only delete a stair if there is a stair to delete.
      if (stairsIndex) {
        this.props.dispatch({
          type: "stairs/remove",
          stairsIndex,
          id: this.props.currentProject,
        });
        // Prevent further deletion.
        return;
      }

      if (this.props.images.size) {
        const clickedImages = this.props.images
          .map((image) => {
            return imageIntersection(image, this.mousePoint, this.props.images);
          })
          .filter((clicked) => clicked)
          .findKey((clicked) => clicked);

        if (clickedImages) {
          this.props.dispatch({
            type: "images/remove",
            imageId: clickedImages,
          });
          // Prevent further deletion.
          return;
        }
      }
    }
  };

  doubleClicked = (event) => {
    if (this.isDrawing) {
      this.isDrawing = false;
    }

    if (this.isProjectFrozen()) {
      return;
    }

    if (this.props.tool === "image") {
      // If not clicking on existing image.
      if (false) {
        return;
      }

      this.currentPoint.x = event.mouseX - this.pan.x;
      this.currentPoint.y = event.mouseY - this.pan.y;

      if (this.props.images.size) {
        const clickedImages = this.props.images
          .map((image) => {
            return imageIntersection(
              image,
              this.currentPoint,
              this.props.images
            );
          })
          .filter((clicked) => clicked)
          .findKey((clicked) => clicked);

        if (clickedImages) {
          this.props.dispatch({
            type: "images/edit-open",
            imageId: clickedImages,
          });
        }
      }
    }
  };

  mouseMoved = (event) => {
    this.rawMousePoint = event;
    this.mousePoint = this.calculatePointWithPanScale(event);

    document.body.style.cursor = "default";
    // this.snapLine = null;
    // this.hoveringGrabrailPoint = null;

    if (!this.isDrawing) {
      this.grabrailAnchorPoint = null;
      this.stairCornerIntersections = null;
    }

    if (this.props.tool === "image") {
      if (this.props.images.size) {
        const hoveredImages = this.props.images.map((image) => {
          return imageIntersection(image, this.mousePoint);
        });

        const hoveredImage = hoveredImages.findKey((selected) => selected);

        if (hoveredImage) {
          this.hoveringImage = hoveredImage;
          document.body.style.cursor = "move";
        } else {
          this.hoveringImage = null;
        }

        this.props.images.map((image) => {
          const corners = [];

          corners.push({
            type: "top-left",
            point: {
              x: Math.floor(image.point.x - image.width / 2),
              y: Math.floor(image.point.y - image.height / 2),
            },
            image: image,
          });

          corners.push({
            type: "top-right",
            point: {
              x: Math.floor(image.point.x + image.width / 2),
              y: Math.floor(image.point.y - image.height / 2),
            },
            image: image,
          });

          corners.push({
            type: "bottom-right",
            point: {
              x: Math.floor(image.point.x + image.width / 2),
              y: Math.floor(image.point.y + image.height / 2),
            },
            image: image,
          });

          corners.push({
            type: "bottom-left",
            point: {
              x: Math.floor(image.point.x - image.width / 2),
              y: Math.floor(image.point.y + image.height / 2),
            },
            image: image,
          });

          const hoveredCorners = corners.filter((corner) => {
            return isNearPoint(corner.point, this.mousePoint);
          });

          if (hoveredCorners.length) {
            const hoveredCorner = hoveredCorners[0];

            if (hoveredCorner.type === "top-left") {
              document.body.style.cursor = "nwse-resize";
            } else if (hoveredCorner.type === "top-right") {
              document.body.style.cursor = "nesw-resize";
            } else if (hoveredCorner.type === "bottom-right") {
              document.body.style.cursor = "nwse-resize";
            } else if (hoveredCorner.type === "bottom-left") {
              document.body.style.cursor = "nesw-resize";
            }
          }
          return false;
        });
      }
    }

    if (this.props.tool === "move") {
      if (this.props.stairs.size) {
        const selectedStairs = this.props.stairs.map((stairs) =>
          stairsCornerIntersection(stairs, this.mousePoint)
        );

        const stairsIndex = selectedStairs.findKey((selected) => selected);

        if (stairsIndex) {
          document.body.style.cursor = "grab";
        }

        if (!this.isMovingObject) {
          calculateSnapLines.bind(this)();
        }
      }

      if (this.props.runs.size) {
        const hoveredRuns = this.props.runs.map((run) => {
          return runIntersection(run, this.mousePoint);
        });

        const hoveredRunIndex = hoveredRuns.findKey((selected) => selected);

        if (hoveredRunIndex) {
          document.body.style.cursor = "move";
        }

        const selectedRuns = this.props.runs.map((run) => {
          return (
            isNearPoint({ x: run.x1, y: run.y1 }, this.mousePoint) ||
            isNearPoint({ x: run.x2, y: run.y2 }, this.mousePoint)
          );
        });

        const runIndex = selectedRuns.findKey((selected) => selected);

        if (runIndex) {
          document.body.style.cursor = "grab";
        }
      }

      if (this.props.shapes.size) {
        const hoveredShapes = this.props.shapes.map((shape) => {
          return shapeIntersection(shape, this.mousePoint);
        });

        const hoveredShape = hoveredShapes.findKey((selected) => selected);

        if (hoveredShape) {
          document.body.style.cursor = "move";
        }
      }
    }

    if (this.props.tool === "edit-selection") {
      // Check for run labels.
      if (this.props.runs.size) {
        const selectedLabels = this.props.runs
          .map((run) => {
            const distanceLabelsSide = run.getIn(["labels", "distanceLabel"]);

            let distanceLabelSide = 1;

            if (distanceLabelsSide) {
              distanceLabelSide = 1;
            } else {
              distanceLabelSide = -1;
            }

            const angle = Math.atan((run.y2 - run.y1) / (run.x2 - run.x1));
            const midpoint = {
              x:
                run.x1 +
                Math.floor((run.x2 - run.x1) / 2) +
                50 * distanceLabelSide * Math.sin(angle),
              y:
                run.y1 +
                Math.floor((run.y2 - run.y1) / 2) -
                50 * distanceLabelSide * Math.cos(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              return run;
            } else {
              return false;
            }
          })
          .filter((selected) => selected);

        if (selectedLabels.size) {
          document.body.style.cursor = "text";
          return;
        }

        const selectedHandrailLabels = this.props.handrails
          .map((handrail) => {
            const distanceLabelsSide = handrail.getIn([
              "labels",
              "distanceLabel",
            ]);

            let distanceLabelSide = 1;

            if (distanceLabelsSide) {
              distanceLabelSide = 1;
            } else {
              distanceLabelSide = -1;
            }

            if (handrail.run) {
              // Do not allow attached handrails to have editing of length.
              return false;
            }

            if (handrail.p1.run && handrail.p2.run) {
              // Do not allow attached handrails to have editing of length.
              return false;
            }

            if (handrail.p1.stairsIndex && handrail.p2.stairsIndex) {
              // Do not allow editing length of handrail attached to stairs.
              return false;
            }

            const angle =
              Math.atan(
                (handrail.y2 - handrail.y1) / (handrail.x2 - handrail.x1)
              ) -
              Math.PI / 2;
            const midpoint = {
              x:
                handrail.x1 +
                Math.floor((handrail.x2 - handrail.x1) / 2) +
                30 * distanceLabelSide * Math.cos(angle),
              y:
                handrail.y1 +
                Math.floor((handrail.y2 - handrail.y1) / 2) -
                30 * distanceLabelSide * Math.sin(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              return handrail;
            } else {
              return false;
            }
          })
          .filter((selected) => selected);

        if (selectedHandrailLabels.size) {
          document.body.style.cursor = "text";
          return;
        }
      }

      // Check for notes.
      if (this.props.notes.size) {
        const selectedNotes = this.props.notes
          .map((note) => {
            return textIntersection(note, this.mousePoint);
          })
          .filter((selected) => selected);

        if (selectedNotes.size) {
          document.body.style.cursor = "text";
          return;
        }
      }

      // Check for shapes line labels.
      if (this.props.shapes.size) {
        const selectedLabels = this.props.shapes
          .filter((shape) => shape.type === "line")
          .map((shape) => {
            const angle = Math.atan(
              (shape.y2 - shape.y1) / (shape.x2 - shape.x1)
            );
            const midpoint = {
              x:
                shape.x1 +
                Math.floor((shape.x2 - shape.x1) / 2) +
                30 * Math.sin(angle),
              y:
                shape.y1 +
                Math.floor((shape.y2 - shape.y1) / 2) -
                30 * Math.cos(angle),
            };

            if (isNearPoint(midpoint, this.mousePoint)) {
              return shape;
            } else {
              return false;
            }
          })
          .filter((selected) => selected);

        if (selectedLabels.size) {
          document.body.style.cursor = "text";
          return;
        }
      }

      if (this.props.stairs.size) {
        const selectedLabels = this.props.stairs
          .map((stairs) => {
            const { x1, x2, y1, y2 } = stairs;

            const midpoint1 = {
              x: Math.floor((x2 - x1) / 2),
              y: y2,
            };

            const midpoint2 = {
              x: x2,
              y: Math.floor((y2 - y1) / 2),
            };

            if (x2 > x1 && y2 > y1) {
              const point1 = { x: midpoint1.x + x1, y: y1 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                return stairs;
              }

              const point2 = { x: x2 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                return stairs;
              }
            }

            if (x2 < x1 && y2 > y1) {
              const point1 = { x: midpoint1.x + x1, y: y1 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                return stairs;
              }

              const point2 = { x: x1 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                return stairs;
              }
            }

            if (x2 > x1 && y2 < y1) {
              const point1 = { x: midpoint1.x + x1, y: y2 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                return stairs;
              }

              const point2 = { x: x2 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                return stairs;
              }
            }

            if (x2 < x1 && y2 < y1) {
              const point1 = { x: midpoint1.x + x1, y: y2 - 15 };

              if (isNearPoint(point1, this.mousePoint)) {
                return stairs;
              }

              const point2 = { x: x1 + 15, y: midpoint2.y + y1 };
              if (isNearPoint(point2, this.mousePoint)) {
                return stairs;
              }
            }

            return false;
          })
          .filter((selected) => selected);

        if (selectedLabels.size) {
          document.body.style.cursor = "text";
          return;
        }
      }
    }

    if (this.props.tool === "run") {
      // Handle stairs snapping.
      if (this.props.stairs.size) {
        if (!this.isDrawingStairsRun) {
          const selectedStairs = this.props.stairs
            .filter((stairs) => stairs.type === "stairs")
            .filter((stairs) =>
              stairsCornerIntersection(stairs, this.mousePoint, 15)
            )
            .reduce(
              (corner, stairs, index) => {
                const cornerPoint = nearestStairCornerForRun(
                  stairs,
                  this.mousePoint
                );

                const distance = getDistance(cornerPoint, this.mousePoint);

                if (distance < corner.distance) {
                  return { distance: distance, index };
                }

                return corner;
              },
              { distance: Infinity, index: null }
            );

          const stairsIndex = selectedStairs.index;

          if (stairsIndex) {
            const stairs = this.props.stairs.get(stairsIndex);
            const cornerPoint = nearestStairCornerForRun(
              stairs,
              this.mousePoint
            );
            cornerPoint.phantom = false;

            if (!this.hoveringObject) {
              this.hoveringStairsCorner = {
                startPost: cornerPoint,
                stairsIndex: stairsIndex,
                endPost: cornerPoint,
              };
            }
          } else {
            this.hoveringStairsCorner = null;
          }
        } else {
          const selectedStairs = this.props.stairs
            .filter((stairs) => stairs.type === "stairs")
            .map((stairs) =>
              stairsCornerIntersection(stairs, this.mousePoint, 15)
            );

          const stairsIndex = selectedStairs.findKey((selected) => selected);

          if (stairsIndex) {
            const stairs = this.props.stairs.get(stairsIndex);
            const cornerPoint = nearestStairCornerForRun(
              stairs,
              this.mousePoint
            );
            cornerPoint.phantom = false;

            if (!this.hoveringObject && this.hoveringStairsCorner) {
              this.hoveringStairsCorner.endPost = cornerPoint;
            }
          }
        }
      }

      // Handle run continuation.
      if (this.props.runs.size) {
        const hoveredRuns = this.props.runs
          .map((run) => {
            const endPoint = { x: run.x2, y: run.y2 };
            const hoveredEnd = isNearPoint(endPoint, this.mousePoint);

            if (hoveredEnd) {
              return {
                type: "2",
                run: run.id,
                point: endPoint,
              };
            }

            const startPoint = { x: run.x1, y: run.y1 };
            const hoveredStart = isNearPoint(startPoint, this.mousePoint);

            if (hoveredStart) {
              return {
                type: "1",
                run: run.id,
                point: startPoint,
              };
            }

            return false;
          })
          .filter((point) => point);

        if (hoveredRuns.size && !this.isControlDown) {
          const hoveredRun = hoveredRuns.reduce((acc, point) => point);

          this.hoveringObject = hoveredRun;
        } else {
          this.hoveringObject = null;
        }
      }

      let skipStairsIds = Set();
      // Get active stairs and skip snap line.
      if (this.isSnappedToStairs) {
        skipStairsIds = skipStairsIds.add(this.isSnappedToStairs.id);
      }
      // Handle Run Snapping.
      calculateSnapLines.bind(this)(Set(), skipStairsIds);
    }

    if (this.props.tool === "handrail") {
      if (this.props.runs.size) {
        const corners = calculateCorners(this.props.runs);
        const points = getHandrailAnchorPoints.bind(this)(
          corners,
          this.props.settings,
          this.props.runs,
          this.props.state
        );
        const hovered = getHandrailHoveredAnchorPoints.bind(this)(
          points,
          this.grabrailAnchorPoint
        );

        if (hovered.length) {
          this.hoveringGrabrailPoint = hovered.reduce(
            (acc, point) => point,
            {}
          );
        } else {
          this.hoveringGrabrailPoint = null;
        }
      }
    }

    if (this.props.tool === "shapes" && this.props.shape === "line") {
      if (this.props.shapes.size) {
        calculateSnapLinesForLines.bind(this)();
      }
    }

    if (this.props.tool === "post") {
      if (this.props.runs.size) {
        calculateSnapLines.bind(this)();
      }
    }

    if (this.props.tool === "image") {
      if (this.props.images.size) {
        const hoveredImages = this.props.images
          .map((image) => {
            const hovered = isNearPoint(image, this.mousePoint);

            if (hovered) {
              return image;
            }

            return false;
          })
          .filter((image) => image);

        if (hoveredImages.size) {
          document.body.style.cursor = "grab";
          return;
        }
      }
    }

    if (this.props.tool === "stairs") {
      if (this.props.stairs.size) {
        if (!this.isControlDown) {
          const stairsCorners = calculateStairsSnapCorners.bind(this)();

          let hoveredStairCorners = Map();

          // If not snapped to any stairs.
          if (!this.currentStairsSnap) {
            hoveredStairCorners = stairsCorners.reduce(
              (corners, corner, stairsId) => {
                let nearPoint = null;

                Object.entries(corner.rotatedPoints).forEach(
                  ([cornerType, point]) => {
                    const [x, y] = point;

                    if (isNearPoint({ x, y }, this.mousePoint)) {
                      const distance = distanceBetweenPoints(
                        { x, y },
                        this.mousePoint
                      );

                      nearPoint = { point, cornerType, stairsId, distance };
                    }
                  }
                );

                if (corners.size) {
                  if (nearPoint) {
                    corners.forEach((existingCorner, stairsIndex) => {
                      if (
                        !isCloseToValue(
                          existingCorner.nearPoint.point[0],
                          nearPoint.point[0],
                          1
                        ) ||
                        !isCloseToValue(
                          existingCorner.nearPoint.point[1],
                          nearPoint.point[1],
                          1
                        )
                      ) {
                        if (
                          existingCorner.nearPoint.distance > nearPoint.distance
                        ) {
                          // Points are not close to each other. Use this to snap to the closest element.
                          const newCorner = { ...corner, nearPoint };
                          corners = corners.set(stairsId, newCorner);
                          corners = corners.remove(
                            existingCorner.nearPoint.stairsId
                          );
                        }
                      } else {
                        // Both points are close to each other. Use this to snap to two elements at once.
                        const newCorner = { ...corner, nearPoint };

                        corners = corners.set(stairsId, newCorner);
                      }
                    });
                  }
                } else {
                  // If no corners exist yet check if we have a near point and add it.
                  if (nearPoint) {
                    const newCorner = { ...corner, nearPoint };

                    corners = corners.set(stairsId, newCorner);
                  }
                }

                return corners;
              },
              Map()
            );
          }

          // If drawing and snapped to stairs already.
          if (this.currentStairsSnap) {
            hoveredStairCorners = stairsCorners.reduce(
              (corners, corner, stairsId) => {
                let nearPoints = Map();

                const currentSnap = this.currentStairsSnap.get(stairsId);

                Object.entries(corner.rotatedPoints).forEach(
                  ([cornerType, point]) => {
                    let nearPoint = null;

                    const [x, y] = point;

                    // Ignore current snap point.
                    if (
                      !currentSnap ||
                      !currentSnap.nearPoint.cornerType === cornerType
                    ) {
                      if (isNearPoint({ x, y }, this.mousePoint)) {
                        const distance = distanceBetweenPoints(
                          { x, y },
                          this.mousePoint
                        );
                        nearPoint = {
                          point,
                          cornerType,
                          stairsId,
                          distance,
                        };
                        nearPoints = nearPoints.set(stairsId, nearPoint);
                      }
                    }

                    // Check for snap points of matching dimensions
                    this.currentStairsSnap.forEach((snapPoint) => {
                      let nearPoint = null;

                      const { stairsId: snapId, cornerType: snapType } =
                        snapPoint.nearPoint;

                      const [snapX, snapY] = snapPoint.nearPoint.point;

                      const dx = Math.abs(snapX - this.mousePoint.x);
                      const dy = Math.abs(snapY - this.mousePoint.y);

                      if (
                        snapId === stairsId &&
                        snapType !== cornerType &&
                        dx > 20 &&
                        dy > 20 &&
                        (isCloseToValue(this.mousePoint.x, x, 10) ||
                          isCloseToValue(this.mousePoint.y, y, 10))
                      ) {
                        const distance = distanceBetweenPoints(
                          { x, y },
                          this.mousePoint
                        );

                        if (nearPoints.has(stairsId)) {
                          // If current nearPoint is further than this point, use this point instead.
                          if (nearPoints.get(stairsId).distance > distance) {
                            nearPoint = {
                              point,
                              cornerType,
                              stairsId,
                              distance,
                            };

                            nearPoints = nearPoints.set(stairsId, nearPoint);
                          }
                        } else {
                          nearPoint = {
                            point,
                            cornerType,
                            stairsId,
                            distance,
                          };

                          nearPoints = nearPoints.set(stairsId, nearPoint);
                        }
                      }
                    });
                  }
                );

                if (nearPoints.size) {
                  nearPoints.forEach((nearPoint, stairsIndex) => {
                    const newCorner = { ...corner, nearPoint };
                    corners = corners.set(stairsIndex, newCorner);
                  });
                }

                return corners;
              },
              Map()
            );

            // Check for corners intersecting with box.
            stairsCorners.forEach((corner, stairsId) => {
              const x1 = this.currentPoint.x;
              const y1 = this.currentPoint.y;
              const x2 = this.mousePoint.x;
              const y2 = this.mousePoint.y;

              Object.entries(corner.rotatedPoints).forEach(
                ([cornerType, point]) => {
                  const [x, y] = point;

                  if (
                    isPointBetweenPoints(
                      { x, y },
                      { x: x1, y: y1 },
                      { x: x2, y: y1 }
                    ) ||
                    isPointBetweenPoints(
                      { x, y },
                      { x: x1, y: y1 },
                      { x: x1, y: y2 }
                    ) ||
                    isPointBetweenPoints(
                      { x, y },
                      { x: x2, y: y2 },
                      { x: x2, y: y1 }
                    ) ||
                    isPointBetweenPoints(
                      { x, y },
                      { x: x2, y: y2 },
                      { x: x1, y: y2 }
                    )
                  ) {
                    if (!this.stairCornerIntersections) {
                      this.stairCornerIntersections = Map();
                    }

                    this.stairCornerIntersections =
                      this.stairCornerIntersections.set(stairsId + cornerType, {
                        type: cornerType,
                        point: { x, y },
                      });
                  } else {
                    if (this.stairCornerIntersections) {
                      this.stairCornerIntersections =
                        this.stairCornerIntersections.delete(
                          stairsId + cornerType
                        );
                    }
                  }
                }
              );
            });
          }

          if (hoveredStairCorners.size) {
            this.stairsAlignmentLine = null;
            this.hoveringStairCorners = hoveredStairCorners;
          } else {
            this.hoveringStairCorners = null;

            const stairsAlignmentLines =
              getSnapLinesForAligningStairsToStairs.bind(this)();

            if (stairsAlignmentLines.length) {
              this.stairsAlignmentLine = stairsAlignmentLines.reduce(
                (theLine, line) => {
                  const distance = getNormalDistance(
                    { x: line.x1, y: line.y1 },
                    { x: line.x2, y: line.y2 },
                    this.mousePoint
                  );

                  if (distance < snapDistanceThreshold()) {
                    return line;
                  }

                  return theLine;
                },
                null
              );
            }
          }
        } else {
          this.hoveringStairCorners = null;
          this.stairsAlignmentLine = null;
        }
      }
    }

    if (this.props.tool === "delete-selection") {
      this.snapLine = null;
    }
  };

  mousePressed = (event) => {
    if (this.clickedInUI({ x: event.mouseX, y: event.mouseY })) {
      return;
    }

    if (this.isProjectFrozen()) {
      return;
    }

    if (this.props.tool === "pan") {
      this.panStartPoint.x = event.mouseX;
      this.panStartPoint.y = event.mouseY;
    }

    if (this.props.tool === "move") {
      this.moveStartPoint = this.calculatePointWithPanScale(event);
    }

    if (this.props.tool === "image") {
      this.moveStartPoint = this.calculatePointWithPanScale(event);
    }
  };

  mouseDragged = (event) => {
    this.mousePoint = this.calculatePointWithPanScale(event);
    // this.snapLine = null;

    // If clicking sidebar ignore.
    if (event.mouseX < 60) {
      return;
    }

    if (this.clickedInUI({ x: event.mouseX, y: event.mouseY })) {
      return;
    }

    if (this.isProjectFrozen()) {
      return;
    }

    window.getSelection().removeAllRanges();

    this.isMouseDown = true;

    if (this.props.tool === "pan") {
      if (this.clickedInUI({ x: event.mouseX, y: event.mouseY })) {
        return;
      }

      clearTimeout(this.wheelTimeoutId);

      this.currentPan.x = event.mouseX - this.panStartPoint.x;
      this.currentPan.y = event.mouseY - this.panStartPoint.y;
    }

    if (this.props.tool === "move") {
      // Move run corners.
      if (
        this.props.runs.size &&
        this.movingObjectType !== "run-drag" &&
        this.movingObjectType !== "stairs" &&
        this.movingObjectType !== "shape-drag" &&
        this.movingObjectType !== "image" &&
        this.movingObjectType !== "note-drag" &&
        this.movingObjectsType === null &&
        this.isRotatingObject === false
      ) {
        const selectedRunStarts = this.props.runs
          .filter((run) => {
            let point = { x: run.x1, y: run.y1 };
            let threshold = 10;

            return isNearPoint(point, this.moveStartPoint, threshold);
          })
          .map((run) => run.id);

        const selectedRunStartIndex = selectedRunStarts.reduce(
          (_acc, index) => index,
          null
        );

        if (selectedRunStartIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          const run = this.props.runs.get(selectedRunStartIndex);

          let newX1 = run.x1;
          let newY1 = run.y1;

          if (this.isShiftDown) {
            if (Math.abs(dx) > Math.abs(dy)) {
              newX1 = run.x1 + dx;
            } else {
              newY1 = run.y1 + dy;
            }
          } else {
            newX1 = run.x1 + dx;
            newY1 = run.y1 + dy;
          }

          calculateSnapLines.bind(this)(Set([run.id]));

          if (!this.isControlDown && this.snapLine) {
            if (run.stairs && run.stairs.snapped) {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                getClosestPointOnLine(
                  { x: run.x1, y: run.y1 },
                  { x: run.x2, y: run.y2 },
                  { x: newX1, y: newY1 }
                )
              );

              newX1 = closestPoint.x;
              newY1 = closestPoint.y;
            } else {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                { x: newX1, y: newY1 }
              );

              newX1 = closestPoint.x;
              newY1 = closestPoint.y;
            }
          }

          this.isMovingObject = true;
          this.movingObjectType = "runs";
          this.movingObject = run.setIn(["x1"], newX1).setIn(["y1"], newY1);
        }

        if (selectedRunStartIndex) {
          return;
        }

        const selectedRunEnds = this.props.runs
          .filter((run) => {
            let point = { x: run.x2, y: run.y2 };
            let threshold = 10;

            return isNearPoint(point, this.moveStartPoint, threshold);
          })
          .map((run) => run.id);

        const selectedRunEndIndex = selectedRunEnds.reduce(
          (_acc, index) => index,
          null
        );

        if (selectedRunEndIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          const run = this.props.runs.get(selectedRunEndIndex);

          let newX2 = run.x2;
          let newY2 = run.y2;

          if (this.isShiftDown) {
            if (Math.abs(dx) > Math.abs(dy)) {
              newX2 = run.x2 + dx;
            } else {
              newY2 = run.y2 + dy;
            }
          } else {
            newX2 = run.x2 + dx;
            newY2 = run.y2 + dy;
          }

          calculateSnapLines.bind(this)(Set([run.id]));

          if (!this.isControlDown && this.snapLine) {
            if (run.stairs && run.stairs.snapped) {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                getClosestPointOnLine(
                  { x: run.x1, y: run.y1 },
                  { x: run.x2, y: run.y2 },
                  { x: newX2, y: newY2 }
                )
              );

              newX2 = closestPoint.x;
              newY2 = closestPoint.y;
            } else {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                { x: newX2, y: newY2 }
              );

              newX2 = closestPoint.x;
              newY2 = closestPoint.y;
            }
          }

          this.isMovingObject = true;
          this.movingObjectType = "runs";
          this.movingObject = run.setIn(["x2"], newX2).setIn(["y2"], newY2);
        }

        if (selectedRunEndIndex) {
          return;
        }
      }

      // Move Note.
      if (this.props.notes.size) {
        if (this.movingObjectType === "note-drag") {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          const note = this.props.notes.get(this.movingObject.id);

          const newX = note.x + dx;
          const newY = note.y + dy;

          this.isMovingObject = true;
          this.movingObjectType = "note-drag";
          this.movingObject = note.setIn(["x"], newX).setIn(["y"], newY);

          return;
        } else {
          const hoveredNotes = this.props.notes.map((note) => {
            return textIntersection(note, this.moveStartPoint);
          });

          const hoveredNoteId = hoveredNotes.findKey((selected) => selected);

          if (hoveredNoteId) {
            const dx = this.mousePoint.x - this.moveStartPoint.x;
            const dy = this.mousePoint.y - this.moveStartPoint.y;

            const note = this.props.notes.get(hoveredNoteId);

            const newX = note.x + dx;
            const newY = note.y + dy;

            this.isMovingObject = true;
            this.movingObjectType = "note-drag";
            this.movingObject = note.setIn(["x"], newX).setIn(["y"], newY);

            return;
          }
        }
      }

      // Move Run.
      if (this.props.runs.size) {
        const hoveredRuns = this.props.runs.map((run) => {
          return runIntersection(run, this.moveStartPoint);
        });

        const hoveredRunIndex = hoveredRuns.findKey((selected) => selected);

        if (hoveredRunIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          if (!this.isMovingObjects) {
            this.isMovingObjects = true;
            this.movingObjectsType = "drag-all";
            const run = this.props.runs.get(hoveredRunIndex);

            const corners = calculateCorners(this.props.runs);
            const movingObjects = getConnectedElements(
              run,
              corners,
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );

            this.movingObjects = handleMoveTransformsOfGraph(
              getNodesOfGraph(movingObjects),
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          } else {
            this.movingObjects = handleMoveTransformsOfGraph(
              this.movingObjects,
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          }

          return;
        }
      }

      // Move shapes.
      if (this.props.shapes.size) {
        // Move shapes.
        if (this.movingObjectsType === "shapes-drag") {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          const originalMovingShape = this.originalMovingShape;

          const newX1 = originalMovingShape.x1 + dx;
          const newY1 = originalMovingShape.y1 + dy;
          const newX2 = originalMovingShape.x2 + dx;
          const newY2 = originalMovingShape.y2 + dy;

          const chainIds = this.movingObjects
            .map((chainLine) => {
              return chainLine.id;
            })
            .reduce((ids, id) => {
              return ids.add(id);
            }, Set());

          calculateSnapLinesMovingLine.bind(this)(chainIds, {
            x: newX1,
            y: newY1,
          });

          if (!this.isControlDown && this.snapLine) {
            const closestPoint = getClosestPointOnLine(
              { x: this.snapLine.x1, y: this.snapLine.y1 },
              { x: this.snapLine.x2, y: this.snapLine.y2 },
              { x: newX1, y: newY1 }
            );

            const newShapes = this.movingObjects.map((chainShape) => {
              const newChainX1 = this.props.shapes.get(chainShape.id).x1 + dx;
              const newChainX2 = this.props.shapes.get(chainShape.id).x2 + dx;
              const newChainY1 = this.props.shapes.get(chainShape.id).y1 + dy;
              const newChainY2 = this.props.shapes.get(chainShape.id).y2 + dy;

              chainShape = chainShape
                .setIn(["x1"], closestPoint.x + (newChainX1 - newX1))
                .setIn(
                  ["x2"],
                  closestPoint.x + (newChainX2 - newX2) + (newX2 - newX1)
                )
                .setIn(["y1"], closestPoint.y + (newChainY1 - newY1))
                .setIn(
                  ["y2"],
                  closestPoint.y + (newChainY2 - newY2) + (newY2 - newY1)
                );

              if (originalMovingShape.id === chainShape.id) {
                chainShape = chainShape
                  .setIn(["x1"], closestPoint.x)
                  .setIn(["x2"], closestPoint.x + (newX2 - newX1))
                  .setIn(["y1"], closestPoint.y)
                  .setIn(["y2"], closestPoint.y + (newY2 - newY1));
              }

              return chainShape;
            });

            this.movingObjects = newShapes;
            this.movingObjectsType = "shapes-drag";
            this.isMovingObjects = true;

            return;
          }

          calculateSnapLinesMovingLine.bind(this)(chainIds, {
            x: newX2,
            y: newY2,
          });

          if (!this.isControlDown && this.snapLine) {
            const closestPoint = getClosestPointOnLine(
              { x: this.snapLine.x1, y: this.snapLine.y1 },
              { x: this.snapLine.x2, y: this.snapLine.y2 },
              { x: newX2, y: newY2 }
            );

            const newShapes = this.movingObjects.map((chainShape) => {
              const newChainX1 = this.props.shapes.get(chainShape.id).x1 + dx;
              const newChainX2 = this.props.shapes.get(chainShape.id).x2 + dx;
              const newChainY1 = this.props.shapes.get(chainShape.id).y1 + dy;
              const newChainY2 = this.props.shapes.get(chainShape.id).y2 + dy;

              chainShape = chainShape
                .setIn(
                  ["x1"],
                  closestPoint.x + (newChainX1 - newX1) + (newX1 - newX2)
                )
                .setIn(["x2"], closestPoint.x + (newChainX2 - newX2))
                .setIn(
                  ["y1"],
                  closestPoint.y + (newChainY1 - newY1) + (newY1 - newY2)
                )
                .setIn(["y2"], closestPoint.y + (newChainY2 - newY2));

              if (originalMovingShape.id === chainShape.id) {
                chainShape = chainShape
                  .setIn(["x1"], closestPoint.x + (newX1 - newX2))
                  .setIn(["x2"], closestPoint.x)
                  .setIn(["y1"], closestPoint.y + (newY1 - newY2))
                  .setIn(["y2"], closestPoint.y);
              }

              return chainShape;
            });

            this.movingObjects = newShapes;
            this.movingObjectsType = "shapes-drag";
            this.isMovingObjects = true;

            return;
          }

          const newShapes = this.movingObjects.map((chainShape) => {
            const newX1 = this.props.shapes.get(chainShape.id).x1 + dx;
            const newX2 = this.props.shapes.get(chainShape.id).x2 + dx;
            const newY1 = this.props.shapes.get(chainShape.id).y1 + dy;
            const newY2 = this.props.shapes.get(chainShape.id).y2 + dy;

            chainShape = chainShape
              .setIn(["x1"], newX1)
              .setIn(["x2"], newX2)
              .setIn(["y1"], newY1)
              .setIn(["y2"], newY2);

            return chainShape;
          });

          this.movingObjects = newShapes;
          this.movingObjectsType = "shapes-drag";
          this.isMovingObjects = true;

          return;
        }

        const hoveredShapes = this.props.shapes.map((shape) => {
          return shapeIntersection(shape, this.mousePoint);
        });

        const hoveredShapeIndex = hoveredShapes.findKey((selected) => selected);

        if (
          (this.movingObject && this.movingObjectType === "shape-drag") ||
          (hoveredShapeIndex &&
            this.movingObjectType === null &&
            this.movingObjectsType === null &&
            this.isRotatingObject === false)
        ) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          let shape;

          if (this.movingObject && this.movingObjectType === "shape-drag") {
            shape = this.props.shapes.get(this.movingObject.id);
          } else {
            shape = this.props.shapes.get(hoveredShapeIndex);
          }

          const newX1 = shape.x1 + dx;
          const newX2 = shape.x2 + dx;
          const newY1 = shape.y1 + dy;
          const newY2 = shape.y2 + dy;

          const corners = calculateShapeCorners(this.props.shapes);

          if (!shapeHasCorners(shape, corners)) {
            calculateSnapLinesMovingLine.bind(this)(Set([shape.id]), {
              x: newX1,
              y: newY1,
            });

            if (!this.isControlDown && this.snapLine) {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                { x: newX1, y: newY1 }
              );

              this.isMovingObject = true;
              this.movingObjectType = "shape-drag";
              this.movingObject = shape
                .setIn(["x1"], closestPoint.x)
                .setIn(["x2"], closestPoint.x + (newX2 - newX1))
                .setIn(["y1"], closestPoint.y)
                .setIn(["y2"], closestPoint.y + (newY2 - newY1));

              return;
            }

            calculateSnapLinesMovingLine.bind(this)(Set([shape.id]), {
              x: newX2,
              y: newY2,
            });

            if (!this.isControlDown && this.snapLine) {
              const closestPoint = getClosestPointOnLine(
                { x: this.snapLine.x1, y: this.snapLine.y1 },
                { x: this.snapLine.x2, y: this.snapLine.y2 },
                { x: newX2, y: newY2 }
              );

              this.isMovingObject = true;
              this.movingObjectType = "shape-drag";
              this.movingObject = shape
                .setIn(["x1"], closestPoint.x + (newX1 - newX2))
                .setIn(["x2"], closestPoint.x)
                .setIn(["y1"], closestPoint.y + (newY1 - newY2))
                .setIn(["y2"], closestPoint.y);

              return;
            }

            this.isMovingObject = true;
            this.movingObjectType = "shape-drag";
            this.movingObject = shape
              .setIn(["x1"], newX1)
              .setIn(["x2"], newX2)
              .setIn(["y1"], newY1)
              .setIn(["y2"], newY2);
          } else {
            const chain = getShapeChain(shape, corners, this.props.shapes);

            const newShapes = chain.map((chainShapeId) => {
              let chainShape = this.props.shapes.get(chainShapeId);

              const newX1 = chainShape.x1 + dx;
              const newX2 = chainShape.x2 + dx;
              const newY1 = chainShape.y1 + dy;
              const newY2 = chainShape.y2 + dy;

              chainShape = chainShape
                .setIn(["x1"], newX1)
                .setIn(["x2"], newX2)
                .setIn(["y1"], newY1)
                .setIn(["y2"], newY2);

              return chainShape;
            });

            this.originalMovingShape = shape;
            this.movingObjects = newShapes;
            this.movingObjectsType = "shapes-drag";
            this.isMovingObjects = true;
          }

          return;
        }
      }

      // Move Handrail.
      if (this.props.handrails.size) {
        const selectedHandrail = this.props.handrails.map((handrail) =>
          handrailIntersection(handrail, this.moveStartPoint, this.props.runs)
        );

        const handrailIndex = selectedHandrail.findKey((selected) => selected);

        if (handrailIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          if (!this.isMovingObjects) {
            this.isMovingObjects = true;
            this.movingObjectsType = "drag-all";
            const handrail = this.props.handrails.get(handrailIndex);

            const corners = calculateCorners(this.props.runs);
            const movingObjects = getConnectedElements(
              handrail,
              corners,
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );

            this.movingObjects = handleMoveTransformsOfGraph(
              getNodesOfGraph(movingObjects),
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          } else {
            this.movingObjects = handleMoveTransformsOfGraph(
              this.movingObjects,
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          }
          return;
        }
      }

      // Rotate Stairs and move stairs.
      if (this.props.stairs.size) {
        const selectedStairCorners = this.props.stairs.map((stairs) =>
          stairsCornerIntersection(stairs, this.moveStartPoint)
        );

        const stairsCornerIndex = selectedStairCorners.findKey(
          (selected) => selected
        );

        if (stairsCornerIndex) {
          const stairs = this.props.stairs.get(stairsCornerIndex);

          const corner = selectedStairCorners.get(stairsCornerIndex);

          let axis = "top-left";

          let { x1, y1, x2, y2 } = stairs;

          if (x2 > x1 && y2 > y1) {
            // Adjust coordinates.
            x1 = stairs.x1;
            x2 = stairs.x2;
            y1 = stairs.y1;
            y2 = stairs.y2;
          }

          if (x2 < x1 && y2 > y1) {
            x1 = stairs.x2;
            x2 = stairs.x1;
            y1 = stairs.y1;
            y2 = stairs.y2;
          }

          if (x2 > x1 && y2 < y1) {
            x1 = stairs.x1;
            x2 = stairs.x2;
            y1 = stairs.y2;
            y2 = stairs.y1;
          }

          if (x2 < x1 && y2 < y1) {
            x1 = stairs.x2;
            x2 = stairs.x1;
            y1 = stairs.y2;
            y2 = stairs.y1;
          }

          if (corner === "LB") {
            axis = "top-right";
          }

          if (corner === "RB") {
            axis = "top-left";
          }

          if (corner === "LT") {
            axis = "bottom-right";
          }

          if (corner === "RT") {
            axis = "bottom-left";
          }

          let dx = event.mouseX - this.pan.x - x2;
          let dy = event.mouseY - y1;

          let startingAngle;

          const point = this.calculatePointWithPanScale(event);

          if (corner === "LB") {
            startingAngle = Math.atan2(y2 - y1, x1 - x2);

            dx = point.x - x2;
            dy = point.y - y1;
          }

          if (corner === "RB") {
            startingAngle = Math.atan2(y2 - y1, x2 - x1);

            dx = point.x - x1;
            dy = point.y - y1;
          }

          if (corner === "LT") {
            startingAngle = Math.atan2(y1 - y2, x1 - x2);

            dx = point.x - x2;
            dy = point.y - y2;
          }

          if (corner === "RT") {
            startingAngle = Math.atan2(y1 - y2, x2 - x1);

            dx = point.x - x1;
            dy = point.y - y2;
          }

          const radians = Math.atan2(dy, dx) - startingAngle;
          const degrees = Math.round(radians * (180 / Math.PI));

          const newAngle = degrees - stairs.rotate.get("angle");
          this.isRotatingObject = true;
          this.rotatingObjectType = "stairs";
          this.rotatingObject = stairs
            .setIn(["rotate", "angle"], newAngle)
            .setIn(["rotate", "axis"], axis);

          return;
        }

        const selectedStairs = this.props.stairs.map((stairs) =>
          stairsIntersection(stairs, this.moveStartPoint)
        );

        const stairsIndex = selectedStairs.findKey(
          (selected) => selected === true
        );

        // Only move stairs if it exists.
        if (stairsIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          if (!this.isMovingObjects) {
            this.isMovingObjects = true;
            this.movingObjectsType = "drag-all";
            const stairs = this.props.stairs.get(stairsIndex);

            const corners = calculateCorners(this.props.runs);
            const movingObjects = getConnectedElements(
              stairs,
              corners,
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );

            this.movingObjects = handleMoveTransformsOfGraph(
              getNodesOfGraph(movingObjects),
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          } else {
            this.movingObjects = handleMoveTransformsOfGraph(
              this.movingObjects,
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          }

          return;
        }
      }

      // Posts intersection.
      if (this.props.posts.size) {
        const selectedPosts = this.props.posts.map((post) =>
          isNearPoint({ x: post.x, y: post.y }, this.moveStartPoint)
        );

        const postIndex = selectedPosts.findKey(
          (selected) => selected === true
        );

        if (postIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          if (!this.isMovingObjects) {
            this.isMovingObjects = true;
            this.movingObjectsType = "drag-all";
            const post = this.props.posts.get(postIndex);

            const corners = calculateCorners(this.props.runs);
            const movingObjects = getConnectedElements(
              post,
              corners,
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );

            this.movingObjects = handleMoveTransformsOfGraph(
              getNodesOfGraph(movingObjects),
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          } else {
            this.movingObjects = handleMoveTransformsOfGraph(
              this.movingObjects,
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          }
          return;
        }
      }

      // Move gates
      if (this.props.gates.size) {
        const selectedGates = this.props.gates.map((gate) =>
          gateIntersection(gate, this.moveStartPoint)
        );

        const gateIndex = selectedGates.findKey(
          (selected) => selected === true
        );

        if (gateIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;

          if (!this.isMovingObjects) {
            this.isMovingObjects = true;
            this.movingObjectsType = "drag-all";
            const gate = this.props.gates.get(gateIndex);

            const corners = calculateCorners(this.props.runs);
            const movingObjects = getConnectedElements(
              gate,
              corners,
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );

            this.movingObjects = handleMoveTransformsOfGraph(
              getNodesOfGraph(movingObjects),
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          } else {
            this.movingObjects = handleMoveTransformsOfGraph(
              this.movingObjects,
              {
                transformX1: dx,
                transformY1: dy,
                transformX2: dx,
                transformY2: dy,
              },
              this.props.runs,
              this.props.gates,
              this.props.stairs,
              this.props.posts,
              this.props.handrails
            );
          }
          return;
        }
      }

      // Images.
      if (this.props.images.size) {
        const imageIndex = this.props.images
          .map((image) => {
            return imageIntersection(
              image,
              this.moveStartPoint,
              this.props.images
            );
          })
          .filter((clicked) => clicked)
          .findKey((clicked) => clicked);

        if (imageIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          const image = this.props.images.get(imageIndex);

          const newX = image.point.x + dx;
          const newY = image.point.y + dy;

          this.isMovingObject = true;
          this.movingObjectType = "image";
          this.movingObject = image
            .setIn(["point", "x"], newX)
            .setIn(["point", "y"], newY);

          return;
        }
      }
    }

    if (this.props.tool === "image") {
      if (this.props.images.size) {
        const hoveredImageCorner = this.props.images
          .map((image) => {
            const corners = [];

            corners.push({
              type: "top-left",
              point: {
                x: Math.floor(
                  image.point.x - (image.originalWidth * image.scale) / 2
                ),
                y: Math.floor(
                  image.point.y - (image.originalHeight * image.scale) / 2
                ),
              },
              image: image,
            });

            corners.push({
              type: "top-right",
              point: {
                x: Math.floor(
                  image.point.x + (image.originalWidth * image.scale) / 2
                ),
                y: Math.floor(
                  image.point.y - (image.originalHeight * image.scale) / 2
                ),
              },
              image: image,
            });

            corners.push({
              type: "bottom-right",
              point: {
                x: Math.floor(
                  image.point.x + (image.originalWidth * image.scale) / 2
                ),
                y: Math.floor(
                  image.point.y + (image.originalHeight * image.scale) / 2
                ),
              },
              image: image,
            });

            corners.push({
              type: "bottom-left",
              point: {
                x: Math.floor(
                  image.point.x - (image.originalWidth * image.scale) / 2
                ),
                y: Math.floor(
                  image.point.y + (image.originalHeight * image.scale) / 2
                ),
              },
              image: image,
            });

            const hoveredCorners = corners.filter((corner) => {
              return isNearPoint(corner.point, this.moveStartPoint);
            });

            if (hoveredCorners.length) {
              const hoveredCorner = hoveredCorners[0];

              return hoveredCorner;
            }

            return false;
          })
          .filter((hovered) => hovered)
          .reduce((obj, corner) => {
            return corner;
          });

        if (hoveredImageCorner) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          const { image } = hoveredImageCorner;
          let newX = image.point.x;
          let newY = image.point.y;
          let newScale = image.scale;
          this.isScalingObject = true;

          if (hoveredImageCorner.type === "top-left") {
            if (dx > 0 && dy > 0) {
              const newScaleX =
                image.scale - dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }

            if (dx < 0 && dy < 0) {
              const newScaleX =
                image.scale - dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }
            this.scalingObject = hoveredImageCorner.image
              .setIn(["width"], Math.floor(newScale * image.originalWidth))
              .setIn(["height"], Math.floor(newScale * image.originalHeight))
              .set("scale", newScale)
              .setIn(["point", "x"], newX)
              .setIn(["point", "y"], newY);
          } else if (hoveredImageCorner.type === "top-right") {
            if (dx < 0 && dy > 0) {
              const newScaleX =
                image.scale + dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }

            if (dx > 0 && dy < 0) {
              const newScaleX =
                image.scale - dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }
            this.scalingObject = hoveredImageCorner.image
              .setIn(["width"], Math.floor(newScale * image.originalWidth))
              .setIn(["height"], Math.floor(newScale * image.originalHeight))
              .set("scale", newScale)
              .setIn(["point", "x"], newX)
              .setIn(["point", "y"], newY);
          } else if (hoveredImageCorner.type === "bottom-right") {
            if (dx < 0 && dy < 0) {
              const newScaleY =
                image.scale + dy / (image.originalHeight * image.scale);
              const newScaleX =
                image.scale + dx / (image.originalWidth * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }

            if (dx > 0 && dy > 0) {
              const newScaleX =
                image.scale + dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }
            this.scalingObject = hoveredImageCorner.image
              .setIn(["width"], Math.floor(newScale * image.originalWidth))
              .setIn(["height"], Math.floor(newScale * image.originalHeight))
              .set("scale", newScale)
              .setIn(["point", "x"], newX)
              .setIn(["point", "y"], newY);
          } else if (hoveredImageCorner.type === "bottom-left") {
            if (dx > 0 && dy < 0) {
              const newScaleX =
                image.scale - dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale + dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }

            if (dx < 0 && dy > 0) {
              const newScaleX =
                image.scale - dx / (image.originalWidth * image.scale);
              const newScaleY =
                image.scale - dy / (image.originalHeight * image.scale);
              newScale = newScaleX < newScaleY ? newScaleY : newScaleX;
              newX = Math.floor(image.point.x);
              newY = Math.floor(image.point.y);
            }
            this.scalingObject = hoveredImageCorner.image
              .setIn(["width"], Math.floor(newScale * image.originalWidth))
              .setIn(["height"], Math.floor(newScale * image.originalHeight))
              .set("scale", newScale)
              .setIn(["point", "x"], newX)
              .setIn(["point", "y"], newY);
          }

          return;
        }
        const imageIndex = this.props.images
          .map((image) => {
            return imageIntersection(
              image,
              this.moveStartPoint,
              this.props.images
            );
          })
          .filter((clicked) => clicked)
          .findKey((clicked) => clicked);

        if (imageIndex) {
          const dx = this.mousePoint.x - this.moveStartPoint.x;
          const dy = this.mousePoint.y - this.moveStartPoint.y;
          const image = this.props.images.get(imageIndex);

          const newX = image.point.x + dx;
          const newY = image.point.y + dy;

          this.isMovingObject = true;
          this.movingObjectType = "image";
          this.movingObject = image
            .setIn(["point", "x"], newX)
            .setIn(["point", "y"], newY);

          return;
        }
      }
    }
  };

  keyPressed = (event) => {
    // Pressing shift.
    if (event.keyCode === 16) {
      this.isShiftDown = true;
    }

    // Pressing Escape.
    if (event.keyCode === 27) {
      if (!this.isDrawing && !this.isDrawingStairsRun) {
        this.props.setTool("edit-selection");
      }

      this.isDrawing = false;
      this.stairsRun = null;
      this.hoveringStairsCorner = null;
      this.isDrawingStairsRun = false;
      this.isSnappedToStairs = false;
      this.currentStairsSnap = null;
      this.hoveringStairCorners = null;
      this.stairsAlignmentLine = null;
    }

    // Pressing control.
    if (event.keyCode === 17) {
      this.isControlDown = true;
      this.hoveringObject = null;
    }

    if (document.activeElement && document.activeElement.tagName === "BODY") {
      if (event.key === "r") {
        const canvasDom = document.querySelector("#defaultCanvas0");

        if (!canvasDom) {
          return;
        }

        const box = getCanvasBox(this.props.canvas, canvasDom, {
          x1: 0,
          y1: 0,
          x2: canvasDom.offsetWidth,
          y2: canvasDom.offsetHeight,
        });
        this.scale = box.scale || 1;
        this.pan = box.pan || { x: 0, y: 0 };
        this.props.dispatch({
          type: "canvas/set-pan-scale",
          pan: box.pan || { x: 0, y: 0 },
          scale: box.scale || 1,
        });
      }
    }

    if (this.isProjectFrozen()) {
      return;
    }

    // Ctrl + z undo.
    if (event.keyCode === 90 && this.isControlDown) {
      this.props.dispatch(ActionCreators.undo());
    }

    // Command + z undo on Mac.
    if (event.keyCode === 90 && event.metaKey) {
      this.props.dispatch(ActionCreators.undo());
    }

    // Ctrl + y redo.
    if (event.keyCode === 89 && this.isControlDown) {
      this.props.dispatch(ActionCreators.redo());
    }

    // Command + y redo on Mac.
    if (event.keyCode === 89 && event.metaKey) {
      this.props.dispatch(ActionCreators.redo());
    }

    // Ctrl + shift + z redo.
    if (event.keyCode === 90 && this.isControlDown && this.isShiftDown) {
      this.props.dispatch(ActionCreators.redo());
    }

    // Command + shift + z redo on Mac.
    if (event.keyCode === 90 && event.metaKey && this.isShiftDown) {
      this.props.dispatch(ActionCreators.redo());
    }
  };

  keyReleased = (event) => {
    if (event.keyCode === 16) {
      this.isShiftDown = false;
    }

    if (event.keyCode === 17) {
      this.isControlDown = false;

      // Handle run continuation.
      if (this.props.runs.size) {
        const hoveredRuns = this.props.runs
          .map((run) => {
            const endPoint = { x: run.x2, y: run.y2 };
            const hoveredEnd = isNearPoint(endPoint, this.mousePoint);

            if (hoveredEnd) {
              return {
                type: "2",
                run: run.id,
                point: endPoint,
              };
            }

            const startPoint = { x: run.x1, y: run.y1 };
            const hoveredStart = isNearPoint(startPoint, this.mousePoint);

            if (hoveredStart) {
              return {
                type: "1",
                run: run.id,
                point: startPoint,
              };
            }

            return false;
          })
          .filter((point) => point);

        if (hoveredRuns.size && !this.isControlDown) {
          const hoveredRun = hoveredRuns.reduce((acc, point) => point);

          this.hoveringObject = hoveredRun;
        } else {
          this.hoveringObject = null;
        }
      }
    }
  };

  handleWheel(event) {
    if (this.clickedInUI({ x: event.pageX, y: event.pageY })) {
      return;
    }

    const scaleFactor = (-1 * event.deltaY) / 100 / 10;

    let newScale = this.scale + scaleFactor;
    newScale = Math.round(newScale * 10) / 10;
    newScale = Math.max(0.1, newScale);

    const beforeZoom = this.calculatePointWithPanScale(this.rawMousePoint);

    this.scale = newScale;

    const afterZoom = this.calculatePointWithPanScale(this.rawMousePoint);

    this.pan.x = this.pan.x - (beforeZoom.x - afterZoom.x) * this.scale;
    this.pan.y = this.pan.y - (beforeZoom.y - afterZoom.y) * this.scale;

    clearTimeout(this.wheelTimeoutId);

    this.wheelTimeoutId = setTimeout(() => {
      this.props.dispatch({
        type: "canvas/set-pan-scale",
        pan: { x: this.pan.x, y: this.pan.y },
        scale: this.scale,
      });
    }, 500);
  }

  componentDidMount() {
    window.addEventListener("wheel", this.handleWheel);
    window.addEventListener("paste", this.handleImagePaste);
  }

  componentWillUnmount() {
    window.removeEventListener("wheel", this.handleWheel);
    window.removeEventListener("paste", this.handleImagePaste);
    clearTimeout(this.wheelTimeoutId);

    if (this.windowObserver) {
      this.windowObserver.disconnect();
    }
  }

  render() {
    return (
      <div
        className={this.props.className}
        id={this.props.htmlId}
        ref={this.mount}
      >
        <Sketch
          setup={(p5, parent) => {
            p5.createCanvas(
              this.props.width || window.innerWidth,
              this.props.height || window.innerHeight
            ).parent(parent);

            this.p5 = p5;

            this.font = p5.loadFont("/fonts/SourceSansPro-Regular.ttf");

            if (this.props.images.size) {
              this.props.images.forEach((item, i) => {
                this.imagesLoaded.push(
                  new Promise((resolve, reject) => {
                    const image = new window.Image();

                    image.onload = function () {
                      this.images[item.url] = image;
                      resolve("success");
                    }.bind(this);

                    image.onerror = function () {
                      reject("failed");
                    };

                    image.src = window.location.origin + item.url;

                    if (image.complete) {
                      this.images[item.url] = image;
                    }
                  })
                );
              });
            }

            if (!this.windowObserver) {
              this.windowObserver = new ResizeObserver((entries) => {
                p5.resizeCanvas(
                  this.props.width || window.innerWidth,
                  this.props.height || window.innerHeight
                );
              });

              this.windowObserver.observe(parent);
            }

            p5.textFont(this.font);
            p5.textSize(14);
          }}
          draw={(p5) => {
            // Set background.
            p5.background("#f6f8fa");

            const corners = this.corners;

            p5.translate(
              this.pan.x + this.currentPan.x,
              this.pan.y + this.currentPan.y
            );

            p5.scale(this.scale);

            // Draw snapping for stairs post.
            if (
              this.hoveringStairsCorner &&
              !this.isDrawingStairsRun &&
              !this.isSnappedToStairs
            ) {
              drawStairsPost(p5)(
                this.hoveringStairsCorner.startPost,
                this.props.stairs.get(this.hoveringStairsCorner.stairsIndex),
                this.props.settings
              );
            }

            if (this.snapLine) {
              p5.push();
              p5.drawingContext.setLineDash([5, 5]);
              if (this.snapLine.type && this.snapLine.type === "stairs") {
                p5.stroke(172, 15, 105);
              } else {
                p5.stroke(120);
              }
              p5.line(
                this.snapLine.x1,
                this.snapLine.y1,
                this.snapLine.x2,
                this.snapLine.y2
              );
              p5.pop();
            }

            if (this.stairsAlignmentLine) {
              p5.push();
              p5.drawingContext.setLineDash([5, 5]);
              p5.stroke(172, 15, 105);
              p5.line(
                this.stairsAlignmentLine.x1,
                this.stairsAlignmentLine.y1,
                this.stairsAlignmentLine.x2,
                this.stairsAlignmentLine.y2
              );
              p5.pop();
            }

            if (this.hoveringGrabrailPoint) {
              p5.push();
              if (this.hoveringGrabrailPoint.point.type === "run") {
                p5.stroke("#5586BD");
              } else if (this.hoveringGrabrailPoint.point.type === "stairs") {
                p5.stroke(172, 15, 105);
              } else {
                p5.stroke("#5586BD");
              }
              p5.strokeWeight(1);
              p5.fill("#fff");
              p5.translate(
                this.hoveringGrabrailPoint.point.x,
                this.hoveringGrabrailPoint.point.y
              );
              p5.circle(0, 0, 6);
              p5.pop();
            }

            if (this.hoveringStairCorners) {
              p5.push();
              p5.fill(172, 15, 105, 5);
              p5.stroke(172, 15, 105, 102);
              p5.strokeWeight(1);
              this.hoveringStairCorners.forEach((corner) => {
                p5.push();
                p5.translate(
                  corner.nearPoint.point[0],
                  corner.nearPoint.point[1]
                );
                p5.circle(0, 0, 12);
                p5.pop();
              });
              p5.pop();
            }

            if (this.stairCornerIntersections) {
              this.stairCornerIntersections.forEach((intersection) => {
                p5.push();
                p5.fill(172, 15, 105, 5);
                p5.stroke(172, 15, 105, 102);
                p5.strokeWeight(1);
                p5.translate(intersection.point.x, intersection.point.y);
                p5.circle(0, 0, 12);
                p5.pop();
              });
            }

            this.props.images.forEach((image) => {
              if (this.movingObject && this.movingObject.id === image.id) {
                image = this.movingObject;
              }
              let hovering = false;

              if (this.hoveringImage && this.hoveringImage === image.id) {
                hovering = true;
              }

              if (
                this.isScalingObject &&
                this.scalingObject &&
                this.scalingObject.id === image.id
              ) {
                image = this.scalingObject;
              }
              drawImage(p5)(image, this.images, this.scale, hovering);
            });

            this.props.gates.forEach((gate) => {
              if (
                this.movingObjects &&
                this.movingObjectsType === "drag-all" &&
                this.movingObjects.has(gate.id)
              ) {
                gate = this.movingObjects.get(gate.id);
              }

              drawGate(p5)(
                gate,
                this.props.runs,
                this.props.posts,
                this.props.settings,
                this.props.state
              );
            });

            this.props.stairs.forEach((stair) => {
              if (this.movingObject && this.movingObject.id === stair.id) {
                stair = this.movingObject;
              }

              if (this.rotatingObject && this.rotatingObject.id === stair.id) {
                stair = this.rotatingObject;
              }

              if (
                this.movingObjects &&
                this.movingObjectsType === "drag-all" &&
                this.movingObjects.has(stair.id)
              ) {
                stair = this.movingObjects.get(stair.id);
              }

              if (
                stair.type === "stairs" ||
                typeof stair.type === "undefined"
              ) {
                drawCompletedStairs(p5)(
                  stair,
                  this.props.settings,
                  this.props.canvas
                );
              }

              if (stair.type === "landing") {
                drawLanding(p5)(stair, this.props.settings, this.props.canvas);
              }
            });

            // Draw all shapes. Shapes first so that runs appear on top.
            this.props.shapes.forEach((polygon) => {
              if (this.movingObject && this.movingObject.id === polygon.id) {
                polygon = this.movingObject;
              }

              if (
                this.movingObjects &&
                this.movingObjectsType === "shapes-drag" &&
                this.movingObjects.has(polygon.id)
              ) {
                polygon = this.movingObjects.get(polygon.id);
              }
              drawShape(p5)(polygon);
            });

            // Draw all runs.
            this.props.runs.entrySeq().forEach(([key, run]) => {
              if (run.stairs) {
                if (this.movingObject && this.movingObject.id === run.id) {
                  run = this.movingObject;
                }

                let movingStairs = null;
                let newStairs = null;

                if (
                  this.movingObjects &&
                  this.movingObjectsType === "drag-all" &&
                  this.movingObjects.has(run.id)
                ) {
                  run = this.movingObjects.get(run.id);

                  newStairs = run.getIn(["stairs", "continuousStairs"]);

                  if (newStairs) {
                    newStairs = newStairs.map((s) =>
                      this.movingObjects.get(s.id)
                    );
                  }
                  run = run.setIn(["stairs", "continuousStairs"], newStairs);

                  movingStairs = this.movingObjects.get(run.stairs.stairsIndex);
                }

                if (run.stairs.snapped) {
                  drawRunConnectedToStairs(
                    p5,
                    this,
                    this.props.state.set(
                      "stairs",
                      this.props.stairs.merge(newStairs)
                    ),
                    corners,
                    this.isSnappedToStairs,
                    this.props.canvas,
                    this.props.stairs.merge(newStairs),
                    this.props.runs,
                    null,
                    getRunIndex(run, this.props.state),
                    movingStairs
                  )(run);
                } else {
                  drawStairsRun(
                    p5,
                    this.props.settings,
                    this.props.stairs,
                    false,
                    corners,
                    run.id,
                    run,
                    this,
                    this.props.state,
                    getRunIndex(run, this.props.state),
                    this.props.canvas
                  )(run, run.stairs);
                }
              } else {
                if (this.movingObject && this.movingObject.id === run.id) {
                  run = this.movingObject;
                }

                if (
                  this.movingObjects &&
                  this.movingObjectsType === "runs-drag" &&
                  this.movingObjects.has(run.id)
                ) {
                  run = this.movingObjects.get(run.id);
                }

                if (
                  this.movingObjects &&
                  this.movingObjectsType === "drag-all" &&
                  this.movingObjects.has(run.id)
                ) {
                  run = this.movingObjects.get(run.id);
                }

                drawRun(
                  p5,
                  this,
                  this.props.settings,
                  this.props.runs,
                  run.id,
                  corners,
                  getRunIndex(run, this.props.state),
                  this.props.canvas,
                  this.props.state
                )(run);
              }
            });

            this.props.posts.forEach((post) => {
              if (this.movingObject && this.movingObject.id === post.id) {
                post = this.movingObject;
              }

              if (
                this.movingObjects &&
                this.movingObjectsType === "drag-all" &&
                this.movingObjects.has(post.id)
              ) {
                post = this.movingObjects.get(post.id);
              }
              drawPost(p5)(post, this.props.gates, this.props.settings);
            });

            this.props.handrails.forEach((handrail) => {
              let movingRun = null;
              let runs = this.props.runs;

              if (
                this.movingObjects &&
                this.movingObjectsType === "drag-all" &&
                this.movingObjects.has(handrail.id)
              ) {
                handrail = this.movingObjects.get(handrail.id);

                if (handrail.run && this.movingObjects.has(handrail.run)) {
                  movingRun = this.movingObjects.get(handrail.run);
                }

                if (
                  (handrail.run === null && handrail.p1.run) ||
                  handrail.p2.run
                ) {
                  if (
                    handrail.p1.run &&
                    this.movingObjects.has(handrail.p1.run)
                  ) {
                    movingRun = this.movingObjects.get(handrail.p1.run);
                  }

                  if (
                    handrail.p2.run &&
                    this.movingObjects.has(handrail.p2.run)
                  ) {
                    movingRun = this.movingObjects.get(handrail.p2.run);
                  }
                }

                if (movingRun) {
                  runs = this.movingObjects.filter(
                    (object) => object.type === "run"
                  );
                }
              }

              if (handrail.run) {
                drawHandrailAttached(p5)(
                  handrail,
                  runs,
                  this.cornersWithFlips,
                  this.calculatedFlips,
                  this.props.state,
                  movingRun
                );
              } else {
                drawHandrail(p5)(
                  handrail,
                  runs,
                  this.props.dispatch,
                  this.props.state,
                  corners,
                  movingRun
                );
              }
            });

            // Draw run when run tool is active.
            if (this.isDrawing && this.props.tool === "run") {
              if (this.isDrawingStairsRun) {
                if (this.isSnappedToStairs) {
                  const closestPoint = getClosestPointOnLine(
                    {
                      x: this.isSnappedToStairs.x1,
                      y: this.isSnappedToStairs.y1,
                    },
                    {
                      x: this.isSnappedToStairs.x2,
                      y: this.isSnappedToStairs.y2,
                    },
                    this.mousePoint
                  );

                  drawRunConnectedToStairs(
                    p5,
                    this,
                    this.props.state,
                    corners,
                    this.isSnappedToStairs,
                    this.props.canvas,
                    this.props.stairs,
                    this.props.runs
                  )(
                    Run({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: closestPoint.x,
                      y2: closestPoint.y,
                      stairs: this.stairsRun,
                    })
                  );
                } else {
                  drawStairsRun(
                    p5,
                    this.props.settings,
                    this.props.stairs,
                    this.isShiftDown,
                    corners,
                    "a",
                    Run({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: this.mousePoint.x,
                      y2: this.mousePoint.y,
                    })
                  )(
                    Run({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: this.mousePoint.x,
                      y2: this.mousePoint.y,
                    }),
                    this.stairsRun
                  );
                }
              } else {
                if (this.isSnappedToStairs) {
                  const closestPoint = getClosestPointOnLine(
                    {
                      x: this.isSnappedToStairs.x1,
                      y: this.isSnappedToStairs.y1,
                    },
                    {
                      x: this.isSnappedToStairs.x2,
                      y: this.isSnappedToStairs.y2,
                    },
                    this.mousePoint
                  );

                  drawRunConnectedToStairs(
                    p5,
                    this,
                    this.props.state,
                    corners,
                    this.isSnappedToStairs,
                    this.props.canvas,
                    this.props.stairs,
                    this.props.runs
                  )(
                    Run({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: closestPoint.x,
                      y2: closestPoint.y,
                      stairs: this.stairsRun,
                    })
                  );
                } else {
                  if (!this.isShiftDown) {
                    drawRun(
                      p5,
                      this,
                      this.props.settings,
                      this.props.runs
                    )(
                      Run({
                        x1: this.currentPoint.x,
                        y1: this.currentPoint.y,
                        x2: this.mousePoint.x,
                        y2: this.mousePoint.y,
                      })
                    );
                  } else {
                    drawStraightRun(
                      p5,
                      this,
                      this.props.settings,
                      this.props.runs
                    )(
                      Run({
                        x1: this.currentPoint.x,
                        y1: this.currentPoint.y,
                        x2: this.mousePoint.x,
                        y2: this.mousePoint.y,
                      })
                    );
                  }
                }
              }
            }

            if (this.isDrawing && this.props.tool === "gate") {
              const startPost = this.props.runs
                .map((theRun, runIndex) => {
                  const posts = getPostsFactoringInCorners(
                    theRun,
                    this.props.settings,
                    calculateCorners(this.props.runs),
                    this.props.state
                  );

                  const startingPost = posts.map((post, postIndex) => {
                    if (isNearPoint(this.currentPoint, post)) {
                      return {
                        runIndex: runIndex,
                        postIndex: postIndex,
                      };
                    }

                    return false;
                  });

                  return startingPost.filter((post) => post !== false);
                })
                .filter((posts) => {
                  return posts.length === 1;
                })
                .reduce((acc, post) => {
                  return post;
                }, {});

              if (startPost && startPost.length === 1) {
                const posts = this.props.runs.map((theRun, id) => {
                  return {
                    posts: getPostsFactoringInCorners(
                      theRun,
                      this.props.settings,
                      calculateCorners(this.props.runs),
                      this.props.state
                    ),
                    id,
                  };
                });

                drawGateRun(
                  p5,
                  this.props.runs,
                  this.props.settings,
                  this.props.state
                )(
                  Run({
                    id: startPost[0]["runIndex"],
                    x1: posts.get(startPost[0]["runIndex"]).posts[
                      startPost[0]["postIndex"]
                    ].x,
                    y1: posts.get(startPost[0]["runIndex"]).posts[
                      startPost[0]["postIndex"]
                    ].y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  })
                );
              }

              const clickingOnIndividualPost = this.props.posts
                .map((post) => {
                  if (isNearPoint(this.currentPoint, post)) {
                    return {
                      runIndex: null,
                      postIndex: post.id,
                    };
                  }

                  return false;
                })
                .filter((post) => post !== false)
                .reduce((_acc, post) => post);

              if (clickingOnIndividualPost) {
                const post = this.props.posts.get(
                  clickingOnIndividualPost.postIndex
                );

                drawGateRun(
                  p5,
                  this.props.runs,
                  this.props.settings,
                  this.props.state
                )(
                  Run({
                    id: "does-not-exist",
                    x1: post.x,
                    y1: post.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  })
                );
              }
            }

            if (this.isDrawing && this.props.tool === "handrail") {
              if (!this.isShiftDown) {
                drawHandrail(p5)(
                  Handrail({
                    x1: this.currentPoint.x,
                    y1: this.currentPoint.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  }),
                  this.props.runs,
                  this.props.dispatch,
                  this.props.state,
                  corners
                );
              } else {
                const dx = Math.abs(this.mousePoint.x - this.currentPoint.x);
                const dy = Math.abs(this.mousePoint.y - this.currentPoint.y);

                if (dx > dy) {
                  drawHandrail(p5)(
                    Handrail({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: this.mousePoint.x,
                      y2: this.currentPoint.y,
                    }),
                    this.props.runs,
                    this.props.dispatch,
                    this.props.state,
                    corners
                  );
                } else {
                  drawHandrail(p5)(
                    Handrail({
                      x1: this.currentPoint.x,
                      y1: this.currentPoint.y,
                      x2: this.currentPoint.x,
                      y2: this.mousePoint.y,
                    }),
                    this.props.runs,
                    this.props.dispatch,
                    this.props.state,
                    corners
                  );
                }
              }
            }

            // Draw shapes when shapes tool is active.
            if (this.isDrawing && this.props.tool === "stairs") {
              if (this.props.stairsTool === "stairs") {
                drawStairs(p5)(
                  Stairs({
                    x1: this.currentPoint.x,
                    y1: this.currentPoint.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  })
                );
              }

              if (this.props.stairsTool === "landing") {
                drawLanding(p5)(
                  Landing({
                    x1: this.currentPoint.x,
                    y1: this.currentPoint.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  }),
                  this.props.settings,
                  this.props.canvas
                );
              }
            }

            // Draw shapes when shapes tool is active.
            if (
              this.isDrawing &&
              this.props.tool === "shapes" &&
              this.props.shape
            ) {
              if (!this.isShiftDown) {
                drawShape(p5)(
                  Shape({
                    type: this.props.shape,
                    x1: this.currentPoint.x,
                    y1: this.currentPoint.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  })
                );
              } else {
                drawPerfectShape(p5)(
                  Shape({
                    type: this.props.shape,
                    x1: this.currentPoint.x,
                    y1: this.currentPoint.y,
                    x2: this.mousePoint.x,
                    y2: this.mousePoint.y,
                  })
                );
              }
            }

            this.props.notes.forEach((note) => {
              if (this.movingObject && this.movingObject.id === note.id) {
                note = this.movingObject;
              }

              drawNote(p5)(note);
            });

            //drawGrid(p5, this.pan, this.currentPan, this.scale);

            if (this.props.renderResolve) {
              if (this.mount.current) {
                const mount = this.mount.current;
                Promise.allSettled(this.imagesLoaded).then((images) => {
                  const domNode = mount.querySelector("canvas");

                  if (domNode) {
                    setTimeout(() => {
                      this.props.renderResolve(domNode.toDataURL("image/png"));
                    }, 0);
                  }
                });
              }
            }
          }}
          mouseDragged={this.mouseDragged}
          mouseReleased={this.mouseReleased}
          mouseClicked={this.mouseClicked}
          doubleClicked={this.doubleClicked}
          mousePressed={this.mousePressed}
          mouseMoved={this.mouseMoved}
          keyPressed={this.keyPressed}
          keyReleased={this.keyReleased}
        />
      </div>
    );
  }
}

function calculateStairsSnapCornersFunc(alreadyCalculated) {
  return function () {
    const hash = this.props.stairs.hashCode().toString();

    if (alreadyCalculated.has(hash)) {
      return alreadyCalculated.get(hash);
    }

    const points = this.props.stairs.map((stairs) => {
      let corners = {};

      corners.points = getUnrotatedStairsCorners(stairs);
      corners.rotatedPoints = getRotatedStairsCorners(stairs);
      corners.type = stairs.type;
      corners.orientation = stairs.orientation;
      corners.rotate = stairs.rotate;

      return corners;
    });

    alreadyCalculated = alreadyCalculated.set(hash, points);

    return points;
  };
}

const calculateStairsSnapCorners = calculateStairsSnapCornersFunc(Map());

function calculateSnapLines(skipRunIds = Set(), skipStairsIds = Set()) {
  let normalLines = Map();

  if (this.props.runs.size) {
    normalLines = getSnapLines.bind(this)(skipRunIds);
  }

  const snapLine = normalLines
    .map((line) => {
      const distance = getNormalDistance(
        { x: line.x1, y: line.y1 },
        { x: line.x2, y: line.y2 },
        this.mousePoint
      );

      return { distance, line };
    })
    .filter(({ distance }) => distance < snapDistanceThreshold())
    .reduce((acc, { distance, line }) => {
      if (!acc) {
        return { distance, line };
      }

      if (distance < acc.distance) {
        return { distance, line };
      }

      return acc;
    }, null);

  this.snapLine = snapLine ? snapLine.line : null;

  let stairLines = Map();

  if (this.props.stairs.size) {
    stairLines = getSnapLinesForConnectingRunToStairs.bind(this)(skipStairsIds);
  }

  const stairLine = stairLines
    .map((line) => {
      const distance = getNormalDistance(
        { x: line.x1, y: line.y1 },
        { x: line.x2, y: line.y2 },
        this.mousePoint
      );

      let distanceToStairs = Infinity;

      let check = getDistance(
        { x: line.originalPoints.x1, y: line.originalPoints.y1 },
        this.mousePoint
      );

      if (check < distanceToStairs) {
        distanceToStairs = check;
      }

      check = getDistance(
        { x: line.originalPoints.x2, y: line.originalPoints.y2 },
        this.mousePoint
      );

      if (check < distanceToStairs) {
        distanceToStairs = check;
      }

      check = getDistance(
        { x: line.originalPoints.x1, y: line.originalPoints.y2 },
        this.mousePoint
      );

      if (check < distanceToStairs) {
        distanceToStairs = check;
      }

      check = getDistance(
        { x: line.originalPoints.x2, y: line.originalPoints.y1 },
        this.mousePoint
      );

      if (check < distanceToStairs) {
        distanceToStairs = check;
      }

      return { distance, line, distanceToStairs };
    })
    .filter(({ distance }) => {
      if (distance < snapDistanceThreshold()) {
        return true;
      }

      return false;
    })
    .reduce((acc, { distance, line, distanceToStairs }) => {
      if (!acc) {
        return { distance, line, distanceToStairs };
      }

      if (distanceToStairs < acc.distanceToStairs) {
        return { distance, line, distanceToStairs };
      }

      if (this.hoveringObject) {
        if (distance < acc.distance) {
          return { distance, line, distanceToStairs };
        }
      }

      return acc;
    }, null);

  if (snapLine) {
    if (
      stairLine &&
      stairLine.line &&
      (stairLine.distance <= snapLine.distance ||
        isCloseToValue(snapLine.distance, stairLine.distance, 10))
    ) {
      this.snapLine = stairLine.line;
    }
  } else {
    this.snapLine = stairLine ? stairLine.line : null;
  }
}

function calculateSnapLinesForLines(skipShapeIds = Set()) {
  const normalLines = getSnapLinesForLines.bind(this)(skipShapeIds);

  normalLines.forEach((line) => {
    const distance = getNormalDistance(
      { x: line.x1, y: line.y1 },
      { x: line.x2, y: line.y2 },
      this.mousePoint
    );

    if (distance < snapDistanceThreshold()) {
      this.snapLine = line;
    }
  });
}

function calculateSnapLinesMovingLine(skipShapeIds = Set(), point) {
  const normalLines = getSnapLinesForLines.bind(this)(skipShapeIds);

  normalLines.forEach((line) => {
    const distance = getNormalDistance(
      { x: line.x1, y: line.y1 },
      { x: line.x2, y: line.y2 },
      point
    );

    if (distance < snapDistanceThreshold()) {
      this.snapLine = line;
    }
  });

  return point;
}

function getSnapLinesForAligningStairsToStairsFunc(alreadyCalculated) {
  return function (skipStairsIds = Set()) {
    const hash =
      this.props.stairs.hashCode().toString() +
      skipStairsIds.hashCode().toString();

    if (alreadyCalculated.has(hash)) {
      return alreadyCalculated.get(hash);
    }

    const lines = this.props.stairs
      .filter((stair) => stair.type === "stairs" || stair.type === "landing")
      .filter((stair) => !skipStairsIds.has(stair.id))
      .map((stair) => {
        const orientation = stair.orientation;
        const bigNum = 20000;

        let { x1, y1, x2, y2 } = stair;

        if (x2 > x1 && y2 > y1) {
          // Adjust coordinates.
          x1 = stair.x1;
          x2 = stair.x2;
          y1 = stair.y1;
          y2 = stair.y2;
        }

        if (x2 < x1 && y2 > y1) {
          x1 = stair.x2;
          x2 = stair.x1;
          y1 = stair.y1;
          y2 = stair.y2;
        }

        if (x2 > x1 && y2 < y1) {
          x1 = stair.x1;
          x2 = stair.x2;
          y1 = stair.y2;
          y2 = stair.y1;
        }

        if (x2 < x1 && y2 < y1) {
          x1 = stair.x2;
          x2 = stair.x1;
          y1 = stair.y2;
          y2 = stair.y1;
        }

        if (stair.type === "stairs" && orientation === "vertical") {
          let slope = Infinity;
          let lines = {
            left: [],
            right: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const angleRadians = (angle * Math.PI) / 180;
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          if (angle === 0) {
            slope = Infinity;

            lines.left = [
              { x: x1, y: y1 },
              { x: x1, y: y2 },
            ];
            lines.right = [
              { x: x2, y: y1 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.LB[0], y: vertices.LB[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.RT[0], y: vertices.RT[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];
          }

          const postWidth = 0;

          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + Math.round(postWidth / 2), y: bigNum },
                  { x: line[0].x + Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y +
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y -
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - Math.round(postWidth / 2), y: bigNum },
                  { x: line[1].x - Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y +
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y -
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        } else if (stair.type === "stairs" && orientation === "horizontal") {
          let slope = Infinity;
          let lines = {
            left: [],
            right: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const angleRadians = (angle * Math.PI) / 180;
          const perpendicularAngleRadians = angleRadians - Math.PI / 2;
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          if (angle === 0) {
            slope = 0;

            lines.left = [
              { x: x1, y: y1 },
              { x: x2, y: y1 },
            ];

            lines.right = [
              { x: x1, y: y2 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.RT[0], y: vertices.RT[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.LB[0], y: vertices.LB[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];
          }

          const postWidth = 0;

          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + postWidth / 2, y: bigNum },
                  { x: line[0].x + postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y +
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y -
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - postWidth / 2, y: bigNum },
                  { x: line[1].x - postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y +
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y -
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        }

        if (stair.type === "landing") {
          let slope = Infinity;
          let horizontalSlope = Infinity;
          let lines = {
            left: [],
            right: [],
            top: [],
            bottom: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          // Vertical snaps for landing.
          if (angle === 0) {
            slope = Infinity;

            lines.left = [
              { x: x1, y: y1 },
              { x: x1, y: y2 },
            ];
            lines.right = [
              { x: x2, y: y1 },
              { x: x2, y: y2 },
            ];

            horizontalSlope = 0;

            lines.top = [
              { x: x1, y: y1 },
              { x: x2, y: y1 },
            ];

            lines.bottom = [
              { x: x1, y: y2 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.LB[0], y: vertices.LB[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.RT[0], y: vertices.RT[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];

            // Rotate.
            const horizontalVertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.top = [
              { x: horizontalVertices.LT[0], y: vertices.LT[1] },
              { x: horizontalVertices.RT[0], y: vertices.RT[1] },
            ];

            horizontalSlope =
              (lines.top[1].y - lines.top[0].y) /
              (lines.top[1].x - lines.top[0].x);

            lines.bottom = [
              { x: horizontalVertices.LB[0], y: horizontalVertices.LB[1] },
              { x: horizontalVertices.RB[0], y: horizontalVertices.RB[1] },
            ];
          }

          // Horizontal snaps for landing.
          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x, y: bigNum },
                  { x: line[0].x, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x: line[0].x + dx,
                    y: line[0].y + dy,
                  },
                  {
                    x: line[0].x - dx,
                    y: line[0].y - dy,
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x, y: bigNum },
                  { x: line[1].x, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x: line[1].x + dx,
                    y: line[1].y + dy,
                  },
                  {
                    x: line[1].x - dx,
                    y: line[1].y - dy,
                  },
                ]);
              }
            }

            if (type === "top") {
              if (horizontalSlope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x, y: bigNum },
                  { x: line[0].x, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx =
                  bigNum / Math.sqrt(1 + horizontalSlope * horizontalSlope);
                const dy = horizontalSlope * dx;

                newPoints = newPoints.concat([
                  {
                    x: line[0].x + dx,
                    y: line[0].y + dy,
                  },
                  {
                    x: line[0].x - dx,
                    y: line[0].y - dy,
                  },
                ]);
              }
            }

            if (type === "bottom") {
              if (horizontalSlope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x, y: bigNum },
                  { x: line[1].x, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx =
                  bigNum / Math.sqrt(1 + horizontalSlope * horizontalSlope);
                const dy = horizontalSlope * dx;

                newPoints = newPoints.concat([
                  {
                    x: line[1].x + dx,
                    y: line[1].y + dy,
                  },
                  {
                    x: line[1].x - dx,
                    y: line[1].y - dy,
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        }

        return {
          id: stair.id,
          points: [],
          originalPoints: {
            x1,
            y1,
            x2,
            y2,
          },
        };
      })
      .reduce((lines, stairLines) => {
        const { points } = stairLines;

        if (points[0] && points[0].length === 2) {
          lines.push({
            x1: points[0][0].x,
            y1: points[0][0].y,
            x2: points[0][1].x,
            y2: points[0][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[1] && points[1].length === 2) {
          lines.push({
            x1: points[1][0].x,
            y1: points[1][0].y,
            x2: points[1][1].x,
            y2: points[1][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[2] && points[2].length === 2) {
          lines.push({
            x1: points[2][0].x,
            y1: points[2][0].y,
            x2: points[2][1].x,
            y2: points[2][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[3] && points[3].length === 2) {
          lines.push({
            x1: points[3][0].x,
            y1: points[3][0].y,
            x2: points[3][1].x,
            y2: points[3][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        return lines;
      }, []);

    alreadyCalculated = alreadyCalculated.set(hash, lines);

    return lines;
  };
}

const getSnapLinesForAligningStairsToStairs =
  getSnapLinesForAligningStairsToStairsFunc(Map());

function getSnapLinesForConnectingRunToStairsFunc(alreadyCalculated) {
  return function (skipStairsIds = Set()) {
    const hash =
      this.props.stairs.hashCode().toString() +
      skipStairsIds.hashCode().toString();

    if (alreadyCalculated.has(hash)) {
      return alreadyCalculated.get(hash);
    }

    const lines = this.props.stairs
      .filter((stair) => stair.type === "stairs" || stair.type === "landing")
      .filter((stair) => !skipStairsIds.has(stair.id))
      .map((stair) => {
        const orientation = stair.orientation ? stair.orientation : "vertical";
        const bigNum = 20000;

        let { x1, y1, x2, y2 } = stair;

        if (x2 > x1 && y2 > y1) {
          // Adjust coordinates.
          x1 = stair.x1;
          x2 = stair.x2;
          y1 = stair.y1;
          y2 = stair.y2;
        }

        if (x2 < x1 && y2 > y1) {
          x1 = stair.x2;
          x2 = stair.x1;
          y1 = stair.y1;
          y2 = stair.y2;
        }

        if (x2 > x1 && y2 < y1) {
          x1 = stair.x1;
          x2 = stair.x2;
          y1 = stair.y2;
          y2 = stair.y1;
        }

        if (x2 < x1 && y2 < y1) {
          x1 = stair.x2;
          x2 = stair.x1;
          y1 = stair.y2;
          y2 = stair.y1;
        }

        if (stair.type === "stairs" && orientation === "vertical") {
          let slope = Infinity;
          let lines = {
            left: [],
            right: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const angleRadians = (angle * Math.PI) / 180;
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          if (angle === 0) {
            slope = Infinity;

            lines.left = [
              { x: x1, y: y1 },
              { x: x1, y: y2 },
            ];
            lines.right = [
              { x: x2, y: y1 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.LB[0], y: vertices.LB[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.RT[0], y: vertices.RT[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];
          }

          const postWidth = getPostWidth();

          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + Math.round(postWidth / 2), y: bigNum },
                  { x: line[0].x + Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y +
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y -
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - Math.round(postWidth / 2), y: bigNum },
                  { x: line[1].x - Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y +
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y -
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        } else if (stair.type === "stairs" && orientation === "horizontal") {
          let slope = Infinity;
          let lines = {
            left: [],
            right: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const angleRadians = (angle * Math.PI) / 180;
          const perpendicularAngleRadians = angleRadians - Math.PI / 2;
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          if (angle === 0) {
            slope = 0;

            lines.left = [
              { x: x1, y: y1 },
              { x: x2, y: y1 },
            ];

            lines.right = [
              { x: x1, y: y2 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.RT[0], y: vertices.RT[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.LB[0], y: vertices.LB[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];
          }

          const postWidth = getPostWidth();

          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + postWidth / 2, y: bigNum },
                  { x: line[0].x + postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y +
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y -
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - postWidth / 2, y: bigNum },
                  { x: line[1].x - postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y +
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y -
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        }

        if (stair.type === "landing") {
          let slope = Infinity;
          let horizontalSlope = Infinity;
          let lines = {
            left: [],
            right: [],
            top: [],
            bottom: [],
          };

          const angle = stair.getIn(["rotate", "angle"]);
          const angleRadians = (angle * Math.PI) / 180;
          const rotationAxis = stair.getIn(["rotate", "axis"]);

          // Vertical snaps for landing.
          if (angle === 0) {
            slope = Infinity;

            lines.left = [
              { x: x1, y: y1 },
              { x: x1, y: y2 },
            ];
            lines.right = [
              { x: x2, y: y1 },
              { x: x2, y: y2 },
            ];

            horizontalSlope = 0;

            lines.top = [
              { x: x1, y: y1 },
              { x: x2, y: y1 },
            ];

            lines.bottom = [
              { x: x1, y: y2 },
              { x: x2, y: y2 },
            ];
          } else {
            // Rotate.
            const vertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.left = [
              { x: vertices.LT[0], y: vertices.LT[1] },
              { x: vertices.LB[0], y: vertices.LB[1] },
            ];

            slope =
              (lines.left[1].y - lines.left[0].y) /
              (lines.left[1].x - lines.left[0].x);

            lines.right = [
              { x: vertices.RT[0], y: vertices.RT[1] },
              { x: vertices.RB[0], y: vertices.RB[1] },
            ];

            // Rotate.
            const horizontalVertices = findVerticesOfStairs(
              { x1, y1, x2, y2 },
              angle,
              rotationAxis
            );

            lines.top = [
              { x: horizontalVertices.LT[0], y: vertices.LT[1] },
              { x: horizontalVertices.RT[0], y: vertices.RT[1] },
            ];

            horizontalSlope =
              (lines.top[1].y - lines.top[0].y) /
              (lines.top[1].x - lines.top[0].x);

            lines.bottom = [
              { x: horizontalVertices.LB[0], y: horizontalVertices.LB[1] },
              { x: horizontalVertices.RB[0], y: horizontalVertices.RB[1] },
            ];
          }

          // Horizontal snaps for landing.
          const perpendicularAngleRadians = angleRadians - Math.PI / 2;

          const postWidth = getPostWidth();

          const linePoints = Object.entries(lines).map(([type, line]) => {
            let newPoints = [];

            if (type === "left") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + Math.round(postWidth / 2), y: bigNum },
                  { x: line[0].x + Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y +
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx +
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[0].y -
                      dy +
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            if (type === "right") {
              if (slope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - Math.round(postWidth / 2), y: bigNum },
                  { x: line[1].x - Math.round(postWidth / 2), y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx = bigNum / Math.sqrt(1 + slope * slope);
                const dy = slope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y +
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx -
                      Math.round(postWidth / 2) * Math.cos(angleRadians),
                    y:
                      line[1].y -
                      dy -
                      Math.round(postWidth / 2) * Math.sin(angleRadians),
                  },
                ]);
              }
            }

            if (type === "top") {
              if (horizontalSlope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[0].x + postWidth / 2, y: bigNum },
                  { x: line[0].x + postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx =
                  bigNum / Math.sqrt(1 + horizontalSlope * horizontalSlope);
                const dy = horizontalSlope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[0].x +
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y +
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[0].x -
                      dx -
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[0].y -
                      dy -
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            if (type === "bottom") {
              if (horizontalSlope === Infinity) {
                newPoints = newPoints.concat([
                  { x: line[1].x - postWidth / 2, y: bigNum },
                  { x: line[1].x - postWidth / 2, y: -1 * bigNum },
                ]);
              } else {
                // Calculate with numerical slope.
                const dx =
                  bigNum / Math.sqrt(1 + horizontalSlope * horizontalSlope);
                const dy = horizontalSlope * dx;

                newPoints = newPoints.concat([
                  {
                    x:
                      line[1].x +
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y +
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                  {
                    x:
                      line[1].x -
                      dx +
                      (postWidth / 2) * Math.cos(perpendicularAngleRadians),
                    y:
                      line[1].y -
                      dy +
                      (postWidth / 2) * Math.sin(perpendicularAngleRadians),
                  },
                ]);
              }
            }

            return newPoints;
          });

          return {
            id: stair.id,
            points: linePoints,
            originalPoints: {
              x1,
              y1,
              x2,
              y2,
            },
          };
        }

        return {
          id: stair.id,
          points: [],
          originalPoints: {
            x1,
            y1,
            x2,
            y2,
          },
        };
      })
      .reduce((lines, stairLines) => {
        const { points } = stairLines;

        if (points[0] && points[0].length === 2) {
          lines.push({
            x1: points[0][0].x,
            y1: points[0][0].y,
            x2: points[0][1].x,
            y2: points[0][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[1] && points[1].length === 2) {
          lines.push({
            x1: points[1][0].x,
            y1: points[1][0].y,
            x2: points[1][1].x,
            y2: points[1][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[2] && points[2].length === 2) {
          lines.push({
            x1: points[2][0].x,
            y1: points[2][0].y,
            x2: points[2][1].x,
            y2: points[2][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        if (points[3] && points[3].length === 2) {
          lines.push({
            x1: points[3][0].x,
            y1: points[3][0].y,
            x2: points[3][1].x,
            y2: points[3][1].y,
            type: "stairs",
            id: stairLines.id,
            originalPoints: stairLines.originalPoints,
          });
        }

        return lines;
      }, []);

    alreadyCalculated = alreadyCalculated.set(hash, lines);

    return lines;
  };
}

const getSnapLinesForConnectingRunToStairs =
  getSnapLinesForConnectingRunToStairsFunc(Map());

function getPostWidth() {
  return 10;
}

function getSnapLinesFunc(alreadyCalculated) {
  return function (skipRunIds = Set()) {
    const hash =
      this.props.runs.hashCode().toString() + skipRunIds.hashCode().toString();

    if (alreadyCalculated.has(hash)) {
      return alreadyCalculated.get(hash);
    }

    const lines = this.props.runs
      .filter((run) => !skipRunIds.has(run.id))
      .map((run) => {
        let slope;

        if (run.x2 - run.x1 === 0) {
          slope = Infinity;
        } else {
          slope = (run.y2 - run.y1) / (run.x2 - run.x1);
        }

        let normalSlope;

        if (run.y2 - run.y1 === 0) {
          normalSlope = Infinity;
        } else {
          normalSlope = (-1 * (run.x2 - run.x1)) / (run.y2 - run.y1);
        }

        const bigNum = 20000;

        const points = [
          { x: run.x1, y: run.y1 },
          { x: run.x2, y: run.y2 },
        ];

        return points.map((point) => {
          let newPoints = [];
          if (normalSlope !== Infinity) {
            if (normalSlope === 0) {
              newPoints = newPoints.concat([
                { x: bigNum, y: point.y },
                { x: -1 * bigNum, y: point.y },
              ]);
            } else {
              // Calculate with numerical slope.
              const dx = bigNum / Math.sqrt(1 + normalSlope * normalSlope);
              const dy = normalSlope * dx;

              newPoints = newPoints.concat([
                { x: point.x + dx, y: point.y + dy },
                { x: point.x - dx, y: point.y - dy },
              ]);
            }
          } else {
            newPoints = newPoints.concat([
              { x: point.x, y: bigNum },
              { x: point.x, y: -1 * bigNum },
            ]);
          }

          if (slope !== Infinity) {
            if (slope === 0) {
              newPoints = newPoints.concat([
                { x: bigNum, y: point.y },
                { x: -1 * bigNum, y: point.y },
              ]);
            } else {
              // Calculate with numerical slope.
              const dx = bigNum / Math.sqrt(1 + slope * slope);
              const dy = slope * dx;

              newPoints = newPoints.concat([
                { x: point.x + dx, y: point.y + dy },
                { x: point.x - dx, y: point.y - dy },
              ]);
            }
          } else {
            newPoints = newPoints.concat([
              { x: point.x, y: bigNum },
              { x: point.x, y: -1 * bigNum },
            ]);
          }

          return newPoints;
        });
      })
      .reduce((lines, points) => {
        lines.push({
          x1: points[0][0].x,
          y1: points[0][0].y,
          x2: points[0][1].x,
          y2: points[0][1].y,
        });

        lines.push({
          x1: points[1][0].x,
          y1: points[1][0].y,
          x2: points[1][1].x,
          y2: points[1][1].y,
        });

        lines.push({
          x1: points[0][2].x,
          y1: points[0][2].y,
          x2: points[0][3].x,
          y2: points[0][3].y,
        });

        lines.push({
          x1: points[1][2].x,
          y1: points[1][2].y,
          x2: points[1][3].x,
          y2: points[1][3].y,
        });

        return lines;
      }, []);

    alreadyCalculated = alreadyCalculated.set(hash, lines);

    return lines;
  };
}

const getSnapLines = getSnapLinesFunc(Map());

function getSnapLinesForLinesFunc(alreadyCalculated) {
  return function (skipShapeIds = Set()) {
    const hash =
      this.props.shapes.hashCode().toString() +
      skipShapeIds.hashCode().toString();

    if (alreadyCalculated.has(hash)) {
      return alreadyCalculated.get(hash);
    }

    const lines = this.props.shapes
      .filter((shape) => shape.type === "line")
      .filter((shape) => !skipShapeIds.has(shape.id))
      .map((shape) => {
        let slope;

        if (shape.x2 - shape.x1 === 0) {
          slope = Infinity;
        } else {
          slope = (shape.y2 - shape.y1) / (shape.x2 - shape.x1);
        }

        let normalSlope;

        if (shape.y2 - shape.y1 === 0) {
          normalSlope = Infinity;
        } else {
          normalSlope = (-1 * (shape.x2 - shape.x1)) / (shape.y2 - shape.y1);
        }

        const bigNum = 20000;

        const points = [
          { x: shape.x1, y: shape.y1 },
          { x: shape.x2, y: shape.y2 },
        ];

        return points.map((point) => {
          let newPoints = [];
          if (normalSlope !== Infinity) {
            if (normalSlope === 0) {
              newPoints = newPoints.concat([
                { x: bigNum, y: point.y },
                { x: -1 * bigNum, y: point.y },
              ]);
            } else {
              // Calculate with numerical slope.
              const dx = bigNum / Math.sqrt(1 + normalSlope * normalSlope);
              const dy = normalSlope * dx;

              newPoints = newPoints.concat([
                { x: point.x + dx, y: point.y + dy },
                { x: point.x - dx, y: point.y - dy },
              ]);
            }
          } else {
            newPoints = newPoints.concat([
              { x: point.x, y: bigNum },
              { x: point.x, y: -1 * bigNum },
            ]);
          }

          if (slope !== Infinity) {
            if (slope === 0) {
              newPoints = newPoints.concat([
                { x: bigNum, y: point.y },
                { x: -1 * bigNum, y: point.y },
              ]);
            } else {
              // Calculate with numerical slope.
              const dx = bigNum / Math.sqrt(1 + slope * slope);
              const dy = slope * dx;

              newPoints = newPoints.concat([
                { x: point.x + dx, y: point.y + dy },
                { x: point.x - dx, y: point.y - dy },
              ]);
            }
          } else {
            newPoints = newPoints.concat([
              { x: point.x, y: bigNum },
              { x: point.x, y: -1 * bigNum },
            ]);
          }

          return newPoints;
        });
      })
      .reduce((lines, points) => {
        lines.push({
          x1: points[0][0].x,
          y1: points[0][0].y,
          x2: points[0][1].x,
          y2: points[0][1].y,
        });

        lines.push({
          x1: points[1][0].x,
          y1: points[1][0].y,
          x2: points[1][1].x,
          y2: points[1][1].y,
        });

        lines.push({
          x1: points[0][2].x,
          y1: points[0][2].y,
          x2: points[0][3].x,
          y2: points[0][3].y,
        });

        lines.push({
          x1: points[1][2].x,
          y1: points[1][2].y,
          x2: points[1][3].x,
          y2: points[1][3].y,
        });

        return lines;
      }, []);

    alreadyCalculated = alreadyCalculated.set(hash, lines);

    return lines;
  };
}

const getSnapLinesForLines = getSnapLinesForLinesFunc(Map());

function getHandrailAnchorPoints(corners, settings, runs, state) {
  const calculatedFlips = this.calculatedRunFlips;

  const stairs = state.stairs;

  const points = this.props.runs
    .map((run) => {
      const posts = getPosts(run, settings, state);

      const { x1, y1, x2, y2 } = run;

      const angle = Math.atan2(y2 - y1, x2 - x1);
      const perpendicularAngle = angle - Math.PI / 2;

      const xComponent = roundToHundreth(Math.cos(perpendicularAngle)) * 10;
      const yComponent = roundToHundreth(Math.sin(perpendicularAngle)) * 10;

      const {
        newLineX1,
        newLineY1,
        newLineX2,
        newLineY2,
        newPostX1,
        newPostY1,
        newPostX2,
        newPostY2,
      } = generateHandrailAnchorPoints(
        calculatedFlips,
        this.props.runs,
        run,
        corners,
        1
      );
      const {
        startingCorner,
        endingCorner,
        newLineX1: newLineX1Flip,
        newLineY1: newLineY1Flip,
        newLineX2: newLineX2Flip,
        newLineY2: newLineY2Flip,
        newPostX1: newPostX1Flip,
        newPostY1: newPostY1Flip,
        newPostX2: newPostX2Flip,
        newPostY2: newPostY2Flip,
      } = generateHandrailAnchorPoints(
        calculatedFlips,
        this.props.runs,
        run,
        corners,
        -1
      );

      const handrailPoints = [];

      posts.forEach((post, index) => {
        if (index === 0) {
          handrailPoints.push({
            default: {
              x: newPostX1,
              y: newPostY1,
              run: run.id,
              postIndex: index,
              corner: false,
              isEndCorner: false,
              type: "run",
              matchingPoint: {
                x: newPostX1Flip,
                y: newPostY1Flip,
              },
            },
            flip: {
              x: newPostX1Flip,
              y: newPostY1Flip,
              run: run.id,
              postIndex: index,
              corner: false,
              type: "run",
              isEndCorner: false,
              matchingPoint: {
                x: newPostX1,
                y: newPostY1,
              },
            },
          });

          if (startingCorner) {
            handrailPoints.push({
              default: {
                x: newLineX1,
                y: newLineY1,
                run: run.id,
                postIndex: index,
                corner: true,
                type: "run",
                isEndCorner: true,
                flip: false,
                matchingPoint: {
                  x: newLineX1Flip,
                  y: newLineY1Flip,
                },
              },
              flip: {
                x: newLineX1Flip,
                y: newLineY1Flip,
                run: run.id,
                postIndex: index,
                corner: true,
                type: "run",
                isEndCorner: true,
                flip: true,
                matchingPoint: {
                  x: newLineX1,
                  y: newLineY1,
                },
              },
            });
          }

          return;
        }

        if (index === posts.length - 1) {
          handrailPoints.push({
            default: {
              x: newPostX2,
              y: newPostY2,
              run: run.id,
              postIndex: index,
              corner: false,
              type: "run",
              isEndCorner: false,
              flip: false,
              matchingPoint: {
                x: newPostX2Flip,
                y: newPostY2Flip,
              },
            },
            flip: {
              x: newPostX2Flip,
              y: newPostY2Flip,
              run: run.id,
              postIndex: index,
              corner: false,
              type: "run",
              isEndCorner: false,
              flip: true,
              matchingPoint: {
                x: newPostX2,
                y: newPostY2,
              },
            },
          });

          if (endingCorner) {
            handrailPoints.push({
              default: {
                x: newLineX2,
                y: newLineY2,
                run: run.id,
                postIndex: index,
                corner: true,
                type: "run",
                isEndCorner: true,
                flip: false,
                matchingPoint: {
                  x: newLineX2Flip,
                  y: newLineY2Flip,
                },
              },
              flip: {
                x: newLineX2Flip,
                y: newLineY2Flip,
                run: run.id,
                postIndex: index,
                corner: true,
                type: "run",
                isEndCorner: true,
                flip: true,
                matchingPoint: {
                  x: newLineX2,
                  y: newLineY2,
                },
              },
            });
          }

          return;
        }

        handrailPoints.push({
          default: {
            x: post.x + xComponent,
            y: post.y + yComponent,
            run: run.id,
            postIndex: index,
            corner: false,
            type: "run",
            isEndCorner: false,
            matchingPoint: {
              x: post.x + xComponent * -1,
              y: post.y + yComponent * -1,
            },
          },
          flip: {
            x: post.x + xComponent * -1,
            y: post.y + yComponent * -1,
            run: run.id,
            postIndex: index,
            corner: false,
            type: "run",
            isEndCorner: false,
            matchingPoint: {
              x: post.x + xComponent,
              y: post.y + yComponent,
            },
          },
        });
        return;
      });

      return handrailPoints;
    })
    .reduce((list, pointsList) => {
      list.push(pointsList);

      return list;
    }, []);

  if (stairs.size) {
    stairs.forEach((theStairs) => {
      const rotatedStairsCorners = getRotatedStairsCorners(theStairs);
      const TL = rotatedStairsCorners.TL;
      const TR = rotatedStairsCorners.TR;
      const BL = rotatedStairsCorners.BL;
      const BR = rotatedStairsCorners.BR;

      TL[2] = "TL";
      TR[2] = "TR";
      BL[2] = "BL";
      BR[2] = "BR";

      const stairsPoints = [TL, TR, BL, BR].map((corner) => {
        return {
          default: {
            x: corner[0],
            y: corner[1],
            run: null,
            postIndex: null,
            corner: false,
            stairsIndex: theStairs.id,
            type: "stairs",
            cornerType: corner[2],
            isEndCorner: false,
            matchingPoint: null,
          },
        };
      });

      points.push(stairsPoints);
    });
  }

  return points;
}

function getHandrailHoveredAnchorPoints(points, anchorPoint) {
  const threshold = 20;
  return points
    .flat()
    .map((pointsList) => {
      return Object.entries(pointsList).map(([type, point]) => {
        if (isNearPoint(this.mousePoint, point, threshold)) {
          if (anchorPoint) {
            if (anchorPoint.point.run === point.run) {
              return {
                hovered: true,
                point: point,
                type: type,
              };
            } else if (anchorPoint.stairsIndex === point.stairsIndex) {
              return {
                hovered: true,
                point: point,
                type: type,
              };
            } else {
              return false;
            }
          } else {
            return {
              hovered: true,
              point: point,
              type: type,
            };
          }
        } else {
          return false;
        }
      });
    })
    .flat()
    .filter((point) => point !== false)
    .reduce((finalPoints, point) => {
      let currentMin = threshold;

      if (finalPoints[0]) {
        currentMin = getDistance(this.mousePoint, finalPoints[0].point);

        if (getDistance(this.mousePoint, point.point) < currentMin) {
          finalPoints = [point];
        }
      } else {
        finalPoints.push(point);
      }

      return finalPoints;
    }, []);
}

function getMatchingCornerAnchorPoints(points, anchorPoint) {
  const matching = points
    .flat()
    .map((pointsList) => {
      return Object.entries(pointsList).map(([type, point]) => {
        if (isNearPoint(this.mousePoint, point, 10)) {
          if (anchorPoint.point.run !== point.run) {
            return {
              hovered: true,
              point: point,
              type: type,
            };
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    })
    .flat()
    .filter((point) => point !== false);
  if (matching.length) {
    return matching.reduce((acc, point) => point, {});
  } else {
    return null;
  }
}

const getCurrentPointType = (checkPoint, points) => {
  return Object.entries(points).reduce((type, point) => {
    const [key, value] = point;
    const [x, y] = value;

    if (
      isCloseToValue(x, checkPoint[0], 1) ||
      isCloseToValue(y, checkPoint[1], 1)
    ) {
      if (!type.length) {
        return key;
      } else {
        const newDistance = getDistance(
          { x, y },
          {
            x: checkPoint[0],
            y: checkPoint[1],
          }
        );
        const oldDistance = getDistance(
          { x, y },
          { x: points[type][0], y: points[type][1] }
        );

        if (newDistance < oldDistance) {
          return key;
        }
      }
    }

    return type;
  }, "");
};

export const getContinuousSpanOfStairs = (
  stairs,
  stairsIndex,
  runConnection
) => {
  const theStairs = stairs.get(stairsIndex);

  let continousStairs = OrderedMap();

  if (theStairs && theStairs.stairsEdges.size) {
    const { start, end } = runConnection.keys;

    const startVertical = start[0];
    const startHorizontal = start[1];

    const endVertical = end[0];
    const endHorizontal = end[1];

    let edgeKeys = [];

    // Moving horizontally.
    if (startVertical === endVertical) {
      edgeKeys = ["stairs-right-to-stairs", "stairs-left-to-stairs"];
    }

    // Moving vertically.
    if (startHorizontal === endHorizontal) {
      edgeKeys = ["stairs-bottom-to-stairs", "stairs-top-to-stairs"];
    }

    continousStairs = getNextContinuousStair(stairs, stairsIndex, edgeKeys);
  } else {
    continousStairs = continousStairs.set(stairsIndex, theStairs);
  }

  return continousStairs;
};

export function getNextContinuousStair(
  stairs,
  stairsIndex,
  edgeKeys,
  collection = OrderedMap()
) {
  const theStairs = stairs.get(stairsIndex);

  collection = collection.set(stairsIndex, theStairs);

  if (theStairs && theStairs.stairsEdges.size) {
    edgeKeys.forEach((edgeKey) => {
      const edge = theStairs.stairsEdges.get(edgeKey);

      if (edge) {
        if (!collection.has(edge.to)) {
          collection = getNextContinuousStair(
            stairs,
            edge.to,
            edgeKeys,
            collection
          );
        }
      }
    });
  }

  return collection;
}

function getCornerPoints(corners, runs) {
  return corners.map((corner) => {
    const point = Object.values(corner.points)
      .map((point) => {
        const runId = point.id;
        const run = runs.get(runId);
        const type = point.type;
        const result = {};
        if (type === "2") {
          result.x = run.x2;
          result.y = run.y2;
        }
        if (type === "1") {
          result.x = run.x1;
          result.y = run.y1;
        }
        return result;
      })
      .reduce((acc, point) => {
        acc = point;
        return acc;
      }, {});

    const stateCorners = this.props.state.corners;

    if (stateCorners.size > 0) {
      const hash = cornerHash(corner);

      if (stateCorners.has(hash)) {
        return stateCorners.get(hash);
      }
    }

    return Corner({
      id: Map(corner.points),
      identity: cornerHash(corner),
      point: point,
    });
  });
}

function subdivideRectangle({ x1, y1, x2, y2 }, xCoords, yCoords) {
  // Sort the coordinate lists
  xCoords.sort((a, b) => a - b);
  yCoords.sort((a, b) => a - b);

  if (x1 > x2) {
    [x1, x2] = [x2, x1];
  }

  if (y1 > y2) {
    [y1, y2] = [y2, y1];
  }

  // Include the initial rectangle boundaries in the coordinate lists
  if (!isCloseToValue(xCoords[0], x1, 4)) {
    xCoords.unshift(x1);
  }

  if (!isCloseToValue(xCoords[xCoords.length - 1], x2, 4)) {
    xCoords.push(x2);
  }

  if (!isCloseToValue(yCoords[0], y1, 4)) {
    yCoords.unshift(y1);
  }

  if (!isCloseToValue(yCoords[yCoords.length - 1], y2, 4)) {
    yCoords.push(y2);
  }

  // Create a 2D array to store the subdivided rectangles
  const subdividedRectangles = [];
  const seen = Map();

  // Loop through the x and y coordinates to create the rectangles
  for (let i = 1; i < xCoords.length; i++) {
    for (let j = 1; j < yCoords.length; j++) {
      const rect = Map({
        x1: xCoords[i - 1],
        y1: yCoords[j - 1],
        x2: xCoords[i],
        y2: yCoords[j],
      });

      const hash = rect.hashCode();

      if (!seen.has(hash)) {
        subdividedRectangles.push(rect.toObject());
      } else {
        seen.set(hash, true);
      }
    }
  }

  return subdividedRectangles;
}
