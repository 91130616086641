const defaultWindows = {
  "project-settings": 0,
  "edit-popup": 0,
  "add-run-hardware": 0,
  "add-post-hardware": 0,
  "add-canvas-hardware": 0,
  "canvas-settings": 0,
  "generate-pdf": 0,
  "shipping-estimate": 0,
  "edit-run-length": 0,
  "edit-stairs-length": 0,
  "export-sales-order-dialog": 0,
  "project-frozen-dialog": 2,
};

export type DefaultWindows = typeof defaultWindows & {
  "save-override"?: number;
  //   "add-canvas-hardware"?: number;
  "edit-handrail-length"?: number;
  "edit-line-length"?: number;
};

export type WindowsZIndexReducerState = DefaultWindows;

export type WindowsZIndexReducerAction =
  | { type: "app/open-project" }
  | { type: "window/click"; id: string }
  | { type: "window/open-save-override" }
  | { type: "window/open-add-hardware-run" }
  | { type: "window/open-add-hardware-post" }
  | { type: "window/open-add-hardware-canvas" }
  | { type: "window/generate-pdf-open" }
  | { type: "window/open-shipping-estimate" }
  | { type: "window/open-run-length" }
  | { type: "window/open-handrail-length" }
  | { type: "window/open-stairs-length" }
  | { type: "window/open-line-length" }
  | { type: "window/open-project-frozen" }
  | { type: "window/export-sales-order-dialog-open" };

export function windowsZIndexReducer(
  windowState: WindowsZIndexReducerState = defaultWindows,
  action: WindowsZIndexReducerAction
): WindowsZIndexReducerState {
  switch (action.type) {
    case "app/open-project":
      return defaultWindows;
    case "window/click":
      const popups = defaultWindows;

      return { ...popups, [action.id]: 1 };
    case "window/open-save-override":
      return { ...defaultWindows, "save-override": 1 };
    case "window/open-add-hardware-run":
      return { ...defaultWindows, "add-run-hardware": 1 };
    case "window/open-add-hardware-post":
      return { ...defaultWindows, "add-post-hardware": 1 };
    case "window/open-add-hardware-canvas":
      return { ...defaultWindows, "add-canvas-hardware": 1 };
    case "window/generate-pdf-open":
      return { ...defaultWindows, "generate-pdf": 1 };
    case "window/open-shipping-estimate":
      return { ...defaultWindows, "shipping-estimate": 1 };
    case "window/open-run-length":
      return { ...defaultWindows, "edit-run-length": 1 };
    case "window/open-handrail-length":
      return { ...defaultWindows, "edit-handrail-length": 1 };
    case "window/open-stairs-length":
      return { ...defaultWindows, "edit-stairs-length": 1 };
    case "window/open-line-length":
      return { ...defaultWindows, "edit-line-length": 1 };
    case "window/open-project-frozen":
      return { ...defaultWindows, "project-frozen-dialog": 2 };
    case "window/export-sales-order-dialog-open":
      return { ...windowState, "export-sales-order-dialog": 1 };
    default:
      return windowState;
  }
}
