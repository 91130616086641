import { ItemSubtotal } from "./parsers/itemSubtotalParser";

const itemSubtotal: ItemSubtotal = {
  "?xml": {
    "@_version": "1.0",
  },
  QBXML: {
    QBXMLMsgsRs: {
      ItemSubtotalQueryRs: {
        ItemSubtotalRet: {
          ListID: "800003D8-1510599165",
          TimeCreated: "2017-11-13T10:52:45-08:00",
          TimeModified: "2017-12-15T14:40:40-08:00",
          EditSequence: 1513377640,
          Name: "SubTotal",
          IsActive: true,
          ItemDesc: "Sub Total",
        },
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default itemSubtotal;
