// @ts-nocheck
import { Map } from "immutable";
import {
  ItemList,
  PostItemList,
  findByUpc,
  mapQBObjectToJS,
} from "./RunItemList";
import {
  findProductByFullName,
  getUnitOfMeasure,
  pixelsPerFoot,
  getNumberOfCableRuns,
} from "../utils";
import { Post, Canvas, Project, ProjectSettings, Run } from "../entities";
import { AllInventoryTypes } from "../data/fetch-inventory";
import { RunItemList } from "./RunItemList/RunItemList";
import { ItemListType } from "./RunItemList/itemListTypes";
import sortItemList from "./sortItemList";
import { roundToHundreth } from "../utils";
export {
  getItemListsByCanvas,
  getMasterItemList,
  getItemLists,
  getItemList,
  roundToHundreth,
} from "./getItemLists";

export function handleCanvasOverrides(itemList, inventory, overrides) {
  if (overrides && overrides.size) {
    const overridden = {};

    overrides.forEach((override) => {
      if (override.type === "quantityChange") {
        overridden[override.product.upc] = true;

        itemList = handleQuantityChangeOverrides(itemList, override);
      }

      if (override.type === "addItemToCanvas") {
        const upc =
          override.product.BarCodeValue ||
          override.product.upc ||
          override.product.FullName;

        overridden[upc] = true;

        if (override.product.type === "group") {
          override.product.SalesDesc = override.product.FullName;
          const groupItems = override.product.ItemGroupLine;

          // Do not add group line item to parts list.
          // if (!itemList[upc]) {
          //   const price = parseFloat(override.product.SalesPrice) || 0;

          //   itemList[upc] = {
          //     quantity: override.quantity,
          //     measure: "ea",
          //     upc: upc,
          //     description: override.product.SalesDesc,
          //     name: override.product.Name,
          //     price: price,
          //     total: roundToHundreth(override.quantity * price),
          //     type: override.product.type,
          //     addedItem: true,
          //   };
          // } else {
          //   itemList[upc].quantity += override.quantity;
          //   itemList[upc].total = roundToHundreth(
          //     itemList[upc].quantity * itemList[upc].price
          //   );
          // }

          if (groupItems && groupItems.length) {
            groupItems.forEach((groupItem) => {
              const product = findProductByFullName(
                groupItem?.ItemRef?.FullName,
                inventory
              );

              if (product) {
                const upc =
                  product.BarCodeValue || product.upc || product.FullName;

                overridden[upc] = true;

                if (!itemList[upc]) {
                  const price = parseFloat(product.SalesPrice) || 0;

                  itemList[upc] = {
                    quantity:
                      override.quantity *
                      parseFloat(groupItem.Quantity || 0, 10),
                    measure: getUnitOfMeasure(groupItem.UnitOfMeasure),
                    upc: upc,
                    description: product.SalesDesc,
                    name: product.Name,
                    price: price,
                    total: roundToHundreth(
                      override.quantity *
                        parseFloat(groupItem.Quantity || 0, 10) *
                        price
                    ),
                    addedItem: true,
                  };
                } else {
                  itemList[upc].quantity +=
                    override.quantity * parseFloat(groupItem.Quantity || 0, 10);
                  itemList[upc].total = roundToHundreth(
                    itemList[upc].quantity * itemList[upc].price
                  );
                }
              }
            });
          }
        } else {
          if (!itemList[upc]) {
            let product = findByUpc(upc, inventory);

            if (product) {
              product = mapQBObjectToJS(product);

              itemList[upc] = {
                quantity: override.quantity,
                measure: "ea",
                upc: upc,
                description: product.description,
                name: product.name,
                price: product.price,
                total: roundToHundreth(override.quantity * product.price),
                addedItem: true,
                type: product.type,
              };
            } else {
              const price = parseFloat(override.product.SalesPrice) || 0;

              itemList[upc] = {
                quantity: override.quantity,
                measure: "ea",
                upc: upc,
                description: override.product.SalesDesc,
                name: override.product.Name,
                price: price,
                total: roundToHundreth(override.quantity * price),
                addedItem: true,
                type: override.product.type,
              };
            }
          } else {
            itemList[upc].quantity += override.quantity;
            itemList[upc].total = roundToHundreth(
              itemList[upc].quantity * itemList[upc].price
            );
          }
        }
      }

      if (override.type === "removeItemFromCanvas") {
        let id = parseInt(override.id, 10);

        if (isNaN(id)) {
          id = override.id;
        }

        delete itemList[id];
      }

      if (override.type === "removeGroupItem") {
        const upc = override.id;
        const group = override.group;

        if (itemList[upc]) {
          // If group is present remove the quantity of the item, if the quantity ends up at 0 or below delete item from item list.
          // Remove the item from the parts list.
          const quantity = itemList[upc].quantity;

          if (
            group.product.ItemGroupLine &&
            group.product.ItemGroupLine.length
          ) {
            const matchingProduct = group.product.ItemGroupLine.find((item) => {
              const theItem = findProductByFullName(
                item?.ItemRef?.FullName,
                inventory
              );

              let theUpc = 0;

              if (theItem) {
                theUpc =
                  theItem.BarCodeValue || theItem.upc || theItem.FullName;
              }

              return theUpc === upc;
            });

            if (matchingProduct) {
              itemList[upc].quantity =
                quantity - group.quantity * matchingProduct.Quantity;

              if (itemList[upc].quantity <= 0) {
                delete itemList[upc];
              }
            }
          }
        }
      }
    });

    Object.keys(overridden).forEach((key) => {
      if (itemList[key] && itemList[key].quantity < 0) {
        itemList[key].quantity = 0;
        itemList[key].total = 0;
      }
    });
  }

  return itemList;
}

export function changeUnitOfMeasure(itemList) {
  const upcsToChange = [
    // AL P2P toprail
    616320729899, // BlK p2p toprail
    616320729905, // bronze p2p toprail
    616320729912, // clay p2p toprail
    616320729929, // natural p2p toprail
    616320729936, // white p2p toprail
    662187020334, // Anodized black p2p toprail
    662187019444, // Anodized clear p2p toprail
    // AL rect toprail
    616320729998, // black rect
    616320730000, // bronze rect
    616320730017, // clay rect
    616320730024, // natural rect
    616320730031, // white rect
    662187020303, // Anodized black rect
    662187019468, // anodized clear rect
    // AL Shaped toprail
    616320730048, // black shaped
    616320730055, // bronze shaped
    616320730062, // clay shaped
    616320730079, // natural shaped
    616320730086, // white shaped
  ];

  upcsToChange.forEach((upc) => {
    if (itemList[upc]) {
      itemList[upc].measure = "ft";
    }
  });

  return itemList;
}

export function handleQuantityChangeOverrides(
  itemList: ItemListType,
  override: any
) {
  if (itemList[override.product.upc]) {
    itemList[override.product.upc].quantity += override.quantityChange;

    itemList[override.product.upc].total = roundToHundreth(
      itemList[override.product.upc].quantity *
        (itemList[override.product.upc].price || 0)
    );
  }

  return itemList;
}

/**
 * This function is not in use, and is serving as a historical placeholder as this was how we previously handled overrides of quantity.
 * The product quantity would be fixed at the override amount instead of using a diff.
 *
 * @param {*} itemList
 * @param {*} override
 * @returns
 */
export function handleQuantityChangeFixedQuantitiesOverrides(
  itemList: ItemListType,
  override: any
) {
  if (itemList[override.product.upc]) {
    itemList[override.product.upc].quantity = override.newQuantity;
    itemList[override.product.upc].total = roundToHundreth(
      itemList[override.product.upc].quantity *
        (itemList[override.product.upc].price || 0)
    );
  }

  return itemList;
}

export const getTotal = (itemList: ItemListType) => {
  let total = 0;

  Object.values(itemList).forEach((item) => {
    if (item.type !== "subtotal") {
      total += item.total || 0;
    }
  });

  return roundToHundreth(total);
};

export const getSubTotal = (itemList: ItemListType) => {
  let total = 0;

  Object.values(itemList).forEach((item) => {
    if (
      item.type !== "subtotal" &&
      item.type !== "otherCharge" &&
      item.type !== "discount"
    ) {
      total += item.total || 0;
    }
  });

  return roundToHundreth(total);
};

export const getTotalWithoutPercentDiscounts = (itemList: ItemListType) => {
  let total = 0;

  Object.values(itemList).forEach((item) => {
    if (
      item.type !== "subtotal" &&
      (item.type !== "discount" || !item.discountPercent)
    ) {
      total += item.total || 0;
    }
  });

  return roundToHundreth(total);
};

export function getItemListForRun(
  settings: ProjectSettings,
  run: Run,
  inventory: AllInventoryTypes[],
  state: Project
) {
  const runItemList = RunItemList(settings, run, inventory, state);
  return changeUnitOfMeasure(runItemList.getItemList());
}

export function getItemListForPost(
  settings: ProjectSettings,
  post: Post,
  inventory: AllInventoryTypes[]
) {
  const postItemList = PostItemList(settings, post, inventory);

  const itemList = changeUnitOfMeasure(postItemList.getItemList());

  return itemList;
}

export const calculateRailingAmount = (
  distance: number,
  settings: ProjectSettings
) => {
  const numCables = getNumberOfCableRuns(settings);

  // Add four percent to cable.
  const distanceInFeet = distanceToFeet(distance);

  const remainder = (distanceInFeet % 1).toFixed(4);
  const inches = roundToHundreth(
    Math.floor(parseFloat(remainder) * 12) * (1 / 12)
  );
  const theDistance = Math.floor(distanceInFeet) + inches;
  // Add 0.5 as 6 inches for each strand of cable.
  return roundToHundreth((theDistance + 0.5) * numCables);
};

export const distanceToFeet = (distanceInPixels: number) => {
  return roundToNearestInch(distanceInPixels / pixelsPerFoot());
};

export const roundToNearestInch = (number: number) => {
  return roundToHundreth(Math.round(number * 12) / 12);
};
