import { Map } from "immutable";
import { Estimator } from "../entities";
import salesReps from "./salesReps";
import { arrayToKeyedObject } from "./utils";

function fetchEstimators() {
  let fetched = false;
  let estimators = Map<string, Estimator>();

  return async function () {
    if (!fetched) {
      const reps = salesReps.QBXML.QBXMLMsgsRs.SalesRepQueryRs.SalesRepRet;

      const estimatorsArray: Estimator[] = reps.flatMap((item) => {
        try {
          return [
            Estimator({
              id: item.ListID,
              name: item.SalesRepEntityRef.FullName,
              quickbooksId: item.ListID,
              quickbooksData: item,
              abbreviation: item.Initial,
              email: "",
              status: item.IsActive ? "active" : "archived",
              version: "1",
            }),
          ];
        } catch {
          return [];
        }
      });

      estimators = Map(arrayToKeyedObject(estimatorsArray));

      fetched = true;
    }

    return estimators;
  };
}

export default fetchEstimators();
