// @ts-nocheck
import { Map, List, OrderedMap } from "immutable";

import {
  Project,
  ProjectRevisionState,
  ProjectSettings,
  ProjectRevision,
  Canvas,
  EditWindow,
  Run,
  Post,
  Gate,
  Handrail,
  Overrides,
  Point,
  Image,
  Shape,
  Note,
  Stairs,
  Landing,
  Labels,
  Corner,
} from "../entities";
import { Edge } from "../utils/graph";

export function projectJsonToImmutable(project) {
  const runs = entityToImmutable(runJsToImmutable, OrderedMap)(project.runs);
  const posts = entityToImmutable(postJsToImmutable)(project.posts);
  const shapes = entityToImmutable(shapeJsToImmutable)(project.shapes);
  const gates = entityToImmutable(gateJsToImmutable)(project.gates);
  const images = entityToImmutable(imageJsToImmutable)(project.images);
  const notes = entityToImmutable(noteJsToImmutable)(project.notes);
  const stairs = entityToImmutable(stairsJsToImmutable)(project.stairs);
  const handrails = entityToImmutable(handrailJsToImmutable)(project.handrails);
  const corners = entityToImmutable(cornerJsToImmutable)(project.corners);

  return Project({
    runs: runs,
    posts: posts,
    shapes: shapes,
    gates: gates,
    images: images,
    notes: notes,
    stairs: stairs,
    corners: corners,
    handrails: handrails,
    edit: editObjectJsToImmutable(project.edit),
    settings: ProjectSettings(project.settings),
    settingsOpen: project.settingsOpen,
    revisions: Map(revisionsToImmutable(project.revisions)),
    currentRevision: project.currentRevision,
    canvases: Map(canvasesToImmutable(project.canvases)),
    canvasMenuOpen: null,
    currentCanvas: project.currentCanvas,
    overrides: List(project.overrides),
    version: project.version,
    createdOn: project.createdOn,
    quickbooksId: project.quickbooksId,
    projectEstimate: project.projectEstimate,
    customer: project.customer,
    estimators: Map(project.estimators),
    mainEstimators: Map(project.mainEstimators),
    estimatorsVerified: project.estimatorsVerified,
    isSalesOrder: project.isSalesOrder,
    tax: project.tax,
    id: project.id,
    exportToQuickbooks: project.exportToQuickbooks,
    sequenceId: project.sequenceId,
    frozenProject: project.frozenProject,
    frozenEstimate: project.frozenEstimate,
  });
}

function canvasesToImmutable(canvases) {
  if (!canvases) {
    return Map({ initial: Canvas() });
  }

  let map = Map();
  Object.values(canvases).forEach((canvas) => {
    const runs = entityToImmutable(runJsToImmutable, OrderedMap)(canvas.runs);
    const posts = entityToImmutable(postJsToImmutable)(canvas.posts);
    const shapes = entityToImmutable(shapeJsToImmutable)(canvas.shapes);
    const gates = entityToImmutable(gateJsToImmutable)(canvas.gates);
    const images = entityToImmutable(imageJsToImmutable)(canvas.images);
    const notes = entityToImmutable(noteJsToImmutable)(canvas.notes);
    const stairs = entityToImmutable(stairsJsToImmutable)(canvas.stairs);
    const handrails = entityToImmutable(handrailJsToImmutable)(
      canvas.handrails
    );
    const corners = entityToImmutable(cornerJsToImmutable)(canvas.corners);

    map = map.set(
      canvas.id,
      Canvas({
        id: canvas.id,
        name: canvas.name,
        runs: runs,
        pan: canvas.pan,
        scale: canvas.scale,
        posts: posts,
        shapes: shapes,
        gates: gates,
        images: images,
        notes: notes,
        stairs: stairs,
        handrails: handrails,
        corners: corners,
        labels: canvas.labels,
        overrides: List(canvas.overrides),
      })
    );
  });

  return map;
}

function revisionsToImmutable(revisions) {
  if (!revisions) {
    return Map();
  }

  let map = Map();
  Object.values(revisions).forEach((revision) => {
    const runs = entityToImmutable(
      runJsToImmutable,
      OrderedMap
    )(revision.state.runs);
    const posts = entityToImmutable(postJsToImmutable)(revision.state.posts);
    const shapes = entityToImmutable(shapeJsToImmutable)(revision.state.shapes);
    const gates = entityToImmutable(gateJsToImmutable)(revision.state.gates);
    const images = entityToImmutable(imageJsToImmutable)(revision.state.images);
    const notes = entityToImmutable(noteJsToImmutable)(revision.state.notes);
    const stairs = entityToImmutable(stairsJsToImmutable)(
      revision.state.stairs
    );
    const handrails = entityToImmutable(handrailJsToImmutable)(
      revision.state.handrails
    );
    const corners = entityToImmutable(cornerJsToImmutable)(
      revision.state.corners
    );

    map = map.set(
      revision.id,
      ProjectRevision({
        date: revision.date,
        datetime: revision.datetime,
        id: revision.id,
        description: revision.description,
        state: ProjectRevisionState({
          runs: runs,
          posts: posts,
          shapes: shapes,
          gates: gates,
          images: images,
          notes: notes,
          stairs: stairs,
          handrails: handrails,
          corners: corners,
          overrides: List(revision.state.overrides),
          edit: editObjectJsToImmutable(revision.state.edit),
          settings: ProjectSettings(revision.state.settings),
          settingsOpen: revision.state.settingsOpen,
          currentCanvas: revision.state.currentCanvas,
          canvases: canvasesToImmutable(revision.state.canvases),
        }),
      })
    );
  });

  return map;
}

function entityToImmutable(Type, Collection = Map) {
  return function (entities) {
    if (!entities) {
      return Collection();
    }

    let map = Collection();

    Object.entries(entities).forEach(([id, entity]) => {
      if (!entity) {
        return;
      }

      map = map.set(id, Type(entity));
    });

    return map;
  };
}

function runJsToImmutable(run) {
  let immutableRun;

  if (run.overrides) {
    immutableRun = Run(run).set(
      "overrides",
      Overrides(run.overrides)
        .set("itemListChanges", List(run.overrides.itemListChanges))
        .set("propertyChanges", List(run.overrides.propertyChanges))
    );
  } else {
    immutableRun = Run(run).set("overrides", Overrides(run.overrides));
  }

  if (run.labels) {
    immutableRun = immutableRun.set("labels", Labels(run.labels));
  }

  if (run.endPosts) {
    immutableRun = immutableRun.set("endPosts", Map(run.endPosts));
  }

  if (run.postTypes) {
    immutableRun = immutableRun.set("postTypes", Map(run.postTypes));
  }

  if (run.stairs) {
    if (run.stairs.continuousStairs) {
      immutableRun = immutableRun.setIn(
        ["stairs", "continuousStairs"],
        OrderedMap(run.stairs.continuousStairs).map((stair) => Stairs(stair))
      );
    }
  }

  return immutableRun;
}

function postJsToImmutable(post) {
  const immutablePost = Post(post).set(
    "overrides",
    Overrides(post.overrides)
      .set("itemListChanges", List(post.overrides.itemListChanges))
      .set("propertyChanges", List(post.overrides.propertyChanges))
  );

  return immutablePost;
}

function gateJsToImmutable(gate) {
  const immutableGate = Gate(gate).set(
    "overrides",
    Overrides(gate.overrides)
      .set("itemListChanges", List(gate.overrides.itemListChanges))
      .set("propertyChanges", List(gate.overrides.propertyChanges))
  );

  return immutableGate;
}

function handrailJsToImmutable(handrail) {
  let immutableHandrail = Handrail(handrail).set(
    "overrides",
    Overrides(handrail.overrides)
      .set("itemListChanges", List(handrail.overrides.itemListChanges))
      .set("propertyChanges", List(handrail.overrides.propertyChanges))
  );

  if (handrail.labels) {
    immutableHandrail = immutableHandrail.set(
      "labels",
      Labels(handrail.labels)
    );
  }

  return immutableHandrail;
}

function cornerJsToImmutable(corner: Corner) {
  const immutableCorner = Corner(corner).set("id", Map(corner.id));

  return immutableCorner;
}

function imageJsToImmutable(image) {
  const immutableImage = Image(image).set("point", Point(image.point));

  return immutableImage;
}

function shapeJsToImmutable(shape) {
  let immutableShape = Shape(shape);

  if (shape.labels) {
    immutableShape = immutableShape.set("labels", Labels(shape.labels));
  }

  return immutableShape;
}

function noteJsToImmutable(note) {
  const immutableNote = Note(note);

  return immutableNote;
}

function stairsJsToImmutable(stairs) {
  if (stairs.type === "landing") {
    let immutableStairs = Landing(stairs).set(
      "rotate",
      Map(stairs.rotate || { axis: "top-left", angle: 0 })
    );

    if (stairs.stairsEdges) {
      stairs.stairsEdges = Map(stairs.stairsEdges);
      stairs.stairsEdges.forEach((edge, edgeKey) => {
        stairs.stairsEdges = stairs.stairsEdges.set(edgeKey, Edge(edge));
      });
      immutableStairs = immutableStairs.set(
        "stairsEdges",
        Map(stairs.stairsEdges)
      );
    }

    if (stairs.labels) {
      immutableStairs = immutableStairs.set("labels", Labels(stairs.labels));
    }

    return immutableStairs;
  }

  let immutableStairs = Stairs(stairs).set(
    "rotate",
    Map(stairs.rotate || { axis: "top-left", angle: 0 })
  );

  if (stairs.stairsEdges) {
    stairs.stairsEdges = Map(stairs.stairsEdges);
    stairs.stairsEdges.forEach((edge, edgeKey) => {
      stairs.stairsEdges = stairs.stairsEdges.set(edgeKey, Edge(edge));
    });
    immutableStairs = immutableStairs.set(
      "stairsEdges",
      Map(stairs.stairsEdges)
    );
  }

  if (stairs.labels) {
    immutableStairs = immutableStairs.set("labels", Labels(stairs.labels));
  }

  return immutableStairs;
}

function editObjectJsToImmutable(edit) {
  if (!edit) {
    return EditWindow();
  }

  if (!edit.object) {
    return EditWindow();
  }

  const { type } = edit;

  switch (type) {
    case "run":
      return EditWindow(edit).set("object", runJsToImmutable(edit.object));
    case "post":
      return EditWindow(edit).set("object", postJsToImmutable(edit.object));
    case "gate":
      return EditWindow(edit).set("object", gateJsToImmutable(edit.object));
    case "handrail":
      return EditWindow(edit).set("object", handrailJsToImmutable(edit.object));
    case "image":
      return EditWindow(edit).set("object", imageJsToImmutable(edit.object));
    case "shape":
      return EditWindow(edit).set("object", shapeJsToImmutable(edit.object));
    case "note":
      return EditWindow(edit).set("object", noteJsToImmutable(edit.object));
    case "stairs":
      return EditWindow(edit).set("object", stairsJsToImmutable(edit.object));
    default:
      return EditWindow(edit);
  }
}
