// @ts-nocheck
import Icon from "./Icon";

function IconButton({ icon, className, onClick, active, children }) {
  let classes = "icon-button";

  if (active) {
    classes += " icon-button--active";
  }

  return (
    <button className={classes + " " + className} onClick={onClick}>
      <Icon icon={icon} className="icon-button__icon" />
      {children}
    </button>
  );
}

export default IconButton;
