// @ts-nocheck
import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";
import { useSpring, animated } from "@react-spring/web";
import useOnClickOutside from "../hooks/useOnClickOutside";

function Note(props) {
  const { note, currentProject } = props;
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state.present);
  const currentCanvas = state.canvases.get(state.currentCanvas);

  const pan = currentCanvas.pan;
  const scale = currentCanvas.scale;

  const point = {
    x: note.x * scale + pan.x,
    y: note.y * scale + pan.y,
  };

  const [statePoint, setPoint] = useState(point);

  const element = useRef();

  useOnClickOutside(element, () => {
    setTimeout(() => {
      dispatch({
        type: "notes/edit",
        note: props.note.set("isEditing", false),
        index: props.note.id,
        id: props.currentProject,
      });
    }, 0);
  });

  const [{ x, y, zIndex }, animation] = useSpring(() => ({
    x: statePoint.x,
    y: statePoint.y,
    zIndex: 0,
  }));

  const bind = useDrag(
    ({ ctrlKey, down, delta: [mx, my], movement: [x, y] }) => {
      if (ctrlKey) {
        animation.start({
          x: statePoint.x + x,
          y: statePoint.y + y,
          immediate: down,
        });

        if (!down) {
          setPoint({ x: statePoint.x + x, y: statePoint.y + y });
        }
      }
    }
  );

  return (
    <animated.div
      {...bind()}
      style={{ x, y, zIndex, position: "absolute" }}
      className="app__note"
      ref={element}
    >
      <div>
        <NoteText
          dispatch={dispatch}
          note={note}
          currentProject={currentProject}
        />
      </div>
    </animated.div>
  );
}

const NoteText = (props) => {
  const fontSize = parseInt(props.note.fontSize.replace("px", ""), 10);

  const [size, setSize] = useState(fontSize);

  const [text, setText] = useState(props.note.text);

  return (
    <div className="inner-note-container">
      <textarea
        value={text}
        style={{ width: "100%" }}
        className="app__note-text"
        onChange={(event) => {
          setText(event.target.value);
        }}
      />
      <input
        type="number"
        min="1"
        style={{ display: "block", margin: "0 0 8px" }}
        className="app__note-text"
        value={size}
        onChange={(event) => {
          if (event.target.value === "") {
            setSize("");
          } else {
            const value = parseInt(event.target.value, 10);
            setSize(value);

            const newSize = value + "px";

            props.dispatch({
              type: "notes/edit",
              note: props.note.set("fontSize", newSize),
              index: props.note.id,
              id: props.currentProject,
            });
          }
        }}
      />
      <button
        className="edit-popup__delete-button note-save"
        onClick={() => {
          setTimeout(() => {
            props.dispatch({
              type: "notes/edit",
              note: props.note.set("text", text).set("isEditing", false),
              index: props.note.id,
              id: props.currentProject,
            });
          }, 0);
        }}
      >
        Save Note
      </button>
      <button
        className="edit-popup__delete-button"
        onClick={() => {
          setTimeout(() => {
            props.dispatch({
              type: "notes/remove",
              id: props.note.id,
            });
          }, 0);
        }}
      >
        Delete Note
      </button>
    </div>
  );
};

export default Note;
