// @ts-nocheck
import { useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "./Icon";

const CanvasSettingsHeader = ({ setPosition, animation, dispatch }) => {
  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="canvas-settings__header">
      <div className="canvas-settings__header-tabs">
        <h2 className="canvas-settings__heading">Canvas Settings</h2>
      </div>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "canvas/settings-close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
};

const CanvasSettings = ({ canvas, setPosition, animation }) => {
  const dispatch = useDispatch();
  const style = {
    top: "150px",
    left: "200px",
  };

  let canvasShowLabelsClass = "edit-popup__selector-button";
  let canvasHideLabelsClass = "edit-popup__selector-button";

  if (canvas.labels.runs === "show") {
    canvasShowLabelsClass += " edit-popup__selector-button--active";
  } else {
    canvasHideLabelsClass += " edit-popup__selector-button--active";
  }

  return (
    <div
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "canvas-settings" });
      }}
      className="canvas-settings edit-popup"
      style={style}
    >
      <CanvasSettingsHeader
        setPosition={setPosition}
        dispatch={dispatch}
        animation={animation}
      />
      <div>
        <div className="canvas-settings__content">
          <div className="edit-popup__control">
            <p className="edit-popup__label">Show Labels</p>
            <div className="edit-popup__selector">
              <button
                className={canvasShowLabelsClass}
                onClick={() => {
                  if (canvas.labels.runs !== "show") {
                    dispatch({
                      type: "canvas/edit-settings",
                      labels: { runs: "show" },
                      canvas: canvas,
                    });
                  }
                }}
              >
                Show
              </button>
              <button
                onClick={() => {
                  if (canvas.labels.runs !== "hide") {
                    dispatch({
                      type: "canvas/edit-settings",
                      labels: { runs: "hide" },
                      canvas,
                    });
                  }
                }}
                className={canvasHideLabelsClass}
              >
                Hide
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CanvasSettings;
