import * as z from "zod";

const DataExtNameSchema = z.enum([
  "Column/Row",
  "Item Category",
  "UPC",
  "Weight",
]);

const DataExtTypeSchema = z.enum(["STR255TYPE"]);

const XmlSchema = z.object({
  "@_version": z.string(),
});

const DataExtRetElementSchema = z.object({
  OwnerID: z.number(),
  DataExtName: DataExtNameSchema,
  DataExtType: DataExtTypeSchema,
  DataExtValue: z.union([z.number(), z.string()]),
});

const RefSchema = z.object({
  ListID: z.string(),
  FullName: z.string(),
});

const InventoryItemTypeSchema = z.literal("inventory");

export const InventoryItemSchema = z.object({
  ListID: z.string(),
  TimeCreated: z.string(),
  TimeModified: z.string(),
  EditSequence: z.number(),
  Name: z.string(),
  FullName: z.string(),
  BarCodeValue: z.union([z.number(), z.string()]),
  IsActive: z.boolean(),
  Sublevel: z.number(),
  SalesTaxCodeRef: RefSchema,
  IncomeAccountRef: RefSchema,
  COGSAccountRef: RefSchema,
  AssetAccountRef: RefSchema,
  QuantityOnHand: z.number(),
  QuantityOnOrder: z.number(),
  QuantityOnSalesOrder: z.number().nullish(),
  ParentRef: z.union([RefSchema, z.null()]).optional(),
  ManufacturerPartNumber: z
    .union([z.number(), z.null(), z.string()])
    .optional(),
  UnitOfMeasureSetRef: z.union([RefSchema, z.null()]).optional(),
  SalesDesc: z.string().nullish(),
  SalesPrice: z.number().nullish(),
  PurchaseDesc: z.string().nullish(),
  PurchaseCost: z.number().nullish(),
  PrefVendorRef: RefSchema.nullish(),
  ReorderPoint: z.number().nullish(),
  AverageCost: z.number().nullish(),
  DataExtRet: z
    .union([
      z.array(DataExtRetElementSchema),
      DataExtRetElementSchema,
      z.null(),
    ])
    .optional(),
  Max: z.number().nullish(),
  type: InventoryItemTypeSchema.nullish(),
});

export type InventoryItem = z.infer<typeof InventoryItemSchema>;

const ItemInventoryQueryRsSchema = z.object({
  ItemInventoryRet: z.array(InventoryItemSchema),
  "@_statusCode": z.string(),
  "@_statusSeverity": z.string(),
  "@_statusMessage": z.string(),
});

const QbxmlMsgsRsSchema = z.object({
  ItemInventoryQueryRs: ItemInventoryQueryRsSchema,
});

const QbxmlSchema = z.object({
  QBXMLMsgsRs: QbxmlMsgsRsSchema,
});

export const InventorySchema = z.object({
  "?xml": XmlSchema,
  QBXML: QbxmlSchema,
});
export type Inventory = z.infer<typeof InventorySchema>;
