// @ts-nocheck
import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";
import { getItemListForPost } from "../../../partsList";
import { InventoryContext } from "../../../App";

function EditPostHardware(props) {
  const { post, dispatch } = props;

  const [selected, setSelected] = useState({});

  let isSelected = false;

  const selectedValues = Object.values(selected);

  for (let i = 0, len = selectedValues.length; i < len; i++) {
    if (selectedValues[i] === true) {
      isSelected = true;
      break;
    }
  }

  const state = useSelector((state) => state.state);
  const { settings } = state.present;
  const appState = useSelector((state) => state.appState);
  const { currentProject } = appState;

  const inventoryList = useContext(InventoryContext);

  const itemList = getItemListForPost(settings, post, inventoryList);

  return (
    <div className="edit-popup__hardware">
      <div className="hardware__table-container">
        <table className="hardware__table">
          <thead className="hardware-table__header">
            <tr>
              <th className="hardware-table__header-item checkbox"></th>
              <th className="hardware-table__header-item quantity">Qty</th>
              <th className="hardware-table__header-item measure">U/M</th>
              <th className="hardware-table__header-item description">
                Description
              </th>
              <th className="hardware-table__header-item upc">UPC</th>
            </tr>
          </thead>
          <tbody className="hardware-table__body">
            {Object.values(itemList).map((item) => {
              return (
                <tr key={item.name} className="hardware-table__row">
                  <td className="hardware-table__item checkbox">
                    <input
                      type="checkbox"
                      className="hardware-table__checkbox"
                      onChange={() => {
                        setSelected({
                          ...selected,
                          [item.upc]: !selected[item.upc],
                        });
                      }}
                      checked={selected[item.upc] === true}
                    />
                  </td>
                  <td className="hardware-table__item quantity">
                    <input
                      className="hardware-table__quantity-input"
                      value={parseFloat(item.quantity, 10)}
                      onChange={(event) => {
                        let newQuantity = parseInt(event.target.value || 0, 10);

                        if (isNaN(newQuantity)) {
                          newQuantity = 0;
                        }
                        const newPost = post.updateIn(
                          ["overrides", "itemListChanges"],
                          (list) =>
                            list.push({
                              product: item,
                              newQuantity: newQuantity,
                              quantityChange: newQuantity - item.quantity,
                              type: "quantityChange",
                            })
                        );

                        dispatch({
                          type: "posts/edit",
                          post: newPost,
                          id: currentProject,
                        });
                      }}
                    />
                  </td>
                  <td className="hardware-table__item measure">
                    {item.measure}
                  </td>
                  <td className="hardware-table__item description">
                    {item.name}
                    <br />
                    {item.description}
                  </td>
                  <td className="hardware-table__item upc">{item.upc}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="hardware__footer">
        {isSelected && (
          <button
            onClick={() => {
              const newPost = post.updateIn(
                ["overrides", "itemListChanges"],
                (list) => {
                  let newList = list;
                  Object.entries(selected).forEach(([upc, readyToDelete]) => {
                    if (readyToDelete === true) {
                      newList = list.push({
                        product: itemList[upc],
                        quantity: itemList[upc].quantity,
                        type: "itemRemoved",
                      });
                    }
                  });
                  return newList;
                }
              );

              dispatch({
                type: "posts/edit",
                post: newPost,
                id: currentProject,
              });
              setSelected({});
            }}
            className="hardware__delete-item"
          >
            <Icon icon="delete" className="hardware__delete-item-icon" />
            <span className="hardware__delete-item-text">Delete</span>
          </button>
        )}
        <button
          onClick={() => {
            // setHardwareMenu(true);
            dispatch({ type: "window/open-add-hardware-post", post: post });
          }}
          className="hardware__add-item"
        >
          <Icon icon="plus" className="hardware__add-item-icon" />
          <span className="hardware__add-item-text">Add Hardware Item</span>
        </button>
      </div>
    </div>
  );
}

function AddHardwareMenuPostHeader(props) {
  const { dispatch, animation, setPosition } = props;
  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
      zIndex: down ? 1 : 0,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="add-hardware__header">
      <label className="add-hardware__label" htmlFor="add-hardware__search">
        Add Item
      </label>
      <button
        className="app__tooltip-close"
        onClick={() => {
          dispatch({ type: "window/close-add-hardware-post" });
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

export function AddHardwareMenuPost(props) {
  const { post, currentProject, setPosition, animation } = props;

  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const inventoryList = useContext(InventoryContext);

  let inventory = inventoryList;

  if (search.length) {
    const searchQuery = search.toLowerCase();

    inventory = inventoryList.filter((item) => {
      if (
        item.BarCodeValue &&
        item.BarCodeValue.toString &&
        item.BarCodeValue.toString().toLowerCase().includes(searchQuery)
      ) {
        return true;
      }

      if (item.FullName && item.FullName.toLowerCase().includes(searchQuery)) {
        return true;
      }

      if (item.Name && item.Name.toLowerCase().includes(searchQuery)) {
        return true;
      }

      if (
        item.SalesDesc &&
        item.SalesDesc.toLowerCase().includes(searchQuery)
      ) {
        return true;
      }
      return false;
    });
  }
  return (
    <div
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "add-post-hardware" });
      }}
      className="add-hardware"
    >
      <AddHardwareMenuPostHeader
        dispatch={dispatch}
        setPosition={setPosition}
        animation={animation}
      />
      <div className="add-hardware__container">
        {product === null && (
          <div className="add-hardware__search-container">
            <Icon icon="search" className="add-hardware__search-icon" />
            <input
              name="add-hardware__search"
              className="add-hardware__search"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              onFocus={() => {
                setListOpen(true);
              }}
              placeholder="Search for a QB product..."
            />
          </div>
        )}
        {(listOpen === true || search.length !== 0) && product === null && (
          <div className="add-hardware__list">
            {inventory.map((item) => {
              return (
                <div
                  className="add-hardware__item"
                  key={item.FullName}
                  tabIndex="0"
                  onClick={() => {
                    setProduct(item);
                  }}
                >
                  <img
                    className="add-hardware__line-drawing"
                    alt={item.Name}
                    loading="lazy"
                    src={
                      process.env.PUBLIC_URL +
                      "/line-drawings/" +
                      item.BarCodeValue +
                      ".svg"
                    }
                    onError={(event) =>
                      (event.target.style.visibility = "hidden")
                    }
                  />
                  <div className="add-hardware__item-details">
                    <p className="add-hardware__item-name">{item.FullName}</p>
                    {item.SalesDesc && (
                      <p className="add-hardware__item-description">
                        {item.SalesDesc}
                      </p>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {product !== null && (
          <div className="add-hardware__product-selection">
            <button
              onClick={() => {
                setProduct(null);
              }}
              className="button add-hardware__clear-product-selection"
            >
              <Icon icon="close" className="add-hardware__clear-product-icon" />
            </button>
            <span className="add-hardware__product-selection-name">
              {product.Name}
            </span>
          </div>
        )}
      </div>
      <div className="add-hardware__footer">
        {product !== null && (
          <div className="add-hardware__quantity">
            <label
              htmlFor="add-hardware__quantity-input"
              className="add-hardware__quantity-label"
            >
              Adjust Quantity
            </label>
            <input
              className="add-hardware__quantity-input"
              name="add-hardware__quantity-input"
              id="add-hardware__quantity-input"
              type="number"
              value={quantity}
              min="0"
              onChange={(event) => {
                setQuantity(parseInt(event.target.value, 10));
              }}
            />
          </div>
        )}
        <button
          onClick={() => {
            if (product !== null && quantity !== 0) {
              const newPost = post.updateIn(
                ["overrides", "itemListChanges"],
                (list) => list.push({ product, quantity, type: "itemAdded" })
              );

              dispatch({
                type: "posts/edit",
                post: newPost,
                id: currentProject,
              });

              setProduct(null);
              setQuantity(0);
              dispatch({ type: "window/close-add-hardware-post" });
            }
          }}
          className="add-hardware__button button button--primary"
        >
          Add Product to Parts List
        </button>
      </div>
    </div>
  );
}

export default EditPostHardware;
