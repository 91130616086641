import * as z from "zod";

const UnitOfMeasureSchema = z.enum([
  "Count in each",
  "Each",
  "Length by the foot",
  "Length by the inch",
]);

const XmlSchema = z.object({
  "@_version": z.string(),
});

const RefSchema = z.object({
  ListID: z.string(),
  FullName: z.string(),
});

const ItemGroupLineClassSchema = z.object({
  ItemRef: RefSchema,
  Quantity: z.number().nullish(),
  UnitOfMeasure: UnitOfMeasureSchema.nullish(),
});

const GroupItemTypeSchema = z.literal("group");

export const GroupItemSchema = z.object({
  ListID: z.string(),
  TimeCreated: z.string(),
  TimeModified: z.string(),
  EditSequence: z.number(),
  Name: z.string(),
  FullName: z.string().default(""),
  BarCodeValue: z.number().nullish(),
  IsActive: z.boolean(),
  ItemDesc: z.string().nullish(),
  IsPrintItemsInGroup: z.boolean(),
  ItemGroupLine: z
    .union([
      z.array(z.union([ItemGroupLineClassSchema, z.string()])),
      ItemGroupLineClassSchema,
    ])
    .nullish(),
  type: GroupItemTypeSchema.nullish(),
  SalesDesc: z.string().nullish(),
  SalesPrice: z.number().nullish(),
});
export type GroupItem = z.infer<typeof GroupItemSchema>;

const ItemGroupQueryRsSchema = z.object({
  ItemGroupRet: z.array(GroupItemSchema),
  "@_statusCode": z.string(),
  "@_statusSeverity": z.string(),
  "@_statusMessage": z.string(),
});

const QbxmlMsgsRsSchema = z.object({
  ItemGroupQueryRs: ItemGroupQueryRsSchema,
});

const QbxmlSchema = z.object({
  QBXMLMsgsRs: QbxmlMsgsRsSchema,
});

export const GroupsSchema = z.object({
  "?xml": XmlSchema,
  QBXML: QbxmlSchema,
});
export type GroupsSchema = z.infer<typeof GroupsSchema>;
