interface PropType {
  className: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

const CountrySelect = ({ className, value, onChange }: PropType) => {
  return (
    <select className={className} value={value} onChange={onChange}>
      <option value="US">United States</option>
      <option value="CA">Canada</option>
    </select>
  );
};

export default CountrySelect;
