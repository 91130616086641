interface PropType {
  className: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  placeholder: string;
}

const CAProvinceSelect = ({
  className,
  value,
  onChange,
  placeholder,
}: PropType) => {
  return (
    <select className={className} value={value} onChange={onChange}>
      <option value="">{placeholder}</option>
      <option value="AB">Alberta</option>
      <option value="BC">British Columbia</option>
      <option value="MB">Manitoba</option>
      <option value="NB">New Brunswick</option>
      <option value="NL">Newfoundland and Labrador</option>
      <option value="NS">Nova Scotia</option>
      <option value="NT">Northwest Territories</option>
      <option value="NU">Nunavut</option>
      <option value="ON">Ontario</option>
      <option value="PE">Prince Edward Island</option>
      <option value="QC">Quebec</option>
      <option value="SK">Saskatchewan</option>
      <option value="YT">Yukon</option>
    </select>
  );
};

export default CAProvinceSelect;
