import { useRef, useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Icon from "../Icon";
import { Customer, Estimator, Project } from "../../entities";
import { Map } from "immutable";
import CustomerSelectItem from "./CustomerSelectItem";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import PaginatedList from "../PaginatedList";
import useListLoader from "../../hooks/useListLoader";

interface PropType {
  setAddNewWindow: React.Dispatch<React.SetStateAction<boolean>>;
  listPopupOpen: boolean;
  setListPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditShipToWindow: React.Dispatch<React.SetStateAction<boolean>>;
  updateCustomer: (
    customer: Customer | null,
    sendEvent?: "customers/add" | "customers/edit"
  ) => void;
  project: Project;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  estimators: Map<string, Estimator>;
}

const CustomersDropdown = ({
  setAddNewWindow,
  listPopupOpen,
  setListPopupOpen,
  setEditShipToWindow,
  updateCustomer,
  project,
  setOpen,
  estimators,
}: PropType) => {
  const dispatch = useDispatch();
  const { items: customers, fetchItems: fetchCustomers } = useListLoader({
    countEndpoint: "getCustomersPageCount",
    dataEndpoint: "getCustomers",
    itemParser: (json) => Customer(json),
  });
  const [search, setSearch] = useState("");

  const ref = useRef<HTMLDivElement | null>(null);
  const clickOutside = useCallback(() => {
    setSearch("");
    setOpen(false);
  }, [setOpen, setSearch]);

  useOnClickOutside(ref, clickOutside);

  let listClass = "estimators__list";

  if (listPopupOpen) {
    listClass += " esimators__list--popup-open";
  }

  useEffect(() => {
    fetchCustomers({ searchTerm: search });
  }, [search, fetchCustomers]);

  return (
    <div ref={ref} className="estimators__dropdown">
      <div className="estimators__search">
        <Icon className="estimators__search-icon" icon="search" />
        <input
          className="estimators__search-input"
          type="text"
          placeholder="Search for a customer..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      <div className="estimators__new">
        <button
          onClick={() => {
            // Unset customer.
            dispatch({
              type: "customer/select",
              checked: true,
            });
            setAddNewWindow(true);
          }}
          className="estimators__add-new"
        >
          <Icon className="estimators__add-icon" icon="plus" />
          <span className="estimators__add-text">Add New Customer</span>
        </button>
      </div>
      <ul className={listClass}>
        <PaginatedList
          items={customers}
          loadMore={() => {
            fetchCustomers({ searchTerm: search });
          }}
          noResultsMessage={() => <div>No results found</div>}
          renderListItem={(customer, index, setThirdToLastElement) => {
            const id = customer.id;

            const checked = project.customer === customer.id;

            return (
              <CustomerSelectItem
                key={id}
                customer={customer}
                checked={checked}
                setListPopupOpen={setListPopupOpen}
                setEditShipToWindow={setEditShipToWindow}
                updateCustomer={updateCustomer}
                estimators={estimators}
                setThirdToLastElement={setThirdToLastElement}
              />
            );
          }}
        ></PaginatedList>
      </ul>
    </div>
  );
};

export default CustomersDropdown;
