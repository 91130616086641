export type AppReducerState = {
  view: string;
  currentProject: string;
  setNewSalesRep?: string | undefined;
};
export type AppReducerAction =
  | { type: "app/project-view"; id: string }
  | { type: "app/projects-view" }
  | { type: "app/open-project"; id: string }
  | {
      type: "estimators/select";
      estimator: any;
      checked: boolean;
      changeType: string;
    }
  | { type: "customer/set-new-sales-rep" };

export function appReducer(
  appState: AppReducerState = {
    view: "login",
    currentProject: "",
    setNewSalesRep: undefined,
  },
  action: AppReducerAction
): AppReducerState {
  switch (action.type) {
    case "app/project-view":
      return {
        ...appState,
        view: "single-project",
        currentProject: action.id,
      };
    case "app/projects-view":
      return {
        ...appState,
        view: "create-project",
        currentProject: "",
      };
    case "app/open-project":
      return {
        ...appState,
        view: "single-project",
        currentProject: action.id,
      };
    case "estimators/select":
      if (action.changeType === "mainEstimators" || action.checked === true) {
        return appState;
      }

      return { ...appState, setNewSalesRep: action.estimator.id };
    case "customer/set-new-sales-rep":
      return { ...appState, setNewSalesRep: undefined };
    default:
      return appState;
  }
}
