import { Map } from "immutable";
import { Estimator } from "../../entities";

const estimatorReducerState = Map<string, Estimator>();

export type EstimatorReducerState = typeof estimatorReducerState;
export type EstimatorReducerAction =
  | {
      type: "estimators/add";
      estimator: Estimator;
    }
  | { type: "estimators/edit"; estimator: Estimator }
  | { type: "estimators/delete"; estimator: Estimator }
  | {
      type: "estimators/select";
      estimator: Estimator;
      checked: boolean;
      changeType: string;
    }
  | { type: "app/open-project"; projectEstimators: EstimatorReducerState }
  | { type: "app/projects-view" };

export function estimatorReducer(
  state: EstimatorReducerState = estimatorReducerState,
  action: EstimatorReducerAction
): EstimatorReducerState {
  switch (action.type) {
    case "estimators/add":
      return state.set(action.estimator.id, action.estimator);
    case "estimators/edit":
      return state.set(action.estimator.id, action.estimator);
    case "estimators/delete":
      return state.delete(action.estimator.id);
    case "estimators/select":
      if (action.changeType === "mainEstimators") {
        return state;
      }

      if (action.checked) {
        // Unset estimator from project.
        return state.delete(action.estimator.id);
      } else {
        // Add estimator to project.
        return state.set(action.estimator.id, action.estimator);
      }
    case "app/open-project":
      return action.projectEstimators;
    case "app/projects-view":
      return Map();
    default:
      return state;
  }
}
