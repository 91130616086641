// @ts-nocheck
import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import { useDrag } from "@use-gesture/react";

import Icon from "../Icon";

const updateColor = (shape, dispatch, value) => {
  const newShape = shape.set("color", value);

  dispatch({ type: "shapes/edit", shape: newShape });
};

function EditShapePopupHeader(props) {
  const { setPosition, dispatch, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <h2 className="edit-popup__heading">Edit Shape</h2>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

export default function EditShapePopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const shape = props.edit.object;
  const [view] = useState("controls");

  const views = {
    controls: EditShapeControls,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditShapePopupHeader
        setPosition={setPosition}
        animation={animation}
        dispatch={dispatch}
      />
      <EditComponent shape={shape} dispatch={dispatch} />
    </div>
  );
}

function EditShapeControls(props) {
  const { shape, dispatch } = props;

  let labelsShowClass = "edit-popup__three-button";
  let labelsHideClass = "edit-popup__three-button";

  if (shape.getIn(["labels", "showLabels"])) {
    labelsShowClass += " edit-popup__three-button--active";
  } else {
    labelsHideClass += " edit-popup__three-button--active";
  }

  const colorChange = useCallback(debounce(updateColor, 300), [shape]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="edit-popup__container run-controls">
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Start Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">X</label>
              <input
                type="number"
                id="startPostX"
                value={shape.x1}
                onChange={(event) => {
                  const newShape = shape.set(
                    "x1",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "shapes/edit", shape: newShape });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="startPostY">Y</label>
              <input
                type="number"
                id="startPostY"
                value={shape.y1}
                onChange={(event) => {
                  const newShape = shape.set(
                    "y1",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "shapes/edit", shape: newShape });
                }}
              />
            </span>
          </div>
        </div>
        <div className="edit-popup__control">
          <p className="edit-popup__label">End Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="endPostX">X</label>
              <input
                type="number"
                id="endPostX"
                value={shape.x2}
                onChange={(event) => {
                  const newShape = shape.set(
                    "x2",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "shapes/edit", shape: newShape });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="endPostY">Y</label>
              <input
                type="number"
                id="endPostY"
                value={shape.y2}
                onChange={(event) => {
                  const newShape = shape.set(
                    "y2",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "shapes/edit", shape: newShape });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Fill Color</p>
          <div className="labels-section">
            <div className="shape-color">
              <input
                type="color"
                id="shape-color"
                name="shape-color"
                value={shape.color ? shape.color : "#00A3FF"}
                onChange={(event) => {
                  colorChange(shape, dispatch, event.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section edit-popup__labels">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Labels</p>
          <div className="labels-section">
            <div className="edit-popup__three-buttons labels__toggle-buttons">
              <button
                onClick={() => {
                  const labelsShow = shape.getIn(["labels", "showLabels"]);

                  if (labelsShow !== true) {
                    let newShape = shape;

                    newShape = newShape.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "shapes/edit", shape: newShape });
                  }
                }}
                className={labelsShowClass}
              >
                Show
              </button>
              <button
                onClick={() => {
                  const labelsShow = shape.getIn(["labels", "showLabels"]);

                  if (labelsShow === true) {
                    let newShape = shape;

                    newShape = newShape.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "shapes/edit", shape: newShape });
                  }
                }}
                className={labelsHideClass}
              >
                Hide
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newShape = shape;

                  const labelsSide = shape.getIn(["labels", "distanceLabel"]);

                  newShape = newShape.setIn(
                    ["labels", "distanceLabel"],
                    !labelsSide
                  );

                  dispatch({ type: "shapes/edit", shape: newShape });
                }}
                className="labels-section__button"
              >
                Flip Distance Side
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "shapes/remove", shapeId: shape.id });
            }, 0);
          }}
        >
          Delete Shape
        </button>
      </div>
    </div>
  );
}
