import undoable, { excludeAction, StateWithHistory } from "redux-undo";
import { Reducer } from "redux";
import { List, Map, Record } from "immutable";
import { getDateString } from "../../utils";
import {
  Canvas,
  ProjectRevision,
  Project,
  ProjectSettings,
  EditWindow,
  Run,
  Overrides,
  Corner,
  Post,
  Handrail,
  Shape,
  Gate,
  Stairs,
  Image,
  Note,
  Estimator,
  Customer,
} from "../../entities";
import { getNextContinuousStair } from "../../components/Canvas";
import { getPosts } from "../../utils/getPosts";
import { AllInventoryTypes } from "../../data/fetch-inventory";

function createDefaultRevision(description = "") {
  const currentDate = new Date();
  const datetime = getDateString(currentDate);

  return ProjectRevision({
    date: currentDate.toString(),
    datetime,
    id: "initial",
    description,
  });
}

function createDefaultCanvas(id = "initial") {
  return Canvas({
    id: id,
    name: "New Canvas",
    pan: { x: 0, y: 0 },
    scale: 1,
    runs: Map(),
    posts: Map(),
    shapes: Map(),
    gates: Map(),
    images: Map(),
    notes: Map(),
    stairs: Map(),
    handrails: Map(),
  });
}

const stateReducerState = Project({
  revisions: Map({ initial: createDefaultRevision("Project Start") }),
  currentRevision: "initial",
  canvases: Map({ initial: createDefaultCanvas() }),
  currentCanvas: "initial",
});

type ReducerState = typeof stateReducerState;
export type StateReducerAction =
  | {
      type: "canvas/set-pan-scale";
      pan: { x: number; y: number };
      scale: number;
    }
  | { type: "canvas/set-pan"; pan: { x: number; y: number } }
  | { type: "canvas/set-scale"; scale: number }
  | {
      type: "canvas/overrides";
      project?: List<Overrides>;
      runs?: Map<string, Run>;
      overrides?: List<Overrides>;
    }
  | { type: "project/set-frozen-project"; frozenProject: { id: string } }
  | { type: "project/unfreeze-project" }
  | {
      type: "project/set-frozen-estimate";
      frozenEstimate: { id: string; itemLists: any };
    }
  | {
      type: "project/load-frozen-project";
      frozenProject: { id: string; itemLists: any };
    }
  | { type: "project/unfreeze-estimate" }
  | { type: "project/export-to-quickbooks"; inventory?: AllInventoryTypes[] }
  | { type: "project/add-override"; override?: any }
  | { type: "project/reset-details" }
  | { type: "canvas/add"; id: string }
  | { type: "canvas/select"; id: string }
  | { type: "canvas/settings-open"; canvas: any }
  | { type: "canvas/settings-close" }
  | { type: "canvas/edit-settings"; canvas: any; labels: any }
  | { type: "canvas/rename"; id: string; name: string }
  | { type: "canvas/duplicate"; id: string; newId: string }
  | { type: "canvas/delete"; id: string }
  | { type: "revisions/delete"; id: string; revision: ProjectRevision }
  | { type: "revisions/add"; id: string; revision: ProjectRevision }
  | {
      type: "revisions/switch";
      revision: ProjectRevision;
      canvases: Map<string, Canvas>;
    }
  | { type: "runs/add"; id: string; run: Run }
  | { type: "runs/edit-open"; runIndex: string }
  | { type: "runs/edit"; run: Run }
  | { type: "runs/edit-move"; run: Run }
  | { type: "runs/edit-spacing"; run: Run }
  | { type: "runs/edit-many"; runs: Map<string, Run> }
  | { type: "runs/remove"; runIndex: string }
  | { type: "corners/edit-open"; selectedCorner: Corner }
  | { type: "corners/edit"; corner: Corner }
  | { type: "corners/edit-post-type"; postType: string; corner: any }
  | { type: "corners/remove"; id: string }
  | { type: "posts/add"; post: Post }
  | { type: "posts/edit-open"; postIndex: string }
  | { type: "posts/edit"; post: Post }
  | { type: "posts/remove"; postIndex: string }
  | { type: "handrails/add"; handrail: Handrail }
  | { type: "handrails/add-many"; grabRails: Map<string, Handrail> }
  | { type: "handrails/remove-many"; removeHandrailIds: List<string> }
  | { type: "handrails/edit-open"; handrailIndex: string }
  | { type: "handrails/edit"; handrail: Handrail }
  | {
      type: "handrails/edit-many";
      handrail: Handrail;
      handrails: Map<string, Handrail>;
    }
  | { type: "handrails/remove"; handrailId: string }
  | { type: "shapes/add"; shape: Shape }
  | { type: "shapes/edit"; shape: Shape }
  | { type: "shapes/edit-many"; shapes: Map<string, Shape> }
  | { type: "shapes/edit-open"; shapeIndex: string }
  | { type: "shapes/remove"; shapeId: string }
  | {
      type: "gates/edit-size";
      posts: Map<string, Post>;
      handrails: Map<string, Handrail>;
      gates: Map<string, Gate>;
      runs: Map<string, Run>;
      stairs: Map<string, any>;
      gate: Gate;
    }
  | {
      type: "gates/replace-run";
      gates: Map<string, Gate>;
      posts: Map<string, Post>;
      runs: Map<string, Run>;
      cornerIds: string[];
      updatedExistingRun: Run;
      insertedGate: Gate;
      insertedPosts: [Post, Post];
      replacedPostIndex: [number, number];
    }
  | {
      type: "gates/insert-into-run";
      gates: Map<string, Gate>;
      runs: Map<string, Run>;
      posts: Map<string, Post>;
      cornerIds: string[];
      updatedExistingRun: Run;
      insertedPost: Post;
      insertedGate: Gate;
      replacedPostIndex: [number, number];
      gatePostIndex: string;
      gate: Gate;
    }
  | { type: "gates/add"; gate: Gate }
  | { type: "gates/edit"; gate: Gate }
  | { type: "gates/edit-open"; gateIndex: string }
  | { type: "gates/remove"; gateId: string }
  | { type: "gates/remove-undo"; gateId: string }
  | {
      type: "canvas/edit-entities-resize-handrail";
      handrails: Map<string, Handrail>;
      posts: Map<string, Post>;
      gates: Map<string, Gate>;
      stairs: Map<string, Stairs>;
      runs: Map<string, Run>;
    }
  | {
      type: "canvas/edit-entities-resize-run";
      runs: Map<string, Run>;
      run: Run;
      gates: Map<string, Gate>;
      handrails: Map<string, Handrail>;
      posts: Map<string, Post>;
      stairs: Map<string, Stairs>;
    }
  | {
      type: "canvas/edit-entities";
      gates: Map<string, Gate>;
      handrails: Map<string, Handrail>;
      posts: Map<string, Post>;
      stairs: Map<string, Stairs>;
      runs: Map<string, Run>;
    }
  | { type: "edit-popup/close" }
  | { type: "images/add"; image: Image }
  | { type: "images/edit-open"; imageId: string }
  | { type: "images/edit"; image: Image }
  | { type: "images/remove"; imageId: string }
  | { type: "notes/add"; note: Note }
  | { type: "notes/move"; note: Note }
  | { type: "notes/edit-open"; note: Note }
  | { type: "notes/edit"; note: Note; index: string }
  | { type: "notes/remove"; id: string }
  | { type: "stairs/add"; stair: Stairs; matchingEdges: any }
  | { type: "stairs/add-many"; stairs: Map<string, Stairs> }
  | { type: "stairs/edit-open"; stairsIndex: string }
  | { type: "stairs/edit"; stairs: Stairs }
  | { type: "stairs/remove"; stairsIndex: string }
  | { type: "settings/edit"; settings: ProjectSettings }
  | { type: "settings/edit-spacing"; settings: ProjectSettings }
  | { type: "settings/toggle" }
  | { type: "settings/open" }
  | { type: "tax/set"; tax: number }
  | { type: "convert-to-sales-order"; transaction: string }
  | { type: "tax/reset" }
  | { type: "estimators/add"; estimator: Estimator }
  | { type: "project/set-version"; version: string }
  | { type: "estimators/delete"; estimator: Estimator }
  | {
      type: "estimators/select";
      checked: boolean;
      estimator: Estimator;
      changeType: string;
    }
  | { type: "estimators/set"; estimator: Estimator }
  | { type: "customer/select"; checked: boolean; customer: Customer }
  | {
      type: "customer/edit-ship-to-address";
      address: any;
      customer: Customer | undefined | null;
    }
  | {
      type: "customer/remove-custom-ship-to";
      customer: Customer | undefined | null;
    }
  | { type: "projects/add"; id: string; date: string }
  | { type: "project/set-estimate"; projectEstimate: any }
  | { type: "app/open-project"; project: Project }
  | { type: "estimators/verify" };

function reducer(
  state: ReducerState = stateReducerState,
  action: StateReducerAction
): ReducerState {
  switch (action.type) {
    case "canvas/set-pan-scale":
      return state
        .setIn(["canvases", state.currentCanvas, "pan"], action.pan)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "pan",
          ],
          action.pan
        )
        .setIn(["canvases", state.currentCanvas, "scale"], action.scale)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "scale",
          ],
          action.scale
        );
    case "canvas/set-pan":
      return state
        .setIn(["canvases", state.currentCanvas, "pan"], action.pan)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "pan",
          ],
          action.pan
        );
    case "canvas/set-scale":
      return state
        .setIn(["canvases", state.currentCanvas, "scale"], action.scale)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "scale",
          ],
          action.scale
        );
    case "canvas/overrides": {
      if (action.runs && action.runs.size) {
        state = addNewValueToProjectCanvasRevisions(state, "runs", action.runs);
      }

      if (action.project) {
        state = state.set("overrides", action.project);
      }

      state = updateObjectEditWindow(state, state.runs);

      return state
        .setIn(["canvases", state.currentCanvas, "overrides"], action.overrides)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "overrides",
          ],
          action.overrides
        );
    }
    case "project/set-frozen-project": {
      return state
        .setIn(["frozenProject", "id"], action.frozenProject.id)
        .setIn(["settings", "status"], "complete");
    }
    case "project/set-frozen-estimate": {
      return state
        .setIn(["frozenEstimate", "id"], action.frozenEstimate.id)
        .setIn(
          ["frozenEstimate", "itemLists", "itemListsByCanvas"],
          action.frozenEstimate.itemLists.itemListsByCanvas
        );
    }
    case "project/load-frozen-project": {
      return state
        .setIn(["frozenProject", "id"], action.frozenProject.id)
        .setIn(
          ["frozenProject", "itemLists", "itemListsByCanvas"],
          action.frozenProject.itemLists.itemListsByCanvas
        );
    }
    case "project/unfreeze-estimate": {
      return state
        .setIn(["frozenEstimate", "id"], null)
        .setIn(["frozenEstimate", "itemLists", "itemListsByCanvas"], null);
    }
    case "project/unfreeze-project": {
      return state
        .setIn(["frozenProject", "id"], null)
        .setIn(["settings", "status"], "pending")
        .setIn(["frozenProject", "itemLists", "itemListsByCanvas"], null);
    }
    case "project/export-to-quickbooks": {
      return state.set("exportToQuickbooks", true);
    }
    case "project/add-override": {
      const upc = action.override?.item?.upc;

      if (upc) {
        const existingOverride = state.overrides.findKey(
          (override: any) => override?.item?.upc === upc
        );

        if (typeof existingOverride !== "undefined") {
          return state
            .setIn(["overrides", existingOverride], action.override)
            .setIn(
              [
                "revisions",
                state.currentRevision,
                "state",
                "overrides",
                existingOverride,
              ],
              action.override
            );
        }
      }

      return state
        .set("overrides", state.overrides.push(action.override))
        .updateIn(
          ["revisions", state.currentRevision, "state", "overrides"],
          () => {
            const overrides: any = state.getIn([
              "revisions",
              state.currentRevision,
              "state",
              "overrides",
            ]);

            return overrides.push(action.override);
          }
        );
    }
    case "project/reset-details":
      return state
        .set("settings", ProjectSettings())
        .setIn(
          ["revisions", state.currentRevision, "state", "settings"],
          ProjectSettings()
        );
    case "canvas/add":
      const newCanvas = createDefaultCanvas(action.id);

      return state
        .set("runs", newCanvas.runs)
        .set("posts", newCanvas.posts)
        .set("shapes", newCanvas.shapes)
        .set("gates", newCanvas.gates)
        .set("images", newCanvas.images)
        .set("notes", newCanvas.notes)
        .set("stairs", newCanvas.stairs)
        .set("handrails", newCanvas.handrails)
        .set("edit", newCanvas.edit)
        .set("canvases", state.canvases.set(action.id, newCanvas))
        .set("currentCanvas", action.id)
        .updateIn(
          ["revisions", state.currentRevision, "state"],
          (revision: any) => {
            return revision
              .set("canvases", state.canvases.set(action.id, newCanvas))
              .set("currentCanvas", action.id);
          }
        );
    case "canvas/select":
      const canvasState = state.canvases.get(action.id);

      if (canvasState) {
        return state
          .set("runs", canvasState.runs)
          .set("posts", canvasState.posts)
          .set("shapes", canvasState.shapes)
          .set("gates", canvasState.gates)
          .set("images", canvasState.images)
          .set("notes", canvasState.notes)
          .set("stairs", canvasState.stairs)
          .set("handrails", canvasState.handrails)
          .set("edit", canvasState.edit)
          .set("currentCanvas", action.id)
          .setIn(
            ["revisions", state.currentRevision, "state", "currentCanvas"],
            action.id
          );
      } else {
        return state;
      }
    case "canvas/settings-open":
      return state.set("canvasMenuOpen", action.canvas);
    case "canvas/settings-close":
      return state.set("canvasMenuOpen", Map());
    case "canvas/edit-settings":
      return state
        .set(
          "canvasMenuOpen",
          state.canvasMenuOpen.set("labels", action.labels)
        )
        .setIn(["canvases", action.canvas.id, "labels"], action.labels)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            action.canvas.id,
            "labels",
          ],
          action.labels
        );
    case "canvas/rename":
      return state
        .setIn(["canvases", action.id, "name"], action.name)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            action.id,
            "name",
          ],
          action.name
        );
    case "canvas/duplicate":
      const duplicateCanvas = state.canvases
        .get(action.id)
        ?.set("id", action.newId);

      return state
        .setIn(["canvases", action.newId], duplicateCanvas)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            action.newId,
          ],
          duplicateCanvas
        );
    case "canvas/delete":
      const canvasesToDelete = state.canvases;

      // Do not delete all canvases.
      if (state.canvases.size === 1) {
        return state;
      }

      let newCanvasId = null;

      const canvases = canvasesToDelete.entrySeq().toJS();

      for (let i = 0, len = canvases.length; i < len; i++) {
        let canvas: any = canvases[i][1];

        if (canvas.id !== action.id) {
          newCanvasId = canvas.id;
          break;
        }
      }

      // If another canvas was not found.
      if (!newCanvasId) {
        return state;
      }

      const theNewCanvas = state.canvases.get(newCanvasId);

      if (theNewCanvas) {
        return state
          .set("runs", theNewCanvas.runs)
          .set("posts", theNewCanvas.posts)
          .set("shapes", theNewCanvas.shapes)
          .set("gates", theNewCanvas.gates)
          .set("images", theNewCanvas.images)
          .set("notes", theNewCanvas.notes)
          .set("stairs", theNewCanvas.stairs)
          .set("handrails", theNewCanvas.handrails)
          .set("edit", theNewCanvas.edit)
          .set("canvases", state.canvases.delete(action.id))
          .set("currentCanvas", newCanvasId)
          .setIn(
            ["revisions", state.currentRevision, "state", "canvases"],
            state.canvases.delete(action.id)
          );
      } else {
        return state;
      }
    case "revisions/delete":
      const revisionsToDelete = state.revisions
        .entrySeq()
        .sort(([_indexA, a], [_indexB, b]) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        });

      // Do not delete all revisions.
      if (state.revisions.size === 1) {
        return state;
      }

      let newRevisionId = state.currentRevision;
      let deleteIndex = revisionsToDelete.findIndex(([index, revision]) => {
        return revision.id === action.revision.id;
      });

      if (state.currentRevision === action.revision.id) {
        revisionsToDelete.forEach(([_index, revision], index) => {
          if (revision.id !== action.revision.id) {
            if (
              newRevisionId === state.currentRevision &&
              index === deleteIndex - 1
            ) {
              newRevisionId = revision.id;
            }

            if (
              newRevisionId === state.currentRevision &&
              index === deleteIndex + 1
            ) {
              newRevisionId = revision.id;
            }
          }
        });
      }

      return state
        .removeIn(["revisions", action.revision.id])
        .set("currentRevision", newRevisionId);
    case "revisions/add":
      const currentRevision = state.revisions.get(state.currentRevision);

      if (currentRevision) {
        return state
          .setIn(
            ["revisions", currentRevision.id, "description"],
            action.revision.description.length
              ? action.revision.description
              : currentRevision.description
          )
          .setIn(
            ["revisions", action.revision.id],
            action.revision.set("description", "")
          )
          .set("currentRevision", action.revision.id);
      } else {
        return state;
      }
    case "revisions/switch":
      const projectId = state.id;

      const revisionState = state.revisions
        .get(action.revision.id)
        ?.state.set("id", projectId);

      if (revisionState) {
        const revisionCanvasState = revisionState.canvases
          .get(revisionState.currentCanvas)
          ?.set("id", projectId);

        if (revisionCanvasState) {
          return state
            .merge(revisionState)
            .merge(revisionCanvasState)
            .setIn(["overrides"], revisionState.overrides)
            .set("settings", revisionState.settings)
            .set("currentRevision", action.revision.id);
        } else {
          return state;
        }
      } else {
        return state;
      }

    case "runs/add":
      const newRuns = state.runs.set(action.id, action.run);

      return state
        .set("runs", newRuns)
        .setIn(["canvases", state.currentCanvas, "runs"], newRuns)
        .setIn(["revisions", state.currentRevision, "state", "runs"], newRuns)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          newRuns
        );
    case "runs/edit-open":
      return state
        .setIn(["edit", "type"], "run")
        .setIn(["edit", "object"], state.runs.get(action.runIndex));
    case "runs/edit":
      const nextRuns = state.runs.set(action.run.id, action.run);

      return state
        .set("runs", nextRuns)
        .setIn(["edit", "object"], action.run)
        .setIn(["canvases", state.currentCanvas, "runs"], nextRuns)
        .setIn(["revisions", state.currentRevision, "state", "runs"], nextRuns)
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.run
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          nextRuns
        );
    case "runs/edit-move":
      let newRunsAfterMove = state.runs.set(action.run.id, action.run);
      const originalRun = state.runs.get(action.run.id);

      const entitiesAfterResize = handleResizingOfRuns(
        newRunsAfterMove,
        action.run,
        originalRun,
        state.settings,
        state.settings,
        state.gates,
        state.handrails,
        state
      );

      const { runs, handrails, gates } = entitiesAfterResize;

      state = addNewValueToProjectCanvasRevisions(state, "gates", gates);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        handrails
      );
      state = addNewValueToProjectCanvasRevisions(state, "runs", runs);

      return state;
    case "runs/edit-spacing":
      const newRunsSpacing = state.runs.set(action.run.id, action.run);
      const oldRun = state.runs.get(action.run.id);

      const entitiesAfterSpacing = handleResizingOfRuns(
        newRunsSpacing,
        action.run,
        oldRun,
        state.settings,
        state.settings,
        state.gates,
        state.handrails,
        state
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "gates",
        entitiesAfterSpacing.gates
      );
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        entitiesAfterSpacing.handrails
      );
      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        entitiesAfterSpacing.runs
      );

      return state
        .setIn(["edit", "object"], action.run)
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.run
        );
    case "runs/edit-many":
      const editedRuns = action.runs;

      return state
        .set("runs", editedRuns)
        .setIn(["canvases", state.currentCanvas, "runs"], editedRuns)
        .setIn(
          ["revisions", state.currentRevision, "state", "runs"],
          editedRuns
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          editedRuns
        );
    case "runs/remove":
      state = deleteObjectFromEditWindow(state, action.runIndex);
      return removeRuns(state, action);
    case "corners/edit-open":
      return state
        .setIn(["edit", "type"], "corner")
        .setIn(["edit", "object"], action.selectedCorner);
    case "corners/edit":
      const nextCorners = state.corners.set(
        action.corner.identity.toString(),
        action.corner
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "corners",
        nextCorners
      );

      return state
        .setIn(["edit", "object"], action.corner)
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.corner
        );
    case "corners/edit-post-type":
      const corner = action.corner;

      let runsWithNewPostTypes = state.runs;

      corner.id.forEach((runId: any) => {
        const run = state.runs.get(runId.id);
        if (run) {
          const end = runId.type === "1" ? "start" : "end";

          const newRun = run.setIn(["postTypes", end], action.postType);
          runsWithNewPostTypes = runsWithNewPostTypes.set(runId.id, newRun);
        }
      });

      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        runsWithNewPostTypes
      );

      return state;
    case "corners/remove":
      state = deleteObjectFromEditWindow(state, action.id);
      return removeCorners(state, action);
    case "posts/add":
      const addPosts = state.posts.set(action.post.id, action.post);

      return state
        .set("posts", addPosts)
        .setIn(["canvases", state.currentCanvas, "posts"], addPosts)
        .setIn(["revisions", state.currentRevision, "state", "posts"], addPosts)
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "posts",
          ],
          addPosts
        );
    case "posts/edit-open":
      return state
        .setIn(["edit", "type"], "post")
        .setIn(["edit", "object"], state.posts.get(action.postIndex));
    case "posts/edit":
      const nextPosts = state.posts.set(action.post.id, action.post);

      return state
        .set("posts", nextPosts)
        .setIn(["edit", "object"], action.post)
        .setIn(["canvases", state.currentCanvas, "posts"], nextPosts)
        .setIn(
          ["revisions", state.currentRevision, "state", "posts"],
          nextPosts
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.post
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "posts",
          ],
          nextPosts
        );
    case "posts/remove":
      const deletedPosts = state.posts.delete(action.postIndex);

      const deletedGates = state.gates.filter((gate) => {
        if (
          (gate.p1 as any).postIndex === action.postIndex ||
          (gate.p2 as any).postIndex === action.postIndex
        ) {
          return false;
        }

        return true;
      });

      state = addNewValueToProjectCanvasRevisions(state, "gates", deletedGates);

      state = deleteObjectFromEditWindow(state, action.postIndex);

      return state
        .set("posts", deletedPosts)
        .setIn(["canvases", state.currentCanvas, "posts"], deletedPosts)
        .setIn(
          ["revisions", state.currentRevision, "state", "posts"],
          deletedPosts
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "posts",
          ],
          deletedPosts
        );
    case "handrails/add":
      const addHandrails = state.handrails.set(
        action.handrail.id,
        action.handrail
      );

      return state
        .set("handrails", addHandrails)
        .setIn(["canvases", state.currentCanvas, "handrails"], addHandrails)
        .setIn(
          ["revisions", state.currentRevision, "state", "handrails"],
          addHandrails
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "handrails",
          ],
          addHandrails
        );
    case "handrails/add-many":
      const addedHandrails = state.handrails.merge(action.grabRails);

      return state
        .set("handrails", addedHandrails)
        .setIn(["canvases", state.currentCanvas, "handrails"], addedHandrails)
        .setIn(
          ["revisions", state.currentRevision, "state", "handrails"],
          addedHandrails
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "handrails",
          ],
          addedHandrails
        );
    case "handrails/remove-many":
      return removeManyHandrails(state, action);
    case "handrails/edit-open":
      return state
        .setIn(["edit", "type"], "handrail")
        .setIn(["edit", "object"], state.handrails.get(action.handrailIndex));
    case "handrails/edit":
      const nextHandrails = state.handrails.set(
        action.handrail.id,
        action.handrail
      );

      return state
        .set("handrails", nextHandrails)
        .setIn(["edit", "object"], action.handrail)
        .setIn(["canvases", state.currentCanvas, "handrails"], nextHandrails)
        .setIn(
          ["revisions", state.currentRevision, "state", "handrails"],
          nextHandrails
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.handrail
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "handrails",
          ],
          nextHandrails
        );
    case "handrails/edit-many":
      return state
        .set("handrails", action.handrails)
        .setIn(["edit", "object"], action.handrail)
        .setIn(["canvases", state.currentCanvas, "handrails"], action.handrails)
        .setIn(
          ["revisions", state.currentRevision, "state", "handrails"],
          action.handrails
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.handrail
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "handrails",
          ],
          action.handrails
        );
    case "handrails/remove":
      state = deleteObjectFromEditWindow(state, action.handrailId);

      return removeHandrail(state, action);
    case "shapes/add":
      const addShapes = state.shapes.set(action.shape.id, action.shape);

      return state
        .set("shapes", addShapes)
        .setIn(["canvases", state.currentCanvas, "shapes"], addShapes)
        .setIn(
          ["revisions", state.currentRevision, "state", "shapes"],
          addShapes
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "shapes",
          ],
          addShapes
        );
    case "shapes/edit":
      return state
        .setIn(["shapes", action.shape.id], action.shape)
        .setIn(["edit", "object"], action.shape)
        .setIn(
          ["canvases", state.currentCanvas, "shapes", action.shape.id],
          action.shape
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.shape
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "shapes",
            action.shape.id,
          ],
          action.shape
        );
    case "shapes/edit-many":
      const editedShapes = action.shapes;

      return state
        .set("shapes", editedShapes)
        .setIn(["canvases", state.currentCanvas, "shapes"], editedShapes)
        .setIn(
          ["revisions", state.currentRevision, "state", "shapes"],
          editedShapes
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "shapes",
          ],
          editedShapes
        );
    case "shapes/edit-open":
      return state
        .setIn(["edit", "type"], "shape")
        .setIn(["edit", "object"], state.shapes.get(action.shapeIndex));
    case "shapes/remove":
      const deletedShapes = state.shapes.delete(action.shapeId);

      state = deleteObjectFromEditWindow(state, action.shapeId);

      return state
        .set("shapes", deletedShapes)
        .setIn(["canvases", state.currentCanvas, "shapes"], deletedShapes)
        .setIn(
          ["revisions", state.currentRevision, "state", "shapes"],
          deletedShapes
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "shapes",
          ],
          deletedShapes
        );
    case "gates/edit-size":
      const editedGates = action.gates;

      state = addNewValueToProjectCanvasRevisions(state, "posts", action.posts);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        action.handrails
      );

      return state
        .set("runs", action.runs)
        .set("gates", editedGates)
        .set("stairs", action.stairs)
        .setIn(["edit", "object"], action.gate)
        .setIn(["canvases", state.currentCanvas, "runs"], action.runs)
        .setIn(["canvases", state.currentCanvas, "gates"], editedGates)
        .setIn(["canvases", state.currentCanvas, "stairs"], action.stairs)
        .setIn(
          ["revisions", state.currentRevision, "state", "runs"],
          action.runs
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "gates"],
          editedGates
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "stairs"],
          action.stairs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          action.runs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "gates",
          ],
          editedGates
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "stairs",
          ],
          action.stairs
        );
    case "gates/replace-run":
      let replacedGates = action.gates;

      const replacedPosts = action.posts;

      state = addNewValueToProjectCanvasRevisions(state, "runs", action.runs);

      const replacedCorners = state.corners.filter((corner) => {
        return action.cornerIds.includes(corner.identity.toString());
      });

      const newHandrailsForReplacedRun = state.handrails.filter((handrail) => {
        if (
          (handrail.p1 as any).run === action.updatedExistingRun.id ||
          (handrail.p2 as any).run === action.updatedExistingRun.id
        ) {
          return false;
        }

        // If the run has been removed remove attached grabrails.
        if (!action.runs.has(action.updatedExistingRun.id)) {
          if (handrail.run === action.updatedExistingRun.id) {
            return false;
          }
        }

        return true;
      });

      replacedGates = replacedGates.map((gate) => {
        if (gate.id !== action.insertedGate.id) {
          const [newPost1, newPost2] = action.insertedPosts;
          const [replacedIndex1, replacedIndex2] = action.replacedPostIndex;
          if ((gate.p1 as any).runIndex === action.updatedExistingRun.id) {
            const postIndex = (gate.p1 as any).postIndex;

            if (postIndex === replacedIndex1) {
              gate = gate
                .setIn(["p1", "postIndex"], newPost1.id)
                .setIn(["p1", "runIndex"], null);

              return gate;
            }

            if (postIndex === replacedIndex2) {
              gate = gate
                .setIn(["p1", "postIndex"], newPost2.id)
                .setIn(["p1", "runIndex"], null);

              return gate;
            }
          }

          if ((gate.p2 as any).runIndex === action.updatedExistingRun.id) {
            const postIndex = (gate.p2 as any).postIndex;

            if (postIndex === replacedIndex1) {
              gate = gate
                .setIn(["p2", "postIndex"], newPost1.id)
                .setIn(["p2", "runIndex"], null);

              return gate;
            }

            if (postIndex === replacedIndex2) {
              gate = gate
                .setIn(["p2", "postIndex"], newPost2.id)
                .setIn(["p2", "runIndex"], null);

              return gate;
            }
          }
        }

        return gate;
      });

      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        newHandrailsForReplacedRun
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "posts",
        replacedPosts
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "corners",
        replacedCorners
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "gates",
        replacedGates
      );

      return state;
    case "gates/insert-into-run":
      let insertedGates = action.gates;

      const insertedPosts = action.posts ? action.posts : state.posts;

      state = state.set("runs", action.runs);

      const newCorners = state.corners.filter((corner) => {
        return action.cornerIds.includes(corner.identity.toString());
      });

      const newHandrailsGate = state.handrails.filter((handrail) => {
        if (
          (handrail.p1 as any).run === action.updatedExistingRun.id ||
          (handrail.p2 as any).run === action.updatedExistingRun.id
        ) {
          return false;
        }

        // If the run has been removed remove attached grabrails.
        if (!action.runs.has(action.updatedExistingRun.id)) {
          if (handrail.run === action.updatedExistingRun.id) {
            return false;
          }
        }

        return true;
      });

      insertedGates = insertedGates
        .map((gate) => {
          if (
            action.insertedPost &&
            action.insertedGate &&
            gate.id !== action.insertedGate.id
          ) {
            if ((gate.p1 as any).runIndex === action.updatedExistingRun.id) {
              const postIndex = (gate.p1 as any).postIndex;

              if (
                postIndex ===
                  action.insertedGate.getIn([
                    action.gatePostIndex,
                    "postIndex",
                  ]) ||
                postIndex === action.insertedPost.id
              ) {
                gate = gate
                  .setIn(["p1", "postIndex"], action.insertedPost.id)
                  .setIn(["p1", "runIndex"], null);

                return gate;
              } else {
                if (action.replacedPostIndex) {
                  if (action.replacedPostIndex.includes(postIndex)) {
                    gate = gate
                      .setIn(["p1", "postIndex"], action.insertedPost.id)
                      .setIn(["p1", "runIndex"], null);

                    return gate;
                  }
                }
                return gate;
              }
            }

            if ((gate.p2 as any).runIndex === action.updatedExistingRun.id) {
              const postIndex = (gate.p2 as any).postIndex;

              if (
                postIndex ===
                  action.insertedGate.getIn([
                    action.gatePostIndex,
                    "postIndex",
                  ]) ||
                postIndex === action.insertedPost.id
              ) {
                gate = gate
                  .setIn(["p2", "postIndex"], action.insertedPost.id)
                  .setIn(["p2", "runIndex"], null);

                return gate;
              } else {
                if (action.replacedPostIndex) {
                  if (action.replacedPostIndex.includes(postIndex)) {
                    gate = gate
                      .setIn(["p2", "postIndex"], action.insertedPost.id)
                      .setIn(["p2", "runIndex"], null);

                    return gate;
                  }
                }

                return gate;
              }
            }
          }

          return gate;
        })
        .filter((gate) => gate);

      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        newHandrailsGate
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "posts",
        insertedPosts
      );

      if (
        action.updatedExistingRun &&
        action.updatedExistingRun.stairs &&
        (action.updatedExistingRun.stairs as any).continuousStairs
      ) {
        const insertedGate = insertedGates.find(
          (gate) =>
            (gate.p1 as any).runIndex === action.updatedExistingRun.id ||
            (gate.p2 as any).runIndex === action.updatedExistingRun.id
        );

        if (insertedGate) {
          if (
            (insertedGate.p1 as any).runIndex ===
              action.updatedExistingRun.id &&
            (insertedGate.p2 as any).runIndex
          ) {
            let newRun = action.runs.get((insertedGate.p2 as any).runIndex);

            if (newRun) {
              newRun.set("stairs", action.updatedExistingRun.stairs);

              action.runs = action.runs.set(newRun.id, newRun);
            }
          }

          if (
            (insertedGate.p2 as any).runIndex ===
              action.updatedExistingRun.id &&
            (insertedGate.p1 as any).runIndex
          ) {
            let newRun = action.runs.get((insertedGate.p1 as any).runIndex);

            if (newRun) {
              newRun.set("stairs", action.updatedExistingRun.stairs);

              action.runs = action.runs.set(newRun.id, newRun);
            }
          }
        }
      }

      return state
        .set("runs", action.runs)
        .set("gates", insertedGates)
        .set("corners", newCorners)
        .setIn(["edit", "object"], action.gate)
        .setIn(["canvases", state.currentCanvas, "runs"], action.runs)
        .setIn(["canvases", state.currentCanvas, "gates"], insertedGates)
        .setIn(["canvases", state.currentCanvas, "corners"], newCorners)
        .setIn(
          ["revisions", state.currentRevision, "state", "runs"],
          action.runs
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "gates"],
          insertedGates
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "corners"],
          newCorners
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          action.runs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "gates",
          ],
          insertedGates
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "corners",
          ],
          newCorners
        );
    case "gates/add":
      return state
        .setIn(["gates", action.gate.id], action.gate)
        .setIn(
          ["canvases", state.currentCanvas, "gates", action.gate.id],
          action.gate
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "gates",
            action.gate.id,
          ],
          action.gate
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "gates",
            action.gate.id,
          ],
          action.gate
        );
    case "gates/edit":
      return state
        .setIn(["gates", action.gate.id], action.gate)
        .setIn(["edit", "object"], action.gate)
        .setIn(
          ["canvases", state.currentCanvas, "gates", action.gate.id],
          action.gate
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.gate
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "gates",
            action.gate.id,
          ],
          action.gate
        );
    case "gates/edit-open":
      return state
        .setIn(["edit", "type"], "gate")
        .setIn(["edit", "object"], state.gates.get(action.gateIndex));
    case "gates/remove":
      return removeGates(state, action);
    case "gates/remove-undo":
      return removeGates(state, action);
    case "canvas/edit-entities-resize-handrail":
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        action.handrails
      );
      state = addNewValueToProjectCanvasRevisions(state, "posts", action.posts);
      state = addNewValueToProjectCanvasRevisions(state, "gates", action.gates);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "stairs",
        action.stairs
      );
      state = addNewValueToProjectCanvasRevisions(state, "runs", action.runs);

      return state;
    case "canvas/edit-entities-resize-run":
      const newEntities = handleResizingOfRuns(
        action.runs,
        action.run,
        state.runs.get(action.run.id),
        state.settings,
        state.settings,
        action.gates,
        action.handrails,
        state
      );

      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        newEntities.handrails
      );
      state = addNewValueToProjectCanvasRevisions(state, "posts", action.posts);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "gates",
        newEntities.gates
      );
      state = addNewValueToProjectCanvasRevisions(
        state,
        "stairs",
        action.stairs
      );
      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        newEntities.runs
      );

      return state;
    case "canvas/edit-entities":
      state = addNewValueToProjectCanvasRevisions(state, "posts", action.posts);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        action.handrails
      );

      if (state.hasIn(["edit", "object", "id"])) {
        const objectId = state.getIn(["edit", "object", "id"]) as string;

        if (action.runs.has(objectId)) {
          state = state.setIn(["edit", "object"], action.runs.get(objectId));
        }

        if (action.gates.has(objectId)) {
          state = state.setIn(["edit", "object"], action.gates.get(objectId));
        }

        if (action.stairs.has(objectId)) {
          state = state.setIn(["edit", "object"], action.stairs.get(objectId));
        }

        if (action.posts.has(objectId)) {
          state = state.setIn(["edit", "object"], action.posts.get(objectId));
        }

        if (action.handrails.has(objectId)) {
          state = state.setIn(
            ["edit", "object"],
            action.handrails.get(objectId)
          );
        }
      }

      return state
        .set("runs", action.runs)
        .set("gates", action.gates)
        .set("stairs", action.stairs)
        .setIn(["canvases", state.currentCanvas, "runs"], action.runs)
        .setIn(["canvases", state.currentCanvas, "gates"], action.gates)
        .setIn(["canvases", state.currentCanvas, "stairs"], action.stairs)
        .setIn(
          ["revisions", state.currentRevision, "state", "runs"],
          action.runs
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "gates"],
          action.gates
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "stairs"],
          action.stairs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "runs",
          ],
          action.runs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "gates",
          ],
          action.gates
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "stairs",
          ],
          action.stairs
        );
    case "edit-popup/close":
      return state
        .set("edit", EditWindow())
        .setIn(
          ["revisions", state.currentRevision, "state", "edit"],
          EditWindow()
        );
    case "images/add":
      return state
        .setIn(["images", action.image.id], action.image)
        .setIn(
          ["canvases", state.currentCanvas, "images", action.image.id],
          action.image
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "images",
            action.image.id,
          ],
          action.image
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "images",
            action.image.id,
          ],
          action.image
        );
    case "images/edit-open":
      return state
        .setIn(["edit", "type"], "image")
        .setIn(["edit", "object"], state.images.get(action.imageId));
    case "images/edit":
      return state
        .setIn(["images", action.image.id], action.image)
        .setIn(["edit", "object"], action.image)
        .setIn(
          ["canvases", state.currentCanvas, "images", action.image.id],
          action.image
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.image
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "images",
            action.image.id,
          ],
          action.image
        );
    case "images/remove":
      const deletedImages = state.images.delete(action.imageId);

      state = deleteObjectFromEditWindow(state, action.imageId);

      return state
        .set("images", deletedImages)
        .setIn(["canvases", state.currentCanvas, "images"], deletedImages)
        .setIn(
          ["revisions", state.currentRevision, "state", "images"],
          deletedImages
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "images",
          ],
          deletedImages
        );
    case "notes/add":
      return state
        .setIn(["notes", action.note.id], action.note)
        .setIn(
          ["canvases", state.currentCanvas, "notes", action.note.id],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "notes",
            action.note.id,
          ],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "notes",
            action.note.id,
          ],
          action.note
        );
    case "notes/move":
      return state
        .setIn(["notes", action.note.id], action.note)
        .setIn(
          ["canvases", state.currentCanvas, "notes", action.note.id],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "notes",
            action.note.id,
          ],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "notes",
            action.note.id,
          ],
          action.note
        );
    case "notes/edit-open":
      return state
        .setIn(["notes", action.note.id], action.note)
        .setIn(
          ["canvases", state.currentCanvas, "notes", action.note.id],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "notes",
            action.note.id,
          ],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "notes",
            action.note.id,
          ],
          action.note
        );
    case "notes/edit":
      return state
        .setIn(["notes", action.index], action.note)
        .setIn(
          ["canvases", state.currentCanvas, "notes", action.index],
          action.note
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "notes", action.index],
          action.note
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "notes",
            action.index,
          ],
          action.note
        );
    case "notes/remove":
      const deletedNotes = state.notes.delete(action.id);

      state = deleteObjectFromEditWindow(state, action.id);

      return state
        .set("notes", deletedNotes)
        .setIn(["canvases", state.currentCanvas, "notes"], deletedNotes)
        .setIn(
          ["revisions", state.currentRevision, "state", "notes"],
          deletedNotes
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "notes",
          ],
          deletedNotes
        );
    case "stairs/add":
      let newStairs = state.stairs.set(action.stair.id, action.stair);

      const runsWithStairs = state.runs.filter((run) => {
        return run.stairs;
      });

      let newRunsWithUpdatedGraphs = state.runs;

      if (action.matchingEdges) {
        action.matchingEdges.forEach((edges: any, stairId: string) => {
          newStairs = newStairs.mergeIn([stairId, "stairsEdges"], edges);
        });

        action.matchingEdges.forEach((_edges: any, stairId: string) => {
          runsWithStairs.forEach((run) => {
            if ((run.stairs as any)?.continuousStairs.has(stairId)) {
              const { start, end } = (run.stairs as any)?.keys;

              const startVertical = start[0];
              const startHorizontal = start[1];

              const endVertical = end[0];
              const endHorizontal = end[1];

              let edgeKeys: string[] = [];

              // Moving horizontally.
              if (startVertical === endVertical) {
                edgeKeys = ["stairs-right-to-stairs", "stairs-left-to-stairs"];
              }

              // Moving vertically.
              if (startHorizontal === endHorizontal) {
                edgeKeys = ["stairs-bottom-to-stairs", "stairs-top-to-stairs"];
              }

              const check = getNextContinuousStair(
                newStairs,
                stairId,
                edgeKeys,
                Map()
              );

              // New stair is a part of a continuous stair.
              if (check.has(action.stair.id)) {
                newRunsWithUpdatedGraphs = newRunsWithUpdatedGraphs.mergeIn(
                  [run.id, "stairs", "continuousStairs"],
                  check
                );
              }
            }
          });
        });
      }

      const stairRunIds = newRunsWithUpdatedGraphs
        .filter((run) => {
          if (
            run.stairs &&
            (run.stairs as any).continuousStairs &&
            (run.stairs as any).continuousStairs.has(action.stair.id)
          ) {
            return true;
          }

          return false;
        })
        .map((run) => run.id);

      let newRunsEntities:
        | {
            runs: Map<string, Run>;
            handrails: Map<string, Handrail>;
            gates: Map<string, Gate>;
          }
        | undefined;

      if (stairRunIds.size) {
        stairRunIds.forEach((runId) => {
          newRunsEntities = handleResizingOfRuns(
            newRunsEntities ? newRunsEntities.runs : newRunsWithUpdatedGraphs,
            newRunsEntities
              ? newRunsEntities.runs.get(runId)
              : newRunsWithUpdatedGraphs.get(runId),
            newRunsEntities
              ? newRunsWithUpdatedGraphs.get(runId)
              : state.runs.get(runId),
            state.settings,
            state.settings,
            state.gates,
            state.handrails,
            state.set("stairs", newStairs)
          );
        });
      }

      if (newRunsEntities) {
        state = addNewValueToProjectCanvasRevisions(
          state,
          "handrails",
          newRunsEntities.handrails
        );

        state = addNewValueToProjectCanvasRevisions(
          state,
          "gates",
          newRunsEntities.gates
        );
      }

      state = addNewValueToProjectCanvasRevisions(state, "stairs", newStairs);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        newRunsEntities ? newRunsEntities.runs : newRunsWithUpdatedGraphs
      );

      return state;
    case "stairs/add-many":
      let newStairsMany = state.stairs;

      action.stairs.forEach((stair) => {
        newStairsMany = newStairsMany.set(stair.id, stair);
      });

      state = addNewValueToProjectCanvasRevisions(
        state,
        "stairs",
        newStairsMany
      );

      return state;
    case "stairs/edit-open":
      return state
        .setIn(["edit", "type"], "stairs")
        .setIn(["edit", "object"], state.stairs.get(action.stairsIndex));
    case "stairs/edit":
      let updatedStairRuns = state.runs;

      if (updatedStairRuns.size) {
        updatedStairRuns.forEach((run) => {
          if (run.stairs) {
            if ((run.stairs as any).continuousStairs.has(action.stairs.id)) {
              updatedStairRuns = updatedStairRuns.setIn(
                [run.id, "stairs", "continuousStairs", action.stairs.id],
                action.stairs
              );
            }
          }
        });
      }

      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        updatedStairRuns
      );

      return state
        .setIn(["stairs", action.stairs.id], action.stairs)
        .setIn(["edit", "object"], action.stairs)
        .setIn(
          ["canvases", state.currentCanvas, "stairs", action.stairs.id],
          action.stairs
        )
        .setIn(
          ["revisions", state.currentRevision, "state", "edit", "object"],
          action.stairs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "stairs",
            action.stairs.id,
          ],
          action.stairs
        );
    case "stairs/remove":
      let deletedStairs = state.stairs.delete(action.stairsIndex);

      const deletedStair = state.stairs.get(action.stairsIndex);

      if (deletedStair?.stairsEdges.size) {
        deletedStair.stairsEdges.forEach((edge: any) => {
          const attachedStair = state.stairs.get(edge.to);

          if (attachedStair) {
            const matchingEdge = attachedStair.stairsEdges.findKey((e: any) => {
              return e.to === action.stairsIndex;
            });

            if (matchingEdge) {
              deletedStairs = deletedStairs.deleteIn([
                attachedStair.id,
                "stairsEdges",
                matchingEdge,
              ]);
            }
          }
        });
      }

      state.runs
        .filter((run) => {
          if (
            run.stairs &&
            !deletedStairs.has((run.stairs as any).stairsIndex)
          ) {
            return true;
          }
          return false;
        })
        .forEach((run) => {
          state = removeRuns(state, { runIndex: run.id });
        });

      const newRunsWithStairsRemoved = state.runs.map((run) => {
        if (run.stairs) {
          if ((run.stairs as any).continuousStairs) {
            return run.removeIn([
              "stairs",
              "continuousStairs",
              action.stairsIndex,
            ]);
          }

          return run;
        } else {
          return run;
        }
      });

      const stairRunId = state.runs
        .filter((run) => {
          if (
            run.stairs &&
            (run.stairs as any).continuousStairs &&
            (run.stairs as any).continuousStairs.has(action.stairsIndex)
          ) {
            return true;
          }

          return false;
        })
        .map((run) => run.id);

      let newRunEntities: {
        runs: Map<string, Run>;
        gates: Map<string, Gate>;
        handrails: Map<string, Handrail>;
        stairs?: Map<string, Stairs>;
      } = {
        runs: newRunsWithStairsRemoved,
        gates: state.gates,
        handrails: state.handrails,
        stairs: deletedStairs,
      };

      if (stairRunId.size) {
        stairRunId.forEach((runId) => {
          const { runs, gates, handrails } = handleResizingOfRuns(
            newRunEntities ? newRunEntities.runs : newRunsWithStairsRemoved,
            newRunEntities
              ? newRunEntities.runs.get(runId)
              : newRunsWithStairsRemoved.get(runId),
            newRunEntities
              ? newRunsWithStairsRemoved.get(runId)
              : state.runs.get(runId),
            state.settings,
            state.settings,
            state.gates,
            state.handrails,
            state.set("stairs", deletedStairs)
          );
          newRunEntities = {
            runs,
            gates,
            handrails,
          };
        });
      }

      state = addNewValueToProjectCanvasRevisions(
        state,
        "runs",
        newRunEntities ? newRunEntities.runs : newRunsWithStairsRemoved
      );

      if (newRunEntities.handrails.size) {
        newRunEntities.handrails = newRunEntities.handrails.map((handrail) => {
          if ((handrail.p1 as any).stairsIndex === action.stairsIndex) {
            handrail = handrail.setIn(["p1", "stairsIndex"], null);
          }

          if ((handrail.p2 as any).stairsIndex === action.stairsIndex) {
            handrail = handrail.setIn(["p2", "stairsIndex"], null);
          }

          return handrail;
        });
      }

      if (newRunEntities) {
        state = addNewValueToProjectCanvasRevisions(
          state,
          "handrails",
          newRunEntities.handrails
        );

        state = addNewValueToProjectCanvasRevisions(
          state,
          "gates",
          newRunEntities.gates
        );
      }

      state = deleteObjectFromEditWindow(state, action.stairsIndex);

      return state
        .set("stairs", deletedStairs)
        .setIn(["canvases", state.currentCanvas, "stairs"], deletedStairs)
        .setIn(
          ["revisions", state.currentRevision, "state", "stairs"],
          deletedStairs
        )
        .setIn(
          [
            "revisions",
            state.currentRevision,
            "state",
            "canvases",
            state.currentCanvas,
            "stairs",
          ],
          deletedStairs
        );
    case "settings/edit":
      return state
        .set("settings", action.settings)
        .setIn(
          ["revisions", state.currentRevision, "state", "settings"],
          action.settings
        );
    case "settings/edit-spacing":
      let newGates = state.gates;

      let newHandrails = state.handrails;

      state.runs
        .filter((run) => {
          // If the run has post spacing override this will not be changed.
          if (run.getIn(["settings", "postSpacing"])) {
            return false;
          }

          return true;
        })
        .forEach((run) => {
          const entitiesAfterSpacing = handleResizingOfRuns(
            state.runs,
            run,
            state.runs.get(run.id),
            action.settings,
            state.settings,
            newGates,
            newHandrails,
            state
          );

          newGates = entitiesAfterSpacing.gates;
          newHandrails = entitiesAfterSpacing.handrails;
        });

      state = addNewValueToProjectCanvasRevisions(state, "gates", newGates);
      state = addNewValueToProjectCanvasRevisions(
        state,
        "handrails",
        newHandrails
      );

      return state
        .set("settings", action.settings)
        .setIn(
          ["revisions", state.currentRevision, "state", "settings"],
          action.settings
        );
    case "settings/toggle":
      return state.set("settingsOpen", !state.settingsOpen);
    case "settings/open":
      return state.set("settingsOpen", true);
    case "tax/set":
      return state
        .set("tax", { set: true, value: action.tax })
        .setIn(["revisions", state.currentRevision, "state", "tax"], {
          set: true,
          value: action.tax,
        });
    case "convert-to-sales-order":
      return state.set("isSalesOrder", action.transaction);
    case "tax/reset":
      return state
        .set("tax", { set: false, value: 0 })
        .setIn(["revisions", state.currentRevision, "state", "tax"], {
          set: false,
          value: 0,
        });
    case "estimators/add":
      return state.set(
        "estimators",
        state.estimators.set(action.estimator.id, action.estimator.id)
      );
    case "project/set-version":
      return state.set("version", action.version);
    case "estimators/delete":
      return state.set(
        "estimators",
        state.estimators.delete(action.estimator.id)
      );
    case "estimators/select":
      if (action.checked) {
        // Remove estimators from project.
        if (action.changeType === "mainEstimators") {
          return state.set(
            "mainEstimators",
            state.mainEstimators.delete(action.estimator.id)
          );
        } else {
          return state.set(
            "estimators",
            state.estimators.delete(action.estimator.id)
          );
        }
      } else {
        // Add estimator to project.
        if (action.changeType === "mainEstimators") {
          return state.set(
            "mainEstimators",
            Map<string, string>().set(action.estimator.id, action.estimator.id)
          );
        } else {
          return state.set(
            "estimators",
            Map<string, string>().set(action.estimator.id, action.estimator.id)
          );
        }
      }
    case "estimators/verify":
      return state.set("estimatorsVerified", true);
    case "estimators/set":
      return state.set(
        "estimators",
        Map<string, string>().set(action.estimator.id, action.estimator.id)
      );
    case "customer/select":
      if (action.checked) {
        // Unset customer if checked.
        return state.set("customer", "");
      } else {
        return state.set("customer", action.customer.id);
      }
    case "customer/edit-ship-to-address":
      return (
        state
          .setIn(["settings", "shipToAddress", "validated"], false)
          .setIn(["settings", "shipToAddress", "overrideQBAddress"], true)
          .setIn(["settings", "shipToAddress", "address"], action.address)
          // Reset tax.
          .set("tax", { set: false, value: 0 })
          .setIn(["revisions", state.currentRevision, "state", "tax"], {
            set: false,
            value: 0,
          })
      );
    case "customer/remove-custom-ship-to":
      return (
        state
          .setIn(["settings", "shipToAddress", "validated"], false)
          .setIn(["settings", "shipToAddress", "overrideQBAddress"], false)
          .setIn(["settings", "shipToAddress", "address"], {})
          // Reset tax.
          .set("tax", { set: false, value: 0 })
          .setIn(["revisions", state.currentRevision, "state", "tax"], {
            set: false,
            value: 0,
          })
      );
    case "projects/add":
      let project = stateReducerState;
      project = project.set("id", action.id);
      project = project.set("createdOn", action.date);

      return project;
    case "project/set-estimate":
      return state.set("projectEstimate", action.projectEstimate);
    case "app/open-project":
      return action.project;
    default:
      return state;
  }
}

function removeRuns(state: ReducerState, action: { runIndex: string }) {
  const deletedRuns = state.runs.delete(action.runIndex);

  const newHandrails = state.handrails.filter((handrail) => {
    if (handrail.run && handrail.run === action.runIndex) {
      return false;
    }
    if (handrail.p1 && handrail.p2) {
      if (
        (handrail.p1 as any).run === action.runIndex ||
        (handrail.p2 as any).run === action.runIndex
      ) {
        return false;
      }
    }
    return true;
  });

  const newCorners = state.corners.filter((corner) => {
    if ((corner.id as any).has(action.runIndex)) {
      return false;
    }
    return true;
  });

  const newGates = state.gates.filter((gate) => {
    if (
      (gate.p1 as any).runIndex === action.runIndex ||
      (gate.p2 as any).runIndex === action.runIndex
    ) {
      return false;
    }

    return true;
  });

  return state
    .set("runs", deletedRuns)
    .set("handrails", newHandrails)
    .set("corners", newCorners)
    .set("gates", newGates)
    .setIn(["canvases", state.currentCanvas, "runs"], deletedRuns)
    .setIn(["canvases", state.currentCanvas, "handrails"], newHandrails)
    .setIn(["canvases", state.currentCanvas, "corners"], newCorners)
    .setIn(["canvases", state.currentCanvas, "gates"], newGates)
    .setIn(["revisions", state.currentRevision, "state", "runs"], deletedRuns)
    .setIn(
      ["revisions", state.currentRevision, "state", "handrails"],
      newHandrails
    )
    .setIn(["revisions", state.currentRevision, "state", "corners"], newCorners)
    .setIn(["revisions", state.currentRevision, "state", "gates"], newGates)
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "runs",
      ],
      deletedRuns
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "handrails",
      ],
      newHandrails
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "gates",
      ],
      newGates
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "corners",
      ],
      newCorners
    );
}

function removeHandrail(
  state: ReducerState,
  action: { type: "handrails/remove"; handrailId: string }
) {
  const deletedHandrails = state.handrails.delete(action.handrailId);

  return state
    .set("handrails", deletedHandrails)
    .setIn(["canvases", state.currentCanvas, "handrails"], deletedHandrails)
    .setIn(
      ["revisions", state.currentRevision, "state", "handrails"],
      deletedHandrails
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "handrails",
      ],
      deletedHandrails
    );
}

function removeManyHandrails(
  state: ReducerState,
  action: { type: "handrails/remove-many"; removeHandrailIds: List<string> }
) {
  const removedHandrails = state.handrails.deleteAll(action.removeHandrailIds);

  return state
    .set("handrails", removedHandrails)
    .setIn(["canvases", state.currentCanvas, "handrails"], removedHandrails)
    .setIn(
      ["revisions", state.currentRevision, "state", "handrails"],
      removedHandrails
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "handrails",
      ],
      removedHandrails
    );
}

function removeGates(state: ReducerState, action: { gateId: string }) {
  const deletedGates = state.gates.delete(action.gateId);

  return state
    .set("gates", deletedGates)
    .setIn(["canvases", state.currentCanvas, "gates"], deletedGates)
    .setIn(["revisions", state.currentRevision, "state", "gates"], deletedGates)
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "gates",
      ],
      deletedGates
    );
}

function removeCorners(state: ReducerState, action: { id: string }) {
  const deletedCorners = state.corners.delete(action.id);

  return state
    .set("corners", deletedCorners)
    .setIn(["canvases", state.currentCanvas, "corners"], deletedCorners)
    .setIn(
      ["revisions", state.currentRevision, "state", "corners"],
      deletedCorners
    )
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        "corners",
      ],
      deletedCorners
    );
}

function addNewValueToProjectCanvasRevisions(
  state: ReducerState,
  key: any,
  value: any
) {
  return state
    .set(key, value)
    .setIn(["canvases", state.currentCanvas, key], value)
    .setIn(["revisions", state.currentRevision, "state", key], value)
    .setIn(
      [
        "revisions",
        state.currentRevision,
        "state",
        "canvases",
        state.currentCanvas,
        key,
      ],
      value
    );
}

export function handleResizingOfRuns(
  runs: Map<string, Run>,
  newRun: Run | undefined,
  originalRun: Run | undefined,
  settings: ProjectSettings,
  oldSettings: ProjectSettings,
  gates: Map<string, Gate>,
  handrails: Map<string, Handrail>,
  state: ReducerState
) {
  // Check for gates and handrails.
  gates = gates.map((gate) => {
    if ((gate.p1 as any).runIndex === newRun?.id) {
      if ((gate.p1 as any).postIndex !== 0) {
        const newPosts = getPosts(newRun, settings, state);
        // Calc posts length with new settings.
        return gate
          .setIn(["p1", "postIndex"], newPosts.length - 1)
          .set("x1", newPosts[newPosts.length - 1].x)
          .set("y1", newPosts[newPosts.length - 1].y);
      }
    }

    if ((gate.p2 as any).runIndex === newRun?.id) {
      if ((gate.p2 as any).postIndex !== 0) {
        const newPosts = getPosts(newRun, settings, state);
        // Calc posts length with new settings.
        let newIndex = newPosts.length - 1;

        return gate
          .setIn(["p2", "postIndex"], newPosts.length - 1)
          .set("x2", newPosts[newIndex].x)
          .set("y2", newPosts[newIndex].y);
      }
    }

    return gate;
  });

  handrails = handrails.map((handrail) => {
    let newHandrail = handrail;

    // handrail.run Does not use x1, y1, x2, y2.

    if ((handrail.p1 as any).run === newRun?.id) {
      const oldPosts = getPosts(originalRun, oldSettings, state);
      const newPosts = getPosts(newRun, settings, state);

      if ((handrail.p1 as any).postIndex !== 0) {
        // Calc posts length with new settings.
        const postIndex = handrail.getIn(["p1", "postIndex"]) as number;
        const otherPostIndex = handrail.getIn(["p2", "postIndex"]) as number;

        if (postIndex === oldPosts.length - 1) {
          newHandrail = newHandrail.setIn(
            ["p1", "postIndex"],
            newPosts.length - 1
          );

          newHandrail = newHandrail
            .set(
              "x1",
              newHandrail.get("x1") -
                (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
            )
            .set(
              "y1",
              newHandrail.get("y1") -
                (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
            );

          if (newHandrail.hasIn(["p1", "x"])) {
            newHandrail = newHandrail
              .setIn(
                ["p1", "x"],
                (newHandrail.getIn(["p1", "x"]) as number) -
                  (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
              )
              .setIn(
                ["p1", "y"],
                (newHandrail.getIn(["p1", "y"]) as number) -
                  (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
              )
              .setIn(
                ["p1", "matchingPoint", "x"],
                (handrail.getIn(["p1", "matchingPoint", "x"]) as number) -
                  (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
              )
              .setIn(
                ["p1", "matchingPoint", "y"],
                (handrail.getIn(["p1", "matchingPoint", "y"]) as number) -
                  (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
              );
          }
        } else {
          const diff = newPosts.length - oldPosts.length;
          if (postIndex < otherPostIndex) {
            // Don't go below zero, and do not go beyond the second to last post.
            const newIndex = Math.min(
              Math.max(postIndex + diff, 0),
              newPosts.length - 2
            );
            newHandrail = newHandrail.setIn(["p1", "postIndex"], newIndex);

            newHandrail = newHandrail
              .set(
                "x1",
                newHandrail.get("x1") -
                  (oldPosts[postIndex].x - newPosts[newIndex].x)
              )
              .set(
                "y1",
                newHandrail.get("y1") -
                  (oldPosts[postIndex].y - newPosts[newIndex].y)
              );

            if (newHandrail.hasIn(["p1", "x"])) {
              newHandrail = newHandrail
                .setIn(
                  ["p1", "x"],
                  (newHandrail.getIn(["p1", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p1", "y"],
                  (newHandrail.getIn(["p1", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                )
                .setIn(
                  ["p1", "matchingPoint", "x"],
                  (handrail.getIn(["p1", "matchingPoint", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p1", "matchingPoint", "y"],
                  (handrail.getIn(["p1", "matchingPoint", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                );
            }
          } else {
            // Don't go below second to first post, and do not go above last post.
            const newIndex = Math.min(
              Math.max(postIndex + diff, 1),
              newPosts.length - 1
            );
            newHandrail = newHandrail.setIn(["p1", "postIndex"], newIndex);

            newHandrail = newHandrail
              .set(
                "x1",
                newHandrail.get("x1") -
                  (oldPosts[postIndex].x - newPosts[newIndex].x)
              )
              .set(
                "y1",
                newHandrail.get("y1") -
                  (oldPosts[postIndex].y - newPosts[newIndex].y)
              );

            if (newHandrail.hasIn(["p1", "x"])) {
              newHandrail = newHandrail
                .setIn(
                  ["p1", "x"],
                  (handrail.getIn(["p1", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p1", "y"],
                  (newHandrail.getIn(["p1", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                )
                .setIn(
                  ["p1", "matchingPoint", "x"],
                  (handrail.getIn(["p1", "matchingPoint", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p1", "matchingPoint", "y"],
                  (handrail.getIn(["p1", "matchingPoint", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                );
            }
          }
        }
      } else {
        newHandrail = newHandrail
          .set("x1", newHandrail.get("x1") - (oldPosts[0].x - newPosts[0].x))
          .set("y1", newHandrail.get("y1") - (oldPosts[0].y - newPosts[0].y));

        if (newHandrail.hasIn(["p1", "x"])) {
          newHandrail = newHandrail
            .setIn(
              ["p1", "x"],
              (newHandrail.getIn(["p1", "x"]) as number) -
                (oldPosts[0].x - newPosts[0].x)
            )
            .setIn(
              ["p1", "y"],
              (newHandrail.getIn(["p1", "y"]) as number) -
                (oldPosts[0].y - newPosts[0].y)
            )
            .setIn(
              ["p1", "matchingPoint", "x"],
              (handrail.getIn(["p1", "matchingPoint", "x"]) as number) -
                (oldPosts[0].x - newPosts[0].x)
            )
            .setIn(
              ["p1", "matchingPoint", "y"],
              (handrail.getIn(["p1", "matchingPoint", "y"]) as number) -
                (oldPosts[0].y - newPosts[0].y)
            );
        }
      }
    }

    if ((handrail.p2 as any).run === newRun?.id) {
      const oldPosts = getPosts(originalRun, oldSettings, state);
      const newPosts = getPosts(newRun, settings, state);

      if ((handrail.p2 as any).postIndex !== 0) {
        // Calc posts length with new settings.
        const postIndex = handrail.getIn(["p2", "postIndex"]) as number;
        const otherPostIndex = handrail.getIn(["p1", "postIndex"]) as number;

        if (postIndex === oldPosts.length - 1) {
          newHandrail = newHandrail.setIn(
            ["p2", "postIndex"],
            newPosts.length - 1
          );

          newHandrail = newHandrail
            .set(
              "x2",
              newHandrail.get("x2") -
                (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
            )
            .set(
              "y2",
              newHandrail.get("y2") -
                (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
            );

          if (newHandrail.hasIn(["p2", "x"])) {
            newHandrail = newHandrail
              .setIn(
                ["p2", "x"],
                (newHandrail.getIn(["p2", "x"]) as number) -
                  (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
              )
              .setIn(
                ["p2", "y"],
                (newHandrail.getIn(["p2", "y"]) as number) -
                  (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
              )
              .setIn(
                ["p2", "matchingPoint", "x"],
                (handrail.getIn(["p2", "matchingPoint", "x"]) as number) -
                  (oldPosts[postIndex].x - newPosts[newPosts.length - 1].x)
              )
              .setIn(
                ["p2", "matchingPoint", "y"],
                (handrail.getIn(["p2", "matchingPoint", "y"]) as number) -
                  (oldPosts[postIndex].y - newPosts[newPosts.length - 1].y)
              );
          }
        } else {
          const diff = newPosts.length - oldPosts.length;

          if (postIndex < otherPostIndex) {
            // Don't go below zero, and do not go beyond the second to last post.
            const newIndex = Math.min(
              Math.max(postIndex + diff, 0),
              newPosts.length - 2
            );

            newHandrail = newHandrail.setIn(["p2", "postIndex"], newIndex);

            newHandrail = newHandrail
              .set(
                "x2",
                newHandrail.get("x2") -
                  (oldPosts[postIndex].x - newPosts[newIndex].x)
              )
              .set(
                "y2",
                newHandrail.get("y2") -
                  (oldPosts[postIndex].y - newPosts[newIndex].y)
              );

            if (newHandrail.hasIn(["p2", "x"])) {
              newHandrail = newHandrail
                .setIn(
                  ["p2", "x"],
                  (newHandrail.getIn(["p2", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p2", "y"],
                  (newHandrail.getIn(["p2", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                )
                .setIn(
                  ["p2", "matchingPoint", "x"],
                  (handrail.getIn(["p2", "matchingPoint", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p2", "matchingPoint", "y"],
                  (handrail.getIn(["p2", "matchingPoint", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                );
            }
          } else {
            // Don't go below second to first post, and do not go above last post.
            const newIndex = Math.min(
              Math.max(postIndex + diff, 1),
              newPosts.length - 1
            );

            newHandrail = newHandrail.setIn(["p2", "postIndex"], newIndex);

            newHandrail = newHandrail
              .set(
                "x2",
                newHandrail.get("x2") -
                  (oldPosts[postIndex].x - newPosts[newIndex].x)
              )
              .set(
                "y2",
                newHandrail.get("y2") -
                  (oldPosts[postIndex].y - newPosts[newIndex].y)
              );

            if (newHandrail.hasIn(["p2", "x"])) {
              newHandrail = newHandrail
                .setIn(
                  ["p2", "x"],
                  (newHandrail.getIn(["p2", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p2", "y"],
                  (newHandrail.getIn(["p2", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                )
                .setIn(
                  ["p2", "matchingPoint", "x"],
                  (handrail.getIn(["p2", "matchingPoint", "x"]) as number) -
                    (oldPosts[postIndex].x - newPosts[newIndex].x)
                )
                .setIn(
                  ["p2", "matchingPoint", "y"],
                  (handrail.getIn(["p2", "matchingPoint", "y"]) as number) -
                    (oldPosts[postIndex].y - newPosts[newIndex].y)
                );
            }
          }
        }
      } else {
        newHandrail = newHandrail
          .set("x2", newHandrail.get("x2") + (oldPosts[0].x - newPosts[0].x))
          .set("y2", newHandrail.get("y2") + (oldPosts[0].y - newPosts[0].y));

        if (newHandrail.hasIn(["p2", "x"])) {
          newHandrail = newHandrail
            .setIn(
              ["p2", "x"],
              (newHandrail.getIn(["p2", "x"]) as number) -
                (oldPosts[0].x - newPosts[0].x)
            )
            .setIn(
              ["p2", "y"],
              (newHandrail.getIn(["p2", "y"]) as number) -
                (oldPosts[0].y - newPosts[0].y)
            )
            .setIn(
              ["p2", "matchingPoint", "x"],
              (handrail.getIn(["p2", "matchingPoint", "x"]) as number) -
                (oldPosts[0].x - newPosts[0].x)
            )
            .setIn(
              ["p2", "matchingPoint", "y"],
              (handrail.getIn(["p2", "matchingPoint", "y"]) as number) -
                (oldPosts[0].y - newPosts[0].y)
            );
        }
      }
    }

    return newHandrail;
  });

  return {
    runs,
    handrails,
    gates,
  };
}

function deleteObjectFromEditWindow(state: ReducerState, objectId: string) {
  if (state.hasIn(["edit", "object", "id"])) {
    if (state.getIn(["edit", "object", "id"]) === objectId) {
      return state
        .set("edit", EditWindow())
        .setIn(
          ["revisions", state.currentRevision, "state", "edit"],
          EditWindow()
        );
    }
  }
  return state;
}

function updateObjectEditWindow(
  state: ReducerState,
  objects: Map<string, Run>
) {
  let newObject = null;

  if (state.hasIn(["edit", "object", "id"])) {
    objects.forEach((object) => {
      if (state.getIn(["edit", "object", "id"]) === object.id) {
        newObject = object;
      }
    });
  }

  if (newObject) {
    return state
      .setIn(["edit", "object"], newObject)
      .setIn(
        ["revisions", state.currentRevision, "state", "edit", "object"],
        newObject
      );
  }

  return state;
}

export type StateReducerState = StateWithHistory<Project>;

export const stateReducer: Reducer<StateReducerState> = undoable(reducer, {
  filter: excludeAction([
    "tax/reset",
    "canvas/set-pan",
    "canvas/set-scale",
    "canvas/set-pan-scale",
  ]),
});
