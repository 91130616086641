const salesReps = {
  "?xml": { "@_version": "1.0" },
  QBXML: {
    QBXMLMsgsRs: {
      SalesRepQueryRs: {
        SalesRepRet: [
          {
            ListID: "80000027-1715102431",
            TimeCreated: "2024-05-07T10:20:31-08:00",
            TimeModified: "2024-05-07T10:21:12-08:00",
            EditSequence: 1715102472,
            Initial: "AA",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000F8B5-1715102271",
              FullName: "Albritton, Ahmed",
            },
          },
          {
            ListID: "8000001D-1639162253",
            TimeCreated: "2021-12-10T10:50:53-08:00",
            TimeModified: "2021-12-13T13:19:31-08:00",
            EditSequence: 1639430371,
            Initial: "AM",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000C4EF-1639430364",
              FullName: "McCallum, Alex",
            },
          },
          {
            ListID: "8000000F-1513783825",
            TimeCreated: "2017-12-20T07:30:25-08:00",
            TimeModified: "2017-12-20T07:30:25-08:00",
            EditSequence: 1513783825,
            Initial: "AMF",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000049-1513783823",
              FullName: "Frieberg, Allison M",
            },
          },
          {
            ListID: "80000002-1513783379",
            TimeCreated: "2017-12-20T07:22:59-08:00",
            TimeModified: "2017-12-20T07:22:59-08:00",
            EditSequence: 1513783379,
            Initial: "AMZN",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000006-1510598977",
              FullName: "Amazon",
            },
          },
          {
            ListID: "80000003-1513783404",
            TimeCreated: "2017-12-20T07:23:24-08:00",
            TimeModified: "2017-12-20T07:23:24-08:00",
            EditSequence: 1513783404,
            Initial: "ASC",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000040-1513783401",
              FullName: "Cox, Aaron S",
            },
          },
          {
            ListID: "80000004-1513783443",
            TimeCreated: "2017-12-20T07:24:03-08:00",
            TimeModified: "2017-12-20T07:24:03-08:00",
            EditSequence: 1513783443,
            Initial: "BAM",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000041-1513783441",
              FullName: "Malcom, Boyd A",
            },
          },
          {
            ListID: "80000021-1673981716",
            TimeCreated: "2023-01-17T10:55:16-08:00",
            TimeModified: "2023-01-17T10:55:16-08:00",
            EditSequence: 1673981716,
            Initial: "ERI",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000A3DC-1603484188",
              FullName: "Eri Design, Inc.",
            },
          },
          {
            ListID: "8000001E-1642783318",
            TimeCreated: "2022-01-21T08:41:58-08:00",
            TimeModified: "2022-01-21T08:41:58-08:00",
            EditSequence: 1642783318,
            Initial: "JA",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000C70A-1642783312",
              FullName: "Andrews, James",
            },
          },
          {
            ListID: "80000020-1648579912",
            TimeCreated: "2022-03-29T11:51:52-08:00",
            TimeModified: "2022-03-29T11:51:52-08:00",
            EditSequence: 1648579912,
            Initial: "JC",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "800083BA-1574280843",
              FullName: "Corson*, Jeremiah D",
            },
          },
          {
            ListID: "80000028-1715102464",
            TimeCreated: "2024-05-07T10:21:04-08:00",
            TimeModified: "2024-05-07T10:21:04-08:00",
            EditSequence: 1715102464,
            Initial: "KB",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000F8B6-1715102444",
              FullName: "Blanchard, Katie",
            },
          },
          {
            ListID: "80000023-1677862722",
            TimeCreated: "2023-03-03T08:58:42-08:00",
            TimeModified: "2023-03-03T08:58:42-08:00",
            EditSequence: 1677862722,
            Initial: "KDR",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80009467-1591498726",
              FullName: "Redula, Kyle D",
            },
          },
          {
            ListID: "80000026-1708618974",
            TimeCreated: "2024-02-22T08:22:54-08:00",
            TimeModified: "2024-02-22T08:22:54-08:00",
            EditSequence: 1708618974,
            Initial: "MM",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000F68A-1708618970",
              FullName: "Meacham, Mitchell",
            },
          },
          {
            ListID: "8000000C-1513783681",
            TimeCreated: "2017-12-20T07:28:01-08:00",
            TimeModified: "2017-12-20T07:28:01-08:00",
            EditSequence: 1513783681,
            Initial: "SAH",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "80000047-1513783677",
              FullName: "Hibler, Seth A",
            },
          },
          {
            ListID: "80000024-1705340225",
            TimeCreated: "2024-01-15T09:37:05-08:00",
            TimeModified: "2024-01-15T09:37:05-08:00",
            EditSequence: 1705340225,
            Initial: "TIA",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000927D-1589991383",
              FullName: "Huff, Tia N",
            },
          },
          {
            ListID: "8000001B-1589994256",
            TimeCreated: "2020-05-20T10:04:16-08:00",
            TimeModified: "2020-05-20T10:04:16-08:00",
            EditSequence: 1589994256,
            Initial: "TMH",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000927A-1589991030",
              FullName: "Holladay, Travis M",
            },
          },
          {
            ListID: "8000000E-1513783740",
            TimeCreated: "2017-12-20T07:29:00-08:00",
            TimeModified: "2019-10-21T14:20:30-08:00",
            EditSequence: 1571692830,
            Initial: "Yahoo",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000003E-1513783253",
              FullName: "Y!",
            },
          },
          {
            ListID: "80000025-1705878072",
            TimeCreated: "2024-01-21T15:01:12-08:00",
            TimeModified: "2024-01-21T15:01:12-08:00",
            EditSequence: 1705878072,
            Initial: "ZS",
            IsActive: true,
            SalesRepEntityRef: {
              ListID: "8000F5F2-1705878030",
              FullName: "Szigethy, Zoran",
            },
          },
        ],
        "@_statusCode": "0",
        "@_statusSeverity": "Info",
        "@_statusMessage": "Status OK",
      },
    },
  },
};

export default salesReps;
