import {
  calculateNumPosts,
  degreesToRadians,
  getPostsWithChainOfStairs,
  isNearPoint,
  isPointBetweenPoints,
} from ".";
import { Corner, Project, ProjectSettings, Run } from "../entities";
import { calculatePostsForRunInsideOfStairs } from "./calculatePostsForRunInsideOfStairs";

export type PostInfo = {
  x: number;
  y: number;
  type: string;
  index?: number;
  matchingStair?: string[];
};

export function getPosts(
  run: Run | undefined,
  settings: ProjectSettings,
  state: Project = Project()
): PostInfo[] {
  if (!run) {
    return [];
  }

  const filterDoubleCorners = (posts: PostInfo[]) => {
    const singleCornerArray: Corner[] = state.corners
      .toArray()
      .map((corner) => corner[1])
      .filter((corner) => corner.type === "single");

    const cornerFirstRunIds = singleCornerArray.flatMap((corner) => {
      const firstRunId = Object.keys(corner.id.toObject())?.[0];
      if (firstRunId) {
        return [firstRunId];
      } else {
        return [];
      }
    });

    if (cornerFirstRunIds.includes(run.id)) {
      const terminalPosts = posts.filter((post) => post.type === "terminal");
      if (terminalPosts.length === 2) {
        const firstTerminalPostIndex = posts.findIndex(
          (post) => post.type === "terminal"
        );

        posts[firstTerminalPostIndex].type = "removed";
      }
    }

    return posts;
  };

  const getPosts = () => {
    let postSpacing = "4";

    if (settings) {
      postSpacing = settings.postSpacing;
    }

    const customPostSpacing = (run?.settings as any)?.customPostSpacing;
    if (postSpacing === "custom" && typeof customPostSpacing === "string") {
      postSpacing = customPostSpacing;
    }

    if (
      run.stairs &&
      (run.stairs as any).snapped &&
      state.stairs &&
      state.stairs.size
    ) {
      if (
        (run.stairs as any).continuousStairs &&
        ((run.stairs as any).continuousStairs.size ||
          Object.keys((run.stairs as any).continuousStairs).length)
      ) {
        return getPostsWithChainOfStairs(run, settings, state);
      }

      if (
        isPointBetweenPoints(
          { x: run.x1, y: run.y1 },
          (run.stairs as any).startPost,
          (run.stairs as any).endPost
        )
      ) {
        return calculatePostsForRunInsideOfStairs(run, state);
      } else {
        return calculatePostsForRunNormalStairs(run, state);
      }
    } else if (run.stairs) {
      // Only here for backwards compatability.
      let angle = 0;
      const { x1, y1, x2, y2 } = run;
      let endPost = { x: x2, y: y2 };

      if ((run.stairs as any).endPost) {
        endPost = (run.stairs as any).endPost;
      }

      if (
        state.stairs &&
        state.stairs.size &&
        state.stairs.get((run.stairs as any).stairsIndex)
      ) {
        const stairsAngle = state.stairs.get(
          (run.stairs as any).stairsIndex
        )?.angle;
        if (stairsAngle && typeof stairsAngle === "number") {
          angle = stairsAngle;
        }
      }

      const {
        posts: numPosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(
        run
          .set("x1", x1)
          .set("y1", y1)
          .set("x2", endPost.x)
          .set("y2", endPost.y),
        settings,
        degreesToRadians(angle)
      );
      const posts = [{ x: run.x1, y: run.y1, type: "stairPostTerminal" }];

      const {
        posts: postsRemaining,
        xDistance: xDistanceRemaining,
        yDistance: yDistanceRemaining,
      } = calculateNumPosts(
        run
          .set("x1", endPost.x)
          .set("y1", endPost.y)
          .set("x2", x2)
          .set("y2", y2),
        settings
      );

      for (let i = 1; i <= numPosts; i++) {
        const newPost = {
          x: run.x1 + i * xDistance,
          y: run.y1 + i * yDistance,
          type: "stairPostIntermediate",
        };

        posts.push(newPost);
      }

      if (!isNearPoint(endPost, { x: run.x2, y: run.y2 }, 5)) {
        posts.push({ x: endPost.x, y: endPost.y, type: "stairPostTransition" });
      }

      for (let i = 1; i <= postsRemaining; i++) {
        const newPost = {
          x: endPost.x + i * xDistanceRemaining,
          y: endPost.y + i * yDistanceRemaining,
          type: "intermediate",
        };

        posts.push(newPost);
      }

      if (isNearPoint(endPost, { x: run.x2, y: run.y2 }, 5)) {
        posts.push({ x: run.x2, y: run.y2, type: "stairPostTerminal" });
      } else {
        posts.push({ x: run.x2, y: run.y2, type: "terminal" });
      }

      return posts;
    } else {
      const {
        posts: numPosts,
        xDistance,
        yDistance,
      } = calculateNumPosts(run, settings);
      let index = 0;

      const posts = [
        { type: "terminal", x: run.x1, y: run.y1, index: index++ },
      ];

      for (let i = 1; i <= numPosts; i++) {
        const newPost = {
          type: "intermediate",
          x: run.x1 + i * xDistance,
          y: run.y1 + i * yDistance,
          index: index++,
        };

        posts.push(newPost);
      }

      posts.push({ type: "terminal", x: run.x2, y: run.y2, index: index++ });

      return posts;
    }
  };
  return filterDoubleCorners(getPosts());
}

function calculatePostsForRunNormalStairs(run: Run, state: Project) {
  let angle = 0;
  const pointThreshold = 5;
  const { x1, y1, x2, y2 } = run;

  const endPost = (run.stairs as any).endPost;
  const startPost = (run.stairs as any).startPost;

  const theStairs = state.stairs.get((run.stairs as any).stairsIndex);

  if (theStairs) {
    angle = degreesToRadians(theStairs.angle);
  }

  const stairsAngle = angle;

  const posts: { x: number; y: number; type: string }[] = [];

  // Calculate number of intermediate posts and distance vectors for initial run.
  let startPosts: number | undefined;
  let xDistance: number | undefined;
  let yDistance: number | undefined;

  if (!isNearPoint(startPost, { x: x1, y: y1 }, pointThreshold)) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      state.settings
    );

    startPosts = numPosts.posts;
    xDistance = numPosts.xDistance;
    yDistance = numPosts.yDistance;
  }

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", x1)
        .set("y1", y1)
        .set("x2", startPost.x)
        .set("y2", startPost.y),
      state.settings
    );

    startPosts = numPosts.posts;
    xDistance = numPosts.xDistance;
    yDistance = numPosts.yDistance;
  }

  // If start post is the start of the run use a terminal stairs post.
  if (isNearPoint(startPost, { x: x1, y: y1 }, pointThreshold)) {
    posts.push({ x: startPost.x, y: startPost.y, type: "stairPostTerminal" });
  } else if (!isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)) {
    // If the start post is an independent terminal post.
    posts.push({ x: x1, y: y1, type: "terminal" });

    // Add extra start posts before the starting stairs post.
    if (startPosts && xDistance && yDistance) {
      for (let i = 1; i <= startPosts; i++) {
        const newPost = {
          x: run.x1 + i * xDistance,
          y: run.y1 + i * yDistance,
          type: "intermediate",
        };

        posts.push(newPost);
      }
    }

    // Add intermediate transition stair post if in run and endpoint is not on start post.
    if (
      isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
      !isNearPoint(startPost, { x: x2, y: y2 }, pointThreshold)
    ) {
      posts.push({
        x: startPost.x,
        y: startPost.y,
        type: "stairPostTransition",
      });
    } else if (
      !isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })
    ) {
      // If start post is not between run confines add terminal post.
      posts.push({
        x: x2,
        y: y2,
        type: "terminal",
      });
    }
  }

  // Calculate number of intermediate posts and distance vectors for initial run.
  let stairPosts: number | undefined;
  let xStairDistance: number | undefined;
  let yStairDistance: number | undefined;

  if (
    isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) ||
    isNearPoint(startPost, { x: x1, y: y1 }, pointThreshold)
  ) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", startPost.x)
        .set("y1", startPost.y)
        .set("x2", endPost.x)
        .set("y2", endPost.y),
      state.settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  if (
    isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost) &&
    !isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)
  ) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", startPost.x)
        .set("y1", startPost.y)
        .set("x2", x2)
        .set("y2", y2),
      state.settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  } else if (
    isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost) &&
    isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)
  ) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      state.settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  if (stairPosts && xStairDistance && yStairDistance) {
    for (let i = 1; i <= stairPosts; i++) {
      const newPost = {
        x: startPost.x + i * xStairDistance,
        y: startPost.y + i * yStairDistance,
        type: "stairPostIntermediate",
      };

      posts.push(newPost);
    }
  }

  // If partway between the stairs add a terminal stair post.
  if (
    isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost) &&
    !isNearPoint(endPost, { x: x2, y: y2 }, pointThreshold)
  ) {
    posts.push({ x: x2, y: y2, type: "stairPostTerminal" });
  }

  // If start is partway between start and end post add terminal stair post.
  if (isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)) {
    if (!isNearPoint({ x: x1, y: y1 }, startPost, pointThreshold)) {
      posts.push({ x: x1, y: 1, type: "stairPostTerminal" });
    }
  }

  let endPosts: number | undefined;
  let xEndDistance: number | undefined;
  let yEndDistance: number | undefined;

  // Add terminal end post if end of run is near endPost.
  if (isNearPoint(endPost, { x: x2, y: y2 }, pointThreshold)) {
    posts.push({ x: x2, y: y2, type: "stairPostTerminal" });
  } else if (
    !isNearPoint(endPost, { x: x2, y: y2 }, pointThreshold) &&
    isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })
  ) {
    // Add final transition post.
    posts.push({ x: endPost.x, y: endPost.y, type: "stairPostTransition" });
  }

  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run.set("x1", endPost.x).set("y1", endPost.y).set("x2", x2).set("y2", y2),
      state.settings
    );

    endPosts = numPosts.posts;
    xEndDistance = numPosts.xDistance;
    yEndDistance = numPosts.yDistance;
  }

  if (endPosts && xEndDistance && yEndDistance) {
    for (let i = 1; i <= endPosts; i++) {
      const newPost = {
        x: endPost.x + i * xEndDistance,
        y: endPost.y + i * yEndDistance,
        type: "intermediate",
      };

      posts.push(newPost);
    }
  }

  // Add final terminal post.
  if (
    !isNearPoint(endPost, { x: x2, y: y2 }, pointThreshold) &&
    isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })
  ) {
    posts.push({ x: x2, y: y2, type: "terminal" });
  }

  return posts;
}
