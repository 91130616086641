// @ts-nocheck
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";
import SelectBox from "../../SelectBox";
import { getHandrailChains } from "../../../partsList/handrailParts";

function EditHandrailPopupHeader(props) {
  const { setPosition, dispatch, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <h2 className="edit-popup__heading">Edit Handrail</h2>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

export default function EditHandrailPopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const handrail = props.edit.object;
  const [view] = useState("controls");

  const views = {
    controls: EditHandrailControls,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditHandrailPopupHeader
        setPosition={setPosition}
        animation={animation}
        dispatch={dispatch}
      />
      <EditComponent handrail={handrail} dispatch={dispatch} />
    </div>
  );
}

function selectBoxClassName(value) {
  if (value !== "default") {
    return "parts-select--override";
  } else {
    return "";
  }
}

function updateChain(handrail, state, dispatch, updateArray, value) {
  const chains = getHandrailChains(state.handrails, state);

  let inChain = null;

  inChain = chains.attached.find((chain) => {
    const handrailFound = chain.find((chainRail) => {
      return handrail.id === chainRail.id;
    });

    if (handrailFound) {
      return true;
    } else {
      return false;
    }
  });

  if (inChain) {
    let handrails = state.handrails;

    inChain.forEach((chainRail) => {
      handrails = handrails.setIn([chainRail.id, ...updateArray], value);
    });

    dispatch({
      type: "handrails/edit-many",
      handrails: handrails,
      handrail: handrails.get(handrail.id),
    });
    return;
  }

  inChain = chains.wall.find((chain) => {
    const handrailFound = chain.find((chainRail) => {
      return handrail.id === chainRail.id;
    });

    if (handrailFound) {
      return true;
    } else {
      return false;
    }
  });

  if (inChain) {
    let handrails = state.handrails;

    inChain.forEach((chainRail) => {
      handrails = handrails.setIn([chainRail.id, ...updateArray], value);
    });

    dispatch({
      type: "handrails/edit-many",
      handrails: handrails,
      handrail: handrails.get(handrail.id),
    });
    return;
  }

  inChain = chains.hybrid.find((chain) => {
    const handrailFound = chain.find((chainRail) => {
      return handrail.id === chainRail.id;
    });

    if (handrailFound) {
      return true;
    } else {
      return false;
    }
  });

  if (inChain) {
    let handrails = state.handrails;

    inChain.forEach((chainRail) => {
      handrails = handrails.setIn([chainRail.id, ...updateArray], value);
    });

    dispatch({
      type: "handrails/edit-many",
      handrails: handrails,
      handrail: handrails.get(handrail.id),
    });
    return;
  } else {
    const newHandrail = handrail.setIn([...updateArray], value);

    dispatch({ type: "handrails/edit", handrail: newHandrail });
  }
}

function EditHandrailControls(props) {
  const { handrail, dispatch } = props;

  let labelsShowClass = "edit-popup__three-button";
  let labelsHideClass = "edit-popup__three-button";

  if (handrail.getIn(["labels", "showLabels"])) {
    labelsShowClass += " edit-popup__three-button--active";
  } else {
    labelsHideClass += " edit-popup__three-button--active";
  }

  const state = useSelector((state) => state.state.present);

  let defaultClassName = "edit-popup__three-button";
  let flippedClassName = "edit-popup__three-button";

  if (handrail.side && handrail.side === "default") {
    defaultClassName += " edit-popup__three-button--active";
  }

  if (handrail.side && handrail.side === "flip") {
    flippedClassName += " edit-popup__three-button--active";
  }

  const handRailOptions = {
    default: "Default",
    link: "Link",
    "alum-p2p": "Aluminum P2P",
    "ss-flat": 'SS Flat .5" x 2"',
    "ss-round-2": 'SS Round 2"',
    "ss-round-1.5": 'SS Round 1.5"',
  };

  const handRailColorOptions = {
    default: "Default",
    black: "Black",
    bronze: "Bronze",
    clay: "Clay",
    Natural: "Natural",
    white: "White",
    "anodized-black": "Anodized Black",
    "anodized-clear": "Anodized Clear",
  };

  return (
    <div className="edit-popup__container run-controls">
      <div className="edit-popup__section edit-popup__labels">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Side</p>
          <div className="labels-section">
            <div className="edit-popup__three-buttons labels__toggle-buttons">
              <button
                onClick={() => {
                  if (handrail.side && handrail.side === "default") {
                    // Return early if already set to default.
                    return;
                  }

                  const newHandrail = handrail.set("side", "default");

                  updateChain(
                    newHandrail,
                    state,
                    dispatch,
                    ["side"],
                    "default"
                  );
                }}
                className={defaultClassName}
              >
                Default
              </button>
              <button
                onClick={() => {
                  if (handrail.side && handrail.side === "flip") {
                    // Return early if already set to flip.
                    return;
                  }

                  const newHandrail = handrail.set("side", "flip");

                  updateChain(newHandrail, state, dispatch, ["side"], "flip");
                }}
                className={flippedClassName}
              >
                Flipped
              </button>
            </div>
          </div>
        </div>
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Parts</p>
          <div className="grabrail__parts">
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">Type</label>
              <SelectBox
                options={handRailOptions}
                selected={handrail.parts.type}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "type"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.type)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">Color</label>
              <SelectBox
                options={handRailColorOptions}
                selected={handrail.parts.linkColor}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "linkColor"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.linkColor)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">Start Loop</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                  24: '24"',
                }}
                selected={handrail.parts.start.loop}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "start", "loop"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.start.loop)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">Start End Caps</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                }}
                selected={handrail.parts.start.endCap}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "start", "endCap"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.start.endCap)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">Start Wall Return</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                }}
                selected={handrail.parts.start.wallReturn}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "start", "wallReturn"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.start.wallReturn)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">End Loop</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                  24: '24"',
                }}
                selected={handrail.parts.end.loop}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "end", "loop"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.end.loop)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">End End Caps</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                }}
                selected={handrail.parts.end.endCap}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "end", "endCap"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.end.endCap)}
              />
            </div>
            <div className="labels-section grabrail__parts-section">
              <label className="grabrail__parts-label">End Wall Return</label>
              <SelectBox
                options={{
                  default: "Default",
                  include: "Include",
                  exclude: "Exclude",
                }}
                selected={handrail.parts.end.wallReturn}
                onChange={(event) => {
                  const value = event.target.value;

                  updateChain(
                    handrail,
                    state,
                    dispatch,
                    ["parts", "end", "wallReturn"],
                    value
                  );
                }}
                className={selectBoxClassName(handrail.parts.end.wallReturn)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section edit-popup__labels">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Labels</p>
          <div className="labels-section">
            <div className="edit-popup__three-buttons labels__toggle-buttons">
              <button
                onClick={() => {
                  const labelsShow = handrail.getIn(["labels", "showLabels"]);

                  if (labelsShow !== true) {
                    let newHandrail = handrail;

                    newHandrail = newHandrail.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "handrails/edit", handrail: newHandrail });
                  }
                }}
                className={labelsShowClass}
              >
                Show
              </button>
              <button
                onClick={() => {
                  const labelsShow = handrail.getIn(["labels", "showLabels"]);

                  if (labelsShow === true) {
                    let newHandrail = handrail;

                    newHandrail = newHandrail.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "handrails/edit", handrail: newHandrail });
                  }
                }}
                className={labelsHideClass}
              >
                Hide
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newHandrail = handrail;

                  const labelsSide = handrail.getIn([
                    "labels",
                    "distanceLabel",
                  ]);

                  newHandrail = newHandrail.setIn(
                    ["labels", "distanceLabel"],
                    !labelsSide
                  );

                  dispatch({ type: "handrails/edit", handrail: newHandrail });
                }}
                className="labels-section__button"
              >
                Flip Distance Side
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "handrails/remove", handrailId: handrail.id });
            }, 0);
          }}
        >
          Delete Handrail
        </button>
      </div>
    </div>
  );
}
