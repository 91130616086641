import * as z from "zod";

const XmlSchema = z.object({
  "@_version": z.string(),
});

const RefSchema = z.object({
  ListID: z.string(),
  FullName: z.string(),
});

const SalesOrPurchaseSchema = z.object({
  Desc: z.string().nullish(),
  Price: z.number().nullish(),
  AccountRef: RefSchema,
  PricePercent: z.number().nullish(),
});

const SalesAndPurchaseSchema = z.object({
  SalesDesc: z.string(),
  SalesPrice: z.number().nullish(),
  IncomeAccountRef: RefSchema,
  PurchaseDesc: z.string().nullish(),
  ExpenseAccountRef: RefSchema,
  PurchaseCost: z.number().nullish(),
});

const ServiceItemTypeSchema = z.literal("service");

export const ServiceItemSchema = z.object({
  ListID: z.string(),
  TimeCreated: z.string(),
  TimeModified: z.string(),
  EditSequence: z.number(),
  Name: z.string(),
  FullName: z.string(),
  IsActive: z.boolean(),
  Sublevel: z.number(),
  SalesTaxCodeRef: RefSchema,
  SalesOrPurchase: SalesOrPurchaseSchema.nullish(),
  ParentRef: RefSchema.nullish(),
  BarCodeValue: z.union([z.number(), z.string()]).nullish(),
  SalesAndPurchase: SalesAndPurchaseSchema.nullish(),
  UnitOfMeasureSetRef: RefSchema.nullish(),
  type: ServiceItemTypeSchema.nullish(),
  SalesDesc: z.string().nullish(),
  SalesPrice: z.number().nullish(),
});
export type ServiceItem = z.infer<typeof ServiceItemSchema>;

const ItemServiceQueryRsSchema = z.object({
  ItemServiceRet: z.array(ServiceItemSchema),
  "@_statusCode": z.string(),
  "@_statusSeverity": z.string(),
  "@_statusMessage": z.string(),
});

const QbxmlMsgsRsSchema = z.object({
  ItemServiceQueryRs: ItemServiceQueryRsSchema,
});

const QbxmlSchema = z.object({
  QBXMLMsgsRs: QbxmlMsgsRsSchema,
});

export const ServicesSchema = z.object({
  "?xml": XmlSchema,
  QBXML: QbxmlSchema,
});
export type Services = z.infer<typeof ServicesSchema>;
