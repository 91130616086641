export type SaveReducerState = {
  isSaving: boolean;
  isSaved: boolean;
  errorOccurred: boolean;
};
export type SaveReducerAction =
  | { type: "project/saving"; isSaving: boolean }
  | { type: "project/saved"; isSaved: boolean }
  | { type: "project/save-error"; errorOccurred: boolean }
  | { type: "app/projects-view" }
  | { type: "app/open-project" }
  | { type: "app/project-view" };

export function saveReducer(
  state: SaveReducerState = {
    isSaving: false,
    isSaved: false,
    errorOccurred: false,
  },
  action: SaveReducerAction
): SaveReducerState {
  switch (action.type) {
    case "project/saving":
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        errorOccurred: false,
      };
    case "project/saved":
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        errorOccurred: false,
      };
    case "project/save-error":
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        errorOccurred: true,
      };
    case "app/projects-view":
      return { isSaving: false, isSaved: false, errorOccurred: false };
    case "app/open-project":
      return { isSaving: false, isSaved: false, errorOccurred: false };
    case "app/project-view":
      return { isSaving: false, isSaved: false, errorOccurred: false };
    default:
      return state;
  }
}
