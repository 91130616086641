// @ts-nocheck
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";

import Icon from "../Icon";
import Reports from "../Reports";
import Sync from "../Sync";

async function getUserIP() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    const ipAddress = data.ip;

    return ipAddress;
  } catch (error) {
    return null;
  }
}

getUserIP();

const ProjectDashboardHeader = (props) => {
  const dispatch = useDispatch();

  const { filters, setFilters } = props;

  const [ip, setIp] = useState(null);

  useEffect(() => {
    async function fetchIp(setIp) {
      const ipaddress = await getUserIP();
      setIp(ipaddress);
    }

    if (!ip) {
      fetchIp(setIp);
    }
  }, [ip, setIp]);

  const navigate = useNavigate();

  return (
    <header className="projects__header">
      <div className="projects__header-container">
        <img
          alt="Stainless Cable & Railing"
          src={process.env.PUBLIC_URL + "/full-logo.png"}
          className="projects__scr-logo"
        />
        {ip === "24.181.189.42" && (
          <div>
            <Reports />
            <Sync />
          </div>
        )}
        <div className="projects__search-container">
          <Icon icon="search" className="projects__search-icon" />
          <input
            className="projects__search-input"
            name="search"
            id="search"
            placeholder="Search for a project name, status, customer etc…"
            type="text"
            value={filters.search}
            onChange={(event) => {
              setFilters((prevState) => ({
                ...prevState,
                search: event.target.value,
              }));
            }}
          />
        </div>
        <button
          className="projects__add-project"
          onClick={() => {
            const id = uuid();
            const isoDate = new Date(Date.now()).toISOString();

            dispatch({ type: "app/project-view", id: id });
            dispatch({
              type: "projects/add",
              id: id,
              date: isoDate,
              navigate: navigate,
            });
          }}
        >
          <Icon icon="plus" className="add-project__icon" />
          <span className="add-project__text">Create New Project</span>
        </button>
      </div>
    </header>
  );
};

export default ProjectDashboardHeader;
