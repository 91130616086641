// @ts-nocheck
import { useState, useRef, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../Icon";
import { InventoryContext } from "../../App";
import LazyList from "../LazyList";
import useOnClickOutside from "../../hooks/useOnClickOutside";

function AddHardwareMenuCanvasHeader(props) {
  const { dispatch, setPosition, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
      zIndex: down ? 1 : 0,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="add-hardware__header">
      <label className="add-hardware__label" htmlFor="add-hardware__search">
        Add Item
      </label>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "window/close-add-hardware-canvas" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function AddHardwareItem({ item, setProduct, setThirdToLastElement }) {
  return (
    <div
      ref={setThirdToLastElement}
      className="add-hardware__item"
      key={item.FullName + (item.BarCodeValue || "no-upc")}
      tabIndex="0"
      onClick={() => {
        setTimeout(function () {
          setProduct(item);
        }, 0);
      }}
    >
      <img
        className="add-hardware__line-drawing"
        alt={item.Name}
        loading="lazy"
        src={
          process.env.PUBLIC_URL +
          "/line-drawings/" +
          item.BarCodeValue +
          ".svg"
        }
        onError={(event) => (event.target.style.visibility = "hidden")}
      />
      <div className="add-hardware__item-details">
        <p className="add-hardware__item-name">
          {item.FullName} {item.BarCodeValue}
        </p>
        {item.SalesDesc && (
          <p className="add-hardware__item-description">{item.SalesDesc}</p>
        )}
      </div>
    </div>
  );
}

function AddHardwareList(props) {
  const { setListOpen } = props;

  const ref = useRef();
  const clickOutside = useCallback(() => setListOpen(false), [setListOpen]);

  useOnClickOutside(ref, clickOutside);

  return (
    <div ref={ref} className="add-hardware__list">
      {props.children}
    </div>
  );
}

function filterInventory(item, searchTerm) {
  const searchQuery = searchTerm.toLowerCase();

  if (
    item.BarCodeValue &&
    item.BarCodeValue.toString &&
    item.BarCodeValue.toString().toLowerCase().includes(searchQuery)
  ) {
    return true;
  }

  if (item.FullName && item.FullName.toLowerCase().includes(searchQuery)) {
    return true;
  }

  if (item.Name && item.Name.toLowerCase().includes(searchQuery)) {
    return true;
  }

  if (item.SalesDesc && item.SalesDesc.toLowerCase().includes(searchQuery)) {
    return true;
  }

  return false;
}

function AddHardwareMenuCanvas(props) {
  const { state, setPosition, animation } = props;
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [listOpen, setListOpen] = useState(false);
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const inventoryList = useContext(InventoryContext);

  let inventory = inventoryList;

  if (search.length) {
    const searchQuery = search.toLowerCase();

    inventory = inventoryList.filter((item) => {
      if (
        item.BarCodeValue &&
        item.BarCodeValue.toString &&
        item.BarCodeValue.toString().toLowerCase().includes(searchQuery)
      ) {
        return true;
      }

      if (item.FullName && item.FullName.toLowerCase().includes(searchQuery)) {
        return true;
      }

      if (item.Name && item.Name.toLowerCase().includes(searchQuery)) {
        return true;
      }

      if (
        item.SalesDesc &&
        item.SalesDesc.toLowerCase().includes(searchQuery)
      ) {
        return true;
      }
      return false;
    });
  }
  return (
    <div
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "add-canvas-hardware" });
      }}
      className="add-hardware add-hardware--window"
    >
      <AddHardwareMenuCanvasHeader
        dispatch={dispatch}
        setPosition={setPosition}
        animation={animation}
      />
      <div className="add-hardware__container">
        {product === null && (
          <div className="add-hardware__search-container">
            <Icon icon="search" className="add-hardware__search-icon" />
            <input
              name="add-hardware__search"
              className="add-hardware__search"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              onFocus={() => {
                // setListOpen(true);
              }}
              placeholder="Search for a QB product..."
            />
          </div>
        )}
        {(listOpen === true || search.length !== 0) && product === null && (
          <AddHardwareList
            inventory={inventory}
            setProduct={setProduct}
            setListOpen={setListOpen}
          >
            <LazyList
              items={inventoryList}
              searchTerm={search}
              isMatchingSearchTerm={filterInventory}
              renderListItem={(listItem, index, setThirdToLastElement) => {
                return (
                  <AddHardwareItem
                    item={listItem}
                    key={listItem.ListID + index}
                    setProduct={setProduct}
                    setThirdToLastElement={setThirdToLastElement}
                  />
                );
              }}
              noResultsMessage={() => <div>No results found</div>}
            />
          </AddHardwareList>
        )}
        {product !== null && (
          <div className="add-hardware__product-selection">
            <button
              onClick={() => {
                setProduct(null);
              }}
              className="button add-hardware__clear-product-selection"
            >
              <Icon icon="close" className="add-hardware__clear-product-icon" />
            </button>
            <span className="add-hardware__product-selection-name">
              {product.Name}
            </span>
          </div>
        )}
      </div>
      <div className="add-hardware__footer">
        {product !== null && (
          <div className="add-hardware__quantity">
            <label
              htmlFor="add-hardware__quantity-input"
              className="add-hardware__quantity-label"
            >
              Adjust Quantity
            </label>
            <input
              className="add-hardware__quantity-input"
              name="add-hardware__quantity-input"
              id="add-hardware__quantity-input"
              type="number"
              value={quantity}
              min="0"
              onChange={(event) => {
                setQuantity(parseInt(event.target.value, 10));
              }}
            />
          </div>
        )}
        <button
          onClick={() => {
            if (product !== null && quantity !== 0) {
              let overrides = state.canvases.get(state.currentCanvas).overrides;

              overrides = overrides.push({
                product: product,
                quantity: quantity,
                type: "addItemToCanvas",
              });

              // Handle discounts on percentage differently and instead add them project wide.
              if (
                product.type === "discount" &&
                product.discountPercent &&
                product.discountPercent > 0
              ) {
                setTimeout(function () {
                  dispatch({ type: "window/close-add-hardware-canvas" });

                  const override = {
                    type: "addPercentDiscount",
                    item: product,
                    quantity: 1,
                  };

                  dispatch({
                    type: "project/add-override",
                    override: override,
                  });
                }, 0);
              } else {
                setTimeout(function () {
                  dispatch({ type: "window/close-add-hardware-canvas" });

                  dispatch({
                    type: "canvas/overrides",
                    overrides: overrides,
                  });
                }, 0);
              }
            }
          }}
          className="add-hardware__button button button--primary"
        >
          Add Product to Parts List
        </button>
      </div>
    </div>
  );
}

export default AddHardwareMenuCanvas;
