// @ts-nocheck
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import EditShapePopup from "./EditPopup/Shape";
import EditRunPopup from "./EditPopup/EditRun/EditRunPopup";
import EditGatePopup from "./EditPopup/EditGate/EditGatePopup";
import EditStairsPopup from "./EditPopup/EditStairs/EditStairsPopup";
import EditImagePopup from "./EditPopup/EditImage/EditImagePopup";
import EditPostPopup from "./EditPopup/EditPost/EditPostPopup";
import EditCornerPopup from "./EditPopup/EditCorner/EditCornerPopup";
import EditHandrailPopup from "./EditPopup/EditHandrail/EditHandrailPopup";

const deleteDetection = (dispatch, edit) => (event) => {
  // Only handle Delete key.
  if (event.keyCode !== 46) {
    return;
  }

  switch (edit.type) {
    case "stairs":
      setTimeout(function () {
        dispatch({ type: "stairs/remove", stairsIndex: edit.object.id });
      }, 0);
      break;
    case "gate":
      setTimeout(function () {
        dispatch({ type: "gates/remove", gateId: edit.object.id });
      }, 0);
      break;
    case "run":
      setTimeout(function () {
        dispatch({ type: "runs/remove", runIndex: edit.object.id });
      }, 0);
      break;
    case "image":
      setTimeout(function () {
        dispatch({ type: "images/remove", imageId: edit.object.id });
      }, 0);
      break;
    case "post":
      setTimeout(function () {
        dispatch({ type: "posts/remove", postIndex: edit.object.id });
      }, 0);
      break;
    case "shape":
      setTimeout(function () {
        dispatch({ type: "shapes/remove", shapeId: edit.object.id });
      }, 0);
      break;
    case "handrail":
      setTimeout(function () {
        dispatch({ type: "handrails/remove", handrailIndex: edit.object.id });
      }, 0);
      break;
    default:
      break;
  }
};

export default function EditPopup(props) {
  const { setPosition, animation } = props;

  const dispatch = useDispatch();

  const boundDelete = deleteDetection(dispatch, props.edit);

  useEffect(() => {
    document.addEventListener("keydown", boundDelete);
    return function cleanup() {
      document.removeEventListener("keydown", boundDelete);
    };
  }, [boundDelete]);

  const style = {
    top: "150px",
    left: "200px",
  };

  const types = {
    gate: EditGatePopup,
    run: EditRunPopup,
    stairs: EditStairsPopup,
    shape: EditShapePopup,
    image: EditImagePopup,
    post: EditPostPopup,
    corner: EditCornerPopup,
    handrail: EditHandrailPopup,
  };

  const EditPopupComponent = types[props.edit.type];

  return (
    <div
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "edit-popup" });
      }}
      className="edit-popup"
      style={style}
    >
      <EditPopupComponent
        edit={props.edit}
        setPosition={setPosition}
        animation={animation}
      />
    </div>
  );
}
