// @ts-nocheck
export function handrailTubeUpcs() {
  return {
    link: {
      "anodized-black": 662187017693,
      "anodized-clear": 662187017716,
      black: 662187017693,
      bronze: 662187017709,
      clay: 616320731274,
      Natural: 662187017716,
      white: 662187017723,
    },
    // Alum p2p assemblies not to be used.
    // "alum-p2p": {
    //   "anodized-black": 0,
    //   "anodized-clear": 0,
    //   black: 616453983212,
    //   bronze: 662187011080,
    //   clay: 662187011097,
    //   Natural: 662187011103,
    //   white: 662187011110,
    // },
    "alum-p2p": p2pByFootTubeUpcs(),
    "ss-flat": 616320734435,
    "ss-round-2": 616320731618,
    "ss-round-1.5": 616320731588,
    "ss-2507-2": 662187017402,
  };
}

export function handrailElbowUpcs() {
  return {
    link: {
      "anodized-black": 616320730468,
      "anodized-clear": 616320730628,
      black: 616320730468,
      bronze: 616320730604,
      clay: 616320730628,
      Natural: 616320730628,
      white: 616320730673,
    },
    // Alum p2p assemblies not to be used.
    "alum-p2p": 0,
    "ss-flat": 616320734473,
    "ss-round-2": 616320731427,
    "ss-round-1.5": 616453982123,
    "ss-2507-2": 662187017488,
  };
}

export function handrailCornersUpcs() {
  return {
    link: {
      "anodized-black": { right: 662187017747, adjustable: 616320730468 },
      "anodized-clear": { right: 662187017761, adjustable: 616320730628 },
      black: { right: 662187017747, adjustable: 616320730468 },
      bronze: { right: 662187017754, adjustable: 616320730604 },
      clay: { right: 616320729493, adjustable: 616320730628 },
      Natural: { right: 662187017761, adjustable: 616320730628 },
      white: { right: 662187017778, adjustable: 616320730673 },
    },
    "alum-p2p": {
      "anodized-black": { right: 691027722329, adjustable: 616453983038 },
      "anodized-clear": { right: 691027722336, adjustable: 616453984189 },
      black: { right: 616453983021, adjustable: 616453983038 },
      bronze: { right: 616453983502, adjustable: 616453983519 },
      clay: { right: 616453983892, adjustable: 616453983908 },
      Natural: { right: 616453984172, adjustable: 616453984189 },
      white: { right: 616453984547, adjustable: 616453984554 },
    },
    "ss-flat": { right: ssFlat90DegCornerUpc(), adjustable: 616320734763 },
    "ss-round-2": { right: 616320731410, adjustable: 616320731427 },
    "ss-round-1.5": { right: 616320732622, adjustable: 616320732646 },
    "ss-2507-2": { right: 662187017471, adjustable: 662187017471 },
  };
}

export function ssFlat90DegCornerUpc() {
  return 616320734442;
}

export function handrailBracketUpcs() {
  return {
    link: {
      "anodized-black": { wall: 662187018034, post: 662187018034 },
      "anodized-clear": { wall: 662187018058, post: 662187018058 },
      black: { wall: 662187018034, post: 662187018034 },
      bronze: { wall: 662187018041, post: 662187018041 },
      clay: { wall: 662187018058, post: 662187018058 },
      Natural: { wall: 662187018058, post: 662187018058 },
      white: { wall: 662187018065, post: 662187018065 },
    },
    "alum-p2p": {
      "anodized-black": { wall: 616320730215, post: 616320730215 },
      "anodized-clear": { wall: 616320730246, post: 616320730246 },
      black: { wall: 616320730215, post: 616320730215 },
      bronze: { wall: 616320730222, post: 616320730222 },
      clay: { wall: 616320730246, post: 616320730246 },
      Natural: { wall: 616320730246, post: 616320730246 },
      white: { wall: 616320730246, post: 616320730246 },
    },
    "ss-flat": { wall: 616320732653, post: 616320730987 },
    "ss-round-2": {
      wall: { flat: 616320732684, round: 616320732684 },
      post: { flat: 616320730987, round: 616320732936 },
    },
    "ss-round-1.5": { wall: 616320732677, post: 616320732912 },
    "ss-2507-2": { wall: 616320732653, post: 616320730987 },
  };
}

export function handrailBracketPostConnectorUpcs() {
  return {
    // Flat or round post connection.
    "ss-flat": { flat: 616320731007, round: 616320731014 },
    // When round stainless round does not need a post connector as the bracket already includes it.
    "ss-round-2": { flat: 616320731007, round: 0 },
    "ss-round-1.5": { flat: 616320731007, round: 0 },
    "ss-2507-2": { flat: 616320731007, round: 616320731014 },
  };
}

export function handrailBracketHandrailConnectorUpcs() {
  return {
    // Flat handrail connector always connects to A series bracket.
    "ss-flat": 616320731045,
    // When a series bracket is used for a flat post connection use radius saddle for handrail connection.
    "ss-round-2": { flat: 616320733001, round: 0 },
    // When round stainless round does not need a post connector as the bracket already includes it.
    "ss-round-1.5": 0,
    "ss-2507-2": 616320731045,
  };
}

export function handrailToprailBracketMountUpcs() {}

export function handrailPostBracketMountUpcs() {}

export function handrailWallMountBracketsUpcs() {
  return {
    link: {
      "anodized-black": { flat: 0, round: 0 },
      "anodized-clear": { flat: 0, round: 0 },
      black: { flat: 0, round: 0 },
      bronze: { flat: 0, round: 0 },
      clay: { flat: 0, round: 0 },
      Natural: { flat: 0, round: 0 },
      white: { flat: 0, round: 0 },
    },
    "alum-p2p": {
      "anodized-black": { flat: 0, round: 0 },
      "anodized-clear": { flat: 0, round: 0 },
      black: { flat: 0, round: 0 },
      bronze: { flat: 0, round: 0 },
      clay: { flat: 0, round: 0 },
      Natural: { flat: 0, round: 0 },
      white: { flat: 0, round: 0 },
    },
    "ss-flat": { flat: 616320732653, round: 616320732653 },
    "ss-round-2": { flat: 616320732684, round: 616320732684 },
    "ss-round-1.5": { flat: 616320732677, round: 616320732677 },
    "ss-2507-2": { flat: 616320732677, round: 616320732677 },
  };
}

export function handrailEndCapUpcs() {
  return {
    link: {
      "anodized-black": 662187017839,
      "anodized-clear": 662187017853,
      black: 662187017839,
      bronze: 662187017846,
      clay: 616320730802,
      Natural: 662187017853,
      white: 662187017860,
    },
    "alum-p2p": {
      "anodized-black": p2pEndcap(),
      "anodized-clear": p2pEndcap(),
      black: p2pEndcap(),
      bronze: p2pEndcap(),
      clay: p2pEndcap(),
      Natural: p2pEndcap(),
      white: p2pEndcap(),
    },
    "ss-flat": ssFlatEndCapUpc,
    "ss-round-2": 616320734756,
    "ss-round-1.5": 616453988262,
    "ss-2507-2": 662187017501,
  };
}

export function ssFlatEndCapUpc() {
  return 616320734459;
}

export function p2pEndcap() {
  return 616320730451;
}

export function getHandrailInstallKitUpcs() {
  return {
    "ss-flat": {
      terminal: 662187014739,
      intermediate: 616453987654,
    },
    "ss-round-2": {
      terminal: 662187014739,
      intermediate: 616453987654,
    },
    "ss-round-1.5": {
      terminal: 662187014739,
      intermediate: 616453987654,
    },
    "ss-2507-2": {
      terminal: 691027724538,
      intermediate: 691027724538,
    },
  };
}

export function p2pByFootTubeUpcs() {
  return {
    "anodized-black": 662187020334,
    "anodized-bronze": 691027722619,
    "anodized-clear": 662187019444,
    black: 616320729899,
    bronze: 616320729905,
    clay: 616320729912,
    Natural: 616320729929,
    white: 616320729936,
  };
}

export function handrailWallReturnUpcs() {
  return {
    link: {
      "anodized-black": 662187017990,
      "anodized-clear": 662187018010,
      black: 662187017990,
      bronze: 662187018003,
      clay: 662187018010,
      Natural: 662187018010,
      white: 662187018027,
    },
    "alum-p2p": {
      "anodized-black": p2pEndcap(),
      "anodized-clear": p2pEndcap(),
      black: 662187013053,
      bronze: 662187013060,
      clay: 662187013077,
      Natural: 662187013084,
      white: 662187013091,
    },
    "ss-flat": 0,
    "ss-round-2": 616320731533,
    "ss-round-1.5": 662187010915,
    "ss-2507-2": 0,
  };
}

export function handrailLoopUpcs() {
  return {
    link: {
      "anodized-black": 662187017792,
      "anodized-clear": 662187017815,
      black: 662187017792,
      bronze: 662187017808,
      clay: 616320731250,
      Natural: 662187017815,
      white: 662187017822,
    },
    "alum-p2p": {
      "anodized-black": 0,
      "anodized-clear": 0,
      black: 0,
      bronze: 0,
      clay: 0,
      Natural: 0,
      white: 0,
    },
    "ss-flat": 0,
    "ss-round-2": 616453982116,
    "ss-round-1.5": {
      include: 616453982093,
      24: 616453982109,
    },
    "ss-2507-2": 0,
  };
}
