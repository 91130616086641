// @ts-nocheck
import { useDispatch, useSelector } from "react-redux";

import Icon from "./Icon";

function RevisionsMenu(props) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.state.present);
  const revisions = project.revisions;
  const currentRevision = project.currentRevision;
  const { setRevisionsMenuOpen } = props;

  const updateRevision = (revision) => () => {
    dispatch({ type: "revisions/switch", revision });
  };

  return (
    <div className="revisions-menu">
      <div className="revisions-menu__header">
        <span className="revisions-menu__heading">Revisions History</span>
        <button
          className="app__tooltip-close"
          onClick={() => {
            // Timeout is needed to keep dom element active for intersection check.
            setTimeout(function () {
              setRevisionsMenuOpen((open) => false);
            }, 0);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
      <div className="revisions-menu__items">
        {revisions
          .entrySeq()
          .sort(([indexA, a], [indexB, b]) => {
            return new Date(a.date) - new Date(b.date);
          })
          .map(([key, revision]) => {
            let buttonClass = "revision-item__button";

            if (currentRevision === revision.id) {
              buttonClass += " revision-item__button--active";
            }

            return (
              <div key={revision.id} className="revisions-menu__item">
                <button
                  onClick={updateRevision(revision)}
                  className={buttonClass}
                ></button>
                <div className="revision-item__content">
                  <div className="revision-item__content-container">
                    <div className="revision-item__datetime">
                      {revision.datetime}
                    </div>
                    {revision.description && (
                      <div className="revision-item__description">
                        {revision.description}
                      </div>
                    )}
                  </div>
                  {revisions.size > 1 && currentRevision === revision.id && (
                    <button
                      onClick={() => {
                        dispatch({ type: "revisions/delete", revision });
                      }}
                      className="hardware__delete-item"
                    >
                      <Icon
                        icon="delete"
                        className="hardware__delete-item-icon"
                      />
                      <span className="hardware__delete-item-text">Delete</span>
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default RevisionsMenu;
