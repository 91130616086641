// @ts-nocheck
import { useState } from "react";

import EnvironmentBar from "./EnvironmentBar";

function Login() {
  const [passwordInput, setPasswordInput] = useState("");
  const [match] = useState(true);

  return (
    <div className="login">
      <EnvironmentBar />
      <div className="login-container">
        <img
          alt="Stainless Cable & Railing"
          src={process.env.PUBLIC_URL + "/full-logo.png"}
          className=""
        />
        <h1>SC&R Estimation Application</h1>
        <div className="login-form">
          <form
            action="/login/password"
            method="post"
            className="login__form"
            onSubmit={(event) => {}}
          >
            <input type="hidden" name="username" id="username" value="test" />
            <label className="login__label" htmlFor="password">
              Password
            </label>
            <input
              className="login__input"
              id="password"
              name="password"
              onChange={(event) => {
                setPasswordInput(event.target.value);
              }}
              type="password"
              value={passwordInput}
            />
            {!match && (
              <div className="error">Login Password Did Not Match</div>
            )}
            <button
              className="button button--primary login__button"
              type="submit"
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
