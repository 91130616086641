// @ts-nocheck
import Icon from "./Icon";

export default function SelectBox({
  options,
  onChange = function () {},
  selected = null,
  className = "",
  overriden = false,
}) {
  let selectBoxClassName = "select-box " + className;

  if (overriden) {
    selectBoxClassName += " select-box--overriden";
  }
  return (
    <div className={selectBoxClassName}>
      <select value={selected || ""} onChange={onChange} id="cable-size">
        {Object.entries(options).map(([key, value]) => {
          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </select>
      <Icon icon="chev-down" className="select-box__icon" />
    </div>
  );
}
