/**
 * External dependencies.
 */
import React, { createContext, useEffect, useRef, useState } from "react";
import { Map } from "immutable";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

/**
 * Internal dependencies.
 */
import "./App.css";

import SingleProjectView from "./components/SingleProjectView";
import ProjectsDashboard from "./components/ProjectsDashboard/ProjectsDashboard";
import Login from "./components/Login";
import fetchInventory, { AllInventoryTypes } from "./data/fetch-inventory";
import fetchEstimators from "./data/fetch-estimators";
import ErrorBoundary from "./components/ErrorBoundary";
import { Customer, Estimator } from "./entities";
import { fetchSaltwaterLocations } from "./data/fetch-saltwaterLocations";

export const CustomersContext = createContext<{
  customers: Map<string, Customer>;
  setCustomers: React.Dispatch<React.SetStateAction<Map<string, Customer>>>;
}>({ customers: Map<string, Customer>(), setCustomers: () => {} });

export const InventoryContext = createContext<AllInventoryTypes[]>([]);

export const EstimatorContext = createContext<{
  estimators: Map<string, Estimator>;
  setEstimators: React.Dispatch<React.SetStateAction<Map<string, Estimator>>>;
}>({ estimators: Map<string, Estimator>(), setEstimators: () => {} });

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <ProjectsDashboard />
      </ErrorBoundary>
    ),
  },
  {
    path: "/login",
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: "/logout",
    element: (
      <ErrorBoundary>
        <Login />
      </ErrorBoundary>
    ),
  },
  {
    path: "/projects/:id",
    element: (
      <ErrorBoundary>
        <SingleProjectView />
      </ErrorBoundary>
    ),
  },
  {
    path: "/*",
    element: (
      <ErrorBoundary>
        <ProjectsDashboard />
      </ErrorBoundary>
    ),
  },
]);

function App() {
  const [inventory, setInventory] = useState<AllInventoryTypes[]>([]);
  const [estimators, setEstimators] = useState<Map<string, Estimator>>(
    Map<string, Estimator>()
  );

  const [hasFetchedInventory, setHasFetchedInventory] = useState(false);
  const [hasFetchedEstimators, setHasFetchedEstimators] = useState(false);
  const hasFetchedSaltwaterLocations = useRef(false);

  useEffect(() => {
    (async function () {
      if (!hasFetchedInventory) {
        setInventory(await fetchInventory());
        setHasFetchedInventory(true);
      }
    })();
  }, [hasFetchedInventory]);

  useEffect(() => {
    (async function () {
      if (!hasFetchedEstimators) {
        setEstimators(await fetchEstimators());
        setHasFetchedEstimators(true);
      }
    })();
  }, [hasFetchedEstimators]);

  useEffect(() => {
    (async function () {
      if (!hasFetchedSaltwaterLocations.current) {
        await fetchSaltwaterLocations();
        hasFetchedSaltwaterLocations.current = true;
      }
    })();
  }, []);

  return (
    <InventoryContext.Provider value={inventory}>
      <EstimatorContext.Provider value={{ estimators, setEstimators }}>
        <RouterProvider router={router}></RouterProvider>
      </EstimatorContext.Provider>
    </InventoryContext.Provider>
  );
}

export default App;
