// @ts-nocheck
function EnvironmentBar(props) {
  const environments = {
    local: "Local",
    staging: "Staging",
    production: "Production",
  };

  let environmentType = process.env.REACT_APP_ENVIRONMENT_TYPE;

  if (!environmentType) {
    environmentType = "staging";
  }

  let build = process.env.REACT_APP_ENVIRONMENT_BUILD;

  if (!build) {
    build = false;
  }

  let environment = environments[environmentType]
    ? environments[environmentType]
    : "Staging";

  if (build) {
    environment += " (" + build + ")";
  }

  return (
    <div className={"environment__bar environment-" + environmentType}>
      <span className="environment__bar-text">{environment}</span>
    </div>
  );
}

export default EnvironmentBar;
