import { Map } from "immutable";
import { Canvas } from "../../entities";

const renderCanvasesReducerState = Map<
  string,
  { canvas: Canvas; resolve: (val: any) => void }
>();

export type RenderCanvasesReducerState = typeof renderCanvasesReducerState;
export type RenderCanvasesReducerAction =
  | { type: "render/canvas"; canvas: Canvas; resolve: (val: any) => void }
  | { type: "cleanup/canvas"; id: string };

export function renderCanvasesReducer(
  state: RenderCanvasesReducerState = Map(),
  action: RenderCanvasesReducerAction
): RenderCanvasesReducerState {
  switch (action.type) {
    case "render/canvas":
      return state.set(action.canvas.id, {
        canvas: action.canvas,
        resolve: action.resolve,
      });
    case "cleanup/canvas":
      return state.delete(action.id);
    default:
      return state;
  }
}
