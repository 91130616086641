// @ts-nocheck
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";

function EditImagePopupHeader(props) {
  const { setPosition, dispatch, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <h2 className="edit-popup__heading">Edit Image</h2>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

export default function EditImagePopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const image = props.edit.object;
  const [view] = useState("controls");

  const views = {
    controls: EditImageControls,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditImagePopupHeader
        setPosition={setPosition}
        animation={animation}
        dispatch={dispatch}
      />
      <EditComponent image={image} dispatch={dispatch} />
    </div>
  );
}

function EditImageControls(props) {
  const { image, dispatch } = props;

  return (
    <div className="edit-popup__container run-controls">
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Start Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">X</label>
              <input
                type="number"
                id="startPostX"
                value={image.point.x}
                onChange={(event) => {
                  const newImage = image.setIn(
                    ["point", "x"],
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "images/edit", image: newImage });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="startPostY">Y</label>
              <input
                type="number"
                id="startPostY"
                value={image.point.y}
                onChange={(event) => {
                  const newImage = image.setIn(
                    ["point", "y"],
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "images/edit", image: newImage });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Scale</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">S</label>
              <input
                type="number"
                id="startPostX"
                value={image.scale}
                step={0.1}
                min={0.1}
                onChange={(event) => {
                  const scale = parseFloat(event.target.value);

                  const width = Math.ceil(scale * image.originalWidth);
                  const height = Math.ceil(scale * image.originalHeight);
                  const newImage = image
                    .set("scale", scale)
                    .set("width", width)
                    .set("height", height);

                  dispatch({ type: "images/edit", image: newImage });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Rotate</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">°</label>
              <input
                type="number"
                id="startPostX"
                value={image.rotate}
                step={1}
                min={0}
                max={360}
                onChange={(event) => {
                  const rotate = parseFloat(event.target.value);

                  const newImage = image.set("rotate", rotate);

                  if (!isNaN(rotate)) {
                    dispatch({ type: "images/edit", image: newImage });
                  } else {
                    dispatch({
                      type: "images/edit",
                      image: newImage.set("rotate", ""),
                    });
                  }
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Opacity</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">O</label>
              <input
                autoFocus
                type="number"
                id="startPostX"
                step={0.1}
                max={1}
                min={0.1}
                value={
                  typeof image.properties.opacity !== "undefined"
                    ? image.properties.opacity
                    : 1
                }
                onChange={(event) => {
                  const newImage = image.setIn(
                    ["properties", "opacity"],
                    parseFloat(event.target.value)
                  );

                  dispatch({ type: "images/edit", image: newImage });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "images/remove", imageId: image.id });
            }, 0);
          }}
        >
          Delete Image
        </button>
      </div>
    </div>
  );
}
