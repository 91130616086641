import { Map } from "immutable";

export const defaultWindowState = Map({
  "save-override": Map(),
  "add-hardware-run": Map(),
  "add-hardware-post": Map(),
  "add-hardware-canvas": Map(),
  "add-image-drop": false,
  "error-message": Map({ open: false, message: "" }),
  "generate-pdf": Map({
    open: false,
    message: "Generating PDF",
    download: "",
  }),
  "shipping-estimate": Map({ open: false }),
  "edit-run-length": Map<string, any>(),
  "edit-stairs-length": Map(),
  "edit-line-length": Map(),
  "export-sales-order-dialog": Map(),
  "project-frozen-dialog": Map(),
});

export type DefaultWindowState = typeof defaultWindowState;
