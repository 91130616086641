import * as z from "zod";

const DataExtNameSchema = z.enum(["UPC", "Weight"]);

const DataExtTypeSchema = z.enum(["STR255TYPE"]);

export const XmlSchema = z.object({
  "@_version": z.string(),
});

const RefSchema = z.object({
  ListID: z.string(),
  FullName: z.string(),
});

const ItemInventoryAssemblyLineClassSchema = z.object({
  ItemInventoryRef: RefSchema,
  Quantity: z.number(),
});

const DataExtRetElementSchema = z.object({
  OwnerID: z.number(),
  DataExtName: DataExtNameSchema,
  DataExtType: DataExtTypeSchema,
  DataExtValue: z.number(),
});

const AssemblyItemTypeSchema = z.literal("assembly");

export const AssemblyItemSchema = z.object({
  ListID: z.string(),
  TimeCreated: z.string(),
  TimeModified: z.string(),
  EditSequence: z.number(),
  Name: z.string(),
  FullName: z.string(),
  BarCodeValue: z.union([z.number(), z.string()]).nullish(),
  IsActive: z.boolean(),
  Sublevel: z.number(),
  SalesTaxCodeRef: RefSchema,
  SalesPrice: z.number().nullish(),
  IncomeAccountRef: RefSchema,
  PurchaseCost: z.number().nullish(),
  COGSAccountRef: RefSchema,
  AssetAccountRef: RefSchema,
  Max: z.number().nullish(),
  QuantityOnHand: z.number(),
  AverageCost: z.number().nullish(),
  QuantityOnOrder: z.number(),
  QuantityOnSalesOrder: z.number().nullish(),
  ParentRef: RefSchema.nullish(),
  SalesDesc: z.string().nullish(),
  BuildPoint: z.number().nullish(),
  ItemInventoryAssemblyLine: z
    .union([
      z.array(z.union([ItemInventoryAssemblyLineClassSchema, z.string()])),
      ItemInventoryAssemblyLineClassSchema,
    ])
    .nullish(),
  DataExtRet: z
    .union([z.array(DataExtRetElementSchema), DataExtRetElementSchema])
    .nullish(),
  UnitOfMeasureSetRef: RefSchema.nullish(),
  PrefVendorRef: RefSchema.nullish(),
  PurchaseDesc: z.string().nullish(),
  type: AssemblyItemTypeSchema.nullish(),
});
export type AssemblyItem = z.infer<typeof AssemblyItemSchema>;

const ItemInventoryAssemblyQueryRsSchema = z.object({
  ItemInventoryAssemblyRet: z.array(AssemblyItemSchema),
  "@_statusCode": z.string(),
  "@_statusSeverity": z.string(),
  "@_statusMessage": z.string(),
});

const QbxmlMsgsRsSchema = z.object({
  ItemInventoryAssemblyQueryRs: ItemInventoryAssemblyQueryRsSchema,
});

const QbxmlSchema = z.object({
  QBXMLMsgsRs: QbxmlMsgsRsSchema,
});

export const AssemblySchema = z.object({
  "?xml": XmlSchema,
  QBXML: QbxmlSchema,
});
export type Assembly = z.infer<typeof AssemblySchema>;
