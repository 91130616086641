import { getHypotenuseInFeetRaw, getRunSettings } from "../../../utils";
import {
  getDistancesFromPostGroupsForRun,
  getGroupedPostsForRun,
} from "../../../draw";
import { getPosts, PostInfo } from "../../../utils/getPosts";
import { distanceToFeet, roundToNearestInch } from "../..";
import { Project, Run, Stairs } from "../../../entities";
import { getRailDistance } from "..";
import { Map } from "immutable";

export const woodSizes = ["2x4", "2x6", "5/4x4", "5/4x6"];
export const woodTypes = [
  "clear-cedar",
  "tight-knot-cedar",
  "balau",
  "ipe",
] as const;
export type WoodType = (typeof woodTypes)[number];

type AlumLengths = {
  type: "aluminum";
  "alum-p2p-stairs"?: number[];
  rectangular?: number[];
  shaped?: number[];
};
type SsLengths = {
  type: "stainless-steel";
  flat?: number[];
  round?: number[];
  "2507"?: number[];
};
type WoodLengths = {
  [key: string]: string | number[] | undefined;
  type: "wood";
};
type CustomerProvidedLengths = {
  type: "customer-provided";
  length: number[];
};

export type TopRailLengths = Partial<{
  aluminum: AlumLengths;
  "stainless-steel": SsLengths;
  wood: WoodLengths;
  "customer-provided": CustomerProvidedLengths;
}>;

export function calculateTopRailLengths(
  state: Project
): TopRailLengths | undefined {
  const { runs } = state;

  if (runs.size === 0) {
    return undefined;
  }

  const result: TopRailLengths = {};

  const allLengths = runs
    .map(
      (
        run: Run
      ):
        | AlumLengths
        | SsLengths
        | WoodLengths
        | CustomerProvidedLengths
        | null => {
        const runSettings = getRunSettings(run, state.settings);

        if (run.stairs && (run.stairs as any).continuousStairs) {
          const posts: PostInfo[] = getPosts(run, state.settings, state);

          const groups: Record<string, PostInfo[]> =
            getGroupedPostsForRun(posts);

          const alumLengths: AlumLengths = {
            type: "aluminum",
            "alum-p2p-stairs": [],
            rectangular: [],
            shaped: [],
          };

          const ssLengths: SsLengths = {
            type: "stainless-steel",
            flat: [],
            round: [],
            "2507": [],
          };

          const woodLengths: WoodLengths = {
            type: "wood",
            "alum-p2p-stairs": [],
          };

          const customerProvidedLengths: CustomerProvidedLengths = {
            type: "customer-provided",
            length: [],
          };

          let returnValue:
            | AlumLengths
            | SsLengths
            | WoodLengths
            | CustomerProvidedLengths
            | undefined;

          Object.entries(groups).forEach(([key, value]) => {
            if (value.length < 2) {
              return;
            }

            const { distance, distanceType } = getDistancesFromPostGroupsForRun(
              run,
              state.stairs,
              value,
              key
            );

            if (runSettings.toprailMaterial === "aluminum") {
              if (runSettings.aluminumToprailType === "alum-p2p") {
                if (distanceType === "hypotenuse") {
                  alumLengths["alum-p2p-stairs"]?.push(
                    roundToNearestInch(distanceToFeet(distance))
                  );
                }
              } else if (runSettings.aluminumToprailType === "rectangular") {
                if (distanceType === "hypotenuse") {
                  alumLengths["alum-p2p-stairs"]?.push(
                    roundToNearestInch(distanceToFeet(distance))
                  );
                } else {
                  alumLengths.rectangular?.push(
                    roundToNearestInch(distanceToFeet(distance))
                  );
                }
              } else if (runSettings.aluminumToprailType === "shaped") {
                if (distanceType === "hypotenuse") {
                  alumLengths["alum-p2p-stairs"]?.push(
                    roundToNearestInch(distanceToFeet(distance))
                  );
                } else {
                  alumLengths.shaped?.push(
                    roundToNearestInch(distanceToFeet(distance))
                  );
                }
              }

              returnValue = alumLengths;
            } else if (runSettings.toprailMaterial === "stainless-steel") {
              if (runSettings.stainlessSteelToprailType === "flat") {
                ssLengths.flat?.push(
                  roundToNearestInch(distanceToFeet(distance))
                );
              } else if (runSettings.stainlessSteelToprailType === "round") {
                ssLengths.round?.push(
                  roundToNearestInch(distanceToFeet(distance))
                );
              } else if (runSettings.stainlessSteelToprailType === "2507") {
                ssLengths["2507"]?.push(
                  roundToNearestInch(distanceToFeet(distance))
                );
              }

              returnValue = ssLengths;
            } else if (runSettings.toprailMaterial === "wood") {
              woodSizes.forEach((size) => {
                woodTypes.forEach((type) => {
                  if (!woodLengths[`wood_${size}_${type}`]) {
                    woodLengths[`wood_${size}_${type}`] = [];
                  }
                });
              });

              if (runSettings.woodToprailSetup === "wood") {
                (
                  woodLengths[
                    `wood_${runSettings.woodToprailSize}_${runSettings.woodToprailType}`
                  ] as number[]
                ).push(roundToNearestInch(distanceToFeet(distance)));
              } else if (runSettings.woodToprailSetup === "wood-alum-p2p") {
                (
                  woodLengths[
                    `wood_${runSettings.woodToprailSize}_${runSettings.woodToprailType}`
                  ] as number[]
                ).push(roundToNearestInch(distanceToFeet(distance)));

                if (
                  run.stairs &&
                  runSettings.woodAlumP2PStairsSetup === "on-top-brackets"
                ) {
                  if (distanceType === "hypotenuse") {
                    (woodLengths["alum-p2p-stairs"] as number[]).push(
                      roundToNearestInch(distanceToFeet(distance))
                    );
                  }
                }
              }

              returnValue = woodLengths;
            } else if (runSettings.toprailMaterial === "customer-provided") {
              customerProvidedLengths.length.push(
                roundToNearestInch(distanceToFeet(distance))
              );

              returnValue = customerProvidedLengths;
            }
          });

          if (!returnValue) {
            const returnValue: AlumLengths = { ...alumLengths };

            let key: keyof typeof returnValue;
            for (key in returnValue) {
              const value = returnValue[key];
              if (value?.length === 0) {
                delete returnValue[key];
              }
            }
            return alumLengths;
          }

          if (returnValue) {
            for (const key in returnValue) {
              if ((returnValue as any)[key].length === 0) {
                (returnValue as any)[key] = null;
              }
            }
          }
          return returnValue;
        } else {
          const railDistanceOnlyStairsHypotenuse =
            getRailDistanceOnlyStairsHypotenuse(run, state.stairs);

          if (runSettings.toprailMaterial === "aluminum") {
            const alumLengths: AlumLengths = {
              type: runSettings.toprailMaterial,
            };
            if (runSettings.aluminumToprailType === "alum-p2p") {
              if (run.stairs && railDistanceOnlyStairsHypotenuse) {
                alumLengths["alum-p2p-stairs"] = [
                  roundToNearestInch(
                    distanceToFeet(railDistanceOnlyStairsHypotenuse)
                  ),
                ];
              }
            } else if (runSettings.aluminumToprailType === "rectangular") {
              if (run.stairs) {
                if (railDistanceOnlyStairsHypotenuse) {
                  alumLengths["alum-p2p-stairs"] = [
                    roundToNearestInch(
                      distanceToFeet(railDistanceOnlyStairsHypotenuse)
                    ),
                  ];
                }
                alumLengths.rectangular = [
                  roundToNearestInch(
                    distanceToFeet(
                      getRailDistance(run, state.stairs) -
                        (railDistanceOnlyStairsHypotenuse || 0)
                    )
                  ),
                ];
              } else {
                alumLengths.rectangular = [
                  roundToNearestInch(
                    distanceToFeet(getRailDistance(run, state.stairs))
                  ),
                ];
              }
            } else if (runSettings.aluminumToprailType === "shaped") {
              if (run.stairs && railDistanceOnlyStairsHypotenuse) {
                alumLengths["alum-p2p-stairs"] = [
                  roundToNearestInch(
                    distanceToFeet(railDistanceOnlyStairsHypotenuse)
                  ),
                ];
                alumLengths.shaped = [
                  roundToNearestInch(
                    distanceToFeet(
                      getRailDistance(run, state.stairs) -
                        (railDistanceOnlyStairsHypotenuse || 0)
                    )
                  ),
                ];
              } else {
                alumLengths.shaped = [
                  roundToNearestInch(
                    distanceToFeet(getRailDistance(run, state.stairs))
                  ),
                ];
              }
            }

            return alumLengths;
          } else if (runSettings.toprailMaterial === "stainless-steel") {
            const ssLengths: SsLengths = {
              type: runSettings.toprailMaterial,
            };
            if (runSettings.stainlessSteelToprailType === "flat") {
              ssLengths.flat = [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ];
            } else if (runSettings.stainlessSteelToprailType === "round") {
              ssLengths.round = [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ];
            } else if (runSettings.stainlessSteelToprailType === "2507") {
              ssLengths["2507"] = [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ];
            }

            return ssLengths;
          } else if (runSettings.toprailMaterial === "wood") {
            const woodLengths: WoodLengths = {
              type: runSettings.toprailMaterial,
            };

            woodSizes.forEach((size) => {
              woodTypes.forEach((type) => {
                delete woodLengths[`wood_${size}_${type}`];
              });
            });

            if (runSettings.woodToprailSetup === "wood") {
              woodLengths[
                `wood_${runSettings.woodToprailSize}_${runSettings.woodToprailType}`
              ] = [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ];
            } else if (runSettings.woodToprailSetup === "wood-alum-p2p") {
              woodLengths[
                `wood_${runSettings.woodToprailSize}_${runSettings.woodToprailType}`
              ] = [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ];

              if (
                run.stairs &&
                runSettings.woodAlumP2PStairsSetup === "on-top-brackets" &&
                railDistanceOnlyStairsHypotenuse
              ) {
                woodLengths["alum-p2p-stairs"] = [
                  roundToNearestInch(
                    distanceToFeet(railDistanceOnlyStairsHypotenuse)
                  ),
                ];
              }
            }

            return woodLengths;
          } else if (runSettings.toprailMaterial === "customer-provided") {
            const customerProvidedLengths: CustomerProvidedLengths = {
              type: runSettings.toprailMaterial,
              length: [
                roundToNearestInch(
                  distanceToFeet(getRailDistance(run, state.stairs))
                ),
              ],
            };
            return customerProvidedLengths;
          }
        }

        return null;
      }
    )
    .reduce((lengths, runLengths) => {
      if (!runLengths) {
        return lengths;
      }
      if (!lengths[runLengths.type]) {
        (lengths[runLengths.type] as any) = runLengths;
      } else {
        Object.entries(runLengths).forEach(([key, value]) => {
          if (key === "type") {
            return;
          }

          if (!(lengths[runLengths.type] as any)[key] && value) {
            (lengths[runLengths.type] as any)[key] = [];
          }

          if (value) {
            (lengths[runLengths.type] as any)[key] = [
              ...(lengths[runLengths.type] as any)[key],
              ...(value as any),
            ];
          }
        });
      }

      return lengths;
    }, result);

  return allLengths;
}

function getRailDistanceOnlyStairsHypotenuse(
  run: Run,
  stairs: Map<string, Stairs>
) {
  if (run.stairs) {
    if ((run.stairs as any).snapped) {
      const theStairs = stairs.get((run.stairs as any).stairsIndex);

      if (theStairs) {
        if (theStairs) {
          let side = getHypotenuseInFeetRaw(run, theStairs, run.stairs);

          const angle = theStairs.angle * (Math.PI / 180);

          // Hypotenuse.
          const hypotenuse = side / Math.cos(angle);

          return hypotenuse;
        }
      }
    } else {
      const theStairs = stairs.get((run.stairs as any).stairsIndex);
      if (theStairs) {
        let side;

        if (theStairs.orientation === "vertical") {
          side = Math.abs(theStairs.y2 - theStairs.y1);
        } else {
          side = Math.abs(theStairs.x2 - theStairs.x1);
        }
        const angle = theStairs.angle * (Math.PI / 180);

        // Hypotenuse.
        const hypotenuse = side / Math.cos(angle);

        return hypotenuse;
      }
    }
  }
}
