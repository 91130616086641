// @ts-nocheck
/**
 * External dependencies.
 */
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { Map } from "immutable";

/**
 * Internal dependencies.
 */
import Details from "./Details";
import Sidebar from "./Sidebar";
import Tooltip from "./Tooltip";
import FileDragDrop from "./FileDragDrop";
import { CanvasContainer } from "./Canvas";
import RenderCanvas from "./ExportPDF/RenderCanvas";
import CanvasManager from "./CanvasManager";
import WindowManager from "./WindowManager/WindowManager";
import { serviceUrl } from "../environment";
import { Point, Image, Customer } from "../entities";
import EnvironmentBar from "./EnvironmentBar";
import { EstimatorContext } from "../App";
import { projectJsonToImmutable } from "../data/fetch-projects";
import { createContext } from "react";
import { RootState } from "../redux/configureStore";
import { getCustomerName } from "../entities/utils";

export const CustomerContext = createContext<{
  customer: Customer | null;
  updateCustomer: (
    customer: Customer | null,
    sendEvent?: "customers/add" | "customers/edit"
  ) => void;
}>({ customer: null, updateCustomer: () => {} });

function handleDrop(dispatch, state) {
  const canvas = state.canvases.get(state.currentCanvas);
  const pan = canvas.pan;

  return function (files, event) {
    let formData = new FormData();
    const file = files[0];

    if (isFileImage(file)) {
      const hashProcess = new Promise((resolve, reject) => {
        var fileItem = file;
        var reader = new FileReader();

        reader.onload = function () {
          var data = reader.result;
          var digestAlgorithm = { name: "SHA-256" };

          crypto.subtle
            .digest(digestAlgorithm, data)
            .then(function (hash) {
              var hashArray = Array.from(new Uint8Array(hash));
              var hashHex = hashArray
                .map(function (b) {
                  return ("00" + b.toString(16)).slice(-2);
                })
                .join("");

              resolve({ file: fileItem, hash: hashHex });
            })
            .catch((err) => {
              reject(err);
            });
        };

        // Call readAsText() method on FileReader object passing the File object
        reader.readAsArrayBuffer(file);
      });

      hashProcess
        .then((data) => {
          formData.append("imageUpload", data.file);
          formData.append("hash", data.hash);

          fetch(serviceUrl("upload"), {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response && response.ok) {
                return response.json();
              } else {
                throw new Error("Network error while uploading image.");
              }
            })
            .catch((error) => {
              dispatch({
                type: "window/error-message-open",
                message: "Network error while uploading image.",
              });
            })
            .then((data) => {
              const windowWidth = window.innerWidth;
              const windowHeight = window.innerHeight;

              let width = data.width;
              let height = data.height;

              const ratio = width / height;

              if (height > windowHeight) {
                height = windowHeight;
                width = height * ratio;
              }

              if (width > windowWidth) {
                width = windowWidth;
                height = width / ratio;
              }

              let imageScale = height / data.height;

              dispatch({
                type: "images/add",
                image: Image({
                  id: uuid(),
                  url: data.upload,
                  point: Point({
                    x: event.pageX - pan.x,
                    y: event.pageY - pan.y,
                  }),
                  originalWidth: data.width,
                  originalHeight: data.height,
                  width: width,
                  height: height,
                  scale: imageScale,
                }),
              });
            });
        })
        .catch((err) => {
          console.warn("err", err);
          dispatch({
            type: "window/error-message-open",
            message: "Error while uploading image. Unable to process image.",
          });
        });
    } else {
      dispatch({
        type: "window/error-message-open",
        message: "Only supports image files. Try a JPG, PNG, GIF, WebP etc.",
      });
    }
  };
}

export function isFileImage(file) {
  if (file.type.indexOf("image") === 0) {
    return true;
  }

  return false;
}

function SingleProjectView() {
  const [tool, setTool] = useState(null);
  const [dismiss, setDismiss] = useState(false);
  const [stairsTool, setStairsTool] = useState("stairs");
  const [shape, setShape] = useState(null);
  const state = useSelector((state: RootState) => state.state.present);
  const renderCanvases = useSelector(
    (state: RootState) => state.renderCanvases
  );
  const dispatch = useDispatch();
  const appState = useSelector((state: RootState) => state.appState);
  const { estimators } = useContext(EstimatorContext);
  const navigate = useNavigate();

  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(state.sequenceId > 1);
  const [customer, setCustomer] = useState<Customer | null>(null);

  const updateCustomer = (
    customer: Customer | null,
    sendEvent?: "customers/add" | "customers/edit"
  ) => {
    if (!customer) return;
    const updatedName = getCustomerName(customer);

    customer = customer.set("name", updatedName);
    if (sendEvent) {
      dispatch({
        type: sendEvent,
        customer: customer,
      });
    }
    setCustomer(customer);
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchCustomerData = async () => {
      try {
        const response = await fetch(
          serviceUrl(`getCustomer/${state.customer}`),
          { signal: controller.signal }
        );
        const json = await response.json();
        if (json) {
          const customer = Customer(json);
          setCustomer(customer);
        } else {
          setCustomer(null);
        }
      } catch (error) {}
    };

    if (customer?.id !== state.customer) {
      if (state.customer) {
        fetchCustomerData();
      } else {
        setCustomer(null);
      }
    }

    return () => controller.abort();
  }, [state.customer, customer]);

  useEffect(() => {
    const check = setInterval(() => {
      if (false && process.env.NODE_ENV === "production") {
        fetch(serviceUrl("api/auth/check"), { method: "POST" })
          .then((response) => {
            if (!response.ok) {
              navigate("/login");
            }

            return response.json();
          })
          .then((data) => {
            if (!data.isLoggedIn) {
              navigate("/login");
            }
          })
          .catch((error) => {
            console.log("error", error);
            navigate("/login");
          });
      }
    }, 1000);

    return () => clearInterval(check);
  }, [navigate]);

  useEffect(() => {
    if (appState.view === "login" || !isLoaded) {
      const projectId = appState.currentProject || id;

      const projectRequest = fetch(serviceUrl(`getProject/${projectId}`));

      projectRequest
        .then((response) => {
          if (!response.ok) {
            dispatch({
              type: "window/error-message-open",
              message: "Error loading project. Please try again.",
            });

            throw new Error("Network response was not ok.");
          }

          return response.json();
        })
        .then((project) => {
          project = projectJsonToImmutable(project);

          const projectEstimators = project.estimators.reduce(
            (acc, estimatorId) => {
              if (estimators.has(estimatorId)) {
                return acc.set(estimatorId, estimators.get(estimatorId));
              } else {
                return acc;
              }
            },
            Map()
          );

          dispatch({
            type: "app/open-project",
            id: project.id,
            project: project,
            projectEstimators: projectEstimators,
          });

          setIsLoaded(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [
    appState.view,
    isLoaded,
    appState.currentProject,
    id,
    dispatch,
    estimators,
  ]);

  return (
    <CustomerContext.Provider
      value={{ customer: customer, updateCustomer: updateCustomer }}
    >
      <div className="app single-project-view">
        <EnvironmentBar />
        <Sidebar
          tool={tool}
          setTool={setTool}
          setDismiss={setDismiss}
          shape={shape}
          setShape={setShape}
          stairsTool={stairsTool}
          setStairsTool={setStairsTool}
        />
        <FileDragDrop
          dispatch={dispatch}
          handleDrop={handleDrop(dispatch, state)}
        >
          <WindowManager tool={tool} />
        </FileDragDrop>
        <Tooltip tool={tool} dismiss={dismiss} setDismiss={setDismiss} />

        <div className="app__canvas">
          <CanvasContainer
            tool={tool}
            setTool={setTool}
            shape={shape}
            stairsTool={stairsTool}
            key="main-canvas"
          />
          {renderCanvases.size &&
            renderCanvases.valueSeq().map((canvas) => {
              return (
                <RenderCanvas
                  key={canvas.canvas.id}
                  canvas={canvas.canvas}
                  resolve={canvas.resolve}
                />
              );
            })}
        </div>
        <Details />
        <CanvasManager />
      </div>
    </CustomerContext.Provider>
  );
}

export default SingleProjectView;
