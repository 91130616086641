import { useState, useContext, useEffect } from "react";

import ProjectDashboardHeader from "./ProjectDashboardHeader";
import ProjectDashboardTable from "./ProjectDashboardTable";
import EnvironmentBar from "../EnvironmentBar";
import { EstimatorContext } from "../../App";
import { Map } from "immutable";
import Icon from "../Icon";
import { useDispatch } from "react-redux";
import { Customer, Project } from "../../entities";
import useListLoader from "../../hooks/useListLoader";
import { projectJsonToImmutable } from "../../data/fetch-projects";

let fetchTimeout: NodeJS.Timeout | null = null;

function ProjectsDashboard() {
  const {
    items: projects,
    setItems: setProjects,
    fetchItems,
    isFetching: projectsLoading,
  } = useListLoader({
    countEndpoint: "getProjectsWithCustomersPageCount",
    dataEndpoint: "getProjectsWithCustomers",
    itemParser: (json) => {
      return {
        project: projectJsonToImmutable(json.project),
        customer: Customer(json.customer),
      };
    },
  });

  const { estimators } = useContext(EstimatorContext);

  const [filters, setFilters] = useState({
    search: "",
    estimator: "",
    status: "",
  });

  const [sorts, setSorts] = useState({
    name: "",
    estimateNumber: "DSC",
    customer: "",
    date: "",
    activeSort: "estimateNumber",
  });

  const fetchProjects = () => {
    let query: Record<string, string> = {
      searchTerm: filters.search,
      estimator: filters.estimator,
      status: filters.status,
    };
    if (
      sorts.activeSort &&
      (sorts[sorts.activeSort as keyof typeof sorts] === "ASC" ||
        sorts[sorts.activeSort as keyof typeof sorts] === "DSC")
    ) {
      query = {
        ...query,
        sortBy: sorts.activeSort,
        sortOrder: sorts[sorts.activeSort as keyof typeof sorts],
      };
    }
    fetchItems(query);
  };

  useEffect(() => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }

    fetchTimeout = setTimeout(() => {
      fetchProjects();
    }, 1000);
  }, [
    filters.search,
    filters.estimator,
    filters.status,
    sorts.name,
    sorts.date,
    sorts.customer,
    sorts.estimateNumber,
    sorts.activeSort,
  ]);

  const [selected, setSelected] = useState<Map<string, boolean>>(Map());

  const dispatch = useDispatch();

  //   const navigate = useNavigate();

  //   useEffect(() => {
  //     const check = setInterval(() => {
  //       if (false && process.env.NODE_ENV === "production") {
  //         fetch(serviceUrl("api/auth/check"), { method: "POST" })
  //           .then((response) => {
  //             if (!response.ok) {
  //               navigate("/login");
  //             }

  //             return response.json();
  //           })
  //           .then((data) => {
  //             if (!data.isLoggedIn) {
  //               navigate("/login");
  //             }
  //           })
  //           .catch((error) => {
  //             console.log("error", error);
  //             navigate("/login");
  //           });
  //       }
  //     }, 1000);

  //     return () => clearInterval(check);
  //   }, [navigate]);

  return (
    <div className="project-dashboard">
      <EnvironmentBar />
      <ProjectDashboardHeader filters={filters} setFilters={setFilters} />
      <ProjectDashboardTable
        projects={projects}
        setProjects={setProjects}
        setSorts={setSorts}
        estimators={estimators}
        setFilters={setFilters}
        filters={filters}
        selected={selected}
        setSelected={setSelected}
        isLoading={projectsLoading}
        loadMore={fetchProjects}
      />
      {projectsLoading && !projects.length && (
        <div className="project-dashboard__loading">Projects Loading...</div>
      )}
      {!!selected.size && (
        <div className="project-dashboard__selected">
          <button
            onClick={() => {
              const newProjects = projects.map((project) => {
                let newProject = project.project;

                if (selected.has(project.project.id)) {
                  newProject = project.project.setIn(
                    ["settings", "status"],
                    "trash"
                  );
                  dispatch({
                    type: "project/update-from-dashboard",
                    project: newProject,
                  });
                }

                return { project: newProject, customer: project.customer };
              });
              setProjects(newProjects);
              setSelected(Map());
            }}
            className="project-dashboard__selected__button"
          >
            <Icon
              icon="delete"
              className="project-dashboard__delete-item-icon"
            />
            <span className="project-dashboard__delete-item-text">
              Delete Selected Projects
            </span>
          </button>
          <button
            onClick={() => {
              setSelected(Map());
            }}
            className="project-dashboard__selected__button project-dashboard__clear-selected__button"
          >
            <Icon
              icon="alert"
              className="project-dashboard__delete-item-icon"
            />
            <span className="project-dashboard__delete-item-text">
              Clear Selection of Projects
            </span>
          </button>
        </div>
      )}
    </div>
  );
}

export default ProjectsDashboard;
