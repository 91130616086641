// @ts-nocheck
import { useDispatch, useSelector } from "react-redux";

import { findByUpc, ItemList } from "../../partsList/RunItemList";
import { getDateValues, projectEstimateNumber } from "../../utils";
import { getTotal } from "../../partsList";
import { serviceUrl } from "../../environment";
import hashCode from "../../utils/hash";
import getPickListItems from "./getPickListItems";
import { useContext } from "react";
import { EstimatorContext } from "../../App";
import { Customer } from "../../entities";
import { formatCustomerPhone } from "../../entities/utils";
import { CustomerContext } from "../SingleProjectView";

const ExportSalesOrderButton = ({
  masterItemList,
  inventoryList,
  project,
  setProcessing,
  processing,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state.present);

  let buttonText = "Create QB Sales Order";

  if (state.isSalesOrder) {
    buttonText = "Update QB Sales Order";
  }

  const { customer: customerRecord, updateCustomer } =
    useContext(CustomerContext);

  const { estimators } = useContext(EstimatorContext);

  return (
    <button
      className="parts-list__button button--primary"
      onClick={async () => {
        if (processing) {
          // If already processing request do not spawn another request.
          return;
        }

        const qbItemList = Object.values(masterItemList)
          .map((item) => {
            const inventoryItem = findByUpc(item.upc, inventoryList);

            if (inventoryItem) {
              if (item.override) {
                return {
                  ListID: inventoryItem.ListID,
                  FullName: inventoryItem.FullName,
                  quantity: item.quantity,
                  price: item.price,
                  description: item.description,
                  override: true,
                  type: "qbItem",
                  itemType: item.type
                    ? item.type
                    : inventoryItem.type
                    ? inventoryItem.type
                    : "inventory",
                  discountPercent: item.discountPercent
                    ? item.discountPercent
                    : null,
                };
              } else {
                return {
                  ListID: inventoryItem.ListID,
                  FullName: inventoryItem.FullName,
                  quantity: item.quantity,
                  price: item.price,
                  type: "qbItem",
                  itemType: item.type
                    ? item.type
                    : inventoryItem.type
                    ? inventoryItem.type
                    : "inventory",
                  discountPercent: item.discountPercent
                    ? item.discountPercent
                    : null,
                };
              }
            } else {
              return false;
            }
          })
          .filter((item) => item);

        const total = getTotal(masterItemList);

        const resellerPermit = state.settings.resellerPermit;

        if (
          qbItemList.length &&
          total > 0 &&
          !project.tax.set &&
          !resellerPermit
        ) {
          dispatch({
            type: "window/error-message-open",
            message:
              "Calculate tax first by clicking the percent icon next to the total.",
          });
          return;
        }

        let customer = customerRecord?.toJS() || null;

        let estimator = null;

        if (project.estimators) {
          estimator = project.estimators.reduce(
            (_acc, estimatorId) => estimators.get(estimatorId, null),
            null
          );
        }

        const estimateNumber = projectEstimateNumber(project).replace("#", "");

        let type = "addSalesOrder";

        if (customer?.data?.phone) {
          customer.data.phone = formatCustomerPhone(customer.data.phone);
        }

        if (customer?.data?.altPhone) {
          customer.data.altPhone = formatCustomerPhone(customer.data.altPhone);
        }

        if (!customer?.exportedToQB) {
          type = "addSalesOrderAndCustomer";
        }

        if (project.isSalesOrder) {
          type = "updateSalesOrder";
        }

        const qbRequestData = {
          items: qbItemList,
          customer: customer,
          customerName: customer.name,
          estimator: estimator ? estimator.id : null,
          estimateNumber: estimateNumber,
          shipTo: project.settings.shipToAddress,
          isSalesOrder: project.isSalesOrder,
          tax: project.tax,
          type: type,
          resellerPermit: resellerPermit,
        };

        const date = getDateValues();

        const pickListItems = getPickListItems(masterItemList, inventoryList);

        const canvasData = state.canvases
          .map((canvas) => ({
            id: canvas.id,
            name: canvas.name,
          }))
          .toJS();

        const itemListsByRun = ItemList(
          state,
          inventoryList,
          { firstCanvas: state.canvases.first().id }
        ).getItemListByRun();

        const currentRevision = project.revisions.get(project.currentRevision);

        const pdfRequestData = {
          itemList: masterItemList,
          estimateNumber: estimateNumber,
          customer: customer,
          customerName: customer.name,
          pickListItems: pickListItems,
          estimators: estimator ? { [estimator.id]: estimator.toJS() } : null,
          date: date,
          shipTo: project.settings.shipToAddress,
          total: total.toString(),
          canvasData: canvasData,
          images: state.images.toJS(),
          revision: currentRevision ? currentRevision.description : "",
          nearSaltwater: project.settings.nearSaltwater,
          nearPoolwater: project.settings.nearPoolwater,
          settings: project.settings.toJS(),
          itemListsByRun: itemListsByRun.toJS(),
          tax: project.tax,
        };

        if (resellerPermit) {
          pdfRequestData.tax.set = false;
        }

        pdfRequestData.hash = hashCode(JSON.stringify(pdfRequestData));

        if (!customer) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-field", field: "customer" });
          dispatch({
            type: "window/error-message-open",
            message:
              "Customer not set. Try refreshing the browser and reopening the project.",
          });
          return;
        }

        if (project.settings.projectType === "none") {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-field", field: "projectType" });
          dispatch({
            type: "window/error-message-open",
            message: "Project type not set.",
          });
          return;
        }

        if (!qbItemList.length) {
          dispatch({
            type: "window/error-message-open",
            message: "There are no items in the estimate.",
          });
          return;
        }

        if (!project.settings.nearSaltwater) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-saltwater" });
          return;
        }

        if (!project.settings.nearPoolwater) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-poolwater" });
          return;
        }

        if (!estimator) {
          dispatch({ type: "settings/open" });
          dispatch({ type: "fields/require-field", field: "estimator" });
          dispatch({
            type: "window/error-message-open",
            message: "Estimator is not set.",
          });
          return;
        }

        const exportDialog = new Promise((resolve, reject) => {
          dispatch({
            type: "window/export-sales-order-dialog-open",
            resolve: resolve,
            reject: reject,
          });
        });

        exportDialog
          .then(() => {
            // Canvas.
            const images = {};

            const processes = Object.values(state.canvases.toJS()).map(
              (canvas) => {
                return new Promise((resolve) => {
                  dispatch({
                    type: "render/canvas",
                    canvas: canvas,
                    resolve: resolve,
                  });
                });
              }
            );

            Promise.all(processes).then((imagesData) => {
              imagesData.forEach((imageData) => {
                images[imageData.canvas.id] = imageData.image;
                pdfRequestData.images = images;
              });
              pdfSalesOrderExportRequest(pdfRequestData, dispatch);
            });

            // const url = "http://66.228.42.104:8080/";
            const url = "https://scrqb.eridesignsites.com/api/";

            setProcessing(true);

            // Legacy Queue
            fetch(url, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify(qbRequestData), // body data type must match "Content-Type" header
            })
              .then((response) => {
                if (response && response.ok) {
                  return response.text();
                } else {
                  return {
                    error: true,
                    response: response.text(),
                  };
                }
              })
              .then((data) => {
                // setProcessing(false);
                // if (data.error) {
                //   data.response.then((json) => {
                //     dispatch({
                //       type: "window/error-message-open",
                //       message:
                //         "An error with Quickbooks was encountered. Connection to Quickbooks potentially down.",
                //     });
                //   });
                //   console.error("Failed Export");
                //   console.error(data);
                // } else {
                //   if (type === "addSalesOrderAndCustomer") {
                //     const json = JSON.parse(data);
                //     const customerJSON = json.customer;
                //     const salesOrderJSON = json.salesOrder;
                //     if (
                //       salesOrderJSON &&
                //       salesOrderJSON["QBXML"] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                //         "SalesOrderAddRs"
                //       ] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderAddRs"][
                //         "SalesOrderRet"
                //       ]
                //     ) {
                //       const id =
                //         salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                //           "SalesOrderAddRs"
                //         ]["SalesOrderRet"]["TxnID"];
                //       dispatch({
                //         type: "convert-to-sales-order",
                //         transaction: id,
                //       });
                //     }
                //     if (
                //       salesOrderJSON &&
                //       salesOrderJSON["QBXML"] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                //         "SalesOrderModRs"
                //       ] &&
                //       salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderModRs"][
                //         "SalesOrderRet"
                //       ]
                //     ) {
                //       const id =
                //         salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                //           "SalesOrderModRs"
                //         ]["SalesOrderRet"]["TxnID"];
                //       dispatch({
                //         type: "convert-to-sales-order",
                //         transaction: id,
                //       });
                //     }
                //     if (
                //       customerJSON &&
                //       customerJSON["QBXML"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"][
                //         "CustomerRet"
                //       ]
                //     ) {
                //       const qbCustomer =
                //         customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"][
                //           "CustomerRet"
                //         ];
                //       customer.exportedToQB = true;
                //       customer.quickbooksId = qbCustomer.ListID;
                //       customer.quickbooksData = qbCustomer;
                //       customer = Customer(customer);
                //       setCustomers(customers.set(customer.id, customer));
                //       dispatch({ type: "customers/edit", customer: customer });
                //     }
                //     if (
                //       customerJSON &&
                //       customerJSON["QBXML"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"] &&
                //       customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"][
                //         "CustomerRet"
                //       ]
                //     ) {
                //       const qbCustomer =
                //         customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"][
                //           "CustomerRet"
                //         ];
                //       customer.exportedToQB = true;
                //       customer.quickbooksId = qbCustomer.ListID;
                //       customer.quickbooksData = qbCustomer;
                //       customer = Customer(customer);
                //       setCustomers(customers.set(customer.id, customer));
                //       dispatch({ type: "customers/edit", customer: customer });
                //     }
                //   } else if (type === "addSalesOrder") {
                //     const json = JSON.parse(data);
                //     let id;
                //     if (
                //       json &&
                //       json["QBXML"] &&
                //       json["QBXML"]["QBXMLMsgsRs"] &&
                //       json["QBXML"]["QBXMLMsgsRs"]["SalesOrderAddRs"] &&
                //       json["QBXML"]["QBXMLMsgsRs"]["SalesOrderAddRs"][
                //         "SalesOrderRet"
                //       ]
                //     ) {
                //       id =
                //         json["QBXML"]["QBXMLMsgsRs"]["SalesOrderAddRs"][
                //           "SalesOrderRet"
                //         ]["TxnID"];
                //       dispatch({
                //         type: "convert-to-sales-order",
                //         transaction: id,
                //       });
                //     }
                //     if (
                //       json &&
                //       json["QBXML"] &&
                //       json["QBXML"]["QBXMLMsgsRs"] &&
                //       json["QBXML"]["QBXMLMsgsRs"]["SalesOrderModRs"] &&
                //       json["QBXML"]["QBXMLMsgsRs"]["SalesOrderModRs"][
                //         "SalesOrderRet"
                //       ]
                //     ) {
                //       const id =
                //         json["QBXML"]["QBXMLMsgsRs"]["SalesOrderModRs"][
                //           "SalesOrderRet"
                //         ]["TxnID"];
                //       dispatch({
                //         type: "convert-to-sales-order",
                //         transaction: id,
                //       });
                //     }
                //   }
                // }
              })
              .catch((error) => {
                console.error(error);
                // dispatch({
                //   type: "window/error-message-open",
                //   message: "A network error occurred while trying to export.",
                // });
                // setProcessing(false);
              });

            const queueEndpoint = process.env.REACT_APP_QUEUE_URL;

            if (queueEndpoint) {
              fetch(`${queueEndpoint}/queue`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(qbRequestData),
              })
                .then((response) => {
                  if (response && response.ok) {
                    return response.text();
                  } else {
                    return {
                      error: true,
                      response: response.text(),
                    };
                  }
                })
                .then((data) => {
                  setProcessing(false);
                  if (data.error) {
                    data.response.then((json) => {
                      dispatch({
                        type: "window/error-message-open",
                        message:
                          "An error with Quickbooks was encountered. Connection to Quickbooks potentially down.",
                      });
                    });
                    console.error("Failed Export");
                    console.error(data);
                  } else {
                    const json = JSON.parse(data);

                    const customerJSON = json.response.customer;
                    const salesOrderJSON = json.response.salesOrder;

                    if (
                      salesOrderJSON &&
                      salesOrderJSON["QBXML"] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                        "SalesOrderAddRs"
                      ] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderAddRs"][
                        "SalesOrderRet"
                      ]
                    ) {
                      const id =
                        salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                          "SalesOrderAddRs"
                        ]["SalesOrderRet"]["TxnID"];

                      dispatch({
                        type: "convert-to-sales-order",
                        transaction: id,
                      });
                    }

                    if (
                      salesOrderJSON &&
                      salesOrderJSON["QBXML"] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                        "SalesOrderModRs"
                      ] &&
                      salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderModRs"][
                        "SalesOrderRet"
                      ]
                    ) {
                      const id =
                        salesOrderJSON["QBXML"]["QBXMLMsgsRs"][
                          "SalesOrderModRs"
                        ]["SalesOrderRet"]["TxnID"];

                      dispatch({
                        type: "convert-to-sales-order",
                        transaction: id,
                      });
                    }

                    if (
                      customerJSON &&
                      customerJSON["QBXML"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"][
                        "CustomerRet"
                      ]
                    ) {
                      const qbCustomer =
                        customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerAddRs"][
                          "CustomerRet"
                        ];

                      customer!.exportedToQB = true;
                      customer!.quickbooksId = qbCustomer.ListID;
                      customer!.quickbooksData = qbCustomer;
                      const updatedCustomer = Customer(customer);

                      updateCustomer(updatedCustomer, "customers/edit");
                    }

                    if (
                      customerJSON &&
                      customerJSON["QBXML"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"] &&
                      customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"][
                        "CustomerRet"
                      ]
                    ) {
                      const qbCustomer =
                        customerJSON["QBXML"]["QBXMLMsgsRs"]["CustomerQueryRs"][
                          "CustomerRet"
                        ];
                      customer!.exportedToQB = true;
                      customer!.quickbooksId = qbCustomer.ListID;
                      customer!.quickbooksData = qbCustomer;
                      const updatedCustomer = Customer(customer);
                      updateCustomer(updatedCustomer, "customers/edit");
                    }
                  }
                });
            }
          })
          .catch(() => {});
      }}
    >
      {buttonText}
    </button>
  );
};

function pdfSalesOrderExportRequest(requestData, dispatch) {
  dispatch({
    type: "window/generate-pdf-open",
    message: "Sales Order PDF Generating...",
  });

  const url = serviceUrl("salesOrderGeneratePdf");

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData), // body data type must match "Content-Type" header
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      dispatch({
        type: "window/generate-pdf-ready",
        message: "PDF Ready",
        download: data.download,
        pdfType: "SO",
      });
    })
    .catch((error) => {
      dispatch({ type: "window/generate-pdf-close" });
      dispatch({
        type: "window/error-message-open",
        message: "An error occurred while processing the PDF. Try again.",
      });
      console.error(error);
    });
}

export default ExportSalesOrderButton;
