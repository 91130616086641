// @ts-nocheck
import { useContext } from "react";
import { InventoryContext } from "../App";
import { projectEstimateNumber } from "../utils";
import {
  freezeProject,
  isProjectFrozen,
  freezeEstimate,
} from "../utils/freezeProject";
import { serviceUrl } from "../environment";
import { Map } from "immutable";
import { projectJsonToImmutable } from "../data/fetch-projects";
import { useFullListLoader } from "../hooks/useFullListLoader";

async function freezeTheEstimate(project, inventoryList) {
  const frozenEstimate = freezeEstimate(project, inventoryList);

  const frozenEstimateToSave = Map(frozenEstimate).toJS();

  const freezeUrl = serviceUrl("freezeEstimate");

  try {
    const response = await fetch(freezeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(frozenEstimateToSave),
    });

    if (!response.ok) {
      throw new Error("Failed to freeze project");
    } else {
      return frozenEstimateToSave;
    }
  } catch (err) {
    console.error("Could not freeze project", err);
    return null;
  }
}

async function freezeTheProject(project, inventoryList) {
  const frozenProject = freezeProject(project, inventoryList);

  const frozenProjectToSave = Map(frozenProject).toJS();

  const freezeUrl = serviceUrl("freezeProject");

  try {
    const response = await fetch(freezeUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(frozenProjectToSave),
    });

    if (!response.ok) {
      throw new Error("Failed to freeze project");
    } else {
      return await response.json();
    }
  } catch (err) {
    console.error("Could not freeze project", err);
    return null;
  }
}

const Sync = () => {
  return null;
  const inventoryList = useContext(InventoryContext);

  const { items: projects } = useFullListLoader({
    countEndpoint: "getProjectsPageCount",
    dataEndpoint: "getProjects",
    itemParser: (json) => projectJsonToImmutable(json),
  });

  return (
    <>
      <button
        onClick={async () => {
          const notFrozenButShouldBe = projects.filter(
            (project) =>
              !isProjectFrozen(project) &&
              (project.exportToQuickbooks || project.isSalesOrder)
          );

          notFrozenButShouldBe.forEach(async (project) => {
            if (project.isSalesOrder && !project.exportToQuickbooks) {
              project = project.set("exportToQuickbooks", true);
            }

            project = project.set("sequenceId", project.get("sequenceId") + 1);

            const data = await freezeTheProject(project, inventoryList);

            if (data) {
              project = project
                .setIn(["frozenProject", "id"], data.id)
                .setIn(["settings", "status"], "complete");

              const url = serviceUrl("saveProject");

              const res = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(project.toJS()),
              });
            }
          });
        }}
      >
        Freeze Projects
      </button>
      <button
        onClick={async () => {
          const notSyncedButShouldBe = projects.filter(
            (project) =>
              project.exportToQuickbooks &&
              isProjectFrozen(project) &&
              !project.isSalesOrder
          );

          for (let i = 0; i < notSyncedButShouldBe.length; i++) {
            let project = notSyncedButShouldBe[i];

            const url = "https://scrqb.eridesignsites.com/api/";

            await fetch(url, {
              method: "POST", // *GET, POST, PUT, DELETE, etc.
              headers: {
                "Content-Type": "application/json",
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: JSON.stringify({
                type: "findSalesOrder",
                estimateNumber: projectEstimateNumber(project).replace("#", ""),
              }), // body data type must match "Content-Type" header
            })
              .then((response) => {
                if (response && response.ok) {
                  return response.text();
                } else {
                  return {
                    error: true,
                    response: response.text(),
                  };
                }
              })
              .then(async (data) => {
                if (data.error) {
                  console.error(data.response);
                  return;
                }

                const salesOrderJSON = JSON.parse(data);

                if (
                  salesOrderJSON &&
                  salesOrderJSON["QBXML"] &&
                  salesOrderJSON["QBXML"]["QBXMLMsgsRs"] &&
                  salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderQueryRs"] &&
                  salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderQueryRs"][
                    "SalesOrderRet"
                  ]
                ) {
                  const id =
                    salesOrderJSON["QBXML"]["QBXMLMsgsRs"]["SalesOrderQueryRs"][
                      "SalesOrderRet"
                    ]["TxnID"];

                  project = project
                    .set("sequenceId", project.get("sequenceId") + 1)
                    .set("isSalesOrder", id);

                  const url = serviceUrl("saveProject");

                  const res = await fetch(url, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(project.toJS()),
                  });
                }
              });
          }
        }}
      >
        Sync With QB
      </button>
      <button
        onClick={async function () {
          const notFrozenButShouldBe = projects.filter(
            (project) =>
              !isProjectFrozen(project) ||
              !(project.exportToQuickbooks || project.isSalesOrder)
          );

          for (let project of notFrozenButShouldBe) {
            console.log(project.id);
            project = project.set("sequenceId", project.get("sequenceId") + 1);

            const data = await freezeTheEstimate(project, inventoryList);

            if (data) {
              project = project
                .setIn(["frozenEstimate", "id"], data.id)
                .setIn(
                  ["frozenEstimate", "itemLists", "itemListsByCanvas"],
                  data?.itemLists?.itemListsByCanvas
                );

              const url = serviceUrl("saveProject");

              const res = await fetch(url, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(project.toJS()),
              });
            }
          }
        }}
      >
        Freeze Estimates
      </button>
    </>
  );
};

export default Sync;
