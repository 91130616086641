// @ts-nocheck
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

import Icon from "./Icon";
import { Estimator } from "../entities";
import { EstimatorContext } from "../App";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { formatCustomerPhone } from "../entities/utils";
import { CustomerContext } from "./SingleProjectView";

const AddEstimatorWindow = (props) => {
  const { setAddNewWindow, setOpen, setEstimators } = props;
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  return (
    <div className="estimators__add-estimator">
      <div className="edit-popup__header add-estimator__header">
        <h2 className="edit-popup__heading">New Estimator Details</h2>
        <button
          className="app__tooltip-close"
          onClick={() => {
            setAddNewWindow(false);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
      <div className="add-estimator__fields">
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-name"
            className="add-estimator__field-label"
          >
            Full Name
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-name"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </div>
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-abbr"
            className="add-estimator__field-label"
          >
            Abbreviation
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-abbr"
            onChange={(event) => setAbbreviation(event.target.value)}
            value={abbreviation}
          />
        </div>
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-email"
            className="add-estimator__field-label"
          >
            Email Address
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
        </div>
      </div>
      <div className="add-estimator__footer">
        <button
          onClick={() => {
            const estimator = Estimator({
              id: uuid(),
              name: name,
              abbreviation: abbreviation,
              email: email,
            });

            dispatch({ type: "estimators/add", estimator: estimator });
            setEstimators((state) => {
              return state.set(estimator.id, estimator);
            });
            setAddNewWindow(false);
            setOpen(false);
          }}
          className="add-estimator__footer-button button button--primary"
        >
          Add New Estimator
        </button>
      </div>
    </div>
  );
};

const EditEstimatorWindow = (props) => {
  const { setOpen, setEditWindow, estimator, setEstimators } = props;
  const dispatch = useDispatch();

  const [name, setName] = useState(estimator.name);
  const [abbreviation, setAbbreviation] = useState(estimator.abbreviation);
  const [email, setEmail] = useState(estimator.email);

  return (
    <div className="estimators__add-estimator">
      <div className="edit-popup__header add-estimator__header">
        <h2 className="edit-popup__heading">Edit Estimator Details</h2>
        <button
          className="app__tooltip-close"
          onClick={() => {
            setEditWindow(false);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
      <div className="add-estimator__fields">
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-name"
            className="add-estimator__field-label"
          >
            Full Name
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-name"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
        </div>
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-abbr"
            className="add-estimator__field-label"
          >
            Abbreviation
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-abbr"
            onChange={(event) => setAbbreviation(event.target.value)}
            value={abbreviation}
          />
        </div>
        <div className="add-estimator__field">
          <label
            htmlFor="add-estimator-email"
            className="add-estimator__field-label"
          >
            Email Address
          </label>
          <input
            className="add-estimator__field-input"
            type="text"
            id="add-estimator-email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
        </div>
      </div>
      <div className="add-estimator__footer">
        <button
          onClick={() => {
            const newEstimator = estimator
              .set("name", name)
              .set("email", email)
              .set("abbreviation", abbreviation);

            dispatch({ type: "estimators/edit", estimator: newEstimator });
            setEstimators((state) => {
              return state.set(newEstimator.id, newEstimator);
            });
            setEditWindow(false);
            setOpen(false);
          }}
          className="add-estimator__footer-button button button--primary"
        >
          Save Estimator Details
        </button>
      </div>
    </div>
  );
};

const EstimatorSelectItem = (props) => {
  const {
    estimator,
    checked,
    setListPopupOpen,
    setEditWindow,
    setEstimators,
    type = "estimators",
  } = props;
  const dispatch = useDispatch();

  const [popupOpen, setPopupOpen] = useState(false);

  let estimatorClass = "estimators__estimator";

  if (checked) {
    estimatorClass += " estimators__estimator--checked";
  }

  if (estimator.status === "archived") {
    estimatorClass += " estimators__estimator--archived";
  }

  let nameField = estimator.name;

  if (estimator.abbreviation) {
    nameField += " (" + estimator.abbreviation + ")";
  }

  return (
    <li className={estimatorClass}>
      <span
        onClick={() => {
          dispatch({
            type: "estimators/select",
            checked: checked,
            estimator: estimator,
            changeType: type,
          });
        }}
        className="estimator__select"
      >
        <input
          id={estimator.id}
          className="estimator__checkbox"
          type="checkbox"
          checked={checked}
          readOnly
        />
        <label className="estimator__name">{nameField}</label>
      </span>
      {!estimator.quickbooksId && (
        <button
          onClick={() => {
            setPopupOpen((open) => !open);
            setListPopupOpen((open) => !open);
          }}
          className="estimator__more"
        >
          <Icon className="estimator__more-icon" icon="more" />
        </button>
      )}
      {popupOpen && (
        <div className="estimator__popup-window">
          <button
            onClick={() => {
              setPopupOpen(false);
              setListPopupOpen((open) => !open);
              setEditWindow(estimator.id);
            }}
            className="estimator__popup-window-action"
          >
            Edit details
          </button>
          {estimator.status !== "archived" && (
            <button
              onClick={() => {
                setPopupOpen(false);
                setListPopupOpen((open) => !open);
                dispatch({
                  type: "estimators/edit",
                  estimator: estimator.set("status", "archived"),
                });
                setEstimators((state) => {
                  return state.set(
                    estimator.id,
                    estimator.set("status", "archived")
                  );
                });
              }}
              className="estimator__popup-window-action"
            >
              Archive estimator
            </button>
          )}
          {estimator.status !== "active" && (
            <button
              onClick={() => {
                setPopupOpen(false);
                setListPopupOpen((open) => !open);
                dispatch({
                  type: "estimators/edit",
                  estimator: estimator.set("status", "active"),
                });
                setEstimators((state) => {
                  return state.set(
                    estimator.id,
                    estimator.set("status", "active")
                  );
                });
              }}
              className="estimator__popup-window-action"
            >
              Activate estimator
            </button>
          )}
          {false && (
            <button
              onClick={() => {
                setPopupOpen(false);
                setListPopupOpen((open) => !open);
                dispatch({
                  type: "estimators/delete",
                  estimator: estimator,
                });
              }}
              className="estimator__popup-window-action"
            >
              Delete estimator
            </button>
          )}
        </div>
      )}
    </li>
  );
};

const filterEstimators = (estimators, search) => {
  search = search.toLowerCase();

  if (!search.length) {
    return estimators;
  }

  return estimators.filter((estimator) => {
    if (estimator.name.toLowerCase().includes(search)) {
      return true;
    }

    if (estimator.abbreviation.toLowerCase().includes(search)) {
      return true;
    }

    return false;
  });
};

const EstimatorsDropdown = (props) => {
  const {
    setAddNewWindow,
    listPopupOpen,
    setListPopupOpen,
    setEditWindow,
    estimators,
    setEstimators,
    project,
    setOpen,
    type = "estimators",
  } = props;

  const ref = useRef();
  const clickOutside = useCallback(() => setOpen(false), [setOpen]);

  useOnClickOutside(ref, clickOutside);

  const [search, setSearch] = useState("");

  let listClass = "estimators__list";

  if (listPopupOpen) {
    listClass += " esimators__list--popup-open";
  }

  const filteredEstimators = filterEstimators(estimators, search);

  return (
    <div ref={ref} className="estimators__dropdown">
      <div className="estimators__search">
        <Icon className="estimators__search-icon" icon="search" />
        <input
          className="estimators__search-input"
          type="text"
          placeholder="Search for an estimator..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
      </div>
      {false && (
        <div className="estimators__new">
          <button
            onClick={() => {
              setAddNewWindow(true);
            }}
            className="estimators__add-new"
          >
            <Icon className="estimators__add-icon" icon="plus" />
            <span className="estimators__add-text">Add New Estimator</span>
          </button>
        </div>
      )}
      <ul className={listClass}>
        {filteredEstimators.entrySeq().map(([id, estimator]) => {
          const checked = project[type].has(id);

          return (
            <EstimatorSelectItem
              key={id}
              estimator={estimator}
              checked={checked}
              setListPopupOpen={setListPopupOpen}
              setEditWindow={setEditWindow}
              setEstimators={setEstimators}
              type={type}
            />
          );
        })}
      </ul>
    </div>
  );
};

const ProjectEstimators = (props) => {
  const { projectEstimators, estimators, type = "estimators" } = props;
  const dispatch = useDispatch();

  // Clean up any invalid estimator data being set.
  useEffect(() => {
    if (estimators.size) {
      projectEstimators.forEach((estimatorId) => {
        if (!estimators.has(estimatorId)) {
          dispatch({
            type: "estimators/delete",
            estimator: { id: estimatorId },
          });
        }
      });
    }
  }, [projectEstimators, estimators, dispatch]);
  let selectClass = "estimators__select";

  if (projectEstimators.size > 0) {
    selectClass += " estimators__select--items";
  }

  return (
    <div id="project-estimator" className={selectClass}>
      {projectEstimators.size === 0 && "Please select estimators"}
      {projectEstimators.size > 0 &&
        projectEstimators.entrySeq().map(([id]) => {
          if (estimators.has(id)) {
            const estimator = estimators.get(id);
            return (
              <span key={id} className="estimator__selected-item">
                {estimator.name}
                <button
                  onClick={() => {
                    dispatch({
                      type: "estimators/select",
                      checked: true,
                      estimator: estimator,
                      changeType: type,
                    });
                  }}
                  className="estimator__selected-item-remove"
                >
                  <Icon
                    icon="close"
                    className="estimator__selected-item-icon"
                  />
                </button>
              </span>
            );
          } else {
            return null;
          }
        })}
    </div>
  );
};

const EstimatorSelectBox = () => {
  const project = useSelector((state) => state.state.present);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [estimatorOpen, setEstimatorOpen] = useState(false);
  const [addNewWindow, setAddNewWindow] = useState(false);
  const [editWindow, setEditWindow] = useState(false);
  const [listPopupOpen, setListPopupOpen] = useState(false);

  const { customer } = useContext(CustomerContext);
  const { estimators, setEstimators } = useContext(EstimatorContext);

  let phone = false;
  let altPhone = false;
  let email = false;
  let companyName = false;
  let customerType = false;

  const estimatorsVerified = project.estimatorsVerified;
  const mainEstimators = project.mainEstimators;
  let theMainEstimators = [];

  if (mainEstimators && mainEstimators.size && mainEstimators.size > 0) {
    mainEstimators.forEach((estimatorId) => {
      if (estimators.has(estimatorId)) {
        theMainEstimators.push(estimators.get(estimatorId));
      }
    });
  }

  if (customer) {
    if (customer.data && Object.keys(customer?.data).length) {
      altPhone = customer.data.altPhone;
      phone = customer.data.phone;
      email = customer.data.email;
      companyName = customer.data.companyName;
      customerType = customer.data.customerType;
    } else if (customer.quickbooksId.length) {
      phone = customer.quickbooksData.Phone;
      altPhone = customer.quickbooksData.AltPhone;
      email = customer.quickbooksData.Email;
      companyName = customer.quickbooksData.CompanyName;
      customerType = customer.quickbooksData.CustomerTypeRef
        ? customer.quickbooksData.CustomerTypeRef.FullName
        : "Not set";
    }
  }

  const showVerifyButton =
    !estimatorsVerified && project?.mainEstimators?.size > 0;

  const showVerifiedEstimators =
    estimatorsVerified && theMainEstimators?.length;

  return (
    <div className="estimators__box">
      <div
        onClick={() => {
          setOpen((open) => !open);
        }}
        className="select-box"
      >
        <ProjectEstimators
          projectEstimators={project.estimators}
          estimators={estimators}
        />
        <Icon icon="chev-down" className="select-box__icon" />
      </div>
      {open && !addNewWindow && !editWindow && (
        <EstimatorsDropdown
          setAddNewWindow={setAddNewWindow}
          listPopupOpen={listPopupOpen}
          setListPopupOpen={setListPopupOpen}
          setEditWindow={setEditWindow}
          setEstimators={setEstimators}
          estimators={estimators}
          project={project}
          setOpen={setOpen}
          type={"estimators"}
        />
      )}
      {addNewWindow && (
        <AddEstimatorWindow
          setAddNewWindow={setAddNewWindow}
          setOpen={setOpen}
          setEstimators={setEstimators}
        />
      )}
      {editWindow && (
        <EditEstimatorWindow
          setOpen={setOpen}
          setEditWindow={setEditWindow}
          estimator={estimators.get(editWindow)}
          setEstimators={setEstimators}
        />
      )}
      <div className="main-estimator">
        {!estimatorsVerified && (
          <>
            <label htmlFor="estimator" className="people__label">
              Choose Estimator(s)
            </label>
            <div
              onClick={() => {
                setEstimatorOpen((open) => !open);
              }}
              className="select-box"
            >
              <ProjectEstimators
                projectEstimators={project.mainEstimators}
                estimators={estimators}
                type="mainEstimators"
              />
              <Icon icon="chev-down" className="select-box__icon" />
            </div>
          </>
        )}
        {estimatorOpen && !addNewWindow && !editWindow && (
          <EstimatorsDropdown
            setAddNewWindow={setAddNewWindow}
            listPopupOpen={listPopupOpen}
            setListPopupOpen={setListPopupOpen}
            setEditWindow={setEditWindow}
            setEstimators={setEstimators}
            estimators={estimators}
            project={project}
            setOpen={setEstimatorOpen}
            type={"mainEstimators"}
          />
        )}
        {showVerifyButton && (
          <button
            onClick={() => {
              dispatch({ type: "estimators/verify" });
            }}
            class="customer-edit__button"
            type="button"
          >
            Verify Estimator
          </button>
        )}
        {showVerifiedEstimators && (
          <>
            <label htmlFor="estimator" className="people__label">
              Estimator(s)
            </label>
            {theMainEstimators.map((estimator) => {
              return (
                <div key={estimator.id} className="estimator__verified">
                  {estimator.name}
                </div>
              );
            })}
          </>
        )}
      </div>
      {customer && (
        <div className="customer-address__container">
          {(phone || altPhone) && (
            <div className="customer-address__item">
              {phone && (
                <>
                  <div className="customer-address__heading">Phone</div>
                  <div className="customer-address__line">
                    {formatCustomerPhone(phone)}
                  </div>
                </>
              )}
              {altPhone && (
                <>
                  <div className="customer-address__heading">
                    Alternative Phone
                  </div>
                  <div className="customer-address__line">
                    {formatCustomerPhone(altPhone)}
                  </div>
                </>
              )}
            </div>
          )}
          {email && (
            <div className="customer-address__item">
              <div className="customer-address__heading">Email</div>
              <div className="customer-address__line">{email}</div>
            </div>
          )}
          {companyName && (
            <div className="customer-address__item">
              <div className="customer-address__heading">Company Name</div>
              <div className="customer-address__line">{companyName}</div>
            </div>
          )}
          {customerType && (
            <div className="customer-address__item">
              {customerType && (
                <>
                  <div className="customer-address__heading">Customer Type</div>
                  <div className="customer-address__line">{customerType}</div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const EstimatorSelect = () => {
  const requiredFields = useSelector((state) => state.requiredFields);

  let estimatorClass = "project-estimator";

  if (requiredFields.has("estimator")) {
    estimatorClass += " project-estimator--required";
  }

  return (
    <div className={estimatorClass}>
      <label htmlFor="project-estimator" className="people__label">
        Choose Sales Rep(s)
      </label>
      <EstimatorSelectBox />
    </div>
  );
};

export default EstimatorSelect;
