import { Map } from "immutable";
import { addItem } from "..";
import { AllInventoryTypes } from "../../../data/fetch-inventory";
import { ProjectSettings, Run } from "../../../entities";
import { getPostInches, getRunSettings } from "../../../utils";
import { getPenofinWoodStainUpcs } from "../getParts";
import { ItemListType } from "../itemListTypes";
import { TopRailLengths, WoodType } from "./calculateTopRailLengths";

type WoodStainType = "red" | "natural";
type WoodStainSize = "quart" | "gallon";

export function addWoodStain(
  itemList: ItemListType,
  inventory: AllInventoryTypes[],
  settings: ProjectSettings,
  lengths: TopRailLengths,
  runs: Map<string, Run>,
  totalPosts: Record<string, number>
) {
  const state = "CA";

  const limits = penofinVocStateLimits();
  const limitVoc = limits[state];

  const woodTopRailSurfaceArea = calculateWoodTopRailSurfaceArea(
    lengths,
    settings
  );

  const totalPostsSurfaceArea = calculateTotalPostsSurfaceArea(
    runs,
    totalPosts,
    settings
  );

  const totalRedWoodStainSurfaceArea =
    woodTopRailSurfaceArea.red + totalPostsSurfaceArea.red;
  const totalNaturalWoodStainSurfaceArea =
    woodTopRailSurfaceArea.natural + totalPostsSurfaceArea.natural;

  if (totalRedWoodStainSurfaceArea) {
    itemList = addItems(
      totalRedWoodStainSurfaceArea,
      "red",
      limitVoc,
      itemList,
      inventory
    );
  }
  if (totalNaturalWoodStainSurfaceArea) {
    itemList = addItems(
      totalNaturalWoodStainSurfaceArea,
      "natural",
      limitVoc,
      itemList,
      inventory
    );
  }

  return itemList;
}

function addItems(
  woodSurfaceArea: number,
  wooStainType: WoodStainType,
  limitVoc: number,
  itemList: ItemListType,
  inventory: AllInventoryTypes[]
) {
  const quartcanCoverage = 200;
  const galloncanCoverage = 800;

  const surfaceAreaInSquareFeet = woodSurfaceArea / 144;
  let gallons = Math.floor(surfaceAreaInSquareFeet / galloncanCoverage);
  let quarts = Math.floor(
    (surfaceAreaInSquareFeet - gallons * galloncanCoverage) / quartcanCoverage
  );

  if (surfaceAreaInSquareFeet - gallons * galloncanCoverage !== 0) {
    quarts += 1; // Add one for base case as we start with 1 quart if we do not already exactly match with gallons
  }

  // When quarts hits 4 add a gallon.  Reset quarts to 0
  if (quarts === 4) {
    quarts = 0;
    gallons += 1;
  }

  if (gallons) {
    const upc = getUpc(wooStainType, limitVoc, "gallon");
    if (upc) {
      itemList = addItem(itemList, inventory, upc.toString(), gallons);
    }
  }
  if (quarts) {
    const upc = getUpc(wooStainType, limitVoc, "quart");
    if (upc) {
      itemList = addItem(itemList, inventory, upc.toString(), quarts);
    }
  }

  return itemList;
}

function calculateTotalPostsSurfaceArea(
  runs: Map<string, Run>,
  totalPosts: Record<string, number>,
  settings: ProjectSettings
) {
  return runs
    .map((run, id) => {
      const runSettings = getRunSettings(run, settings);
      if (runSettings.postMaterial === "wood") {
        const postCount = totalPosts[id];
        const postHeight = getPostInches(runSettings);
        const postArea = postHeight * 4 * 4;
        const woodType = (runSettings.woodToprailType ||
          settings.woodToprailType) as WoodType;
        const stainType = getWoodStainType(woodType);
        const area = postArea * postCount;
        return {
          area,
          type: stainType,
        };
      }
    })
    .reduce(
      (sum, val) => {
        if (val) {
          sum[val.type] += val.area;
        }
        return sum;
      },
      { red: 0, natural: 0 }
    );
}

function calculateWoodTopRailSurfaceArea(
  lengths: TopRailLengths,
  settings: ProjectSettings
) {
  if (!lengths.wood) return { red: 0, natural: 0 };

  const areasPerWoodType = Object.entries(lengths.wood).flatMap(
    ([key, woodLengths]) => {
      if (!key.startsWith("wood") || typeof woodLengths !== "object") return [];

      const [_wood, size, type] = key.split("_"); // eslint-disable-line no-unused-vars

      if (!woodLengths?.length) return [];

      const woodLengthsSum = woodLengths.reduce((sum, val) => sum + val, 0);

      let height = 0;
      let width = 0;

      const widthAndHeight = size.split("x");

      if (widthAndHeight.length !== 2) return [];

      width = parseFloat(eval(widthAndHeight[0]));
      height = parseFloat(eval(widthAndHeight[1]));

      const totalArea =
        width * height * 2 +
        woodLengthsSum * 12 * width * 2 + // Wood Length Sum is in feet convert to inches.
        woodLengthsSum * 12 * height * 2;

      let stainType = getWoodStainType(
        (type || settings.woodToprailType) as WoodType
      );

      return [{ area: totalArea, type: stainType }];
    }
  );

  const totalAreaByStainType = (type: WoodStainType) => {
    return areasPerWoodType
      .filter((val) => val.type === type)
      .reduce((sum, val) => sum + val.area, 0);
  };

  const totalAreaRed = totalAreaByStainType("red");
  const totalAreaNatural = totalAreaByStainType("natural");

  return { red: totalAreaRed, natural: totalAreaNatural };
}

const getUpc = (type: WoodStainType, limitVoc: number, size: WoodStainSize) => {
  const upcs = getPenofinWoodStainUpcs();
  const color = upcs[type];

  let upc: number | undefined;
  if (limitVoc === 250) {
    upc = color[limitVoc][size];
  } else if (limitVoc === 550) {
    upc = color[limitVoc][size];
  }

  return upc;
};

function getWoodStainType(woodType: WoodType): WoodStainType {
  // Use red label for cedar.
  if (woodType === "clear-cedar" || woodType === "tight-knot-cedar") {
    return "red";
  }

  // Use clear natural for darker woods.
  if (woodType === "ipe" || woodType === "balau") {
    return "natural";
  }

  return "natural";
}

function penofinVocStateLimits() {
  return {
    WA: 550,
    OR: 550,
    CA: 250,
    AK: 550,
    NV: 550,
    ID: 550,
    UT: 250,
    AZ: 250,
    MT: 550,
    WY: 550,
    CO: 550,
    NM: 550,
    HI: 550,
    TX: 550,
    OK: 550,
    KS: 550,
    NE: 550,
    SD: 550,
    ND: 550,
    MN: 550,
    IA: 550,
    MO: 550,
    AR: 550,
    LA: 550,
    MS: 550,
    TN: 550,
    KY: 550,
    IL: 250,
    WI: 550,
    MI: 550,
    IN: 250,
    OH: 250,
    AL: 550,
    FL: 550,
    GA: 550,
    SC: 550,
    NC: 550,
    VA: 250,
    WV: 550,
    MD: 250,
    DE: 250,
    PA: 250,
    NJ: 250,
    NY: 250,
    CT: 250,
    RI: 250,
    MA: 250,
    NH: 250,
    VT: 550,
    ME: 250,
  };
}
