// @ts-nocheck
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";
import {
  distanceInFeetObject,
  isCloseToValue,
  roundToHundreth,
} from "../../../utils";
import {
  getConnectedElements,
  handleMoveTransformsOfGraph,
  nodesToObject,
  transformRunStairs,
  walkGraph,
} from "../../../utils/graph";
import { calculateCorners } from "../../../utils/corners";
import { Map } from "immutable";
import { getPosts } from "../../../utils/getPosts";
import { handleResizingOfRuns } from "../../../redux/reducers/stateReducer";

function EditStairsPopupHeader(props) {
  const { setPosition, animation, dispatch } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  let label = "Stairs/Ramp";

  if (props.stairs.type === "landing") {
    label = "Landing";
  }

  return (
    <div {...bind()} className="edit-popup__header">
      <h2 className="edit-popup__heading">Edit {label}</h2>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function EditStairsPopup(props) {
  const { setPosition, animation } = props;

  const dispatch = useDispatch();
  const stairs = props.edit.object;
  const [view] = useState("controls");
  const state = useSelector((state) => state.state.present);

  const views = {
    controls: EditStairsControls,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditStairsPopupHeader
        dispatch={dispatch}
        setPosition={setPosition}
        animation={animation}
        stairs={stairs}
      />
      <EditComponent stairs={stairs} dispatch={dispatch} state={state} />
    </div>
  );
}

function stairLength(stairs) {
  if (stairs.orientation === "vertical") {
    return distanceInFeetObject({
      x1: stairs.x1,
      x2: stairs.x1,
      y1: stairs.y1,
      y2: stairs.y2,
    });
  } else if (stairs.orientation === "horizontal") {
    return distanceInFeetObject({
      x1: stairs.x1,
      x2: stairs.x2,
      y1: stairs.y1,
      y2: stairs.y1,
    });
  }

  // Landings don't have an orientation.
  return distanceInFeetObject({
    x1: stairs.x1,
    x2: stairs.x1,
    y1: stairs.y1,
    y2: stairs.y2,
  });
}

function stairWidth(stairs) {
  if (stairs.orientation === "vertical") {
    return distanceInFeetObject({
      x1: stairs.x1,
      x2: stairs.x2,
      y1: stairs.y1,
      y2: stairs.y1,
    });
  } else if (stairs.orientation === "horizontal") {
    return distanceInFeetObject({
      x1: stairs.x1,
      x2: stairs.x1,
      y1: stairs.y1,
      y2: stairs.y2,
    });
  }

  // Landings don't have an orientation.
  return distanceInFeetObject({
    x1: stairs.x1,
    x2: stairs.x2,
    y1: stairs.y1,
    y2: stairs.y1,
  });
}

const rawDistanceOfHypotenuse = function (angle, distanceOfSide) {
  return distanceOfSide / Math.cos(angle);
};

const distanceOfHypotenuse = function (angle, distanceOfSide) {
  const distance = rawDistanceOfHypotenuse(angle, distanceOfSide);

  const distanceInFeet = distance / pixelsPerFoot();
  const remainder = (distanceInFeet % 1).toFixed(4);

  const inches = Math.floor(remainder * 12);
  const feet = Math.floor(distanceInFeet);

  return { feet: feet, inches: inches };
};

const distanceToPixels = function (distance) {
  const inches = distance.inches * pixelsPerInch();

  const feet = distance.feet * pixelsPerFoot();

  return feet + inches;
};

const angleBasedOnSideAndHypotenuse = function (hypotenuse, distanceOfSide) {
  const hypDistance = distanceToPixels(hypotenuse);
  const sideDistance = distanceToPixels(distanceOfSide);

  let angle = Math.acos(sideDistance / hypDistance);

  if (isNaN(angle)) {
    angle = 0;
  }

  return roundToHundreth(toDegrees(angle));
};

function lengthBasedOnAngleAndHypotenuse(
  newLength,
  angle,
  stateStairs,
  hypotenuse,
  metric
) {
  let newStairs = stateStairs;

  const hypotenuseDistance = distanceToPixels(hypotenuse);

  let newHypotenuse = hypotenuseDistance;

  if (metric === "inches") {
    newHypotenuse = newLength * pixelsPerInch() + hypotenuseDistance;
  } else if (metric === "feet") {
    newHypotenuse = newLength * pixelsPerFoot() + hypotenuseDistance;
  } else if (metric === "pixels") {
    newHypotenuse = newLength + hypotenuseDistance;
  }

  const originalSize = distanceToPixels(stairLength(stateStairs));
  const newSide = Math.round(Math.cos(toRadians(angle)) * newHypotenuse);

  const diff = newSide - originalSize;

  if (stateStairs.orientation === "vertical") {
    if (stateStairs.y2 > stateStairs.y1) {
      newStairs = newStairs.set("y2", stateStairs.y2 + diff / 2);
      newStairs = newStairs.set("y1", stateStairs.y1 - diff / 2);
    } else {
      newStairs = newStairs.set("y2", stateStairs.y2 - diff / 2);
      newStairs = newStairs.set("y1", stateStairs.y1 + diff / 2);
    }
  } else if (stateStairs.orientation === "horizontal") {
    if (stateStairs.x2 > stateStairs.x1) {
      newStairs = newStairs.set("x2", stateStairs.x2 + diff / 2);
      newStairs = newStairs.set("x1", stateStairs.x1 - diff / 2);
    } else {
      newStairs = newStairs.set("x2", stateStairs.x2 - diff / 2);
      newStairs = newStairs.set("x1", stateStairs.x1 + diff / 2);
    }
  }

  return {
    metrics: stairLength(newStairs),
    stairs: newStairs,
  };
}

const pixelsPerFoot = function () {
  return 24;
};

const pixelsPerInch = function () {
  return 2;
};

function toRadians(angle) {
  return angle * (Math.PI / 180);
}

function toDegrees(angle) {
  return angle * (180 / Math.PI);
}

function LandingDimensionControls(props) {
  const {
    stairs,
    stateStairs,
    setStateStairs,
    dispatch,
    width,
    setWidth,
    length,
    setLength,
    state,
  } = props;

  return (
    <div className="edit-popup__section edit-popup__labels">
      <div className="edit-popup__control edit-popup__control--align-top">
        <p className="edit-popup__label">Length</p>
        <div className="labels-section length-section">
          <span className="labeled-input input--w-label">
            <label htmlFor="stairlengthfeet">ft</label>
            <input
              type="number"
              id="stairlengthfeet"
              min="0"
              step="1"
              value={length.feet}
              onChange={(event) => {
                if (event.target.value === "") {
                  const newFeet = 0;
                  setLength(() => ({
                    ...length,
                    feet: "",
                  }));

                  const diff = newFeet - length.feet;

                  const pixels = diff * pixelsPerFoot();

                  if (stairs.y2 > stairs.y1) {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 + pixels / 2)
                        .set("y1", stateStairs.y1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 - pixels / 2)
                        .set("y1", stateStairs.y1 + pixels / 2)
                    );
                  }
                } else {
                  const newFeet = parseInt(event.target.value, 10);
                  setLength(() => ({
                    ...length,
                    feet: newFeet,
                  }));

                  const diff = newFeet - length.feet;

                  const pixels = diff * pixelsPerFoot();

                  if (stairs.y2 > stairs.y1) {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 + pixels / 2)
                        .set("y1", stateStairs.y1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 - pixels / 2)
                        .set("y1", stateStairs.y1 + pixels / 2)
                    );
                  }
                }
              }}
            />
          </span>
          <span className="labeled-input input--w-label">
            <label htmlFor="stairlengthinch">in</label>
            <input
              type="number"
              max="11"
              min="0"
              id="stairlengthinch"
              value={length.inches}
              onChange={(event) => {
                if (event.target.value === "") {
                  const newInches = 0;
                  setLength({
                    ...length,
                    inches: "",
                  });

                  const diff = newInches - length.inches;

                  const pixels = diff * pixelsPerInch();

                  if (stairs.y2 > stairs.y1) {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 + pixels / 2)
                        .set("y1", stateStairs.y1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 - pixels / 2)
                        .set("y1", stateStairs.y1 + pixels / 2)
                    );
                  }
                } else {
                  const newInches = parseInt(event.target.value, 10);
                  setLength({
                    ...length,
                    inches: newInches,
                  });

                  const diff = newInches - length.inches;

                  const pixels = diff * pixelsPerInch();

                  if (stairs.y2 > stairs.y1) {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 + pixels / 2)
                        .set("y1", stateStairs.y1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("y2", stateStairs.y2 - pixels / 2)
                        .set("y1", stateStairs.y1 + pixels / 2)
                    );
                  }
                }
              }}
            />
          </span>
        </div>
      </div>
      <div className="edit-popup__control edit-popup__control--align-top">
        <p className="edit-popup__label">Width</p>
        <div className="labels-section length-section">
          <span className="labeled-input input--w-label">
            <label htmlFor="stairwidthfeet">ft</label>
            <input
              type="number"
              id="stairwidthfeet"
              min="0"
              step="1"
              value={width.feet}
              onChange={(event) => {
                if (event.target.value === "") {
                  const newFeet = 0;

                  setWidth(() => ({
                    ...width,
                    feet: "",
                  }));

                  const diff = newFeet - width.feet;

                  const pixels = diff * pixelsPerFoot();

                  if (stairs.x2 > stairs.x1) {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 + pixels / 2)
                        .set("x1", stateStairs.x1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 - pixels / 2)
                        .set("x1", stateStairs.x1 + pixels / 2)
                    );
                  }
                } else {
                  const newFeet = parseInt(event.target.value, 10);
                  setWidth(() => ({
                    ...width,
                    feet: newFeet,
                  }));

                  const diff = newFeet - width.feet;

                  const pixels = diff * pixelsPerFoot();

                  if (stairs.x2 > stairs.x1) {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 + pixels / 2)
                        .set("x1", stateStairs.x1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 - pixels / 2)
                        .set("x1", stateStairs.x1 + pixels / 2)
                    );
                  }
                }

                //
              }}
            />
          </span>
          <span className="labeled-input input--w-label">
            <label htmlFor="stairwidthinch">in</label>
            <input
              type="number"
              max="11"
              min="0"
              id="stairwidthinch"
              value={width.inches}
              onChange={(event) => {
                if (event.target.value === "") {
                  const newInches = 0;

                  setWidth({
                    ...width,
                    inches: "",
                  });

                  const diff = newInches - width.inches;

                  const pixels = diff * pixelsPerInch();

                  if (stairs.x2 > stairs.x1) {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 + pixels / 2)
                        .set("x1", stateStairs.x1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 - pixels / 2)
                        .set("x1", stateStairs.x1 + pixels / 2)
                    );
                  }
                } else {
                  const newInches = parseInt(event.target.value, 10);

                  setWidth({
                    ...width,
                    inches: newInches,
                  });

                  const diff = newInches - width.inches;

                  const pixels = diff * pixelsPerInch();

                  if (stairs.x2 > stairs.x1) {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 + pixels / 2)
                        .set("x1", stateStairs.x1 - pixels / 2)
                    );
                  } else {
                    setStateStairs(
                      stateStairs
                        .set("x2", stateStairs.x2 - pixels / 2)
                        .set("x1", stateStairs.x1 + pixels / 2)
                    );
                  }
                }
              }}
            />
          </span>
        </div>
      </div>
      <div className="edit-popup__control edit-popup__control--align-top save-calculation">
        <button
          onClick={() => {
            if (
              stateStairs.x1 === stairs.x1 &&
              stateStairs.y1 === stairs.y1 &&
              stateStairs.x2 === stairs.x2 &&
              stateStairs.y2 === stairs.y2
            ) {
              dispatch({
                type: "stairs/edit",
                stairs: stateStairs,
              });
            } else {
              const dx1 = stateStairs.x1 - stairs.x1;
              const dy1 = stateStairs.y1 - stairs.y1;
              const dx2 = stateStairs.x2 - stairs.x2;
              const dy2 = stateStairs.y2 - stairs.y2;

              const transforms = { x1: dx1, y1: dy1, x2: dx2, y2: dy2 };

              const action = { transforms };

              const transformedEntities = handleStairsTransformsOfGraph(
                stairs,
                state,
                action
              );

              const {
                gates: newGates,
                runs: newRuns,
                stairs: newStairs,
                posts: newPosts,
                handrails: newHandrails,
              } = transformedEntities;

              dispatch({
                type: "canvas/edit-entities",
                runs: newRuns,
                gates: newGates,
                stairs: newStairs,
                posts: newPosts,
                handrails: newHandrails,
              });
            }
          }}
          className="labels-section__button"
        >
          Save Calculation
        </button>
      </div>
    </div>
  );
}

function EditStairsControls(props) {
  const { stairs, dispatch, state } = props;

  const [length, setLength] = useState(stairLength(stairs));
  const [width, setWidth] = useState(stairWidth(stairs));
  const [hypotenuse, setHypotenuse] = useState(
    distanceOfHypotenuse(toRadians(stairs.angle), calculateStairsLength(stairs))
  );
  const [rotationAngle, setRotationAngle] = useState(
    stairs.rotate.get("angle")
  );
  const [stairsAngle, setStairsAngle] = useState(stairs.angle);

  const [stateStairs, setStateStairs] = useState(stairs);

  const [locks, setLocks] = useState({
    length: true,
    angle: false,
    hypotenuse: false,
  });

  let verticalOrientationClass = "edit-popup__three-button";
  let horizontalOrientationClass = "edit-popup__three-button";

  if (stairs.orientation === "vertical") {
    verticalOrientationClass += " edit-popup__three-button--active";
  }

  if (stairs.orientation === "horizontal") {
    horizontalOrientationClass += " edit-popup__three-button--active";
  }

  let labelsShowClass = "edit-popup__three-button";
  let labelsHideClass = "edit-popup__three-button";

  if (stairs.getIn(["labels", "showLabels"])) {
    labelsShowClass += " edit-popup__three-button--active";
  } else {
    labelsHideClass += " edit-popup__three-button--active";
  }

  let isRampClass = "edit-popup__three-button";
  let isNotRampClass = "edit-popup__three-button";

  if (stairs.isRamp) {
    isRampClass += " edit-popup__three-button--active";
  } else {
    isNotRampClass += " edit-popup__three-button--active";
  }

  return (
    <div className="edit-popup__container run-controls">
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Rotation</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="rotation">°</label>
              <input
                type="number"
                min="0"
                max="360"
                id="rotation"
                value={rotationAngle}
                onChange={(event) => {
                  if (event.target.value !== "") {
                    let newAngle = parseInt(event.target.value, 10);

                    if (newAngle > 360) {
                      newAngle = 360;
                    }

                    if (newAngle < 0) {
                      newAngle = 0;
                    }

                    const newStairs = stateStairs.setIn(
                      ["rotate", "angle"],
                      newAngle
                    );

                    setRotationAngle(newAngle);

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                    setStateStairs(newStairs);
                  } else {
                    setRotationAngle("");
                    const newStairs = stateStairs.setIn(["rotate", "angle"], 0);
                    dispatch({ type: "stairs/edit", stairs: newStairs });
                    setStateStairs(newStairs);
                  }
                }}
              />
            </span>
          </div>
        </div>
      </div>
      {stairs.type === "landing" && (
        <LandingDimensionControls
          stairs={stairs}
          dispatch={dispatch}
          length={length}
          setLength={setLength}
          width={width}
          setWidth={setWidth}
          state={state}
          stateStairs={stateStairs}
          setStateStairs={setStateStairs}
        />
      )}
      {stairs.type === "stairs" && (
        <div className="edit-popup__section edit-popup__labels">
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label">Type</p>
            <div className="labels-section">
              <div className="edit-popup__three-buttons labels__toggle-buttons">
                <button
                  onClick={() => {
                    if (!stairs.isRamp) {
                      return;
                    }

                    const newStairs = stateStairs.set("isRamp", false);

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                    setStateStairs(newStairs);
                  }}
                  className={isNotRampClass}
                >
                  Stairs
                </button>
                <button
                  onClick={() => {
                    if (stairs.isRamp) {
                      return;
                    }

                    let newStairs = stateStairs.set("isRamp", true);

                    const newAngle = 5;
                    newStairs = newStairs.set("angle", newAngle);

                    // If we are locking the length.
                    if (locks.length) {
                      setStairsAngle(newAngle);
                      setHypotenuse(
                        distanceOfHypotenuse(
                          toRadians(newAngle),
                          calculateStairsLength(stateStairs)
                        )
                      );
                      setStateStairs(newStairs);
                    }

                    // If we are locking hypotenuse.
                    if (locks.hypotenuse) {
                      const newLength = calculateStairsLengthFromAngleChange(
                        newAngle,
                        stateStairs,
                        stateStairs.angle
                      );

                      setLength(newLength.metrics);
                      setStairsAngle(newAngle);
                      setStateStairs(newLength.stairs);
                    }

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                    setStateStairs(newStairs);
                  }}
                  className={isRampClass}
                >
                  Ramp
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {stairs.type === "stairs" && (
        <div className="edit-popup__section edit-popup__labels">
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label">Orientation</p>
            <div className="labels-section">
              <div className="edit-popup__three-buttons labels__toggle-buttons">
                <button
                  onClick={() => {
                    if (stateStairs.orientation === "vertical") {
                      return;
                    }

                    const newStairs = stateStairs.set(
                      "orientation",
                      "vertical"
                    );

                    setStateStairs(newStairs);
                    setLength(stairLength(newStairs));
                    setHypotenuse(
                      distanceOfHypotenuse(
                        toRadians(newStairs.angle),
                        calculateStairsLength(newStairs)
                      )
                    );
                    setStairsAngle(newStairs.angle);

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                    setStateStairs(newStairs);
                  }}
                  className={verticalOrientationClass}
                >
                  Vertical
                </button>
                <button
                  onClick={() => {
                    if (stateStairs.orientation === "horizontal") {
                      return;
                    }

                    const newStairs = stateStairs.set(
                      "orientation",
                      "horizontal"
                    );

                    setStateStairs(newStairs);
                    setLength(stairLength(newStairs));
                    setHypotenuse(
                      distanceOfHypotenuse(
                        toRadians(newStairs.angle),
                        calculateStairsLength(newStairs)
                      )
                    );
                    setStairsAngle(newStairs.angle);
                    setStateStairs(newStairs);

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                  }}
                  className={horizontalOrientationClass}
                >
                  Horizontal
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {stairs.type === "stairs" && (
        <div className="edit-popup__section edit-popup__labels">
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label edit-popup__label--stairs-lock">
              <Lock
                locks={locks}
                setLocks={setLocks}
                lockName="angle"
                dispatch={dispatch}
                stateStairs={stateStairs}
              />
              Angle
            </p>
            <div className="labels-section angle-section">
              <span className="labeled-input input--w-label">
                <label htmlFor="stairlengthfeet">°</label>
                <input
                  type="number"
                  id="endPostY"
                  min="0"
                  max="89"
                  disabled={locks.angle}
                  value={stairsAngle}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      let newAngle = parseInt(event.target.value, 10);
                      if (newAngle > 89) {
                        newAngle = 89;
                      }

                      if (newAngle < 0) {
                        newAngle = 0;
                      }
                      const newStairs = stateStairs.set("angle", newAngle);

                      // If we are locking the length.
                      if (locks.length) {
                        setStairsAngle(newAngle);
                        setHypotenuse(
                          distanceOfHypotenuse(
                            toRadians(newAngle),
                            calculateStairsLength(stateStairs)
                          )
                        );
                        setStateStairs(newStairs);

                        return;
                      }

                      // If we are locking hypotenuse.
                      if (locks.hypotenuse) {
                        const newLength = calculateStairsLengthFromAngleChange(
                          newAngle,
                          stateStairs,
                          stateStairs.angle
                        );

                        setLength(newLength.metrics);
                        setStairsAngle(newAngle);
                        setStateStairs(newLength.stairs);

                        return;
                      }
                    } else {
                      let newAngle = 0;
                      const newStairs = stateStairs.set("angle", newAngle);

                      // If we are locking the length.
                      if (locks.length) {
                        setStairsAngle(newAngle);
                        setHypotenuse(
                          distanceOfHypotenuse(
                            toRadians(newAngle),
                            calculateStairsLength(stateStairs)
                          )
                        );
                        setStateStairs(newStairs);

                        return;
                      }

                      // If we are locking hypotenuse.
                      if (locks.hypotenuse) {
                        const newLength = calculateStairsLengthFromAngleChange(
                          newAngle,
                          stateStairs,
                          stateStairs.angle
                        );

                        setLength(newLength.metrics);
                        setStairsAngle(newAngle);
                        setStateStairs(newLength.stairs);

                        return;
                      }
                    }
                  }}
                />
              </span>
              <input
                type="range"
                min="0"
                max="89"
                step="1"
                value={stairsAngle}
                disabled={locks.angle}
                onChange={(event) => {
                  const newAngle = parseInt(event.target.value, 10);
                  const newStairs = stateStairs.set("angle", newAngle);

                  // If we are locking the length.
                  if (locks.length) {
                    setStairsAngle(newAngle);
                    setHypotenuse(
                      distanceOfHypotenuse(
                        toRadians(newAngle),
                        calculateStairsLength(stateStairs)
                      )
                    );
                    setStateStairs(newStairs);

                    return;
                  }

                  // If we are locking hypotenuse.
                  if (locks.hypotenuse) {
                    const newLength = calculateStairsLengthFromAngleChange(
                      newAngle,
                      stateStairs,
                      stateStairs.angle
                    );

                    setLength(newLength.metrics);
                    setStairsAngle(newAngle);
                    setStateStairs(newLength.stairs);

                    return;
                  }
                }}
              />
            </div>
          </div>
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label edit-popup__label--stairs-lock">
              <Lock
                locks={locks}
                setLocks={setLocks}
                lockName="length"
                dispatch={dispatch}
                stateStairs={stateStairs}
              />
              Length
            </p>
            <div className="labels-section length-section">
              <span className="labeled-input input--w-label">
                <label htmlFor="stairlengthfeet">ft</label>
                <input
                  type="number"
                  id="stairlengthfeet"
                  min="0"
                  step="1"
                  value={length.feet}
                  disabled={locks.length}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newFeet = 0;

                      const diff = newFeet - length.feet;

                      const pixels = diff * pixelsPerFoot();
                      // If angle locked.
                      if (locks.angle) {
                        if (stateStairs.orientation === "vertical") {
                          if (stateStairs.y2 > stateStairs.y1) {
                            const newY2 = stateStairs.y2 + pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 - pixels / 2
                            );

                            setLength(() => ({
                              ...length,
                              feet: "",
                            }));
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newY2 = stateStairs.y2 - pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 + pixels / 2
                            );

                            setLength(() => ({
                              ...length,
                              feet: "",
                            }));
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }

                        if (stateStairs.orientation === "horizontal") {
                          if (stateStairs.x2 > stateStairs.x1) {
                            const newX2 = stateStairs.x2 + pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 - pixels / 2
                            );

                            setLength(() => ({
                              ...length,
                              feet: "",
                            }));
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newX2 = stateStairs.x2 - pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 + pixels / 2
                            );

                            setLength(() => ({
                              ...length,
                              feet: "",
                            }));
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }
                      }

                      // Set new angle and length.
                      if (locks.hypotenuse) {
                        const newAngle = calculateStairsAngleFromLengthChange(
                          diff,
                          stateStairs,
                          calculateStairsLength(stateStairs),
                          rawDistanceOfHypotenuse(
                            toRadians(stateStairs.angle),
                            calculateStairsLength(stateStairs)
                          ),
                          "feet"
                        );

                        setStairsAngle(newAngle.angle);
                        setLength(newAngle.metrics);
                        setStateStairs(newAngle.stairs);

                        return;
                      }
                    } else {
                      let newFeet = parseInt(event.target.value, 10);
                      let newInches = length.inches;

                      if (newFeet > hypotenuse.feet) {
                        newFeet = hypotenuse.feet;
                        newInches = hypotenuse.inches;
                      }

                      const diffFeet = newFeet - length.feet;
                      const diffInches = newInches - length.inches;
                      const pixels =
                        diffFeet * pixelsPerFoot() +
                        diffInches * pixelsPerInch();

                      // If angle locked.
                      if (locks.angle) {
                        if (stateStairs.orientation === "vertical") {
                          if (stateStairs.y2 > stateStairs.y1) {
                            const newY2 = stateStairs.y2 + pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 - pixels / 2
                            );

                            setLength({
                              ...length,
                              feet: newFeet,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newY2 = stateStairs.y2 - pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              feet: newFeet,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }

                        if (stateStairs.orientation === "horizontal") {
                          if (stateStairs.x2 > stateStairs.x1) {
                            const newX2 = stateStairs.x2 + pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 - pixels / 2
                            );

                            setLength({
                              ...length,
                              feet: newFeet,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newX2 = stateStairs.x2 - pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              feet: newFeet,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }
                      }

                      // Set new angle and length.
                      if (locks.hypotenuse) {
                        const newAngle = calculateStairsAngleFromLengthChange(
                          pixels,
                          stateStairs,
                          calculateStairsLength(stateStairs),
                          rawDistanceOfHypotenuse(
                            toRadians(stateStairs.angle),
                            calculateStairsLength(stateStairs)
                          ),
                          "pixels"
                        );

                        setStairsAngle(newAngle.angle);
                        setLength(newAngle.metrics);
                        setStateStairs(newAngle.stairs);

                        return;
                      }
                    }
                  }}
                />
              </span>
              <span className="labeled-input input--w-label">
                <label htmlFor="stairlengthinch">in</label>
                <input
                  type="number"
                  max="11"
                  min="0"
                  id="stairlengthinch"
                  value={length.inches}
                  disabled={locks.length}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newInches = 0;

                      const diff = newInches - length.inches;

                      const pixels = diff * pixelsPerInch();

                      // If angle locked.
                      if (locks.angle) {
                        if (stateStairs.orientation === "vertical") {
                          if (stateStairs.y2 > stateStairs.y1) {
                            const newY2 = stateStairs.y2 + pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 - pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: "",
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newY2 = stateStairs.y2 - pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: "",
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }

                        if (stateStairs.orientation === "horizontal") {
                          if (stateStairs.x2 > stateStairs.x1) {
                            const newX2 = stateStairs.x2 + pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 - pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: "",
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newX2 = stateStairs.x2 - pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: "",
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }
                      }

                      // Set new angle and length.
                      if (locks.hypotenuse) {
                        const newAngle = calculateStairsAngleFromLengthChange(
                          diff,
                          stateStairs,
                          calculateStairsLength(stateStairs),
                          rawDistanceOfHypotenuse(
                            toRadians(stateStairs.angle),
                            calculateStairsLength(stateStairs)
                          ),
                          "inches"
                        );

                        setStairsAngle(newAngle.angle);
                        setLength(newAngle.metrics);
                        setStateStairs(newAngle.stairs);

                        return;
                      }
                    } else {
                      let newInches = parseInt(event.target.value, 10);
                      let newFeet = length.feet;

                      if (
                        newInches > hypotenuse.inches &&
                        hypotenuse.feet === length.feet
                      ) {
                        newInches = hypotenuse.inches;
                      }

                      const diffInches = newInches - length.inches;
                      const diffFeet = newFeet - length.feet;

                      const pixels =
                        diffInches * pixelsPerInch() +
                        diffFeet * pixelsPerFoot();

                      // If angle locked.
                      if (locks.angle) {
                        if (stateStairs.orientation === "vertical") {
                          if (stateStairs.y2 > stateStairs.y1) {
                            const newY2 = stateStairs.y2 + pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 - pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newY2 = stateStairs.y2 - pixels / 2;

                            let newStairs = stateStairs.set("y2", newY2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newY2 - newStairs.y1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }

                        if (stateStairs.orientation === "horizontal") {
                          if (stateStairs.x2 > stateStairs.x1) {
                            const newX2 = stateStairs.x2 + pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "y1",
                              stateStairs.y1 - pixels / 2
                            );
                            setLength({
                              ...length,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          } else {
                            const newX2 = stateStairs.x2 - pixels / 2;

                            let newStairs = stateStairs.set("x2", newX2);
                            newStairs = newStairs.set(
                              "x1",
                              stateStairs.x1 + pixels / 2
                            );

                            setLength({
                              ...length,
                              inches: newInches,
                            });
                            setHypotenuse(
                              distanceOfHypotenuse(
                                toRadians(stateStairs.angle),
                                Math.abs(newX2 - newStairs.x1)
                              )
                            );
                            setStateStairs(newStairs);
                          }

                          return;
                        }
                      }

                      // Set new angle and length.
                      if (locks.hypotenuse) {
                        const newAngle = calculateStairsAngleFromLengthChange(
                          pixels,
                          stateStairs,
                          calculateStairsLength(stateStairs),
                          rawDistanceOfHypotenuse(
                            toRadians(stateStairs.angle),
                            calculateStairsLength(stateStairs)
                          ),
                          "pixels"
                        );

                        setStairsAngle(newAngle.angle);
                        setLength(newAngle.metrics);
                        setStateStairs(newAngle.stairs);

                        return;
                      }
                    }
                  }}
                />
              </span>
            </div>
          </div>
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label edit-popup__label--stairs-lock">
              <Lock
                locks={locks}
                setLocks={setLocks}
                lockName="hypotenuse"
                dispatch={dispatch}
                stateStairs={stateStairs}
              />
              Hypotenuse
            </p>
            <div className="labels-section length-section">
              <span className="labeled-input input--w-label">
                <label htmlFor="stairhypotenusefeet">ft</label>
                <input
                  type="number"
                  id="stairhypotenusefeet"
                  value={hypotenuse.feet}
                  min="0"
                  disabled={locks.hypotenuse}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newFeet = 0;

                      if (locks.length) {
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          feet: "",
                        }));

                        const newAngle = angleBasedOnSideAndHypotenuse(
                          {
                            ...hypotenuse,
                            feet: newFeet,
                          },
                          stairLength(stateStairs)
                        );
                        setStairsAngle(newAngle);

                        const newStairs = stateStairs.set("angle", newAngle);

                        setStateStairs(newStairs);
                      }

                      if (locks.angle) {
                        const diff = newFeet - hypotenuse.feet;

                        const newLength = lengthBasedOnAngleAndHypotenuse(
                          diff,
                          stateStairs.angle,
                          stateStairs,
                          hypotenuse,
                          "feet"
                        );
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          feet: "",
                        }));
                        setLength(newLength.metrics);
                        setStateStairs(newLength.stairs);
                      }
                    } else {
                      let newFeet = parseInt(event.target.value, 10);
                      let newInches = hypotenuse.inches;

                      if (
                        newFeet === length.feet &&
                        hypotenuse.inches <= length.inches
                      ) {
                        newFeet = length.feet;
                        newInches = length.inches;
                      }

                      if (newFeet < length.feet) {
                        newFeet = length.feet;
                      }

                      if (locks.length) {
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          feet: newFeet,
                          inches: newInches,
                        }));

                        const newAngle = angleBasedOnSideAndHypotenuse(
                          {
                            ...hypotenuse,
                            feet: newFeet,
                            inches: newInches,
                          },
                          stairLength(stateStairs)
                        );
                        setStairsAngle(newAngle);

                        const newStairs = stateStairs.set("angle", newAngle);

                        setStateStairs(newStairs);
                      }

                      if (locks.angle) {
                        const diffFeet = newFeet - hypotenuse.feet;
                        const diffInches = newInches - hypotenuse.inches;

                        const newLength = lengthBasedOnAngleAndHypotenuse(
                          diffInches * pixelsPerInch() +
                            diffFeet * pixelsPerFoot(),
                          stateStairs.angle,
                          stateStairs,
                          hypotenuse,
                          "pixels"
                        );
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          feet: newFeet,
                        }));
                        setLength(newLength.metrics);
                        setStateStairs(newLength.stairs);
                      }
                    }
                  }}
                />
              </span>
              <span className="labeled-input input--w-label">
                <label htmlFor="stairhypotenuseinch">in</label>
                <input
                  type="number"
                  max="11"
                  min="0"
                  id="stairhypotenuseinch"
                  disabled={locks.hypotenuse}
                  value={hypotenuse.inches}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newInches = 0;

                      if (locks.length) {
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          inches: "",
                        }));

                        const newAngle = angleBasedOnSideAndHypotenuse(
                          {
                            ...hypotenuse,
                            inches: newInches,
                          },
                          stairLength(stateStairs)
                        );
                        setStairsAngle(newAngle);

                        const newStairs = stateStairs.set("angle", newAngle);

                        setStateStairs(newStairs);
                      }

                      if (locks.angle) {
                        const diff = newInches - hypotenuse.inches;

                        const newLength = lengthBasedOnAngleAndHypotenuse(
                          diff,
                          stateStairs.angle,
                          stateStairs,
                          hypotenuse,
                          "inches"
                        );
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          inches: "",
                        }));
                        setLength(newLength.metrics);
                        setStateStairs(newLength.stairs);
                      }
                    } else {
                      let newInches = parseInt(event.target.value, 10);

                      if (
                        newInches <= length.inches &&
                        hypotenuse.feet <= length.feet
                      ) {
                        newInches = length.inches;
                      }

                      if (locks.length) {
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          inches: newInches,
                        }));

                        const newAngle = angleBasedOnSideAndHypotenuse(
                          {
                            ...hypotenuse,
                            inches: newInches,
                          },
                          stairLength(stateStairs)
                        );
                        setStairsAngle(newAngle);

                        const newStairs = stateStairs.set("angle", newAngle);

                        setStateStairs(newStairs);
                      }

                      if (locks.angle) {
                        const diff = newInches - hypotenuse.inches;

                        const newLength = lengthBasedOnAngleAndHypotenuse(
                          diff,
                          stateStairs.angle,
                          stateStairs,
                          hypotenuse,
                          "inches"
                        );
                        setHypotenuse(() => ({
                          ...hypotenuse,
                          inches: newInches,
                        }));
                        setLength(newLength.metrics);
                        setStateStairs(newLength.stairs);
                      }
                    }
                  }}
                />
              </span>
            </div>
          </div>
          <div className="edit-popup__control edit-popup__control--align-top">
            <p className="edit-popup__label">Width</p>
            <div className="labels-section length-section">
              <span className="labeled-input input--w-label">
                <label htmlFor="stairwidthfeet">ft</label>
                <input
                  type="number"
                  id="stairwidthfeet"
                  min="0"
                  step="1"
                  value={width.feet}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newFeet = 0;

                      setWidth(() => ({
                        ...width,
                        feet: "",
                      }));

                      const diff = newFeet - width.feet;

                      const pixels = diff * pixelsPerFoot();

                      if (stateStairs.orientation === "vertical") {
                        if (stateStairs.x2 > stateStairs.x1) {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 + pixels / 2)
                              .set("x1", stateStairs.x1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 - pixels / 2)
                              .set("x1", stateStairs.x1 + pixels / 2)
                          );
                        }
                      } else if (stateStairs.orientation === "horizontal") {
                        if (stateStairs.y2 > stateStairs.y1) {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 + pixels / 2)
                              .set("y1", stateStairs.y1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 - pixels / 2)
                              .set("y1", stateStairs.y1 + pixels / 2)
                          );
                        }
                      }
                    } else {
                      const newFeet = parseInt(event.target.value, 10);
                      setWidth(() => ({
                        ...width,
                        feet: newFeet,
                      }));

                      const diff = newFeet - width.feet;

                      const pixels = diff * pixelsPerFoot();

                      if (stateStairs.orientation === "vertical") {
                        if (stateStairs.x2 > stateStairs.x1) {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 + pixels / 2)
                              .set("x1", stateStairs.x1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 - pixels / 2)
                              .set("x1", stateStairs.x1 + pixels / 2)
                          );
                        }
                      } else if (stateStairs.orientation === "horizontal") {
                        if (stateStairs.y2 > stateStairs.y1) {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 + pixels / 2)
                              .set("y1", stateStairs.y1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 - pixels / 2)
                              .set("y1", stateStairs.y1 + pixels / 2)
                          );
                        }
                      }
                    }
                  }}
                />
              </span>
              <span className="labeled-input input--w-label">
                <label htmlFor="stairwidthinch">in</label>
                <input
                  type="number"
                  max="11"
                  min="0"
                  id="stairwidthinch"
                  value={width.inches}
                  onChange={(event) => {
                    if (event.target.value === "") {
                      const newInches = 0;

                      setWidth({
                        ...width,
                        inches: "",
                      });

                      const diff = newInches - width.inches;

                      const pixels = diff * pixelsPerInch();

                      if (stateStairs.orientation === "vertical") {
                        if (stateStairs.x2 > stateStairs.x1) {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 + pixels / 2)
                              .set("x1", stateStairs.x1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 - pixels / 2)
                              .set("x1", stateStairs.x1 + pixels / 2)
                          );
                        }
                      } else if (stateStairs.orientation === "horizontal") {
                        if (stateStairs.y2 > stateStairs.y1) {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 + pixels / 2)
                              .set("y1", stateStairs.y1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 - pixels / 2)
                              .set("y1", stateStairs.y1 + pixels / 2)
                          );
                        }
                      }
                    } else {
                      const newInches = parseInt(event.target.value, 10);

                      setWidth({
                        ...width,
                        inches: newInches,
                      });

                      const diff = newInches - width.inches;

                      const pixels = diff * pixelsPerInch();

                      if (stateStairs.orientation === "vertical") {
                        if (stateStairs.x2 > stateStairs.x1) {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 + pixels / 2)
                              .set("x1", stateStairs.x1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("x2", stateStairs.x2 - pixels / 2)
                              .set("x1", stateStairs.x1 + pixels / 2)
                          );
                        }
                      } else if (stateStairs.orientation === "horizontal") {
                        if (stateStairs.y2 > stateStairs.y1) {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 + pixels / 2)
                              .set("y1", stateStairs.y1 - pixels / 2)
                          );
                        } else {
                          setStateStairs(
                            stateStairs
                              .set("y2", stateStairs.y2 - pixels / 2)
                              .set("y1", stateStairs.y1 + pixels / 2)
                          );
                        }
                      }
                    }
                  }}
                />
              </span>
            </div>
          </div>
          <div className="edit-popup__control edit-popup__control--align-top save-calculation">
            <button
              onClick={() => {
                if (
                  stateStairs.x1 === stairs.x1 &&
                  stateStairs.y1 === stairs.y1 &&
                  stateStairs.x2 === stairs.x2 &&
                  stateStairs.y2 === stairs.y2
                ) {
                  dispatch({
                    type: "stairs/edit",
                    stairs: stateStairs,
                  });
                } else {
                  const dx1 = stateStairs.x1 - stairs.x1;
                  const dy1 = stateStairs.y1 - stairs.y1;
                  const dx2 = stateStairs.x2 - stairs.x2;
                  const dy2 = stateStairs.y2 - stairs.y2;

                  const transforms = { x1: dx1, y1: dy1, x2: dx2, y2: dy2 };

                  const action = { transforms };

                  const transformedEntities = handleStairsTransformsOfGraph(
                    stairs,
                    state,
                    action
                  );

                  const {
                    gates: newGates,
                    runs: newRuns,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "canvas/edit-entities",
                    runs: newRuns,
                    gates: newGates,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }
              }}
              className="labels-section__button"
            >
              Save Calculation
            </button>
          </div>
        </div>
      )}
      <div className="edit-popup__section edit-popup__labels">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Labels</p>
          <div className="labels-section">
            <div className="edit-popup__three-buttons labels__toggle-buttons">
              <button
                onClick={() => {
                  const labelsShow = stateStairs.getIn([
                    "labels",
                    "showLabels",
                  ]);

                  if (labelsShow !== true) {
                    let newStairs = stateStairs;

                    newStairs = newStairs.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                  }
                }}
                className={labelsShowClass}
              >
                Show
              </button>
              <button
                onClick={() => {
                  const labelsShow = stateStairs.getIn([
                    "labels",
                    "showLabels",
                  ]);

                  if (labelsShow === true) {
                    let newStairs = stairs;

                    newStairs = newStairs.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "stairs/edit", stairs: newStairs });
                  }
                }}
                className={labelsHideClass}
              >
                Hide
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newStairs = stateStairs;

                  // For stairs the distance label is used for vertical end.
                  const labelsSide = stateStairs.getIn([
                    "labels",
                    "distanceLabel",
                  ]);

                  newStairs = newStairs.setIn(
                    ["labels", "distanceLabel"],
                    !labelsSide
                  );

                  dispatch({ type: "stairs/edit", stairs: newStairs });
                }}
                className="labels-section__button"
              >
                Flip Vertical Distance Side
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newStairs = stateStairs;

                  const labelsSide = stateStairs.getIn([
                    "labels",
                    "stairsHorizontalLabel",
                  ]);

                  newStairs = newStairs.setIn(
                    ["labels", "stairsHorizontalLabel"],
                    !labelsSide
                  );

                  dispatch({ type: "stairs/edit", stairs: newStairs });
                }}
                className="labels-section__button"
              >
                Flip Horizontal Distance Side
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "stairs/remove", stairsIndex: stairs.id });
            }, 0);
          }}
        >
          Delete Stairs
        </button>
      </div>
    </div>
  );
}

function calculateStairsLength(stairs) {
  if (stairs.orientation === "horizontal") {
    return Math.abs(stairs.x2 - stairs.x1);
  }

  if (stairs.orientation === "vertical") {
    return Math.abs(stairs.y2 - stairs.y1);
  }
}

function calculateStairsLengthFromAngleChange(angle, stairs, originalAngle) {
  angle = toRadians(angle);

  const originalLength = calculateStairsLength(stairs);

  const rawHypotenuse = rawDistanceOfHypotenuse(
    toRadians(originalAngle),
    originalLength
  );
  const newLength = rawHypotenuse * Math.cos(angle);

  const distanceInFeet = newLength / pixelsPerFoot();
  const remainder = (distanceInFeet % 1).toFixed(4);

  const inches = Math.floor(remainder * 12);
  const feet = Math.floor(distanceInFeet);

  const originalDistanceInFeet = originalLength / pixelsPerFoot();
  const originalRemainder = (originalDistanceInFeet % 1).toFixed(4);

  const originalInches = Math.floor(originalRemainder * 12);
  const originalFeet = Math.floor(originalDistanceInFeet);

  const diffInches = inches - originalInches;
  const diffFeet = feet - originalFeet;

  let newStairs = stairs;

  const pixels = diffInches * pixelsPerInch() + diffFeet * pixelsPerFoot();

  // Set dimesions of stairs.
  if (stairs.orientation === "vertical") {
    if (stairs.y2 > stairs.y1) {
      const newY2 = newStairs.y2 + pixels / 2;

      newStairs = newStairs.set("y2", newY2);
      newStairs = newStairs.set("y1", newStairs.y1 - pixels / 2);
    } else {
      const newY2 = newStairs.y2 - pixels / 2;

      newStairs = newStairs.set("y2", newY2);
      newStairs = newStairs.set("y1", newStairs.y1 + pixels / 2);
    }
  } else if (stairs.orientation === "horizontal") {
    if (stairs.x2 > stairs.x1) {
      const newX2 = newStairs.x2 + pixels / 2;

      newStairs = stairs.set("x2", newX2);
      newStairs = newStairs.set("x1", newStairs.x1 - pixels / 2);
    } else {
      const newX2 = newStairs.x2 - pixels / 2;

      newStairs = stairs.set("x2", newX2);
      newStairs = newStairs.set("x1", newStairs.x1 + pixels / 2);
    }
  }

  newStairs = newStairs.set("angle", toDegrees(angle));

  return {
    metrics: stairLength(newStairs),
    stairs: newStairs,
  };
}

function calculateStairsAngleFromLengthChange(
  length,
  stairs,
  originalLength,
  hypotenuse,
  metric
) {
  if (metric === "feet") {
    length = length * pixelsPerFoot();
  } else if (metric === "inches") {
    length = length * pixelsPerInch();
  }

  const rawHypotenuse = hypotenuse;
  let newAngle = Math.acos((length + originalLength) / rawHypotenuse);

  if (isNaN(newAngle)) {
    newAngle = 0;
  }

  let newStairs = stairs.set("angle", roundToHundreth(toDegrees(newAngle)));

  if (stairs.orientation === "vertical") {
    if (stairs.y2 > stairs.y1) {
      const newY2 = stairs.y2 + length / 2;

      newStairs = newStairs.set("y2", newY2);
      newStairs = newStairs.set("y1", newStairs.y1 - length / 2);
    } else {
      const newY2 = stairs.y2 - length / 2;

      newStairs = newStairs.set("y2", newY2);
      newStairs = newStairs.set("y1", newStairs.y1 + length / 2);
    }
  } else if (stairs.orientation === "horizontal") {
    if (stairs.x2 > stairs.x1) {
      const newX2 = stairs.x2 + length / 2;

      newStairs = newStairs.set("x2", newX2);
      newStairs = newStairs.set("x1", newStairs.x1 - length / 2);
    } else {
      const newX2 = stairs.x2 - length / 2;

      newStairs = newStairs.set("x2", newX2);
      newStairs = newStairs.set("x1", newStairs.x1 + length / 2);
    }
  }

  return {
    angle: roundToHundreth(toDegrees(newAngle)),
    metrics: stairLength(newStairs),
    stairs: newStairs,
  };
}

function Lock({ locks, setLocks, lockName, dispatch, stateStairs }) {
  let lockClass = "stairs-lock";

  if (locks[lockName]) {
    lockClass += " stairs-lock--locked";
  }

  return (
    <span
      onClick={() => {
        // Don't do anything if locked.
        if (locks[lockName] === true) {
          return;
        }

        const newLocks = {
          hypotenuse: false,
          length: false,
          angle: false,
        };

        setLocks({
          ...newLocks,
          [lockName]: true,
        });
      }}
      className={lockClass}
    >
      <Icon icon="lock" />
    </span>
  );
}

const handleStairsTransformsOfGraph = (theStairs, state, action) => {
  const { transforms } = action;

  const { stairs, runs, handrails, gates, posts } = state;

  const corners = calculateCorners(runs);

  let {
    x1: transformX1,
    y1: transformY1,
    x2: transformX2,
    y2: transformY2,
  } = transforms;

  // Calibrate transforms.
  if (theStairs.x1 > theStairs.x2) {
    transformX1 = -1 * transformX1;
    transformX2 = -1 * transformX2;
  }

  if (theStairs.y1 > theStairs.y2) {
    transformY1 = -1 * transformY1;
    transformY2 = -1 * transformY2;
  }

  const graph = getConnectedElements(
    theStairs,
    corners,
    runs,
    gates,
    stairs,
    posts,
    handrails
  );

  let topEdge = "stairs-top-to-stairs";
  let bottomEdge = "stairs-bottom-to-stairs";
  let leftEdge = "stairs-left-to-stairs";
  let rightEdge = "stairs-right-to-stairs";

  const connections = Map({
    top: getGraphsFromEdgeType(theStairs, graph, topEdge),
    left: getGraphsFromEdgeType(theStairs, graph, leftEdge),
    bottom: getGraphsFromEdgeType(theStairs, graph, bottomEdge),
    right: getGraphsFromEdgeType(theStairs, graph, rightEdge),
  });

  const transformedConnections = connections.map((connection, type) => {
    if (!connection.root) {
      return Map();
    }

    if (type === "top") {
      return handleTransformOfStairs(
        connection,
        {
          x1: transformX1,
          y1: transformY1,
          x2: transformX2,
          y2: transformY1,
        },
        type,
        Map({ [theStairs.id]: true }),
        "bottom"
      ).map((item) => item.node);
    }

    if (type === "left") {
      return handleTransformOfStairs(
        connection,
        {
          x1: transformX1,
          y1: transformY1,
          x2: transformX1,
          y2: transformY2,
        },
        type,
        Map({ [theStairs.id]: true }),
        "right"
      ).map((item) => item.node);
    }

    if (type === "bottom") {
      return handleTransformOfStairs(
        connection,
        {
          x1: transformX1,
          y1: transformY2,
          x2: transformX2,
          y2: transformY2,
        },
        type,
        Map({ [theStairs.id]: true }),
        "top"
      ).map((item) => item.node);
    }

    if (type === "right") {
      return handleTransformOfStairs(
        connection,
        {
          x1: transformX2,
          y1: transformY1,
          x2: transformX2,
          y2: transformY2,
        },
        type,
        Map({ [theStairs.id]: true }),
        "left"
      ).map((item) => item.node);
    }

    return handleTransformOfStairs(
      connection,
      {
        x1: transformX1,
        y1: transformY1,
        x2: transformX2,
        y2: transformY2,
      },
      type,
      Map({ [theStairs.id]: true })
    ).map((item) => item.node);
  });

  const newStairs = theStairs
    .set("x1", theStairs.x1 + transforms.x1)
    .set("y1", theStairs.y1 + transforms.y1)
    .set("x2", theStairs.x2 + transforms.x2)
    .set("y2", theStairs.y2 + transforms.y2);

  const allTransformedStairs = transformedConnections.reduce(
    (transformedStairs, connection) => {
      return transformedStairs.merge(connection);
    },
    Map({
      [theStairs.id]: newStairs,
    })
  );

  let transformedEntities = handleRunTransformsOfStairs(
    newStairs,
    newStairs,
    { x1: transformX1, y1: transformY1, x2: transformX2, y2: transformY2 },
    allTransformedStairs,
    graph,
    state
  );

  const newEntities = nodesToObject(
    transformedEntities.graph.map((item) => item.node),
    runs,
    gates,
    stairs,
    posts,
    handrails
  );

  return newEntities;
};

function handleRunTransformsOfStairs(
  absoluteRoot,
  rootStair,
  transforms,
  transformedStairs,
  graph,
  state,
  seen = Map(),
  alreadyTransformed = Map(),
  type = null,
  from = null,
  changedDirection = false
) {
  const { runs, gates, stairs, posts, handrails } = state;
  const { x1, y1, x2, y2 } = transforms;

  const stairsToRunKeys = {
    "BL-TL-stairs-to-run": "left",
    "TL-BL-stairs-to-run": "left",
    "BR-TR-stairs-to-run": "right",
    "TR-BR-stairs-to-run": "right",
    "BL-BR-stairs-to-run": "bottom",
    "BR-BL-stairs-to-run": "bottom",
    "TL-TR-stairs-to-run": "top",
    "TR-TL-stairs-to-run": "top",
  };

  let rootNode = rootStair;

  if (rootNode.id) {
    rootNode = graph.get(rootNode.id);
  }
  const nodeValue = rootNode.node;

  graph = graph.setIn(
    [nodeValue.id, "node"],
    transformedStairs.get(nodeValue.id)
  );

  seen = seen.set(nodeValue.id, true);

  let newX1 = x1;
  let newY1 = y1;
  let newX2 = x2;
  let newY2 = y2;

  const edgeToType = {
    "stairs-top-to-stairs": "top",
    "stairs-bottom-to-stairs": "bottom",
    "stairs-left-to-stairs": "left",
    "stairs-right-to-stairs": "right",
  };

  const fromEdgeType = {
    "stairs-top-to-stairs": "bottom",
    "stairs-bottom-to-stairs": "top",
    "stairs-left-to-stairs": "right",
    "stairs-right-to-stairs": "left",
  };

  const edges = rootNode.edges;

  const connectedRuns = runs
    .filter((run) => {
      if (run.stairs && run.stairs.continuousStairs) {
        if (run.stairs.continuousStairs.has(nodeValue.id)) {
          return true;
        }
      }

      return false;
    })
    .map((run) => graph.get(run.id))
    .map((item) => {
      const toStairsEdge = item.edges.get("run-to-stairs");
      const toStairs = graph.get(toStairsEdge.to);

      const edgeType = toStairs.edges.findKey(
        (edge) => item.node.id === edge.to
      );
      return { run: item.node, edgeType: edgeType };
    });

  if (edges && edges.size) {
    if (connectedRuns.size) {
      connectedRuns.forEach(({ run, edgeType }) => {
        if (
          !alreadyTransformed.has(run.id) &&
          run.stairs.continuousStairs.has(nodeValue.id) &&
          lineRectIntersection(run, run, nodeValue) &&
          isRunNotOnlyInCorner(run, state, nodeValue)
        ) {
          let runTransforms = { x1: newX1, y1: newY1, x2: newX2, y2: newY2 };
          if (stairsToRunKeys[edgeType] === "left") {
            runTransforms = { x1: newX1, y1: newY1, x2: newX1, y2: newY2 };
          }
          if (stairsToRunKeys[edgeType] === "right") {
            runTransforms = { x1: newX2, y1: newY1, x2: newX2, y2: newY2 };
          }
          if (stairsToRunKeys[edgeType] === "bottom") {
            runTransforms = { x1: newX1, y1: newY2, x2: newX2, y2: newY2 };
          }
          if (stairsToRunKeys[edgeType] === "top") {
            runTransforms = { x1: newX1, y1: newY1, x2: newX2, y2: newY1 };
          }

          if (absoluteRoot.id === nodeValue.id) {
            const angleOfRun = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
            if (isCloseToValue(angleOfRun, -Math.PI / 2, 0.1)) {
              runTransforms.y1 *= -1;
              runTransforms.y2 *= -1;
            }
            if (
              isCloseToValue(angleOfRun, Math.PI, 0.1) ||
              isCloseToValue(angleOfRun, -Math.PI, 0.1)
            ) {
              runTransforms.x1 *= -1;
              runTransforms.x2 *= -1;
            }
          }

          run = run
            .set("x1", run.x1 + runTransforms.x1)
            .set("y1", run.y1 + runTransforms.y1)
            .set("x2", run.x2 + runTransforms.x2)
            .set("y2", run.y2 + runTransforms.y2);
          run = transformRunStairs(run, runTransforms.x1, runTransforms.y1);
          run.stairs.continuousStairs.forEach((_continuousStair, index) => {
            run = run.setIn(
              ["stairs", "continuousStairs", index],
              transformedStairs.get(index)
            );
          });
          graph = graph.setIn([run.id, "node"], run);
          alreadyTransformed = alreadyTransformed.set(run.id, true);

          const runNode = graph.get(run.id);

          const subGraphStart = getRunSubGraph(
            runNode,
            graph,
            transformedStairs,
            "start"
          ).map((item) => item.node);
          const subGraphEnd = getRunSubGraph(
            runNode,
            graph,
            transformedStairs,
            "end"
          ).map((item) => item.node);

          if (subGraphStart && subGraphStart.size) {
            const movedEntities = handleMoveTransformsOfGraph(
              subGraphStart,
              {
                transformX1: runTransforms.x1,
                transformY1: runTransforms.y1,
                transformX2: runTransforms.x1,
                transformY2: runTransforms.y1,
              },
              runs,
              gates,
              stairs,
              posts,
              handrails
            );
            movedEntities.forEach((item) => {
              graph = graph.setIn([item.id, "node"], item);
            });
          }

          if (subGraphEnd && subGraphEnd.size) {
            const movedEntities = handleMoveTransformsOfGraph(
              subGraphEnd,
              {
                transformX1: runTransforms.x2,
                transformY1: runTransforms.y2,
                transformX2: runTransforms.x2,
                transformY2: runTransforms.y2,
              },
              runs,
              gates,
              stairs,
              posts,
              handrails
            );

            movedEntities.forEach((item) => {
              graph = graph.setIn([item.id, "node"], item);
            });
          }

          const newState = handleResizingOfRuns(
            runs,
            run,
            runs.get(run.id),
            state.settings,
            state.settings,
            Map(),
            runNode.edges.reduce((acc, edge, edgeType) => {
              if (
                edgeType.startsWith("run-to-automatic-handrail") ||
                edgeType.startsWith("run-to-drawn-attached-handrail") ||
                edgeType.startsWith("run-to-drawn-handrail")
              ) {
                return acc.set(edge.to, graph.getIn([edge.to, "node"]));
              }
              return acc;
            }, Map()),
            state
          );

          if (newState.gates && newState.gates.size) {
            newState.gates.forEach((gate) => {
              if (graph.has(gate.id) && !alreadyTransformed.has(gate.id)) {
                graph = graph.setIn([gate.id, "node"], gate);
                graph = graph.setIn([gate.id, "type"], "gate");
                alreadyTransformed = alreadyTransformed.set(gate.id, true);
              }
            });
          }

          if (newState.handrails && newState.handrails.size) {
            newState.handrails.forEach((handrail) => {
              if (
                graph.has(handrail.id) &&
                !alreadyTransformed.has(handrail.id)
              ) {
                graph = graph.setIn([handrail.id, "node"], handrail);
                graph = graph.setIn([handrail.id, "type"], "handrail");
                alreadyTransformed = alreadyTransformed.set(handrail.id, true);
              }
            });
          }
        }
      });
    }
    edges.forEach((edge, edgeType) => {
      // Travers runs.
      if (stairsToRunKeys[edgeType] && !alreadyTransformed.has(edge.to)) {
        const runNode = graph.get(edge.to);
        let runValue = runNode.node;

        if (runValue.stairs && runValue.stairs.continuousStairs) {
          let runTransforms = { x1: newX1, y1: newY1, x2: newX2, y2: newY2 };

          if (stairsToRunKeys[edgeType] === "left") {
            runTransforms = { x1: newX1, y1: newY1, x2: newX1, y2: newY2 };
          }

          if (stairsToRunKeys[edgeType] === "right") {
            runTransforms = { x1: newX2, y1: newY1, x2: newX2, y2: newY2 };
          }

          if (stairsToRunKeys[edgeType] === "bottom") {
            runTransforms = { x1: newX1, y1: newY2, x2: newX2, y2: newY2 };
          }

          if (stairsToRunKeys[edgeType] === "top") {
            runTransforms = { x1: newX1, y1: newY1, x2: newX2, y2: newY1 };
          }

          const angleOfRun = Math.atan2(
            runValue.y2 - runValue.y1,
            runValue.x2 - runValue.x1
          );

          if (absoluteRoot.id === nodeValue.id) {
            if (isCloseToValue(angleOfRun, -Math.PI / 2, 0.1)) {
              runTransforms.y1 *= -1;
              runTransforms.y2 *= -1;
            }

            if (
              isCloseToValue(angleOfRun, Math.PI, 0.1) ||
              isCloseToValue(angleOfRun, -Math.PI, 0.1)
            ) {
              runTransforms.x1 *= -1;
              runTransforms.x2 *= -1;
            }
          }

          runValue = runValue
            .set("x1", runValue.x1 + runTransforms.x1)
            .set("y1", runValue.y1 + runTransforms.y1)
            .set("x2", runValue.x2 + runTransforms.x2)
            .set("y2", runValue.y2 + runTransforms.y2);

          runValue = transformRunStairs(
            runValue,
            runTransforms.x1,
            runTransforms.y1
          );

          runValue.stairs.continuousStairs.forEach(
            (_continuousStair, index) => {
              runValue = runValue.setIn(
                ["stairs", "continuousStairs", index],
                transformedStairs.get(index)
              );
            }
          );

          graph = graph.setIn([runValue.id, "node"], runValue);
          alreadyTransformed = alreadyTransformed.set(runValue.id, true);

          const subGraphStart = getRunSubGraph(
            runNode,
            graph,
            transformedStairs,
            "start"
          ).map((item) => item.node);
          const subGraphEnd = getRunSubGraph(
            runNode,
            graph,
            transformedStairs,
            "end"
          ).map((item) => item.node);

          if (subGraphStart && subGraphStart.size) {
            const movedEntities = handleMoveTransformsOfGraph(
              subGraphStart,
              {
                transformX1: transforms.x1,
                transformY1: transforms.y1,
                transformX2: transforms.x1,
                transformY2: transforms.y1,
              },
              runs,
              gates,
              stairs,
              posts,
              handrails
            );
            movedEntities.forEach((item) => {
              graph = graph.setIn([item.id, "node"], item);
            });
          }

          if (subGraphEnd && subGraphEnd.size) {
            const movedEntities = handleMoveTransformsOfGraph(
              subGraphEnd,
              {
                transformX1: transforms.x2,
                transformY1: transforms.y2,
                transformX2: transforms.x2,
                transformY2: transforms.y2,
              },
              runs,
              gates,
              stairs,
              posts,
              handrails
            );
            movedEntities.forEach((item) => {
              graph = graph.setIn([item.id, "node"], item);
            });
          }

          const newState = handleResizingOfRuns(
            runs,
            runValue,
            runs.get(runValue.id),
            state.settings,
            state.settings,
            Map(),
            runNode.edges.reduce((acc, edge, edgeType) => {
              if (
                edgeType.startsWith("run-to-automatic-handrail") ||
                edgeType.startsWith("run-to-drawn-attached-handrail") ||
                edgeType.startsWith("run-to-drawn-handrail")
              ) {
                return acc.set(edge.to, graph.getIn([edge.to, "node"]));
              }
              return acc;
            }, Map()),
            state
          );

          if (newState.gates && newState.gates.size) {
            newState.gates.forEach((gate) => {
              if (graph.has(gate.id) && !alreadyTransformed.has(gate.id)) {
                graph = graph.setIn([gate.id, "node"], gate);
                graph = graph.setIn([gate.id, "type"], "gate");
                alreadyTransformed = alreadyTransformed.set(gate.id, true);
              }
            });
          }

          if (newState.handrails && newState.handrails.size) {
            newState.handrails.forEach((handrail) => {
              if (
                graph.has(handrail.id) &&
                !alreadyTransformed.has(handrail.id)
              ) {
                graph = graph.setIn([handrail.id, "node"], handrail);
                graph = graph.setIn([handrail.id, "type"], "handrail");
                alreadyTransformed = alreadyTransformed.set(handrail.id, true);
              }
            });
          }
        }
      }

      // Only traverse stairs.
      if (edgeToType[edgeType]) {
        if (seen.has(edge.to)) {
          return;
        }

        const node = graph.get(edge.to);

        let newTransforms = transforms;

        let newChangedDirection = changedDirection;

        if (edgeType === "stairs-top-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y1,
            x2: transforms.x2,
            y2: transforms.y1,
          };
        }

        if (edgeType === "stairs-left-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y1,
            x2: transforms.x1,
            y2: transforms.y2,
          };
        }

        if (edgeType === "stairs-bottom-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y2,
            x2: transforms.x2,
            y2: transforms.y2,
          };
        }

        if (edgeType === "stairs-right-to-stairs") {
          newTransforms = {
            x1: transforms.x2,
            y1: transforms.y1,
            x2: transforms.x2,
            y2: transforms.y2,
          };
        }

        // When changing direction from the main axis of change we do not flip anymore.
        // A change in direction occurs when the from type and to type do not match anymore.

        if (from && !newChangedDirection) {
          if (from === "top" && edgeToType[edgeType] !== "bottom") {
            newChangedDirection = true;
          }

          if (from === "left" && edgeToType[edgeType] !== "right") {
            newChangedDirection = true;
          }

          if (from === "bottom" && edgeToType[edgeType] !== "top") {
            newChangedDirection = true;
          }

          if (from === "right" && edgeToType[edgeType] !== "left") {
            newChangedDirection = true;
          }
        }

        if (node && (node.type === "stairs" || node.type === "landing")) {
          const values = handleRunTransformsOfStairs(
            absoluteRoot,
            node,
            newTransforms,
            transformedStairs,
            graph,
            state,
            seen,
            alreadyTransformed,
            edgeToType[edgeType],
            fromEdgeType[edgeType],
            newChangedDirection
          );

          alreadyTransformed = values.alreadyTransformed;
          graph = values.graph;
        }
      }
    });
  }

  return {
    graph,
    alreadyTransformed,
  };
}

function isRunNotOnlyInCorner(run, state, stairs) {
  const posts = getPosts(run, state.settings, state);

  const postsOnStairs = posts.filter((post) => {
    return post.matchingStair.includes(stairs.id);
  });

  if (postsOnStairs.length > 1) {
    return true;
  }

  return false;
}

function getRunSubGraph(run, graph, transformedStairs, type = "start") {
  let startNode = run;

  if (run.edges && run.edges.size) {
    run.edges.forEach((edge, edgeType) => {
      if (edgeType === `run-${type}-to-run`) {
        startNode = graph.get(edge.to);

        if (startNode.node.stairs && startNode.node.stairs.continuousStairs) {
          if (
            startNode.node.stairs.continuousStairs.find((s) =>
              transformedStairs.has(s.id)
            )
          ) {
            // Don't walk graph if connected to transformed stairs.
            startNode = run;
          }
        }
      }

      if (edgeType === `run-${type}-to-gate`) {
        startNode = graph.get(edge.to);
      }
    });
  }

  return walkGraph(graph, startNode, [], Map({ [run.node.id]: run })).remove(
    run.node.id
  );
}

function handleTransformOfStairs(
  connection,
  transforms,
  type,
  seen = Map(),
  from = null,
  changedDirection = false
) {
  const { x1, y1, x2, y2 } = transforms;

  if (!connection.root) {
    return connection.graph;
  }

  const root = connection.root.node;

  seen = seen.set(root.id, true);

  let nodeValue = connection.graph.getIn([root.id, "node"]);

  if (root.type === "stairs" || root.type === "landing") {
    let newX1 = x1;
    let newY1 = y1;
    let newX2 = x2;
    let newY2 = y2;

    // Calibrate transforms.
    if (!changedDirection && type === "left") {
      if (nodeValue.y1 > nodeValue.y2) {
        newY1 = -1 * newY1;
        newY2 = -1 * newY2;
      }
    }

    if (!changedDirection && type === "top") {
      if (nodeValue.x1 > nodeValue.x2) {
        newX1 = -1 * newX1;
        newX2 = -1 * newX2;
      }
    }

    if (!changedDirection && type === "right") {
      if (nodeValue.y1 > nodeValue.y2) {
        newY1 = -1 * newY1;
        newY2 = -1 * newY2;
      }
    }

    if (!changedDirection && type === "bottom") {
      if (nodeValue.x1 > nodeValue.x2) {
        newX1 = -1 * newX1;
        newX2 = -1 * newX2;
      }
    }

    nodeValue = nodeValue.set("x1", nodeValue.x1 + newX1);
    nodeValue = nodeValue.set("y1", nodeValue.y1 + newY1);
    nodeValue = nodeValue.set("x2", nodeValue.x2 + newX2);
    nodeValue = nodeValue.set("y2", nodeValue.y2 + newY2);

    connection.graph = connection.graph.setIn([root.id, "node"], nodeValue);

    const edges = connection.graph.getIn([root.id, "edges"]);

    const edgeToType = {
      "stairs-top-to-stairs": "top",
      "stairs-bottom-to-stairs": "bottom",
      "stairs-left-to-stairs": "left",
      "stairs-right-to-stairs": "right",
    };

    const fromEdgeType = {
      "stairs-top-to-stairs": "bottom",
      "stairs-bottom-to-stairs": "top",
      "stairs-left-to-stairs": "right",
      "stairs-right-to-stairs": "left",
    };

    if (edges && edges.size) {
      edges.forEach((edge, edgeType) => {
        if (seen.has(edge.to)) {
          return;
        }

        const node = connection.graph.get(edge.to);

        connection.root = node;

        let newTransforms = transforms;

        let newChangedDirection = changedDirection;

        if (edgeType === "stairs-top-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y1,
            x2: transforms.x2,
            y2: transforms.y1,
          };
        }

        if (edgeType === "stairs-left-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y1,
            x2: transforms.x1,
            y2: transforms.y2,
          };
        }

        if (edgeType === "stairs-bottom-to-stairs") {
          newTransforms = {
            x1: transforms.x1,
            y1: transforms.y2,
            x2: transforms.x2,
            y2: transforms.y2,
          };
        }

        if (edgeType === "stairs-right-to-stairs") {
          newTransforms = {
            x1: transforms.x2,
            y1: transforms.y1,
            x2: transforms.x2,
            y2: transforms.y2,
          };
        }

        // When changing direction from the main axis of change we do not flip anymore.
        // A change in direction occurs when the from type and to type do not match anymore.

        if (from && !newChangedDirection) {
          if (from === "top" && edgeToType[edgeType] !== "bottom") {
            newChangedDirection = true;
          }

          if (from === "left" && edgeToType[edgeType] !== "right") {
            newChangedDirection = true;
          }

          if (from === "bottom" && edgeToType[edgeType] !== "top") {
            newChangedDirection = true;
          }

          if (from === "right" && edgeToType[edgeType] !== "left") {
            newChangedDirection = true;
          }
        }

        if (node && (node.type === "stairs" || node.type === "landing")) {
          connection.graph = handleTransformOfStairs(
            connection,
            newTransforms,
            edgeToType[edgeType],
            seen,
            fromEdgeType[edgeType],
            newChangedDirection
          );
        }
      });
    }
  }

  return connection.graph;
}

function getGraphsFromEdgeType(theStairs, graph, edgeType) {
  const node = graph.get(theStairs.id);

  const matchingEdgeType = {
    "stairs-top-to-stairs": "stairs-bottom-to-stairs",
    "stairs-bottom-to-stairs": "stairs-top-to-stairs",
    "stairs-left-to-stairs": "stairs-right-to-stairs",
    "stairs-right-to-stairs": "stairs-left-to-stairs",
  };

  const edges = node.edges;

  if (edges.has(edgeType)) {
    const edge = edges.get(edgeType);

    const modifiedGraph = graph.removeIn([
      edge.to,
      "edges",
      matchingEdgeType[edgeType],
    ]);

    const connectedNode = modifiedGraph.get(edge.to);

    return {
      root: connectedNode,
      graph: walkGraph(modifiedGraph, connectedNode, ["stairs", "landing"]),
    };
  }

  return { root: null, graph: Map() };
}

function lineRectIntersection(lineStart, lineEnd, rect) {
  if (rect.x1 > rect.x2) {
    let temp = rect.x1;
    rect = rect.set("x1", rect.x2);
    rect = rect.set("x2", temp);
  }

  if (rect.y1 > rect.y2) {
    let temp = rect.y1;
    rect = rect.set("y1", rect.y2);
    rect = rect.set("y2", temp);
  }

  let topLeft = [rect.x1, rect.y1];
  let bottomRight = [rect.x2, rect.y2];
  let topRight = [rect.x2, rect.y1];
  let bottomLeft = [rect.x1, rect.y2];

  rect = [topLeft, bottomRight];

  lineStart = [lineStart.x1, lineStart.y1];
  lineEnd = [lineEnd.x2, lineEnd.y2];

  if (
    lineRectIntersect(lineStart, lineEnd, topLeft, topRight) ||
    lineRectIntersect(lineStart, lineEnd, topRight, bottomRight) ||
    lineRectIntersect(lineStart, lineEnd, bottomRight, bottomLeft) ||
    lineRectIntersect(lineStart, lineEnd, bottomLeft, topLeft)
    // pointInRectangle(lineStart, rect) ||
    // pointInRectangle(lineEnd, rect)
  ) {
    return true;
  }
  return false;
}

function lineRectIntersect(p1, p2, p3, p4) {
  let denominator =
    (p4[1] - p3[1]) * (p2[0] - p1[0]) - (p4[0] - p3[0]) * (p2[1] - p1[1]);
  let ua =
    ((p4[0] - p3[0]) * (p1[1] - p3[1]) - (p4[1] - p3[1]) * (p1[0] - p3[0])) /
    denominator;
  let ub =
    ((p2[0] - p1[0]) * (p1[1] - p3[1]) - (p2[1] - p1[1]) * (p1[0] - p3[0])) /
    denominator;
  if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
    return true;
  }
  return false;
}

export default EditStairsPopup;
