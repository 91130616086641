import itemDiscount from "../data/itemDiscount";
import {
  getSubTotal,
  getTotalWithoutPercentDiscounts,
  roundToHundreth,
} from "../partsList";

export function percentDiscountPresent(itemList) {
  const discounts = getPercentDiscounts();

  for (let i = 0, len = discounts.length; i < len; i++) {
    if (itemList[discounts[i].BarCodeValue]) {
      return true;
    }
  }

  return false;
}

export function flatDiscountPresent(itemList) {
  const discounts = getFlatDiscounts();

  for (let i = 0, len = discounts.length; i < len; i++) {
    const upc =
      discounts[i].BarCodeValue ||
      discounts[i].Name ||
      discounts[i].FullName ||
      discounts[i].upc;

    if (upc) {
      return true;
    }
  }

  return false;
}

export function getPercentDiscountUpcs() {
  const discounts = getPercentDiscounts();

  return discounts
    .map((discount) => {
      const upc =
        discount.BarCodeValue ||
        discount.Name ||
        discount.FullName ||
        discount.upc;

      return upc;
    })
    .reduce((values, upc) => {
      values[upc] = upc;

      return values;
    }, {});
}

export function addPercentDiscounts(itemList) {
  const discounts = getPercentDiscounts();

  discounts.forEach((discount) => {
    const upc =
      discount.BarCodeValue ||
      discount.Name ||
      discount.FullName ||
      discount.upc;

    if (itemList[upc]) {
      const percentage = itemList[upc].discountPercent / 100;
      itemList[upc].total = roundToHundreth(
        percentage * -1 * getTotalWithoutPercentDiscounts(itemList)
      );
    }
  });

  return itemList;
}

export function addFlatDiscount(itemList) {
  const discounts = getFlatDiscounts();

  discounts.forEach((discount) => {
    const upc =
      discount.BarCodeValue ||
      discount.Name ||
      discount.FullName ||
      discount.upc;

    if (itemList[upc]) {
      const item = itemList[upc];

      if (
        item?.otherDiscounts?.percent &&
        item?.otherDiscounts?.percent !== 0 &&
        item?.otherDiscounts?.percent !== ""
      ) {
        item.total = roundToHundreth(
          getSubTotal(itemList) *
            (item?.otherDiscounts?.percent
              ? item?.otherDiscounts?.percent / 100
              : 0) *
            item.quantity *
            -1
        );
        item.price = item.total;
      } else {
        item.total = item.price * item.quantity;
      }
    }
  });

  return itemList;
}

function getPercentDiscountsFromQB(fetched) {
  return function () {
    if (fetched) {
      return fetched;
    }

    const discounts =
      itemDiscount["QBXML"]["QBXMLMsgsRs"]["ItemDiscountQueryRs"][
        "ItemDiscountRet"
      ];

    fetched = discounts.filter((item) => {
      return item.DiscountRatePercent ? true : false;
    });

    return fetched;
  };
}

function getFlatDiscountsFromQB(fetched) {
  return function () {
    if (fetched) {
      return fetched;
    }

    const discounts =
      itemDiscount["QBXML"]["QBXMLMsgsRs"]["ItemDiscountQueryRs"][
        "ItemDiscountRet"
      ];

    fetched = discounts.filter((item) => {
      return !item.DiscountRatePercent ? true : false;
    });

    return fetched;
  };
}

export const getPercentDiscounts = getPercentDiscountsFromQB(false);
export const getFlatDiscounts = getFlatDiscountsFromQB(false);
