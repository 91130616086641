import {
  calculateNumPosts,
  degreesToRadians,
  isNearPoint,
  isPointBetweenPoints,
} from ".";
import { Project, Run } from "../entities";

export function calculatePostsForRunInsideOfStairs(run: Run, state: Project) {
  let angle = 0;
  const pointThreshold = 5;
  const { x1, y1, x2, y2 } = run;

  const endPost = (run.stairs as any).endPost;
  const startPost = (run.stairs as any).startPost;

  const theStairs = state.stairs.get((run.stairs as any).stairsIndex);

  if (theStairs) {
    angle = degreesToRadians(theStairs.angle);
  }

  const settings = state.settings;

  const stairsAngle = angle;

  const posts: { x: number; y: number; type: string }[] = [];

  // First post inside stairs.
  posts.push({ x: x1, y: y1, type: "stairPostTerminal" });

  // If end is inside stairs.
  if (isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost)) {
    // Add terminal post.
    posts.push({ x: x2, y: y2, type: "stairPostTerminal" });

    // Calculate and add intermediates.
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      settings,
      stairsAngle
    );

    const stairPosts = numPosts.posts;
    const xStairDistance = numPosts.xDistance;
    const yStairDistance = numPosts.yDistance;

    if (stairPosts) {
      for (let i = 1; i <= stairPosts; i++) {
        const newPost = {
          x: run.x1 + i * xStairDistance,
          y: run.y1 + i * yStairDistance,
          type: "stairPostIntermediate",
        };

        posts.push(newPost);
      }
    }
  } else {
    // If end is outside of stairs.
    // If endpost is same as end of run.
    if (isNearPoint({ x: x2, y: y2 }, endPost, pointThreshold)) {
      posts.push({ x: endPost.x, y: endPost.y, type: "stairPostTerminal" });
    }

    // If startpost is same as end of run.
    if (isNearPoint({ x: x2, y: y2 }, startPost, pointThreshold)) {
      posts.push({ x: startPost.x, y: startPost.y, type: "stairPostTerminal" });
    }

    // If endpost is between run and not at end of stairs.
    if (
      isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
      !isNearPoint({ x: x2, y: y2 }, endPost, pointThreshold)
    ) {
      posts.push({ x: endPost.x, y: endPost.y, type: "stairPostTransition" });
    }

    // If startpost is between run and not at start of stairs.
    if (
      isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
      !isNearPoint({ x: x2, y: y2 }, startPost, pointThreshold)
    ) {
      posts.push({
        x: startPost.x,
        y: startPost.y,
        type: "stairPostTransition",
      });
    }

    // Add intermediate stairs posts.
    let stairPosts, xStairDistance, yStairDistance;

    // If on stairs and moving over start post.
    if (isPointBetweenPoints(startPost, { x: x2, y: y2 }, { x: x1, y: y1 })) {
      const numPosts = calculateNumPosts(
        run
          .set("x1", x1)
          .set("y1", y1)
          .set("x2", startPost.x)
          .set("y2", startPost.y),
        settings,
        stairsAngle
      );

      stairPosts = numPosts.posts;
      xStairDistance = numPosts.xDistance;
      yStairDistance = numPosts.yDistance;
    }

    // If started on stairs but moving past endpost.
    if (isPointBetweenPoints(endPost, { x: x2, y: y2 }, { x: x1, y: y1 })) {
      const numPosts = calculateNumPosts(
        run
          .set("x1", x1)
          .set("y1", y1)
          .set("x2", endPost.x)
          .set("y2", endPost.y),
        settings,
        stairsAngle
      );

      stairPosts = numPosts.posts;
      xStairDistance = numPosts.xDistance;
      yStairDistance = numPosts.yDistance;
    }

    // Add intermediate stair posts.
    if (stairPosts && xStairDistance && yStairDistance) {
      for (let i = 1; i <= stairPosts; i++) {
        const newPost = {
          x: run.x1 + i * xStairDistance,
          y: run.y1 + i * yStairDistance,
          type: "stairPostIntermediate",
        };

        posts.push(newPost);
      }
    }

    let endPosts, xEndDistance, yEndDistance: number | undefined;

    if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
      const numPosts = calculateNumPosts(
        run
          .set("x1", endPost.x)
          .set("y1", endPost.y)
          .set("x2", x2)
          .set("y2", y2),
        settings
      );

      endPosts = numPosts.posts;
      xEndDistance = numPosts.xDistance;
      yEndDistance = numPosts.yDistance;
    }

    if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
      const numPosts = calculateNumPosts(
        run
          .set("x1", startPost.x)
          .set("y1", startPost.y)
          .set("x2", x2)
          .set("y2", y2),
        settings
      );

      endPosts = numPosts.posts;
      xEndDistance = numPosts.xDistance;
      yEndDistance = numPosts.yDistance;
    }

    if (endPosts && xEndDistance && yEndDistance) {
      for (let i = 1; i <= endPosts; i++) {
        const newPost = {
          x: run.x1 + i * xEndDistance,
          y: run.y1 + i * yEndDistance,
          type: "intermediate",
        };

        posts.push(newPost);
      }
    }

    // Add final terminal posts.
    // If terminal post is past endpost.
    if (
      !isNearPoint({ x: x2, y: y2 }, endPost, pointThreshold) &&
      isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })
    ) {
      posts.push({ x: x2, y: y2, type: "terminal" });
    }

    // If terminal post is past start post.
    if (
      !isNearPoint({ x: x2, y: y2 }, startPost, pointThreshold) &&
      isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })
    ) {
      posts.push({ x: x2, y: y2, type: "terminal" });
    }
  }
  return posts;
}
