import { Map } from "immutable";
import { Handrail, Point, Post, Run, Shape, Stairs } from "../../entities";
import { DefaultWindowState, defaultWindowState } from "../utils";

export type WindowStateReducerState = DefaultWindowState;
export type WindowStateReducerAction =
  | { type: "app/open-project" }
  | { type: "window/open-save-override" }
  | { type: "window/close-save-override" }
  | { type: "window/open-project-frozen-dialog" }
  | { type: "window/close-project-frozen-dialog" }
  | { type: "window/open-add-hardware-run"; run: Run }
  | { type: "window/close-add-hardware-run" }
  | { type: "window/open-add-hardware-post"; post: Post }
  | { type: "window/close-add-hardware-post" }
  | { type: "window/open-add-hardware-canvas" }
  | { type: "window/close-add-hardware-canvas" }
  | { type: "window/open-shipping-estimate" }
  | { type: "window/close-shipping-estimate" }
  | { type: "window/open-image-drop" }
  | { type: "window/close-image-drop" }
  | { type: "window/open-run-length"; run: Run; point: Point }
  | { type: "window/open-handrail-length"; handrail: Handrail; point: Point }
  | { type: "window/close-run-length" }
  | { type: "window/close-handrail-length" }
  | { type: "window/open-line-length"; shape: Shape; point: Point }
  | { type: "window/close-line-length" }
  | {
      type: "window/open-stairs-length";
      stairs: Stairs;
      point: Point;
      "point-type": number;
    }
  | { type: "window/close-stairs-length" }
  | { type: "runs/remove"; runIndex: string }
  | { type: "window/error-message-open"; message: string }
  | { type: "window/error-message-close" }
  | { type: "window/generate-pdf-open"; message: string }
  | {
      type: "window/generate-pdf-ready";
      message: string;
      pdfType: string;
      download: any;
    }
  | { type: "window/generate-pdf-close" }
  | {
      type: "window/export-sales-order-dialog-open";
      resolve: () => void;
      reject: () => void;
    }
  | { type: "window/export-sales-order-dialog-close" };

export function windowStateReducer(
  windowState: WindowStateReducerState = defaultWindowState,
  action: WindowStateReducerAction
): WindowStateReducerState {
  switch (action.type) {
    case "app/open-project":
      return defaultWindowState;
    case "window/open-save-override":
      return windowState.setIn(["save-override", "open"], true);
    case "window/close-save-override":
      return windowState.setIn(["save-override", "open"], false);
    case "window/open-project-frozen-dialog":
      return windowState.setIn(["project-frozen-dialog", "open"], true);
    case "window/close-project-frozen-dialog":
      return windowState.setIn(["project-frozen-dialog", "open"], false);
    case "window/open-add-hardware-run":
      return windowState
        .setIn(["add-hardware-run", "run"], action.run)
        .setIn(["add-hardware-run", "open"], true);
    case "window/close-add-hardware-run":
      return windowState
        .setIn(["add-hardware-run", "run"], {})
        .setIn(["add-hardware-run", "open"], false);
    case "window/open-add-hardware-post":
      return windowState
        .setIn(["add-hardware-post", "post"], action.post)
        .setIn(["add-hardware-post", "open"], true);
    case "window/close-add-hardware-post":
      return windowState
        .setIn(["add-hardware-post", "post"], {})
        .setIn(["add-hardware-post", "open"], false);
    case "window/open-add-hardware-canvas":
      return windowState.setIn(["add-hardware-canvas", "open"], true);
    case "window/close-add-hardware-canvas":
      return windowState
        .setIn(["add-hardware-canvas", "canvas"], {})
        .setIn(["add-hardware-canvas", "open"], false);
    case "window/open-shipping-estimate":
      return windowState.setIn(["shipping-estimate", "open"], true);
    case "window/close-shipping-estimate":
      return windowState.setIn(["shipping-estimate", "open"], false);
    case "window/open-image-drop":
      return windowState.setIn(["add-image-drop"], true);
    case "window/close-image-drop":
      return windowState.setIn(["add-image-drop"], false);
    case "window/open-run-length":
      return windowState
        .setIn(["edit-run-length", "open"], true)
        .setIn(["edit-run-length", "run"], action.run)
        .setIn(["edit-run-length", "point"], action.point);
    case "window/open-handrail-length":
      return windowState
        .setIn(["edit-handrail-length", "open"], true)
        .setIn(["edit-handrail-length", "handrail"], action.handrail)
        .setIn(["edit-handrail-length", "point"], action.point);
    case "window/close-run-length":
      return windowState.set("edit-run-length", Map());
    case "window/close-handrail-length":
      return windowState.set("edit-handrail-length", Map());
    case "window/open-line-length":
      return windowState
        .setIn(["edit-line-length", "open"], true)
        .setIn(["edit-line-length", "shape"], action.shape)
        .setIn(["edit-line-length", "point"], action.point);
    case "window/close-line-length":
      return windowState.set("edit-line-length", Map());
    case "window/open-stairs-length":
      return windowState
        .setIn(["edit-stairs-length", "open"], true)
        .setIn(["edit-stairs-length", "stairs"], action.stairs)
        .setIn(["edit-stairs-length", "point-type"], action["point-type"])
        .setIn(["edit-stairs-length", "point"], action.point);
    case "window/close-stairs-length":
      return windowState.set("edit-stairs-length", Map());
    case "runs/remove":
      const run: any = windowState.getIn(["edit-run-length", "run"]);
      if (run && run?.id === action.runIndex) {
        return windowState.set("edit-run-length", Map());
      } else {
        return windowState;
      }
    case "window/error-message-open":
      return windowState
        .setIn(["error-message", "open"], true)
        .setIn(["error-message", "message"], action.message);
    case "window/error-message-close":
      return windowState
        .setIn(["error-message", "open"], false)
        .setIn(["error-message", "message"], "");
    case "window/generate-pdf-open":
      return windowState
        .setIn(["generate-pdf", "open"], true)
        .setIn(["generate-pdf", "download"], "")
        .setIn(["generate-pdf", "message"], action.message)
        .setIn(["generate-pdf", "type"], "");
    case "window/generate-pdf-ready":
      return windowState
        .setIn(["generate-pdf", "open"], true)
        .setIn(["generate-pdf", "download"], action.download)
        .setIn(["generate-pdf", "message"], action.message)
        .setIn(["generate-pdf", "type"], action.pdfType);
    case "window/generate-pdf-close":
      return windowState
        .setIn(["generate-pdf", "open"], false)
        .setIn(["generate-pdf", "message"], "");
    case "window/export-sales-order-dialog-open":
      return windowState
        .setIn(["export-sales-order-dialog", "open"], true)
        .setIn(["export-sales-order-dialog", "resolve"], action.resolve)
        .setIn(["export-sales-order-dialog", "reject"], action.reject);
    case "window/export-sales-order-dialog-close":
      return windowState.set("export-sales-order-dialog", Map({ open: false }));
    default:
      return windowState;
  }
}
