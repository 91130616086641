import { useCallback, useRef, useState } from "react";
import { usePagesFetcher } from "./usePagesFetcher";

function useListLoader<T>({
  countEndpoint,
  dataEndpoint,
  itemParser,
}: {
  countEndpoint: string;
  dataEndpoint: string;
  itemParser: (json: any) => T;
}) {
  const [items, setItems] = useState<T[]>([]);
  const [error, setError] = useState<any>(null);
  const [isFetching, setIsFetching] = useState<any>(null);

  const queryParamsBeingFetched = useRef<Record<string, string> | null>(null);
  const lastQueryParams = useRef<Record<string, string> | null>(null);
  const pageCount = useRef<number | null>(null);
  const page = useRef(1);
  const abortController = useRef<AbortController>(new AbortController());

  const { fetchPageCount, fetchPage } = usePagesFetcher({
    countEndpoint,
    dataEndpoint,
    itemParser,
  });

  const fetchItems = useCallback(
    async (queryParams: Record<string, string>) => {
      if (
        JSON.stringify(queryParamsBeingFetched.current) ===
        JSON.stringify(queryParams)
      ) {
        return;
      }
      queryParamsBeingFetched.current = queryParams;

      abortController.current.abort();
      abortController.current = new AbortController();

      const loadMore =
        JSON.stringify(queryParams) === JSON.stringify(lastQueryParams.current);

      if (loadMore) {
        page.current += 1;
      } else {
        setItems([]);
        page.current = 1;
        try {
          pageCount.current = await fetchPageCount(queryParams);
        } catch (error) {
          setError(error);
          return;
        }
        lastQueryParams.current = queryParams;
      }

      if (!pageCount.current || page.current > pageCount.current) return;

      setIsFetching(true);

      try {
        const newItems = await fetchPage(
          { page: `${page.current}`, ...queryParams },
          abortController.current
        );

        if (loadMore) {
          setItems((prevItems) => [...prevItems, ...newItems]);
        } else {
          setItems(newItems);
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsFetching(false);
        queryParamsBeingFetched.current = null;
      }
    },
    [fetchPageCount, setError, setItems]
  );

  return {
    items,
    setItems,
    fetchPageCount,
    fetchPage,
    fetchItems,
    isFetching,
    error,
  };
}

export default useListLoader;
