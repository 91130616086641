import { useState, useEffect, useMemo, useRef } from "react";

interface PropTypes<T> {
  items: T[];
  isMatchingSearchTerm: (item: T, searchTerm: string) => boolean;
  renderListItem: (
    item: T,
    index: number,
    setThirdToLastItem: ((element: HTMLDivElement) => void) | undefined
  ) => React.ReactNode;
  noResultsMessage: () => React.ReactNode;
  searchTerm: string;
  defaultOpen?: boolean;
}

const LazyList = <T extends object>({
  items,
  isMatchingSearchTerm,
  renderListItem,
  noResultsMessage,
  searchTerm,
  defaultOpen = false,
}: PropTypes<T>) => {
  const chunkSize = 20;
  const [listItems, setListItems] = useState<T[]>([]);
  const thirdToLastElement = useRef<HTMLElement>();

  const observer = useRef<IntersectionObserver | null>();
  const listSize = useRef(chunkSize);

  const matchingItems = useMemo(() => {
    if (searchTerm || searchTerm?.length || defaultOpen) {
      return items.filter((element) =>
        isMatchingSearchTerm(element, searchTerm)
      );
    } else {
      return [];
    }
  }, [items, searchTerm, isMatchingSearchTerm, defaultOpen]);

  useEffect(() => {
    setListItems(matchingItems.slice(0, listSize.current));
  }, [matchingItems]);

  useEffect(
    () => {
      listSize.current = chunkSize;
    },
    typeof searchTerm === "string"
      ? [searchTerm]
      : Array.from(Object.values(searchTerm))
  );

  const setThirdToLastElement = (currentElement: HTMLElement) => {
    if (thirdToLastElement.current && observer.current) {
      observer.current.unobserve(thirdToLastElement.current);
    }

    if (currentElement) {
      observer.current = new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          if (listItems.length < matchingItems.length) {
            const newValue = [
              ...listItems,
              ...matchingItems.slice(
                listItems.length,
                listItems.length + chunkSize
              ),
            ];

            listSize.current = newValue.length;
            setListItems(newValue);
          }
        }
      });

      observer.current.observe(currentElement);
      thirdToLastElement.current = currentElement;
    }
  };

  return (
    <div className="list">
      <div
        className={`list__elements ${
          !listItems.length && "list__elements--empty"
        }`}
      >
        {listItems.length || !searchTerm.length
          ? listItems.map((listItem, index) =>
              renderListItem(
                listItem,
                index,
                index === listItems.length - 3
                  ? setThirdToLastElement
                  : undefined
              )
            )
          : noResultsMessage()}
      </div>
    </div>
  );
};

export default LazyList;
