// @ts-nocheck
import { isImmutable, Map } from "immutable";

import {
  getDistance,
  calculateNumPosts,
  hexToRgb,
  isRunSettingsEmpty,
  distanceInFeet,
  roundToHundreth,
  isPointBetweenPoints,
  isNearPoint,
  getHypotenuseInFeetObject,
  toRadians,
  distanceOfHypotenuse,
  getRunSettings,
  mergeSettings,
  isCloseToValue,
  getIntersectionOfLineSegments,
  getClosestPointOnLine,
  distanceInFeetObjectFromDistance,
  getStairCornersForSnapLines,
  degreesToRadians,
  pixelsPerFoot,
} from "../utils";

import { getRunIndex } from "../utils/canvas";
import { calculateCorners } from "../utils/corners";
import { getPosts, PostInfo } from "../utils/getPosts";
import { Run, Stairs } from "../entities";

export const drawNote = (p5) => (note) => {
  const text = note.text;
  const fontSize = parseInt(note.fontSize.replace("px", ""), 10);

  const widthRatio = 9 / 16;
  const heightRatio = 22 / 16;

  const charWidth = fontSize * widthRatio;
  const charHeight = fontSize * heightRatio;

  let lineCount = 0;

  const lines = text.split("\n");

  const longestLine = lines.reduce((longest, line) => {
    if (line.length > longest) {
      return line.length;
    }

    return longest;
  }, 0);

  lineCount = lines.length;

  const { x, y } = note;

  const newY = y - charHeight;

  const x2 = x + longestLine * charWidth;
  const y2 = newY + charHeight * lineCount;

  p5.push();
  p5.rectMode(p5.CORNER);
  p5.strokeWeight(0);
  // Add 10 px padding.
  p5.translate(x - 10, newY);
  p5.fill(255, 255, 255, 200);
  p5.rect(0, 0, x2 - x + 20, y2 - newY + 10);
  p5.pop();

  p5.push();
  p5.translate(note.x, note.y);
  p5.strokeWeight(1);
  p5.textSize(fontSize);
  p5.textFont("monospace");
  p5.text(note.text, 0, 0);
  p5.pop();
};

export const drawGrid = (p5, pan, currentPan, scale) => {
  const width = window.innerWidth / scale;
  const height = window.innerHeight / scale;

  p5.push();
  p5.fill(0, 0, 0, 50);
  p5.stroke(0, 0, 0, 5);
  p5.rectMode(p5.CENTER);
  for (let x = 0; x < width; x = x + 25) {
    for (let y = 0; y < height; y = y + 25) {
      let xValue = x;
      let yValue = y;

      if (yValue + pan.y + currentPan.y > height) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y - height) / 25) * 25 * -1;
      }

      if (yValue + pan.y + currentPan.y < 0) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y) / 25) * 25 * -1 +
          Math.round(height / 25) * 25;
      }

      if (xValue + pan.x + currentPan.x > width) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x - width) / 25) * 25 * -1;
      }

      if (xValue + pan.x + currentPan.x < 0) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x) / 25) * 25 * -1 +
          Math.round(width / 25) * 25;
      }
      p5.circle(xValue, yValue, 2);
    }
  }
  for (let x = 0; x > -1 * width; x = x - 25) {
    for (let y = 0; y > -1 * height; y = y - 25) {
      let xValue = x;
      let yValue = y;

      if (yValue + pan.y + currentPan.y < height) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y - height) / 25) * 25 * -1;
      }

      if (yValue + pan.y + currentPan.y > 0) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y) / 25) * 25 * -1 +
          Math.round(height / 25) * 25;
      }

      if (xValue + pan.x + currentPan.x < width) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x - width) / 25) * 25 * -1;
      }

      if (xValue + pan.x + currentPan.x > 0) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x) / 25) * 25 * -1 +
          Math.round(width / 25) * 25;
      }
      p5.circle(xValue, yValue, 2);
    }
  }
  for (let x = 0; x < width; x = x + 25) {
    for (let y = 0; y > -1 * height; y = y - 25) {
      let xValue = x;
      let yValue = y;

      if (yValue + pan.y + currentPan.y < height) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y - height) / 25) * 25 * -1;
      }

      if (yValue + pan.y + currentPan.y > 0) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y) / 25) * 25 * -1 +
          Math.round(height / 25) * 25;
      }

      if (xValue + pan.x + currentPan.x > width) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x - width) / 25) * 25 * -1;
      }

      if (xValue + pan.x + currentPan.x < 0) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x) / 25) * 25 * -1 +
          Math.round(width / 25) * 25;
      }
      p5.circle(xValue, yValue, 2);
    }
  }

  for (let x = 0; x > -1 * width; x = x - 25) {
    for (let y = 0; y < height; y = y + 25) {
      let xValue = x;
      let yValue = y;

      if (yValue + pan.y + currentPan.y > height) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y - height) / 25) * 25 * -1;
      }

      if (yValue + pan.y + currentPan.y < 0) {
        yValue =
          Math.round((yValue + pan.y + currentPan.y) / 25) * 25 * -1 +
          Math.round(height / 25) * 25;
      }

      if (xValue + pan.x + currentPan.x < width) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x - width) / 25) * 25 * -1;
      }

      if (xValue + pan.x + currentPan.x > 0) {
        xValue =
          Math.round((xValue + pan.x + currentPan.x) / 25) * 25 * -1 +
          Math.round(width / 25) * 25;
      }
      p5.circle(xValue, yValue, 2);
    }
  }
  p5.pop();
};

export const drawImage =
  (p5) =>
  (image, images, scale, hovering = false) => {
    const opacity =
      typeof image.properties.opacity !== "undefined"
        ? image.properties.opacity
        : 1;

    let width = image.width;
    let height = image.height;

    let gl = p5.drawingContext;

    if (images[image.url] && gl) {
      gl.save();
      gl.globalAlpha = opacity;
      gl.translate(image.point.x, image.point.y);
      gl.rotate((image.rotate * Math.PI) / 180);
      gl.translate(-width / 2, -height / 2);
      try {
        gl.drawImage(images[image.url], 0, 0, width, height);
      } catch (e) {
        console.log(e);
      }
      gl.restore();

      if (hovering) {
        p5.push();
        p5.rectMode(p5.CENTER);
        p5.angleMode(p5.DEGREES);
        p5.strokeWeight(2);
        p5.fill(172, 15, 105, 0);
        p5.stroke(0, 163, 255, 255);
        p5.translate(image.point.x, image.point.y);
        p5.rotate(image.rotate);
        p5.rect(0, 0, width, height);
        p5.pop();
      }
    }
  };

export const drawStairs = (p5) => (stairs) => {
  const { x1, y1, x2, y2 } = stairs;

  p5.push();

  p5.fill(172, 15, 105, 5);
  p5.stroke(172, 15, 105, 102);

  p5.rectMode(p5.CORNER);

  if (x2 > x1 && y2 > y1) {
    p5.rect(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
  }

  if (x2 < x1 && y2 > y1) {
    p5.rect(x2, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
  }

  if (x2 > x1 && y2 < y1) {
    p5.rect(x1, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
  }

  if (x2 < x1 && y2 < y1) {
    p5.rect(x2, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
  }
  p5.pop();

  const showLabels = stairs.getIn(["labels", "showLabels"]);

  if (showLabels) {
    // Draw midpoint length measurement.
    const midpoint1 = {
      x: Math.floor((x2 - x1) / 2),
      y: y2,
    };

    const midpoint2 = {
      x: x2,
      y: Math.floor((y2 - y1) / 2),
    };

    let distance1 = distanceInFeet({ x1: x1, x2: x2, y1: y1, y2: y1 });
    let distance2 = distanceInFeet({ x1: x2, x2: x2, y1: y1, y2: y2 });

    if (stairs.orientation === "vertical") {
      distance2 = stairsHypotenuseInFeet(stairs) + " H";
    } else if (stairs.orientation === "horizontal") {
      distance1 = stairsHypotenuseInFeet(stairs) + " H";
    }

    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);
    p5.translate(midpoint1.x, -15);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance1, 0, 0);
    p5.pop();

    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x2, y1);
    p5.translate(15, midpoint2.y);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance2, 0, 0);
    p5.pop();
  }
};

function getConnectedRunsForStairs(stairs, runs) {
  if (runs && runs.size) {
    return runs.filter((run) => {
      if (run.stairs && run.stairs.stairsIndex === stairs.id) {
        return true;
      }

      if (run.stairs && run.stairs.continuousStairs) {
        if (isImmutable(run.stairs.continuousStairs)) {
          if (run.stairs.continuousStairs.has(stairs.id)) {
            return true;
          }
        } else {
          if (run.stairs.continuousStairs[stairs.id]) {
            return true;
          }
        }
      }

      return false;
    });
  }

  return Map();
}

export const drawCompletedStairs = (p5) => (stairs, projectSettings, state) => {
  let { x1, y1, x2, y2 } = stairs;

  const connectedRuns = getConnectedRunsForStairs(stairs, state.runs);

  let fasciaSideLeft = null;
  let fasciaSideRight = null;

  if (connectedRuns.size) {
    connectedRuns.forEach((run) => {
      const runSettings = getRunSettings(run, projectSettings);

      const settings = mergeSettings(projectSettings, runSettings);

      if (settings.mountStyle === "fascia") {
        if (stairs.orientation === "vertical") {
          if (run.stairs.keys.start === "BL" && run.stairs.keys.end === "TL") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "TL" && run.stairs.keys.end === "BL") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "BR" && run.stairs.keys.end === "TR") {
            fasciaSideRight = run;
          }

          if (run.stairs.keys.start === "TR" && run.stairs.keys.end === "BR") {
            fasciaSideRight = run;
          }
        } else if (stairs.orientation === "horizontal") {
          if (run.stairs.keys.start === "BL" && run.stairs.keys.end === "BR") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "BR" && run.stairs.keys.end === "BL") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "TR" && run.stairs.keys.end === "TL") {
            fasciaSideRight = run;
          }

          if (run.stairs.keys.start === "TL" && run.stairs.keys.end === "TR") {
            fasciaSideRight = run;
          }
        }
      }
    });
  }

  if (x2 > x1 && y2 > y1) {
    // Adjust coordinates.
    x1 = stairs.x1;
    x2 = stairs.x2;
    y1 = stairs.y1;
    y2 = stairs.y2;
  }

  if (x2 < x1 && y2 > y1) {
    x1 = stairs.x2;
    x2 = stairs.x1;
    y1 = stairs.y1;
    y2 = stairs.y2;
  }

  if (x2 > x1 && y2 < y1) {
    x1 = stairs.x1;
    x2 = stairs.x2;
    y1 = stairs.y2;
    y2 = stairs.y1;
  }

  if (x2 < x1 && y2 < y1) {
    x1 = stairs.x2;
    x2 = stairs.x1;
    y1 = stairs.y2;
    y2 = stairs.y1;
  }

  p5.push();

  if (!stairs.isRamp) {
    p5.fill(172, 15, 105, 5);
    p5.stroke(172, 15, 105, 102);
  } else {
    p5.fill(255, 154, 0, 5);
    p5.stroke(255, 154, 0, 102);
  }

  p5.rectMode(p5.CORNER);
  p5.rectMode(p5.CENTER);

  const dy = Math.abs(y2 - y1);
  const dx = Math.abs(x2 - x1);

  let numberOfStairs = Math.floor(dy / 20);

  const orientation = stairs.orientation;

  const rotationAxis = stairs.getIn(["rotate", "axis"]);
  const angle = stairs.rotate.get("angle");

  if (orientation === "horizontal") {
    numberOfStairs = Math.floor(dx / 20);
  }

  p5.angleMode(p5.DEGREES);

  let pivot = {
    x: (x2 - x1) / 2,
    y: (y2 - y1) / 2,
  };

  let lineStrokeColor = "rgba(172, 15, 105, 0.4)";

  if (stairs.isRamp) {
    lineStrokeColor = "rgba(172, 15, 105, 0)";
  }

  if (x2 > x1 && y2 > y1) {
    p5.translate(x1, y1);
    p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);

    if (rotationAxis === "top-right") {
      pivot = {
        x: (x2 - x1) / 2,
        y: -1 * ((y2 - y1) / 2),
      };
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "top-left") {
      pivot = {
        x: -1 * ((x2 - x1) / 2),
        y: -1 * ((y2 - y1) / 2),
      };

      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      pivot = {
        x: -1 * ((x2 - x1) / 2),
        y: (y2 - y1) / 2,
      };

      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      pivot = {
        x: (x2 - x1) / 2,
        y: (y2 - y1) / 2,
      };

      p5.translate(pivot.x, pivot.y);
    }

    p5.rotate(angle);

    if (rotationAxis === "top-right") {
      p5.translate(-1 * pivot.x, -1 * pivot.y);
    }

    if (rotationAxis === "top-left") {
      p5.translate(-1 * pivot.x, -1 * pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(-1 * pivot.x, -1 * pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(-1 * pivot.x, -1 * pivot.y);
    }

    if (fasciaSideLeft && fasciaSideRight) {
      const rectStart = 10;
      if (orientation === "vertical") {
        p5.rect(0, 0, Math.abs(x2 - x1) - rectStart * 2, Math.abs(y2 - y1));
      }

      if (orientation === "horizontal") {
        p5.rect(0, 0, Math.abs(x2 - x1), Math.abs(y2 - y1) - rectStart * 2);
      }

      p5.push();
      p5.translate(-1 * (Math.abs(x2 - x1) / 2), -1 * (Math.abs(y2 - y1) / 2));

      if (orientation === "vertical") {
        for (let i = 1; i < numberOfStairs; i++) {
          const yDistance = dy / numberOfStairs;
          p5.stroke(lineStrokeColor);
          p5.line(rectStart, i * yDistance, x2 - x1 - rectStart, i * yDistance);
        }
      }

      if (orientation === "horizontal") {
        for (let i = 1; i < numberOfStairs; i++) {
          const xDistance = dx / numberOfStairs;
          p5.stroke(lineStrokeColor);
          p5.line(i * xDistance, rectStart, i * xDistance, y2 - y1 - rectStart);
        }
      }
      p5.pop();
    } else {
      if (fasciaSideLeft) {
        const rectStart = 10;
        if (orientation === "vertical") {
          p5.translate(5, 0);
          p5.rect(0, 0, Math.abs(x2 - x1) - rectStart, Math.abs(y2 - y1));
        }

        if (orientation === "horizontal") {
          p5.translate(0, -5);
          p5.rect(0, 0, Math.abs(x2 - x1), Math.abs(y2 - y1) - rectStart);
        }

        p5.push();
        p5.translate(
          -1 * (Math.abs(x2 - x1) / 2),
          -1 * (Math.abs(y2 - y1) / 2)
        );
        if (orientation === "vertical") {
          for (let i = 1; i < numberOfStairs; i++) {
            const yDistance = dy / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(
              rectStart / 2,
              i * yDistance,
              x2 - x1 - rectStart / 2,
              i * yDistance
            );
          }
        }

        if (orientation === "horizontal") {
          for (let i = 1; i < numberOfStairs; i++) {
            const xDistance = dx / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(
              i * xDistance,
              rectStart / 2,
              i * xDistance,
              y2 - y1 - rectStart / 2
            );
          }
        }
        p5.pop();
      } else if (fasciaSideRight) {
        const rectStart = 10;

        if (orientation === "vertical") {
          p5.translate(-5, 0);
          p5.rect(0, 0, Math.abs(x2 - x1) - rectStart, Math.abs(y2 - y1));
        }

        if (orientation === "horizontal") {
          p5.translate(0, 5);
          p5.rect(0, 0, Math.abs(x2 - x1), Math.abs(y2 - y1) - rectStart);
        }

        p5.push();
        p5.translate(
          -1 * (Math.abs(x2 - x1) / 2),
          -1 * (Math.abs(y2 - y1) / 2)
        );
        if (orientation === "vertical") {
          for (let i = 1; i < numberOfStairs; i++) {
            const yDistance = dy / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(
              rectStart / 2,
              i * yDistance,
              x2 - x1 - rectStart / 2,
              i * yDistance
            );
          }
        }

        if (orientation === "horizontal") {
          for (let i = 1; i < numberOfStairs; i++) {
            const xDistance = dx / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(
              i * xDistance,
              rectStart / 2,
              i * xDistance,
              y2 - y1 - rectStart / 2
            );
          }
        }
        p5.pop();
      } else {
        p5.rect(0, 0, Math.abs(x2 - x1), Math.abs(y2 - y1));

        p5.push();
        p5.translate(
          -1 * (Math.abs(x2 - x1) / 2),
          -1 * (Math.abs(y2 - y1) / 2)
        );
        if (orientation === "vertical") {
          for (let i = 1; i < numberOfStairs; i++) {
            const yDistance = dy / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(0, i * yDistance, x2 - x1, i * yDistance);
          }
        }

        if (orientation === "horizontal") {
          for (let i = 1; i < numberOfStairs; i++) {
            const xDistance = dx / numberOfStairs;
            p5.stroke(lineStrokeColor);
            p5.line(i * xDistance, 0, i * xDistance, y2 - y1);
          }
        }
        p5.pop();
      }
    }
  }

  p5.pop();

  // Draw midpoint length measurement.
  let distance1 = distanceInFeet({ x1: x1, x2: x2, y1: y1, y2: y1 });
  let distance2 = distanceInFeet({ x1: x2, x2: x2, y1: y1, y2: y2 });

  if (stairs.orientation === "vertical") {
    distance2 = stairsHypotenuseInFeet(stairs) + " H";
  } else if (stairs.orientation === "horizontal") {
    distance1 = stairsHypotenuseInFeet(stairs) + " H";
  }

  const showLabels = stairs.getIn(["labels", "showLabels"]);

  if (showLabels) {
    const distanceVerticalLabelsSide = stairs.getIn([
      "labels",
      // For stairs the distance label is used for vertical end.
      "distanceLabel",
    ]);

    let distanceVerticalLabelSide = 1;

    if (distanceVerticalLabelsSide) {
      distanceVerticalLabelSide = 1;
    } else {
      distanceVerticalLabelSide = -1;
    }

    const distanceHorizontalLabelsSide = stairs.getIn([
      "labels",
      "stairsHorizontalLabel",
    ]);

    let distanceHorizontalLabelSide = 1;

    if (distanceHorizontalLabelsSide) {
      distanceHorizontalLabelSide = 1;
    } else {
      distanceHorizontalLabelSide = -1;
    }

    p5.push();
    p5.angleMode(p5.DEGREES);
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);

    if (rotationAxis === "top-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "top-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    p5.rotate(angle);

    if (rotationAxis === "top-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "top-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, -1 * pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, -1 * pivot.y - distanceHorizontalLabelSide * 15);
    }

    p5.rotate(360 - angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance1, 0, 0);
    p5.pop();

    p5.push();
    p5.angleMode(p5.DEGREES);
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);

    if (rotationAxis === "top-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "top-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    p5.rotate(angle);

    if (rotationAxis === "top-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "top-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    p5.rotate(360 - angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance2, 0, 0);
    p5.pop();
  }
};

export const drawLanding = (p5) => (stairs, projectSettings, state) => {
  const connectedRuns = getConnectedRunsForStairs(stairs, state.runs);

  let fasciaSideLeft = null;
  let fasciaSideRight = null;
  let fasciaSideTop = null;
  let fasciaSideBottom = null;

  if (connectedRuns.size) {
    connectedRuns.forEach((run) => {
      const runSettings = getRunSettings(run, projectSettings);

      const settings = mergeSettings(projectSettings, runSettings);

      const keys = run.stairs.keys;
      const { start, end } = keys;

      let orientation = "vertical";

      const startVertical = start[0];
      const startHorizontal = start[1];

      const endVertical = end[0];
      const endHorizontal = end[1];

      if (startVertical === endVertical) {
        orientation = "horizontal";
      }

      if (startHorizontal === endHorizontal) {
        orientation = "vertical";
      }

      if (settings.mountStyle === "fascia") {
        if (orientation === "vertical") {
          if (run.stairs.keys.start === "BL" && run.stairs.keys.end === "TL") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "TL" && run.stairs.keys.end === "BL") {
            fasciaSideLeft = run;
          }

          if (run.stairs.keys.start === "BR" && run.stairs.keys.end === "TR") {
            fasciaSideRight = run;
          }

          if (run.stairs.keys.start === "TR" && run.stairs.keys.end === "BR") {
            fasciaSideRight = run;
          }
        } else if (orientation === "horizontal") {
          if (run.stairs.keys.start === "BL" && run.stairs.keys.end === "BR") {
            fasciaSideBottom = run;
          }

          if (run.stairs.keys.start === "BR" && run.stairs.keys.end === "BL") {
            fasciaSideBottom = run;
          }

          if (run.stairs.keys.start === "TR" && run.stairs.keys.end === "TL") {
            fasciaSideTop = run;
          }

          if (run.stairs.keys.start === "TL" && run.stairs.keys.end === "TR") {
            fasciaSideTop = run;
          }
        }
      }
    });
  }

  let { x1, y1, x2, y2 } = stairs;

  if (x2 > x1 && y2 > y1) {
    // Adjust coordinates.
    x1 = stairs.x1;
    x2 = stairs.x2;
    y1 = stairs.y1;
    y2 = stairs.y2;
  }

  if (x2 < x1 && y2 > y1) {
    x1 = stairs.x2;
    x2 = stairs.x1;
    y1 = stairs.y1;
    y2 = stairs.y2;
  }

  if (x2 > x1 && y2 < y1) {
    x1 = stairs.x1;
    x2 = stairs.x2;
    y1 = stairs.y2;
    y2 = stairs.y1;
  }

  if (x2 < x1 && y2 < y1) {
    x1 = stairs.x2;
    x2 = stairs.x1;
    y1 = stairs.y2;
    y2 = stairs.y1;
  }

  p5.push();

  p5.fill(172, 15, 105, 5);
  p5.stroke(172, 15, 105, 102);

  p5.rectMode(p5.CORNER);

  p5.angleMode(p5.DEGREES);

  const rotationAxis = stairs.rotate.get("axis");

  let pivot = {
    x: (x2 - x1) / 2,
    y: (y2 - y1) / 2,
  };

  const rectStart = 10;

  let newX1 = 0;
  let newY1 = 0;

  let shiftX = 0;
  let shiftY = 0;

  const angle = stairs.rotate.get("angle");

  let dx = Math.abs(x2 - x1);
  let dy = Math.abs(y2 - y1);

  p5.push();
  p5.translate(x1, y1);
  p5.translate(dx / 2, dy / 2);

  if (rotationAxis === "top-right") {
    pivot = {
      x: (x2 - x1) / 2,
      y: -1 * ((y2 - y1) / 2),
    };
    p5.translate(pivot.x, pivot.y);
  }

  if (rotationAxis === "top-left") {
    pivot = {
      x: -1 * ((x2 - x1) / 2),
      y: -1 * ((y2 - y1) / 2),
    };

    p5.translate(pivot.x, pivot.y);
  }

  if (rotationAxis === "bottom-left") {
    pivot = {
      x: -1 * ((x2 - x1) / 2),
      y: (y2 - y1) / 2,
    };

    p5.translate(pivot.x, pivot.y);
  }

  if (rotationAxis === "bottom-right") {
    pivot = {
      x: (x2 - x1) / 2,
      y: (y2 - y1) / 2,
    };

    p5.translate(pivot.x, pivot.y);
  }

  p5.rotate(angle);

  if (rotationAxis === "top-right") {
    p5.translate(-1 * pivot.x, -1 * pivot.y);
  }

  if (rotationAxis === "top-left") {
    p5.translate(-1 * pivot.x, -1 * pivot.y);
  }

  if (rotationAxis === "bottom-left") {
    p5.translate(-1 * pivot.x, -1 * pivot.y);
  }

  if (rotationAxis === "bottom-right") {
    p5.translate(-1 * pivot.x, -1 * pivot.y);
  }

  if (fasciaSideLeft) {
    newX1 = rectStart;

    shiftX = 1;
    dx = dx - rectStart;
  }

  if (fasciaSideRight) {
    if (shiftX === 1) {
      newX1 = newX1 - rectStart;
      shiftX = 0;
    }

    dx = dx - rectStart;
  }

  if (fasciaSideTop) {
    newY1 = rectStart;

    shiftY = 1;
    dy = dy - rectStart;
  }

  if (fasciaSideBottom) {
    if (shiftY) {
      newY1 = newY1 - rectStart;
      shiftY = 0;
    }

    dy = dy - rectStart;
  }

  p5.translate((-1 * dx) / 2, (-1 * dy) / 2);
  p5.translate(newX1, newY1);
  p5.rect(0, 0, dx, dy);
  p5.pop();

  p5.pop();

  const distance1 = distanceInFeet({ x1: x1, x2: x2, y1: y1, y2: y1 });
  const distance2 = distanceInFeet({ x1: x2, x2: x2, y1: y1, y2: y2 });

  const showLabels = stairs.getIn(["labels", "showLabels"]);

  if (showLabels) {
    const distanceVerticalLabelsSide = stairs.getIn([
      "labels",
      // For stairs the distance label is used for vertical end.
      "distanceLabel",
    ]);

    let distanceVerticalLabelSide = 1;

    if (distanceVerticalLabelsSide) {
      distanceVerticalLabelSide = 1;
    } else {
      distanceVerticalLabelSide = -1;
    }

    const distanceHorizontalLabelsSide = stairs.getIn([
      "labels",
      "stairsHorizontalLabel",
    ]);

    let distanceHorizontalLabelSide = 1;

    if (distanceHorizontalLabelsSide) {
      distanceHorizontalLabelSide = 1;
    } else {
      distanceHorizontalLabelSide = -1;
    }

    p5.push();
    p5.angleMode(p5.DEGREES);
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);

    if (rotationAxis === "top-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "top-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    p5.rotate(angle);

    if (rotationAxis === "top-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "top-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, -1 * pivot.y - distanceHorizontalLabelSide * 15);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(0, -1 * pivot.y - distanceHorizontalLabelSide * 15);
    }

    p5.rotate(360 - angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance1, 0, 0);
    p5.pop();

    p5.push();
    p5.angleMode(p5.DEGREES);
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);

    if (rotationAxis === "top-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "top-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(Math.abs(x2 - x1) / 2, Math.abs(y2 - y1) / 2);
      p5.translate(pivot.x, pivot.y);
    }

    p5.rotate(angle);

    if (rotationAxis === "top-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "top-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "bottom-right") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    if (rotationAxis === "bottom-left") {
      p5.translate(-pivot.x, -pivot.y);
      p5.translate(Math.abs(x2 - x1) / 2 + distanceVerticalLabelSide * 25, 0);
    }

    p5.rotate(360 - angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill(172, 15, 105);
    p5.text(distance2, 0, 0);
    p5.pop();
  }
};

export const drawShape = (p5) => (polygon) => {
  const { type, x1, y1, x2, y2, color } = polygon;

  const fillColor = color ? color : "#00A3FF";
  const fillRgb = color ? hexToRgb(color) : { r: 62, g: 185, b: 242 };
  const strokeColor = color ? color : "#00A3FF";

  if (type === "rectangle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    p5.rectMode(p5.CORNER);

    if (x2 > x1 && y2 > y1) {
      p5.rect(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 > y1) {
      p5.rect(x2, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 > x1 && y2 < y1) {
      p5.rect(x1, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 < y1) {
      p5.rect(x2, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }
    p5.pop();
  }

  if (type === "line") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);
    p5.strokeWeight(2);

    p5.line(x1, y1, x2, y2);

    p5.pop();

    // Calculate angle of rotation for posts.
    const angle = Math.atan((y2 - y1) / (x2 - x1));

    // Draw midpoint length measurement.
    const midpoint = {
      x: Math.floor((x2 - x1) / 2),
      y: Math.floor((y2 - y1) / 2),
    };

    const distance = distanceInFeet({ x1, y1, x2, y2 });

    const showLabels = polygon.getIn(["labels", "showLabels"]);
    const distanceLabelsSide = polygon.getIn(["labels", "distanceLabel"]);

    let distanceLabelSide = 1;

    if (distanceLabelsSide) {
      distanceLabelSide = 1;
    } else {
      distanceLabelSide = -1;
    }

    if (showLabels) {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(x1, y1);
      p5.translate(midpoint.x, midpoint.y);
      p5.translate(
        30 * distanceLabelSide * Math.sin(angle),
        -30 * distanceLabelSide * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(distance, 0, 0);
      p5.pop();
    }
  }

  if (type === "circle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    p5.ellipseMode(p5.CORNER);

    if (x2 > x1 && y2 > y1) {
      p5.ellipse(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 > y1) {
      p5.ellipse(x2, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 > x1 && y2 < y1) {
      p5.ellipse(x1, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 < y1) {
      p5.ellipse(x2, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    p5.pop();
  }

  if (type === "triangle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    if (x2 > x1) {
      p5.triangle(x1 + Math.abs(x2 - x1) / 2, y1, x1, y2, x2, y2);
    } else {
      p5.triangle(x2 + Math.abs(x2 - x1) / 2, y1, x1, y2, x2, y2);
    }

    p5.pop();
  }
};

export const drawPerfectShape = (p5) => (polygon) => {
  let { type, x1, y1, x2, y2, color } = polygon;

  const fillColor = color ? color : "#00A3FF";
  const fillRgb = color ? hexToRgb(color) : { r: 62, g: 185, b: 242 };
  const strokeColor = color ? color : "#00A3FF";

  if (type === "rectangle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    p5.rectMode(p5.CORNER);

    if (x2 > x1 && y2 > y1) {
      p5.rect(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 > y1) {
      p5.rect(x2, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 > x1 && y2 < y1) {
      p5.rect(x1, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 < y1) {
      p5.rect(x2, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }
    p5.pop();
  }

  if (type === "line") {
    const dy = Math.abs(y2 - y1);
    const dx = Math.abs(x2 - x1);

    if (dy > dx) {
      x2 = x1;
    } else {
      y2 = y1;
    }

    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);
    p5.strokeWeight(2);

    p5.line(x1, y1, x2, y2);

    p5.pop();

    // Calculate angle of rotation for posts.
    const angle = Math.atan((y2 - y1) / (x2 - x1));

    // Draw midpoint length measurement.
    const midpoint = {
      x: Math.floor((x2 - x1) / 2),
      y: Math.floor((y2 - y1) / 2),
    };

    const distance = distanceInFeet({ x1, y1, x2, y2 });

    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);
    p5.translate(midpoint.x, midpoint.y);
    p5.translate(30 * Math.sin(angle), -30 * Math.cos(angle));
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(distance, 0, 0);
    p5.pop();
  }

  if (type === "circle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    p5.ellipseMode(p5.CORNER);

    if (x2 > x1 && y2 > y1) {
      p5.ellipse(x1, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 > y1) {
      p5.ellipse(x2, y1, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 > x1 && y2 < y1) {
      p5.ellipse(x1, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    if (x2 < x1 && y2 < y1) {
      p5.ellipse(x2, y2, Math.abs(x2 - x1), Math.abs(y2 - y1));
    }

    p5.pop();
  }

  if (type === "triangle") {
    p5.push();

    p5.fill(fillColor);
    p5.fill(fillRgb.r, fillRgb.g, fillRgb.b, 76);
    p5.stroke(strokeColor);

    if (x2 > x1) {
      p5.triangle(x1 + Math.abs(x2 - x1) / 2, y1, x1, y2, x2, y2);
    } else {
      p5.triangle(x2 + Math.abs(x2 - x1) / 2, y1, x1, y2, x2, y2);
    }

    p5.pop();
  }
};

const drawGateNoRun = (p5) => (gate, runs, settings, state) => {
  let startPost = { x: gate.x1, y: gate.y1 };
  let endPost = { x: gate.x2, y: gate.y2 };

  let run = null;
  let runIndexLabel = null;
  let theRun1 = null;
  let theRun2 = null;
  let postsCount = null;

  if (gate.p1.runIndex !== "no-run") {
    const runIndex1 = gate.p1.runIndex;

    theRun1 = runs.get(runIndex1);

    if (!theRun1) {
      return;
    }

    const posts1 = getPosts(theRun1, settings);
    postsCount = posts1.length;

    runIndexLabel = runIndices[getRunIndex(theRun1, state)];
  }

  if (gate.p2.runIndex !== "no-run") {
    const runIndex2 = gate.p2.runIndex;

    theRun2 = runs.get(runIndex2);

    if (!theRun2) {
      return;
    }

    const posts2 = getPosts(theRun2, settings);
    postsCount = posts2.length;

    // endPost = posts2[gate.p2.postIndex] ? posts2[gate.p2.postIndex] : endPost;

    runIndexLabel = runIndices[getRunIndex(theRun2, state)];
  }

  if (!startPost || !endPost) {
    return;
  }

  const distance = getDistance(startPost, endPost);

  // Calculate angle of rotation for posts.
  const angle = Math.atan(
    (endPost.y - startPost.y) / (endPost.x - startPost.x)
  );

  if (gate.opening === "normal" && gate.side === "normal") {
    drawGateOpeningNormalSideNormal(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "invert" && gate.side === "normal") {
    drawGateOpeningInvertSideNormal(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "normal" && gate.side === "invert") {
    drawGateOpeningNormalSideInvert(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "invert" && gate.side === "invert") {
    drawGateOpeningInvertSideInvert(p5)(startPost, endPost, angle, distance);
  }

  if (theRun1) {
    run = theRun1;
  }

  if (theRun2) {
    run = theRun2;
  }

  const labelsSide = run.getIn(["labels", "runLabels"]);

  let labelSide = 1;

  if (labelsSide) {
    labelSide = 1;
  } else {
    labelSide = -1;
  }

  // Starting post label
  if (
    gate.p1.runIndex === "no-run" &&
    gate.p2.runIndex !== "no-run" &&
    gate.p2.postIndex === 0
  ) {
    const label = runIndexLabel + "0";
    p5.push();
    p5.translate(startPost.x, startPost.y);
    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }

  // Ending post label.
  if (
    gate.p1.runIndex === "no-run" &&
    gate.p2.runIndex !== "no-run" &&
    gate.p2.postIndex !== 0
  ) {
    const label = runIndexLabel + (postsCount + 1);
    p5.push();
    p5.translate(startPost.x, startPost.y);
    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }

  // Starting post label
  if (
    gate.p2.runIndex === "no-run" &&
    gate.p1.runIndex !== "no-run" &&
    gate.p1.postIndex === 0
  ) {
    const label = runIndexLabel + "0";
    p5.push();
    p5.translate(endPost.x, endPost.y);
    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }

  // Ending post label.
  if (
    gate.p2.runIndex === "no-run" &&
    gate.p1.runIndex !== "no-run" &&
    gate.p1.postIndex !== 0
  ) {
    const label = runIndexLabel + (postsCount + 1);
    p5.push();
    p5.translate(endPost.x, endPost.y);
    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }
};

export const drawGate = (p5) => (gate, runs, posts, settings, state) => {
  if (!gate) {
    return;
  }

  if (gate.p1.runIndex === "no-run" || gate.p2.runIndex === "no-run") {
    return drawGateNoRun(p5)(gate, runs, settings, state);
  }

  if (
    (gate.p1.runIndex || gate.p2.runIndex) &&
    (!gate.p1.runIndex || !gate.p2.runIndex)
  ) {
    // return drawGateNoRun(p5)(gate, runs, settings, state);
  }

  const runIndex1 = gate.p1.runIndex;

  const theRun1 = runs.get(runIndex1);

  if (!theRun1 && !gate.p1.postIndex) {
    return;
  }

  const runIndex2 = gate.p2.runIndex;

  const theRun2 = runs.get(runIndex2);

  if (!theRun2 && !gate.p2.postIndex) {
    return;
  }

  let startPost = { x: gate.x1, y: gate.y1, post: false };
  let endPost = { x: gate.x2, y: gate.y2, post: false };

  if (!startPost || !endPost) {
    return;
  }

  if (!theRun1 && gate.p1.postIndex) {
    startPost.post = true;
  }

  if (!theRun2 && gate.p2.postIndex) {
    endPost.post = true;
  }

  const distance = getDistance(startPost, endPost);

  // Calculate angle of rotation for posts.
  const angle = Math.atan(
    (endPost.y - startPost.y) / (endPost.x - startPost.x)
  );

  if (gate.opening === "normal" && gate.side === "normal") {
    drawGateOpeningNormalSideNormal(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "invert" && gate.side === "normal") {
    drawGateOpeningInvertSideNormal(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "normal" && gate.side === "invert") {
    drawGateOpeningNormalSideInvert(p5)(startPost, endPost, angle, distance);
  }

  if (gate.opening === "invert" && gate.side === "invert") {
    drawGateOpeningInvertSideInvert(p5)(startPost, endPost, angle, distance);
  }
};

const drawGateOpeningNormalSideInvert =
  (p5) => (startPost, endPost, angle, distance) => {
    p5.push();
    p5.stroke("#B0E1C1");
    p5.strokeWeight(2);
    p5.fill("#f1f6f6");
    if (endPost.x < startPost.x) {
      p5.translate(endPost.x, endPost.y);
    } else {
      p5.translate(startPost.x, startPost.y);
    }
    // Rotate the angle negative 180 degrees to appear on other side.
    p5.rotate(angle - p5.HALF_PI);
    p5.arc(0, 0, distance * 2, distance * 2, 0, p5.HALF_PI, p5.PIE);
    p5.pop();
  };

const drawGateOpeningInvertSideInvert =
  (p5) => (startPost, endPost, angle, distance) => {
    p5.push();
    p5.stroke("#B0E1C1");
    p5.strokeWeight(2);
    p5.fill("#f1f6f6");
    // Start at the endpost and rotate into position.
    if (endPost.x < startPost.x) {
      p5.translate(startPost.x, startPost.y);
    } else {
      p5.translate(endPost.x, endPost.y);
    }
    p5.rotate(angle + p5.PI);
    p5.arc(0, 0, distance * 2, distance * 2, 0, p5.HALF_PI, p5.PIE);
    p5.pop();
  };

const drawGateOpeningInvertSideNormal =
  (p5) => (startPost, endPost, angle, distance) => {
    p5.push();
    p5.stroke("#B0E1C1");
    p5.strokeWeight(2);
    p5.fill("#f1f6f6");
    // Start at end post and rotate 180 degrees.
    if (endPost.x < startPost.x) {
      p5.translate(startPost.x, startPost.y);
    } else {
      p5.translate(endPost.x, endPost.y);
    }
    p5.rotate(angle + p5.HALF_PI);
    p5.arc(0, 0, distance * 2, distance * 2, 0, p5.HALF_PI, p5.PIE);
    p5.pop();
  };

const drawGateOpeningNormalSideNormal =
  (p5) => (startPost, endPost, angle, distance) => {
    p5.push();
    p5.stroke("#B0E1C1");
    p5.strokeWeight(2);
    p5.fill("#f1f6f6");
    if (endPost.x < startPost.x) {
      p5.translate(endPost.x, endPost.y);
    } else {
      p5.translate(startPost.x, startPost.y);
    }
    p5.rotate(angle);
    p5.arc(0, 0, distance * 2, distance * 2, 0, p5.HALF_PI, p5.PIE);
    p5.pop();
  };

export const drawGateRun = (p5, runs, settings, state) => (coordinates) => {
  let { x1, y1, x2, y2, id } = coordinates;

  const run = runs.get(id);

  if (run) {
    const corners = calculateCorners(runs);

    let linearEndCorner = null;
    let linearStartCorner = null;

    if (corners) {
      const endingCorner = corners.find((corner) => {
        return Object.values(corner.points).find((point) => {
          return point.id === run.id && point.type === "2";
        });
      });

      const startingCorner = corners.find((corner) => {
        return Object.values(corner.points).find((point) => {
          return point.id === run.id && point.type === "1";
        });
      });

      if (startingCorner) {
        let matchId = null;

        Object.keys(startingCorner.points).forEach((key) => {
          return key !== run.id && (matchId = key);
        });

        if (matchId) {
          const matchRun = runs.get(matchId);

          if (matchRun) {
            const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
            const matchAngle = Math.atan2(
              matchRun.y2 - matchRun.y1,
              matchRun.x2 - matchRun.x1
            );

            if (isCloseToValue(angle, matchAngle, 0.01)) {
              linearStartCorner = startingCorner;
            }
          }
        }
      }

      if (endingCorner) {
        let matchId = null;

        Object.keys(endingCorner.points).forEach((key) => {
          return key !== run.id && (matchId = key);
        });

        if (matchId) {
          const matchRun = runs.get(matchId);

          if (matchRun) {
            const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
            const matchAngle = Math.atan2(
              matchRun.y2 - matchRun.y1,
              matchRun.x2 - matchRun.x1
            );

            if (isCloseToValue(angle, matchAngle, 0.01)) {
              linearEndCorner = endingCorner;
            }
          }
        }
      }

      const angle = Math.atan((run.y2 - run.y1) / (run.x2 - run.x1));

      const xComponent =
        roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
      const yComponent =
        roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

      if (
        endingCorner &&
        isCloseToValue(endingCorner.x, x2, 1) &&
        isCloseToValue(endingCorner.y, y2, 1)
      ) {
        x2 =
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1);
        y2 =
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1);
      }

      if (
        startingCorner &&
        isCloseToValue(startingCorner.x, x1, 1) &&
        isCloseToValue(startingCorner.y, y1, 1)
      ) {
        x1 =
          x1 -
          xComponent * (linearStartCorner ? linearRunReductionFactor() : 1);
        y1 =
          y1 -
          yComponent * (linearStartCorner ? linearRunReductionFactor() : 1);
      }
    }
  }

  // Runs must have distance.
  if (
    Math.floor(Math.abs(x2 - x1)) === 0 &&
    Math.floor(Math.abs(y2 - y1)) === 0
  ) {
    return;
  }

  // Draw run.
  p5.push();
  p5.stroke("#0FAC40");
  p5.strokeWeight(2);
  p5.line(x1, y1, x2, y2);
  p5.pop();
};

export const drawHandrailRun = (p5) => (coordinates) => {
  const { x1, y1, x2, y2 } = coordinates;

  // Runs must have distance.
  if (
    Math.floor(Math.abs(x2 - x1)) === 0 &&
    Math.floor(Math.abs(y2 - y1)) === 0
  ) {
    return;
  }

  // Draw run.
  p5.push();
  p5.stroke("#5586BD");
  p5.strokeWeight(2);
  p5.line(x1, y1, x2, y2);
  p5.pop();
};

export function lineIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  var ua,
    ub,
    denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  if (denom === 0) {
    return null;
  }
  ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
  ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1),
    seg1: ua >= 0 && ua <= 1,
    seg2: ub >= 0 && ub <= 1,
  };
}

export const drawHandrailAttached =
  (p5) =>
  (handrail, runs, corners, handrailsWithFlips, state, movingRun = null) => {
    if (!handrail) {
      return;
    }

    let run = runs.get(handrail.run);

    if (movingRun) {
      run = movingRun;
    }

    if (!run) {
      return;
    }

    const stairs = state.stairs;

    const { x1, y1, x2, y2 } = run;

    const dx = x2 - x1;
    const dy = y2 - y1;

    const fullAngle = Math.atan2(dy, dx);
    const perpendicularAngle = fullAngle - Math.PI / 2;

    let side = 1;

    if (handrail.side === "default") {
      side = 1;
    } else if (handrail.side === "flip") {
      side = -1;
    }

    const xComponent = Math.cos(perpendicularAngle) * side * cornerDistance();
    const yComponent = Math.sin(perpendicularAngle) * side * cornerDistance();

    const cornerXComponent = Math.cos(fullAngle) * cornerDistance();
    const cornerYComponent = Math.sin(fullAngle) * cornerDistance();

    let startingCorner = null;
    let endingCorner = null;

    let matchingStartRun = null;
    let matchingEndRun = null;

    let startCornerXComponent = 0;
    let startCornerYComponent = 0;

    let endCornerXComponent = 0;
    let endCornerYComponent = 0;

    let linearStartCorner = null;
    let linearEndCorner = null;

    if (corners && corners.length) {
      const potentialCorners = corners
        .map((corner) => corner.runs.has(run.id))
        .filter((included) => included);

      if (potentialCorners && potentialCorners.length) {
        startingCorner = corners.find((corner) => {
          return corner.runs.has(run.id) && corner.points[run.id].type === "1";
        });

        if (startingCorner) {
          matchingStartRun = Object.keys(startingCorner.points).reduce(
            (_acc, id) => {
              if (id !== run.id) {
                return id;
              }

              return _acc;
            },
            null
          );

          matchingStartRun = runs.get(matchingStartRun);
        }

        endingCorner = corners.find((corner) => {
          return corner.runs.has(run.id) && corner.points[run.id].type === "2";
        });

        if (endingCorner) {
          matchingEndRun = Object.keys(endingCorner.points).reduce(
            (_acc, id) => {
              if (id !== run.id) {
                return id;
              }

              return _acc;
            },
            null
          );

          matchingEndRun = runs.get(matchingEndRun);
        }

        if (startingCorner) {
          let matchId = null;

          Object.keys(startingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearStartCorner = startingCorner;
              }
            }
          }
        }

        if (endingCorner) {
          let matchId = null;

          Object.keys(endingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearEndCorner = endingCorner;
              }
            }
          }
        }
      }
    }

    if (startingCorner) {
      startCornerXComponent = cornerXComponent * 1;
      startCornerYComponent = cornerYComponent * 1;
    }

    if (endingCorner) {
      endCornerXComponent = cornerXComponent * -1;
      endCornerYComponent = cornerYComponent * -1;
    }

    let flip = 1;
    let otherFlip1 = 1;
    let otherFlip2 = 1;

    if (handrailsWithFlips.has(handrail.id)) {
      if (handrailsWithFlips.getIn([handrail.id, "flip"])) {
        flip = -1;
      }
    }

    if (matchingStartRun) {
      const matchingStartRail = handrailsWithFlips.get(
        handrailsWithFlips.getIn([
          handrail.id,
          "edges",
          "handrail-start-to-handrail",
          "to",
        ])
      );
      if (matchingStartRail && matchingStartRail.flip) {
        otherFlip1 = -1;
      }
    }

    if (matchingEndRun) {
      const matchingEndRail = handrailsWithFlips.get(
        handrailsWithFlips.getIn([
          handrail.id,
          "edges",
          "handrail-end-to-handrail",
          "to",
        ])
      );
      if (matchingEndRail && matchingEndRail.flip) {
        otherFlip2 = -1;
      }
    }

    const newPostX1 =
      x1 +
      xComponent * (linearStartCorner ? linearRunReductionFactor() : 1) * flip +
      startCornerXComponent *
        (linearStartCorner ? linearRunReductionFactor() : 1);
    const newPostY1 =
      y1 +
      yComponent * (linearStartCorner ? linearRunReductionFactor() : 1) * flip +
      startCornerYComponent *
        (linearStartCorner ? linearRunReductionFactor() : 1);
    const newPostX2 =
      x2 +
      xComponent * (linearEndCorner ? linearRunReductionFactor() : 1) * flip +
      endCornerXComponent * (linearEndCorner ? linearRunReductionFactor() : 1);
    const newPostY2 =
      y2 +
      yComponent * (linearEndCorner ? linearRunReductionFactor() : 1) * flip +
      endCornerYComponent * (linearEndCorner ? linearRunReductionFactor() : 1);

    let newLineX1 = newPostX1;
    let newLineY1 = newPostY1;
    let newLineX2 = newPostX2;
    let newLineY2 = newPostY2;

    if (startingCorner) {
      if (matchingStartRun) {
        const {
          x1: matchX1,
          y1: matchY1,
          x2: matchX2,
          y2: matchY2,
        } = matchingStartRun;

        const matchAngle =
          Math.atan2(matchY2 - matchY1, matchX2 - matchX1) - Math.PI / 2;

        const matchXComponent = Math.cos(matchAngle) * side * 10;
        const matchYComponent = Math.sin(matchAngle) * side * 10;

        const matchingPostX1 = matchX1 + matchXComponent * otherFlip1;
        const matchingPostY1 = matchY1 + matchYComponent * otherFlip1;
        const matchingPostX2 = matchX2 + matchXComponent * otherFlip1;
        const matchingPostY2 = matchY2 + matchYComponent * otherFlip1;

        const { x, y } = getIntersectionOfLineSegments(
          { x1: newPostX1, y1: newPostY1, x2: newPostX2, y2: newPostY2 },
          {
            x1: matchingPostX1,
            y1: matchingPostY1,
            x2: matchingPostX2,
            y2: matchingPostY2,
          }
        );

        newLineX1 = x;
        newLineY1 = y;
      }
    }

    if (endingCorner) {
      if (matchingEndRun) {
        const {
          x1: matchX1,
          y1: matchY1,
          x2: matchX2,
          y2: matchY2,
        } = matchingEndRun;

        const matchAngle =
          Math.atan2(matchY2 - matchY1, matchX2 - matchX1) - Math.PI / 2;

        const matchXComponent = Math.cos(matchAngle) * side * 10;
        const matchYComponent = Math.sin(matchAngle) * side * 10;

        const matchingPostX1 = matchX1 + matchXComponent * otherFlip2;
        const matchingPostY1 = matchY1 + matchYComponent * otherFlip2;
        const matchingPostX2 = matchX2 + matchXComponent * otherFlip2;
        const matchingPostY2 = matchY2 + matchYComponent * otherFlip2;
        const { x, y } = getIntersectionOfLineSegments(
          { x1: newPostX1, y1: newPostY1, x2: newPostX2, y2: newPostY2 },
          {
            x1: matchingPostX1,
            y1: matchingPostY1,
            x2: matchingPostX2,
            y2: matchingPostY2,
          }
        );

        newLineX2 = x;
        newLineY2 = y;
      }
    }

    // Draw handrail line.
    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.line(newLineX1, newLineY1, newLineX2, newLineY2);
    p5.pop();

    // Draw start connector.
    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.fill("#fff");
    p5.translate(newPostX1, newPostY1);
    p5.circle(0, 0, 6);
    p5.pop();

    // Draw end connector.
    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.fill("#fff");
    p5.translate(newPostX2, newPostY2);
    p5.circle(0, 0, 6);
    p5.pop();

    const posts = getPosts(run, state.settings, state);

    if (run.stairs) {
      const transitionStairPosts = posts.filter((post) => {
        return post.type === "stairPostTransition";
      });

      if (transitionStairPosts.length) {
        transitionStairPosts.forEach((post) => {
          const closestPoint = getClosestPointOnLine(
            { x: newPostX1, y: newPostY1 },
            { x: newPostX2, y: newPostY2 },
            { x: post.x, y: post.y }
          );
          p5.push();
          if (isHandrailOverriden(handrail)) {
            p5.stroke(255, 154, 0);
          } else {
            p5.stroke("#5586BD");
          }
          p5.strokeWeight(1);
          p5.fill("#fff");
          p5.translate(closestPoint.x, closestPoint.y);
          p5.circle(0, 0, 6);
          p5.pop();
        });
      }
    }

    handrailLabels(p5, handrail, runs, stairs, posts);
  };

export const drawHandrail =
  (p5) =>
  (handrail, runs, dispatch, state, corners, movingRun = null) => {
    if (!handrail) {
      return;
    }

    const stairs = state.stairs;

    // Only around for historical use.
    if (
      handrail.p1 &&
      handrail.p1.runIndex &&
      handrail.p2 &&
      handrail.p2.runIndex
    ) {
      const runIndex = handrail.p1.runIndex;

      const theRun = runs.get(runIndex);

      if (!theRun) {
        // dispatch({ type: "handrails/remove", handrailId: handrail.id });
        return;
      }

      const posts = getPosts(theRun, state.settings);

      const startPost = posts[handrail.p1.postIndex];
      const endPost = posts[handrail.p2.postIndex];

      if (!startPost || !endPost) {
        // dispatch({ type: "handrails/remove", handrailId: handrail.id });
        return;
      }

      // Calculate angle of rotation for posts.
      const angle = Math.atan(
        (theRun.y2 - theRun.y1) / (theRun.x2 - theRun.x1)
      );

      const perpendicularAngle = angle - Math.PI / 2;

      let side = 1;

      if (handrail.side === "default") {
        side = 1;
      }

      if (handrail.side === "flip") {
        side = -1;
      }

      const xComponent =
        roundToHundreth(Math.cos(perpendicularAngle)) * side * 10;
      const yComponent =
        roundToHundreth(Math.sin(perpendicularAngle)) * side * 10;

      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.translate(startPost.x, startPost.y);
      p5.line(
        xComponent,
        yComponent,
        xComponent + endPost.x - startPost.x,
        yComponent + (endPost.y - startPost.y)
      );
      p5.pop();

      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(startPost.x, startPost.y);
      p5.circle(xComponent, yComponent, 6);
      p5.pop();

      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(endPost.x, endPost.y);
      p5.circle(xComponent, yComponent, 6);
      p5.pop();

      if (movingRun) {
        runs = runs.set(movingRun.id, movingRun);
      }

      handrailLabels(p5, handrail, runs, stairs, posts);

      return;
    }

    // Hand drawn handrails that link over corners.
    if (
      handrail.p1 &&
      handrail.p1.run &&
      handrail.p2 &&
      handrail.p2.run &&
      (handrail.p1.corner || handrail.p2.corner)
    ) {
      const runIndex = handrail.p1.run;

      let theRun = runs.get(runIndex);

      if (movingRun) {
        theRun = movingRun;
      }

      const posts = getPosts(theRun, state.settings, state);

      let x1 = handrail.p1.x;
      let y1 = handrail.p1.y;
      let x2 = handrail.p2.x;
      let y2 = handrail.p2.y;

      if (handrail.side === "default") {
        x1 = handrail.p1.x;
        y1 = handrail.p1.y;
        x2 = handrail.p2.x;
        y2 = handrail.p2.y;
      }

      if (handrail.side === "flip") {
        if (handrail.p1.matchingPoint && handrail.p2.matchingPoint) {
          x1 = handrail.p1.matchingPoint.x;
          y1 = handrail.p1.matchingPoint.y;
          x2 = handrail.p2.matchingPoint.x;
          y2 = handrail.p2.matchingPoint.y;
        }
      }

      // Line
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.line(x1, y1, x2, y2);
      p5.pop();

      // Start circle
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(x1, y1);
      p5.circle(0, 0, 6);
      p5.pop();

      // End circle
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(x2, y2);
      p5.circle(0, 0, 6);
      p5.pop();

      if (theRun.stairs) {
        const transitionStairPosts = posts.filter((post) => {
          return post.type === "stairPostTransition";
        });

        if (transitionStairPosts.length) {
          transitionStairPosts.forEach((post) => {
            const { index } = post;

            if (
              (handrail.p1.postIndex >= index &&
                handrail.p2.postIndex <= index) ||
              (handrail.p1.postIndex <= index && handrail.p2.postIndex >= index)
            ) {
              const closestPoint = getClosestPointOnLine(
                { x: x1, y: y1 },
                { x: x2, y: y2 },
                { x: post.x, y: post.y }
              );
              p5.push();
              if (isHandrailOverriden(handrail)) {
                p5.stroke(255, 154, 0);
              } else {
                p5.stroke("#5586BD");
              }
              p5.strokeWeight(1);
              p5.fill("#fff");
              p5.translate(closestPoint.x, closestPoint.y);
              p5.circle(0, 0, 6);
              p5.pop();
            }
          });
        }
      }

      if (movingRun) {
        runs = runs.set(movingRun.id, movingRun);
      }

      handrailLabels(p5, handrail, runs, stairs, posts);

      return;
    }

    // Hand drawn handrail with no corners.
    if (handrail.p1 && handrail.p1.run && handrail.p2 && handrail.p2.run) {
      const runIndex = handrail.p1.run;

      let theRun = runs.get(runIndex);

      if (!theRun) {
        // dispatch({ type: "handrails/remove", handrailId: handrail.id });
        return;
      }

      if (movingRun) {
        theRun = movingRun;
      }

      const posts = getPosts(theRun, state.settings, state);

      let x1 = handrail.p1.x;
      let y1 = handrail.p1.y;
      let x2 = handrail.p2.x;
      let y2 = handrail.p2.y;

      if (handrail.side === "default") {
        x1 = handrail.p1.x;
        y1 = handrail.p1.y;
        x2 = handrail.p2.x;
        y2 = handrail.p2.y;
      }

      if (handrail.side === "flip") {
        if (handrail.p1.matchingPoint && handrail.p2.matchingPoint) {
          x1 = handrail.p1.matchingPoint.x;
          y1 = handrail.p1.matchingPoint.y;
          x2 = handrail.p2.matchingPoint.x;
          y2 = handrail.p2.matchingPoint.y;
        }
      }

      // Line
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.line(x1, y1, x2, y2);
      p5.pop();

      // Start circle
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(x1, y1);
      p5.circle(0, 0, 6);
      p5.pop();

      // End circle
      p5.push();
      if (isHandrailOverriden(handrail)) {
        p5.stroke(255, 154, 0);
      } else {
        p5.stroke("#5586BD");
      }
      p5.strokeWeight(1);
      p5.fill("#fff");
      p5.translate(x2, y2);
      p5.circle(0, 0, 6);
      p5.pop();

      if (theRun.stairs) {
        const transitionStairPosts = posts.filter((post) => {
          return post.type === "stairPostTransition";
        });

        if (transitionStairPosts.length) {
          transitionStairPosts.forEach((post) => {
            const { index } = post;

            if (
              (handrail.p1.postIndex >= index &&
                handrail.p2.postIndex <= index) ||
              (handrail.p1.postIndex <= index && handrail.p2.postIndex >= index)
            ) {
              const closestPoint = getClosestPointOnLine(
                { x: x1, y: y1 },
                { x: x2, y: y2 },
                { x: post.x, y: post.y }
              );
              p5.push();
              if (isHandrailOverriden(handrail)) {
                p5.stroke(255, 154, 0);
              } else {
                p5.stroke("#5586BD");
              }
              p5.strokeWeight(1);
              p5.fill("#fff");
              p5.translate(closestPoint.x, closestPoint.y);
              p5.circle(0, 0, 6);
              p5.pop();
            }
          });
        }
      }

      if (movingRun) {
        runs = runs.set(movingRun.id, movingRun);
      }

      handrailLabels(p5, handrail, runs, stairs, posts);

      return;
    }

    // Free form handrail.
    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.translate(handrail.x1, handrail.y1);
    p5.line(0, 0, handrail.x2 - handrail.x1, handrail.y2 - handrail.y1);
    p5.pop();

    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.fill("#fff");
    p5.translate(handrail.x1, handrail.y1);
    p5.circle(0, 0, 6);
    p5.pop();

    p5.push();
    if (isHandrailOverriden(handrail)) {
      p5.stroke(255, 154, 0);
    } else {
      p5.stroke("#5586BD");
    }
    p5.strokeWeight(1);
    p5.fill("#fff");
    p5.translate(handrail.x2, handrail.y2);
    p5.circle(0, 0, 6);
    p5.pop();

    if (movingRun) {
      runs = runs.set(movingRun.id, movingRun);
    }

    handrailLabels(p5, handrail, runs, stairs);
  };

export function getGroupedPostsForHandrail(posts, handrail) {
  return posts.reduce((grouped, post) => {
    const { index } = post;

    if (handrail.p1.run) {
      if (
        !(
          (handrail.p1.postIndex >= index && handrail.p2.postIndex <= index) ||
          (handrail.p1.postIndex <= index && handrail.p2.postIndex >= index)
        )
      ) {
        return grouped;
      }
    }

    if (post.matchingStair && post.matchingStair.length) {
      post.matchingStair.forEach((stairId) => {
        if (!grouped[stairId]) {
          grouped[stairId] = [];
        }

        grouped[stairId].push(post);
      });
    } else {
      if (!grouped["regular"]) {
        grouped["regular"] = [];
      }

      grouped["regular"].push(post);
    }

    return grouped;
  }, {});
}

export function getGroupedPostsForRun(posts: PostInfo[]) {
  const res: Record<string, PostInfo[]> = {};
  return posts.reduce((grouped, post) => {
    if (post.matchingStair && post.matchingStair.length) {
      post.matchingStair.forEach((stairId) => {
        if (!grouped[stairId]) {
          grouped[stairId] = [];
        }

        grouped[stairId].push(post);
      });
    } else {
      if (!grouped["regular"]) {
        grouped["regular"] = [];
      }

      grouped["regular"].push(post);
    }

    return grouped;
  }, res);
}

export function getDistancesFromPostGroupsForHandrail(
  handrail,
  run,
  stairs,
  value,
  key
) {
  const minMax = value.reduce(
    (acc, point) => {
      const { x, y, type, cornerType, index } = point;

      if (handrail.p1.run) {
        if (
          (handrail.p1.postIndex >= index && handrail.p2.postIndex <= index) ||
          (handrail.p1.postIndex <= index && handrail.p2.postIndex >= index)
        ) {
          acc.max.x = Math.max(acc.max.x, x);
          acc.max.y = Math.max(acc.max.y, y);
          acc.min.x = Math.min(acc.min.x, x);
          acc.min.y = Math.min(acc.min.y, y);

          if (key === "not-connected") {
            acc.max.x = Math.max(acc.max.x, x);
            acc.max.y = Math.max(acc.max.y, y);
            acc.min.x = Math.min(acc.min.x, x);
            acc.min.y = Math.min(acc.min.y, y);

            return acc;
          }
        }
      } else {
        acc.max.x = Math.max(acc.max.x, x);
        acc.max.y = Math.max(acc.max.y, y);
        acc.min.x = Math.min(acc.min.x, x);
        acc.min.y = Math.min(acc.min.y, y);

        if (key === "not-connected") {
          acc.min.x = run.x1;
          acc.min.y = run.y1;
          acc.max.x = run.x2;
          acc.max.y = run.y2;

          return acc;
        }
      }

      if (acc.max.x === x && acc.max.y === y) {
        acc.max.type = type;
        acc.max.cornerType = cornerType;
      }

      if (acc.min.x === x && acc.min.y === y) {
        acc.min.type = type;
        acc.min.cornerType = cornerType;
      }

      return acc;
    },
    {
      max: { x: -Infinity, y: -Infinity },
      min: { x: Infinity, y: Infinity },
    }
  );

  let distance = getDistance(minMax.min, minMax.max);

  let distanceLabel = { feet: 0, inches: 0 };

  if (stairs.has(key)) {
    if (run.stairs) {
      const keys = run.stairs.keys;

      const { start, end } = keys;

      const startVertical = start[0];
      const startHorizontal = start[1];

      const endVertical = end[0];
      const endHorizontal = end[1];

      let orientation = "horizontal";

      if (startVertical === endVertical) {
        orientation = "horizontal";
      }

      if (startHorizontal === endHorizontal) {
        orientation = "vertical";
      }

      const theStairs = stairs.get(key);

      const angle = theStairs.angle;

      const rotatedPoints = getStairCornersForSnapLines(theStairs, orientation);

      if (rotatedPoints) {
        if (minMax.max.type === "stairPostTransition") {
          if (minMax.max.cornerType) {
            minMax.max = Object.values(rotatedPoints).reduce(
              (acc, rotatePoint) => {
                const distance = getDistance(minMax.max, rotatePoint);

                if (distance < acc.distance) {
                  acc.distance = distance;
                  acc.point = rotatePoint;
                }

                return acc;
              },
              { point: minMax.max, distance: Infinity }
            ).point;
          }
        }

        if (minMax.min.type === "stairPostTransition") {
          if (minMax.min.cornerType) {
            minMax.min = Object.values(rotatedPoints).reduce(
              (acc, rotatePoint) => {
                const distance = getDistance(minMax.min, rotatePoint);

                if (distance < acc.distance) {
                  acc.distance = distance;
                  acc.point = rotatePoint;
                }

                return acc;
              },
              { point: minMax.min, distance: Infinity }
            ).point;
          }
        }
      }

      distance = getDistance(minMax.min, minMax.max);

      if (theStairs.type === "stairs") {
        distanceLabel = distanceOfHypotenuse(degreesToRadians(angle), distance);
        distance =
          getDistance(minMax.min, minMax.max) /
          Math.cos(degreesToRadians(angle));
      } else if (theStairs.type === "landing") {
        distanceLabel = distanceInFeetObjectFromDistance(distance);
      }
    }
  } else {
    distance = getDistance(minMax.min, minMax.max);

    distanceLabel = distanceInFeetObjectFromDistance(distance);
  }

  return {
    distance,
    distanceLabel,
    minMax,
  };
}

export function getDistancesFromPostGroupsForRun(
  run: Run,
  stairs: Map<string, Stairs>,
  value: PostInfo[],
  key: string
) {
  type MinMax = {
    max: { x: number; y: number; type?: string; cornerType?: string };
    min: { x: number; y: number; type?: string; cornerType?: string };
  };
  const minMax: MinMax = value.reduce(
    (acc: MinMax, point: any): MinMax => {
      const { x, y, type, cornerType } = point;

      acc.max.x = Math.max(acc.max.x, x);
      acc.max.y = Math.max(acc.max.y, y);
      acc.min.x = Math.min(acc.min.x, x);
      acc.min.y = Math.min(acc.min.y, y);

      if (key === "not-connected") {
        acc.min.x = run.x1;
        acc.min.y = run.y1;
        acc.max.x = run.x2;
        acc.max.y = run.y2;

        return acc;
      }

      if (acc.max.x === x && acc.max.y === y) {
        acc.max.type = type;
        acc.max.cornerType = cornerType;
      }

      if (acc.min.x === x && acc.min.y === y) {
        acc.min.type = type;
        acc.min.cornerType = cornerType;
      }

      return acc;
    },
    {
      max: { x: -Infinity, y: -Infinity },
      min: { x: Infinity, y: Infinity },
    }
  );

  let distance = getDistance(minMax.min, minMax.max);
  let distanceType = "straight";
  let distanceLabel = { feet: 0, inches: 0 };

  if (stairs.has(key)) {
    if (run.stairs) {
      const keys = (run?.stairs as any)?.keys;

      const { start, end } = keys;

      const startVertical = start[0];
      const startHorizontal = start[1];

      const endVertical = end[0];
      const endHorizontal = end[1];

      let orientation = "horizontal";

      if (startVertical === endVertical) {
        orientation = "horizontal";
      }

      if (startHorizontal === endHorizontal) {
        orientation = "vertical";
      }

      const theStairs = stairs.get(key);

      const angle = theStairs?.angle;

      const rotatedPoints = getStairCornersForSnapLines(theStairs, orientation);

      if (rotatedPoints) {
        if (
          minMax.max.type === "stairPostTransition" ||
          minMax.max.type === "terminal" ||
          minMax.max.type === "stairPostTerminal"
        ) {
          if (minMax.max.cornerType) {
            minMax.max = Object.values(rotatedPoints).reduce(
              (acc, rotatePoint) => {
                const distance = getDistance(minMax.max, rotatePoint);

                if (distance < acc.distance) {
                  acc.distance = distance;
                  acc.point = rotatePoint;
                }

                return acc;
              },
              { point: minMax.max, distance: Infinity }
            ).point;
          }
        }

        if (
          minMax.min.type === "stairPostTransition" ||
          minMax.min.type === "terminal" ||
          minMax.min.type === "stairPostTerminal"
        ) {
          if (minMax.min.cornerType) {
            minMax.min = Object.values(rotatedPoints).reduce(
              (acc, rotatePoint) => {
                const distance = getDistance(minMax.min, rotatePoint);

                if (distance < acc.distance) {
                  acc.distance = distance;
                  acc.point = rotatePoint;
                }

                return acc;
              },
              { point: minMax.min, distance: Infinity }
            ).point;
          }
        }
      }

      distance = getDistance(minMax.min, minMax.max);

      if ((theStairs as any)?.type === "stairs") {
        distanceLabel = distanceOfHypotenuse(degreesToRadians(angle), distance);
        distance =
          getDistance(minMax.min, minMax.max) /
          Math.cos(degreesToRadians(angle));
        distanceType = "hypotenuse";
      } else if ((theStairs as any).type === "landing") {
        distanceLabel = distanceInFeetObjectFromDistance(distance);
        distanceType = "straight";
      }
    }
  } else {
    distance = getDistance(minMax.min, minMax.max);

    distanceLabel = distanceInFeetObjectFromDistance(distance);
  }

  return {
    distance,
    distanceType,
    distanceLabel,
    minMax,
  };
}

function newHandrailLabels(p5, handrail, run, stairs, posts) {
  const showLabels = handrail.getIn(["labels", "showLabels"]);

  if (!showLabels) {
    return;
  }

  // Calculate handrail labels side
  const distanceLabelsSide = handrail.getIn(["labels", "distanceLabel"]);

  let distanceLabelSide = 1;

  if (distanceLabelsSide) {
    distanceLabelSide = 1;
  } else {
    distanceLabelSide = -1;
  }

  const groupedPosts = getGroupedPostsForHandrail(posts, handrail);

  Object.entries(groupedPosts).forEach(([key, value]) => {
    if (value.length < 2) {
      return;
    }

    const { minMax, distanceLabel } = getDistancesFromPostGroupsForHandrail(
      handrail,
      run,
      stairs,
      value,
      key
    );

    const midpoint = {
      x: minMax.min.x + Math.floor((minMax.max.x - minMax.min.x) / 2),
      y: minMax.min.y + Math.floor((minMax.max.y - minMax.min.y) / 2),
    };

    const angle = Math.abs(
      Math.atan((minMax.max.y - minMax.min.y) / (minMax.max.x - minMax.min.x)) -
        Math.PI / 2
    );

    const label = `${distanceLabel.feet}' ${distanceLabel.inches}"`;

    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(
      midpoint.x + 30 * distanceLabelSide * Math.cos(angle),
      midpoint.y + 30 * distanceLabelSide * Math.sin(angle)
    );
    // p5.translate(
    //   20 * distanceLabelSide * Math.cos(angle),
    //   20 * distanceLabelSide * Math.sin(angle)
    // );
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.fill("#5586BD");
    p5.text(label, 0, 0);
    p5.pop();
  });
}

function handrailLabels(p5, handrail, runs, stairs, posts = []) {
  const showLabels = handrail.getIn(["labels", "showLabels"]);

  if (!showLabels) {
    return;
  }

  // Handrail labels
  let { x1, y1, x2, y2 } = handrail;

  // Attached auto drawn handrail.
  if (handrail.run) {
    const run = runs.get(handrail.run);

    if (!run) {
      return;
    }

    newHandrailLabels(p5, handrail, run, stairs, posts);
    return;
  }

  // Only around for historical use.
  if (
    handrail.p1 &&
    handrail.p1.runIndex &&
    handrail.p2 &&
    handrail.p2.runIndex
  ) {
    const startPost = posts[handrail.p1.postIndex];
    const endPost = posts[handrail.p2.postIndex];
    x1 = startPost.x;
    y1 = startPost.y;
    x2 = endPost.x;
    y2 = endPost.y;
  }

  // Hand drawn handrails that link over corners.
  if (
    handrail.p1 &&
    handrail.p1.run &&
    handrail.p2 &&
    handrail.p2.run &&
    (handrail.p1.corner || handrail.p2.corner)
  ) {
    const run = runs.get(handrail.p1.run);

    if (!run) {
      return;
    }

    newHandrailLabels(p5, handrail, run, stairs, posts);
    return;
  }

  // Hand drawn handrail with no corners.
  if (handrail.p1 && handrail.p1.run && handrail.p2 && handrail.p2.run) {
    const run = runs.get(handrail.p1.run);

    if (!run) {
      return;
    }

    newHandrailLabels(p5, handrail, run, stairs, posts);
    return;
  }

  // Calculate angle of rotation for posts.
  const angle = Math.atan((y2 - y1) / (x2 - x1));

  const xDistance = x2 - x1;
  const yDistance = y2 - y1;

  // Calculate handrail labels side
  const distanceLabelsSide = handrail.getIn(["labels", "distanceLabel"]);

  let distanceLabelSide = 1;

  if (distanceLabelsSide) {
    distanceLabelSide = 1;
  } else {
    distanceLabelSide = -1;
  }

  let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
  let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

  if (xComponent > 0 && xDistance < 0) {
    xComponent = xComponent * -1;
  }

  if (yComponent > 0 && yDistance < 0) {
    yComponent = yComponent * -1;
  }

  if (xDistance > 0) {
    xComponent = Math.abs(xComponent);
  }

  if (yDistance > 0) {
    yComponent = Math.abs(yComponent);
  }

  const midpoint = {
    x: Math.floor(xDistance / 2),
    y: Math.floor(yDistance / 2),
  };

  let distanceFootage = distanceInFeet({ x1, y1, x2, y2 });

  if (
    handrail.p1 &&
    handrail.p1.stairsIndex &&
    handrail.p2 &&
    handrail.p2.stairsIndex
  ) {
    const theStairs = stairs.get(handrail.p1.stairsIndex);
    const angle = degreesToRadians(theStairs.angle);

    const hypotenuse = Math.floor(
      getDistance(handrail.p1, handrail.p2) / Math.cos(angle)
    );
    const distanceInFeet = hypotenuse / pixelsPerFoot();
    const remainder = (distanceInFeet % 1).toFixed(4);

    const inches = Math.floor(remainder * 12);
    const feet = Math.floor(distanceInFeet);

    distanceFootage = `${Math.floor(feet)}' ${inches}"`;
  }

  p5.push();
  p5.rectMode(p5.CENTER);
  p5.translate(x1, y1);
  p5.translate(midpoint.x, midpoint.y);
  p5.translate(
    30 * distanceLabelSide * Math.cos(Math.abs(angle - Math.PI / 2)),
    30 * distanceLabelSide * Math.sin(Math.abs(angle - Math.PI / 2))
  );
  p5.strokeWeight(0);
  p5.textAlign(p5.CENTER, p5.CENTER);
  p5.fill("#5586BD");
  p5.text(distanceFootage, 0, 0);
  p5.pop();
}

function isHandrailOverriden(handrail) {
  if (!handrail.parts) {
    return false;
  }

  if (handrail.parts.type !== "default") {
    return true;
  }

  if (handrail.parts.linkColor !== "default") {
    return true;
  }

  if (handrail.parts.start.loop !== "default") {
    return true;
  }

  if (handrail.parts.start.endCap !== "default") {
    return true;
  }

  if (handrail.parts.start.wallReturn !== "default") {
    return true;
  }

  if (handrail.parts.end.loop !== "default") {
    return true;
  }

  if (handrail.parts.end.endCap !== "default") {
    return true;
  }

  if (handrail.parts.end.wallReturn !== "default") {
    return true;
  }

  return false;
}

export const drawPost = (p5) => (post, gates, settings) => {
  const { x, y, phantom } = post;

  const attachedGates = gates.filter((gate) => {
    return gate.p1.postIndex === post.id || gate.p2.postIndex === post.id;
  });

  let attachedGate = null;

  if (attachedGates.size) {
    attachedGate = attachedGates.first();
  }

  let angle = 0;

  if (attachedGate) {
    angle = Math.atan(
      (attachedGate.y2 - attachedGate.y1) / (attachedGate.x2 - attachedGate.x1)
    );
  }

  if (!phantom) {
    p5.push();
    p5.angleMode(p5.RADIANS);
    p5.rectMode(p5.CENTER);
    p5.translate(x, y);
    p5.rotate(angle);
    if (attachedGate) {
      p5.stroke("#0FAC40");
      p5.fill("#B0E1C1");
    } else {
      p5.fill(126);
    }
    p5.strokeWeight(2);
    if ( settings.postMaterial === "stainless-steel" && settings.stainlessPostShape === "round") {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }
    p5.pop();
  } else {
    p5.push();
    p5.angleMode(p5.RADIANS);
    p5.translate(-5, -5);
    p5.translate(x, y);
    p5.rotate(angle);
    if (attachedGate) {
      p5.stroke("#0FAC40");
      p5.fill("#B0E1C1");
    } else {
      p5.fill(126);
    }
    p5.strokeWeight(2);
    p5.line(0, 0, 0, 10);
    p5.line(0, 5, 10, 5);
    p5.pop();
  }
};

const generateRunIndices = () => {
  const runIndices = [];

  for (let i = 0; i < 26; i++) {
    runIndices.push(String.fromCharCode(65 + i));
  }

  for (let i = 0; i < 26; i++) {
    runIndices.push(String.fromCharCode(97 + i));
  }

  for (let i = 0; i < 26; i++) {
    runIndices.push();

    for (let j = 0; j < 26; j++) {
      runIndices.push(
        String.fromCharCode(65 + i) + String.fromCharCode(97 + j)
      );
    }
  }

  return runIndices;
};

const runIndices = generateRunIndices();

export const drawRunConnectedToStairs =
  (
    p5,
    sketch,
    state,
    corners,
    snapLine,
    canvas,
    stairs,
    runs,
    runId = null,
    index = undefined,
    movingStairs = null
  ) =>
  (coordinates) => {
    const { settings } = state;
    const { x1, y1, x2, y2 } = coordinates;

    // Runs must have distance.
    if (
      Math.floor(Math.abs(x2 - x1)) === 0 &&
      Math.floor(Math.abs(y2 - y1)) === 0
    ) {
      return;
    }

    let run = coordinates;

    if (runId) {
      run = runs.get(runId);
    } else {
      run = coordinates;
    }

    let startingCorner = null;
    let endingCorner = null;

    let singlePostEndingCorner = null;
    let singlePostStartingCorner = null;

    let hoveringEndPoint = false;
    let hoveringStartPoint = false;

    let startCornerCableThroughPost = null;
    let endCornerCableThroughPost = null;

    let matchingStartCorner = null;
    let matchingEndCorner = null;

    let linearStartCorner = null;
    let linearEndCorner = null;

    if (sketch.hoveringObject && run) {
      if (sketch.hoveringObject.run === run.id) {
        if (sketch.hoveringObject.type === "1") {
          hoveringStartPoint = true;
        }

        if (sketch.hoveringObject.type === "2") {
          hoveringEndPoint = true;
        }
      }
    }

    if (corners && corners.length) {
      const potentialCorners = corners
        .map((corner) => corner.runs.has(run.id))
        .filter((included) => included);

      if (potentialCorners && potentialCorners.length) {
        startingCorner = corners.find((corner) => {
          return corner.runs.has(run.id) && corner.points[run.id].type === "1";
        });

        endingCorner = corners.find((corner) => {
          return corner.runs.has(run.id) && corner.points[run.id].type === "2";
        });

        if (startingCorner) {
          let matchId = null;

          Object.keys(startingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearStartCorner = startingCorner;
              }
            }
          }
        }

        if (endingCorner) {
          let matchId = null;

          Object.keys(endingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearEndCorner = endingCorner;
              }
            }
          }
        }
      }

      if (state) {
        const stateCorners = state.corners;

        matchingStartCorner = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "1") {
            return value.type && value.type === "single";
          }

          return false;
        });

        if (matchingStartCorner) {
          startingCorner = null;
          singlePostStartingCorner = matchingStartCorner;
        }

        matchingEndCorner = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "2") {
            return value.type && value.type === "single";
          }

          return false;
        });

        startCornerCableThroughPost = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "1") {
            return value.cableContinuesThrough;
          }

          return false;
        });

        endCornerCableThroughPost = stateCorners.find((value) => {
          // Type 2 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "2") {
            return value.cableContinuesThrough;
          }

          return false;
        });

        if (matchingEndCorner) {
          endingCorner = null;
          singlePostEndingCorner = matchingEndCorner;
        }
      }
    }

    // Calculate run labels side
    const labelsSide = run.getIn(["labels", "runLabels"]);

    let labelSide = 1;

    if (labelsSide) {
      labelSide = 1;
    } else {
      labelSide = -1;
    }

    const distanceLabelsSide = run.getIn(["labels", "distanceLabel"]);

    const showLabels = run.getIn(["labels", "showLabels"]);

    let distanceLabelSide = 1;

    if (distanceLabelsSide) {
      distanceLabelSide = 1;
    } else {
      distanceLabelSide = -1;
    }

    let stairsRun = run.stairs;

    if (!stairsRun) {
      stairsRun = sketch.stairsRun;
    }

    const { keys } = stairsRun;

    let theStairs = stairs.get(stairsRun.stairsIndex);

    if (movingStairs) {
      theStairs = movingStairs;
    }
    const endPost = stairsRun.endPost;
    const startPost = stairsRun.startPost;

    if (!theStairs) {
      return;
    }

    // Stairs angle in radians.
    const stairsAngle = (theStairs.angle * Math.PI) / 180;

    if (
      stairsRun.continuousStairs &&
      (stairsRun.continuousStairs.size ||
        Object.keys(stairsRun.continuousStairs).length)
    ) {
      drawRunContinousStairs(
        p5,
        run,
        theStairs,
        settings,
        startingCorner,
        endingCorner,
        hoveringStartPoint,
        hoveringEndPoint,
        endCornerCableThroughPost,
        startCornerCableThroughPost,
        index,
        labelSide,
        distanceLabelSide,
        state,
        canvas,
        singlePostEndingCorner,
        singlePostStartingCorner,
        showLabels,
        linearStartCorner,
        linearEndCorner
      );
      return;
    }

    if (
      isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost) &&
      !isNearPoint({ x: x1, y: y1 }, startPost, 5)
    ) {
      drawRunConnectedToStairsWhenStartIsInsideOfStairs(
        p5,
        startPost,
        endPost,
        stairsAngle,
        x1,
        y1,
        x2,
        y2,
        settings,
        run,
        startingCorner,
        endingCorner,
        hoveringStartPoint,
        hoveringEndPoint,
        index,
        labelSide,
        distanceLabelSide,
        keys,
        stairsRun,
        canvas,
        theStairs,
        singlePostEndingCorner,
        showLabels,
        linearStartCorner,
        linearEndCorner
      );
    } else {
      drawRunConnectedToStairsWhenStartIsOutsideOfStairs(
        p5,
        startPost,
        endPost,
        stairsAngle,
        x1,
        y1,
        x2,
        y2,
        settings,
        run,
        startingCorner,
        endingCorner,
        hoveringStartPoint,
        hoveringEndPoint,
        index,
        labelSide,
        distanceLabelSide,
        keys,
        stairsRun,
        canvas,
        theStairs,
        singlePostEndingCorner,
        showLabels,
        linearStartCorner,
        linearEndCorner
      );
    }
  };

function drawRunContinousStairs(
  p5,
  run,
  theStairs,
  settings,
  startingCorner,
  endingCorner,
  hoveringStartPoint,
  hoveringEndPoint,
  endCornerCableThroughPost,
  startCornerCableThroughPost,
  index,
  labelSide,
  distanceLabelSide,
  state,
  canvas,
  singlePostEndingCorner,
  singlePostStartingCorner,
  showLabels,
  linearStartCorner,
  linearEndCorner
) {
  const { x1, y1, x2, y2 } = run;

  // Get phantom post settings for run.
  const phantomPostStart = run.getIn(["endPosts", "start"]);
  const phantomPostEnd = run.getIn(["endPosts", "end"]);

  const angle = Math.atan2(y2 - y1, x2 - x1);

  let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
  let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

  const posts = getPosts(run, settings, state);

  // Draw Line.
  if (true) {
    p5.push();
    p5.stroke(0);
    if (run && !isRunSettingsEmpty(run.settings)) {
      p5.stroke(255, 154, 0);
    }

    if (settings.customPostSpacing > 5) {
      p5.stroke(255, 0, 0);
    }

    if (run && (run.getIn(["settings", "customPostSpacing"]) || 0) > 5) {
      p5.stroke(255, 0, 0);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      if (endingCorner) {
        p5.line(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 +
            yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.line(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 +
            yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          x2,
          y2
        );
      }
    } else {
      if (endingCorner) {
        p5.line(
          x1,
          y1,
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.line(x1, y1, x2, y2);
      }
    }

    if (endCornerCableThroughPost) {
      let matchEnd = null;

      const matchId = endCornerCableThroughPost.id.findKey((_point, id) => {
        return id !== run.id;
      });
      const matchingRun = state.runs.get(matchId);

      matchEnd = endCornerCableThroughPost
        .getIn(["id", matchId, "type"])
        .toString();

      if (matchingRun) {
        const {
          x1: x1Match,
          y1: y1Match,
          x2: x2Match,
          y2: y2Match,
        } = matchingRun;

        const matchAngle = Math.atan2(y2Match - y1Match, x2Match - x1Match);

        const matchXComponent =
          roundToHundreth(Math.cos(matchAngle)) * (cornerDistance() / 2);
        const matchYComponent =
          roundToHundreth(Math.sin(matchAngle)) * (cornerDistance() / 2);

        const newXComponent =
          roundToHundreth(Math.cos(angle)) * (cornerDistance() / 2);
        const newYComponent =
          roundToHundreth(Math.sin(angle)) * (cornerDistance() / 2);

        if (matchEnd === "1") {
          p5.line(
            x2 - newXComponent,
            y2 - newYComponent,
            x1Match + matchXComponent,
            y1Match + matchYComponent
          );
        } else {
          p5.line(
            x2 - newXComponent,
            y2 - newYComponent,
            x2Match - matchXComponent,
            y2Match - matchYComponent
          );
        }
      }
    }

    if (startCornerCableThroughPost) {
      let matchStart = null;
      const matchId = startCornerCableThroughPost.id.findKey((_point, id) => {
        return id !== run.id;
      });

      const matchingRun = state.runs.get(matchId);

      matchStart = startCornerCableThroughPost
        .getIn(["id", matchId, "type"])
        .toString();

      if (matchingRun) {
        const {
          x1: x1Match,
          y1: y1Match,
          x2: x2Match,
          y2: y2Match,
        } = matchingRun;

        const matchAngle = Math.atan2(y2Match - y1Match, x2Match - x1Match);

        const matchXComponent =
          roundToHundreth(Math.cos(matchAngle)) * (cornerDistance() / 2);
        const matchYComponent =
          roundToHundreth(Math.sin(matchAngle)) * (cornerDistance() / 2);

        const newXComponent =
          roundToHundreth(Math.cos(angle)) * (cornerDistance() / 2);
        const newYComponent =
          roundToHundreth(Math.sin(angle)) * (cornerDistance() / 2);

        if (matchStart === "1") {
          p5.line(
            x1 + newXComponent,
            y1 + newYComponent,
            x1Match + matchXComponent,
            y1Match + matchYComponent
          );
        } else {
          p5.line(
            x1 + newXComponent,
            y1 + newYComponent,
            x2Match - matchXComponent,
            y2Match - matchYComponent
          );
        }
      }
    }
    p5.pop();
  }

  // Draw end posts.
  p5.angleMode(p5.RADIANS);
  p5.rectMode(p5.CENTER);

  // Draw first end post.
  if (!singlePostStartingCorner) {
    p5.push();
    p5.fill(126);

    if (run.getIn(["postTypes", "start"]) === "intermediate") {
      p5.fill(255, 255, 255);
    }

    if (hoveringStartPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      p5.translate(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x1, y1);
    }
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      if (phantomPostStart) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(0, 0, 0, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.circle(0, 0, 10);
      }
    } else {
      if (phantomPostStart) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(0, 0, 0, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.rect(0, 0, 10);
      }
    }

    p5.pop();

    // Draw Label.
    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + "1";

      p5.push();
      p5.rectMode(p5.CENTER);

      if (startingCorner) {
        p5.translate(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x1, y1);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle % Math.PI);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  // Draw posts.
  let thePosts = posts.length;

  for (let i = 0; i < posts.length; i++) {
    const { x: xCord, y: yCord } = posts[i];

    if (xCord === run.x1 && yCord === run.y1) {
      thePosts--;
      continue;
    }

    if (xCord === run.x2 && yCord === run.y2) {
      thePosts--;
      continue;
    }

    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(xCord, yCord);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    // Draw Label.
    if (typeof index !== "undefined" && canvas.labels.runs === "show") {
      const label = runIndices[index] + (i - (posts.length - thePosts) + 2);

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(xCord, yCord);
      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle % Math.PI);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  // Draw second end post.
  if (!singlePostEndingCorner) {
    p5.push();
    p5.fill(126);

    if (run.getIn(["postTypes", "end"]) === "intermediate") {
      p5.fill(255, 255, 255);
    }

    if (hoveringEndPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (endingCorner) {
      p5.translate(
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x2, y2);
    }
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.circle(0, 0, 10);
      }
    } else {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.rect(0, 0, 10);
      }
    }

    p5.pop();

    // Draw Label.
    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (thePosts + 2);

      p5.push();
      p5.rectMode(p5.CENTER);

      if (endingCorner) {
        p5.translate(
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x2, y2);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle % Math.PI);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  // Draw distance label.
  if (canvas.labels.runs === "show" && showLabels) {
    const groupedPosts = getGroupedPostsForRun(posts, run);

    Object.entries(groupedPosts).forEach(([key, value]) => {
      if (value.length < 2) {
        return;
      }

      const { distanceLabel, minMax } = getDistancesFromPostGroupsForRun(
        run,
        state.stairs,
        value,
        key
      );

      const midpoint = {
        x: minMax.min.x + Math.floor((minMax.max.x - minMax.min.x) / 2),
        y: minMax.min.y + Math.floor((minMax.max.y - minMax.min.y) / 2),
      };

      const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1) - Math.PI / 2;

      const label = `${distanceLabel.feet}' ${distanceLabel.inches}"`;

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(
        midpoint.x + 30 * distanceLabelSide * roundToHundreth(Math.cos(angle)),
        midpoint.y + 30 * distanceLabelSide * roundToHundreth(Math.sin(angle))
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    });
  }
}

function linearRunReductionFactor() {
  return 0.5;
}

function drawRunConnectedToStairsWhenStartIsInsideOfStairs(
  p5,
  startPost,
  endPost,
  stairsAngle,
  x1,
  y1,
  x2,
  y2,
  settings,
  run,
  startingCorner,
  endingCorner,
  hoveringStartPoint,
  hoveringEndPoint,
  index,
  labelSide,
  distanceLabelSide,
  keys,
  stairsRun,
  canvas,
  theStairs,
  singlePostEndingCorner,
  showLabels,
  linearStartCorner,
  linearEndCorner
) {
  // Get phantom post settings for run.
  const phantomPostStart = run.getIn(["endPosts", "start"]);
  const phantomPostEnd = run.getIn(["endPosts", "end"]);

  // Draw when start of run is inside of stairs.
  // Calculate number of intermediate posts and distance vectors for initial run.
  // When inside of stairs start posts can never be available as we start on stairs and either end on stairs or outside of stairs.

  // Calculate number of intermediate posts and distance vectors for initial run.
  let stairPosts, xStairDistance, yStairDistance;

  // If all in stairs.
  if (isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost)) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  // If on stairs and moving over start post.
  if (isPointBetweenPoints(startPost, { x: x2, y: y2 }, { x: x1, y: y1 })) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", x1)
        .set("y1", y1)
        .set("x2", startPost.x)
        .set("y2", startPost.y),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  // If started on stairs but moving past endpost.
  if (isPointBetweenPoints(endPost, { x: x2, y: y2 }, { x: x1, y: y1 })) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", endPost.x).set("y2", endPost.y),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  let endPosts, xEndDistance, yEndDistance;

  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run.set("x1", endPost.x).set("y1", endPost.y).set("x2", x2).set("y2", y2),
      settings
    );

    endPosts = numPosts.posts;
    xEndDistance = numPosts.xDistance;
    yEndDistance = numPosts.yDistance;
  }

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", startPost.x)
        .set("y1", startPost.y)
        .set("x2", x2)
        .set("y2", y2),
      settings
    );

    endPosts = numPosts.posts;
    xEndDistance = numPosts.xDistance;
    yEndDistance = numPosts.yDistance;
  }

  // Calculate angle of rotation for posts.
  const angle = Math.atan((y2 - y1) / (x2 - x1));

  let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
  let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

  if (xComponent > 0 && xStairDistance < 0) {
    xComponent = xComponent * -1;
  }

  if (yComponent > 0 && yStairDistance < 0) {
    yComponent = yComponent * -1;
  }

  if (xStairDistance > 0) {
    xComponent = Math.abs(xComponent);
  }

  if (yStairDistance > 0) {
    yComponent = Math.abs(yComponent);
  }

  // Draw run.
  p5.push();
  p5.stroke(0);

  if (run && !isRunSettingsEmpty(run.settings)) {
    p5.stroke(255, 154, 0);
  }

  if (settings.customPostSpacing > 5) {
    p5.stroke(255, 0, 0);
  }

  if (run && (run.getIn(["settings", "customPostSpacing"]) || 0) > 5) {
    p5.stroke(255, 0, 0);
  }

  p5.strokeWeight(2);
  if (startingCorner) {
    if (endingCorner) {
      p5.line(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.line(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        x2,
        y2
      );
    }
  } else {
    if (endingCorner) {
      p5.line(
        x1,
        y1,
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.line(x1, y1, x2, y2);
    }
  }
  p5.pop();

  // Draw end posts.
  p5.angleMode(p5.RADIANS);
  p5.rectMode(p5.CENTER);

  // Draw first end post.
  p5.push();
  p5.fill(126);

  if (run.getIn(["postTypes", "start"]) === "intermediate") {
    p5.fill(255, 255, 255);
  }

  if (hoveringStartPoint) {
    p5.stroke(0, 163, 255);
    p5.fill(171, 222, 251);
  }

  p5.strokeWeight(2);
  if (startingCorner) {
    p5.translate(
      x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
      y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
    );
  } else {
    p5.translate(x1, y1);
  }
  p5.rotate(angle);

  if (
    settings.postMaterial === "stainless-steel" &&
    settings.stainlessPostShape === "round"
  ) {
    if (phantomPostStart) {
      p5.push();
      p5.translate(-5, -5);
      p5.translate(0, 0);
      p5.fill(126);
      p5.strokeWeight(2);
      p5.line(0, 0, 0, 10);
      p5.line(0, 5, 10, 5);
      p5.pop();
    } else {
      p5.circle(0, 0, 10);
    }
  } else {
    if (phantomPostStart) {
      p5.push();
      p5.translate(-5, -5);
      p5.translate(0, 0);
      p5.fill(126);
      p5.strokeWeight(2);
      p5.line(0, 0, 0, 10);
      p5.line(0, 5, 10, 5);
      p5.pop();
    } else {
      p5.rect(0, 0, 10);
    }
  }

  p5.pop();

  // Draw Label.
  if (
    typeof index !== "undefined" &&
    canvas.labels.runs === "show" &&
    showLabels
  ) {
    const label = runIndices[index] + "1";

    p5.push();
    p5.rectMode(p5.CENTER);

    if (startingCorner) {
      p5.translate(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x1, y1);
    }

    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }

  // Draw midpoints length measurement for stairs.
  let stairsMidpoint = {
    x: Math.floor((x2 - x1) / 2),
    y: Math.floor((y2 - y1) / 2),
  };

  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    stairsMidpoint = {
      x: Math.floor((endPost.x - x1) / 2),
      y: Math.floor((endPost.y - y1) / 2),
    };
  }

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    stairsMidpoint = {
      x: Math.floor((startPost.x - x1) / 2),
      y: Math.floor((startPost.y - y1) / 2),
    };
  }

  const stairsDistance = getHypotenuseInFeet(theStairs, run, stairsRun);

  if (showLabels) {
    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);
    p5.translate(stairsMidpoint.x, stairsMidpoint.y);
    p5.translate(
      50 * distanceLabelSide * Math.sin(angle),
      -50 * distanceLabelSide * Math.cos(angle)
    );
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(stairsDistance, 0, 0);
    p5.pop();
  }

  // Draw midpoints length measurement for stairs.
  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const endMidpoint = {
      x: Math.floor((x2 - endPost.x) / 2),
      y: Math.floor((y2 - endPost.y) / 2),
    };

    const endCoordinates = {
      x1: endPost.x,
      y1: endPost.y,
      x2,
      y2,
    };

    const endDistance = distanceInFeet(endCoordinates);

    if (!isNearPoint(endPost, { x: x2, y: y2 }) && showLabels) {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(endPost.x, endPost.y);
      p5.translate(endMidpoint.x, endMidpoint.y);
      p5.translate(
        50 * distanceLabelSide * Math.sin(angle),
        -50 * distanceLabelSide * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(endDistance, 0, 0);
      p5.pop();
    }
  }

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const endMidpoint = {
      x: Math.floor((x2 - startPost.x) / 2),
      y: Math.floor((y2 - startPost.y) / 2),
    };

    const endCoordinates = {
      x1: startPost.x,
      y1: startPost.y,
      x2,
      y2,
    };

    const endDistance = distanceInFeet(endCoordinates);

    if (!isNearPoint(startPost, { x: x2, y: y2 }) && showLabels) {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(startPost.x, startPost.y);
      p5.translate(endMidpoint.x, endMidpoint.y);
      p5.translate(
        50 * distanceLabelSide * Math.sin(angle),
        -50 * distanceLabelSide * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(endDistance, 0, 0);
      p5.pop();
    }
  }

  let postsIndex = 0;

  if (stairPosts) {
    // Draw stairs posts.
    for (let i = 0; i < stairPosts; i++) {
      postsIndex++;
      let xCord = x1 + (i + 1) * xStairDistance;
      let yCord = y1 + (i + 1) * yStairDistance;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(angle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (
        typeof index !== "undefined" &&
        canvas.labels.runs === "show" &&
        showLabels
      ) {
        const label = runIndices[index] + (postsIndex + 1);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }
  }

  if (
    isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
    !isNearPoint(endPost, { x: x2, y: y2 }, 5)
  ) {
    // Draw End Post of Stairs.
    postsIndex++;
    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(endPost.x, endPost.y);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 1);

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(endPost.x, endPost.y);
      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  if (
    isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
    !isNearPoint(startPost, { x: x2, y: y2 }, 5)
  ) {
    // Draw End Post of Stairs.
    postsIndex++;
    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(startPost.x, startPost.y);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 1);

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(startPost.x, startPost.y);
      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  // Draw end posts.
  if (endPosts) {
    if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
      for (let i = 0; i < endPosts; i++) {
        postsIndex++;
        let xCord = endPost.x + (i + 1) * xEndDistance;
        let yCord = endPost.y + (i + 1) * yEndDistance;
        p5.push();
        p5.fill(255);
        p5.strokeWeight(2);
        p5.translate(xCord, yCord);
        p5.rotate(angle);

        if (
          settings.postMaterial === "stainless-steel" &&
          settings.stainlessPostShape === "round"
        ) {
          p5.circle(0, 0, 10);
        } else {
          p5.rect(0, 0, 10);
        }

        p5.pop();

        // Draw Label.
        if (
          typeof index !== "undefined" &&
          canvas.labels.runs === "show" &&
          showLabels
        ) {
          const label = runIndices[index] + (postsIndex + 1);

          p5.push();
          p5.rectMode(p5.CENTER);
          p5.translate(xCord, yCord);
          p5.translate(
            20 * labelSide * Math.cos(angle + Math.PI / 2),
            20 * labelSide * Math.sin(angle + Math.PI / 2)
          );
          p5.rotate(angle);
          p5.strokeWeight(0);
          p5.textAlign(p5.CENTER, p5.CENTER);
          p5.text(label, 0, 0);
          p5.pop();
        }
      }
    }

    if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
      for (let i = 0; i < endPosts; i++) {
        postsIndex++;
        let xCord = startPost.x + (i + 1) * xEndDistance;
        let yCord = startPost.y + (i + 1) * yEndDistance;
        p5.push();
        p5.fill(255);
        p5.strokeWeight(2);
        p5.translate(xCord, yCord);
        p5.rotate(angle);

        if (
          settings.postMaterial === "stainless-steel" &&
          settings.stainlessPostShape === "round"
        ) {
          p5.circle(0, 0, 10);
        } else {
          p5.rect(0, 0, 10);
        }

        p5.pop();

        // Draw Label.
        if (
          typeof index !== "undefined" &&
          canvas.labels.runs === "show" &&
          showLabels
        ) {
          const label = runIndices[index] + (postsIndex + 1);

          p5.push();
          p5.rectMode(p5.CENTER);
          p5.translate(xCord, yCord);
          p5.translate(
            20 * labelSide * Math.cos(angle + Math.PI / 2),
            20 * labelSide * Math.sin(angle + Math.PI / 2)
          );
          p5.rotate(angle);
          p5.strokeWeight(0);
          p5.textAlign(p5.CENTER, p5.CENTER);
          p5.text(label, 0, 0);
          p5.pop();
        }
      }
    }
  }

  // Draw final terminal end post.
  if (!singlePostEndingCorner) {
    p5.push();
    p5.fill(126);

    if (run.getIn(["postTypes", "end"]) === "intermediate") {
      p5.fill(255, 255, 255);
    }

    if (hoveringEndPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (endingCorner) {
      p5.translate(
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x2, y2);
    }
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.circle(0, 0, 10);
      }
    } else {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.rect(0, 0, 10);
      }
    }

    p5.pop();

    // Draw Label.
    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 2);

      p5.push();
      p5.rectMode(p5.CENTER);

      if (endingCorner) {
        p5.translate(
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x2, y2);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }
}

function drawRunConnectedToStairsWhenStartIsOutsideOfStairs(
  p5,
  startPost,
  endPost,
  stairsAngle,
  x1,
  y1,
  x2,
  y2,
  settings,
  run,
  startingCorner,
  endingCorner,
  hoveringStartPoint,
  hoveringEndPoint,
  index,
  labelSide,
  distanceLabelSide,
  keys,
  stairsRun,
  canvas,
  theStairs,
  singlePostEndingCorner,
  showLabels,
  linearStartCorner,
  linearEndCorner
) {
  // Get phantom post settings for run.
  const phantomPostStart = run.getIn(["endPosts", "start"]);
  const phantomPostEnd = run.getIn(["endPosts", "end"]);

  // Calculate number of intermediate posts and distance vectors for initial run.
  let posts, xDistance, yDistance;

  if (
    !isNearPoint(startPost, { x: x1, y: y1 }, 5) &&
    !isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)
  ) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      settings
    );

    posts = numPosts.posts;
    xDistance = numPosts.xDistance;
    yDistance = numPosts.yDistance;
  }

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", x1)
        .set("y1", y1)
        .set("x2", startPost.x)
        .set("y2", startPost.y),
      settings
    );

    posts = numPosts.posts;
    xDistance = numPosts.xDistance;
    yDistance = numPosts.yDistance;
  }

  // Calculate number of intermediate posts and distance vectors for initial run.
  let stairPosts, xStairDistance, yStairDistance;

  if (
    isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) ||
    isNearPoint(startPost, { x: x1, y: y1 }, 5)
  ) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", startPost.x)
        .set("y1", startPost.y)
        .set("x2", endPost.x)
        .set("y2", endPost.y),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  if (
    isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost) &&
    !isNearPoint(startPost, { x: x1, y: y1 }, 5)
  ) {
    const numPosts = calculateNumPosts(
      run
        .set("x1", startPost.x)
        .set("y1", startPost.y)
        .set("x2", x2)
        .set("y2", y2),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  if (
    isPointBetweenPoints({ x: x2, y: y2 }, startPost, endPost) &&
    isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)
  ) {
    const numPosts = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
      settings,
      stairsAngle
    );

    stairPosts = numPosts.posts;
    xStairDistance = numPosts.xDistance;
    yStairDistance = numPosts.yDistance;
  }

  let endPosts, xEndDistance, yEndDistance;

  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    const numPosts = calculateNumPosts(
      run.set("x1", endPost.x).set("y1", endPost.y).set("x2", x2).set("y2", y2),
      settings
    );

    endPosts = numPosts.posts;
    xEndDistance = numPosts.xDistance;
    yEndDistance = numPosts.yDistance;
  }

  // Calculate angle of rotation for posts.
  const angle = Math.atan((y2 - y1) / (x2 - x1));

  let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
  let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

  if (xComponent > 0 && xDistance < 0) {
    xComponent = xComponent * -1;
  }

  if (yComponent > 0 && yDistance < 0) {
    yComponent = yComponent * -1;
  }

  if (xDistance > 0) {
    xComponent = Math.abs(xComponent);
  }

  if (yDistance > 0) {
    yComponent = Math.abs(yComponent);
  }

  // Draw run.
  p5.push();
  p5.stroke(0);

  if (run && !isRunSettingsEmpty(run.settings)) {
    p5.stroke(255, 154, 0);
  }

  if (settings.customPostSpacing > 5) {
    p5.stroke(255, 0, 0);
  }

  if (run && (run.getIn(["settings", "customPostSpacing"]) || 0) > 5) {
    p5.stroke(255, 0, 0);
  }

  p5.strokeWeight(2);
  if (startingCorner) {
    if (endingCorner) {
      p5.line(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.line(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        x2,
        y2
      );
    }
  } else {
    if (endingCorner) {
      p5.line(
        x1,
        y1,
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.line(x1, y1, x2, y2);
    }
  }
  p5.pop();

  // Draw end posts.
  p5.angleMode(p5.RADIANS);
  p5.rectMode(p5.CENTER);

  // Draw first end post.
  p5.push();
  p5.fill(126);

  if (run.getIn(["postTypes", "start"]) === "intermediate") {
    p5.fill(255, 255, 255);
  }

  if (hoveringStartPoint) {
    p5.stroke(0, 163, 255);
    p5.fill(171, 222, 251);
  }

  p5.strokeWeight(2);
  if (startingCorner) {
    p5.translate(
      x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
      y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
    );
  } else {
    p5.translate(x1, y1);
  }
  p5.rotate(angle);

  if (
    settings.postMaterial === "stainless-steel" &&
    settings.stainlessPostShape === "round"
  ) {
    if (phantomPostStart) {
      p5.push();
      p5.translate(-5, -5);
      p5.translate(0, 0);
      p5.fill(126);
      p5.strokeWeight(2);
      p5.line(0, 0, 0, 10);
      p5.line(0, 5, 10, 5);
      p5.pop();
    } else {
      p5.circle(0, 0, 10);
    }
  } else {
    if (phantomPostStart) {
      p5.push();
      p5.translate(-5, -5);
      p5.translate(0, 0);
      p5.fill(126);
      p5.strokeWeight(2);
      p5.line(0, 0, 0, 10);
      p5.line(0, 5, 10, 5);
      p5.pop();
    } else {
      p5.rect(0, 0, 10);
    }
  }

  p5.pop();

  // Draw Label.
  if (
    typeof index !== "undefined" &&
    canvas.labels.runs === "show" &&
    showLabels
  ) {
    const label = runIndices[index] + "1";

    p5.push();
    p5.rectMode(p5.CENTER);

    if (startingCorner) {
      p5.translate(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x1, y1);
    }

    p5.translate(
      20 * labelSide * Math.cos(angle + Math.PI / 2),
      20 * labelSide * Math.sin(angle + Math.PI / 2)
    );
    p5.rotate(angle);
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(label, 0, 0);
    p5.pop();
  }

  // Draw midpoints length measurement for start.
  let startMidpoint = {
    x: Math.floor((x2 - x1) / 2),
    y: Math.floor((y2 - y1) / 2),
  };

  let startCoordinates = {
    x1,
    y1,
    x2,
    y2,
  };

  if (isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    startMidpoint = {
      x: Math.floor((startPost.x - x1) / 2),
      y: Math.floor((startPost.y - y1) / 2),
    };
    startCoordinates = {
      x1,
      y1,
      x2: startPost.x,
      y2: startPost.y,
    };
  }

  const distance = distanceInFeet(startCoordinates);

  if (
    !isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost) &&
    showLabels
  ) {
    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);
    p5.translate(startMidpoint.x, startMidpoint.y);
    p5.translate(
      50 * distanceLabelSide * Math.sin(angle),
      -50 * distanceLabelSide * Math.cos(angle)
    );
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(distance, 0, 0);
    p5.pop();
  }

  // Draw midpoints length measurement for stairs.
  let stairsMidpoint = {
    x: Math.floor((x2 - startPost.x) / 2),
    y: Math.floor((y2 - startPost.y) / 2),
  };

  if (isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 })) {
    stairsMidpoint = {
      x: Math.floor((endPost.x - startPost.x) / 2),
      y: Math.floor((endPost.y - startPost.y) / 2),
    };
  }

  if (
    (isPointBetweenPoints(
      { x: x2, y: y2 },
      stairsRun.snapLineCorners[keys.start],
      stairsRun.snapLineCorners[keys.end]
    ) ||
      isPointBetweenPoints(
        stairsRun.snapLineCorners[keys.end],
        { x: x1, y: y1 },
        { x: x2, y: y2 }
      )) &&
    showLabels
  ) {
    const stairsDistance = getHypotenuseInFeet(theStairs, run, stairsRun);

    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(startPost.x, startPost.y);
    p5.translate(stairsMidpoint.x, stairsMidpoint.y);
    p5.translate(
      50 * distanceLabelSide * Math.sin(angle),
      -50 * distanceLabelSide * Math.cos(angle)
    );
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(stairsDistance, 0, 0);
    p5.pop();
  }

  // Draw midpoints length measurement for stairs.
  let endMidpoint = {
    x: Math.floor((x2 - endPost.x) / 2),
    y: Math.floor((y2 - endPost.y) / 2),
  };

  let endCoordinates = {
    x1: endPost.x,
    y1: endPost.y,
    x2,
    y2,
  };

  if (
    isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
    showLabels
  ) {
    const endDistance = distanceInFeet(endCoordinates);

    if (!isNearPoint(endPost, { x: x2, y: y2 })) {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(endPost.x, endPost.y);
      p5.translate(endMidpoint.x, endMidpoint.y);
      p5.translate(
        50 * distanceLabelSide * Math.sin(angle),
        -50 * distanceLabelSide * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(endDistance, 0, 0);
      p5.pop();
    }
  }

  let postsIndex = 0;

  if (posts) {
    // Draw starting intermediate posts.
    for (let i = 0; i < posts; i++) {
      postsIndex++;
      let xCord = x1 + (i + 1) * xDistance;
      let yCord = y1 + (i + 1) * yDistance;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(angle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (
        typeof index !== "undefined" &&
        canvas.labels.runs === "show" &&
        showLabels
      ) {
        const label = runIndices[index] + (postsIndex + 1);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }
  }

  if (
    isPointBetweenPoints(startPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
    !isNearPoint(startPost, { x: x1, y: y1 }, 5)
  ) {
    // Draw Start Post of Stairs.
    postsIndex++;
    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(startPost.x, startPost.y);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 1);

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(startPost.x, startPost.y);
      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  if (stairPosts) {
    // Draw stairs posts.
    if (isPointBetweenPoints({ x: x1, y: y1 }, startPost, endPost)) {
      for (let i = 0; i < stairPosts; i++) {
        postsIndex++;
        let xCord = x1 + (i + 1) * xStairDistance;
        let yCord = y1 + (i + 1) * yStairDistance;
        p5.push();
        p5.fill(255);
        p5.strokeWeight(2);
        p5.translate(xCord, yCord);
        p5.rotate(angle);

        if (
          settings.postMaterial === "stainless-steel" &&
          settings.stainlessPostShape === "round"
        ) {
          p5.circle(0, 0, 10);
        } else {
          p5.rect(0, 0, 10);
        }

        p5.pop();

        // Draw Label.
        if (
          typeof index !== "undefined" &&
          canvas.labels.runs === "show" &&
          showLabels
        ) {
          const label = runIndices[index] + (postsIndex + 1);

          p5.push();
          p5.rectMode(p5.CENTER);
          p5.translate(xCord, yCord);
          p5.translate(
            20 * labelSide * Math.cos(angle + Math.PI / 2),
            20 * labelSide * Math.sin(angle + Math.PI / 2)
          );
          p5.rotate(angle);
          p5.strokeWeight(0);
          p5.textAlign(p5.CENTER, p5.CENTER);
          p5.text(label, 0, 0);
          p5.pop();
        }
      }
    } else {
      for (let i = 0; i < stairPosts; i++) {
        postsIndex++;
        let xCord = startPost.x + (i + 1) * xStairDistance;
        let yCord = startPost.y + (i + 1) * yStairDistance;
        p5.push();
        p5.fill(255);
        p5.strokeWeight(2);
        p5.translate(xCord, yCord);
        p5.rotate(angle);

        if (
          settings.postMaterial === "stainless-steel" &&
          settings.stainlessPostShape === "round"
        ) {
          p5.circle(0, 0, 10);
        } else {
          p5.rect(0, 0, 10);
        }

        p5.pop();

        // Draw Label.
        if (
          typeof index !== "undefined" &&
          canvas.labels.runs === "show" &&
          showLabels
        ) {
          const label = runIndices[index] + (postsIndex + 1);

          p5.push();
          p5.rectMode(p5.CENTER);
          p5.translate(xCord, yCord);
          p5.translate(
            20 * labelSide * Math.cos(angle + Math.PI / 2),
            20 * labelSide * Math.sin(angle + Math.PI / 2)
          );
          p5.rotate(angle);
          p5.strokeWeight(0);
          p5.textAlign(p5.CENTER, p5.CENTER);
          p5.text(label, 0, 0);
          p5.pop();
        }
      }
    }
  }

  if (
    isPointBetweenPoints(endPost, { x: x1, y: y1 }, { x: x2, y: y2 }) &&
    !isNearPoint(endPost, { x: x2, y: y2 }, 5)
  ) {
    // Draw End Post of Stairs.
    postsIndex++;
    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(endPost.x, endPost.y);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 1);

      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(endPost.x, endPost.y);
      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }

  // Draw end posts.
  if (endPosts) {
    for (let i = 0; i < endPosts; i++) {
      postsIndex++;
      let xCord = endPost.x + (i + 1) * xEndDistance;
      let yCord = endPost.y + (i + 1) * yEndDistance;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(angle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (
        typeof index !== "undefined" &&
        canvas.labels.runs === "show" &&
        showLabels
      ) {
        const label = runIndices[index] + (postsIndex + 1);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }
  }

  // Draw final terminal end post.
  if (!singlePostEndingCorner) {
    p5.push();
    p5.fill(126);

    if (run.getIn(["postTypes", "end"]) === "intermediate") {
      p5.fill(255, 255, 255);
    }

    if (hoveringEndPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (endingCorner) {
      p5.translate(
        x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
        y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x2, y2);
    }
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.circle(0, 0, 10);
      }
    } else {
      if (phantomPostEnd) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(10, 0, 10, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.rect(0, 0, 10);
      }
    }

    p5.pop();

    // Draw Label.
    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + (postsIndex + 2);

      p5.push();
      p5.rectMode(p5.CENTER);

      if (endingCorner) {
        p5.translate(
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x2, y2);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }
  }
}

export const drawRun =
  (p5, sketch, settings, runs, runId = null, corners, index, canvas, state) =>
  (coordinates) => {
    const { x1, y1, x2, y2 } = coordinates;

    // Runs must have distance.
    if (
      Math.floor(Math.abs(x2 - x1)) === 0 &&
      Math.floor(Math.abs(y2 - y1)) === 0
    ) {
      return;
    }

    let run = null;

    if (runId) {
      run = runs.get(runId);
    } else {
      run = coordinates;
    }

    // Calculate angle of rotation for posts.
    const angle = Math.atan((y2 - y1) / (x2 - x1));

    let startingCorner = null;
    let endingCorner = null;

    let singlePostEndingCorner = null;
    let singlePostStartingCorner = null;

    let hoveringEndPoint = false;
    let hoveringStartPoint = false;

    let startCornerCableThroughPost = null;
    let endCornerCableThroughPost = null;

    let linearEndCorner = null;
    let linearStartCorner = null;

    if (sketch.hoveringObject && run) {
      if (sketch.hoveringObject.run === run.id) {
        if (sketch.hoveringObject.type === "1") {
          hoveringStartPoint = true;
        }

        if (sketch.hoveringObject.type === "2") {
          hoveringEndPoint = true;
        }
      }
    }

    if (corners && corners.length) {
      const potentialCorners = corners
        .map((corner) => corner.runs.has(runId))
        .filter((included) => included);

      if (potentialCorners && potentialCorners.length) {
        startingCorner = corners.find((corner) => {
          return corner.runs.has(runId) && corner.points[runId].type === "1";
        });

        endingCorner = corners.find((corner) => {
          return corner.runs.has(runId) && corner.points[runId].type === "2";
        });

        if (startingCorner) {
          let matchId = null;

          Object.keys(startingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearStartCorner = startingCorner;
              }
            }
          }
        }

        if (endingCorner) {
          let matchId = null;

          Object.keys(endingCorner.points).forEach((key) => {
            return key !== run.id && (matchId = key);
          });

          if (matchId) {
            const matchRun = runs.get(matchId);

            if (matchRun) {
              const angle = Math.atan2(run.y2 - run.y1, run.x2 - run.x1);
              const matchAngle = Math.atan2(
                matchRun.y2 - matchRun.y1,
                matchRun.x2 - matchRun.x1
              );

              if (isCloseToValue(angle, matchAngle, 0.01)) {
                linearEndCorner = endingCorner;
              }
            }
          }
        }
      }

      if (state) {
        const stateCorners = state.corners;

        const matchingStartCorner = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(runId) && value.id.get(runId).type === "1") {
            return value.type && value.type === "single";
          }

          return false;
        });

        if (matchingStartCorner) {
          startingCorner = null;
          singlePostStartingCorner = matchingStartCorner; // eslint-disable-line no-unused-vars
        }

        const matchingEndCorner = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(runId) && value.id.get(runId).type === "2") {
            return value.type && value.type === "single";
          }

          return false;
        });

        if (matchingEndCorner) {
          endingCorner = null;
          singlePostEndingCorner = matchingEndCorner;
        }

        startCornerCableThroughPost = stateCorners.find((value) => {
          // Type 1 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "1") {
            return value.cableContinuesThrough;
          }

          return false;
        });

        endCornerCableThroughPost = stateCorners.find((value) => {
          // Type 2 is the ending run for the corner where the first post is what is removed.
          if (value.id.get(run.id) && value.id.get(run.id).type === "2") {
            return value.cableContinuesThrough;
          }

          return false;
        });
      }
    }

    // Get phantom post settings for run.
    const phantomPostStart = run.getIn(["endPosts", "start"]);
    const phantomPostEnd = run.getIn(["endPosts", "end"]);

    // Calculate run labels side
    const labelsSide = run.getIn(["labels", "runLabels"]);

    let labelSide = 1;

    if (labelsSide) {
      labelSide = 1;
    } else {
      labelSide = -1;
    }

    const distanceLabelsSide = run.getIn(["labels", "distanceLabel"]);
    const showLabels = run.getIn(["labels", "showLabels"]);

    let distanceLabelSide = 1;

    if (distanceLabelsSide) {
      distanceLabelSide = 1;
    } else {
      distanceLabelSide = -1;
    }

    // Calculate number of intermediate posts and distance vectors.
    const { posts, xDistance, yDistance } = calculateNumPosts(
      coordinates,
      settings
    );

    let xComponent = roundToHundreth(Math.cos(angle)) * 1 * cornerDistance();
    let yComponent = roundToHundreth(Math.sin(angle)) * 1 * cornerDistance();

    if (xComponent > 0 && xDistance < 0) {
      xComponent = xComponent * -1;
    }

    if (yComponent > 0 && yDistance < 0) {
      yComponent = yComponent * -1;
    }

    if (xDistance > 0) {
      xComponent = Math.abs(xComponent);
    }

    if (yDistance > 0) {
      yComponent = Math.abs(yComponent);
    }

    // Draw run.
    p5.push();
    p5.stroke(0);

    if (run && !isRunSettingsEmpty(run.settings)) {
      p5.stroke(255, 154, 0);
    }

    if (settings.customPostSpacing > 5) {
      p5.stroke(255, 0, 0);
    }

    if (run && (run.getIn(["settings", "customPostSpacing"]) || 0) > 5) {
      p5.stroke(255, 0, 0);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      if (endingCorner) {
        p5.line(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 +
            yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.line(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 +
            yComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          x2,
          y2
        );
      }
    } else {
      if (endingCorner) {
        p5.line(
          x1,
          y1,
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.line(x1, y1, x2, y2);
      }
    }

    if (endCornerCableThroughPost) {
      let matchEnd = null;

      const matchId = endCornerCableThroughPost.id.findKey((_point, id) => {
        return id !== run.id;
      });
      const matchingRun = canvas.runs.get(matchId);

      matchEnd = endCornerCableThroughPost
        .getIn(["id", matchId, "type"])
        .toString();

      if (matchingRun) {
        const {
          x1: x1Match,
          y1: y1Match,
          x2: x2Match,
          y2: y2Match,
        } = matchingRun;

        const matchAngle = Math.atan2(y2Match - y1Match, x2Match - x1Match);

        const matchXComponent =
          roundToHundreth(Math.cos(matchAngle)) * (cornerDistance() / 2);
        const matchYComponent =
          roundToHundreth(Math.sin(matchAngle)) * (cornerDistance() / 2);

        const newXComponent =
          roundToHundreth(Math.cos(angle)) * (cornerDistance() / 2);
        const newYComponent =
          roundToHundreth(Math.sin(angle)) * (cornerDistance() / 2);

        if (matchEnd === "1") {
          p5.line(
            x2 - newXComponent,
            y2 - newYComponent,
            x1Match + matchXComponent,
            y1Match + matchYComponent
          );
        } else {
          p5.line(
            x2 - newXComponent,
            y2 - newYComponent,
            x2Match - matchXComponent,
            y2Match - matchYComponent
          );
        }
      }
    }

    if (startCornerCableThroughPost) {
      let matchStart = null;
      const matchId = startCornerCableThroughPost.id.findKey((_point, id) => {
        return id !== run.id;
      });

      const matchingRun = canvas.runs.get(matchId);

      matchStart = startCornerCableThroughPost
        .getIn(["id", matchId, "type"])
        .toString();

      if (matchingRun) {
        const {
          x1: x1Match,
          y1: y1Match,
          x2: x2Match,
          y2: y2Match,
        } = matchingRun;

        const matchAngle = Math.atan2(y2Match - y1Match, x2Match - x1Match);

        const matchXComponent =
          roundToHundreth(Math.cos(matchAngle)) * (cornerDistance() / 2);
        const matchYComponent =
          roundToHundreth(Math.sin(matchAngle)) * (cornerDistance() / 2);

        const newXComponent =
          roundToHundreth(Math.cos(angle)) * (cornerDistance() / 2);
        const newYComponent =
          roundToHundreth(Math.sin(angle)) * (cornerDistance() / 2);

        if (matchStart === "1") {
          p5.line(
            x1 + newXComponent,
            y1 + newYComponent,
            x1Match + matchXComponent,
            y1Match + matchYComponent
          );
        } else {
          p5.line(
            x1 + newXComponent,
            y1 + newYComponent,
            x2Match - matchXComponent,
            y2Match - matchYComponent
          );
        }
      }
    }
    p5.pop();

    // Draw end posts.
    p5.angleMode(p5.RADIANS);
    p5.rectMode(p5.CENTER);

    // Draw first end post.
    p5.push();
    p5.fill(126);

    if (run.getIn(["postTypes", "start"]) === "intermediate") {
      p5.fill(255, 255, 255);
    }

    if (hoveringStartPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      p5.translate(
        x1 + xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
        y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
      );
    } else {
      p5.translate(x1, y1);
    }
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      if (phantomPostStart) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(0, 0, 0, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.circle(0, 0, 10);
      }
    } else {
      if (phantomPostStart) {
        p5.push();
        p5.translate(-5, -5);
        p5.translate(0, 0);
        p5.fill(126);
        p5.strokeWeight(2);
        p5.line(0, 0, 0, 10);
        p5.line(0, 5, 10, 5);
        p5.pop();
      } else {
        p5.rect(0, 0, 10);
      }
    }

    p5.pop();

    // Draw Label.
    if (
      typeof index !== "undefined" &&
      canvas.labels.runs === "show" &&
      showLabels
    ) {
      const label = runIndices[index] + "1";

      p5.push();
      p5.rectMode(p5.CENTER);

      if (startingCorner) {
        p5.translate(
          x1 +
            xComponent * (linearStartCorner ? linearRunReductionFactor() : 1),
          y1 + yComponent * (linearStartCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x1, y1);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }

    // Draw midpoint length measurement.
    const midpoint = {
      x: Math.floor((x2 - x1) / 2),
      y: Math.floor((y2 - y1) / 2),
    };

    const distance = distanceInFeet(coordinates);

    if (showLabels) {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(x1, y1);
      p5.translate(midpoint.x, midpoint.y);
      p5.translate(
        50 * distanceLabelSide * Math.sin(angle),
        -50 * distanceLabelSide * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(distance, 0, 0);
      p5.pop();
    }

    // Draw second end post.
    if (!singlePostEndingCorner) {
      p5.push();
      p5.fill(126);

      if (run.getIn(["postTypes", "end"]) === "intermediate") {
        p5.fill(255, 255, 255);
      }

      if (hoveringEndPoint) {
        p5.stroke(0, 163, 255);
        p5.fill(171, 222, 251);
      }

      p5.strokeWeight(2);
      if (endingCorner) {
        p5.translate(
          x2 - xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
          y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
        );
      } else {
        p5.translate(x2, y2);
      }
      p5.rotate(angle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        if (phantomPostEnd) {
          p5.push();
          p5.translate(-5, -5);
          p5.translate(0, 0);
          p5.fill(126);
          p5.strokeWeight(2);
          p5.line(10, 0, 10, 10);
          p5.line(0, 5, 10, 5);
          p5.pop();
        } else {
          p5.circle(0, 0, 10);
        }
      } else {
        if (phantomPostEnd) {
          p5.push();
          p5.translate(-5, -5);
          p5.translate(0, 0);
          p5.fill(126);
          p5.strokeWeight(2);
          p5.line(10, 0, 10, 10);
          p5.line(0, 5, 10, 5);
          p5.pop();
        } else {
          p5.rect(0, 0, 10);
        }
      }

      p5.pop();

      // Draw Label.
      if (
        typeof index !== "undefined" &&
        canvas.labels.runs === "show" &&
        showLabels
      ) {
        const label = runIndices[index] + (posts + 2);

        p5.push();
        p5.rectMode(p5.CENTER);

        if (endingCorner) {
          p5.translate(
            x2 -
              xComponent * (linearEndCorner ? linearRunReductionFactor() : 1),
            y2 - yComponent * (linearEndCorner ? linearRunReductionFactor() : 1)
          );
        } else {
          p5.translate(x2, y2);
        }

        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }

    // Draw intermediate posts.
    for (let i = 0; i < posts; i++) {
      let xCord = x1 + (i + 1) * xDistance;
      let yCord = y1 + (i + 1) * yDistance;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(angle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (
        typeof index !== "undefined" &&
        canvas.labels.runs === "show" &&
        showLabels
      ) {
        const label = runIndices[index] + (i + 2);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }
  };

export const cornerDistance = () => {
  return 10;
};

export const drawStraightRun = (p5, sketch, settings) => (coordinates) => {
  let { x1, y1, x2, y2 } = coordinates;

  const dy = Math.abs(y2 - y1);
  const dx = Math.abs(x2 - x1);

  if (dy > dx) {
    x2 = x1;
  } else {
    y2 = y1;
  }

  // Runs must have distance.
  if (
    Math.floor(Math.abs(x2 - x1)) === 0 &&
    Math.floor(Math.abs(y2 - y1)) === 0
  ) {
    return;
  }

  // Calculate number of intermediate posts and distance vectors.
  const { posts, xDistance, yDistance } = calculateNumPosts(
    coordinates.set("x1", x1).set("y1", y1).set("x2", x2).set("y2", y2),
    settings
  );

  // Calculate angle of rotation for posts.
  const angle = Math.atan((y2 - y1) / (x2 - x1));

  // Draw run.
  p5.stroke(0);
  p5.strokeWeight(2);
  p5.line(x1, y1, x2, y2);

  // Draw end posts.
  p5.rectMode(p5.CENTER);

  // Draw first end post.
  p5.push();
  p5.fill(126);
  p5.strokeWeight(2);
  p5.translate(x1, y1);
  p5.rotate(angle);

  if (
    settings.postMaterial === "stainless-steel" &&
    settings.stainlessPostShape === "round"
  ) {
    p5.circle(0, 0, 10);
  } else {
    p5.rect(0, 0, 10);
  }

  p5.pop();

  // Draw second end post.
  p5.push();
  p5.fill(126);
  p5.strokeWeight(2);
  p5.translate(x2, y2);
  p5.rotate(angle);

  if (
    settings.postMaterial === "stainless-steel" &&
    settings.stainlessPostShape === "round"
  ) {
    p5.circle(0, 0, 10);
  } else {
    p5.rect(0, 0, 10);
  }

  p5.pop();

  // Draw intermediate posts.
  for (let i = 0; i < posts; i++) {
    let xCord = x1 + (i + 1) * xDistance;
    let yCord = y1 + (i + 1) * yDistance;
    p5.push();
    p5.fill(255);
    p5.strokeWeight(2);
    p5.translate(xCord, yCord);
    p5.rotate(angle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();
  }

  // Draw midpoint length measurement.
  const midpoint = {
    x: Math.floor((x2 - x1) / 2),
    y: Math.floor((y2 - y1) / 2),
  };

  const distance = distanceInFeet({ x1, y1, x2, y2 });

  p5.push();
  p5.rectMode(p5.CENTER);
  p5.translate(x1, y1);
  p5.translate(midpoint.x, midpoint.y);
  p5.translate(30 * Math.sin(angle), -30 * Math.cos(angle));
  p5.strokeWeight(0);
  p5.textAlign(p5.CENTER, p5.CENTER);
  p5.text(distance, 0, 0);
  p5.pop();
};

export const drawStairsPost = (p5) => (post, stairs, settings) => {
  const { x, y, phantom } = post;

  const angle = stairs.getIn(["rotate", "angle"]);

  if (!phantom) {
    p5.push();
    p5.angleMode(p5.DEGREES);
    p5.rectMode(p5.CENTER);
    p5.translate(x, y);
    p5.rotate(angle);
    p5.fill(126);
    p5.strokeWeight(2);
    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }
    p5.pop();
  } else {
    p5.push();
    p5.translate(-5, -5);
    p5.translate(x, y);
    p5.fill(126);
    p5.strokeWeight(2);
    p5.line(0, 0, 0, 10);
    p5.line(0, 5, 10, 5);
    p5.pop();
  }
};

export const drawStairsRun =
  (
    p5,
    settings,
    stairs,
    shiftDown,
    corners,
    runId = null,
    run,
    sketch,
    state,
    index,
    canvas
  ) =>
  (coordinates, stairsRun) => {
    let { x1, y1, x2, y2 } = coordinates;

    const dy = Math.abs(y2 - y1);
    const dx = Math.abs(x2 - x1);

    if (shiftDown) {
      if (dy > dx) {
        x2 = x1;
      } else {
        y2 = y1;
      }
    }

    // Calculate run labels side
    const labelsSide = run.getIn(["labels", "runLabels"]);

    let labelSide = 1;

    if (labelsSide) {
      labelSide = 1;
    } else {
      labelSide = -1;
    }

    const distanceLabelsSide = run.getIn(["labels", "distanceLabel"]);

    let distanceLabelSide = -1;

    if (distanceLabelsSide) {
      distanceLabelSide = -1;
    } else {
      distanceLabelSide = 1;
    }

    let startingCorner = null;
    let endingCorner = null;

    let hoveringEndPoint = false;
    let hoveringStartPoint = false;

    if (sketch && sketch.hoveringObject && run) {
      if (sketch.hoveringObject.run === run.id) {
        if (sketch.hoveringObject.type === "1") {
          hoveringStartPoint = true;
        }

        if (sketch.hoveringObject.type === "2") {
          hoveringEndPoint = true;
        }
      }
    }

    if (corners && corners.length) {
      const potentialCorners = corners
        .map((corner) => corner.runs.has(runId))
        .filter((included) => included);

      if (potentialCorners && potentialCorners.length) {
        startingCorner = corners.find((corner) => {
          return corner.runs.has(runId) && corner.points[runId].type === "1";
        });

        endingCorner = corners.find((corner) => {
          return corner.runs.has(runId) && corner.points[runId].type === "2";
        });
      }
    }

    // Runs must have distance.
    if (
      Math.floor(Math.abs(x2 - x1)) === 0 &&
      Math.floor(Math.abs(y2 - y1)) === 0
    ) {
      return;
    }

    const theStairs = stairs.get(stairsRun.stairsIndex);
    const endPost = stairsRun.endPost;

    // Calculate number of intermediate posts and distance vectors.
    const { posts, xDistance, yDistance } = calculateNumPosts(
      run.set("x1", x1).set("y1", y1).set("x2", endPost.x).set("y2", endPost.y),
      settings,
      // Stairs angle in radians.
      (theStairs.angle * Math.PI) / 180
    );

    const {
      posts: postsRemaining,
      xDistance: xDistanceRemaining,
      yDistance: yDistanceRemaining,
    } = calculateNumPosts(
      run.set("x1", endPost.x).set("y1", endPost.y).set("x2", x2).set("y2", y2),
      settings
    );

    // Calculate angle of rotation for posts.
    const angle = Math.atan((y2 - y1) / (x2 - x1));

    const xComponent = roundToHundreth(Math.cos(angle)) * -1 * cornerDistance();
    const yComponent = roundToHundreth(Math.sin(angle)) * -1 * cornerDistance();

    let stairsAngle = 0;

    if (theStairs) {
      stairsAngle = theStairs.getIn(["rotate", "angle"]) * (Math.PI / 180);
    }

    // Draw run.
    p5.push();
    p5.stroke(0);

    if (run && !isRunSettingsEmpty(run.settings)) {
      p5.stroke(255, 154, 0);
    }

    if (settings.customPostSpacing > 5) {
      p5.stroke(255, 0, 0);
    }

    if (run && (run.getIn(["settings", "customPostSpacing"]) || 0) > 5) {
      p5.stroke(255, 0, 0);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      if (endingCorner) {
        p5.line(
          x1 - xComponent,
          y1 - yComponent,
          x2 + xComponent,
          y2 + yComponent
        );
      } else {
        p5.line(x1 - xComponent, y1 - yComponent, x2, y2);
      }
    } else {
      if (endingCorner) {
        p5.line(x1, y1, x2 + xComponent, y2 + yComponent);
      } else {
        p5.line(x1, y1, x2, y2);
      }
    }
    p5.pop();

    // Draw end posts.
    p5.rectMode(p5.CENTER);
    p5.angleMode(p5.RADIANS);

    // Draw first end post.
    p5.push();
    p5.fill(126);

    if (hoveringStartPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (startingCorner) {
      p5.translate(x1 - xComponent, y1 - yComponent);
    } else {
      p5.translate(x1, y1);
    }
    p5.rotate(stairsAngle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    // Draw Label.
    if (typeof index !== "undefined" && canvas.labels.runs === "show") {
      const label = runIndices[index] + "1";

      p5.push();
      p5.rectMode(p5.CENTER);

      if (startingCorner) {
        p5.translate(x1 - xComponent, y1 - yComponent);
      } else {
        p5.translate(x1, y1);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }

    // Draw stairs post.
    if (valuesAreClose(x2, endPost.x, 2) !== valuesAreClose(y2, endPost.y, 2)) {
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(endPost.x, endPost.y);
      p5.rotate(stairsAngle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();
    }

    // Draw second end post.
    p5.push();
    p5.fill(126);

    if (hoveringEndPoint) {
      p5.stroke(0, 163, 255);
      p5.fill(171, 222, 251);
    }

    p5.strokeWeight(2);
    if (endingCorner) {
      p5.translate(x2 + xComponent, y2 + yComponent);
    } else {
      p5.translate(x2, y2);
    }
    p5.rotate(stairsAngle);

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "round"
    ) {
      p5.circle(0, 0, 10);
    } else {
      p5.rect(0, 0, 10);
    }

    p5.pop();

    // Draw end post Label.
    if (typeof index !== "undefined" && canvas.labels.runs === "show") {
      const label = runIndices[index] + (posts + postsRemaining + 3);

      p5.push();
      p5.rectMode(p5.CENTER);

      if (endingCorner) {
        p5.translate(x2 + xComponent, y2 + yComponent);
      } else {
        p5.translate(x2, y2);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }

    // Draw transitionary stairs post Label.
    if (typeof index !== "undefined" && canvas.labels.runs === "show") {
      const label = runIndices[index] + (posts + 2);

      p5.push();
      p5.rectMode(p5.CENTER);

      if (endingCorner) {
        p5.translate(endPost.x + xComponent, endPost.y + yComponent);
      } else {
        p5.translate(endPost.x, endPost.y);
      }

      p5.translate(
        20 * labelSide * Math.cos(angle + Math.PI / 2),
        20 * labelSide * Math.sin(angle + Math.PI / 2)
      );
      p5.rotate(angle);
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(label, 0, 0);
      p5.pop();
    }

    // Draw intermediate posts.
    for (let i = 0; i < posts; i++) {
      let xCord = x1 + (i + 1) * xDistance;
      let yCord = y1 + (i + 1) * yDistance;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(stairsAngle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (typeof index !== "undefined" && canvas.labels.runs === "show") {
        const label = runIndices[index] + (i + 2);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }

    // Draw intermediate posts for stairs to terminal post.
    for (let i = 0; i < postsRemaining; i++) {
      let xCord = endPost.x + (i + 1) * xDistanceRemaining;
      let yCord = endPost.y + (i + 1) * yDistanceRemaining;
      p5.push();
      p5.fill(255);
      p5.strokeWeight(2);
      p5.translate(xCord, yCord);
      p5.rotate(stairsAngle);

      if (
        settings.postMaterial === "stainless-steel" &&
        settings.stainlessPostShape === "round"
      ) {
        p5.circle(0, 0, 10);
      } else {
        p5.rect(0, 0, 10);
      }

      p5.pop();

      // Draw Label.
      if (typeof index !== "undefined" && canvas.labels.runs === "show") {
        const label = runIndices[index] + (i + posts + 3);

        p5.push();
        p5.rectMode(p5.CENTER);
        p5.translate(xCord, yCord);
        p5.translate(
          20 * labelSide * Math.cos(angle + Math.PI / 2),
          20 * labelSide * Math.sin(angle + Math.PI / 2)
        );
        p5.rotate(angle);
        p5.strokeWeight(0);
        p5.textAlign(p5.CENTER, p5.CENTER);
        p5.text(label, 0, 0);
        p5.pop();
      }
    }

    // Draw midpoint length measurement.
    const midpoint = {
      x: Math.floor((x2 - endPost.x) / 2),
      y: Math.floor((y2 - endPost.y) / 2),
    };

    const stairsMidpoint = {
      x: Math.floor((endPost.x - x1) / 2),
      y: Math.floor((endPost.y - y1) / 2),
    };

    const stairsDstance = hypotenuseInFeet(theStairs, run, endPost);

    const distanceOfRun = distanceInFeet({
      x1: endPost.x,
      y1: endPost.y,
      x2,
      y2,
    });

    // Stairs distance label.
    p5.push();
    p5.rectMode(p5.CENTER);
    p5.translate(x1, y1);
    p5.translate(stairsMidpoint.x, stairsMidpoint.y);
    p5.translate(
      50 * distanceLabelSide * Math.sin(angle),
      -50 * Math.cos(angle)
    );
    p5.strokeWeight(0);
    p5.textAlign(p5.CENTER, p5.CENTER);
    p5.text(stairsDstance, 0, 0);
    p5.pop();

    // Remaining run distance; do not draw if there is no distance.
    if (distanceOfRun !== "0' 0\"") {
      p5.push();
      p5.rectMode(p5.CENTER);
      p5.translate(endPost.x, endPost.y);
      p5.translate(midpoint.x, midpoint.y);
      p5.translate(
        50 * distanceLabelSide * Math.sin(angle),
        -50 * Math.cos(angle)
      );
      p5.strokeWeight(0);
      p5.textAlign(p5.CENTER, p5.CENTER);
      p5.text(distanceOfRun, 0, 0);
      p5.pop();
    }
  };

const stairsHypotenuseInFeet = function (stairs) {
  let side = 0;

  if (stairs.orientation === "vertical") {
    side = Math.abs(stairs.y2 - stairs.y1);
  } else {
    side = Math.abs(stairs.x2 - stairs.x1);
  }

  const angle = toRadians(stairs.angle);

  const distance = distanceOfHypotenuse(angle, side);

  return distance.feet + "' " + distance.inches + '"';
};

const hypotenuseInFeet = function (stairs, run, endPost) {
  if (!stairs) {
    return distanceInFeet({
      x1: run.x1,
      y1: run.y1,
      x2: endPost.x,
      y2: endPost.y,
    });
  }
  let side = 0;

  if (stairs.orientation === "vertical") {
    side = Math.abs(stairs.y2 - stairs.y1);
  } else {
    side = Math.abs(stairs.x2 - stairs.x1);
  }

  const angle = toRadians(stairs.angle);

  const distance = distanceOfHypotenuse(angle, side);

  return distance.feet + "' " + distance.inches + '"';
};

function getHypotenuseInFeet(stairs, run, stairsRun) {
  if (!stairs) {
    return distanceInFeet({
      x1: run.x1,
      y1: run.y1,
      x2: run.x2,
      y2: run.y2,
    });
  }

  const distance = getHypotenuseInFeetObject(run, stairs, stairsRun);

  return distance.feet + "' " + distance.inches + '"';
}

const valuesAreClose = (valueA, valueB, threshold = 1) => {
  if (Math.abs(valueB - valueA) <= threshold) {
    return true;
  }

  return false;
};
