import { CustomerRet } from "../data/parsers/customerParser";
import { Customer, ProjectSettings } from "./index.js";

export const getCustomerName = (customer: {
  data: any;
  quickbooksData: CustomerRet;
  version: string;
  name: string | undefined;
}) => {
  if (!customer) {
    return "";
  }

  if (customer?.version === "1") {
    if (customer.name) {
      return customer.name;
    }

    if (customer?.data?.name) {
      return customer.data.name;
    }
  }

  if (customer?.quickbooksData?.FullName) {
    return customer.quickbooksData.FullName.toString() || "";
  }

  if (Object.keys(customer.data).length) {
    if (customer.data.companyName) {
      return (customer.data.companyName || "") as string;
    } else {
      return `${customer.data.firstName || ""} ${
        customer.data.lastName || ""
      }`.trim();
    }
  } else {
    if (customer.quickbooksData.FullName) {
      return customer.quickbooksData.FullName.toString() || "";
    } else {
      return `${customer.quickbooksData.FirstName || ""} ${
        customer.quickbooksData.LastName || ""
      }`.trim();
    }
  }
};

export const formatCustomerPhone = (phone: string | number) => {
  if (typeof phone === "number") {
    return phone.toString();
  }

  if (!phone || !phone.endsWith) {
    return "";
  }

  if (phone.endsWith(" ext: ______")) {
    return phone.replace(" ext: ______", "");
  }

  if (phone.endsWith("_")) {
    return phone.replace(/_+/, "");
  }

  return phone;
};

export function getPhoneNumber(customer: any) {
  let phone = "";

  if (customer?.data?.phone) {
    phone = customer?.data?.phone;
  } else if (customer.quickbooksData?.Phone) {
    phone = customer.quickbooksData.Phone;
  }

  return phone;
}

export function getEmail(customer: any) {
  let email = "";

  if (customer?.data?.email) {
    email = customer?.data?.email;
  } else if (customer.quickbooksData?.Email) {
    email = customer.quickbooksData.Email;
  }

  return email;
}

export function customerType(customer: any) {
  let type = "";

  if (customer?.data?.customerType) {
    type = customer?.data?.customerType;
  } else if (customer.quickbooksData?.CustomerTypeRef?.FullName) {
    type = customer.quickbooksData?.CustomerTypeRef?.FullName;
  }

  return type;
}

export function getBillAddressFromCustomer(customer: Customer) {
  let lines: any[] = [];
  if (customer && customer.data && customer.data.billing) {
    if (customer.data.billing.name) {
      lines.push(customer.data.billing.name);
    } else {
      lines.push(getCustomerName(customer));
    }

    if (customer.data.billing.name) {
      lines.push(customer.data.billing.name);
    }

    if (customer.data.billing.street) {
      lines.push(customer.data.billing.street);
    }

    if (
      customer.data.billing.city &&
      customer.data.billing.state &&
      customer.data.billing.zip
    ) {
      lines.push(
        customer.data.billing.city +
          ", " +
          customer.data.billing.state +
          " " +
          customer.data.billing.zip
      );
    }
  } else if (
    customer &&
    customer.quickbooksData &&
    customer.quickbooksData.BillAddressBlock
  ) {
    lines = Object.values(customer.quickbooksData.BillAddressBlock).map(
      (item) => item
    );
  }

  return lines;
}

export function getShipAddressFromCustomer(
  customer: Customer,
  settings: ProjectSettings
) {
  const customerName = getCustomerName(customer);
  let lines: any[] = [];

  if (
    settings.shipToAddress.overrideQBAddress &&
    settings.shipToAddress &&
    settings.shipToAddress.address &&
    settings.shipToAddress.address.street
  ) {
    let name = "";
    if (settings.shipToAddress.address.name) {
      name = settings.shipToAddress.address.name;
    } else {
      name = customerName;
    }
    if (name) {
      lines.push(name);
    }

    if (settings.shipToAddress.address.street) {
      lines.push(settings.shipToAddress.address.street);
    }

    if (
      settings.shipToAddress.address.city &&
      settings.shipToAddress.address.state &&
      settings.shipToAddress.address.zip
    ) {
      lines.push(
        settings.shipToAddress.address.city +
          ", " +
          settings.shipToAddress.address.state +
          " " +
          settings.shipToAddress.address.zip
      );
    }
  } else if (customer && customer.data && customer.data.shipping) {
    if (customer.data.shipping.name) {
      lines.push(customer.data.shipping.name);
    } else {
      lines.push(customerName);
    }

    if (customer.data.shipping.street) {
      lines.push(customer.data.shipping.street);
    }

    if (
      customer.data.shipping.city &&
      customer.data.shipping.state &&
      customer.data.shipping.zip
    ) {
      lines.push(
        customer.data.shipping.city +
          ", " +
          customer.data.shipping.state +
          " " +
          customer.data.shipping.zip
      );
    }
  } else if (
    customer &&
    customer.quickbooksData &&
    customer.quickbooksData.ShipAddressBlock
  ) {
    lines = Object.values(customer.quickbooksData.ShipAddressBlock).map(
      (item) => item
    );
  }

  return lines;
}

export function shippingAddress(customer: any, settings: any) {
  return getShipAddressFromCustomer(customer, settings).join("\n");
}

export function billingAddress(customer: any) {
  return getBillAddressFromCustomer(customer).join("\n");
}
