import { Run } from "../../../entities";
import { PostInfo } from "../../../utils/getPosts";

export function postsForTheRun(thePosts: PostInfo[], run: Run) {
  const startValue: Record<string, number> = {};
  let postsForRun = thePosts.reduce((result, post) => {
    if (post.type && !result[post.type]) {
      result[post.type] = 0;
    }

    result[post.type]++;

    return result;
  }, startValue);

  if (!postsForRun.intermediate) {
    postsForRun.intermediate = 0;
  }

  if (run.getIn(["postTypes", "start"]) === "intermediate") {
    if (thePosts[0] && thePosts[0].type === "terminal") {
      postsForRun.terminal--;
      postsForRun.intermediate++;

      if (run.getIn(["endPosts", "start"]) === "phantom") {
        postsForRun.intermediate--;
      }
    }

    if (thePosts[0] && thePosts[0].type === "stairPostTerminal") {
      postsForRun.stairPostTerminal--;
      postsForRun.stairPostIntermediate++;

      if (run.getIn(["endPosts", "start"]) === "phantom") {
        postsForRun.stairPostIntermediate--;
      }
    }
  } else {
    if (thePosts[0] && thePosts[0].type === "terminal") {
      if (run.getIn(["endPosts", "start"]) === "phantom") {
        postsForRun.terminal--;
      }
    }

    if (thePosts[0] && thePosts[0].type === "stairPostTerminal") {
      if (run.getIn(["endPosts", "start"]) === "phantom") {
        postsForRun.stairPostTerminal--;
      }
    }
  }

  if (run.getIn(["postTypes", "end"]) === "intermediate") {
    if (
      thePosts[thePosts.length - 1] &&
      thePosts[thePosts.length - 1].type === "terminal"
    ) {
      postsForRun.terminal--;
      postsForRun.intermediate++;

      if (run.getIn(["endPosts", "end"]) === "phantom") {
        postsForRun.intermediate--;
      }
    }

    if (
      thePosts[thePosts.length - 1] &&
      thePosts[thePosts.length - 1].type === "stairPostTerminal"
    ) {
      postsForRun.stairPostTerminal--;
      postsForRun.stairPostIntermediate++;

      if (run.getIn(["endPosts", "end"]) === "phantom") {
        postsForRun.stairPostIntermediate--;
      }
    }
  } else {
    if (
      thePosts[thePosts.length - 1] &&
      thePosts[thePosts.length - 1].type === "terminal"
    ) {
      if (run.getIn(["endPosts", "end"]) === "phantom") {
        postsForRun.terminal--;
      }
    }

    if (
      thePosts[thePosts.length - 1] &&
      thePosts[thePosts.length - 1].type === "stairPostTerminal"
    ) {
      if (run.getIn(["endPosts", "end"]) === "phantom") {
        postsForRun.stairPostTerminal--;
      }
    }
  }

  if (postsForRun.terminal < 0) {
    postsForRun.terminal = 0;
  }

  return postsForRun;
}
