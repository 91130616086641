// @ts-nocheck
import { Set, List } from "immutable";

import { calculateAngleOfCorner, calculateCorners } from "../../utils/corners";
import {
  addItem,
  addItemForceQuantity,
  getRailDistance,
  isRightAngleCorner,
  sumGroup,
  toprailLength,
  toprailTubeSize,
} from "../RunItemList";
import { degreesToRadians } from "../../utils";
import { distanceToFeet, roundToNearestInch } from "..";
import {
  getHandrailInstallKitUpcs,
  handrailBracketHandrailConnectorUpcs,
  handrailBracketPostConnectorUpcs,
  handrailBracketUpcs,
  handrailCornersUpcs,
  handrailElbowUpcs,
  handrailEndCapUpcs,
  handrailLoopUpcs,
  handrailTubeUpcs,
  handrailWallReturnUpcs,
  p2pEndcap,
  ssFlat90DegCornerUpc,
  ssFlatEndCapUpc,
} from "./parts";
import {
  getDistancesFromPostGroupsForHandrail,
  getGroupedPostsForHandrail,
} from "../../draw";
import { getPosts } from "../../utils/getPosts";
import { Project } from "../../entities";

export function addHandrails(itemList, inventory, state) {
  const handrails = state.handrails;

  const chains = getHandrailChains(handrails, state);

  itemList = addHandrailTube(itemList, inventory, chains, state);
  itemList = addHandrailBrackets(itemList, inventory, chains, state);
  itemList = addHandrailInstallKits(itemList, inventory, chains, state);
  itemList = addHandrailLoop(itemList, inventory, chains, state);
  itemList = addHandrailEndCaps(itemList, inventory, chains, state);
  itemList = addHandrailWallReturns(itemList, inventory, chains, state);
  itemList = addHandrailCorners(itemList, inventory, chains, state);
  itemList = addHandrailElbows(itemList, inventory, chains, state);

  return itemList;
}

function addHandrailElbows(itemList, inventory, chains, state) {
  const elbows = getHandrailVerticalElbows(chains, state);

  const elbowUpcs = handrailElbowUpcs();

  Object.entries(elbowUpcs).forEach(([type, elbowTypes]) => {
    if (type === "link" || type === "alum-p2p") {
      Object.entries(elbowTypes).forEach(([color, upc]) => {
        if (elbows[type][color]) {
          itemList = addItemForceQuantity(
            itemList,
            inventory,
            upc,
            elbows[type][color]
          );
        }
      });
    } else {
      itemList = addItemForceQuantity(
        itemList,
        inventory,
        elbowTypes,
        elbows[type]
      );
    }
  });

  return itemList;
}

function addHandrailInstallKits(itemList, inventory, chains, state) {
  const installKits = getHandrailInstallKits(chains, state);

  const installKitUpcs = getHandrailInstallKitUpcs();

  Object.entries(installKitUpcs).forEach(([type, kit]) => {
    Object.entries(kit).forEach(([postType, upc]) => {
      if (installKits[type][postType]) {
        itemList = addItemForceQuantity(itemList, inventory, upc, 1);
      }
    });
  });

  return itemList;
}

function addHandrailCorners(itemList, inventory, chains, state) {
  const corners = getHandrailCorners(chains, state);

  const cornerUpcs = handrailCornersUpcs();

  Object.entries(cornerUpcs).forEach(([type, cornerTypes]) => {
    if (type === "link" || type === "alum-p2p") {
      Object.entries(cornerTypes).forEach(([color, corner]) => {
        Object.entries(corner).forEach(([cornerType, upc]) => {
          if (corners[type][color][cornerType]) {
            itemList = addItem(
              itemList,
              inventory,
              upc,
              corners[type][color][cornerType]
            );

            // There is no p2p adjustable elbow, instead add two endcaps.
            if (type === "alum-p2p" && cornerType === "adjustable") {
              const endCapUpc = p2pEndcap();

              itemList = addItem(
                itemList,
                inventory,
                endCapUpc,
                corners[type][color][cornerType] * 2
              );
            }
          }
        });
      });
    } else {
      Object.entries(cornerTypes).forEach(([cornerType, upc]) => {
        if (corners[type][cornerType]) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            corners[type][cornerType]
          );
        }
      });
    }
  });
  return itemList;
}

function getHandrailVerticalElbows(chains, state) {
  const verticalElbows = {
    link: {
      "anodized-black": 0,
      "anodized-clear": 0,
      black: 0,
      bronze: 0,
      clay: 0,
      Natural: 0,
      white: 0,
    },
    "alum-p2p": {
      "anodized-black": 0,
      "anodized-clear": 0,
      black: 0,
      bronze: 0,
      clay: 0,
      Natural: 0,
      white: 0,
    },
    "ss-flat": 0,
    "ss-round-2": 0,
    "ss-round-1.5": 0,
    "ss-2507-2": 0,
  };

  if (chains.attached.size) {
    chains.attached.forEach((chain) => {
      const handrail = chain[0];

      let stairsTransitions = 0;

      if (chain.length) {
        chain.forEach((handrail) => {
          if (handrail.run) {
            const run = state.runs.get(handrail.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter(
                (post) => post.type === "stairPostTransition"
              ).length;
            }
          }

          if (handrail.p1.run && handrail.p2.run) {
            const run = state.runs.get(handrail.p1.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter((post, index) => {
                return (
                  post.type === "stairPostTransition" &&
                  ((handrail.p1.postIndex > index &&
                    handrail.p2.postIndex < index) ||
                    (handrail.p1.postIndex < index &&
                      handrail.p2.postIndex > index))
                );
              }).length;
            }
          }
        });
      }

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      // Add elbows setups when stair transition posts are present.
      if (mergedSettings.type !== "alum-p2p") {
        if (mergedSettings.type === "link") {
          verticalElbows[mergedSettings.type][mergedSettings.linkColor] +=
            stairsTransitions;
        } else {
          verticalElbows[mergedSettings.type] += stairsTransitions;
        }
      }
    });
  }

  if (chains.hybrid.size) {
    chains.hybrid.forEach((chain) => {
      const handrail = chain[0];

      let stairsTransitions = 0;

      if (chain.length) {
        chain.forEach((handrail) => {
          if (handrail.run) {
            const run = state.runs.get(handrail.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter(
                (post) => post.type === "stairPostTransition"
              ).length;
            }
          }

          if (handrail.p1.run && handrail.p2.run) {
            const run = state.runs.get(handrail.p1.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter((post, index) => {
                return (
                  post.type === "stairPostTransition" &&
                  ((handrail.p1.postIndex > index &&
                    handrail.p2.postIndex < index) ||
                    (handrail.p1.postIndex < index &&
                      handrail.p2.postIndex > index))
                );
              }).length;
            }
          }
        });
      }

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      // Add elbows setups when stair transition posts are present.
      if (mergedSettings.type !== "alum-p2p") {
        if (mergedSettings.type === "link") {
          verticalElbows[mergedSettings.type][mergedSettings.linkColor] +=
            stairsTransitions;
        } else {
          verticalElbows[mergedSettings.type] += stairsTransitions;
        }
      }
    });
  }

  return verticalElbows;
}

function getHandrailCorners(chains, state) {
  const theCorners = {
    link: {
      "anodized-black": { right: 0, adjustable: 0 },
      "anodized-clear": { right: 0, adjustable: 0 },
      black: { right: 0, adjustable: 0 },
      bronze: { right: 0, adjustable: 0 },
      clay: { right: 0, adjustable: 0 },
      Natural: { right: 0, adjustable: 0 },
      white: { right: 0, adjustable: 0 },
    },
    "alum-p2p": {
      "anodized-black": { right: 0, adjustable: 0 },
      "anodized-clear": { right: 0, adjustable: 0 },
      black: { right: 0, adjustable: 0 },
      bronze: { right: 0, adjustable: 0 },
      clay: { right: 0, adjustable: 0 },
      Natural: { right: 0, adjustable: 0 },
      white: { right: 0, adjustable: 0 },
    },
    "ss-flat": { right: 0, adjustable: 0 },
    "ss-round-2": { right: 0, adjustable: 0 },
    "ss-round-1.5": { right: 0, adjustable: 0 },
    "ss-2507-2": { right: 0, adjustable: 0 },
  };

  const corners = calculateCorners(state.runs);

  if (chains.attached.size) {
    let seenCorners = Set();
    chains.attached.forEach((chain) => {
      corners.forEach((corner) => {
        chain.forEach((handrail) => {
          let runId = null;

          if (
            handrail.p1.run &&
            handrail.p2.run &&
            handrail.p1.run === handrail.p2.run
          ) {
            runId = handrail.p1.run;
          }

          if (handrail.run) {
            runId = handrail.run;
          }

          if (corner.runs.has(runId)) {
            const matchingRun = corner.runs.find((id) => id !== runId);

            const run = state.runs.get(matchingRun);

            const posts = getPosts(run, state.settings, state);

            const cornerType = corner.points[matchingRun].type;

            const potentialMatchingCorner = chain.find((matchHandrail) => {
              if (
                matchHandrail.p1.run &&
                matchHandrail.p2.run &&
                matchHandrail.p1.run === matchHandrail.p2.run &&
                matchingRun === matchHandrail.p1.run
              ) {
                if (cornerType === "1") {
                  if (
                    matchHandrail.p1.isEndCorner &&
                    matchHandrail.p1.postIndex === 0
                  ) {
                    return true;
                  }

                  if (
                    matchHandrail.p2.isEndCorner &&
                    matchHandrail.p2.postIndex === 0
                  ) {
                    return true;
                  }
                } else if (cornerType === "2") {
                  if (
                    matchHandrail.p1.isEndCorner &&
                    matchHandrail.p1.postIndex === posts.length - 1
                  ) {
                    return true;
                  }

                  if (
                    matchHandrail.p2.isEndCorner &&
                    matchHandrail.p2.postIndex === posts.length - 1
                  ) {
                    return true;
                  }
                } else {
                  return false;
                }
              }

              if (matchHandrail.run && matchingRun === matchHandrail.run) {
                return true;
              }

              return false;
            });

            // Found a corner match.
            if (potentialMatchingCorner) {
              if (!seenCorners.has(corner.runs)) {
                seenCorners = seenCorners.add(corner.runs);
                const settings = mergeHandrailSettings(
                  state.settings,
                  handrail
                );

                if (isRightAngleCorner(corner)) {
                  if (
                    settings.type === "link" ||
                    settings.type === "alum-p2p"
                  ) {
                    theCorners[settings.type][settings.linkColor]["right"]++;
                  } else {
                    if ( theCorners[settings.type] ) {
                      theCorners[settings.type]["right"]++;
                    }
                  }
                } else {
                  if (
                    settings.type === "link" ||
                    settings.type === "alum-p2p"
                  ) {
                    theCorners[settings.type][settings.linkColor][
                      "adjustable"
                    ]++;
                  } else {
                    if ( theCorners[settings.type] ) {
                      theCorners[settings.type]["adjustable"]++;
                    }
                  }
                }
              }
            }
          }
        });
      });
    });
  }

  if (chains.wall.size) {
    const wallCorners = chains.wall
      .map((chain) => {
        return cornersForChain(chain);
      })
      .filter((chain) => chain.length);

    wallCorners.forEach((chain) => {
      const handrail = state.handrails.get(chain[0].handrails.first());

      const settings = mergeHandrailSettings(state.settings, handrail);

      chain.forEach((corner) => {
        if (isRightAngleCorner(corner)) {
          if (settings.type === "link" || settings.type === "alum-p2p") {
            theCorners[settings.type][settings.linkColor]["right"]++;
          } else {
            if ( theCorners[settings.type] ) {
              theCorners[settings.type]["right"]++;
            }
          }
        } else {
          if (settings.type === "link" || settings.type === "alum-p2p") {
            theCorners[settings.type][settings.linkColor]["adjustable"]++;
          } else {
            if ( theCorners[settings.type] ) {
              theCorners[settings.type]["adjustable"]++;
            }
          }
        }
      });
    });
  }

  if (chains.hybrid.size) {
    const hybridCorners = chains.hybrid
      .map((chain) => {
        return cornersForChain(chain);
      })
      .filter((chain) => chain.length);

    hybridCorners.forEach((chain) => {
      const handrail = state.handrails.get(chain[0].handrails.first());

      const settings = mergeHandrailSettings(state.settings, handrail);

      chain.forEach((corner) => {
        if (isRightAngleCorner(corner)) {
          if (settings.type === "link" || settings.type === "alum-p2p") {
            theCorners[settings.type][settings.linkColor]["right"]++;
          } else {
            if ( theCorners[settings.type] ) {
              theCorners[settings.type]["right"]++;
            }
          }
        } else {
          if (settings.type === "link" || settings.type === "alum-p2p") {
            theCorners[settings.type][settings.linkColor]["adjustable"]++;
          } else {
            if ( theCorners[settings.type] ) {
              theCorners[settings.type]["adjustable"]++;
            }
          }
        }
      });
    });

    let seenCorners = Set();

    chains.hybrid.forEach((chain) => {
      corners.forEach((corner) => {
        chain.forEach((handrail) => {
          let runId = null;

          if (
            handrail.p1.run &&
            handrail.p2.run &&
            handrail.p1.run === handrail.p2.run
          ) {
            runId = handrail.p1.run;
          }

          if (handrail.run) {
            runId = handrail.run;
          }

          if (corner.runs.has(runId)) {
            const matchingRun = corner.runs.find((id) => id !== runId);

            const run = state.runs.get(matchingRun);

            const posts = getPosts(run, state.settings, state);

            const cornerType = corner.points[matchingRun].type;

            const potentialMatchingCorner = chain.find((matchHandrail) => {
              if (
                matchHandrail.p1.run &&
                matchHandrail.p2.run &&
                matchHandrail.p1.run === matchHandrail.p2.run &&
                matchingRun === matchHandrail.p1.run
              ) {
                if (cornerType === "1") {
                  if (
                    matchHandrail.p1.isEndCorner &&
                    matchHandrail.p1.postIndex === 0
                  ) {
                    return true;
                  }

                  if (
                    matchHandrail.p2.isEndCorner &&
                    matchHandrail.p2.postIndex === 0
                  ) {
                    return true;
                  }
                } else if (cornerType === "2") {
                  if (
                    matchHandrail.p1.isEndCorner &&
                    matchHandrail.p1.postIndex === posts.length - 1
                  ) {
                    return true;
                  }

                  if (
                    matchHandrail.p2.isEndCorner &&
                    matchHandrail.p2.postIndex === posts.length - 1
                  ) {
                    return true;
                  }
                } else {
                  return false;
                }
              }

              if (matchHandrail.run && matchingRun === matchHandrail.run) {
                return true;
              }

              return false;
            });

            // Found a corner match.
            if (potentialMatchingCorner) {
              if (!seenCorners.has(corner.runs)) {
                seenCorners = seenCorners.add(corner.runs);
                const settings = mergeHandrailSettings(
                  state.settings,
                  handrail
                );

                if (isRightAngleCorner(corner)) {
                  if (
                    settings.type === "link" ||
                    settings.type === "alum-p2p"
                  ) {
                    theCorners[settings.type][settings.linkColor]["right"]++;
                  } else {
                    if ( theCorners[settings.type] ) {
                      theCorners[settings.type]["right"]++;
                    }
                  }
                } else {
                  if (
                    settings.type === "link" ||
                    settings.type === "alum-p2p"
                  ) {
                    theCorners[settings.type][settings.linkColor][
                      "adjustable"
                    ]++;
                  } else {
                    if ( theCorners[settings.type] ) {
                      theCorners[settings.type]["adjustable"]++;
                    }
                  }
                }
              }
            }
          }
        });
      });
    });
  }

  return theCorners;
}

function cornersForChain(chain, state) {
  return chain.reduce((listOfCorners, handrail) => {
    chain
      .map((checkHandrail) => {
        if (checkHandrail.id === handrail.id) {
          return false;
        }

        if (
          handrail.p1.run &&
          handrail.p2.run &&
          checkHandrail.p1.run &&
          checkHandrail.p2.run
        ) {
          return false;
        }

        if (handrail.run && checkHandrail.run) {
          return false;
        }

        if (
          handrail.x1 === checkHandrail.x2 &&
          handrail.y1 === checkHandrail.y2
        ) {
          const angle = calculateAngleOfCorner(handrail, checkHandrail);

          return {
            handrails: Set([handrail.id, checkHandrail.id]),
            points: {
              [handrail.id]: { id: handrail.id, type: "1" },
              [checkHandrail.id]: { id: checkHandrail.id, type: "2" },
            },
            angle: angle,
          };
        }

        if (
          handrail.x1 === checkHandrail.x1 &&
          handrail.y1 === checkHandrail.y1
        ) {
          const angle = calculateAngleOfCorner(handrail, checkHandrail);
          return {
            handrails: Set([handrail.id, checkHandrail.id]),
            points: {
              [handrail.id]: { id: handrail.id, type: "1" },
              [checkHandrail.id]: { id: checkHandrail.id, type: "1" },
            },
            angle: angle,
          };
        }

        if (
          handrail.x2 === checkHandrail.x2 &&
          handrail.y2 === checkHandrail.y2
        ) {
          const angle = calculateAngleOfCorner(handrail, checkHandrail);
          return {
            handrails: Set([handrail.id, checkHandrail.id]),
            points: {
              [handrail.id]: { id: handrail.id, type: "2" },
              [checkHandrail.id]: { id: checkHandrail.id, type: "2" },
            },
            angle: angle,
          };
        }

        if (
          handrail.x2 === checkHandrail.x1 &&
          handrail.y2 === checkHandrail.y1
        ) {
          const angle = calculateAngleOfCorner(handrail, checkHandrail);
          return {
            handrails: Set([handrail.id, checkHandrail.id]),
            points: {
              [handrail.id]: { id: handrail.id, type: "2" },
              [checkHandrail.id]: { id: checkHandrail.id, type: "1" },
            },
            angle: angle,
          };
        }

        return false;
      })
      .filter((value) => value)
      .filter((corner) => {
        if (
          corner.angle === 180 ||
          corner.angle === -180 ||
          corner.angle === 0 ||
          corner.angle === -0
        ) {
          return false;
        }
        return true;
      })
      .forEach((value) => {
        // check for duplicate.
        let duplicate = false;

        listOfCorners.forEach((corner) => {
          let same = true;
          Object.values(corner.points).forEach((point) => {
            if (
              !value.points[point.id] ||
              (value.points[point.id] &&
                value.points[point.id].type !== point.type)
            ) {
              same = false;
            }
          });

          if (same === true) {
            duplicate = true;
          }
        });

        if (!duplicate) {
          listOfCorners.push(value);
        }
      });

    return listOfCorners;
  }, []);
}

function addHandrailWallReturns(itemList, inventory, chains, state) {
  const wallReturns = getHandrailWallReturns(chains, state);

  const wallReturnUpcs = handrailWallReturnUpcs();

  Object.entries(wallReturnUpcs).forEach(([type, object]) => {
    if (type === "alum-p2p" || type === "link") {
      Object.entries(object).forEach(([color, upc]) => {
        if (wallReturns[type][color].start || wallReturns[type][color].end) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            wallReturns[type][color].start + wallReturns[type][color].end
          );
        }
      });
    } else if (type === "ss-flat") {
      if (wallReturns[type].end || wallReturns[type].start) {
        // SS Flat wall return is made out of a 90deg elbow and endcap.
        const endCapUpc = ssFlatEndCapUpc();
        const cornerUpc = ssFlat90DegCornerUpc();

        if (wallReturns[type].end || wallReturns[type].start) {
          itemList = addItem(
            itemList,
            inventory,
            endCapUpc,
            wallReturns[type].end + wallReturns[type].start
          );
        }

        if (wallReturns[type].end || wallReturns[type].start) {
          itemList = addItem(
            itemList,
            inventory,
            cornerUpc,
            wallReturns[type].end + wallReturns[type].start
          );
        }
      }
    } else {
      if (wallReturns[type].end || wallReturns[type].start) {
        itemList = addItem(
          itemList,
          inventory,
          object,
          wallReturns[type].end + wallReturns[type].start
        );
      }
    }
  });

  return itemList;
}

function getHandrailWallReturns(chains, state) {
  const wallReturns = {
    link: {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "alum-p2p": {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "ss-flat": { start: 0, end: 0 },
    "ss-round-2": { start: 0, end: 0 },
    "ss-round-1.5": { start: 0, end: 0 },
    "ss-2507-2": { start: 0, end: 0 },
  };

  if (chains.attached.size) {
    chains.attached.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          wallReturns[mergedSettings.type].start++;
        }
      }

      if (mergedSettings.end.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          wallReturns[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.hybrid.size) {
    chains.hybrid.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          wallReturns[mergedSettings.type].start++;
        }
      }

      if (mergedSettings.end.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          wallReturns[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.wall.size) {
    chains.wall.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          wallReturns[mergedSettings.type].start++;
        }
      }

      if (mergedSettings.end.wallReturn === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          wallReturns[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          wallReturns[mergedSettings.type].end++;
        }
      }
    });
  }

  return wallReturns;
}

function addHandrailEndCaps(itemList, inventory, chains, state) {
  const endCaps = getHandrailEndCaps(chains, state);

  const endCapUpcs = handrailEndCapUpcs();

  Object.entries(endCapUpcs).forEach(([type, object]) => {
    if (type === "alum-p2p" || type === "link") {
      Object.entries(object).forEach(([color, upc]) => {
        if (endCaps[type][color].start || endCaps[type][color].end) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            endCaps[type][color].start + endCaps[type][color].end
          );
        }
      });
    } else {
      if (endCaps[type].end || endCaps[type].start) {
        itemList = addItem(
          itemList,
          inventory,
          object,
          endCaps[type].end + endCaps[type].start
        );
      }
    }
  });

  return itemList;
}

function getHandrailEndCaps(chains, state) {
  const endCaps = {
    link: {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "alum-p2p": {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "ss-flat": { start: 0, end: 0 },
    "ss-round-2": { start: 0, end: 0 },
    "ss-round-1.5": { start: 0, end: 0 },
    "ss-2507-2": { start: 0, end: 0 },
  };

  if (chains.attached.size) {
    chains.attached.forEach((chain) => {
      const handrail = chain[0];

      let stairsTransitions = 0;

      if (chain.length) {
        chain.forEach((handrail) => {
          if (handrail.run) {
            const run = state.runs.get(handrail.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter(
                (post) => post.type === "stairPostTransition"
              ).length;
            }
          }

          if (handrail.p1.run && handrail.p2.run) {
            const run = state.runs.get(handrail.p1.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter((post, index) => {
                return (
                  post.type === "stairPostTransition" &&
                  ((handrail.p1.postIndex > index &&
                    handrail.p2.postIndex < index) ||
                    (handrail.p1.postIndex < index &&
                      handrail.p2.postIndex > index))
                );
              }).length;
            }
          }
        });
      }

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          endCaps[mergedSettings.type].start++;
        }
      }

      // Add post endcaps to aluminum setups when stair transition posts are present.
      if (mergedSettings.type === "alum-p2p") {
        endCaps[mergedSettings.type][mergedSettings.linkColor].start +=
          stairsTransitions;

        endCaps[mergedSettings.type][mergedSettings.linkColor].end +=
          stairsTransitions;
      }

      if (mergedSettings.end.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          endCaps[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.hybrid.size) {
    chains.hybrid.forEach((chain) => {
      const handrail = chain[0];

      let stairsTransitions = 0;

      if (chain.length) {
        chain.forEach((handrail) => {
          if (handrail.run) {
            const run = state.runs.get(handrail.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter(
                (post) => post.type === "stairPostTransition"
              ).length;
            }
          }

          if (handrail.p1.run && handrail.p2.run) {
            const run = state.runs.get(handrail.p1.run);

            if (
              run &&
              run.stairs &&
              run.stairs.continuousStairs &&
              run.stairs.continuousStairs.size
            ) {
              const posts = getPosts(run, state.settings, state);

              stairsTransitions += posts.filter((post, index) => {
                return (
                  post.type === "stairPostTransition" &&
                  ((handrail.p1.postIndex > index &&
                    handrail.p2.postIndex < index) ||
                    (handrail.p1.postIndex < index &&
                      handrail.p2.postIndex > index))
                );
              }).length;
            }
          }
        });
      }

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          endCaps[mergedSettings.type].start++;
        }
      }

      // Add post endcaps to aluminum setups when stair transition posts are present.
      if (mergedSettings.type === "alum-p2p") {
        endCaps[mergedSettings.type][mergedSettings.linkColor].start +=
          stairsTransitions;

        endCaps[mergedSettings.type][mergedSettings.linkColor].end +=
          stairsTransitions;
      }

      if (mergedSettings.end.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          endCaps[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.wall.size) {
    chains.wall.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (mergedSettings.start.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].start++;
        } else {
          endCaps[mergedSettings.type].start++;
        }
      }

      if (mergedSettings.end.endCap === "include") {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          endCaps[mergedSettings.type][mergedSettings.linkColor].end++;
        } else {
          endCaps[mergedSettings.type].end++;
        }
      }
    });
  }

  return endCaps;
}

function addHandrailLoop(itemList, inventory, chains, state) {
  const loops = getHandrailLoops(chains, state);

  const loopsUpcs = handrailLoopUpcs();

  Object.entries(loopsUpcs).forEach(([type, object]) => {
    if (type === "alum-p2p" || type === "link") {
      Object.entries(object).forEach(([color, upc]) => {
        if (loops[type][color].start || loops[type][color].end) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            loops[type][color].start + loops[type][color].end
          );
        }
      });
    } else if (type === "ss-round-1.5") {
      Object.entries(object).forEach(([loopType, upc]) => {
        if (loops[type][loopType].end || loops[type][loopType].start) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            loops[type][loopType].end + loops[type][loopType].start
          );
        }
      });
    } else {
      if (loops[type].end || loops[type].start) {
        itemList = addItem(
          itemList,
          inventory,
          object,
          loops[type].end + loops[type].start
        );
      }
    }
  });

  return itemList;
}

function getHandrailLoops(chains, state) {
  const loops = {
    link: {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "alum-p2p": {
      "anodized-black": { start: 0, end: 0 },
      "anodized-clear": { start: 0, end: 0 },
      black: { start: 0, end: 0 },
      bronze: { start: 0, end: 0 },
      clay: { start: 0, end: 0 },
      Natural: { start: 0, end: 0 },
      white: { start: 0, end: 0 },
    },
    "ss-flat": { start: 0, end: 0 },
    "ss-round-2": { start: 0, end: 0 },
    "ss-round-1.5": { include: { start: 0, end: 0 }, 24: { start: 0, end: 0 } },
    "ss-2507-2": { start: 0, end: 0 },
  };

  if (chains.attached.size) {
    chains.attached.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (
        mergedSettings.start.loop === "include" ||
        mergedSettings.start.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].start++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.start.loop].start++;
        } else {
          loops[mergedSettings.type].start++;
        }
      }

      if (
        mergedSettings.end.loop === "include" ||
        mergedSettings.end.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].end++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.end.loop].end++;
        } else {
          loops[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.hybrid.size) {
    chains.hybrid.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (
        mergedSettings.start.loop === "include" ||
        mergedSettings.start.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].start++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.start.loop].start++;
        } else {
          loops[mergedSettings.type].start++;
        }
      }

      if (
        mergedSettings.end.loop === "include" ||
        mergedSettings.end.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].end++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.end.loop].end++;
        } else {
          loops[mergedSettings.type].end++;
        }
      }
    });
  }

  if (chains.wall.size) {
    chains.wall.forEach((chain) => {
      const handrail = chain[0];

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      if (
        mergedSettings.start.loop === "include" ||
        mergedSettings.start.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].start++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.start.loop].start++;
        } else {
          loops[mergedSettings.type].start++;
        }
      }

      if (
        mergedSettings.end.loop === "include" ||
        mergedSettings.end.loop === "24"
      ) {
        if (
          mergedSettings.type === "link" ||
          mergedSettings.type === "alum-p2p"
        ) {
          loops[mergedSettings.type][mergedSettings.linkColor].end++;
        } else if (mergedSettings.type === "ss-round-1.5") {
          loops[mergedSettings.type][mergedSettings.end.loop].end++;
        } else {
          loops[mergedSettings.type].end++;
        }
      }
    });
  }

  return loops;
}

function mergeHandrailSettings(settings, handrail) {
  const handrailSettings = {
    ...handrail.parts,
    start: { ...handrail.parts.start },
    end: { ...handrail.parts.end },
  };

  if (handrailSettings.type === "default") {
    handrailSettings.type = settings.handrailParts.type;
  }

  if (handrailSettings.linkColor === "default") {
    handrailSettings.linkColor = settings.handrailParts.linkColor;
  }

  if (handrailSettings.start.loop === "default") {
    handrailSettings.start.loop = settings.handrailParts.start.loop;
  }

  if (handrailSettings.start.wallReturn === "default") {
    handrailSettings.start.wallReturn = settings.handrailParts.start.wallReturn;
  }

  if (handrailSettings.start.endCap === "default") {
    handrailSettings.start.endCap = settings.handrailParts.start.endCap;
  }

  if (handrailSettings.end.loop === "default") {
    handrailSettings.end.loop = settings.handrailParts.end.loop;
  }

  if (handrailSettings.end.wallReturn === "default") {
    handrailSettings.end.wallReturn = settings.handrailParts.end.wallReturn;
  }

  if (handrailSettings.end.endCap === "default") {
    handrailSettings.end.endCap = settings.handrailParts.end.endCap;
  }

  return handrailSettings;
}

// Whether the post is round or if flat for mounting.
function postMountStyle(state) {
  if (state.settings.postMaterial === "stainless-steel") {
    if (state.settings.stainlessPostShape === "round") {
      return "round";
    }
  }

  return "flat";
}

function addHandrailBrackets(itemList, inventory, chains, state) {
  const brackets = getHandrailBrackets(chains, state);
  const bracketUpcs = handrailBracketUpcs();

  const mountStyle = postMountStyle(state);

  Object.entries(bracketUpcs).forEach(([type, upcs]) => {
    if (type === "link" || type === "alum-p2p") {
      Object.entries(upcs).forEach(([color, mount]) => {
        Object.entries(mount).forEach(([mountType, upc]) => {
          if (brackets[type][color][mountType]) {
            itemList = addItem(
              itemList,
              inventory,
              upc,
              brackets[type][color][mountType]
            );
          }
        });
      });
    } else if (type === "ss-round-2") {
      Object.entries(upcs).forEach(([mountType, upc]) => {
        if (brackets[type][mountType]) {
          itemList = addItem(
            itemList,
            inventory,
            upc[mountStyle],
            brackets[type][mountType]
          );
        }
      });
    } else {
      Object.entries(upcs).forEach(([mountType, upc]) => {
        if (brackets[type][mountType]) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            brackets[type][mountType]
          );
        }
      });
    }
  });

  const postConnectorUpcs = handrailBracketPostConnectorUpcs();

  Object.entries(postConnectorUpcs).forEach(([type, upc]) => {
    if (brackets[type]["post"]) {
      itemList = addItem(
        itemList,
        inventory,
        upc[mountStyle],
        brackets[type]["post"]
      );
    }
  });

  const handrailConnectorUpcs = handrailBracketHandrailConnectorUpcs();

  Object.entries(handrailConnectorUpcs).forEach(([type, connectors]) => {
    if (type === "link" || type === "alum-p2p") {
      // Aluminum grabrail does not have any handrail connectors.
    } else if (type === "ss-round-2") {
      if (brackets[type]["post"]) {
        itemList = addItem(
          itemList,
          inventory,
          connectors[mountStyle],
          brackets[type]["post"]
        );
      }
    } else {
      if (brackets[type]["post"]) {
        itemList = addItem(
          itemList,
          inventory,
          connectors,
          brackets[type]["post"]
        );
      }
    }
  });

  return itemList;
}

function getHandrailInstallKits(chains, state) {
  const kits = {
    "ss-flat": { intermediate: 0, terminal: 0 },
    "ss-round-2": { intermediate: 0, terminal: 0 },
    "ss-round-1.5": { intermediate: 0, terminal: 0 },
    "ss-2507-2": { intermediate: 0, terminal: 0 },
  };

  const hybridBrackets = chains.hybrid.map((chain) => {
    return getHandrailInstallKitsForChain(chain, state);
  });

  const attachedBrackets = chains.attached.map((chain) => {
    return getHandrailInstallKitsForChain(chain, state);
  });

  if (hybridBrackets.size) {
    hybridBrackets.forEach((theKits) => {
      Object.entries(theKits).forEach(([type, counts]) => {
        Object.entries(counts).forEach(([mountType, count]) => {
          kits[type][mountType] += count;
        });
      });
    });
  }

  if (attachedBrackets.size) {
    attachedBrackets.forEach((theKits) => {
      Object.entries(theKits).forEach(([type, counts]) => {
        Object.entries(counts).forEach(([mountType, count]) => {
          kits[type][mountType] += count;
        });
      });
    });
  }

  return kits;
}

function getHandrailBrackets(chains, state) {
  const brackets = {
    link: {
      "anodized-black": { wall: 0, post: 0 },
      "anodized-clear": { wall: 0, post: 0 },
      black: { wall: 0, post: 0 },
      bronze: { wall: 0, post: 0 },
      clay: { wall: 0, post: 0 },
      Natural: { wall: 0, post: 0 },
      white: { wall: 0, post: 0 },
    },
    "alum-p2p": {
      "anodized-black": { wall: 0, post: 0 },
      "anodized-clear": { wall: 0, post: 0 },
      black: { wall: 0, post: 0 },
      bronze: { wall: 0, post: 0 },
      clay: { wall: 0, post: 0 },
      Natural: { wall: 0, post: 0 },
      white: { wall: 0, post: 0 },
    },
    "ss-flat": { wall: 0, post: 0 },
    "ss-round-2": { wall: 0, post: 0 },
    "ss-round-1.5": { wall: 0, post: 0 },
    "ss-2507-2": { wall: 0, post: 0 },
  };

  const hybridBrackets = chains.hybrid.map((chain) => {
    return getHandrailBracketsForChain(chain, state);
  });

  const wallBrackets = chains.wall.map((chain) => {
    return getHandrailBracketsForChain(chain, state);
  });

  const attachedBrackets = chains.attached.map((chain) => {
    return getHandrailBracketsForChain(chain, state);
  });

  if (hybridBrackets.size) {
    hybridBrackets.forEach((theBrackets) => {
      Object.entries(theBrackets).forEach(([type, counts]) => {
        if (type === "link" || type === "alum-p2p") {
          Object.entries(counts).forEach(([color, wallPost]) => {
            Object.entries(wallPost).forEach(([mountType, count]) => {
              brackets[type][color][mountType] += count;
            });
          });
        } else {
          Object.entries(counts).forEach(([mountType, count]) => {
            brackets[type][mountType] += count;
          });
        }
      });
    });
  }

  if (wallBrackets.size) {
    wallBrackets.forEach((theBrackets) => {
      Object.entries(theBrackets).forEach(([type, counts]) => {
        if (type === "link" || type === "alum-p2p") {
          Object.entries(counts).forEach(([color, wallPost]) => {
            Object.entries(wallPost).forEach(([mountType, count]) => {
              brackets[type][color][mountType] += count;
            });
          });
        } else {
          Object.entries(counts).forEach(([mountType, count]) => {
            brackets[type][mountType] += count;
          });
        }
      });
    });
  }

  if (attachedBrackets.size) {
    attachedBrackets.forEach((theBrackets) => {
      Object.entries(theBrackets).forEach(([type, counts]) => {
        if (type === "link" || type === "alum-p2p") {
          Object.entries(counts).forEach(([color, wallPost]) => {
            Object.entries(wallPost).forEach(([mountType, count]) => {
              brackets[type][color][mountType] += count;
            });
          });
        } else {
          Object.entries(counts).forEach(([mountType, count]) => {
            brackets[type][mountType] += count;
          });
        }
      });
    });
  }

  return brackets;
}

function getHandrailInstallKitsForChain(chain, state) {
  const kits = {
    "ss-flat": { intermediate: 0, terminal: 0 },
    "ss-round-2": { intermediate: 0, terminal: 0 },
    "ss-round-1.5": { intermediate: 0, terminal: 0 },
    "ss-2507-2": { intermediate: 0, terminal: 0 },
  };

  chain.forEach((handrail) => {
    // Connected handrail.
    if (
      handrail.p1.run &&
      handrail.p2.run &&
      handrail.p1.run === handrail.p2.run
    ) {
      const postDiff = Math.abs(handrail.p2.postIndex - handrail.p1.postIndex);
      const posts = postDiff + 1;

      let type = "";
      if (handrail.parts.type === "default") {
        type = state.settings.handrailParts.type;
      } else {
        type = handrail.parts.type;
      }

      if (
        type === "ss-flat" ||
        type === "ss-round-2" ||
        type === "ss-round-1.5" ||
        type === "ss-2507-2"
      ) {
        const run = state.runs.get(handrail.p1.run);

        if (!run) {
          return;
        }

        const runPosts = getPosts(run, state.settings);

        if (
          handrail.p1.postIndex === 0 ||
          handrail.p1.postIndex === runPosts.length - 1
        ) {
          // Add terminal post.
          kits[type].terminal++;

          // Check if endpost is also terminal.
          if (
            handrail.p2.postIndex === 0 ||
            handrail.p2.postIndex === runPosts.length - 1
          ) {
            kits[type].terminal++;
            // Total posts minus the two terminal.
            kits[type].intermediate += posts - 2;
          } else {
            // Total posts minus one terminal.
            kits[type].intermediate += posts - 1;
          }
        } else {
          // Check if endpost is also terminal.
          if (
            handrail.p2.postIndex === 0 ||
            handrail.p2.postIndex === runPosts.length - 1
          ) {
            kits[type].terminal++;
            // Total posts minus only terminal.
            kits[type].intermediate += posts - 1;
          } else {
            // Total posts is intermediate count.
            kits[type].intermediate += posts;
          }
        }
      }
    }

    // Connected handrail.
    if (handrail.run) {
      const run = state.runs.get(handrail.run);
      const posts = getPosts(run, state.settings, state).length;

      let type = "";
      if (handrail.parts.type === "default") {
        type = state.settings.handrailParts.type;
      } else {
        type = handrail.parts.type;
      }

      if (
        type === "ss-flat" ||
        type === "ss-round-2" ||
        type === "ss-round-1.5" ||
        type === "ss-2507-2"
      ) {
        kits[type].terminal += 2;
        // Total posts minus the two terminal.
        kits[type].intermediate += posts - 2;
      }
    }

    // Hybrid Handrail.
    if (
      (handrail.p1.run || handrail.p2.run) &&
      (!handrail.p1.run || !handrail.p2.run)
    ) {
      let type = "";
      if (handrail.parts.type === "default") {
        type = state.settings.handrailParts.type;
      } else {
        type = handrail.parts.type;
      }

      if (
        type === "ss-flat" ||
        type === "ss-round-2" ||
        type === "ss-round-1.5" ||
        type === "ss-2507-2"
      ) {
        // If p1 is connected to run.
        if (handrail.p1.run) {
          const run = state.runs.get(handrail.p1.run);
          const posts = getPosts(run, state.settings, state);

          if (
            handrail.p1.postIndex === 0 ||
            handrail.p1.postIndex === posts.length - 1
          ) {
            // Only one terminal.
            kits[type].terminal += 1;
          } else {
            // Only one intermediate.
            kits[type].intermediate += 1;
          }
        } else if (handrail.p2.run) {
          const run = state.runs.get(handrail.p2.run);
          const posts = getPosts(run, state.settings, state);

          if (
            handrail.p2.postIndex === 0 ||
            handrail.p2.postIndex === posts.length - 1
          ) {
            // Only one terminal.
            kits[type].terminal += 1;
          } else {
            // Only one intermediate.
            kits[type].intermediate += 1;
          }
        }
      }
    }
  });

  return kits;
}

function getHandrailBracketsForChain(chain, theState) {
  let state = Project( theState.toJS() );

  state = theState.setIn( [ 'settings' ], "postSpacing", "4");

  const brackets = {
    link: {
      "anodized-black": { wall: 0, post: 0 },
      "anodized-clear": { wall: 0, post: 0 },
      black: { wall: 0, post: 0 },
      bronze: { wall: 0, post: 0 },
      clay: { wall: 0, post: 0 },
      Natural: { wall: 0, post: 0 },
      white: { wall: 0, post: 0 },
    },
    "alum-p2p": {
      "anodized-black": { wall: 0, post: 0 },
      "anodized-clear": { wall: 0, post: 0 },
      black: { wall: 0, post: 0 },
      bronze: { wall: 0, post: 0 },
      clay: { wall: 0, post: 0 },
      Natural: { wall: 0, post: 0 },
      white: { wall: 0, post: 0 },
    },
    "ss-flat": { wall: 0, post: 0 },
    "ss-round-2": { wall: 0, post: 0 },
    "ss-round-1.5": { wall: 0, post: 0 },
    "ss-2507-2": { wall: 0, post: 0 },
  };

  chain.forEach((handrail) => {
    // Connected handrail.
    if (
      handrail.p1.run &&
      handrail.p2.run &&
      handrail.p1.run === handrail.p2.run
    ) {
      const postDiff = Math.abs(handrail.p2.postIndex - handrail.p1.postIndex);
      const posts = postDiff + 1;

      if (handrail.parts.type === "default") {
        const type = state.settings.handrailParts.type;
        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["post"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["post"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["post"] += posts;
          }
        }
      } else {
        const type = handrail.parts.type;

        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["post"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["post"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["post"] += posts;
          }
        }
      }
    }

    // Connected handrail.
    if (handrail.run) {
      const run = state.runs.get(handrail.run);
      const posts = getPosts(run, state.settings, state).length;

      if (handrail.parts.type === "default") {
        const type = state.settings.handrailParts.type;
        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["post"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["post"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["post"] += posts;
          }
        }
      } else {
        const type = handrail.parts.type;

        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["post"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["post"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["post"] += posts;
          }
        }
      }
    }

    // Hybrid Handrail.
    if (
      (handrail.p1.run || handrail.p2.run) &&
      (!handrail.p1.run || !handrail.p2.run)
    ) {
      if (handrail.p1.stairsIndex) {
        handrail = handrail.set("stairs", {
          stairsIndex: handrail.p1.stairsIndex,
        });
      }

      if (handrail.p2.stairsIndex) {
        handrail = handrail.set("stairs", {
          stairsIndex: handrail.p2.stairsIndex,
        });
      }

      let posts = getPosts(handrail, state.settings, state).length;

      if (handrail.parts.type === "default") {
        const type = state.settings.handrailParts.type;
        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["wall"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["wall"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["wall"] += posts;
          }
        }
      } else {
        const type = handrail.parts.type;

        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["wall"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["wall"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["wall"] += posts;
          }
        }
      }
    }

    // Wall Mounted Handrail.
    if (!handrail.p1.run && !handrail.p2.run && !handrail.run) {
      if (handrail.p1.stairsIndex) {
        handrail = handrail.set("stairs", {
          stairsIndex: handrail.p1.stairsIndex,
        });
      }

      if (handrail.p2.stairsIndex) {
        handrail = handrail.set("stairs", {
          stairsIndex: handrail.p2.stairsIndex,
        });
      }

      const posts = getPosts(handrail, state.settings, state).length;

      if (handrail.parts.type === "default") {
        const type = state.settings.handrailParts.type;
        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["wall"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["wall"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["wall"] += posts;
          }
        }
      } else {
        const type = handrail.parts.type;

        if (type === "alum-p2p" || type === "link") {
          if (handrail.parts.linkColor === "default") {
            const color = state.settings.handrailParts.linkColor;

            brackets[type][color]["wall"] += posts;
          } else {
            const color = handrail.parts.linkColor;

            brackets[type][color]["wall"] += posts;
          }
        } else {
          if ( brackets[type] ) {
            brackets[type]["wall"] += posts;
          }
        }
      }
    }
  });

  return brackets;
}

function addHandrailTube(itemList, inventory, chains, state) {
  const lengths = getHandrailLengths(chains, state);

  const tubeUpcs = handrailTubeUpcs();

  Object.entries(tubeUpcs).forEach(([type, upcs]) => {
    if (type === "link") {
      Object.entries(upcs).forEach(([color, upc]) => {
        if (lengths[type][color]) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            lengths[type][color] * toprailTubeSize(state),
            "ft"
          );
        }
      });
    } else if (type === "alum-p2p") {
      Object.entries(upcs).forEach(([color, upc]) => {
        if (lengths[type][color]) {
          itemList = addItem(
            itemList,
            inventory,
            upc,
            lengths[type][color],
            "ft"
          );
        }
      });
    } else if (
      type === "ss-flat" ||
      type === "ss-round-2" ||
      type === "ss-round-1.5" ||
      type === "ss-2507-2"
    ) {
      if (lengths[type]) {
        // SS measured in feet.
        itemList = addItem(
          itemList,
          inventory,
          upcs,
          lengths[type] * toprailTubeSize(state),
          "ft"
        );
      }
    } else {
      if (lengths[type]) {
        itemList = addItem(itemList, inventory, upcs, lengths[type]);
      }
    }
  });

  return itemList;
}

function getHandrailLengths(chains, state) {
  const lengths = {
    link: {
      "anodized-black": [],
      "anodized-clear": [],
      black: [],
      bronze: [],
      clay: [],
      Natural: [],
      white: [],
    },
    "alum-p2p": {
      "anodized-black": [],
      "anodized-clear": [],
      black: [],
      bronze: [],
      clay: [],
      Natural: [],
      white: [],
    },
    "ss-flat": [],
    "ss-round-2": [],
    "ss-round-1.5": [],
    "ss-2507-2": [],
  };

  const hybridLengths = chains.hybrid.map((chain) => {
    return getHandrailLengthsForChain(chain, state);
  });

  const wallLengths = chains.wall.map((chain) => {
    return getHandrailLengthsForChain(chain, state);
  });

  const attachedLengths = chains.attached.map((chain) => {
    return getHandrailLengthsForChain(chain, state);
  });

  if (hybridLengths.size) {
    hybridLengths.forEach((theLengths) => {
      lengths.link["anodized-black"] = lengths.link["anodized-black"].concat(
        theLengths.link["anodized-black"]
      );
      lengths.link["anodized-clear"] = lengths.link["anodized-clear"].concat(
        theLengths.link["anodized-clear"]
      );
      lengths.link.black = lengths.link.black.concat(theLengths.link.black);
      lengths.link.bronze = lengths.link.bronze.concat(theLengths.link.bronze);
      lengths.link.clay = lengths.link.clay.concat(theLengths.link.clay);
      lengths.link.Natural = lengths.link.Natural.concat(
        theLengths.link.Natural
      );
      lengths.link.white = lengths.link.white.concat(theLengths.link.white);
      lengths["alum-p2p"]["anodized-black"] = lengths["alum-p2p"][
        "anodized-black"
      ].concat(theLengths["alum-p2p"]["anodized-black"]);
      lengths["alum-p2p"]["anodized-clear"] = lengths["alum-p2p"][
        "anodized-clear"
      ].concat(theLengths["alum-p2p"]["anodized-clear"]);
      lengths["alum-p2p"].black = lengths["alum-p2p"].black.concat(
        theLengths["alum-p2p"].black
      );
      lengths["alum-p2p"].bronze = lengths["alum-p2p"].bronze.concat(
        theLengths["alum-p2p"].bronze
      );
      lengths["alum-p2p"].clay = lengths["alum-p2p"].clay.concat(
        theLengths["alum-p2p"].clay
      );
      lengths["alum-p2p"].Natural = lengths["alum-p2p"].Natural.concat(
        theLengths["alum-p2p"].Natural
      );
      lengths["alum-p2p"].white = lengths["alum-p2p"].white.concat(
        theLengths["alum-p2p"].white
      );
      lengths["ss-flat"] = lengths["ss-flat"].concat(theLengths["ss-flat"]);
      lengths["ss-round-2"] = lengths["ss-round-2"].concat(
        theLengths["ss-round-2"]
      );
      lengths["ss-round-1.5"] = lengths["ss-round-1.5"].concat(
        theLengths["ss-round-1.5"]
      );
      lengths["ss-2507-2"] = lengths["ss-2507-2"].concat(
        theLengths["ss-2507-2"]
      );
    });
  }

  if (wallLengths.size) {
    wallLengths.forEach((theLengths) => {
      lengths.link["anodized-black"] = lengths.link["anodized-black"].concat(
        theLengths.link["anodized-black"]
      );
      lengths.link["anodized-clear"] = lengths.link["anodized-clear"].concat(
        theLengths.link["anodized-clear"]
      );
      lengths.link.black = lengths.link.black.concat(theLengths.link.black);
      lengths.link.bronze = lengths.link.bronze.concat(theLengths.link.bronze);
      lengths.link.clay = lengths.link.clay.concat(theLengths.link.clay);
      lengths.link.Natural = lengths.link.Natural.concat(
        theLengths.link.Natural
      );
      lengths.link.white = lengths.link.white.concat(theLengths.link.white);
      lengths["alum-p2p"]["anodized-black"] = lengths["alum-p2p"][
        "anodized-black"
      ].concat(theLengths["alum-p2p"]["anodized-black"]);
      lengths["alum-p2p"]["anodized-clear"] = lengths["alum-p2p"][
        "anodized-clear"
      ].concat(theLengths["alum-p2p"]["anodized-clear"]);
      lengths["alum-p2p"].black = lengths["alum-p2p"].black.concat(
        theLengths["alum-p2p"].black
      );
      lengths["alum-p2p"].bronze = lengths["alum-p2p"].bronze.concat(
        theLengths["alum-p2p"].bronze
      );
      lengths["alum-p2p"].clay = lengths["alum-p2p"].clay.concat(
        theLengths["alum-p2p"].clay
      );
      lengths["alum-p2p"].Natural = lengths["alum-p2p"].Natural.concat(
        theLengths["alum-p2p"].Natural
      );
      lengths["alum-p2p"].white = lengths["alum-p2p"].white.concat(
        theLengths["alum-p2p"].white
      );
      lengths["ss-flat"] = lengths["ss-flat"].concat(theLengths["ss-flat"]);
      lengths["ss-round-2"] = lengths["ss-round-2"].concat(
        theLengths["ss-round-2"]
      );
      lengths["ss-round-1.5"] = lengths["ss-round-1.5"].concat(
        theLengths["ss-round-1.5"]
      );
      lengths["ss-2507-2"] = lengths["ss-2507-2"].concat(
        theLengths["ss-2507-2"]
      );
    });
  }

  if (attachedLengths.size) {
    attachedLengths.forEach((theLengths) => {
      lengths.link["anodized-black"] = lengths.link["anodized-black"].concat(
        theLengths.link["anodized-black"]
      );
      lengths.link["anodized-clear"] = lengths.link["anodized-clear"].concat(
        theLengths.link["anodized-clear"]
      );
      lengths.link.black = lengths.link.black.concat(theLengths.link.black);
      lengths.link.bronze = lengths.link.bronze.concat(theLengths.link.bronze);
      lengths.link.clay = lengths.link.clay.concat(theLengths.link.clay);
      lengths.link.Natural = lengths.link.Natural.concat(
        theLengths.link.Natural
      );
      lengths.link.white = lengths.link.white.concat(theLengths.link.white);
      lengths["alum-p2p"]["anodized-black"] = lengths["alum-p2p"][
        "anodized-black"
      ].concat(theLengths["alum-p2p"]["anodized-black"]);
      lengths["alum-p2p"]["anodized-clear"] = lengths["alum-p2p"][
        "anodized-clear"
      ].concat(theLengths["alum-p2p"]["anodized-clear"]);
      lengths["alum-p2p"].black = lengths["alum-p2p"].black.concat(
        theLengths["alum-p2p"].black
      );
      lengths["alum-p2p"].bronze = lengths["alum-p2p"].bronze.concat(
        theLengths["alum-p2p"].bronze
      );
      lengths["alum-p2p"].clay = lengths["alum-p2p"].clay.concat(
        theLengths["alum-p2p"].clay
      );
      lengths["alum-p2p"].Natural = lengths["alum-p2p"].Natural.concat(
        theLengths["alum-p2p"].Natural
      );
      lengths["alum-p2p"].white = lengths["alum-p2p"].white.concat(
        theLengths["alum-p2p"].white
      );
      lengths["ss-flat"] = lengths["ss-flat"].concat(theLengths["ss-flat"]);
      lengths["ss-round-2"] = lengths["ss-round-2"].concat(
        theLengths["ss-round-2"]
      );
      lengths["ss-round-1.5"] = lengths["ss-round-1.5"].concat(
        theLengths["ss-round-1.5"]
      );
      lengths["ss-2507-2"] = lengths["ss-2507-2"].concat(
        theLengths["ss-2507-2"]
      );
    });
  }

  lengths.link["anodized-black"] = calculateLengthOfTube(
    lengths.link["anodized-black"]
  );
  lengths.link["anodized-clear"] = calculateLengthOfTube(
    lengths.link["anodized-clear"]
  );
  lengths.link.black = calculateLengthOfTube(lengths.link.black);
  lengths.link.bronze = calculateLengthOfTube(lengths.link.bronze);
  lengths.link.clay = calculateLengthOfTube(lengths.link.clay);
  lengths.link.Natural = calculateLengthOfTube(lengths.link.Natural);
  lengths.link.white = calculateLengthOfTube(lengths.link.white);
  lengths["alum-p2p"]["anodized-black"] = calculateP2PLengthOfTube(
    lengths["alum-p2p"]["anodized-black"]
  );
  lengths["alum-p2p"]["anodized-clear"] = calculateP2PLengthOfTube(
    lengths["alum-p2p"]["anodized-clear"]
  );
  lengths["alum-p2p"].black = calculateP2PLengthOfTube(
    lengths["alum-p2p"].black
  );
  lengths["alum-p2p"].bronze = calculateP2PLengthOfTube(
    lengths["alum-p2p"].bronze
  );
  lengths["alum-p2p"].clay = calculateP2PLengthOfTube(lengths["alum-p2p"].clay);
  lengths["alum-p2p"].Natural = calculateP2PLengthOfTube(
    lengths["alum-p2p"].Natural
  );
  lengths["alum-p2p"].white = calculateP2PLengthOfTube(
    lengths["alum-p2p"].white
  );
  lengths["ss-flat"] = calculateLengthOfTube(lengths["ss-flat"]);
  lengths["ss-round-2"] = calculateLengthOfTube(lengths["ss-round-2"]);
  lengths["ss-round-1.5"] = calculateLengthOfTube(lengths["ss-round-1.5"]);
  lengths["ss-2507-2"] = calculateLengthOfTube(lengths["ss-2507-2"]);

  return lengths;
}

function calculateP2PLengthOfTube(lengths) {
  return Math.ceil(
    lengths.reduce((sum, length) => {
      return sum + length;
    }, 0)
  );
}

function calculateLengthOfTube(lengths) {
  const toprailDivisor = toprailLength();

  lengths = lengths
    .flatMap((length) => {
      const divisor = Math.ceil(length / toprailDivisor);

      const lengths = [];
      // Calculate the split of the length for instance if we have a 21 ft run this will end up being 10.5 for the two run lengths in the middle.
      const divisorValue = length / divisor;

      for (let i = 0; i < divisor; i++) {
        lengths.push(divisorValue);
      }

      return lengths;
    })
    .sort((a, b) => {
      return b - a;
    });

  let groups = [].fill([], 0, lengths.length);

  lengths.forEach((_) => {
    groups.push([]);
  });

  lengths.forEach((length) => {
    for (let currentGroup = 0; currentGroup < groups.length; currentGroup++) {
      const currentSum = sumGroup(groups[currentGroup]);

      if (currentSum + length <= toprailDivisor) {
        groups[currentGroup].push(length);
        break;
      }
    }
  });

  groups = groups.filter((group) => group.length > 0);

  return groups.length;
}

function getHandrailLengthsForChain(chain, state) {
  const lengths = {
    link: {
      "anodized-black": [],
      "anodized-clear": [],
      black: [],
      bronze: [],
      clay: [],
      Natural: [],
      white: [],
    },
    "alum-p2p": {
      "anodized-black": [],
      "anodized-clear": [],
      black: [],
      bronze: [],
      clay: [],
      Natural: [],
      white: [],
    },
    "ss-flat": [],
    "ss-round-2": [],
    "ss-round-1.5": [],
    "ss-2507-2": [],
  };

  chain.forEach((handrail) => {
    // Connected handrail.
    if (
      handrail.p1.run &&
      handrail.p2.run &&
      handrail.p1.run === handrail.p2.run
    ) {
      const run = state.runs.get(handrail.p1.run);

      if (
        run &&
        run.stairs &&
        run.stairs.continuousStairs &&
        run.stairs.continuousStairs.size
      ) {
        const posts = getPosts(run, state.settings, state);
        const groupedPosts = getGroupedPostsForHandrail(posts, handrail);

        Object.entries(groupedPosts).forEach(([key, value]) => {
          if (value.length < 2) {
            return;
          }

          const { distance } = getDistancesFromPostGroupsForHandrail(
            handrail,
            run,
            state.stairs,
            value,
            key
          );

          const length = roundToNearestInch(distanceToFeet(distance));

          const mergedSettings = mergeHandrailSettings(
            state.settings,
            handrail
          );

          const type = mergedSettings.type;

          if (type === "alum-p2p" || type === "link") {
            const color = mergedSettings.linkColor;

            lengths[type][color].push(length);
          } else {
            if ( lengths[type] ) {
              lengths[type].push(length);
            }
          }
        });
      } else {
        const coordinates = {
          x1: handrail.p1.x,
          y1: handrail.p1.y,
          x2: handrail.p2.x,
          y2: handrail.p2.y,
        };

        const length = roundToNearestInch(
          distanceToFeet(getRailDistance(run, state.stairs, coordinates))
        );

        const mergedSettings = mergeHandrailSettings(state.settings, handrail);

        const type = mergedSettings.type;

        if (type === "alum-p2p" || type === "link") {
          const color = mergedSettings.linkColor;

          lengths[type][color].push(length);
        } else {
          if ( lengths[type] ) {
            lengths[type].push(length);
          }
        }
      }
    }

    // Connected handrail.
    if (handrail.run) {
      const run = state.runs.get(handrail.run);

      if (
        run &&
        run.stairs &&
        run.stairs.continuousStairs &&
        run.stairs.continuousStairs.size
      ) {
        const posts = getPosts(run, state.settings, state);
        const groupedPosts = getGroupedPostsForHandrail(posts, handrail);

        Object.entries(groupedPosts).forEach(([key, value]) => {
          const { distance } = getDistancesFromPostGroupsForHandrail(
            handrail,
            run,
            state.stairs,
            value,
            key
          );

          const length = roundToNearestInch(distanceToFeet(distance));

          const mergedSettings = mergeHandrailSettings(
            state.settings,
            handrail
          );

          const type = mergedSettings.type;

          if (type === "alum-p2p" || type === "link") {
            const color = mergedSettings.linkColor;

            lengths[type][color].push(length);
          } else {
            if ( lengths[type] ) {
              lengths[type].push(length);
            }
          }
        });
      } else {
        const length = roundToNearestInch(
          distanceToFeet(getRailDistance(run, state.stairs))
        );

        const mergedSettings = mergeHandrailSettings(state.settings, handrail);

        const type = mergedSettings.type;

        if (type === "alum-p2p" || type === "link") {
          const color = mergedSettings.linkColor;

          lengths[type][color].push(length);
        } else {
          if ( lengths[type] ) {
            lengths[type].push(length);
          }
        }
      }
    }

    // Hybrid Handrail.
    if (
      (handrail.p1.run || handrail.p2.run) &&
      (!handrail.p1.run || !handrail.p2.run)
    ) {
      const length = roundToNearestInch(
        distanceToFeet(getRailDistance(handrail, state.stairs))
      );

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      const type = mergedSettings.type;

      if (type === "alum-p2p" || type === "link") {
        const color = mergedSettings.linkColor;

        lengths[type][color].push(length);
      } else {
        if ( lengths[type] ) {
          lengths[type].push(length);
        }
      }
    }

    // Wall Mounted Handrail.
    if (!handrail.p1.run && !handrail.p2.run && !handrail.run) {
      let length = roundToNearestInch(
        distanceToFeet(getRailDistance(handrail, state.stairs))
      );

      if (
        handrail.p1.stairsIndex &&
        handrail.p2.stairsIndex &&
        handrail.p1.stairsIndex === handrail.p2.stairsIndex
      ) {
        const theStairs = state.stairs.get(handrail.p1.stairsIndex);
        length = roundToNearestInch(
          distanceToFeet(
            getRailDistance(handrail, state.stairs) /
              Math.cos(degreesToRadians(theStairs.angle))
          )
        );
      }

      const mergedSettings = mergeHandrailSettings(state.settings, handrail);

      const type = mergedSettings.type;
      if (type === "alum-p2p" || type === "link") {
        const color = mergedSettings.linkColor;

        lengths[type][color].push(length);
      } else {
        if ( lengths[type] ) {
          lengths[type].push(length);
        }
      }
    }
  });

  return lengths;
}

export function getHandrailChains(handrails, state) {
  const corners = calculateCorners(state.runs);

  const postsAndCorner = state.runs.map((run) => {
    const item = {
      id: run.id,
      posts: getPosts(run, state.settings, state),
    };

    const runCorners = corners.filter((runCorner) => {
      if (runCorner.points[run.id]) {
        return true;
      }

      return false;
    });

    item.corners = runCorners;

    return item;
  });

  const runChains = postsAndCorner.reduce(
    getChainRuns(postsAndCorner, Set([])),
    []
  );

  const handrailsAttachedToRun = handrails.filter((handrail) => {
    return (handrail.p1.run && handrail.p2.run) || handrail.run;
  });

  const wallMountedHandrails = handrails.filter((handrail) => {
    return !(handrail.p1.run && handrail.p2.run) && !handrail.run;
  });

  const handrailChains = getHandrailAttachedChains(
    handrailsAttachedToRun,
    runChains
  );

  const wallMountedChains = getWallMountedChains(wallMountedHandrails);

  const connectedChains = getConnectedChains(
    wallMountedChains,
    handrailChains,
    state.runs,
    state.settings
  );

  return {
    attached: connectedChains.handrailChains,
    wall: connectedChains.wallMountedChains,
    hybrid: connectedChains.hybridChains,
  };
}

const getConnectedChains = (
  wallMountedChains,
  handrailChains,
  runs,
  settings
) => {
  wallMountedChains = List(wallMountedChains);
  handrailChains = List(handrailChains);
  let hybridChains = List();

  const wallMountedChainAttachedToRuns = wallMountedChains.filter(
    (wallMountedChain) => {
      const attachedAndWallMounted = wallMountedChain.find((handrail) => {
        return handrail.p1.run || handrail.p2.run;
      });

      if (attachedAndWallMounted) {
        return true;
      }

      return false;
    }
  );

  if (wallMountedChainAttachedToRuns && wallMountedChainAttachedToRuns.size) {
    const connectingHandrails = wallMountedChainAttachedToRuns
      .map((chain) => {
        return chain.filter((handrail) => {
          return handrail.p1.run || handrail.p2.run;
        });
      })
      .filter((chain) => chain)
      .reduce((list, chain) => {
        chain.forEach((handrail) => {
          list = list.push(handrail);
        });
        return list;
      }, List());

    const pairs = [];

    if (connectingHandrails && connectingHandrails.size) {
      connectingHandrails.forEach((connectingHandrail) => {
        handrailChains.forEach((handrailChain) => {
          const matchingHandrail = handrailChain.find((attachedHandrail) => {
            return handrailsMatchPoints(
              connectingHandrail,
              attachedHandrail,
              runs,
              settings
            );
          });

          if (matchingHandrail) {
            pairs.push([connectingHandrail, matchingHandrail]);
          }
        });
      });
    }

    if (pairs && pairs.length) {
      pairs.forEach((pair) => {
        let wallMountedChainIndex = 0;
        let handrailChainIndex = 0;
        let hybridChainIndex = 0;

        if (hybridChains.size) {
          hybridChainIndex = 0;
          hybridChains.forEach((hybridChain) => {
            hybridChain.forEach((hybridHandrail) => {
              const matchingHybridPair = pair.find((handrail) => {
                return handrail.id === hybridHandrail.id;
              });

              if (matchingHybridPair) {
                wallMountedChainIndex = 0;
                wallMountedChains.forEach((wallMountedChain) => {
                  wallMountedChain.forEach((wallHandrail) => {
                    const matchingWallMount = pair.find((handrail) => {
                      return handrail.id === wallHandrail.id;
                    });

                    if (matchingWallMount) {
                      const newChain = hybridChains
                        .get(hybridChainIndex)
                        .concat(wallMountedChains.get(wallMountedChainIndex));
                      hybridChains = hybridChains.set(
                        hybridChainIndex,
                        newChain
                      );
                      wallMountedChains = wallMountedChains.delete(
                        wallMountedChainIndex
                      );
                    }
                  });
                  wallMountedChainIndex++;
                });

                handrailChainIndex = 0;
                handrailChains.forEach((handrailChain) => {
                  handrailChain.forEach((postHandrail) => {
                    const matchingPostMount = pair.find((handrail) => {
                      return handrail.id === postHandrail.id;
                    });

                    if (matchingPostMount) {
                      const newChain = hybridChains
                        .get(hybridChainIndex)
                        .concat(handrailChains.get(handrailChainIndex));
                      hybridChains = hybridChains.set(
                        hybridChainIndex,
                        newChain
                      );
                      handrailChains =
                        handrailChains.delete(handrailChainIndex);
                    }
                  });
                  handrailChainIndex++;
                });
              } else {
                const merged = mergeCombinedChains(
                  pair,
                  wallMountedChains,
                  handrailChains,
                  hybridChains
                );

                wallMountedChains = merged.wallMountedChains;
                handrailChains = merged.handrailChains;
                hybridChains = merged.hybridChains;
              }
            });
            hybridChainIndex++;
          });
        } else {
          const merged = mergeCombinedChains(
            pair,
            wallMountedChains,
            handrailChains,
            hybridChains
          );

          wallMountedChains = merged.wallMountedChains;
          handrailChains = merged.handrailChains;
          hybridChains = merged.hybridChains;
        }
      });
    }
  }

  return {
    hybridChains: hybridChains,
    handrailChains: handrailChains,
    wallMountedChains: wallMountedChains,
  };
};

const mergeCombinedChains = (
  pair,
  wallMountedChains,
  handrailChains,
  hybridChains
) => {
  let wallMountedChainIndex = 0;
  let handrailChainIndex = 0;
  wallMountedChainIndex = 0;
  wallMountedChains.forEach((wallMountedChain) => {
    wallMountedChain.forEach((wallMountedHandrail) => {
      const matchingWallPair = pair.find((handrail) => {
        return handrail.id === wallMountedHandrail.id;
      });

      if (matchingWallPair) {
        handrailChainIndex = 0;
        handrailChains.forEach((handrailChain) => {
          handrailChain.forEach((postMountedHandrail) => {
            const matchingPostPair = pair.find((handrail) => {
              return handrail.id === postMountedHandrail.id;
            });

            if (matchingPostPair) {
              const newChain = wallMountedChains
                .get(wallMountedChainIndex)
                .concat(handrailChains.get(handrailChainIndex));
              hybridChains = hybridChains.push(newChain);
              wallMountedChains = wallMountedChains.delete(
                wallMountedChainIndex
              );
              handrailChains = handrailChains.delete(handrailChainIndex);
            }
          });
          handrailChainIndex++;
        });
      }
    });
    wallMountedChainIndex++;
  });

  return {
    hybridChains,
    wallMountedChains,
    handrailChains,
  };
};

const handrailsMatchPoints = (
  connectingHandrail,
  attachedHandrail,
  runs,
  settings
) => {
  if (
    connectingHandrail.p1.run === attachedHandrail.p1.run &&
    connectingHandrail.p1.postIndex === attachedHandrail.p1.postIndex
  ) {
    return true;
  }

  if (
    connectingHandrail.p2.run === attachedHandrail.p1.run &&
    connectingHandrail.p2.postIndex === attachedHandrail.p1.postIndex
  ) {
    return true;
  }

  if (
    connectingHandrail.p1.run === attachedHandrail.p2.run &&
    connectingHandrail.p1.postIndex === attachedHandrail.p2.postIndex
  ) {
    return true;
  }

  if (
    connectingHandrail.p2.run === attachedHandrail.p2.run &&
    connectingHandrail.p2.postIndex === attachedHandrail.p2.postIndex
  ) {
    return true;
  }

  if (connectingHandrail.p1.run === attachedHandrail.run) {
    const run = runs.get(attachedHandrail.run);

    if (run) {
      const posts = getPosts(run, settings);
      const postsLength = posts.length - 1;
      const postsStart = 0;

      if (
        connectingHandrail.p1.postIndex === postsLength ||
        connectingHandrail.p1.postIndex === postsStart
      ) {
        return true;
      }
    }
  }

  if (connectingHandrail.p2.run === attachedHandrail.run) {
    const run = runs.get(attachedHandrail.run);

    if (run) {
      const posts = getPosts(run, settings);
      const postsLength = posts.length - 1;
      const postsStart = 0;

      if (
        connectingHandrail.p2.postIndex === postsLength ||
        connectingHandrail.p2.postIndex === postsStart
      ) {
        return true;
      }
    }
  }

  return false;
};

const getHandrailAttachedChains = (handrails, runChains) => {
  return handrails.reduce(
    getHandrailChainsReduce(handrails, runChains, Set([])),
    []
  );
};

const getWallMountedChains = (wallMountedHandrails) => {
  return wallMountedHandrails.reduce(
    wallMountedChainsReducer(Set([]), wallMountedHandrails),
    []
  );
};

const wallMountedChainsReducer =
  (alreadyAddedHandrails, handrails) => (chains, handrail) => {
    if (alreadyAddedHandrails.has(handrail.id)) {
      return chains;
    }

    let chain = [];

    alreadyAddedHandrails = alreadyAddedHandrails.add(handrail.id);
    chain.push(handrail);

    if (handrail.x1 && handrail.y1) {
      const matchRail = handrails.find((searchHandrail) => {
        return (
          searchHandrail.id !== handrail.id &&
          !alreadyAddedHandrails.has(searchHandrail.id) &&
          ((searchHandrail.x1 === handrail.x1 &&
            searchHandrail.y1 === handrail.y1) ||
            (searchHandrail.x2 === handrail.x1 &&
              searchHandrail.y2 === handrail.y1))
        );
      });

      if (matchRail) {
        const recurse = wallMountedChainsRecurse(
          matchRail,
          handrails,
          chain,
          alreadyAddedHandrails
        );

        chain = recurse.chain;
        alreadyAddedHandrails = recurse.alreadyAddedHandrails;
      }
    }

    if (handrail.x2 && handrail.y2) {
      const matchRail = handrails.find((searchHandrail) => {
        return (
          searchHandrail.id !== handrail.id &&
          !alreadyAddedHandrails.has(searchHandrail.id) &&
          ((searchHandrail.x1 === handrail.x2 &&
            searchHandrail.y1 === handrail.y2) ||
            (searchHandrail.x2 === handrail.x2 &&
              searchHandrail.y2 === handrail.y2))
        );
      });

      if (matchRail) {
        const recurse = wallMountedChainsRecurse(
          matchRail,
          handrails,
          chain,
          alreadyAddedHandrails
        );

        chain = recurse.chain;
        alreadyAddedHandrails = recurse.alreadyAddedHandrails;
      }
    }

    chains.push(chain);
    return chains;
  };

const wallMountedChainsRecurse = (
  handrail,
  handrails,
  chain,
  alreadyAddedHandrails
) => {
  if (alreadyAddedHandrails.has(handrail.id)) {
    return {
      chain,
      alreadyAddedHandrails,
    };
  }
  alreadyAddedHandrails = alreadyAddedHandrails.add(handrail.id);
  chain.push(handrail);

  if (handrail.x1 && handrail.y1) {
    const matchRail = handrails.find((searchHandrail) => {
      return (
        searchHandrail.id !== handrail.id &&
        !alreadyAddedHandrails.has(searchHandrail.id) &&
        ((searchHandrail.x1 === handrail.x1 &&
          searchHandrail.y1 === handrail.y1) ||
          (searchHandrail.x2 === handrail.x1 &&
            searchHandrail.y2 === handrail.y1))
      );
    });

    if (matchRail) {
      const recurse = wallMountedChainsRecurse(
        matchRail,
        handrails,
        chain,
        alreadyAddedHandrails
      );

      chain = recurse.chain;
      alreadyAddedHandrails = recurse.alreadyAddedHandrails;
    }
  }

  if (handrail.x2 && handrail.y2) {
    const matchRail = handrails.find((searchHandrail) => {
      return (
        searchHandrail.id !== handrail.id &&
        !alreadyAddedHandrails.has(searchHandrail.id) &&
        ((searchHandrail.x1 === handrail.x2 &&
          searchHandrail.y1 === handrail.y2) ||
          (searchHandrail.x2 === handrail.x2 &&
            searchHandrail.y2 === handrail.y2))
      );
    });

    if (matchRail) {
      const recurse = wallMountedChainsRecurse(
        matchRail,
        handrails,
        chain,
        alreadyAddedHandrails
      );

      chain = recurse.chain;
      alreadyAddedHandrails = recurse.alreadyAddedHandrails;
    }
  }

  return {
    chain,
    alreadyAddedHandrails,
  };
};

const getHandrailChainsReduce =
  (handrails, runChains, alreadyAddedHandrails) => (chains, handrail) => {
    if (alreadyAddedHandrails.has(handrail.id)) {
      return chains;
    }

    let chain = [];

    chain.push(handrail);
    alreadyAddedHandrails = alreadyAddedHandrails.add(handrail.id);

    runChains.forEach((runChain) => {
      const theRun = runChain.find((run) => {
        return (
          (handrail.p1.run === run.id && handrail.p2.run === run.id) ||
          handrail.run === run.id
        );
      });

      if (theRun) {
        const posts = theRun.posts;
        const postsLength = posts.length - 1;
        const postsStart = 0;

        if (handrail.p1.isEndCorner) {
          if (handrail.p1.postIndex === postsStart) {
            // Starting corner.
            const corner = theRun.corners.find((corner) => {
              return corner.points[theRun.id].type === "1";
            });

            if (corner) {
              const otherRunId = Object.values(corner.points).find((point) => {
                return point.id !== theRun.id;
              });

              const otherRun = runChain.find((run) => otherRunId.id === run.id);

              if (otherRun) {
                const recurse = getHandrailChainsRecurse(
                  handrails,
                  runChain,
                  otherRun,
                  alreadyAddedHandrails,
                  chain
                );

                chain = recurse.chain;
                alreadyAddedHandrails = recurse.alreadyAddedHandrails;
              }
            }
          }

          if (handrail.p1.postIndex === postsLength) {
            // Ending corner.
            const corner = theRun.corners.find((corner) => {
              return corner.points[theRun.id].type === "2";
            });

            if (corner) {
              const otherRunId = Object.values(corner.points).find((point) => {
                return point.id !== theRun.id;
              });

              const otherRun = runChain.find((run) => otherRunId.id === run.id);

              if (otherRun) {
                const recurse = getHandrailChainsRecurse(
                  handrails,
                  runChain,
                  otherRun,
                  alreadyAddedHandrails,
                  chain
                );

                chain = recurse.chain;
                alreadyAddedHandrails = recurse.alreadyAddedHandrails;
              }
            }
          }
        }

        if (handrail.p2.isEndCorner) {
          if (handrail.p2.postIndex === postsStart) {
            // Starting corner.
            const corner = theRun.corners.find((corner) => {
              return corner.points[theRun.id].type === "1";
            });

            if (corner) {
              const otherRunId = Object.values(corner.points).find((point) => {
                return point.id !== theRun.id;
              });

              const otherRun = runChain.find((run) => otherRunId.id === run.id);

              if (otherRun) {
                const recurse = getHandrailChainsRecurse(
                  handrails,
                  runChain,
                  otherRun,
                  alreadyAddedHandrails,
                  chain
                );

                chain = recurse.chain;
                alreadyAddedHandrails = recurse.alreadyAddedHandrails;
              }
            }
          }

          if (handrail.p2.postIndex === postsLength) {
            // Ending corner.
            const corner = theRun.corners.find((corner) => {
              return corner.points[theRun.id].type === "2";
            });

            if (corner) {
              const otherRunId = Object.values(corner.points).find((point) => {
                return point.id !== theRun.id;
              });

              const otherRun = runChain.find((run) => otherRunId.id === run.id);

              if (otherRun) {
                const recurse = getHandrailChainsRecurse(
                  handrails,
                  runChain,
                  otherRun,
                  alreadyAddedHandrails,
                  chain
                );

                chain = recurse.chain;
                alreadyAddedHandrails = recurse.alreadyAddedHandrails;
              }
            }
          }
        }

        if (handrail.run === theRun.id) {
          theRun.corners.forEach((corner) => {
            const otherRunId = Object.values(corner.points).find((point) => {
              return point.id !== theRun.id;
            });

            const otherRun = runChain.find((run) => otherRunId.id === run.id);

            if (otherRun) {
              const recurse = getHandrailChainsRecurse(
                handrails,
                runChain,
                otherRun,
                alreadyAddedHandrails,
                chain
              );

              chain = recurse.chain;
              alreadyAddedHandrails = recurse.alreadyAddedHandrails;
            }
          });
        }
      }
    });

    chains.push(chain);

    return chains;
  };

const getHandrailChainsRecurse = (
  handrails,
  runChain,
  run,
  alreadyAddedHandrails,
  chain
) => {
  const handrail = handrails.find((handrail) => {
    return (
      (handrail.p1.run === run.id && handrail.p2.run === run.id) ||
      handrail.run === run.id
    );
  });

  if (handrail && !alreadyAddedHandrails.has(handrail.id)) {
    alreadyAddedHandrails = alreadyAddedHandrails.add(handrail.id);
    chain.push(handrail);

    const posts = run.posts;
    const postsLength = posts.length - 1;
    const postsStart = 0;

    if (handrail.p1.isEndCorner) {
      if (handrail.p1.postIndex === postsStart) {
        // Starting corner.
        const corner = run.corners.find((corner) => {
          return corner.points[run.id].type === "1";
        });

        if (corner) {
          const otherRunId = Object.values(corner.points).find((point) => {
            return point.id !== run.id;
          });

          const otherRun = runChain.find((run) => otherRunId.id === run.id);

          if (otherRun) {
            const recurse = getHandrailChainsRecurse(
              handrails,
              runChain,
              otherRun,
              alreadyAddedHandrails,
              chain
            );

            chain = recurse.chain;
            alreadyAddedHandrails = recurse.alreadyAddedHandrails;
          }
        }
      }

      if (handrail.p1.postIndex === postsLength) {
        // Ending corner.
        const corner = run.corners.find((corner) => {
          return corner.points[run.id].type === "2";
        });

        if (corner) {
          const otherRunId = Object.values(corner.points).find((point) => {
            return point.id !== run.id;
          });

          const otherRun = runChain.find((run) => otherRunId.id === run.id);

          if (otherRun) {
            const recurse = getHandrailChainsRecurse(
              handrails,
              runChain,
              otherRun,
              alreadyAddedHandrails,
              chain
            );

            chain = recurse.chain;
            alreadyAddedHandrails = recurse.alreadyAddedHandrails;
          }
        }
      }
    }

    if (handrail.p2.isEndCorner) {
      if (handrail.p2.postIndex === postsStart) {
        // Starting corner.
        const corner = run.corners.find((corner) => {
          return corner.points[run.id].type === "1";
        });

        if (corner) {
          const otherRunId = Object.values(corner.points).find((point) => {
            return point.id !== run.id;
          });

          const otherRun = runChain.find((run) => otherRunId.id === run.id);

          if (otherRun) {
            const recurse = getHandrailChainsRecurse(
              handrails,
              runChain,
              otherRun,
              alreadyAddedHandrails,
              chain
            );

            chain = recurse.chain;
            alreadyAddedHandrails = recurse.alreadyAddedHandrails;
          }
        }
      }

      if (handrail.p2.postIndex === postsLength) {
        // Ending corner.
        const corner = run.corners.find((corner) => {
          return corner.points[run.id].type === "2";
        });

        if (corner) {
          const otherRunId = Object.values(corner.points).find((point) => {
            return point.id !== run.id;
          });

          const otherRun = runChain.find((run) => otherRunId.id === run.id);

          if (otherRun) {
            const recurse = getHandrailChainsRecurse(
              handrails,
              runChain,
              otherRun,
              alreadyAddedHandrails,
              chain
            );

            chain = recurse.chain;
            alreadyAddedHandrails = recurse.alreadyAddedHandrails;
          }
        }
      }
    }

    if (handrail.run === run.id) {
      run.corners.forEach((corner) => {
        const otherRunId = Object.values(corner.points).find((point) => {
          return point.id !== run.id;
        });

        const otherRun = runChain.find((run) => otherRunId.id === run.id);

        if (otherRun) {
          const recurse = getHandrailChainsRecurse(
            handrails,
            runChain,
            otherRun,
            alreadyAddedHandrails,
            chain
          );

          chain = recurse.chain;
          alreadyAddedHandrails = recurse.alreadyAddedHandrails;
        }
      });
    }

    return {
      chain,
      alreadyAddedHandrails,
    };
  } else {
    return {
      chain,
      alreadyAddedHandrails,
    };
  }
};

const getChainRuns = (runs, alreadyAddedRuns) => (chains, run) => {
  if (alreadyAddedRuns.has(run.id)) {
    return chains;
  }

  let chain = [];

  chain.push(run);
  alreadyAddedRuns = alreadyAddedRuns.add(run.id);

  if (run.corners && run.corners.length) {
    run.corners.forEach((corner) => {
      if (corner.points[run.id]) {
        Object.values(corner.points).forEach((point) => {
          if (!alreadyAddedRuns.has(point.id) && point.id !== run.id) {
            chain.push(runs.get(point.id));

            const recurse = getChainRunsRecurse(
              runs,
              runs.get(point.id),
              alreadyAddedRuns,
              chain
            );

            chain = recurse.chain;
            alreadyAddedRuns = recurse.seen;
          }
        });
      }
    });
  }

  chains.push(chain);

  return chains;
};

const getChainRunsRecurse = (runs, run, alreadyAddedRuns, chain = []) => {
  if (alreadyAddedRuns.has(run.id)) {
    return { chain: chain, seen: alreadyAddedRuns };
  }

  alreadyAddedRuns = alreadyAddedRuns.add(run.id);

  if (run.corners && run.corners.length) {
    run.corners.forEach((corner) => {
      if (corner.points[run.id]) {
        Object.values(corner.points).forEach((point) => {
          if (!alreadyAddedRuns.has(point.id) && point.id !== run.id) {
            chain.push(runs.get(point.id));

            const recurse = getChainRunsRecurse(
              runs,
              runs.get(point.id),
              alreadyAddedRuns,
              chain
            );

            chain = recurse.chain;
            alreadyAddedRuns = recurse.seen;
          }
        });
      }
    });

    return { chain: chain, seen: alreadyAddedRuns };
  }

  return { chain: chain, seen: alreadyAddedRuns };
};
