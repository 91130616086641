import { Set, Map } from "immutable";

export const calculateShapeCorners = (shapes) => {
  const corners = shapes.reduce((listOfCorners, shape, shapeId) => {
    shapes
      .map((checkShape) => {
        if (checkShape.id === shapeId) {
          return false;
        }

        if (shape.x1 === checkShape.x2 && shape.y1 === checkShape.y2) {
          const angle = calculateAngleOfCorner(shape, checkShape);
          return {
            shapes: Set([shape.id, checkShape.id]),
            points: {
              [shape.id]: { id: shape.id, type: "1" },
              [checkShape.id]: { id: checkShape.id, type: "2" },
            },
            angle: angle,
          };
        }

        if (shape.x1 === checkShape.x1 && shape.y1 === checkShape.y1) {
          const angle = calculateAngleOfCorner(shape, checkShape);
          return {
            shapes: Set([shape.id, checkShape.id]),
            points: {
              [shape.id]: { id: shape.id, type: "1" },
              [checkShape.id]: { id: checkShape.id, type: "1" },
            },
            angle: angle,
          };
        }

        if (shape.x2 === checkShape.x2 && shape.y2 === checkShape.y2) {
          const angle = calculateAngleOfCorner(shape, checkShape);
          return {
            shapes: Set([shape.id, checkShape.id]),
            points: {
              [shape.id]: { id: shape.id, type: "2" },
              [checkShape.id]: { id: checkShape.id, type: "2" },
            },
            angle: angle,
          };
        }

        return false;
      })
      .filter((value) => value)
      .forEach((value) => {
        // check for duplicate.
        let duplicate = false;

        listOfCorners.forEach((corner) => {
          let same = true;
          Object.values(corner.points).forEach((point) => {
            if (
              !value.points[point.id] ||
              (value.points[point.id] &&
                value.points[point.id].type !== point.type)
            ) {
              same = false;
            }
          });

          if (same === true) {
            duplicate = true;
          }
        });

        if (!duplicate) {
          listOfCorners.push(value);
        }
      });

    return listOfCorners;
  }, []);

  return corners;
};

const calculateAngle = (shape) => {
  const { x1, y1, x2, y2 } = shape;

  const dx = x2 - x1;
  const dy = y2 - y1;
  const angle = (Math.atan2(dy, dx) * 180) / Math.PI;

  return angle;
};

const calculateAngleOfCorner = (shape1, shape2) => {
  return calculateAngle(shape2) - calculateAngle(shape1);
};

export function shapeHasCorners(shape, corners) {
  let hasCorners = false;

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[shape.id]) {
        hasCorners = true;
      }
    });
  }

  return hasCorners;
}

export function getShapeChain(shape, corners, shapes, chain = Map()) {
  chain = chain.set(shape.id, shape.id);

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[shape.id]) {
        Object.values(corner.points).forEach((point) => {
          if (!chain.has(point.id) && point.id !== shape.id) {
            chain = chain.set(point.id, point.id);

            chain = getShapeChain(shapes.get(point.id), corners, shapes, chain);
          }
        });
      }
    });
  }

  return chain;
}

export function getShapeCorners(shape, corners) {
  let theCorners = [];

  if (corners.length) {
    corners.forEach((corner) => {
      if (corner.points[shape.id]) {
        theCorners.push(corner);
      }
    });
  }

  return theCorners;
}

export function getCornerType(shape, corner) {
  let type = "";

  Object.values(corner.points).forEach((point) => {
    if (point.id === shape.id) {
      type = point.type;
    }
  });

  return type;
}

export function getShapeChainFromCorner(
  shape,
  corner,
  corners,
  shapes,
  chain = Map()
) {
  chain = chain.set(shape.id, { shape: shape, corners: [corner] });

  let cornerShape;

  Object.values(corner.points).forEach((point) => {
    if (point.id !== shape.id) {
      cornerShape = shapes.get(point.id);

      if (!chain.has(cornerShape.id)) {
        chain = chain.set(cornerShape.id, {
          shape: cornerShape,
          corners: [corner],
        });
      }

      corners.forEach((checkCorner) => {
        // New Corner.
        if (
          checkCorner.points[cornerShape.id] &&
          !checkCorner.points[shape.id]
        ) {
          chain = chain.setIn([cornerShape.id, "corners", 1], checkCorner);
          let checkCornerId;

          Object.values(checkCorner.points).forEach((checkPoint) => {
            if (checkPoint.id !== cornerShape.id) {
              checkCornerId = checkPoint.id;
            }
          });
          if (!chain.has(checkCornerId)) {
            chain = chain.set(checkCornerId, {
              shape: shapes.get(checkCornerId),
              corners: [checkCorner],
            });

            chain = getShapeChainFromCorner(
              cornerShape,
              checkCorner,
              corners,
              shapes,
              chain
            );
          }
        }
      });
    }
  });

  return chain;
}
