// @ts-nocheck
/**
 * External dependencies.
 */
import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

/**
 * Internal dependencies.
 */
import Icon from "./Icon";
import {
  get20x20x4BoxDetails,
  get52x8x8BoxDetails,
  getFreightDetails,
} from "../partsList/ShippingCalculation";
import { getMasterItemList, getItemLists } from "../utils/partsList";
import { InventoryContext } from "../App";

const ShippingEstimate = (props) => {
  const state = useSelector((state) => state.state.present);
  const { setPosition, animation } = props;

  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  const inventoryList = useContext(InventoryContext);

  const masterItemList = getMasterItemList(
    getItemLists(state.canvases, inventoryList, state)
  );

  const the20x20x4BoxDetails = get20x20x4BoxDetails(masterItemList);
  const the52x8x8BoxDetails = get52x8x8BoxDetails(masterItemList);
  const theFreightDetails = getFreightDetails(masterItemList);

  return (
    <div
      {...bind()}
      onMouseDown={() => {
        dispatch({ type: "window/click", id: "shipping-estimate" });
      }}
      className="shipping-estimate"
    >
      <div className="shipping-estimate__header">
        <button
          className="app__tooltip-close"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "window/close-shipping-estimate" });
            }, 0);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
      <div className="shipping-estimate__content">
        <h2 className="shipping-estimate__heading">Shipping Estimate</h2>
        <div className="shipping-estimate__contents">
          <div className="shipping-estimate__section">
            <div className="shipping-estimate__label">
              Cable, Hardware and Material Components
            </div>
            <div className="shipping-estimate__box">
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Total Weight</div>
                <div className="shipping-estimate__box-value">
                  {the20x20x4BoxDetails.totalWeight}lbs
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Box Size</div>
                <div className="shipping-estimate__box-value">
                  {the20x20x4BoxDetails.boxSize}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Quantity of Boxes
                </div>
                <div className="shipping-estimate__box-value">
                  {the20x20x4BoxDetails.numberOfBoxes}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Weight per Box (w/ packaging)
                </div>
                <div className="shipping-estimate__box-value">
                  {the20x20x4BoxDetails.weightPerBoxAndPackaging}lbs
                </div>
              </div>
            </div>
          </div>
          <div className="shipping-estimate__section">
            <div className="shipping-estimate__label">Posts</div>
            <div className="shipping-estimate__box">
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Total Weight</div>
                <div className="shipping-estimate__box-value">
                  {the52x8x8BoxDetails.totalWeight}lbs
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Box Size</div>
                <div className="shipping-estimate__box-value">
                  {the52x8x8BoxDetails.boxSize}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Quantity of Boxes
                </div>
                <div className="shipping-estimate__box-value">
                  {the52x8x8BoxDetails.numberOfBoxes}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Weight per Box (w/ packaging)
                </div>
                <div className="shipping-estimate__box-value">
                  {the52x8x8BoxDetails.weightPerBoxAndPackaging}lbs
                </div>
              </div>
            </div>
          </div>
          <div className="shipping-estimate__section">
            <div className="shipping-estimate__label">Top Rail</div>
            <div className="shipping-estimate__box">
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Total Weight</div>
                <div className="shipping-estimate__box-value">
                  {theFreightDetails.totalWeight}lbs
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">Tube Size</div>
                <div className="shipping-estimate__box-value">
                  {theFreightDetails.boxSize}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Quantity of Tubes
                </div>
                <div className="shipping-estimate__box-value">
                  {theFreightDetails.numberOfBoxes}
                </div>
              </div>
              <div className="shipping-estimate__box-row">
                <div className="shipping-estimate__box-label">
                  Weight per Tube (w/ packaging)
                </div>
                <div className="shipping-estimate__box-value">
                  {theFreightDetails.weightPerBoxAndPackaging}lbs
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingEstimate;
