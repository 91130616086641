// @ts-nocheck
import Icon from "./Icon";

function getTooltipContent(tool) {
  switch (tool) {
    case "run":
      return (
        <p>
          <strong>Insert run tool selected:</strong> Click on the canvas to
          start a run. Single-click to complete a run. Double-click or press
          Escape to stop drawing. Hold Shift to draw runs at right angles.
        </p>
      );
    case "delete-selection":
      return (
        <p>
          <strong>Delete selection tool selected:</strong> Hover over an element
          on the canvas and click to delete it.
        </p>
      );
    case "shapes":
      return (
        <p>
          <strong>Insert shapes tool selected:</strong> Select a shape then
          click on the canvas and move mouse to start drawing. Single click to
          complete shape. drawing.
        </p>
      );
    case "gate":
      return (
        <p>
          <strong>Insert gate tool selected:</strong> Click on a post within a
          run and then click on a neighboring post to complete the gate.
        </p>
      );
    case "pan":
      return (
        <p>
          <strong>Pan tool selected:</strong> Click down and hold the mouse down
          and drag to move elements in the window.
        </p>
      );
    case "handrail":
      return (
        <p>
          <strong>Handrail tool selected:</strong> Click on a post within a run
          and then click on another post to complete the handrail. You can also
          free draw the handrail to indicate wall mounting of handrail.
        </p>
      );
    case "post":
      return (
        <p>
          <strong>Insert post tool selected:</strong> Click on the canvas where
          you would like to position a free standing post.
        </p>
      );
    case "image":
      return (
        <p>
          <strong>Image edit tool selected:</strong> Drag the image file you
          would like to upload into the canvas and release where you want the
          image to be centered. Use this tool to then resize the image.
        </p>
      );
    case "move":
      return (
        <p>
          <strong>Move tool selected:</strong> Click and drag an element other
          than a run to move its position on the canvas.
        </p>
      );
    case "note":
      return (
        <p>
          <strong>Insert note tool selected:</strong> Click on the canvas where
          you would want to place the note. Click within the text area to start
          editing the text. Hold down Ctrl and drag to move the note.
        </p>
      );
    case "stairs":
      return (
        <p>
          <strong>Stair/Ramp tool selected:</strong> Click on the canvas where
          you would want start a set of stairs, then drag the mouse and click to
          insert the stairs.
        </p>
      );
    case "edit-selection":
      return (
        <p>
          <strong>Edit selection tool selected:</strong> Hover over an element
          on the canvas and click to open its edit menu.
        </p>
      );
    default:
      return (
        <p>
          <strong>This tool has not been implemented yet.</strong>
        </p>
      );
  }
}

function Tooltip({ tool, dismiss, setDismiss }) {
  let permanentlyDismissed = false;

  if (sessionStorage && sessionStorage.getItem("dismissedTooltip")) {
    permanentlyDismissed = true;
  }

  if (tool === null || dismiss === true || permanentlyDismissed === true) {
    return null;
  } else {
    return (
      <div className="app__tooltip">
        <div className="app__tooltip-content">{getTooltipContent(tool)}</div>
        <button
          className="app__tooltip-close"
          onClick={() => {
            // Timeout is needed to keep dom element active for intersection check.
            setTimeout(function () {
              setDismiss(() => true);

              if (sessionStorage) {
                sessionStorage.setItem("dismissedTooltip", true);
              }
            }, 0);
          }}
        >
          <Icon icon="close" className="app__tooltip-close-icon" />
        </button>
      </div>
    );
  }
}

export default Tooltip;
