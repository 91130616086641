// @ts-nocheck
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";
import { Map, List } from "immutable";
import { v4 as uuid } from "uuid";

import Icon from "../../Icon";
import EditRunHardware from "./EditRunHardware";
import {
  distanceInFeetFromDistance,
  getRunSettings,
  maximumCustomPostSpacing,
  mergeSettings,
} from "../../../utils";
import debounce from "lodash.debounce";
import { calculateCorners, getRunChain } from "../../../utils/corners";
import { Handrail } from "../../../entities";
import SelectBox from "../../SelectBox";
import { getHandrailChains } from "../../../partsList/handrailParts";
import { getRailDistance } from "../../../partsList/RunItemList";
import { getPosts } from "../../../utils/getPosts";
import { postsForTheRun } from "../../../partsList/RunItemList/utils/postsForTheRun";

function dispatchNewCustomSpacing(dispatch, run, customPostSpacing) {
  dispatch({
    type: "runs/edit-spacing",
    run: run
      .setIn(["settings", "customPostSpacing"], customPostSpacing)
      .setIn(["settings", "postSpacing"], "custom"),
  });
}

function dispatchNewCustomRailHeight(dispatch, run, customRailHeight) {
  dispatch({
    type: "runs/edit",
    run: run
      .setIn(["settings", "customRailHeight"], customRailHeight)
      .setIn(["settings", "railHeight"], "custom"),
  });
}

function EditRunPopupHeader(props) {
  const { setView, animation, setPosition } = props;

  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <div className="edit-popup__header-tabs">
        <h2
          className="edit-popup__heading"
          onClick={() => {
            setView("controls");
          }}
        >
          Edit Run
        </h2>
        <h2
          className="edit-popup__heading"
          onClick={() => {
            setView("hardware");
          }}
        >
          Hardware
        </h2>
      </div>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function buttonClass(settings, value, setting = "railHeight") {
  let className = "edit-popup__three-button";

  if (settings[setting] === value) {
    className += " edit-popup__three-button--active";
  }

  return className;
}

function EditRunControls(props) {
  const { run, dispatch, posts, postsForRun, settings } = props;

  const state = useSelector((state) => state.state.present);
  const stairs = state.stairs;

  const runSettings = getRunSettings(run, settings);

  let railHeightClass = "edit-popup__three-buttons";

  if (run.getIn(["settings", "railHeight"])) {
    railHeightClass += " parts-select--override";
  }

  let customRailHeightClass = "";
  if (run.getIn(["settings", "customRailHeight"])) {
    customRailHeightClass += " parts-select--override";
  }

  let postSpacingClass = "edit-popup__three-buttons";

  if (run.getIn(["settings", "postSpacing"])) {
    postSpacingClass += " parts-select--override";
  }

  let customPostSpacingClass = "";
  if (run.getIn(["settings", "customPostSpacing"])) {
    customPostSpacingClass += " parts-select--override";
  }

  const handrailChains = getHandrailChains(state.handrails, state);

  const matchingChain = handrailChains.attached.find((chain) => {
    const theRun = chain.find((chainHandrail) => {
      return chainHandrail.run === run.id;
    });

    if (theRun) {
      return true;
    }

    return false;
  });

  let labelsShowClass = "edit-popup__three-button";
  let labelsHideClass = "edit-popup__three-button";

  if (run.getIn(["labels", "showLabels"])) {
    labelsShowClass += " edit-popup__three-button--active";
  } else {
    labelsHideClass += " edit-popup__three-button--active";
  }

  const [customPostSpacing, setCustomPostSpacing] = useState(
    runSettings.customPostSpacing
  );

  const [customRailHeight, setCustomRailHeight] = useState(
    runSettings.customRailHeight
  );

  const [numberOfPosts, setNumberOfPosts] = useState(posts.length);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const customSpacingCallback = useCallback(
    debounce(dispatchNewCustomSpacing, 200),
    [dispatchNewCustomSpacing]
  );

  const [showPostsLength, setShowPostsLength] = useState(true);

  useEffect(() => {
    return () => {
      customSpacingCallback.cancel();
    };
  }, [customSpacingCallback]);

  return (
    <div className="edit-popup__container run-controls edit-run__controls">
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Start Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">X</label>
              <input
                type="number"
                id="startPostX"
                value={run.x1}
                onChange={(event) => {
                  const newRun = run.set(
                    "x1",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "runs/edit", run: newRun });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="startPostY">Y</label>
              <input
                type="number"
                id="startPostY"
                value={run.y1}
                onChange={(event) => {
                  const newRun = run.set(
                    "y1",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "runs/edit", run: newRun });
                }}
              />
            </span>
          </div>
        </div>
        <div className="edit-popup__control">
          <p className="edit-popup__label">End Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="endPostX">X</label>
              <input
                type="number"
                id="endPostX"
                value={run.x2}
                onChange={(event) => {
                  const newRun = run.set(
                    "x2",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "runs/edit", run: newRun });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="endPostY">Y</label>
              <input
                type="number"
                id="endPostY"
                value={run.y2}
                onChange={(event) => {
                  const newRun = run.set(
                    "y2",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "runs/edit", run: newRun });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Reset</p>
          <div>
            <button
              onClick={() => {
                dispatch({
                  type: "runs/edit",
                  run: run.set("settings", Map()),
                });
              }}
              className="labels-section__button"
            >
              Reset To Globals
            </button>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Height</p>
          <div className={railHeightClass}>
            <button
              onClick={() => {
                let newRun = run.setIn(["settings", "railHeight"], "36");

                if (
                  run.settings &&
                  run.settings.railHeight &&
                  run.settings.railHeight === "36"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("railHeight") &&
                  run.settings.get("railHeight") === "36"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              className={buttonClass(runSettings, "36")}
            >
              36”
            </button>
            <button
              onClick={() => {
                let newRun = run.setIn(["settings", "railHeight"], "42");

                if (
                  run.settings &&
                  run.settings.railHeight &&
                  run.settings.railHeight === "42"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("railHeight") &&
                  run.settings.get("railHeight") === "42"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              className={buttonClass(runSettings, "42")}
            >
              42”
            </button>
            <button
              onClick={() => {
                let newRun = run.setIn(["settings", "railHeight"], "custom");

                if (
                  run.settings &&
                  run.settings.railHeight &&
                  run.settings.railHeight === "custom"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                  newRun = newRun.removeIn(["settings", "customRailHeight"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("railHeight") &&
                  run.settings.get("railHeight") === "custom"
                ) {
                  newRun = run.removeIn(["settings", "railHeight"]);
                  newRun = newRun.removeIn(["settings", "customRailHeight"]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              className={buttonClass(runSettings, "custom")}
            >
              Custom
            </button>
          </div>
        </div>
        {runSettings && runSettings.railHeight === "custom" && (
          <div className="edit-popup__control">
            <label className="edit-popup__label">Custom Height</label>
            <div style={{ display: "flex" }} className={customRailHeightClass}>
              <label
                style={{
                  fontSize: "12px",
                  width: "90px",
                  flexGrow: 1,
                  flexBasis: "50%",
                }}
              >
                Feet
                <input
                  className="number-of-posts"
                  value={
                    run.getIn(["settings", "customRailHeight"])
                      ? customRailHeight.feet
                      : runSettings.customRailHeight.feet
                  }
                  onChange={(event) => {
                    setCustomRailHeight({
                      inches: customRailHeight.inches,
                      feet: event.target.value,
                    });

                    const spacing = parseFloat(event.target.value);
                    if (!isNaN(spacing)) {
                      customRailHeight.feet = spacing;
                      updateCustomRailHeight(customRailHeight, run, dispatch);
                    }
                  }}
                  type="number"
                  min="0"
                  step="1"
                />
              </label>
              <label
                style={{
                  fontSize: "12px",
                  width: "90px",
                  flexGrow: 1,
                  flexBasis: "50%",
                  marginLeft: "4px",
                }}
              >
                Inches
                <input
                  className="number-of-posts"
                  value={
                    run.getIn(["settings", "customRailHeight"])
                      ? customRailHeight.inches
                      : runSettings.customRailHeight.inches
                  }
                  onChange={(event) => {
                    setCustomRailHeight({
                      inches: event.target.value,
                      feet: customRailHeight.feet,
                    });

                    const spacing = parseFloat(event.target.value);
                    if (!isNaN(spacing)) {
                      customRailHeight.inches = spacing;
                      updateCustomRailHeight(customRailHeight, run, dispatch);
                    }
                  }}
                  type="number"
                  min="0"
                  max="11"
                  step="1"
                />
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Post Spacing</p>
          <div className={postSpacingClass}>
            <button
              onClick={() => {
                let newRun = run
                  .setIn(["settings", "postSpacing"], "4")
                  .removeIn(["settings", "customPostSpacing"]);

                if (
                  run.settings &&
                  run.settings.postSpacing &&
                  run.settings.postSpacing === "4"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("postSpacing") &&
                  run.settings.get("postSpacing") === "4"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                dispatch({ type: "runs/edit-spacing", run: newRun });
              }}
              className={buttonClass(runSettings, "4", "postSpacing")}
            >
              4'
            </button>
            <button
              onClick={() => {
                let newRun = run
                  .setIn(["settings", "postSpacing"], "5")
                  .removeIn(["settings", "customPostSpacing"]);

                if (
                  run.settings &&
                  run.settings.postSpacing &&
                  run.settings.postSpacing === "5"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("postSpacing") &&
                  run.settings.get("postSpacing") === "5"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                dispatch({ type: "runs/edit-spacing", run: newRun });
              }}
              className={buttonClass(runSettings, "5", "postSpacing")}
            >
              5'
            </button>
            <button
              onClick={() => {
                let newRun = run
                  .setIn(["settings", "postSpacing"], "custom")
                  .removeIn(["settings", "customPostSpacing"]);

                if (
                  run.settings &&
                  run.settings.postSpacing &&
                  run.settings.postSpacing === "custom"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                if (
                  Map.isMap(run.settings) &&
                  run.settings.has("postSpacing") &&
                  run.settings.get("postSpacing") === "custom"
                ) {
                  newRun = run
                    .removeIn(["settings", "postSpacing"])
                    .removeIn(["settings", "customPostSpacing"]);
                }

                dispatch({ type: "runs/edit-spacing", run: newRun });
              }}
              className={buttonClass(runSettings, "custom", "postSpacing")}
            >
              Custom
            </button>
          </div>
        </div>
        {runSettings.postSpacing === "custom" && (
          <div className="edit-popup__control">
            <label className="edit-popup__label">Custom Post Spacing</label>
            <div className={customPostSpacingClass}>
              <input
                className="number-of-posts"
                style={{ width: "max-content", flexGrow: 0 }}
                value={
                  run.getIn(["settings", "customPostSpacing"])
                    ? customPostSpacing
                    : runSettings.customPostSpacing
                }
                onChange={(event) => {
                  if (showPostsLength !== true) {
                    setShowPostsLength(true);
                  }
                  setCustomPostSpacing(event.target.value);

                  const spacing = parseFloat(event.target.value);
                  if (!isNaN(spacing)) {
                    updateCustomSpacing(
                      spacing,
                      setCustomPostSpacing,
                      run,
                      dispatch,
                      customSpacingCallback
                    );
                  }
                }}
                type="number"
                step="1"
              />
            </div>
          </div>
        )}
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label"># of Posts</p>
          <div style={{ flexGrow: 1 }} className={customPostSpacingClass}>
            {!run.stairs && (
              <input
                className="number-of-posts"
                value={
                  run.getIn(["settings", "postSpacing"]) === "custom" &&
                  !showPostsLength
                    ? numberOfPosts
                    : posts.length
                }
                min="2"
                type="number"
                step="1"
                onChange={(event) => {
                  if (showPostsLength !== false) {
                    setShowPostsLength(false);
                  }
                  setNumberOfPosts(event.target.value);

                  const numPosts = parseInt(event.target.value, 10);

                  if (!isNaN(numPosts)) {
                    updateNumberOfPosts(
                      numPosts,
                      setNumberOfPosts,
                      setCustomPostSpacing,
                      run,
                      stairs,
                      dispatch,
                      customSpacingCallback,
                      postsForRun
                    );
                  }
                }}
              />
            )}
            {run.stairs && (
              <div className="number-of-posts">{posts.length}</div>
            )}
          </div>
        </div>
      </div>
      <RunMountSettingsControls
        run={run}
        dispatch={dispatch}
        settings={settings}
      />
      <RunPostSettingsControls
        run={run}
        dispatch={dispatch}
        settings={settings}
      />
      <RunTopRailSettingsControls
        run={run}
        dispatch={dispatch}
        settings={settings}
      />
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Double Tensioner</p>
          <SelectBox
            onChange={(event) => {
              let newRun = run.setIn(
                ["settings", "doubleTensioner"],
                event.target.value
              );

              if (event.target.value === "inherit") {
                newRun = run.removeIn(["settings", "doubleTensioner"]);
              }

              dispatch({ type: "runs/edit", run: newRun });
            }}
            options={{
              inherit: "Default",
              doubleTensioner: "Use Double Tensioner",
            }}
            selected={run.getIn(["settings", "doubleTensioner"]) || "inherit"}
            overriden={run.getIn(["settings", "doubleTensioner"])}
          />
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Add Grabrail</p>
          <div className="labels-section">
            <div className="labels-section__buttons">
              {!matchingChain && (
                <button
                  className="labels-section__button"
                  onClick={() => {
                    const corners = calculateCorners(state.runs);
                    const chain = getRunChain(run, corners, state.runs);

                    const grabRails = chain.reduce((map, chainRun) => {
                      const id = uuid();

                      let grabrail = Handrail({
                        id: id,
                        run: chainRun,
                      });

                      return map.set(id, grabrail);
                    }, Map());

                    dispatch({
                      type: "handrails/add-many",
                      grabRails: grabRails,
                    });
                  }}
                >
                  Add Grabrail
                </button>
              )}
              {matchingChain && (
                <button
                  className="labels-section__button"
                  onClick={() => {
                    if (matchingChain) {
                      let newHandrails = matchingChain.reduce(
                        (map, handrail) => {
                          const newHandrail = handrail.set(
                            "side",
                            handrail.get("side") === "default"
                              ? "flip"
                              : "default"
                          );
                          return map.set(handrail.id, newHandrail);
                        },
                        Map()
                      );

                      dispatch({
                        type: "handrails/add-many",
                        grabRails: newHandrails,
                      });
                    }
                  }}
                >
                  Flip Grabrail
                </button>
              )}
              {matchingChain && (
                <button
                  className="labels-section__button"
                  onClick={() => {
                    if (matchingChain) {
                      let removeIds = matchingChain.reduce((list, handrail) => {
                        return list.push(handrail.id);
                      }, List());

                      dispatch({
                        type: "handrails/remove-many",
                        removeHandrailIds: removeIds,
                      });
                    }
                  }}
                >
                  Delete Grabrail
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Phantom Post</p>
          <div className="labels-section">
            <div className="labels-section__buttons">
              <div style={{ marginBottom: "8px" }}>
                <button
                  className="labels-section__button"
                  onClick={() => {
                    let newRun = run;

                    const startPostType = run.getIn(["endPosts", "start"]);

                    if (!startPostType) {
                      newRun = newRun.setIn(["endPosts", "start"], "phantom");
                    } else {
                      newRun = newRun.deleteIn(["endPosts", "start"]);
                    }

                    dispatch({ type: "runs/edit", run: newRun });
                  }}
                >
                  Toggle Start Post Phantom
                </button>
              </div>
              <div>
                <button
                  className="labels-section__button"
                  onClick={() => {
                    let newRun = run;

                    const startPostType = run.getIn(["endPosts", "end"]);

                    if (!startPostType) {
                      newRun = newRun.setIn(["endPosts", "end"], "phantom");
                    } else {
                      newRun = newRun.deleteIn(["endPosts", "end"]);
                    }

                    dispatch({ type: "runs/edit", run: newRun });
                  }}
                >
                  Toggle End Post Phantom
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="edit-popup__section edit-popup__labels">
        <div className="edit-popup__control edit-popup__control--align-top">
          <p className="edit-popup__label">Labels</p>
          <div className="labels-section">
            <div className="edit-popup__three-buttons labels__toggle-buttons">
              <button
                onClick={() => {
                  const labelsShow = run.getIn(["labels", "showLabels"]);

                  if (labelsShow !== true) {
                    let newRun = run;

                    newRun = newRun.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "runs/edit", run: newRun });
                  }
                }}
                className={labelsShowClass}
              >
                Show
              </button>
              <button
                onClick={() => {
                  const labelsShow = run.getIn(["labels", "showLabels"]);

                  if (labelsShow === true) {
                    let newRun = run;

                    newRun = newRun.setIn(
                      ["labels", "showLabels"],
                      !labelsShow
                    );

                    dispatch({ type: "runs/edit", run: newRun });
                  }
                }}
                className={labelsHideClass}
              >
                Hide
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newRun = run;

                  const labelsSide = run.getIn(["labels", "runLabels"]);

                  newRun = newRun.setIn(["labels", "runLabels"], !labelsSide);

                  dispatch({ type: "runs/edit", run: newRun });
                }}
                className="labels-section__button"
              >
                Flip Labels Side
              </button>
            </div>
            <div className="labels-section__buttons">
              <button
                onClick={() => {
                  let newRun = run;

                  const labelsSide = run.getIn(["labels", "distanceLabel"]);

                  newRun = newRun.setIn(
                    ["labels", "distanceLabel"],
                    !labelsSide
                  );

                  dispatch({ type: "runs/edit", run: newRun });
                }}
                className="labels-section__button"
              >
                Flip Distance Side
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function RunPostSettingsControls({ run, dispatch, settings }) {
  const runSettings = getRunSettings(run, settings);

  return (
    <div className="edit-popup__section">
      <div className="edit-popup__control">
        <p className="edit-popup__label">Post Material</p>
        <SelectBox
          onChange={(event) => {
            let newRun = run.setIn(
              ["settings", "postMaterial"],
              event.target.value
            );

            if (event.target.value === "inherit") {
              newRun = run.removeIn(["settings", "postMaterial"]);
            }

            if (event.target.value === "wood") {
              newRun = newRun.setIn(["settings", "mountStyle"], "fascia");
            }

            dispatch({ type: "runs/edit", run: newRun });
          }}
          options={{
            inherit: "Project Setting",
            aluminum: "Aluminum",
            "stainless-steel": "Stainless Steel",
            wood: "Wood",
            "customer-provided": "Customer Provided",
          }}
          selected={runSettings.postMaterial}
          overriden={run.getIn(["settings", "postMaterial"])}
        />
      </div>
    </div>
  );
}

function RunMountSettingsControls({ run, dispatch, settings }) {
  let runSettings = getRunSettings(run, settings);
  runSettings = mergeSettings(settings, runSettings);

  return (
    <div className="edit-popup__section">
      <div className="edit-popup__control">
        <p className="edit-popup__label">Mount Style</p>
        <SelectBox
          onChange={(event) => {
            let newRun = run.setIn(
              ["settings", "mountStyle"],
              event.target.value
            );

            if (event.target.value === "inherit") {
              newRun = run.removeIn(["settings", "mountStyle"]);
              newRun = newRun.removeIn(["settings", "ponyWallSize"]);
              newRun = newRun.removeIn(["settings", "fasciaBracketType"]);
              newRun = newRun.removeIn(
                ["settings", "postMaterial"],
                "stainless-steel"
              );
              newRun = newRun.removeIn(
                ["settings", "stainlessPostShape"],
                "round"
              );
            }

            dispatch({ type: "runs/edit", run: newRun });
          }}
          options={{
            inherit: "Project Setting",
            deck: "Deck Mount",
            fascia: "Fascia Mount",
            core: "Core Mount",
            "pony-wall": "Pony Wall",
          }}
          selected={runSettings.mountStyle}
          overriden={run.getIn(["settings", "mountStyle"])}
        />
      </div>
      {runSettings.mountStyle === "pony-wall" && (
        <div className="edit-popup__control">
          <p className="edit-popup__label">Pony Wall Size</p>
          <SelectBox
            onChange={(event) => {
              let newRun = run.setIn(
                ["settings", "ponyWallSize"],
                event.target.value
              );

              if (event.target.value === "inherit") {
                newRun = run.removeIn(["settings", "ponyWallSize"]);
              }

              dispatch({ type: "runs/edit", run: newRun });
            }}
            options={{
              inherit: "Project Setting",
              "2x4": "2 x 4",
              "2x6greater": "2 x 6 or greater",
            }}
            selected={runSettings.ponyWallSize}
            overriden={run.getIn(["settings", "ponyWallSize"])}
          />
        </div>
      )}
      {runSettings.mountStyle === "fascia" && (
        <div className="edit-popup__control">
          <p className="edit-popup__label">Fascia Mount Type</p>
          <SelectBox
            onChange={(event) => {
              const value = event.target.value;
              let newRun = run.setIn(["settings", "fasciaBracketType"], value);

              if (value === "inherit") {
                newRun = run.removeIn(["settings", "fasciaBracketType"]);
                newRun = newRun.removeIn(
                  ["settings", "postMaterial"],
                  "stainless-steel"
                );
                newRun = newRun.removeIn(
                  ["settings", "stainlessPostShape"],
                  "round"
                );
              }

              if (value === "ss-square-4hole-rect") {
                newRun = newRun
                  .setIn(["settings", "postMaterial"], "stainless-steel")
                  .setIn(["settings", "stainlessPostShape"], "square");
              }

              if (
                value === "ss-round-4hole-rect" ||
                value === "ss-round-2hole-rect" ||
                value === "ss-round-2hole-round"
              ) {
                newRun = newRun
                  .setIn(["settings", "postMaterial"], "stainless-steel")
                  .setIn(["settings", "stainlessPostShape"], "round");
              }

              dispatch({ type: "runs/edit", run: newRun });
            }}
            options={{
              inherit: "Project Setting",
              none: "None",
              direct: "Direct Fascia Mounting",
              "direct-spacer": "Direct Fascia Mounting with Spacer",
              "ss-square-4hole-rect":
                "Rectangular Fascia Bracket for Square Post",
              "ss-round-4hole-rect":
                "Rectangular Fascia Bracket 4 Hole for Round Post",
              "ss-round-2hole-rect":
                "Rectangular Fascia Bracket 2 Hole for Round Post",
              "ss-round-2hole-round":
                "Round Fascia Bracket 2 Hole for Round Post",
            }}
            selected={runSettings.fasciaBracketType}
            overriden={run.getIn(["settings", "fasciaBracketType"])}
          />
        </div>
      )}
    </div>
  );
}

function RunTopRailSettingsControls({ dispatch, run, settings }) {
  let runSettings = getRunSettings(run, settings);
  runSettings = mergeSettings(settings, runSettings);

  const woodSizeOptions = {
    inherit: "Project Setting",
    "5/4x4": '5/4" x 4"',
    "5/4x6": '5/4" x 6"',
    "2x4": '2" x 4"',
  };

  if (
    runSettings.woodToprailType === "clear-cedar" ||
    runSettings.woodToprailType === "tight-knot-cedar"
  ) {
    woodSizeOptions["2x6"] = '2" x 6"';
  }

  return (
    <div className="edit-popup__section">
      <div className="edit-popup__control">
        <p className="edit-popup__label">Top Rail</p>
        <SelectBox
          options={{
            inherit: "Project Setting",
            aluminum: "Aluminum",
            "stainless-steel": "Stainless Steel",
            wood: "Wood",
            "customer-provided": "Customer Provided",
          }}
          onChange={(event) => {
            let newRun = run.setIn(
              ["settings", "toprailMaterial"],
              event.target.value
            );

            if (event.target.value === "inherit") {
              newRun = run.removeIn(["settings", "toprailMaterial"]);
            }

            newRun = newRun.removeIn(["settings", "aluminumToprailType"]);
            newRun = newRun.removeIn(["settings", "woodToprailSize"]);
            newRun = newRun.removeIn(["settings", "woodToprailType"]);
            newRun = newRun.removeIn(["settings", "stainlessSteelToprailType"]);
            newRun = newRun.removeIn(["settings", "woodToprailSetup"]);
            newRun = newRun.removeIn(["settings", "woodAlumP2PStairsSetup"]);

            dispatch({ type: "runs/edit", run: newRun });
          }}
          selected={runSettings.toprailMaterial}
          overriden={run.getIn(["settings", "toprailMaterial"])}
        />
      </div>
      {runSettings.toprailMaterial === "aluminum" && (
        <div>
          <div className="edit-popup__control">
            <p className="edit-popup__label">Top Rail Type</p>
            <SelectBox
              options={{
                inherit: "Project Setting",
                rectangular: "Rectangular",
                shaped: "Shaped",
                "alum-p2p": "Post to Post",
                "wood-p2p": "Wood w/ P2P",
              }}
              onChange={(event) => {
                let newRun = run.setIn(
                  ["settings", "aluminumToprailType"],
                  event.target.value
                );

                if (event.target.value === "inherit") {
                  newRun = run.removeIn(["settings", "aluminumToprailType"]);
                }

                // Convert to wood toprail, back compat.
                if (event.target.value === "wood-p2p") {
                  newRun = run
                    .setIn(["settings", "toprailMaterial"], "wood")
                    .setIn(["settings", "woodToprailSetup"], "wood-alum-p2p")
                    .removeIn(["settings", "aluminumToprailType"])
                    .setIn(["settings", "postMaterial"], "aluminum");
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              selected={runSettings.aluminumToprailType}
              overriden={run.getIn(["settings", "aluminumToprailType"])}
            />
          </div>
        </div>
      )}
      {runSettings.toprailMaterial === "stainless-steel" && (
        <div>
          <div className="edit-popup__control">
            <p className="edit-popup__label">Top Rail Type</p>
            <SelectBox
              options={{
                inherit: "Project Setting",
                flat: "Flat 1/2 x 2",
                round: "Round",
              }}
              onChange={(event) => {
                let newRun = run.setIn(
                  ["settings", "stainlessSteelToprailType"],
                  event.target.value
                );

                if (event.target.value === "inherit") {
                  newRun = run.removeIn([
                    "settings",
                    "stainlessSteelToprailType",
                  ]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              selected={runSettings.stainlessSteelToprailType}
              overriden={run.getIn(["settings", "stainlessSteelToprailType"])}
            />
          </div>
        </div>
      )}
      {runSettings.toprailMaterial === "wood" && (
        <div>
          <div className="edit-popup__control edit-popup__control--wood-size">
            <p className="edit-popup__label">Wood Toprail Setup</p>
            <SelectBox
              options={{
                inherit: "Project Setting",
                wood: "Wood",
                "wood-alum-p2p": "Wood w/ P2P",
              }}
              onChange={(event) => {
                let newRun = run.setIn(
                  ["settings", "woodToprailSetup"],
                  event.target.value
                );

                if (event.target.value === "inherit") {
                  newRun = run.removeIn(["settings", "woodToprailSetup"]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              selected={runSettings.woodToprailSetup}
              overriden={run.getIn(["settings", "woodToprailSetup"])}
            />
          </div>
          <div className="edit-popup__control edit-popup__control--wood-size">
            <p className="edit-popup__label">Wood Top Rail Size</p>
            <SelectBox
              options={woodSizeOptions}
              onChange={(event) => {
                let newRun = run.setIn(
                  ["settings", "woodToprailSize"],
                  event.target.value
                );

                if (event.target.value === "inherit") {
                  newRun = run.removeIn(["settings", "woodToprailSize"]);
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              selected={runSettings.woodToprailSize}
              overriden={run.getIn(["settings", "woodToprailSize"])}
            />
          </div>
          <div className="edit-popup__control">
            <p className="edit-popup__label">Wood Type</p>
            <SelectBox
              options={{
                inherit: "Project Setting",
                "clear-cedar": "Clear Cedar",
                "tight-knot-cedar": "Tight Knot Cedar",
                balau: "Balau",
                ipe: "IPE",
              }}
              onChange={(event) => {
                let newRun = run.setIn(
                  ["settings", "woodToprailType"],
                  event.target.value
                );

                if (event.target.value === "inherit") {
                  newRun = run.removeIn(["settings", "woodToprailType"]);
                }

                if (
                  (event.target.value === "balau" ||
                    event.target.value === "ipe") &&
                  runSettings.woodToprailSize === "2x6"
                ) {
                  newRun = newRun.setIn(["settings", "woodToprailSize"], "2x4");
                }

                dispatch({ type: "runs/edit", run: newRun });
              }}
              selected={runSettings.woodToprailType}
              overriden={run.getIn(["settings", "woodToprailType"])}
            />
          </div>
          {runSettings.woodToprailSetup === "wood-alum-p2p" && (
            <div className="edit-popup__control edit-popup__control--wood-size">
              <p className="edit-popup__label">Wood Alum Stairs</p>
              <SelectBox
                options={{
                  inherit: "Project Setting",
                  "in-between": "P2P in between posts",
                  "on-top-brackets": "P2P on top of brackets",
                }}
                onChange={(event) => {
                  let newRun = run.setIn(
                    ["settings", "woodAlumP2PStairsSetup"],
                    event.target.value
                  );

                  if (event.target.value === "inherit") {
                    newRun = run.removeIn([
                      "settings",
                      "woodAlumP2PStairsSetup",
                    ]);
                  }

                  dispatch({ type: "runs/edit", run: newRun });
                }}
                selected={runSettings.woodAlumP2PStairsSetup}
                overriden={run.getIn(["settings", "woodAlumP2PStairsSetup"])}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function EditRunPopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const run = props.edit.object;
  const [view, setView] = useState("controls");
  const settings = useSelector((state) => state.state.present.settings);
  const state = useSelector((state) => state.state.present);

  const posts = getPosts(run, settings, state);
  const postsForRun = postsForTheRun(posts, run);

  const views = {
    controls: EditRunControls,
    hardware: EditRunHardware,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditRunPopupHeader
        setView={setView}
        setPosition={setPosition}
        animation={animation}
      />
      <EditComponent
        run={run}
        dispatch={dispatch}
        posts={posts}
        settings={settings}
        postsForRun={postsForRun}
      />
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "runs/remove", runIndex: run.id });
            }, 0);
          }}
        >
          Delete Run
        </button>
      </div>
    </div>
  );
}

function updateNumberOfPosts(
  numberOfPosts,
  setNumberOfPosts,
  setCustomPostSpacing,
  run,
  stairs,
  dispatch,
  dispatchAction,
  postsForRun
) {
  const fixedPosts =
    (postsForRun.terminal || 0) +
    (postsForRun.stairPostTerminal || 0) +
    (postsForRun.stairPostTransition || 0);

  if (numberOfPosts < fixedPosts) {
    numberOfPosts = fixedPosts;
  }

  let spacing = 1;

  const feet = distanceInFeetFromDistance(getRailDistance(run, stairs));

  const posts = numberOfPosts - 1;

  if (posts === 0) {
    spacing = feet;
  } else if (posts > 0) {
    spacing = feet / posts;
  } else {
    spacing = 1;
  }

  if (spacing <= 1) {
    spacing = 1;
    numberOfPosts = 1 + Math.floor(feet);
  }

  if (spacing >= maximumCustomPostSpacing) {
    spacing = maximumCustomPostSpacing;

    numberOfPosts = 1 + Math.ceil(feet / spacing);
  }

  setNumberOfPosts(numberOfPosts);
  setCustomPostSpacing(spacing);

  dispatchAction(dispatch, run, spacing);
}

function updateCustomSpacing(
  customPostSpacing,
  setCustomPostSpacing,
  run,
  dispatch,
  dispatchAction
) {
  let spacing = parseFloat(customPostSpacing);

  if (isNaN(spacing)) {
    spacing = 1;
  }

  if (spacing < 1) {
    spacing = 1;
  }

  if (spacing > maximumCustomPostSpacing) {
    spacing = maximumCustomPostSpacing;
  }

  setCustomPostSpacing(spacing);
  if (run.getIn(["settings", "customPostSpacing"]) !== spacing) {
    dispatchAction(dispatch, run, spacing);
  }
}

function updateCustomRailHeight(customRailHeight, run, dispatch) {
  if (
    run.getIn(["settings", "customPostSpacing", "inches"]) !==
      customRailHeight.inches ||
    run.getIn(["settings", "customPostSpacing", "feet"]) !==
      customRailHeight.feet
  ) {
    dispatchNewCustomRailHeight(dispatch, run, customRailHeight);
  }
}

export default EditRunPopup;
