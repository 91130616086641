// @ts-nocheck
import { useDispatch, useSelector } from "react-redux";

import { Canvas } from "../Canvas";

export function getCanvasBox(state, canvas, dimensions = null) {
  const pan = getPan(state);
  const boundingBox = getBoundingBoxPoints(state);
  const canvasBox = getCanvasBoxPoints(canvas, pan, dimensions);
  const scale = getScale(boundingBox, canvasBox);
  const centerPan = getCenterPan(boundingBox, canvasBox, scale);

  const canFit = canBoxFit(boundingBox, canvasBox);
  if (canFit !== false) {
    return {
      pan: centerPan,
      scale: 1,
      fits: true,
    };
  } else {
    // Handle situations where scaling needs to occur.
    return {
      pan: centerPan,
      scale: scale,
      fits: false,
    };
  }
}

export function getCanvasBoxes(state, canvas, dimensions = null) {
  const pan = getPan(state);
  const boundingBox = getBoundingBoxPoints(state);
  const canvasBox = getCanvasBoxPoints(canvas, pan, dimensions);

  const scale = getScale(boundingBox, canvasBox);
  const centerPan = getCenterPan(boundingBox, canvasBox, scale);

  const canFit = canBoxFit(boundingBox, canvasBox);
  if (canFit !== false) {
    return [
      {
        pan: centerPan,
        scale: 1,
        fits: true,
      },
    ];
  } else {
    const allBoundingBoxes = getAllBoundingBoxes(boundingBox, canvasBox, 1); // Scale of 1.
    // Handle situations division needs to occur.
    return [
      {
        x1: boundingBox.x1,
        y1: boundingBox.y1,
        x2: boundingBox.x2,
        y2: boundingBox.y2,
        pan: centerPan,
        scale: scale,
        fits: false,
      },
      ...allBoundingBoxes,
    ];
  }
}

function getAllBoundingBoxes(boundingBox, canvasBox, scale = 1) {
  const boundingBoxes = [];

  const boundingBoxWidth = Math.abs(boundingBox.x2 - boundingBox.x1);
  const boundingBoxHeight = Math.abs(boundingBox.y2 - boundingBox.y1);

  const boundingBoxX = boundingBox.x1;
  const boundingBoxY = boundingBox.y1;

  const boundingBoxX2 = boundingBox.x2; // eslint-disable-line no-unused-vars
  const boundingBoxY2 = boundingBox.y2; // eslint-disable-line no-unused-vars

  // Get the number of rows and columns needed to fit the bounding box.
  const rows = Math.ceil(boundingBoxHeight / 912);
  const columns = Math.ceil(boundingBoxWidth / 1920);

  for (let row = 0; row < rows; row++) {
    for (let column = 0; column < columns; column++) {
      const x1 = boundingBoxX + column * 1920;
      const y1 = boundingBoxY + row * 912;
      const x2 = x1 + 1920;
      const y2 = y1 + 912;

      const centerPan = getCenterPan({ x1, y1, x2, y2 }, canvasBox, scale);

      boundingBoxes.push({
        x1,
        y1,
        x2,
        y2,
        pan: centerPan,
        scale: 1,
      });
    }
  }

  return boundingBoxes;
}

function getScale(boundingBox, canvasBox) {
  const canvasDx = canvasBox.x2 - canvasBox.x1;
  const canvasDy = canvasBox.y2 - canvasBox.y1;

  const boundingDx = Math.abs(boundingBox.x2 - boundingBox.x1);
  const boundingDy = Math.abs(boundingBox.y2 - boundingBox.y1);

  let scale = 1;
  if (boundingDx > canvasDx) {
    scale = canvasDx / boundingDx;

    if (boundingDy > canvasDy) {
      const potentialScale = canvasDy / boundingDy;

      // If scale is smaller than current scale use that to fit vertical elements.
      if (potentialScale < scale) {
        scale = potentialScale;
      }
    }
  } else {
    if (boundingDy > canvasDy) {
      const potentialScale = canvasDy / boundingDy;

      // If scale is smaller than current scale use that to fit vertical elements.
      if (potentialScale < scale) {
        scale = potentialScale;
      }
    }
  }

  return scale;
}

function getPan(state) {
  const canvas = state.pan;

  return canvas.pan;
}

function getCanvasBoxPoints(canvas, pan, dimesions = null) {
  let x1 = 0;
  let x2 = 1920; // canvas.offsetWidth;
  let y1 = 0;
  let y2 = 912; //canvas.offsetHeight;

  if (dimesions) {
    x1 = dimesions.x1;
    y1 = dimesions.y1;
    x2 = dimesions.x2;
    y2 = dimesions.y2;
  }

  return {
    x1,
    x2,
    y1,
    y2,
  };
}

function getCenterPan(boundingBox, canvas, scale) {
  const boundingCX = (boundingBox.x1 + boundingBox.x2) / 2;
  const boundingCY = (boundingBox.y1 + boundingBox.y2) / 2;
  const canvasCX = (canvas.x1 + canvas.x2) / 2;
  const canvasCY = (canvas.y1 + canvas.y2) / 2;

  const newPan = {
    x: canvasCX - boundingCX,
    y: canvasCY - boundingCY,
  };

  newPan.x = newPan.x - (boundingCX - boundingCX / scale) * scale;
  newPan.y = newPan.y - (boundingCY - boundingCY / scale) * scale;
  return newPan;
}

function canBoxFit(boundingBox, canvas) {
  const dx = Math.abs(boundingBox.x2 - boundingBox.x1);
  const dy = Math.abs(boundingBox.y2 - boundingBox.y1);

  const canvasDX = Math.abs(canvas.x2 - canvas.x1);
  const canvasDY = Math.abs(canvas.y2 - canvas.y1);

  if (dx < canvasDX && dy < canvasDY) {
    return true;
  } else {
    return false;
  }
}

function getBoundingBoxPoints(state) {
  let x1 = null;
  let x2 = null;
  let y1 = null;
  let y2 = null;

  if (state.runs.size) {
    state.runs.forEach((run) => {
      if (x1 === null) {
        x1 = run.x1 < run.x2 ? run.x1 : run.x2;
      } else {
        if (run.x1 < x1) {
          x1 = run.x1;
        }

        if (run.x2 < x1) {
          x1 = run.x2;
        }
      }

      if (x2 === null) {
        x2 = run.x1 > run.x2 ? run.x1 : run.x2;
      } else {
        if (run.x1 > x2) {
          x2 = run.x1;
        }

        if (run.x2 > x2) {
          x2 = run.x2;
        }
      }

      if (y1 === null) {
        y1 = run.y1 < run.y2 ? run.y1 : run.y2;
      } else {
        if (run.y1 < y1) {
          y1 = run.y1;
        }

        if (run.y2 < y1) {
          y1 = run.y2;
        }
      }

      if (y2 === null) {
        y2 = run.y1 > run.y2 ? run.y1 : run.y2;
      } else {
        if (run.y1 > y2) {
          y2 = run.y1;
        }

        if (run.y2 > y2) {
          y2 = run.y2;
        }
      }
    });
  }

  if (state.stairs.size) {
    state.stairs.forEach((stair) => {
      if (x1 === null) {
        x1 = stair.x1 < stair.x2 ? stair.x1 : stair.x2;
      } else {
        if (stair.x1 < x1) {
          x1 = stair.x1;
        }

        if (stair.x2 < x1) {
          x1 = stair.x2;
        }
      }

      if (x2 === null) {
        x2 = stair.x1 > stair.x2 ? stair.x1 : stair.x2;
      } else {
        if (stair.x1 > x2) {
          x2 = stair.x1;
        }

        if (stair.x2 > x2) {
          x2 = stair.x2;
        }
      }

      if (y1 === null) {
        y1 = stair.y1 < stair.y2 ? stair.y1 : stair.y2;
      } else {
        if (stair.y1 < y1) {
          y1 = stair.y1;
        }

        if (stair.y2 < y1) {
          y1 = stair.y2;
        }
      }

      if (y2 === null) {
        y2 = stair.y1 > stair.y2 ? stair.y1 : stair.y2;
      } else {
        if (stair.y1 > y2) {
          y2 = stair.y1;
        }

        if (stair.y2 > y2) {
          y2 = stair.y2;
        }
      }
    });
  }

  if (state.shapes.size) {
    state.shapes.forEach((shape) => {
      if (x1 === null) {
        x1 = shape.x1 < shape.x2 ? shape.x1 : shape.x2;
      } else {
        if (shape.x1 < x1) {
          x1 = shape.x1;
        }

        if (shape.x2 < x1) {
          x1 = shape.x2;
        }
      }

      if (x2 === null) {
        x2 = shape.x1 > shape.x2 ? shape.x1 : shape.x2;
      } else {
        if (shape.x1 > x2) {
          x2 = shape.x1;
        }

        if (shape.x2 > x2) {
          x2 = shape.x2;
        }
      }

      if (y1 === null) {
        y1 = shape.y1 < shape.y2 ? shape.y1 : shape.y2;
      } else {
        if (shape.y1 < y1) {
          y1 = shape.y1;
        }

        if (shape.y2 < y1) {
          y1 = shape.y2;
        }
      }

      if (y2 === null) {
        y2 = shape.y1 > shape.y2 ? shape.y1 : shape.y2;
      } else {
        if (shape.y1 > y2) {
          y2 = shape.y1;
        }

        if (shape.y2 > y2) {
          y2 = shape.y2;
        }
      }
    });
  }

  if (state.images.size) {
    state.images.forEach((image) => {
      const x = Math.floor(image.width / 2);
      const y = Math.floor(image.height / 2);

      const bounds = {
        x1: image.point.x - x,
        y1: image.point.y - y,
        x2: image.point.x + x,
        y2: image.point.y + y,
      };
      if (x1 === null) {
        x1 = bounds.x1 < bounds.x2 ? bounds.x1 : bounds.x2;
      } else {
        if (bounds.x2 < x1) {
          x1 = bounds.x2;
        }

        if (bounds.x1 < x1) {
          x1 = bounds.x1;
        }
      }

      if (x2 === null) {
        x2 = bounds.x1 > bounds.x2 ? bounds.x1 : bounds.x2;
      } else {
        if (bounds.x1 > x2) {
          x2 = bounds.x1;
        }

        if (bounds.x2 > x2) {
          x2 = bounds.x2;
        }
      }

      if (y1 === null) {
        y1 = bounds.y1 < bounds.y2 ? bounds.y1 : bounds.y2;
      } else {
        if (bounds.y2 < y1) {
          y1 = bounds.y2;
        }

        if (bounds.y1 < y1) {
          y1 = bounds.y1;
        }
      }

      if (y2 === null) {
        y2 = bounds.y1 > bounds.y2 ? bounds.y1 : bounds.y2;
      } else {
        if (bounds.y1 > y2) {
          y2 = bounds.y1;
        }

        if (bounds.y2 > y2) {
          y2 = bounds.y2;
        }
      }
    });
  }

  if (state.posts.size) {
    state.posts.forEach((post) => {
      if (x1 === null) {
        x1 = post.x;
      } else {
        if (post.x < x1) {
          x1 = post.x;
        }
      }

      if (x2 === null) {
        x2 = post.x;
      } else {
        if (post.x > x2) {
          x2 = post.x;
        }
      }

      if (y1 === null) {
        y1 = post.y;
      } else {
        if (post.y < y1) {
          y1 = post.y;
        }
      }

      if (y2 === null) {
        y2 = post.y;
      } else {
        if (post.y > y2) {
          y2 = post.y;
        }
      }
    });
  }

  // Add padding of 20px.
  return {
    x1: x1 - 40,
    x2: x2 + 40,
    y1: y1 - 40,
    y2: y2 + 40,
  };
}

export function RenderCanvas(props) {
  const dispatch = useDispatch();

  const tool = "pan";
  const setTool = () => {};
  const shape = "line";
  const stairsTool = "stairs";
  // Only fire selectors once equality check always true.
  const state = useSelector(
    (state) => state.state,
    (oldValue, newValue) => true
  );
  const appState = useSelector(
    (state) => state.appState,
    (oldValue, newValue) => true
  );
  const { currentProject } = appState;
  const canvas = state.present.canvases.get(props.canvas.id);

  const canvasId = "defaultCanvas0";
  const domCanvas = document.getElementById(canvasId);

  const canvasBoxes = getCanvasBoxes(canvas, domCanvas);

  const boxes = canvasBoxes.map((box, index) => {
    let boxResolve = null;
    let boxReject = null;
    let boxPromise = new Promise((resolve, reject) => {
      boxResolve = resolve;
      boxReject = reject;
    });

    const resolve = (image) => {
      boxResolve(image);
    };

    return {
      box: box,
      resolve: resolve,
      reject: boxReject,
      promise: boxPromise,
      index: index,
    };
  });

  Promise.all(boxes.map((box) => box.promise)).then((images) => {
    props.resolve({ canvas: props.canvas, image: images });
    dispatch({ type: "cleanup/canvas", id: props.canvas.id });
  });

  return boxes.map((box) => {
    return (
      <Canvas
        dispatch={dispatch}
        runs={canvas.runs}
        tool={tool}
        key={box.index}
        setTool={setTool}
        stairsTool={stairsTool}
        pan={box.box.pan}
        scale={box.box.scale}
        shape={shape}
        shapes={canvas.shapes}
        gates={canvas.gates}
        images={canvas.images}
        notes={canvas.notes}
        stairs={canvas.stairs}
        posts={canvas.posts}
        edit={state.present.edit}
        state={state.present}
        canvas={canvas}
        htmlId={canvas.id}
        className="render-canvas"
        renderResolve={box.resolve}
        settings={state.present.settings}
        currentProject={currentProject}
        handrails={canvas.handrails}
        width={1920}
        height={912}
      />
    );
  });
}

export default RenderCanvas;
