// @ts-nocheck
/**
 * External dependencies.
 */
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

import Icon from "./Icon";

const CanvasMenu = (props) => {
  const renameInput = useRef(null);
  const { canvas, setMenuOpen, canvases } = props;
  const [renameMenu, setRenameMenu] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="canvas-menu">
      <div className="canvas-menu__top">
        {renameMenu && (
          <div className="rename-canvas-menu">
            <input
              ref={renameInput}
              className="rename-canvas-menu__input"
              onChange={(event) => {
                dispatch({
                  type: "canvas/rename",
                  name: event.target.value,
                  id: canvas.id,
                });
              }}
              value={canvas.name}
              onKeyDown={(event) => {
                // Hitting enter.
                if (event.keyCode === 13) {
                  setRenameMenu(false);
                  setMenuOpen(false);
                }
              }}
            />
          </div>
        )}
        <button
          className="canvas-menu__button"
          onClick={() => {
            setRenameMenu((open) => !open);
            setTimeout(function () {
              renameInput.current.focus();
            }, 0);
          }}
        >
          Rename
        </button>
        <button
          className="canvas-menu__button"
          onClick={() => {
            dispatch({
              type: "canvas/duplicate",
              id: canvas.id,
              newId: uuid(),
            });
            setTimeout(function () {
              setMenuOpen(false);
            }, 0);
          }}
        >
          Duplicate
        </button>
        {
          // <button className="canvas-menu__button">Multiply Inventory</button>
          <button
            className="canvas-menu__button"
            onClick={() => {
              dispatch({ type: "canvas/settings-open", canvas: canvas });
            }}
          >
            Canvas Settings
          </button>
        }
      </div>
      {canvases.size > 1 && (
        <div className="canvas-menu__bottom">
          <button
            className="canvas-menu__button"
            onMouseDown={() => {
              setTimeout(function () {
                setMenuOpen(false);
              }, 0);
              setTimeout(function () {
                dispatch({ type: "canvas/delete", id: canvas.id });
              }, 0);
            }}
          >
            Delete
          </button>
        </div>
      )}
    </div>
  );
};

const CanvasButton = (props) => {
  const buttonRef = useRef(null);
  const { canvas, currentCanvas, canvases } = props;
  const { name, id } = canvas;
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useDispatch();

  let canvasButtonClass = "canvas-button";

  if (id === currentCanvas) {
    canvasButtonClass += " canvas-button--active";
  }

  let canvasMenuToggleClass = "button canvas-button__menu-toggle";

  if (menuOpen) {
    canvasMenuToggleClass += " canvas-button__menu-toggle--open";
  }

  return (
    <div className={canvasButtonClass} ref={buttonRef}>
      <span
        className="canvas-button__text"
        onClick={() => {
          if (currentCanvas !== id) {
            dispatch({ type: "canvas/select", id: id });
          }
        }}
      >
        {name}
      </span>
      <button
        onClick={() => {
          setMenuOpen((open) => !open);
        }}
        className={canvasMenuToggleClass}
      >
        <Icon icon="caret-down" className="canvas-button__icon" />
      </button>
      {menuOpen && (
        <CanvasMenu
          canvas={canvas}
          setMenuOpen={setMenuOpen}
          canvases={canvases}
        />
      )}
    </div>
  );
};

const SaveStateManager = (props) => {
  const saveState = useSelector((state) => state.saveState);
  const dispatch = useDispatch();

  let output = "";

  if (saveState.isSaving) {
    output = "Saving...";
  }

  if (saveState.isSaved) {
    output = "Saved";
  }

  if (saveState.errorOccurred) {
    output =
      "Error occurred while saving, make another change to retry saving.";
  }

  useEffect(() => {
    if (saveState.errorOccurred) {
      dispatch({ type: "window/open-save-override", errorOccurred: false });
    }
  }, [saveState.errorOccurred, dispatch]);

  return (
    <div className="app__save-state">
      {!saveState.errorOccurred && (
        <div className="save-state__output">{output}</div>
      )}
      {saveState.errorOccurred && (
        <>
          <div className="save-state__output">{output}</div>
          <button
            onClick={() => {
              dispatch({ type: "window/open-save-override" });
            }}
            className="override-save"
          >
            Override Save State
          </button>
        </>
      )}
    </div>
  );
};

const CanvasManager = (props) => {
  const state = useSelector((state) => state.state.present);
  const { canvases, currentCanvas } = state;

  const dispatch = useDispatch();

  return (
    <div className="app__canvas-manager">
      <div className="app__canvases">
        {canvases.entrySeq().map(([key, canvas]) => (
          <CanvasButton
            key={canvas.id}
            canvas={canvas}
            currentCanvas={currentCanvas}
            canvases={canvases}
          />
        ))}
        <button
          onClick={() => {
            const canvasId = uuid();
            dispatch({ type: "canvas/add", id: canvasId });
          }}
          className="canvas-manager__add-button"
        >
          <Icon icon="plus" className="canvas-manager__add-icon" />
        </button>
      </div>
      <SaveStateManager />
    </div>
  );
};

export default CanvasManager;
