import { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../Icon";
import { EstimatorContext } from "../../App";
import EditCustomerShipToWindow from "./EditCustomerShipToWindow";
import CustomersDropdown from "./CustomersDropdown";
import AddCustomerWindow from "./AddCustomerWindow/AddCustomerWindow";
import { Project } from "../../entities";
import {
  getBillAddressFromCustomer,
  getShipAddressFromCustomer,
} from "./utils";
import { CustomerContext } from "../SingleProjectView";

interface PropType {
  project: Project;
}

const CustomerSelect = ({ project }: PropType) => {
  const { settings } = project;

  const appState = useSelector((state: any) => state.appState);
  const requiredFields = useSelector((state: any) => state.requiredFields);
  const { currentProject } = appState;

  const dispatch = useDispatch();

  const { customer, updateCustomer } = useContext(CustomerContext);
  const { estimators } = useContext(EstimatorContext);

  const [open, setOpen] = useState(false);
  const [addNewWindow, setAddNewWindow] = useState(false);
  const [editShipToWindow, setEditShipToWindow] = useState(false);
  const [listPopupOpen, setListPopupOpen] = useState(false);

  let nameField = customer?.name || "Please select or add a customer";

  let billAddressLines = [];
  let shipAddressLines = [];

  const setNewSalesRep = useSelector(
    (state: any) => state.appState.setNewSalesRep
  );

  useEffect(() => {
    if (setNewSalesRep) {
      if (customer) {
        updateCustomer(
          customer
            .setIn(["data", "salesRep"], setNewSalesRep)
            .setIn(["data", "customerRep"], setNewSalesRep),
          "customers/edit"
        );

        dispatch({
          type: "customer/set-new-sales-rep",
          setNewSalesRep: "",
        });
      }
    }
  }, [setNewSalesRep, customer, updateCustomer, dispatch]);

  if (customer) {
    billAddressLines = getBillAddressFromCustomer(customer);
    shipAddressLines = getShipAddressFromCustomer(customer, settings);
  }

  let customerClassName = "project-customer";

  if (requiredFields.has("customer")) {
    customerClassName += " project-customer--required";
  }

  return (
    <div className={customerClassName}>
      <div>
        <label htmlFor="project-customer" className="people__label">
          Choose Customer
        </label>
        <div
          onClick={() => {
            setOpen((open) => !open);
          }}
          className="select-box"
        >
          <div className="estimators__select">{nameField}</div>
          <Icon icon="chev-down" className="select-box__icon" />
        </div>
        {open && !addNewWindow && !editShipToWindow && (
          <CustomersDropdown
            setAddNewWindow={setAddNewWindow}
            listPopupOpen={listPopupOpen}
            setListPopupOpen={setListPopupOpen}
            setEditShipToWindow={setEditShipToWindow}
            updateCustomer={updateCustomer}
            estimators={estimators}
            project={project}
            setOpen={setOpen}
          />
        )}
        {addNewWindow && (
          <AddCustomerWindow
            setAddNewWindow={setAddNewWindow}
            setOpen={setOpen}
            updateCustomer={updateCustomer}
            customer={customer}
          />
        )}
        {editShipToWindow && (
          <EditCustomerShipToWindow
            setEditShipToWindow={setEditShipToWindow}
            updateCustomer={updateCustomer}
            customer={customer}
            settings={settings}
          />
        )}
      </div>
      {customer && (
        <div className="customer-address__container">
          {billAddressLines && (
            <div className="customer-address__item">
              <div className="customer-address__heading">Bill-to Address</div>
              {billAddressLines.map((line, index) => {
                return (
                  <div key={index} className="customer-address__line">
                    {line}
                  </div>
                );
              })}
            </div>
          )}
          {shipAddressLines && (
            <div className="customer-address__item">
              <div
                onClick={() => {
                  setEditShipToWindow(true);
                }}
              >
                <Icon icon="edit" className="customer-address__edit-icon" />
              </div>
              <div className="customer-address__heading">Ship-to Address</div>
              {shipAddressLines.map((line, index) => {
                return (
                  <div key={index} className="customer-address__line">
                    {line}
                  </div>
                );
              })}
              {!settings.shipToAddress.validated && (
                <button
                  onClick={() => {
                    dispatch({
                      type: "settings/edit",
                      settings: settings.setIn(
                        ["shipToAddress", "validated"],
                        !settings.shipToAddress.validated
                      ),
                      id: currentProject,
                    });
                  }}
                  className="customer-address__alert"
                >
                  <Icon icon="alert" className="customer-address__alert-icon" />
                  Please Validate Shipping Address
                </button>
              )}
              {settings.shipToAddress.validated && (
                <button
                  onClick={() => {
                    dispatch({
                      type: "settings/edit",
                      settings: settings.setIn(
                        ["shipToAddress", "validated"],
                        !settings.shipToAddress.validated
                      ),
                      id: currentProject,
                    });
                  }}
                  className="customer-address__validated"
                >
                  <Icon
                    icon="success"
                    className="customer-address__validated-icon"
                  />
                  Shipping Address Validated
                </button>
              )}
            </div>
          )}
          {!shipAddressLines && (
            <div className="customer-address__item">
              <div
                onClick={() => {
                  setEditShipToWindow(true);
                }}
              >
                <Icon icon="edit" className="customer-address__edit-icon" />
              </div>
              <div className="customer-address__heading">Ship-to Address</div>
            </div>
          )}
          {customer && (
            <div className="customer-edit">
              <button
                onClick={() => {
                  setAddNewWindow(true);
                }}
                className="customer-edit__button"
              >
                Edit All Customer Details
              </button>
            </div>
          )}
          {customer && (
            <div className="qb-export">
              <button
                onClick={() => {
                  dispatch({
                    type: "customer/export-to-quickbooks",
                    customer: customer,
                  });
                }}
                className="qb-export__button"
              >
                Export to Quickbooks
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomerSelect;
