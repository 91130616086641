import { useRef } from "react";
import { useDispatch } from "react-redux";
import { Map } from "immutable";
import { useNavigate } from "react-router-dom";

import Icon from "../Icon";
import { getYearMonthDay, projectEstimateNumber } from "../../utils";
import PaginatedList from "../PaginatedList";
import { Customer, Estimator, Project } from "../../entities";

// todo: reenable
// const searchFilter = (filters, project, customers) => {
//   const search = filters.search.toLowerCase();
//   const projectTitle = project.settings.projectTitle.toLowerCase();
//   const status = project.settings.status.toLowerCase();
//   const estimateNumber = projectEstimateNumber(project).toLowerCase();

//   let customerName = "No customer assigned".toLowerCase();
//   let companyName = "No company".toLowerCase();
//   let email = "No email".toLowerCase();
//   let phone = "No phone".toLowerCase();
//   let phoneDigits = "No phone".toLowerCase();
//   let firstName = "No first name".toLowerCase();
//   let lastName = "No last name".toLowerCase();
//   let firstAndLast = "No first and last name".toLowerCase();

//   if (project.customer && customers.has(project.customer)) {
//     let customer = customers.get(project.customer);
//     customerName = getCustomerName(customer).toLowerCase();

//     firstName = customer?.data?.firstName
//       ? customer.data.firstName.toLowerCase()
//       : "";

//     lastName = customer?.data?.lastName
//       ? customer.data.lastName.toLowerCase()
//       : "";

//     firstAndLast = `${firstName} ${lastName}`;

//     companyName = customer?.data?.companyName
//       ? customer.data.companyName.toLowerCase()
//       : customer?.quickbooksData?.Company?.toLowerCase
//       ? customer?.quickbooksData?.Company.toLowerCase()
//       : "";
//     email = customer?.data?.email
//       ? customer.data.email.toLowerCase()
//       : customer?.quickbooksData?.Email?.toLowerCase
//       ? customer?.quickbooksData?.Email.toLowerCase()
//       : "";
//     phone = customer?.data?.phone
//       ? customer.data.phone.toLowerCase()
//       : customer?.quickbooksData?.Phone?.toLowerCase
//       ? customer?.quickbooksData?.Phone.toLowerCase()
//       : "";

//     phoneDigits = phone.replace(/\D/g, "");
//   }

//   if (
//     - projectTitle.includes(search) || -
//     - status.includes(search) ||
//     - customerName.includes(search) ||
//     firstAndLast.includes(search) ||
//     email.includes(search) ||
//     phone.includes(search) ||
//     phoneDigits.includes(search) ||
//     companyName.includes(search) ||
//     - estimateNumber.includes(search)
//   ) {
//     return true;
//   }
//   return false;
// };

// const estimatorFilter = (filters, project, estimators) => {
//   if (filters.estimator === "none") {
//     if (project.estimators.size === 0) {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   if (project.estimators.has(filters.estimator)) {
//     return true;
//   }

//   return false;
// };

// const statusFilter = (filters, project) => {
//   if (project.settings.status === filters.status) {
//     return true;
//   }

//   return false;
// };

// const filterProject = (filters, estimators, customers) => (project) => {
//   let search = true;
//   if (filters.search.length) {
//     search = searchFilter(filters, project, customers);
//   }

//   let estimator = true;
//   if (filters.estimator.length) {
//     estimator = estimatorFilter(filters, project, estimators);
//   }

//   let status = true;
//   if (filters.status.length) {
//     status = statusFilter(filters, project);
//   } else {
//     status = project.settings.status !== "trash";
//   }

//   return search && estimator && status;
// };

const ProjectDashboardTable = ({
  projects,
  setProjects,
  setSorts,
  estimators,
  setFilters,
  filters,
  selected,
  setSelected,
  isLoading,
  loadMore,
}: {
  projects: { project: Project; customer: Customer }[];
  setProjects: React.Dispatch<
    React.SetStateAction<{ project: Project; customer: Customer }[]>
  >;
  setSorts: React.Dispatch<
    React.SetStateAction<{
      name: string;
      estimateNumber: string;
      customer: string;
      date: string;
      activeSort: string;
    }>
  >;
  estimators: Map<string, Estimator>;
  setFilters: React.Dispatch<
    React.SetStateAction<{
      search: string;
      estimator: string;
      status: string;
    }>
  >;
  filters: {
    search: string;
    estimator: string;
    status: string;
  };
  selected: Map<string, boolean>;
  setSelected: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  isLoading: boolean;
  loadMore: () => void;
}) => {
  return (
    <div className="projects__dashboard">
      <div className="projects__dashboard-table-header">
        <div className="dashboard-header__container">
          <span className="dashboard__header-item delete-project">
            {false && (
              <button className="projects__dashboard-delete">
                <Icon icon="delete" className="dashboard__delete-icon" />
              </button>
            )}
          </span>
          <span
            onClick={() => {
              setSorts((prevSorts) => {
                const sort = prevSorts.name === "ASC" ? "DESC" : "ASC";
                return {
                  ...prevSorts,
                  name: sort,
                  activeSort: "name",
                };
              });
            }}
            className="dashboard__header-item project-name"
          >
            Project Name{" "}
            <Icon icon="double-caret" className="dashboard__header-item-icon" />
          </span>
          <span
            onClick={() => {
              setSorts((prevSorts) => {
                const sort = prevSorts.date === "ASC" ? "DESC" : "ASC";
                return {
                  ...prevSorts,
                  date: sort,
                  activeSort: "date",
                };
              });
            }}
            className="dashboard__header-item date-created"
          >
            Date Created{" "}
            <Icon icon="double-caret" className="dashboard__header-item-icon" />
          </span>
          <span
            onClick={() => {
              setSorts((prevSorts) => {
                const sort =
                  prevSorts.estimateNumber === "ASC" ? "DESC" : "ASC";
                return {
                  ...prevSorts,
                  estimateNumber: sort,
                  activeSort: "estimateNumber",
                };
              });
            }}
            className="dashboard__header-item estimate-number"
          >
            Estimate #{" "}
            <Icon icon="double-caret" className="dashboard__header-item-icon" />
          </span>
          <span className="dashboard__header-item estimator">
            Estimator{" "}
            <select
              onChange={(event) => {
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    estimator: event.target.value,
                  };
                });
              }}
              className="dashboard__header-item-select"
              value={filters.estimator}
            >
              <option value="">Sort by Estimator</option>
              <option value="none">None Assigned</option>
              {estimators.entrySeq().map(([id, estimator]) => {
                return (
                  <option key={estimator.id} value={estimator.id}>
                    {estimator.name}
                  </option>
                );
              })}
            </select>
          </span>
          <span
            onClick={() => {
              setSorts((prevSorts) => {
                const sort = prevSorts.customer === "ASC" ? "DESC" : "ASC";
                return {
                  ...prevSorts,
                  customer: sort,
                  activeSort: "customer",
                };
              });
            }}
            className="dashboard__header-item customer"
          >
            Customer{" "}
            <Icon icon="double-caret" className="dashboard__header-item-icon" />
          </span>
          <span className="dashboard__header-item status">
            Status{" "}
            <select
              onChange={(event) => {
                setFilters((prevFilters) => {
                  return {
                    ...prevFilters,
                    status: event.target.value,
                  };
                });
              }}
              className="dashboard__header-item-select"
              value={filters.status}
            >
              <option value="">All</option>
              <option value="pending">Pending</option>
              <option value="complete">Complete</option>
              <option value="cancelled">Cancelled</option>
              <option value="archived">Archived</option>
              <option value="trash">Trash</option>
            </select>
          </span>
        </div>
      </div>
      <div className="projects__dashbaord-table">
        <PaginatedList
          items={projects}
          loadMore={loadMore}
          noResultsMessage={() =>
            isLoading ? null : (
              <div style={{ paddingTop: "2rem", textAlign: "center" }}>
                No results found
              </div>
            )
          }
          renderListItem={(project, index, setThirdToLastElement) => {
            const projectEstimators = project.project.estimators.reduce(
              (acc, estimatorId) => {
                if (estimators.has(estimatorId)) {
                  return acc.set(estimatorId, estimators.get(estimatorId)!);
                } else {
                  return acc;
                }
              },
              Map<string, Estimator>()
            );

            return (
              <ProjectDashboardTableItem
                key={project.project.id}
                project={project.project}
                customer={project.customer}
                projectEstimators={projectEstimators}
                setSelected={setSelected}
                selected={selected}
                setProjects={setProjects}
                setThirdToLastElement={setThirdToLastElement}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

const ProjectEstimators = ({
  projectEstimators,
}: {
  projectEstimators: Map<string, Estimator>;
}) => {
  let estimators = "None Assigned";

  if (projectEstimators.size > 0) {
    estimators = projectEstimators
      .reduce((acc, estimator) => {
        acc.push(estimator.name);
        return acc;
      }, [] as string[])
      .join(", ");
  }
  return <span className="dashboard__table-item estimator">{estimators}</span>;
};

const ProjectDashboardTableItem = ({
  project,
  projectEstimators,
  customer,
  setProjects,
  setSelected,
  selected,
  setThirdToLastElement,
}: {
  project: Project;
  projectEstimators: Map<string, Estimator>;
  customer: Customer;
  setProjects: React.Dispatch<
    React.SetStateAction<{ project: Project; customer: Customer }[]>
  >;
  setSelected: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  selected: Map<string, boolean>;
  setThirdToLastElement: ((element: HTMLElement | null) => void) | undefined;
}) => {
  const dispatch = useDispatch();

  const selectRef = useRef<HTMLSelectElement | null>(null);
  const navigate = useNavigate();

  return (
    <div
      ref={setThirdToLastElement}
      className="project__dashboard-table-item-container"
    >
      <div className="project__dashboard-table-item">
        <span className="dashboard__table-item delete-project">
          <input
            type="checkbox"
            checked={selected.has(project.id)}
            onChange={(event) => {
              setSelected((prevSelected) => {
                if (event.target.checked) {
                  return prevSelected.set(project.id, true);
                } else {
                  return prevSelected.delete(project.id);
                }
              });
            }}
          />
        </span>
        <span
          onClick={(event) => {
            // If clicking on select return.
            if (
              event.target === selectRef.current ||
              // @ts-ignore
              (selectRef.current && selectRef.current.contains(event.target))
            ) {
              return;
            }

            dispatch({
              type: "app/open-project",
              id: project.id,
              project: project,
              projectEstimators: projectEstimators,
            });

            navigate(`/projects/${project.id}`);
          }}
          className="dashboard__table-item-data"
        >
          <span className="dashboard__table-item project-name">
            {project.settings.projectTitle}
          </span>
          <span className="dashboard__table-item date-created">
            {getYearMonthDay(project.createdOn)}
          </span>
          <span className="dashboard__table-item estimate-number">
            {projectEstimateNumber(project)}
          </span>
          <ProjectEstimators projectEstimators={projectEstimators} />
          <span className="dashboard__table-item customer">
            {customer?.name || "No customer assigned"}
          </span>
          <span className="dashboard__table-item status">
            <select
              ref={selectRef}
              onChange={(event) => {
                setProjects((projects) => {
                  const projectIndex = projects.findIndex(
                    (theProject) => project.id === theProject.project.id
                  );

                  if (projectIndex !== -1) {
                    const newProjects = [...projects];
                    const newProject = project.setIn(
                      ["settings", "status"],
                      event.target.value
                    );

                    newProjects[projectIndex] = {
                      project: newProject,
                      customer: newProjects[projectIndex].customer,
                    };

                    dispatch({
                      type: "project/update-from-dashboard",
                      project: newProject,
                    });

                    return newProjects;
                  }

                  return projects;
                });
              }}
              style={{ marginLeft: 0 }}
              className="dashboard__header-item-select"
              value={project.settings.status}
            >
              <option value="pending">Pending</option>
              <option value="complete">Complete</option>
              <option value="cancelled">Cancelled</option>
              <option value="archived">Archived</option>
              <option value="trash">Trash</option>
            </select>
          </span>
        </span>
      </div>
    </div>
  );
};
export default ProjectDashboardTable;
