// @ts-nocheck
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useDrag } from "@use-gesture/react";

import Icon from "../../Icon";
import EditPostHardware from "./EditPostHardware";

function SelectBox({ options, value, onChange }) {
  return (
    <div className="select-box">
      <select onChange={onChange} id="cable-size" value={value}>
        {Object.entries(options).map(([key, value]) => {
          return (
            <option key={key} value={key}>
              {value}
            </option>
          );
        })}
      </select>
      <Icon icon="chev-down" className="select-box__icon" />
    </div>
  );
}

function EditPostPopupHeader(props) {
  const { setView, animation, setPosition } = props;

  const dispatch = useDispatch();

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <div className="edit-popup__header-tabs">
        <h2
          className="edit-popup__heading"
          onClick={() => {
            setView("controls");
          }}
        >
          Edit Post
        </h2>
        <h2
          className="edit-popup__heading"
          onClick={() => {
            setView("hardware");
          }}
        >
          Hardware
        </h2>
      </div>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function EditPostControls(props) {
  const { post, dispatch } = props;
  return (
    <div className="edit-popup__container post-controls">
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Position</p>
          <div className="edit-popup__position">
            <span className="labeled-input">
              <label htmlFor="startPostX">X</label>
              <input
                type="number"
                id="startPostX"
                value={post.x}
                onChange={(event) => {
                  const newPost = post.set(
                    "x",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "posts/edit", post: newPost });
                }}
              />
            </span>
            <span className="labeled-input">
              <label htmlFor="startPostY">Y</label>
              <input
                type="number"
                id="startPostY"
                value={post.y}
                onChange={(event) => {
                  const newPost = post.set(
                    "y",
                    parseInt(event.target.value, 10)
                  );

                  dispatch({ type: "posts/edit", post: newPost });
                }}
              />
            </span>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Phantom Post</p>
          <SelectBox
            onChange={(event) => {
              const newPost = post.set(
                "phantom",
                event.target.value === "phantom" ? true : false
              );

              dispatch({ type: "posts/edit", post: newPost });
            }}
            options={{ normal: "Normal Post", phantom: "Phantom Post" }}
            value={post.phantom ? "phantom" : "normal"}
          />
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "posts/remove", postIndex: post.id });
            }, 0);
          }}
        >
          Delete Post
        </button>
      </div>
    </div>
  );
}

function EditPostPopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const post = props.edit.object;
  const [view, setView] = useState("controls");

  const views = {
    controls: EditPostControls,
    hardware: EditPostHardware,
  };

  const EditComponent = views[view];

  return (
    <div>
      <EditPostPopupHeader
        setView={setView}
        setPosition={setPosition}
        animation={animation}
      />
      <EditComponent post={post} dispatch={dispatch} />
    </div>
  );
}

export default EditPostPopup;
