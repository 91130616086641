import { SaltwaterLocations } from "./parsers/saltwaterLocationsParser";

const databaseName = "scrdatabase";
const objectStoreName = "saltwaterLocations";

const openDatabase = (): Promise<IDBDatabase> => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const openRequest = indexedDB.open(databaseName);

    openRequest.onupgradeneeded = function (event) {
      const db = openRequest.result;

      if (!db.objectStoreNames.contains(objectStoreName)) {
        db.createObjectStore(objectStoreName);
      } else {
        reject("Error opening IndexedDB database 2");
      }
    };

    openRequest.onsuccess = function (event) {
      const db = openRequest.result;

      resolve(db);
    };
  });
};

const saveSaltwaterLocationsInDB = (
  db: IDBDatabase,
  locations: SaltwaterLocations
): Promise<void> => {
  console.log("saveSaltwaterLocations");
  return new Promise<void>((resolve, reject) => {
    try {
      const transaction = db.transaction([objectStoreName], "readwrite");
      const objectStore = transaction.objectStore(objectStoreName);

      const request = objectStore.add(locations, "saltwaterLocations");

      request.onsuccess = function (event) {
        // console.log("Locations saved to IndexedDB");
        resolve();
      };

      request.onerror = function (event) {
        // @ts-ignore ts(2339)
        // console.error(event.target.error);
        // console.error("Error saving locations to IndexedDB");
        reject("Error saving locations to IndexedDB");
      };

      transaction.oncomplete = function () {
        db.close();
      };
    } catch (error) {
      reject(error);
    }
  });
};

const getSaltwaterLocationsFromDB = (
  db: IDBDatabase
): Promise<SaltwaterLocations> => {
  return new Promise<SaltwaterLocations>((resolve, reject) => {
    try {
      const transaction = db.transaction([objectStoreName], "readonly");
      const objectStore = transaction.objectStore(objectStoreName);

      const request: IDBRequest = objectStore.get("saltwaterLocations");

      request.onsuccess = function (event) {
        const record = request.result;
        if (record) {
          //   console.log("Locations fetched from IndexedDB");
          resolve(record);
        } else {
          //   console.log("Error retrieving locations from IndexedDB");
          reject("Error retrieving locations from IndexedDB");
        }
      };

      request.onerror = function (event) {
        // @ts-ignore ts(2339)
        // console.error(event.target.error);
        // console.log("Error retrieving locations from IndexedDB");
        reject("Error retrieving locations from IndexedDB");
      };

      transaction.oncomplete = function () {
        db.close();
      };
    } catch (error) {
      reject(error);
    }
  });
};

const saveSaltwaterLocations = async (locations: SaltwaterLocations) => {
  const db = await openDatabase();
  await saveSaltwaterLocationsInDB(db, locations);
};

const getSaltwaterLocations = async (): Promise<SaltwaterLocations> => {
  const db = await openDatabase();
  const locations = await getSaltwaterLocationsFromDB(db);
  return locations;
};

export {
  saveSaltwaterLocations as saveSaltwaterLocationsInDB,
  getSaltwaterLocations as getSaltwaterLocationsFromDB,
};
