import { useCallback } from "react";
import { serviceUrl } from "../environment";

function usePagesFetcher<T>({
  countEndpoint,
  dataEndpoint,
  itemParser,
}: {
  countEndpoint: string;
  dataEndpoint: string;
  itemParser: (json: any) => T;
}) {
  const countUrl = serviceUrl(countEndpoint);
  const dataUrl = serviceUrl(dataEndpoint);

  const fetchPageCount = useCallback(
    async (queryParams: Record<string, string>) => {
      let url = `${countUrl}`;
      for (const key in queryParams) {
        if (!queryParams[key]) continue;
        if (url.includes("?")) {
          url += `&${key}=${queryParams[key]}`;
        } else {
          url += `?${key}=${queryParams[key]}`;
        }
      }

      const response = await fetch(url);
      const { pageCount } = await response.json();
      return pageCount;
    },
    []
  );

  const fetchPage = useCallback(
    async (
      queryParams: Record<string, string>,
      abortController?: AbortController
    ) => {
      let url = `${dataUrl}`;
      for (const key in queryParams) {
        if (!queryParams[key]) continue;
        if (url.includes("?")) {
          url += `&${key}=${queryParams[key]}`;
        } else {
          url += `?${key}=${queryParams[key]}`;
        }
      }
      const response = await fetch(url, {
        signal: abortController?.signal,
      });
      const data: any[] = await response.json();

      const newItems = data.map((json: any) => itemParser(json));
      return newItems;
    },
    []
  );

  return { fetchPageCount, fetchPage };
}

export { usePagesFetcher };
