import {
  getRunSettings,
  mergeSettings,
  getPostInches,
  getNumberOfCableRuns,
} from "../../utils";
import data from "../../data";
import { getPosts } from "../../utils/getPosts";
import { distanceToFeet, roundToHundreth, roundToNearestInch } from "..";
import { Project, ProjectSettings, Run, Stairs } from "../../entities";
import { AllInventoryTypes } from "../../data/fetch-inventory";
import { ItemListType, MappedQBObject } from "./itemListTypes";
import { addAluminumCustomPosts } from "./utils/addAluminumCustomPosts";
import {
  addAluminumBasePlatesForWoodToprail,
  addBlackOxideCable,
  addCoverPlates,
  addCustomPart,
  addFasciaBrackets,
  addFittings,
  addGrommets,
  addItem,
  addMiterCuttingServices,
  addMountingHardware,
  addMountingHardwareCovers,
  addRampPostServices,
  addShrinkTube,
  addStainlessBaseFlanges,
  addStainlessHiProfileBrackets,
  addStainlessLoProfileBrackets,
  addStainlessSteelCustomPosts,
  addStainlessSteelSplices,
  addStainlessSteelSplicesForStairs,
  addStairWashers,
  addWeldedFlanges,
  addWeldingServices,
  addWoodCustomPosts,
  calculateRawPostFootage,
  findByUpc,
  getCableDistanceContinuousStairs,
  getCableForRun,
  getItemListCableForRun,
  getNumberOfToprailSplices,
  getPostAttributeByMaterial,
  getQuantityScrewsPerRun,
  getRailDistance,
  handleOverrides,
  mapQBObjectToJS,
  totalPosts,
} from ".";
import {
  getMainScrewUpc,
  cableUpcs,
  getDrillingServiceNames,
  getPostStockUpcs,
  postUpcs,
  undrilledPostUpcs,
  ponyWallPostUpcs,
  ponyWallBasePlateUpcs,
  ponyWallMitreCutServices,
  ponyWallWeldingServices,
  ponyWallPowderCoatingServices,
  ponyWallHoleDrillingService,
  getAluminumPostCapUpcs,
  getAlumP2PSaddleBracketUpcs,
  getAluminumTopRailSpliceUpcs,
  getWoodScrewUpcs,
  getWoodP2PWasherUpc,
  cableUpcs2507,
  ponyWallPostPadUpcs,
  getShortAnodizedPostStockUpcs,
} from "./getParts";
import { postsForTheRun } from "./utils/postsForTheRun";
import { isMarine } from "./utils/isMarine";
import {
  getDistancesFromPostGroupsForRun,
  getGroupedPostsForRun,
} from "../../draw";

export function RunItemList(
  settings: ProjectSettings,
  run: Run,
  inventory: AllInventoryTypes[],
  state: Project
) {
  const runSettings = getRunSettings(run, settings);
  settings = mergeSettings(settings, runSettings);
  const thePosts = getPosts(run, settings, state);
  const cableSize = settings.cableSize;
  const railHeight = settings.railHeight;

  const mountStyle = settings.mountStyle;
  const postMaterial = settings.postMaterial;
  const aluminumColor = settings.aluminumColor;
  const stainlessPostShape = settings.stainlessPostShape;
  const woodType = settings.woodType;

  const postAttribute = getPostAttributeByMaterial(postMaterial, {
    aluminumColor,
    stainlessPostShape,
    woodType,
  });

  const posts = postUpcs();
  const undrilledPosts = undrilledPostUpcs();

  let railDistance = 0;

  if (run.stairs && (run.stairs as any).continuousStairs) {
    railDistance = getCableDistanceContinuousStairs(run, state);
  } else {
    railDistance = getRailDistance(run, state.stairs);
  }

  const distance = roundToNearestInch(distanceToFeet(railDistance));

  const postsForRun = postsForTheRun(thePosts, run);

  function addDrillingServices(
    itemList: ItemListType,
    postsForRun: Record<string, number>
  ) {
    const numCables = getNumberOfCableRuns(settings);

    const quantityPerPost = numCables;

    const serviceNames = getDrillingServiceNames();

    const serviceName = serviceNames[postMaterial];

    if (serviceName) {
      const start: Record<string, number> = {};
      const services = Object.entries(postsForRun).reduce(
        (result, [type, count]) => {
          if (
            type === "stairPostIntermediate" ||
            type === "stairPostTerminal"
          ) {
            if (!result[serviceName]) {
              result[serviceName] = Math.round(count * quantityPerPost);
            } else {
              result[serviceName] += Math.round(count * quantityPerPost);
            }
          }
          return result;
        },
        start
      );

      if (services[serviceName]) {
        let service = findByUpc(serviceName, inventory);

        if (service) {
          const serviceMapped = mapQBObjectToJS(service);

          itemList[serviceName] = {
            quantity: services[serviceName],
            measure: "ea",
            upc: serviceName,
            description: serviceMapped.description,
            name: serviceMapped.name,
            price: serviceMapped.price,
            type: "service",
            total: serviceMapped.price
              ? roundToHundreth(services[serviceName] * serviceMapped.price)
              : undefined,
          };
        }
      }
    }

    return itemList;
  }

  function buildItemListPosts(itemList: ItemListType) {
    const postStockUpcs = getPostStockUpcs();

    if (settings.mountStyle === "pony-wall") {
      const ponyWallPosts = ponyWallPostUpcs();
      const ponyWallBasePlates = ponyWallBasePlateUpcs();
      const ponyWallPads = ponyWallPostPadUpcs();

      const { postMaterial } = settings;
      const totalPostsCount = totalPosts(postsForRun);

      const postInches = getPostInches(settings);

      if (postMaterial === "aluminum") {
        const numCables = getNumberOfCableRuns(settings);
        const { railHeight } = settings;

        let postsToUse = ponyWallPosts[postMaterial];
        let anodized = false;

        if (
          (settings.aluminumColor === "anodized-black" ||
            settings.aluminumColor === "anodized-clear") &&
          postInches < 49
        ) {
          const anodizedUpcs = getShortAnodizedPostStockUpcs();

          anodized = true;
          postsToUse = anodizedUpcs[settings.aluminumColor];
        }

        if (postsForRun.terminal) {
          itemList = addAluminumCustomPosts(
            itemList,
            inventory,
            postsToUse.terminal.toString(),
            postsForRun.terminal,
            anodized ? "ea" : "ft",
            postInches,
            anodized
          );
        }

        if (postsForRun.intermediate) {
          itemList = addAluminumCustomPosts(
            itemList,
            inventory,
            postsToUse.intermediate.toString(),
            postsForRun.intermediate,
            anodized ? "ea" : "ft",
            postInches,
            anodized
          );
        }

        if (postsForRun.stairPostIntermediate) {
          itemList = addAluminumCustomPosts(
            itemList,
            inventory,
            postsToUse.intermediate.toString(),
            postsForRun.stairPostIntermediate,
            anodized ? "ea" : "ft",
            postInches,
            anodized
          );
        }

        if (postsForRun.stairPostTerminal) {
          itemList = addAluminumCustomPosts(
            itemList,
            inventory,
            postsToUse.terminal.toString(),
            postsForRun.stairPostTerminal,
            anodized ? "ea" : "ft",
            postInches,
            anodized
          );
        }

        if (postsForRun.stairPostTransition) {
          itemList = addAluminumCustomPosts(
            itemList,
            inventory,
            postsToUse.intermediate.toString(),
            postsForRun.stairPostTransition,
            anodized ? "ea" : "ft",
            postInches,
            anodized
          );
        }

        // Add base plate if ponywall size is large enough.
        if (
          settings.ponyWallSize === "2x4" ||
          settings.ponyWallSize !== "2x4"
        ) {
          if (settings.postMaterial === "aluminum") {
            itemList = addItem(
              itemList,
              inventory,
              ponyWallBasePlates[
                postMaterial as keyof typeof ponyWallBasePlates
              ]?.toString(),
              totalPostsCount
            );

            itemList = addItem(
              itemList,
              inventory,
              ponyWallPads[
                postMaterial as keyof typeof ponyWallBasePlates
              ]?.toString(),
              totalPostsCount
            );
          }

          if (settings.postMaterial === "stainless-steel") {
            itemList = addItem(
              itemList,
              inventory,
              // @ts-ignore
              ponyWallBasePlates[
                postMaterial as keyof typeof ponyWallBasePlates
              ][
                settings.stainlessPostShape as keyof typeof ponyWallBasePlates
              ].toString(),
              totalPostsCount
            );

            itemList = addItem(
              itemList,
              inventory,
              // @ts-ignore
              ponyWallPads[postMaterial as keyof typeof ponyWallBasePlates][
                settings.stainlessPostShape as keyof typeof ponyWallBasePlates
              ].toString(),
              totalPostsCount
            );
          }
        } else {
          let aluminumBaseplate = findByUpc("616320733148", inventory);

          if (aluminumBaseplate) {
            const aluminumBaseplateMapped = mapQBObjectToJS(aluminumBaseplate);

            itemList = addCustomPart(
              itemList,
              inventory,
              "custompart-AluminumBaseplate",
              totalPostsCount,
              "Custom Aluminum Base Plate for 2x4 Ponywall mount.",
              aluminumBaseplateMapped.price
            );
          } else {
            itemList = addCustomPart(
              itemList,
              inventory,
              "custompart-AluminumBaseplate",
              totalPostsCount,
              "Custom Aluminum Base Plate for 2x4 Ponywall mount."
            );
          }
        }

        const stairsPosts =
          (postsForRun.stairPostIntermediate || 0) +
          (postsForRun.stairPostTerminal || 0) +
          (postsForRun.stairPostTransition || 0);

        if (stairsPosts > 0) {
          // Mitre cutting for stairs.
          const upc = ponyWallMitreCutServices()[postMaterial]["stairs"];
          itemList = addItem(itemList, inventory, upc, stairsPosts);
        }

        const flatPosts =
          (postsForRun.terminal || 0) + (postsForRun.intermediate || 0);

        if (flatPosts > 0) {
          // Regular cutting for flat runs of ponywall.
          itemList = addItem(
            itemList,
            inventory,
            ponyWallMitreCutServices()[postMaterial]["flat"],
            flatPosts
          );
        }

        itemList = addItem(
          itemList,
          inventory,
          ponyWallWeldingServices()[postMaterial].toString(),
          totalPostsCount
        );

        const postFootage = calculateRawPostFootage(
          totalPostsCount,
          railHeight
        );

        itemList = addItem(
          itemList,
          inventory,
          (ponyWallPowderCoatingServices()[postMaterial] as any)[
            settings.aluminumColor
          ],
          postFootage,
          "ft"
        );

        const holesPerPost = numCables;

        itemList = addItem(
          itemList,
          inventory,
          ponyWallHoleDrillingService()[postMaterial],
          flatPosts * holesPerPost
        );
      }

      if (postMaterial === "stainless-steel") {
        const { stainlessPostShape: postShape, railHeight } = settings;

        const ponyWallPost = ponyWallPosts[postMaterial];
        const ponyWallPostShape =
          ponyWallPost[postShape as keyof typeof ponyWallPost];

        if (settings.railHeight === "custom" && settings.stainlessPostShape === '2507-square') {
          const postInches = getPostInches(settings);
          if ( postsForRun.terminal ) {
            itemList = addStainlessSteelCustomPosts(itemList, inventory, ponyWallPostShape[railHeight].terminal.toString(), postsForRun.terminal, "in", postInches, settings, 'terminal');
          }
          if ( postsForRun.intermediate ) {
            itemList = addStainlessSteelCustomPosts(itemList, inventory, ponyWallPostShape[railHeight].intermediate.toString(), postsForRun.intermediate, "in", postInches, settings, 'intermediate');
          }
          if ( postsForRun.stairPostIntermediate ) {
            itemList = addStainlessSteelCustomPosts(itemList, inventory, ponyWallPostShape[railHeight].intermediate.toString(), postsForRun.stairPostIntermediate, "in", postInches, settings, 'intermediate');
          }
          if ( postsForRun.stairPostTransition ) {
            itemList = addStainlessSteelCustomPosts(itemList, inventory, ponyWallPostShape[railHeight].intermediate.toString(), postsForRun.stairPostTransition, "in", postInches, settings, 'intermediate');
          }
          if ( postsForRun.stairPostTerminal ) {
            itemList = addStainlessSteelCustomPosts(itemList, inventory, ponyWallPostShape[railHeight].terminal.toString(), postsForRun.stairPostTerminal, "in", postInches, settings, 'terminal');
          }
        } else {
          if (postsForRun.terminal) {
            itemList = addItem(
              itemList,
              inventory,
              ponyWallPostShape[railHeight].terminal.toString(),
              postsForRun.terminal
            );
          }
  
          if (postsForRun.intermediate) {
            const ponyWallPost = ponyWallPosts[postMaterial];
            const ponyWallPostShape =
              ponyWallPost[postShape as keyof typeof ponyWallPost];
  
            itemList = addItem(
              itemList,
              inventory,
              ponyWallPostShape[railHeight].intermediate.toString(),
              postsForRun.intermediate
            );
          }
  
          if (postsForRun.stairPostIntermediate) {
            const ponyWallPost = ponyWallPosts[postMaterial];
            const ponyWallPostShape =
              ponyWallPost[postShape as keyof typeof ponyWallPost];
  
            itemList = addItem(
              itemList,
              inventory,
              ponyWallPostShape[railHeight].intermediate.toString(),
              postsForRun.stairPostIntermediate
            );
          }
  
          if (postsForRun.stairPostTerminal) {
            const ponyWallPost = ponyWallPosts[postMaterial];
            const ponyWallPostShape =
              ponyWallPost[postShape as keyof typeof ponyWallPost];
  
            itemList = addItem(
              itemList,
              inventory,
              ponyWallPostShape[railHeight].terminal.toString(),
              postsForRun.stairPostTerminal
            );
          }
  
          if (postsForRun.stairPostTransition) {
            const ponyWallPost = ponyWallPosts[postMaterial];
            const ponyWallPostShape =
              ponyWallPost[postShape as keyof typeof ponyWallPost];
  
            itemList = addItem(
              itemList,
              inventory,
              ponyWallPostShape[railHeight].intermediate.toString(),
              postsForRun.stairPostTransition
            );
          }
        }

        // Add base plate if ponywall size is large enough.
        if (settings.ponyWallSize !== "2x4") {
          itemList = addItem(
            itemList,
            inventory,
            ponyWallBasePlates[postMaterial][
              postShape as keyof (typeof ponyWallBasePlates)[typeof postMaterial]
            ].toString(),
            totalPostsCount
          );
        } else {
          let stainlessBaseplate: AllInventoryTypes | null = null;
          let mappedObject: MappedQBObject | null = null;

          // adding flanges to product list
          if (postShape === "square") {
            stainlessBaseplate = findByUpc("616320731236", inventory);

            if (stainlessBaseplate) {
              mappedObject = mapQBObjectToJS(stainlessBaseplate);
            }
          } else if (postShape === "round") {
            stainlessBaseplate = findByUpc("616320733094", inventory);

            if (stainlessBaseplate) {
              mappedObject = mapQBObjectToJS(stainlessBaseplate);
            }
          } else if (postShape === "2507-square") {
            // todo-2507: flange already part of post assembly
          }

          if (mappedObject) {
            itemList = addCustomPart(
              itemList,
              inventory,
              "custompart-StainlessBaseplate",
              totalPostsCount,
              `Custom Stainless Base Plate for 2x4 Ponywall mount for SS ${postShape}.`,
              mappedObject?.price
            );
          } else {
            itemList = addCustomPart(
              itemList,
              inventory,
              "custompart-StainlessBaseplate",
              totalPostsCount,
              `Custom Stainless Base Plate for 2x4 Ponywall mount for SS ${postShape}.`
            );
          }
        }

        let mitreType = postMaterial;

        if ( postMaterial === 'stainless-steel' && settings.stainlessPostShape === '2507-square' ) {
          mitreType = 'stainless-steel-2507';
        }

        const miterServices: any = ponyWallMitreCutServices();

        itemList = addItem(
          itemList,
          inventory,
          miterServices[mitreType],
          totalPostsCount
        );

        // Do not add welding services for 2507 posts because they are included in the assembly.
        // Welding services are already added.
        // if (
        //   !(postMaterial === "stainless-steel" && postShape === "2507-square")
        // ) {
        //   itemList = addItem(
        //     itemList,
        //     inventory,
        //     ponyWallWeldingServices()[postMaterial],
        //     totalPostsCount
        //   );
        // }

        const numCables = getNumberOfCableRuns(settings);

        const holesPerPost = numCables;

        itemList = addItem(
          itemList,
          inventory,
          ponyWallHoleDrillingService()[postMaterial],
          totalPostsCount * holesPerPost
        );
        // todo: is there powder coating for stainless steel?
      }

      return itemList;
    }

    if (posts[postMaterial as keyof typeof posts]) {
      const postUpcs = (posts as any)[postMaterial][postAttribute][mountStyle][
        railHeight
      ];

      const undrilledPostUpcs = (undrilledPosts as any)[postMaterial][
        postAttribute
      ][mountStyle][railHeight];

      let rawPostsUpcs = (postStockUpcs as any)[postMaterial][postAttribute];

      const postInches = getPostInches(settings);
      const numCables = getNumberOfCableRuns(settings);

      if (settings.railHeight === "custom") {
        if (postInches > 0) {
          if (postMaterial === "aluminum") {
            let anodized = false;

            if (
              (settings.aluminumColor === "anodized-black" ||
                settings.aluminumColor === "anodized-clear") &&
              postInches < 49
            ) {
              const anodizedUpcs = getShortAnodizedPostStockUpcs();

              anodized = true;
              rawPostsUpcs = anodizedUpcs[settings.aluminumColor];
            }

            if (postsForRun.terminal) {
              itemList = addAluminumCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.terminal,
                anodized ? "ea" : "ft",
                postInches,
                anodized
              );
            }

            if (postsForRun.intermediate) {
              itemList = addAluminumCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.intermediate,
                anodized ? "ea" : "ft",
                postInches,
                anodized
              );
            }

            if (postsForRun.stairPostIntermediate) {
              itemList = addAluminumCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostIntermediate,
                anodized ? "ea" : "ft",
                postInches,
                anodized
              );
            }

            if (postsForRun.stairPostTransition) {
              itemList = addAluminumCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostTransition,
                anodized ? "ea" : "ft",
                postInches,
                anodized
              );
            }

            if (postsForRun.stairPostTerminal) {
              itemList = addAluminumCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.stairPostTerminal,
                anodized ? "ea" : "ft",
                postInches,
                anodized
              );
            }
          } else if (postMaterial === "stainless-steel") {
            if (postsForRun.terminal) {
              itemList = addStainlessSteelCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.terminal,
                "in",
                Math.round(postInches / 2) * 2,
                settings,
                'terminal'
              );
            }

            if (postsForRun.intermediate) {
              itemList = addStainlessSteelCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.intermediate,
                "in",
                Math.round(postInches / 2) * 2,
                settings,
                'intermediate'
              );
            }

            if (postsForRun.stairPostIntermediate) {
              itemList = addStainlessSteelCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostIntermediate,
                "in",
                Math.round(postInches / 2) * 2,
                settings,
                'intermediate'
              );
            }

            if (postsForRun.stairPostTransition) {
              itemList = addStainlessSteelCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostTransition,
                "in",
                Math.round(postInches / 2) * 2,
                settings,
                'intermediate'
              );
            }

            if (postsForRun.stairPostTerminal) {
              itemList = addStainlessSteelCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.stairPostTerminal,
                "in",
                Math.round(postInches / 2) * 2,
                settings,
                'terminal'
              );
            }
          } else {
            if (postsForRun.terminal) {
              itemList = addWoodCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.terminal,
                "ea",
                postInches
              );
            }

            if (postsForRun.intermediate) {
              itemList = addWoodCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.intermediate,
                "ea",
                postInches
              );
            }

            if (postsForRun.stairPostIntermediate) {
              itemList = addWoodCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostIntermediate,
                "ea",
                postInches
              );
            }

            if (postsForRun.stairPostTransition) {
              itemList = addWoodCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.intermediate,
                postsForRun.stairPostTransition,
                "ea",
                postInches
              );
            }

            if (postsForRun.stairPostTerminal) {
              itemList = addWoodCustomPosts(
                itemList,
                inventory,
                rawPostsUpcs.terminal,
                postsForRun.stairPostTerminal,
                "ea",
                postInches
              );
            }
          }
        } else {
          rawPostsUpcs = undrilledPostUpcs;

          if (postsForRun.terminal) {
            itemList = addItem(
              itemList,
              inventory,
              rawPostsUpcs.terminal,
              postsForRun.terminal
            );
          }

          if (postsForRun.intermediate) {
            itemList = addItem(
              itemList,
              inventory,
              rawPostsUpcs.intermediate,
              postsForRun.intermediate
            );
          }

          if (postsForRun.stairPostIntermediate) {
            itemList = addItem(
              itemList,
              inventory,
              rawPostsUpcs.intermediate,
              postsForRun.stairPostIntermediate
            );
          }

          if (postsForRun.stairPostTransition) {
            itemList = addItem(
              itemList,
              inventory,
              rawPostsUpcs.intermediate,
              postsForRun.stairPostTransition
            );
          }

          if (postsForRun.stairPostTerminal) {
            itemList = addItem(
              itemList,
              inventory,
              rawPostsUpcs.terminal,
              postsForRun.stairPostTerminal
            );
          }
        }

        if (postMaterial === "stainless-steel") {
          const services: any = ponyWallMitreCutServices();
          const holeDrilling = ponyWallHoleDrillingService();
          const angledHoleDrilling = getDrillingServiceNames();

          const total =
            (postsForRun.terminal || 0) +
            (postsForRun.intermediate || 0) +
            (postsForRun.stairPostIntermediate || 0) +
            (postsForRun.stairPostTransition || 0) +
            (postsForRun.stairPostTerminal || 0);

          // Add custom fab for posts.
          let mitreType = postMaterial;

          if ( postMaterial === 'stainless-steel' && settings.stainlessPostShape === '2507-square' ) {
            mitreType = 'stainless-steel-2507';
          }

          if ( settings.mountStyle === 'pony-wall' ) {
            addItem(itemList, inventory, services[mitreType], total);
          }

          addItem(
            itemList,
            inventory,
            holeDrilling[postMaterial],
            ((postsForRun.terminal || 0) + (postsForRun.intermediate || 0)) *
              numCables
          );
          addItem(
            itemList,
            inventory,
            angledHoleDrilling[postMaterial],
            ((postsForRun.stairPostIntermediate || 0) +
              (postsForRun.stairPostTransition || 0) +
              (postsForRun.stairPostTerminal || 0)) *
              numCables
          );
        }

        if (postMaterial === "aluminum") {
          const holeDrilling = ponyWallHoleDrillingService();
          const angledHoleDrilling = getDrillingServiceNames();

          addItem(
            itemList,
            inventory,
            holeDrilling[postMaterial],
            ((postsForRun.terminal || 0) + (postsForRun.intermediate || 0)) *
              numCables
          );

          addItem(
            itemList,
            inventory,
            angledHoleDrilling[postMaterial],
            ((postsForRun.stairPostIntermediate || 0) +
              (postsForRun.stairPostTransition || 0) +
              (postsForRun.stairPostTerminal || 0)) *
              numCables
          );
        }
      } else {
        if (postsForRun.terminal) {
          itemList = addItem(
            itemList,
            inventory,
            postUpcs.terminal,
            postsForRun.terminal
          );
        }

        if (postsForRun.intermediate) {
          itemList = addItem(
            itemList,
            inventory,
            postUpcs.intermediate,
            postsForRun.intermediate
          );
        }

        if (postsForRun.stairPostIntermediate) {
          itemList = addItem(
            itemList,
            inventory,
            undrilledPostUpcs.intermediate,
            postsForRun.stairPostIntermediate
          );
        }

        if (postsForRun.stairPostTransition) {
          itemList = addItem(
            itemList,
            inventory,
            postUpcs.intermediate,
            postsForRun.stairPostTransition
          );
        }

        if (postsForRun.stairPostTerminal) {
          itemList = addItem(
            itemList,
            inventory,
            undrilledPostUpcs.terminal,
            postsForRun.stairPostTerminal
          );
        }
      }
    }

    if (
      run.stairs &&
      (run.stairs as any).continuousStairs &&
      (run.stairs as any).continuousStairs.find((s: Stairs) => s.isRamp)
    ) {
      itemList = addRampPostServices(itemList, inventory, settings, run, state);
    }

    return itemList;
  }

  function addAluminumSplices(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    settings: ProjectSettings,
    distance: number
  ) {
    const numberOfSplices = getNumberOfToprailSplices(distance);

    // Aluminum.
    const aluminumTopRailSpliceUpcs = getAluminumTopRailSpliceUpcs();
    const spliceUpc =
      aluminumTopRailSpliceUpcs[
        settings.aluminumToprailType as keyof typeof aluminumTopRailSpliceUpcs
      ]?.toString();

    if (numberOfSplices > 0 && spliceUpc) {
      itemList = addItem(itemList, inventory, spliceUpc, numberOfSplices);
    }

    return itemList;
  }

  function addAluminumSplicesForStairs(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    run: Run,
    settings: ProjectSettings,
    state: Project
  ) {
    if (!run.stairs && !(run.stairs as any).continuousStairs) {
      return itemList;
    }

    const posts = getPosts(run, state.settings, state);

    const groups = getGroupedPostsForRun(posts);

    let numberOfSplices = 0;
    let numberOfP2PSplices = 0;

    Object.entries(groups).forEach(([key, value]) => {
      if (value.length < 2) {
        return;
      }

      const { distance, distanceType } = getDistancesFromPostGroupsForRun(
        run,
        state.stairs,
        value,
        key
      );

      if (distanceType === "hypotenuse") {
        numberOfSplices += getNumberOfToprailSplices(distanceToFeet(distance));
      } else {
        numberOfP2PSplices += getNumberOfToprailSplices(
          distanceToFeet(distance)
        );
      }
    });

    const aluminumTopRailSpliceUpcs = getAluminumTopRailSpliceUpcs();

    const spliceUpc =
      aluminumTopRailSpliceUpcs[
        settings.aluminumToprailType as keyof typeof aluminumTopRailSpliceUpcs
      ].toString();

    if (numberOfSplices > 0 && spliceUpc) {
      itemList = addItem(itemList, inventory, spliceUpc, numberOfSplices);
    }

    const p2pSpliceUpc = aluminumTopRailSpliceUpcs["alum-p2p"];

    if (numberOfP2PSplices > 0 && p2pSpliceUpc) {
      itemList = addItem(
        itemList,
        inventory,
        p2pSpliceUpc.toString(),
        numberOfP2PSplices
      );
    }

    return itemList;
  }

  function addWoodSplicesForStairs(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    run: Run,
    settings: ProjectSettings,
    state: Project
  ) {
    if (!run.stairs && !(run.stairs as any).continuousStairs) {
      return itemList;
    }

    const posts = getPosts(run, state.settings, state);

    const groups = getGroupedPostsForRun(posts);

    let numberOfSplices = 0;

    Object.entries(groups).forEach(([key, value]) => {
      if (value.length < 2) {
        return;
      }

      const { distance, distanceType } = getDistancesFromPostGroupsForRun(
        run,
        state.stairs,
        value,
        key
      );

      if (distanceType === "hypotenuse") {
        numberOfSplices += getNumberOfToprailSplices(distanceToFeet(distance));
      }
    });

    if (numberOfSplices > 0) {
      const spliceUpc = getAluminumTopRailSpliceUpcs()["alum-p2p"];

      itemList = addItem(
        itemList,
        inventory,
        spliceUpc.toString(),
        numberOfSplices
      );
    }

    return itemList;
  }

  function buildItemListAluminumToprail(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    settings: ProjectSettings,
    postsForRun: Record<string, number>,
    distance: number,
    railDistance: number,
    run: Run,
    state: Project
  ) {
    function addScrews(itemList: ItemListType) {
      return itemList;
    }

    if (settings.toprailMaterial === "aluminum") {
      itemList = addFittings(
        itemList,
        inventory,
        settings,
        railDistance,
        run,
        postsForRun,
        state
      );
      itemList = addScrews(itemList);
      if (run.stairs && (run.stairs as any).continuousStairs) {
        itemList = addAluminumSplicesForStairs(
          itemList,
          inventory,
          run,
          settings,
          state
        );
      } else {
        itemList = addAluminumSplices(itemList, inventory, settings, distance);
      }
      itemList = addGrommets(itemList, inventory, settings, postsForRun);
    }

    return itemList;
  }

  function buildItemListWoodToprail(
    itemList: ItemListType,
    inventory: AllInventoryTypes[],
    settings: ProjectSettings,
    postsForRun: Record<string, number>,
    distance: number,
    railDistance: number,
    run: Run,
    state: Project
  ) {
    function addScrews(itemList: ItemListType) {
      // Wood screw for stainless posts.
      if (settings.postMaterial === "stainless-steel") {
        let screwType = "stainless-steel";

        if (settings.stainlessSteelToprailType === "2507") {
          screwType = "stainless-2507-flat";
        }

        const screwUpcs = getWoodScrewUpcs();

        let screwUpc =
          getWoodScrewUpcs()[screwType as keyof typeof screwUpcs].toString();

        const totalPosts =
          (postsForRun.intermediate || 0) +
          (postsForRun.terminal || 0) +
          (postsForRun.stairPostTransition || 0) +
          (postsForRun.stairPostTerminal || 0) +
          (postsForRun.stairPostIntermediate || 0);

        let screws = 0;

        // Hi profile has 4 screws per bracket.
        if (settings.stainlessPostCapType === "hi-profile") {
          screws = totalPosts * 4;
        }

        // Lo profile has 2 screws per bracket.
        if (settings.stainlessPostCapType === "lo-profile") {
          screws = totalPosts * 2;
        }

        itemList = addItem(itemList, inventory, screwUpc, screws);
      }

      // Wood posts and customer provided posts use a different screw.
      if (
        settings.postMaterial === "wood" ||
        settings.postMaterial === "customer-provided"
      ) {
        const screwUpc = getWoodScrewUpcs()["wood"].toString();

        const totalTerminal =
          (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);
        const totalIntermediate =
          (postsForRun.intermediate || 0) +
          (postsForRun.stairPostTransition || 0) +
          (postsForRun.stairPostIntermediate || 0);

        // Terminal has 4 screws per post.
        if (totalTerminal) {
          const screws = totalTerminal * 4;
          itemList = addItem(itemList, inventory, screwUpc, screws);
        }

        // Intermediate has 4 screws per post.
        if (totalIntermediate) {
          const screws = totalIntermediate * 4;
          itemList = addItem(itemList, inventory, screwUpc, screws);
        }
      }

      // Aluminum p2p w/wood uses 2 screws per foot with washers.
      if (
        settings.postMaterial === "aluminum" &&
        settings.toprailMaterial === "wood" &&
        settings.woodToprailSetup === "wood-alum-p2p"
      ) {
        const screwUpc = getWoodScrewUpcs()["aluminum"];
        const washerUpc = getWoodP2PWasherUpc();

        const perFoot = Math.floor(distance);
        const quantity = perFoot * 2;

        itemList = addItem(itemList, inventory, screwUpc.toString(), quantity);
        itemList = addItem(itemList, inventory, washerUpc.toString(), quantity);
      }

      if (run.stairs && (run.stairs as any).continuousStairs) {
        if (
          settings.postMaterial === "aluminum" &&
          settings.toprailMaterial === "wood" &&
          settings.woodToprailSetup === "wood-alum-p2p" &&
          settings.woodAlumP2PStairsSetup === "on-top-brackets"
        ) {
          itemList = addWoodSplicesForStairs(
            itemList,
            inventory,
            run,
            settings,
            state
          );
        }
      }

      return itemList;
    }

    if (settings.toprailMaterial === "wood") {
      itemList = addFittings(
        itemList,
        inventory,
        settings,
        railDistance,
        run,
        postsForRun,
        state
      );
      itemList = addScrews(itemList);

      if (settings.postMaterial === "aluminum") {
        itemList = addGrommets(itemList, inventory, settings, postsForRun);
      }

      if (
        settings.postMaterial === "aluminum" &&
        settings.woodToprailSetup !== "wood-alum-p2p"
      ) {
        itemList = addAluminumBasePlatesForWoodToprail(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }
    }

    return itemList;
  }

  function buildItemListStainlessSteelToprail(itemList: ItemListType) {
    function addScrews(itemList: ItemListType) {
      const screwUpc = getMainScrewUpc();
      const screws = getQuantityScrewsPerRun(postsForRun);

      itemList = addItem(itemList, inventory, screwUpc, screws);

      return itemList;
    }

    if (
      settings.toprailMaterial === "stainless-steel" &&
      settings.postMaterial === "stainless-steel"
    ) {
      itemList = addFittings(
        itemList,
        inventory,
        settings,
        railDistance,
        run,
        postsForRun,
        state
      );
      itemList = addScrews(itemList);

      if (run.stairs && (run.stairs as any).continuousStairs) {
        itemList = addStainlessSteelSplicesForStairs(
          itemList,
          inventory,
          run,
          settings,
          state
        );
      } else {
        itemList = addStainlessSteelSplices(
          itemList,
          inventory,
          settings,
          distance
        );
      }

      if (settings.stainlessPostCapType === "hi-profile") {
        itemList = addStainlessHiProfileBrackets(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }

      if (settings.stainlessPostCapType === "lo-profile") {
        itemList = addStainlessLoProfileBrackets(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }

      itemList = addWeldingServices(itemList, inventory, settings, postsForRun);

      if (settings.mountStyle === "pony-wall") {
        itemList = addWeldedFlanges(itemList, inventory, settings, postsForRun);
      }
    }

    if (
      settings.postMaterial === "stainless-steel" &&
      settings.stainlessPostShape === "2507-square"
    ) {
      itemList = addMiterCuttingServices(
        itemList,
        inventory,
        settings,
        postsForRun
      );
    }

    if (
      (settings.postMaterial === "stainless-steel" &&
        settings.toprailMaterial === "wood") ||
      (settings.postMaterial === "stainless-steel" &&
        settings.toprailMaterial === "customer-provided")
    ) {
      if (settings.stainlessPostCapType === "hi-profile") {
        itemList = addStainlessHiProfileBrackets(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }

      if (settings.stainlessPostCapType === "lo-profile") {
        itemList = addStainlessLoProfileBrackets(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }

      itemList = addWeldingServices(itemList, inventory, settings, postsForRun);
    }

    return itemList;
  }

  function buildItemListCustomerProvidedToprail(itemList: ItemListType) {
    function addScrews(itemList: ItemListType) {
      // No screws. Customer provides them. Code below for documentation on previous use.
      return itemList;
    }

    if (
      settings.toprailMaterial === "customer-provided" ||
      settings.postMaterial === "customer-provided"
    ) {
      itemList = addFittings(
        itemList,
        inventory,
        settings,
        railDistance,
        run,
        postsForRun,
        state
      );
      itemList = addScrews(itemList);

      if (settings.postMaterial === "aluminum") {
        itemList = addGrommets(itemList, inventory, settings, postsForRun);
        itemList = addAluminumBasePlatesForWoodToprail(
          itemList,
          inventory,
          settings,
          postsForRun
        );
      }
    }

    return itemList;
  }

  function buildItemListToprail(itemList: ItemListType) {
    itemList = buildItemListStainlessSteelToprail(itemList);
    if (settings.postMaterial !== "customer-provided") {
      itemList = buildItemListAluminumToprail(
        itemList,
        inventory,
        settings,
        postsForRun,
        distance,
        railDistance,
        run,
        state
      );
    }

    itemList = buildItemListCustomerProvidedToprail(itemList);
    itemList = buildItemListWoodToprail(
      itemList,
      inventory,
      settings,
      postsForRun,
      distance,
      railDistance,
      run,
      state
    );
    itemList = handleOverrides(itemList, run, inventory);
    return itemList;
  }

  return {
    getItemList: function () {
      let itemList: ItemListType = {};

      if (
        settings.cableType === "default" ||
        settings.cableType === "2507-steel"
      ) {
        const cableForRun = getCableForRun(
          railDistance,
          settings,
          settings.cableType === "default" ? cableUpcs() : cableUpcs2507(),
          inventory,
          cableSize
        );

        if (cableForRun) {
          itemList[cableForRun.upc] = getItemListCableForRun(cableForRun);
        }
      }

      if (settings.cableType === "black-oxide") {
        itemList = addBlackOxideCable(
          itemList,
          inventory,
          railDistance,
          railHeight,
          run,
          settings,
          state
        );
      }

      itemList = buildItemListToprail(itemList); // here
      itemList = buildItemListPosts(itemList); // here
      itemList = addDrillingServices(itemList, postsForRun);
      itemList = addMountingHardware(
        itemList,
        inventory,
        postsForRun,
        settings
      );
      itemList = addMountingHardwareCovers(
        itemList,
        inventory,
        postsForRun,
        settings
      );
      itemList = addStairWashers(itemList, inventory, state, run, postsForRun);

      if (
        (settings.postMaterial === "aluminum" &&
          settings.cableSize === "1 x 19 - 1/8”") ||
        (isMarine(settings) && settings.postMaterial === "aluminum")
      ) {
        itemList = addShrinkTube(itemList, inventory, postsForRun, settings);
      }

      if (
        settings.includeCoverPlate === "yes" &&
        (settings.mountStyle === "deck" ||
          settings.mountStyle === "core" ||
          settings.mountStyle === "pony-wall")
      ) {
        itemList = addCoverPlates(itemList, inventory, postsForRun, settings);
      }

      if (
        settings.postMaterial === "stainless-steel" &&
        (settings.mountStyle === "deck" || settings.mountStyle === "core")
      ) {
        itemList = addStainlessBaseFlanges(
          itemList,
          inventory,
          postsForRun,
          settings
        );
      }

      if (settings.mountStyle === "fascia") {
        itemList = addFasciaBrackets(
          itemList,
          inventory,
          state,
          run,
          postsForRun,
          settings
        );
      }

      // Add postcaps when aluminum posts are used and p2p toprail is used.
      if (
        settings.postMaterial === "aluminum" &&
        settings.toprailMaterial === "aluminum" &&
        settings.aluminumToprailType === "alum-p2p"
      ) {
        const alumPostCapUpcs = getAluminumPostCapUpcs();

        const color = settings.aluminumColor;

        const totalTerminal =
          (postsForRun.terminal || 0) + (postsForRun.stairPostTerminal || 0);

        // Stair post intermediate caps are not used instead a bracket is put on top.
        const totalIntermediate =
          (postsForRun.intermediate || 0) +
          (postsForRun.stairPostTransition || 0);

        if (totalTerminal) {
          itemList = addItem(
            itemList,
            inventory,
            alumPostCapUpcs[
              color as keyof typeof alumPostCapUpcs
            ]?.terminal.toString(),
            totalTerminal
          );
        }

        if (totalIntermediate) {
          itemList = addItem(
            itemList,
            inventory,
            alumPostCapUpcs[
              color as keyof typeof alumPostCapUpcs
            ]?.intermediate.toString(),
            totalIntermediate
          );
        }
      }

      // Add postcaps when aluminum posts are used and p2p toprail is used only on stairs.
      if (
        settings.postMaterial === "aluminum" &&
        settings.toprailMaterial === "aluminum" &&
        settings.aluminumToprailType !== "alum-p2p"
      ) {
        const alumPostCapUpcs = getAluminumPostCapUpcs();

        const color = settings.aluminumColor;

        const totalTerminal = postsForRun.stairPostTerminal || 0;

        // Stair post intermediate caps are not used instead a bracket is put on top and transition will have regular aluminum toprail.

        if (totalTerminal) {
          itemList = addItem(
            itemList,
            inventory,
            alumPostCapUpcs[
              color as keyof typeof alumPostCapUpcs
            ]?.terminal.toString(),
            totalTerminal
          );
        }
      }

      // Add aluminum saddle brackets for p2p toprail.
      if (
        (settings.postMaterial === "aluminum" &&
          settings.toprailMaterial === "aluminum") ||
        (settings.postMaterial === "aluminum" &&
          settings.toprailMaterial === "wood" &&
          settings.woodToprailSetup === "wood-alum-p2p" &&
          settings.woodAlumP2PStairsSetup === "on-top-brackets")
      ) {
        const alumP2PSaddleBracketUpcs = getAlumP2PSaddleBracketUpcs();

        const color = settings.aluminumColor;

        // Stair post intermediate are the only posts with the brackets.
        const totalIntermediate = postsForRun.stairPostIntermediate || 0;

        if (totalIntermediate) {
          itemList = addItem(
            itemList,
            inventory,
            alumP2PSaddleBracketUpcs[
              color as keyof typeof alumP2PSaddleBracketUpcs
            ]?.intermediate.toString(),
            totalIntermediate
          );
        }
      }

      return itemList;
    },
  };
}
