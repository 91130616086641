// @ts-nocheck
/**
 * External dependencies.
 */
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";

/**
 * Internal dependencies.
 */
import { distanceInFeetObject, pixelsPerFoot, pixelsPerInch } from "../utils";
import {
  calculateShapeCorners,
  shapeHasCorners,
  getShapeCorners,
  getCornerType,
  getShapeChainFromCorner,
} from "../utils/shapeCorners";

function EditLineLength({ shape, point }) {
  const dispatch = useDispatch();
  const shapes = useSelector((state) => state.state.present.shapes);

  const theShape = shapes.get(shape.id);

  const element = useRef();

  useOnClickOutside(element, () => {
    dispatch({ type: "window/close-line-length" });
  });

  const metrics = distanceInFeetObject(theShape);
  const corners = calculateShapeCorners(shapes);

  const [metricState, setMetricState] = useState(metrics);

  const boxWidth = 224;
  const boxHeight = 64;

  const adjustedPoint = {
    x: point.x - boxWidth / 2,
    y: point.y - boxHeight / 2,
  };

  return (
    <div
      style={{ left: adjustedPoint.x, top: adjustedPoint.y }}
      ref={element}
      className="error-message edit-shape-length"
    >
      <div className="error-message__message edit-popup__position">
        <form
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              let newFeet = metricState.feet;
              if (isNaN(newFeet)) {
                newFeet = 0;
              }

              let newInches = metricState.inches;
              if (isNaN(newInches)) {
                newInches = 0;
              }

              const diff = newFeet - metrics.feet;

              const diffInches = newInches - metrics.inches;

              // horizontal.
              if (shape.y1 === shape.y2) {
                if (!shapeHasCorners(shape, corners)) {
                  const newX2 =
                    diff * pixelsPerFoot() +
                    diffInches * pixelsPerInch() +
                    theShape.x2;

                  const newShape = shape.set("x2", newX2);

                  dispatch({ type: "shapes/edit", shape: newShape });
                } else {
                  const shapeCorners = getShapeCorners(shape, corners);

                  if (shapeCorners.length === 1) {
                    const type = getCornerType(shape, shapeCorners[0]);

                    if (type === "2") {
                      let newX1;

                      if (theShape.x2 > theShape.x1) {
                        newX1 =
                          -1 * diff * pixelsPerFoot() +
                          -1 * diffInches * pixelsPerInch() +
                          theShape.x1;
                      } else {
                        newX1 =
                          1 * diff * pixelsPerFoot() +
                          1 * diffInches * pixelsPerInch() +
                          theShape.x1;
                      }

                      const newShape = shape.set("x1", newX1);

                      dispatch({ type: "shapes/edit", shape: newShape });
                    } else if (type === "1") {
                      let newX2;

                      if (theShape.x2 > theShape.x1) {
                        newX2 =
                          diff * pixelsPerFoot() +
                          diffInches * pixelsPerInch() +
                          theShape.x2;
                      } else {
                        newX2 =
                          -1 * diff * pixelsPerFoot() +
                          -1 * diffInches * pixelsPerInch() +
                          theShape.x2;
                      }

                      const newShape = shape.set("x2", newX2);

                      dispatch({ type: "shapes/edit", shape: newShape });
                    }
                  } else {
                    // Handle from midpoint.
                    let transformX1, transformX2;

                    if (theShape.x2 > theShape.x1) {
                      transformX1 = Math.round(
                        (-1 / 2) * diff * pixelsPerFoot() +
                          (-1 / 2) * diffInches * pixelsPerInch()
                      );
                      transformX2 = Math.round(
                        (1 / 2) * diff * pixelsPerFoot() +
                          (1 / 2) * diffInches * pixelsPerInch()
                      );
                    } else {
                      transformX1 = Math.round(
                        (1 / 2) * diff * pixelsPerFoot() +
                          (1 / 2) * diffInches * pixelsPerInch()
                      );
                      transformX2 = Math.round(
                        (-1 / 2) * diff * pixelsPerFoot() +
                          (-1 / 2) * diffInches * pixelsPerInch()
                      );
                    }

                    const newX1 = transformX1 + theShape.x1;
                    const newX2 = transformX2 + theShape.x2;

                    const newShape = shape.set("x2", newX2).set("x1", newX1);

                    let newShapes = shapes;

                    newShapes = newShapes.set(newShape.id, newShape);

                    shapeCorners.forEach((shapeCorner) => {
                      let chainPart = getShapeChainFromCorner(
                        shape,
                        shapeCorner,
                        corners,
                        shapes
                      );

                      const type = getCornerType(
                        chainPart.get(shape.id).shape,
                        chainPart.get(shape.id).corners[0]
                      );

                      if (type === "1") {
                        chainPart = chainPart
                          .map((item) => {
                            return item.shape
                              .set("x1", item.shape.x1 + transformX1)
                              .set("x2", item.shape.x2 + transformX1);
                          })
                          .filter((item) => {
                            return item.id !== shape.id;
                          })
                          .forEach((newShape) => {
                            newShapes = newShapes.set(newShape.id, newShape);
                          });
                      } else if (type === "2") {
                        chainPart = chainPart
                          .map((item) => {
                            return item.shape
                              .set("x1", item.shape.x1 + transformX2)
                              .set("x2", item.shape.x2 + transformX2);
                          })
                          .filter((item) => {
                            return item.id !== shape.id;
                          })
                          .forEach((newShape) => {
                            newShapes = newShapes.set(newShape.id, newShape);
                          });
                      }
                    });

                    dispatch({ type: "shapes/edit-many", shapes: newShapes });
                  }
                }
              }

              // Vertical.
              if (shape.x1 === shape.x2) {
                if (!shapeHasCorners(shape, corners)) {
                  const newY2 =
                    diff * pixelsPerFoot() +
                    diffInches * pixelsPerInch() +
                    theShape.y2;

                  const newShape = shape.set("y2", newY2);

                  dispatch({ type: "shapes/edit", shape: newShape });
                } else {
                  const shapeCorners = getShapeCorners(shape, corners);

                  if (shapeCorners.length === 1) {
                    const type = getCornerType(shape, shapeCorners[0]);

                    if (type === "2") {
                      let newY1;
                      if (theShape.y2 > theShape.y1) {
                        newY1 =
                          -1 * diff * pixelsPerFoot() +
                          -1 * diffInches * pixelsPerInch() +
                          theShape.y1;
                      } else {
                        newY1 =
                          1 * diff * pixelsPerFoot() +
                          1 * diffInches * pixelsPerInch() +
                          theShape.y1;
                      }

                      const newShape = shape.set("y1", newY1);

                      dispatch({ type: "shapes/edit", shape: newShape });
                    } else if (type === "1") {
                      let newY2;

                      if (theShape.y2 > theShape.y1) {
                        newY2 =
                          1 * diff * pixelsPerFoot() +
                          1 * diffInches * pixelsPerInch() +
                          theShape.y2;
                      } else {
                        newY2 =
                          -1 * diff * pixelsPerFoot() +
                          -1 * diffInches * pixelsPerInch() +
                          theShape.y2;
                      }

                      const newShape = shape.set("y2", newY2);

                      dispatch({ type: "shapes/edit", shape: newShape });
                    }
                  } else {
                    // Handle from midpoint.
                    let transformY1, transformY2;

                    if (theShape.y2 > theShape.y1) {
                      transformY1 = Math.round(
                        (-1 / 2) * diff * pixelsPerFoot() +
                          (-1 / 2) * diffInches * pixelsPerInch()
                      );
                      transformY2 = Math.round(
                        (1 / 2) * diff * pixelsPerFoot() +
                          (1 / 2) * diffInches * pixelsPerInch()
                      );
                    } else {
                      transformY1 = Math.round(
                        (1 / 2) * diff * pixelsPerFoot() +
                          (1 / 2) * diffInches * pixelsPerInch()
                      );
                      transformY2 = Math.round(
                        (-1 / 2) * diff * pixelsPerFoot() +
                          (-1 / 2) * diffInches * pixelsPerInch()
                      );
                    }

                    const newY1 = transformY1 + theShape.y1;
                    const newY2 = transformY2 + theShape.y2;

                    const newShape = shape.set("y2", newY2).set("y1", newY1);

                    let newShapes = shapes;

                    newShapes = newShapes.set(newShape.id, newShape);

                    shapeCorners.forEach((shapeCorner) => {
                      let chainPart = getShapeChainFromCorner(
                        shape,
                        shapeCorner,
                        corners,
                        shapes
                      );

                      const type = getCornerType(
                        chainPart.get(shape.id).shape,
                        chainPart.get(shape.id).corners[0]
                      );

                      if (type === "1") {
                        chainPart = chainPart
                          .map((item) => {
                            return item.shape
                              .set("y1", item.shape.y1 + transformY1)
                              .set("y2", item.shape.y2 + transformY1);
                          })
                          .filter((item) => {
                            return item.id !== shape.id;
                          })
                          .forEach((newShape) => {
                            newShapes = newShapes.set(newShape.id, newShape);
                          });
                      } else if (type === "2") {
                        chainPart = chainPart
                          .map((item) => {
                            return item.shape
                              .set("y1", item.shape.y1 + transformY2)
                              .set("y2", item.shape.y2 + transformY2);
                          })
                          .filter((item) => {
                            return item.id !== shape.id;
                          })
                          .forEach((newShape) => {
                            newShapes = newShapes.set(newShape.id, newShape);
                          });
                      }
                    });

                    dispatch({ type: "shapes/edit-many", shapes: newShapes });
                  }
                }
              }

              // sloped line.
              if (shape.x1 !== shape.x2 && shape.y1 !== shape.y2) {
                const dy = shape.y2 - shape.y1;
                const dx = shape.x2 - shape.x1;
                const theta = Math.atan2(dy, dx);

                const newX2 =
                  Math.round(newFeet * pixelsPerFoot() * Math.cos(theta)) +
                  theShape.x1;
                const newY2 =
                  Math.round(newFeet * pixelsPerFoot() * Math.sin(theta)) +
                  theShape.y1;

                let newShape = shape.set("y2", newY2).set("x2", newX2);

                const newMetrics = distanceInFeetObject(newShape);

                if (newMetrics.feet !== newFeet) {
                  if (newFeet > 0) {
                    newShape = newShape
                      .set("x2", newShape.get("x2") + 4)
                      .set("y2", newShape.get("y2") + 4);
                  } else {
                    newShape = newShape
                      .set("x2", newShape.get("x2") - 4)
                      .set("y2", newShape.get("y2") - 4);
                  }
                }
                // dispatch({ type: "shapes/edit", shape: newShape });
              }
              dispatch({ type: "window/close-line-length" });
            }
          }}
        >
          <span className="labeled-input">
            <label>FT</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.feet) ? "" : metricState.feet}
              autoFocus
              onChange={(event) => {
                const feet = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, feet: feet });
              }}
            />
          </span>
          <span className="labeled-input">
            <label>IN</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.inches) ? "" : metricState.inches}
              min="0"
              max="11"
              onChange={(event) => {
                const inches = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, inches: inches });
              }}
            />
          </span>
        </form>
      </div>
    </div>
  );
}

export default EditLineLength;
