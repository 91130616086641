// @ts-nocheck
/**
 * External dependencies.
 */
import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useOnClickOutside from "../hooks/useOnClickOutside";

/**
 * Internal dependencies.
 */
import {
  distanceInFeetObject,
  pixelsPerFoot,
  pixelsPerInch,
  roundToHundreth,
} from "../utils";
import { calculateCorners } from "../utils/corners";
import {
  getConnectedElements,
  getElementChainSplitFromHandrail,
  handleTransformsOfGraph,
} from "../utils/graph";

function EditHandrailLength({ handrail, setPosition, animation, point }) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.state.present);
  const { runs, gates, stairs, posts, handrails } = state;

  let theHandrail = handrails.get(handrail.id);

  const element = useRef();

  useOnClickOutside(element, () => {
    dispatch({ type: "window/close-handrail-length" });
  });

  const metrics = distanceInFeetObject(theHandrail);
  const corners = calculateCorners(runs);

  const [metricState, setMetricState] = useState(metrics);

  const boxWidth = 224;
  const boxHeight = 64;

  const adjustedPoint = {
    x: point.x - boxWidth / 2,
    y: point.y - boxHeight / 2,
  };

  return (
    <div
      style={{ left: adjustedPoint.x, top: adjustedPoint.y }}
      ref={element}
      className="error-message edit-handrail-length"
    >
      <div className="error-message__message edit-popup__position">
        <form
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              let newFeet = metricState.feet;
              if (isNaN(newFeet)) {
                newFeet = 0;
              }

              let newInches = metricState.inches;
              if (isNaN(newInches)) {
                newInches = 0;
              }

              const diff = newFeet - metrics.feet;

              const diffInches = newInches - metrics.inches;

              let transformX1 = 0,
                transformX2 = 0,
                transformY1 = 0,
                transformY2 = 0;

              const elements = getConnectedElements(
                handrail,
                corners,
                runs,
                gates,
                stairs,
                posts,
                handrails
              );

              const chains = getElementChainSplitFromHandrail(
                handrail,
                elements
              );

              // horizontal.
              if (handrail.y1 === handrail.y2) {
                if (
                  chains.start.size === 0 &&
                  chains.end.size === 0 &&
                  chains.stairs.size === 0
                ) {
                  if (theHandrail.x2 > theHandrail.x1) {
                    transformX1 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                    transformX2 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                  } else {
                    transformX1 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                    transformX2 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                  }
                  const transformedEntities = handleTransformsOfGraph(
                    chains,
                    theHandrail,
                    { transformX1, transformY1, transformX2, transformY2 },
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails
                  );

                  theHandrail = theHandrail
                    .set("x1", theHandrail.x1 + transformX1)
                    .set("x2", theHandrail.x2 + transformX2);

                  const {
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "canvas/edit-entities-resize-handrail",
                    handrail: theHandrail,
                    runs: newRuns,
                    gates: newGates,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails.set(theHandrail.id, theHandrail),
                  });
                } else {
                  if (theHandrail.x2 > theHandrail.x1) {
                    transformX1 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                    transformX2 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                  } else {
                    transformX1 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                    transformX2 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                  }

                  const transformedEntities = handleTransformsOfGraph(
                    chains,
                    theHandrail,
                    { transformX1, transformY1, transformX2, transformY2 },
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails
                  );

                  const {
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "canvas/edit-entities-resize-handrail",
                    handrail: newHandrails.get(theHandrail.id),
                    runs: newRuns,
                    gates: newGates,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }
              }

              // Vertical.
              if (handrail.x1 === handrail.x2) {
                if (
                  chains.start.size === 0 &&
                  chains.end.size === 0 &&
                  chains.stairs.size === 0
                ) {
                  if (theHandrail.y2 > theHandrail.y1) {
                    transformY1 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                    transformY2 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                  } else {
                    transformY1 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                    transformY2 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                  }

                  const transformedEntities = handleTransformsOfGraph(
                    chains,
                    theHandrail,
                    { transformX1, transformY1, transformX2, transformY2 },
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails
                  );

                  theHandrail = theHandrail
                    .set("y1", theHandrail.y1 + transformY1)
                    .set("y2", theHandrail.y2 + transformY2);

                  const {
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "canvas/edit-entities-resize-handrail",
                    handrail: theHandrail,
                    runs: newRuns,
                    gates: newGates,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails.set(theHandrail.id, theHandrail),
                  });
                } else {
                  if (theHandrail.y2 > theHandrail.y1) {
                    transformY1 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                    transformY2 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                  } else {
                    transformY1 =
                      (1 / 2) * diff * pixelsPerFoot() +
                      (1 / 2) * diffInches * pixelsPerInch();
                    transformY2 =
                      (-1 / 2) * diff * pixelsPerFoot() +
                      (-1 / 2) * diffInches * pixelsPerInch();
                  }

                  const transformedEntities = handleTransformsOfGraph(
                    chains,
                    theHandrail,
                    { transformX1, transformY1, transformX2, transformY2 },
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails
                  );

                  const {
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "canvas/edit-entities-resize-handrail",
                    handrail: newHandrails.get(theHandrail.id),
                    runs: newRuns,
                    gates: newGates,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }
              }

              // sloped line.
              if (handrail.x1 !== handrail.x2 && handrail.y1 !== handrail.y2) {
                let angle = Math.atan(
                  (handrail.y2 - handrail.y1) / (handrail.x2 - handrail.x1)
                );

                if (angle < 0) {
                  angle = Math.abs(angle);
                }

                const xComponent = roundToHundreth(Math.cos(angle));
                const yComponent = roundToHundreth(Math.sin(angle));

                if (handrail.x2 > handrail.x1) {
                  // Get X transform
                  transformX1 = Math.round(
                    (-1 / 2) * diff * xComponent * pixelsPerFoot() +
                      (-1 / 2) * diffInches * xComponent * pixelsPerInch()
                  );
                  transformX2 = Math.round(
                    (1 / 2) * diff * xComponent * pixelsPerFoot() +
                      (1 / 2) * diffInches * xComponent * pixelsPerInch()
                  );
                } else {
                  transformX1 = Math.round(
                    (1 / 2) * diff * xComponent * pixelsPerFoot() +
                      (1 / 2) * diffInches * xComponent * pixelsPerInch()
                  );
                  transformX2 = Math.round(
                    (-1 / 2) * diff * xComponent * pixelsPerFoot() +
                      (-1 / 2) * diffInches * xComponent * pixelsPerInch()
                  );
                }

                // Get Y Transform
                if (handrail.y2 > handrail.y1) {
                  transformY1 = Math.round(
                    (-1 / 2) * diff * yComponent * pixelsPerFoot() +
                      (-1 / 2) * diffInches * yComponent * pixelsPerInch()
                  );
                  transformY2 = Math.round(
                    (1 / 2) * diff * yComponent * pixelsPerFoot() +
                      (1 / 2) * diffInches * yComponent * pixelsPerInch()
                  );
                } else {
                  transformY1 = Math.round(
                    (1 / 2) * diff * yComponent * pixelsPerFoot() +
                      (1 / 2) * diffInches * yComponent * pixelsPerInch()
                  );
                  transformY2 = Math.round(
                    (-1 / 2) * diff * yComponent * pixelsPerFoot() +
                      (-1 / 2) * diffInches * yComponent * pixelsPerInch()
                  );
                }

                const transformedEntities = handleTransformsOfGraph(
                  chains,
                  theHandrail,
                  { transformX1, transformY1, transformX2, transformY2 },
                  runs,
                  gates,
                  stairs,
                  posts,
                  handrails
                );

                const { newGates, newRuns, newStairs, newPosts, newHandrails } =
                  transformedEntities;

                dispatch({
                  type: "canvas/edit-entities-resize-handrail",
                  handrail: newHandrails.get(theHandrail.id),
                  runs: newRuns,
                  gates: newGates,
                  stairs: newStairs,
                  posts: newPosts,
                  handrails: newHandrails,
                });
              }
              dispatch({ type: "window/close-handrail-length" });
            }
          }}
        >
          <span className="labeled-input">
            <label>FT</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.feet) ? "" : metricState.feet}
              autoFocus
              onChange={(event) => {
                const feet = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, feet: feet });
              }}
            />
          </span>
          <span className="labeled-input">
            <label>IN</label>
            <input
              className="metrics__input"
              type="number"
              value={isNaN(metricState.inches) ? "" : metricState.inches}
              min="0"
              max="11"
              onChange={(event) => {
                const inches = parseInt(event.target.value, 10);

                setMetricState({ ...metricState, inches: inches });
              }}
            />
          </span>
        </form>
      </div>
    </div>
  );
}

export default EditHandrailLength;
