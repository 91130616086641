import { useRef } from "react";

interface PropTypes<T> {
  items: T[];
  renderListItem: (
    item: T,
    index: number,
    setThirdToLastItem: ((element: HTMLElement | null) => void) | undefined
  ) => React.ReactNode;
  noResultsMessage: () => React.ReactNode;
  loadMore: () => void;
}

const PaginatedList = <T extends object>({
  items,
  renderListItem,
  noResultsMessage,
  loadMore,
}: PropTypes<T>) => {
  const thirdToLastElement = useRef<HTMLElement>();
  const observer = useRef<IntersectionObserver | null>();

  const setThirdToLastElement = (currentElement: HTMLElement | null) => {
    if (thirdToLastElement.current && observer.current) {
      observer.current.unobserve(thirdToLastElement.current);
    }

    if (currentElement) {
      observer.current = new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          loadMore();
        }
      });

      observer.current.observe(currentElement);
      thirdToLastElement.current = currentElement;
    }
  };

  return (
    <div className="list">
      <div
        className={`list__elements ${!items.length && "list__elements--empty"}`}
      >
        {items.length
          ? items.map((listItem, index) =>
              renderListItem(
                listItem,
                index,
                index === items.length - 3 ? setThirdToLastElement : undefined
              )
            )
          : noResultsMessage()}
      </div>
    </div>
  );
};

export default PaginatedList;
