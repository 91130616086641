function arrayToKeyedObject<T extends { id: string }>(
  array: T[]
): Record<string, T> {
  return array.reduce((obj: Record<string, T>, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
}

export { arrayToKeyedObject };
