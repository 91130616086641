// @ts-nocheck
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "@use-gesture/react";
import { Map } from "immutable";

import Icon from "../../Icon";
import {
  distanceInFeetObject,
  pixelsPerFoot,
  pixelsPerInch,
  roundToHundreth,
} from "../../../utils";
import {
  getConnectedElements,
  getGatePoints,
  getNodesOfGraph,
  transformRunContinuousStairs,
  transformRunStairs,
} from "../../../utils/graph";
import { calculateCorners } from "../../../utils/corners";

function EditGatePopupHeader(props) {
  const { dispatch, setPosition, animation } = props;

  const bind = useDrag(({ down, offset: [mx, my] }) => {
    animation.start({
      x: mx,
      y: my,
      immediate: down,
    });

    if (!down) {
      const newPosition = { x: mx, y: my };

      setPosition(newPosition);
    }
  });

  return (
    <div {...bind()} className="edit-popup__header">
      <h2 className="edit-popup__heading">Edit Gate</h2>
      <button
        className="app__tooltip-close"
        onClick={() => {
          setTimeout(function () {
            dispatch({ type: "edit-popup/close" });
          }, 0);
        }}
      >
        <Icon icon="close" className="app__tooltip-close-icon" />
      </button>
    </div>
  );
}

function EditGatePopup(props) {
  const { setPosition, animation } = props;
  const dispatch = useDispatch();
  const gate = props.edit.object;
  const state = useSelector((state) => state.state.present);
  const { runs, gates, stairs, settings, posts, handrails } = state;

  let gateOpeningNormalClass = "edit-popup__selector-button";
  let gateOpeningInvertClass = "edit-popup__selector-button";

  let gateSideNormalClass = "edit-popup__selector-button";
  let gateSideInvertClass = "edit-popup__selector-button";

  if (gate.opening === "normal") {
    gateOpeningNormalClass += " edit-popup__selector-button--active";
  } else {
    gateOpeningInvertClass += " edit-popup__selector-button--active";
  }

  if (gate.side === "normal") {
    gateSideNormalClass += " edit-popup__selector-button--active";
  } else {
    gateSideInvertClass += " edit-popup__selector-button--active";
  }

  const metrics = distanceInFeetObject(gate);

  const [metricState, setMetricState] = useState(metrics);

  return (
    <div>
      <EditGatePopupHeader
        setPosition={setPosition}
        animation={animation}
        dispatch={dispatch}
      />
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Gate Opening</p>
          <div className="edit-popup__selector">
            <button
              onClick={() => {
                const newGate = gate.set("opening", "normal");

                dispatch({ type: "gates/edit", gate: newGate });
              }}
              className={gateOpeningNormalClass}
            >
              Normal
            </button>
            <button
              onClick={() => {
                const newGate = gate.set("opening", "invert");

                dispatch({ type: "gates/edit", gate: newGate });
              }}
              className={gateOpeningInvertClass}
            >
              Invert
            </button>
          </div>
        </div>
        <div className="edit-popup__control">
          <p className="edit-popup__label">Gate Side</p>
          <div className="edit-popup__selector">
            <button
              onClick={() => {
                const newGate = gate.set("side", "normal");

                dispatch({ type: "gates/edit", gate: newGate });
              }}
              className={gateSideNormalClass}
            >
              Normal
            </button>
            <button
              onClick={() => {
                const newGate = gate.set("side", "invert");

                dispatch({ type: "gates/edit", gate: newGate });
              }}
              className={gateSideInvertClass}
            >
              Invert
            </button>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <div className="edit-popup__control">
          <p className="edit-popup__label">Gate Size</p>
          <div>
            <div className="edit-popup__position">
              <span className="labeled-input">
                <label>FT</label>
                <input
                  className="metrics__input"
                  type="number"
                  value={isNaN(metricState.feet) ? "" : metricState.feet}
                  autoFocus
                  onChange={(event) => {
                    const feet = parseInt(event.target.value, 10);

                    setMetricState({ ...metricState, feet: feet });
                  }}
                />
              </span>
              <span className="labeled-input">
                <label>IN</label>
                <input
                  className="metrics__input"
                  type="number"
                  value={isNaN(metricState.inches) ? "" : metricState.inches}
                  min="0"
                  max="11"
                  onChange={(event) => {
                    const inches = parseInt(event.target.value, 10);

                    setMetricState({ ...metricState, inches: inches });
                  }}
                />
              </span>
            </div>
            <button
              className="labels-section__button"
              style={{ marginTop: "8px" }}
              onClick={() => {
                // Update Length
                let newFeet = metricState.feet;
                if (isNaN(newFeet)) {
                  newFeet = 0;
                }

                let newInches = metricState.inches;
                if (isNaN(newInches)) {
                  newInches = 0;
                }

                const diff = newFeet - metrics.feet;

                const diffInches = newInches - metrics.inches;

                // Don't do anything if nothing has changed.
                if (diff === 0 && diffInches === 0) {
                  // return;
                }

                const gatePoints = getGatePoints(gate, runs, posts, settings);

                const startPost = gatePoints.startPost;
                const endPost = gatePoints.endPost;

                // Handle from midpoint.
                let transformX1 = 0,
                  transformX2 = 0,
                  transformY1 = 0,
                  transformY2 = 0;

                let gateEndPostX = endPost ? endPost.x : null;
                let gateEndPostY = endPost ? endPost.y : null;
                let gateStartPostX = startPost ? startPost.x : null;
                let gateStartPostY = startPost ? startPost.y : null;

                if (!gateEndPostX) {
                  gateEndPostX = gate.x2;
                }

                if (!gateEndPostY) {
                  gateEndPostY = gate.y2;
                }

                if (!gateStartPostX) {
                  gateStartPostX = gate.x1;
                }

                if (!gateStartPostY) {
                  gateStartPostY = gate.y1;
                }

                let angle = Math.atan(
                  (gateEndPostY - gateStartPostY) /
                    (gateEndPostX - gateStartPostX)
                );

                if (angle < 0) {
                  angle = Math.abs(angle);
                }

                const xComponent = roundToHundreth(Math.cos(angle));
                const yComponent = roundToHundreth(Math.sin(angle));

                // Vertical.
                if (gateEndPostX === gateStartPostX) {
                  // Handle from midpoint.
                  if (gateEndPostY > gateStartPostY) {
                    transformY1 = Math.round(
                      (-1 / 2) * diff * pixelsPerFoot() +
                        (-1 / 2) * diffInches * pixelsPerInch()
                    );
                    transformY2 = Math.round(
                      (1 / 2) * diff * pixelsPerFoot() +
                        (1 / 2) * diffInches * pixelsPerInch()
                    );
                  } else {
                    transformY1 = Math.round(
                      (1 / 2) * diff * pixelsPerFoot() +
                        (1 / 2) * diffInches * pixelsPerInch()
                    );
                    transformY2 = Math.round(
                      (-1 / 2) * diff * pixelsPerFoot() +
                        (-1 / 2) * diffInches * pixelsPerInch()
                    );
                  }

                  let run1 = runs.get(gate.p1.runIndex);
                  let run2 = runs.get(gate.p2.runIndex);

                  if (!run1) {
                    run1 = posts.get(gate.p1.postIndex);
                  }

                  if (!run2) {
                    run2 = posts.get(gate.p2.postIndex);
                  }

                  const corners = calculateCorners(runs);

                  const transformedEntities = handleTransformsOfGraph(
                    run1,
                    run2,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    gate,
                    { transformX1, transformY1, transformX2, transformY2 }
                  );

                  const {
                    newGate,
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "gates/edit-size",
                    runs: newRuns,
                    gates: newGates,
                    gate: newGate,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }

                // Horizontal.
                if (gateEndPostY === gateStartPostY) {
                  if (gateEndPostX > gateStartPostX) {
                    transformX1 = Math.round(
                      (-1 / 2) * diff * pixelsPerFoot() +
                        (-1 / 2) * diffInches * pixelsPerInch()
                    );
                    transformX2 = Math.round(
                      (1 / 2) * diff * pixelsPerFoot() +
                        (1 / 2) * diffInches * pixelsPerInch()
                    );
                  } else {
                    transformX1 = Math.round(
                      (1 / 2) * diff * pixelsPerFoot() +
                        (1 / 2) * diffInches * pixelsPerInch()
                    );
                    transformX2 = Math.round(
                      (-1 / 2) * diff * pixelsPerFoot() +
                        (-1 / 2) * diffInches * pixelsPerInch()
                    );
                  }

                  let run1 = runs.get(gate.p1.runIndex);
                  let run2 = runs.get(gate.p2.runIndex);

                  if (!run1) {
                    run1 = posts.get(gate.p1.postIndex);
                  }

                  if (!run2) {
                    run2 = posts.get(gate.p2.postIndex);
                  }

                  const corners = calculateCorners(runs);

                  const transformedEntities = handleTransformsOfGraph(
                    run1,
                    run2,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    gate,
                    { transformX1, transformY1, transformX2, transformY2 }
                  );

                  const {
                    newGate,
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "gates/edit-size",
                    runs: newRuns,
                    gates: newGates,
                    gate: newGate,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }

                // Angled.
                if (
                  gateEndPostX !== gateStartPostX &&
                  gateEndPostY !== gateStartPostY
                ) {
                  if (gateEndPostX > gateStartPostX) {
                    // Get X transform
                    transformX1 = Math.round(
                      (-1 / 2) * diff * xComponent * pixelsPerFoot() +
                        (-1 / 2) * diffInches * xComponent * pixelsPerInch()
                    );
                    transformX2 = Math.round(
                      (1 / 2) * diff * xComponent * pixelsPerFoot() +
                        (1 / 2) * diffInches * xComponent * pixelsPerInch()
                    );
                  } else {
                    transformX1 = Math.round(
                      (1 / 2) * diff * xComponent * pixelsPerFoot() +
                        (1 / 2) * diffInches * xComponent * pixelsPerInch()
                    );
                    transformX2 = Math.round(
                      (-1 / 2) * diff * xComponent * pixelsPerFoot() +
                        (-1 / 2) * diffInches * xComponent * pixelsPerInch()
                    );
                  }

                  // Get Y Transform
                  if (gateEndPostY > gateStartPostY) {
                    transformY1 = Math.round(
                      (-1 / 2) * diff * yComponent * pixelsPerFoot() +
                        (-1 / 2) * diffInches * yComponent * pixelsPerInch()
                    );
                    transformY2 = Math.round(
                      (1 / 2) * diff * yComponent * pixelsPerFoot() +
                        (1 / 2) * diffInches * yComponent * pixelsPerInch()
                    );
                  } else {
                    transformY1 = Math.round(
                      (1 / 2) * diff * yComponent * pixelsPerFoot() +
                        (1 / 2) * diffInches * yComponent * pixelsPerInch()
                    );
                    transformY2 = Math.round(
                      (-1 / 2) * diff * yComponent * pixelsPerFoot() +
                        (-1 / 2) * diffInches * yComponent * pixelsPerInch()
                    );
                  }

                  let run1 = runs.get(gate.p1.runIndex);
                  let run2 = runs.get(gate.p2.runIndex);

                  if (!run1) {
                    run1 = posts.get(gate.p1.postIndex);
                  }

                  if (!run2) {
                    run2 = posts.get(gate.p2.postIndex);
                  }

                  const corners = calculateCorners(runs);

                  const transformedEntities = handleTransformsOfGraph(
                    run1,
                    run2,
                    corners,
                    runs,
                    gates,
                    stairs,
                    posts,
                    handrails,
                    gate,
                    { transformX1, transformY1, transformX2, transformY2 }
                  );

                  const {
                    newGate,
                    newGates,
                    newRuns,
                    newStairs,
                    newPosts,
                    newHandrails,
                  } = transformedEntities;

                  dispatch({
                    type: "gates/edit-size",
                    runs: newRuns,
                    gates: newGates,
                    gate: newGate,
                    stairs: newStairs,
                    posts: newPosts,
                    handrails: newHandrails,
                  });
                }
              }}
            >
              Update Gate Size
            </button>
          </div>
        </div>
      </div>
      <div className="edit-popup__section">
        <button
          className="edit-popup__delete-button"
          onClick={() => {
            setTimeout(function () {
              dispatch({ type: "gates/remove", gateId: gate.id });
            }, 0);
          }}
        >
          Delete Gate
        </button>
      </div>
    </div>
  );
}

function handleTransformsOfGraph(
  run1,
  run2,
  corners,
  runs,
  gates,
  stairs,
  posts,
  handrails,
  gate,
  transform
) {
  let { transformX1, transformY1, transformX2, transformY2 } = transform;

  let graph1 = getConnectedElements(
    run1,
    corners,
    runs,
    gates,
    stairs,
    posts,
    handrails,
    Map({ [gate.id]: gate })
  );
  let graph2 = getConnectedElements(
    run2,
    corners,
    runs,
    gates,
    stairs,
    posts,
    handrails,
    Map({ [gate.id]: gate })
  );

  graph1 = graph1.remove(gate.id);
  graph2 = graph2.remove(gate.id);

  if (run1) {
    run1 = graph1.get(run1.id);
  }

  if (run2) {
    run2 = graph2.get(run2.id);
  }

  graph1 = getNodesOfGraph(graph1);
  graph2 = getNodesOfGraph(graph2);

  if (run1) {
    run1 = graph1.get(run1.node.id);
  }

  if (run2) {
    run2 = graph2.get(run2.node.id);
  }

  if (run1 && run1.type === "post" && graph1.size === 1) {
    let newGates = gates;
    let newRuns = runs;
    let newStairs = stairs;
    let newGate = gate;
    let newPosts = posts;
    let newHandrails = handrails;

    if (gate.p1.runIndex === null) {
      // Move only the x1 y1.
      newGate = gate
        .set("y1", gate.get("y1") + transformY1 * 2)
        .set("x1", gate.get("x1") + transformX1 * 2);

      newGates = newGates.set(newGate.id, newGate);

      // run1 is a post.
      let newPost = run1;

      newPost = newPost.set("x", newGate.get("x1")).set("y", newGate.get("y1"));

      newPosts = newPosts.set(newPost.id, newPost);
    } else if (gate.p2.runIndex === null) {
      // Move only x2 and y2.
      newGate = gate
        .set("y2", gate.get("y2") + transformY2 * 2)
        .set("x2", gate.get("x2") + transformX2 * 2);

      newGates = newGates.set(newGate.id, newGate);

      // run1 is a post.
      let newPost = run1;

      newPost = newPost.set("x", newGate.get("x2")).set("y", newGate.get("y2"));

      newPosts = newPosts.set(newPost.id, newPost);
    }

    return {
      newGate: newGate,
      newGates: newGates,
      newRuns: newRuns,
      newStairs: newStairs,
      newPosts: newPosts,
      newHandrails: newHandrails,
    };
  }

  if (run2 && run2.type === "post" && graph2.size === 1) {
    let newGates = gates;
    let newRuns = runs;
    let newStairs = stairs;
    let newGate = gate;
    let newPosts = posts;
    let newHandrails = handrails;

    if (gate.p1.runIndex === null) {
      // Move only the x1 y1.
      newGate = gate
        .set("y1", gate.get("y1") + transformY1 * 2)
        .set("x1", gate.get("x1") + transformX1 * 2);

      newGates = newGates.set(newGate.id, newGate);

      // run1 is a post.
      let newPost = run2;

      newPost = newPost.set("x", newGate.get("x1")).set("y", newGate.get("y1"));

      newPosts = newPosts.set(newPost.id, newPost);
    } else if (gate.p2.runIndex === null) {
      // Move only x2 and y2.
      newGate = gate
        .set("y2", gate.get("y2") + transformY2 * 2)
        .set("x2", gate.get("x2") + transformX2 * 2);

      newGates = newGates.set(newGate.id, newGate);

      // run1 is a post.
      let newPost = run2;

      newPost = newPost.set("x", newGate.get("x2")).set("y", newGate.get("y2"));

      newPosts = newPosts.set(newPost.id, newPost);
    }

    return {
      newGate: newGate,
      newGates: newGates,
      newRuns: newRuns,
      newStairs: newStairs,
      newPosts: newPosts,
      newHandrails: newHandrails,
    };
  }

  const translatedGraph1 = graph1
    .filter((run) => run.type === "run")
    .map((run) => {
      run = run
        .set("y1", run.get("y1") + transformY1)
        .set("y2", run.get("y2") + transformY1)
        .set("x1", run.get("x1") + transformX1)
        .set("x2", run.get("x2") + transformX1);

      run = transformRunStairs(run, transformX1, transformY1);
      run = transformRunContinuousStairs(
        run,
        transformX1,
        transformY1,
        transformX1,
        transformY1
      );

      return run;
    });

  const translatedGraph2 = graph2
    .filter((run) => run.type === "run")
    .map((run) => {
      run = run
        .set("y1", run.get("y1") + transformY2)
        .set("y2", run.get("y2") + transformY2)
        .set("x1", run.get("x1") + transformX2)
        .set("x2", run.get("x2") + transformX2);

      run = transformRunStairs(run, transformX2, transformY2);
      run = transformRunContinuousStairs(
        run,
        transformX2,
        transformY2,
        transformX2,
        transformY2
      );

      return run;
    });

  const translatedStairs1 = graph1
    .filter((stairs) => stairs.type === "stairs" || stairs.type === "landing")
    .map((stairs) => {
      stairs = stairs
        .set("y1", stairs.get("y1") + transformY1)
        .set("y2", stairs.get("y2") + transformY1)
        .set("x1", stairs.get("x1") + transformX1)
        .set("x2", stairs.get("x2") + transformX1);

      return stairs;
    });

  const translatedStairs2 = graph2
    .filter((stairs) => stairs.type === "stairs" || stairs.type === "landing")
    .map((stairs) => {
      stairs = stairs
        .set("y1", stairs.get("y1") + transformY2)
        .set("y2", stairs.get("y2") + transformY2)
        .set("x1", stairs.get("x1") + transformX2)
        .set("x2", stairs.get("x2") + transformX2);

      return stairs;
    });

  const translatedGates1 = graph1
    .filter((theGate) => theGate.type === "gate" && theGate.id !== gate.id)
    .map((theGate) => {
      theGate = theGate
        .set("y1", theGate.get("y1") + transformY1)
        .set("y2", theGate.get("y2") + transformY1)
        .set("x1", theGate.get("x1") + transformX1)
        .set("x2", theGate.get("x2") + transformX1);

      return theGate;
    });

  const translatedGates2 = graph2
    .filter((theGate) => theGate.type === "gate" && theGate.id !== gate.id)
    .map((theGate) => {
      theGate = theGate
        .set("y1", theGate.get("y1") + transformY2)
        .set("y2", theGate.get("y2") + transformY2)
        .set("x1", theGate.get("x1") + transformX2)
        .set("x2", theGate.get("x2") + transformX2);

      return theGate;
    });

  const translatedPosts1 = graph1
    .filter((post) => post.type === "post")
    .map((post) => {
      post = post
        .set("x", post.get("x") + transformX1)
        .set("y", post.get("y") + transformY1);

      return post;
    });

  const translatedPosts2 = graph2
    .filter((post) => post.type === "post")
    .map((post) => {
      post = post
        .set("x", post.get("x") + transformX2)
        .set("y", post.get("y") + transformY2);

      return post;
    });

  const translatedHandrails1 = graph1
    .filter((handrail) => handrail.type === "handrail")
    .map((handrail) => {
      handrail = handrail
        .set("x1", handrail.get("x1") + transformX1)
        .set("y1", handrail.get("y1") + transformY1)
        .set("x2", handrail.get("x2") + transformX1)
        .set("y2", handrail.get("y2") + transformY1);

      if (handrail.hasIn(["p1", "x"])) {
        handrail = handrail
          .setIn(["p1", "x"], handrail.getIn(["p1", "x"]) + transformX1)
          .setIn(["p1", "y"], handrail.getIn(["p1", "y"]) + transformY1)
          .setIn(
            ["p1", "matchingPoint", "x"],
            handrail.getIn(["p1", "matchingPoint", "x"]) + transformX1
          )
          .setIn(
            ["p1", "matchingPoint", "y"],
            handrail.getIn(["p1", "matchingPoint", "y"]) + transformY1
          );
      }

      if (handrail.hasIn(["p2", "x"])) {
        handrail = handrail
          .setIn(["p2", "x"], handrail.getIn(["p2", "x"]) + transformX1)
          .setIn(["p2", "y"], handrail.getIn(["p2", "y"]) + transformY1)
          .setIn(
            ["p2", "matchingPoint", "x"],
            handrail.getIn(["p2", "matchingPoint", "x"]) + transformX1
          )
          .setIn(
            ["p2", "matchingPoint", "y"],
            handrail.getIn(["p2", "matchingPoint", "y"]) + transformY1
          );
      }
      return handrail;
    });

  const translatedHandrails2 = graph2
    .filter((handrail) => handrail.type === "handrail")
    .map((handrail) => {
      handrail = handrail
        .set("x1", handrail.get("x1") + transformX2)
        .set("y1", handrail.get("y1") + transformY2)
        .set("x2", handrail.get("x2") + transformX2)
        .set("y2", handrail.get("y2") + transformY2);

      if (handrail.hasIn(["p1", "x"])) {
        handrail = handrail
          .setIn(["p1", "x"], handrail.getIn(["p1", "x"]) + transformX2)
          .setIn(["p1", "y"], handrail.getIn(["p1", "y"]) + transformY2)
          .setIn(
            ["p1", "matchingPoint", "x"],
            handrail.getIn(["p1", "matchingPoint", "x"]) + transformX2
          )
          .setIn(
            ["p1", "matchingPoint", "y"],
            handrail.getIn(["p1", "matchingPoint", "y"]) + transformY2
          );
      }

      if (handrail.hasIn(["p2", "x"])) {
        handrail = handrail
          .setIn(["p2", "x"], handrail.getIn(["p2", "x"]) + transformX2)
          .setIn(["p2", "y"], handrail.getIn(["p2", "y"]) + transformY2)
          .setIn(
            ["p2", "matchingPoint", "x"],
            handrail.getIn(["p2", "matchingPoint", "x"]) + transformX2
          )
          .setIn(
            ["p2", "matchingPoint", "y"],
            handrail.getIn(["p2", "matchingPoint", "y"]) + transformY2
          );
      }
      return handrail;
    });

  const newRuns = runs.merge(translatedGraph1).merge(translatedGraph2);
  const newHandrails = handrails
    .merge(translatedHandrails1)
    .merge(translatedHandrails2);
  const newStairs = stairs.merge(translatedStairs1).merge(translatedStairs2);
  const newPosts = posts.merge(translatedPosts1).merge(translatedPosts2);

  const newGate = gate
    .set("y1", gate.get("y1") + transformY1)
    .set("y2", gate.get("y2") + transformY2)
    .set("x1", gate.get("x1") + transformX1)
    .set("x2", gate.get("x2") + transformX2);

  let newGates = gates.merge(translatedGates1).merge(translatedGates2);

  newGates = newGates.set(newGate.id, newGate);

  return {
    newGate: newGate,
    newGates: newGates,
    newRuns: newRuns,
    newStairs: newStairs,
    newPosts: newPosts,
    newHandrails: newHandrails,
  };
}

export default EditGatePopup;
