import { roundToHundreth } from "../..";
import { calculateNumberOfSegmentsForCustomRailHeight } from "../../../utils";
import { Item } from "../itemListTypes";

export function aluminumCustomPostDescriptions(upc: string, item: Item) {
  upc = upc.toString();
  let cost = 0;
  let desc = "";

  if (!item || !item.counts) {
    return { price: item?.price || 0, description: item?.description || "" };
  }

  switch (upc.toString()) {
    // Raw Alum Terminal
    case "616320730130":
      // Original description:
      //       RAW ALUMINUM TERMINAL POST STOCK
      // As per drawing: 03-001-01
      //  ALLOY 6005A-T61
      // Cut to 20' 6" long
      // ALLOY 6005 AT6
      // DIE 811294
      // Mill Finish
      cost = item.price!;

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(
            parseFloat(inches)
          );

          return `RAW ALUMINUM TERMINAL POST STOCK
${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
            parseFloat(inches) * cost
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    // Raw Alum Intermediate
    case "616320730093":
      // Original description:
      //       RAW INTERMEDIATE POST STOCK
      // As per drawing: 03-001-02 ALLOY 6063-T5
      // Cut to 20' 6' long
      // Mill Finish
      // Die: 810376
      cost = item.price!;

      desc = Object.entries(item.counts)
        .map(([inches, quantity]) => {
          const holes = calculateNumberOfSegmentsForCustomRailHeight(
            parseFloat(inches)
          );

          return `RAW ALUMINUM INTERMEDIATE POST STOCK
${quantity} EA AT ${inches} INCHES FOR ${roundToHundreth(
            parseFloat(inches) * cost
          )} PER CUT LENGTH.
${holes} HOLES DRILLED PER CUT LENGTH.`;
        })
        .join("\n\n");
      return { price: cost, description: desc };
    default:
      return { price: item.price || 0, description: item?.description };
  }
}