// @ts-nocheck
/**
 * External dependencies.
 */
import { useState } from "react";
import { useSpring, animated } from "@react-spring/web";

function Window(props) {
  const { render: View, zIndex } = props;
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [{ x, y }, api] = useSpring(() => ({
    x: position.x,
    y: position.y,
  }));

  // Bind it to a component
  return (
    <animated.div style={{ x, y, zIndex, position: "absolute" }}>
      <View {...props} setPosition={setPosition} animation={api} />
    </animated.div>
  );
}

export default Window;
